import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import filterIcon from 'assets/images/icons/filter.svg';
import {
  AlphamartIntlProvider,
  withAlphamartIntlProvider,
} from 'components/shared/AlphamartIntlProvider';
import { Badge } from 'components/shared/Badge/Badge';
import { ActionsButton } from 'components/shared/Buttons/ActionsButton';
import { DownloadLogsModal } from 'components/shared/DownloadLogsModal/DownloadLogsModal';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { DataList, HighlightSearch, ListActions, useGetRowId } from 'components/shared/List';
import { LoadableContent } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { ThresholdsModal } from 'components/shared/ThresholdsModal/ThresholdsModal';
import { formatDate } from 'helpers/dateTime/dateTime';
import { FilterableModules, PERMISSIONS, SHARED, UserRoles } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useCompanyActions, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useLanguage } from 'shared/hooks/useLanguage';
import { useGetCompanies, useGetCompanyDetails } from 'shared/queries';
import { CompanyListItem, ItemAction, ThresholdsType } from 'shared/types';
import { downloadFile } from 'store/downloadFileSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { MEDIA_QUERY } from 'theme';
import { CompaniesFilters } from './CompaniesFilters';
import { CompanyDetails } from '../CompanyDetails/CompanyDetails';
import { DownloadUserLocationsModal } from '../DownloadUserLocationsModal/DownloadUserLocationsModal';
import { useCompanyItemActions } from '../hooks/useCompanyItemActions';
import {
  CompanyMessages,
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const CompaniesListComponent = (): React.ReactElement => {
  const authorize = useAuthorization();
  const { actionsPending } = useCompanyActions();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const language = useLanguage();
  const listComponent = useAppSelector(state => state.listComponent);
  const changeStatusSubmitting = useAppSelector(state => state.changeCompanyStatus.isPending);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.COMPANIES]);
  const companyDetails = useGetCompanyDetails(id!, {
    enabled: !changeStatusSubmitting && id !== undefined,
  });
  const companiesData = useGetCompanies(
    {
      ...savedFilters.data,
      page: listComponent.pageIndex + 1,
      pageSize: listComponent.pageSize,
    },
    { enabled: !changeStatusSubmitting, cacheTime: 0 },
  );
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isLg = useMediaQuery(MEDIA_QUERY.LG);

  const onFiltersToggle = () => setFiltersOpen(open => !open);
  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));
  const onFiltersChanged = newFilters => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.COMPANIES,
        values: newFilters,
      }),
    );
    setPageIndex(0);
  };

  const createUser = () => navigate('/companies/create');

  const goToCompany = (item: CompanyListItem) => {
    navigate(`/companies/${item.id}`);
  };

  const actions = useCompanyItemActions();

  const columns: ColumnDef<CompanyListItem>[] = [
    {
      id: 'status',
      header: () => FormattedMessage({ id: 'CompaniesList.TableHeader.Status' }),
      cell: ({ row }) => (
        <span>
          <Badge color={resolveStatusColor(row.original.status.toUpperCase())}>
            <FormattedMessage
              id={`Global.Status.${row.original.status.toUpperCase()}` as keyof CompanyMessages}
            />
          </Badge>
        </span>
      ),
    },
    {
      id: 'type',
      header: () => FormattedMessage({ id: 'CompaniesList.TableHeader.Type' }),
      cell: ({ row }) => row.original.type,
    },
    {
      id: 'parentCompanyName',
      header: () => FormattedMessage({ id: 'CompaniesList.TableHeader.ParentCompanyName' }),
      cell: ({ row }) =>
        row.original.parentCompany ? (
          <HighlightSearch
            autoEscape
            searchWords={[savedFilters.data.query!]}
            textToHighlight={row.original.parentCompany}
          />
        ) : (
          SHARED.LONG_DASH
        ),
    },
    {
      id: 'name',
      header: () => FormattedMessage({ id: 'CompaniesList.TableHeader.Name' }),
      cell: ({ row }) => (
        <HighlightSearch
          autoEscape
          searchWords={[savedFilters.data.query!]}
          textToHighlight={row.original.name}
        />
      ),
    },
    {
      id: 'country',
      header: () => FormattedMessage({ id: 'CompaniesList.TableHeader.Country' }),
      cell: ({ row }) => row.original.country[language],
    },
    {
      id: 'city',
      header: () => FormattedMessage({ id: 'CompaniesList.TableHeader.City' }),
      cell: ({ row }) => (
        <HighlightSearch
          searchWords={[savedFilters.data.query!]}
          textToHighlight={row.original.city}
        />
      ),
    },
    {
      id: 'actions',
      header: isMd ? intl.formatMessage({ id: 'Global.Actions' }) : '',
      cell: ({ row }) => <ListActions actions={actions} item={row.original} />,
      meta: {
        widthPx: 80,
      },
    },
  ];

  const handleGenerateBtnClick = async () => {
    try {
      await dispatch(
        downloadFile(
          '/api/companies/report',
          `${intl.formatMessage({ id: 'CompaniesList.ReportName' })} ${formatDate(
            new Date(),
            language === 'EN' ? 'MM-dd-yyyy' : 'dd-MM-yyyy',
          )}.xlsx`,
        ),
      );
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const buttonActions: ItemAction[] = [
    {
      id: 1,
      label: <FormattedMessage id="CompaniesList.Display.GenerateReport" />,
      onClick: () => {
        handleGenerateBtnClick();
      },
      dataCy: () => 'companies-list-display-generate-report',
      visible: authorize([UserRoles.SUPER_ADMIN]),
    },
  ];

  const listLoading =
    (companiesData.isFetching && !companyDetails.isFetching && !companyDetails.data) ||
    actionsPending;

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data: companiesData.data!.data,
    getRowId: useGetRowId(),
    pageCount: Math.ceil(companiesData.data!.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        status: isLg,
        type: authorize(PERMISSIONS.COMPANIES.LIST_RESTRICTED_FIELDS) && isMd,
        parentCompanyName: authorize(PERMISSIONS.COMPANIES.LIST_RESTRICTED_FIELDS) && isMd,
        country: isMd,
        city: isLg,
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      columnSizing: {
        status: 100,
        type: 100,
        parentCompanyName: 100,
        name: 100,
        country: 100,
        city: 100,
      },
    },
  });

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelTemplate>
        <PanelHeader title={<FormattedMessage id="CompaniesList.Header.Companies" />}>
          <AppButton type="button" onClick={createUser}>
            <FormattedMessage id="Global.Create" />
          </AppButton>
          <ActionsButton actions={buttonActions} />
        </PanelHeader>
        <PanelContent>
          <PanelContentMain disableScroll={listLoading}>
            <SearchBar
              isSearchPending={companiesData.isFetching}
              onSearchChanged={onFiltersChanged}
              initialQuery={savedFilters.data.query}
              onFiltersToggle={onFiltersToggle}
              savedFilters={savedFilters}
            />
            <DataList
              table={table}
              isLoading={companiesData.isFetching || actionsPending}
              onRowClicked={goToCompany}
            />
          </PanelContentMain>
          <PanelContentSidebar
            header={intl.formatMessage({ id: 'Global.Filters' })}
            headerIcon={filterIcon}
            open={filtersOpen}
            onSidebarClosed={onFiltersToggle}
          >
            <CompaniesFilters
              onFiltersChanged={onFiltersChanged}
              onFiltersApplied={onFiltersToggle}
            />
          </PanelContentSidebar>
        </PanelContent>
        <LoadableContent loading={companyDetails.isFetching}>
          <CompanyDetails company={companyDetails.data!} />
        </LoadableContent>
      </PanelTemplate>
      <DownloadUserLocationsModal />
      <DownloadLogsModal />
      <ThresholdsModal modalEndpoint={ThresholdsType.COMPANY} />
    </AlphamartIntlProvider>
  );
};

export const CompaniesList = withAlphamartIntlProvider(CompaniesListComponent, messages);
