import * as React from 'react';
import { Store } from '@reduxjs/toolkit';

import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { RootState } from 'store';
import { snackBarPushSuccess } from 'store/shared/snackBarSlice';
import appPackage from '../../../package.json';

export interface VersionHeaders {
  'minimal-frontend-version': string;
}
export function compareVersion(appVersion: string, minimalVersion: string): boolean {
  const appVersionNumbers = appVersion.split('.').map(Number);
  const minimalVersionNumbers = minimalVersion.split('.').map(Number);

  for (let i = 0; i < minimalVersionNumbers.length; i++) {
    if (minimalVersionNumbers[i] !== appVersionNumbers[i])
      return minimalVersionNumbers[i] > appVersionNumbers[i];
  }

  return false;
}

function getCurrentDelay(minimalVersion: string) {
  const maxDelay = 10 * 60 * 1000; // 10min
  const delayKey = `reload-delay-${minimalVersion}`;

  const currentDelay = +(localStorage.getItem(delayKey) ?? 0);
  const newDelay = Math.min(maxDelay, currentDelay + 60000);
  localStorage.setItem(delayKey, newDelay.toString());

  return currentDelay;
}

let obsoleteTimer;
export const validateVersion = (
  { 'minimal-frontend-version': minimalVersion }: VersionHeaders,
  { defaultStore }: { defaultStore: Store<RootState> },
): boolean => {
  if (obsoleteTimer) clearTimeout(obsoleteTimer);

  const reloadDelay = 500;
  const snackbarDelay = 5000;
  const delay = getCurrentDelay(minimalVersion);

  if (appPackage.version && minimalVersion && compareVersion(appPackage.version, minimalVersion)) {
    obsoleteTimer = setTimeout(() => {
      defaultStore.dispatch(snackBarPushSuccess(<FormattedMessage id="Global.AppNewVersion" />));
      setTimeout(() => {
        if (caches) {
          caches.keys().then(names => {
            names.map(name => caches.delete(name));
          });
        }

        return window.location.reload();
      }, snackbarDelay + reloadDelay);
    }, snackbarDelay + delay);

    return true;
  }

  return false;
};
