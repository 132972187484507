import { chain, sumBy } from 'lodash';

import { ShoppingCartItem, ShoppingCartItemsGroup, User } from 'shared/types';
import { TypedIntlShape } from '../locale/messages';

export const ALLOWED_PARTS_OF_CONVERTER = [0, 0.25, 0.5, 0.75, 1];

export const calculateTotalPrice = (item: ShoppingCartItem): number =>
  item.unitsCount * item.partOfConverter * item.unitPrice;

export const calculateGroupTotalPrice = (group: ShoppingCartItemsGroup): number =>
  group.unitsCount * group.partOfConverter * group.avgPrice;

interface CartSummary {
  itemsInCart: number;
  totalConverters: number;
  totalPrice: number;
  averagePrice: number;
}

export const getCartSummary = (
  items: ShoppingCartItem[],
  groups: ShoppingCartItemsGroup[],
): CartSummary => {
  const totalItemsConverters = items.reduce(
    (acc, item) => acc + item.unitsCount * item.partOfConverter,
    0,
  );

  const totalGroupConverters = groups.reduce(
    (acc, group) => acc + group.unitsCount * group.partOfConverter,
    0,
  );

  const totalConverters = totalItemsConverters + totalGroupConverters;

  const totalItemPrice = items.reduce((acc, item) => acc + calculateTotalPrice(item), 0);
  const totalGroupPrice = groups.reduce((acc, group) => acc + calculateGroupTotalPrice(group), 0);
  const totalPrice = totalItemPrice + totalGroupPrice;

  return {
    itemsInCart: sumBy(items, 'unitsCount') + sumBy(groups, 'unitsCount'),
    totalConverters,
    totalPrice,
    averagePrice: totalConverters === 0 ? 0 : totalPrice / totalConverters,
  };
};

export const formatPartOfConverter = (
  value: number,
  intl: TypedIntlShape,
  removeEntities?: boolean,
): string => {
  let fullText = intl.formatMessage({ id: 'ShoppingCart.PartOfConverter.Full' });

  if (removeEntities) {
    fullText = fullText.replace(/&[^;]+;/g, '');
  }

  const map = new Map([
    [0, intl.formatMessage({ id: 'ShoppingCart.PartOfConverter.Empty' })],
    [0.25, '1/4'],
    [0.5, '1/2'],
    [0.75, '3/4'],
    [1, fullText],
  ]);
  return map.get(value) ?? `${value}`;
};

export const isUsingHedgePricing = (user: User): boolean =>
  chain(user.prices)
    .pick(['ptPriceSource', 'pdPriceSource', 'rhPriceSource'])
    .values()
    .includes('HEDGE')
    .value();
