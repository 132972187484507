import React from 'react';
import styled from '@emotion/styled';

import backIcon from 'assets/images/icons/Back.svg';
import { useTypedIntl } from 'locale/messages';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { layers, MEDIA_QUERY, Theme } from 'theme';

const Actions = styled.div<{ theme?: Theme }>`
  grid-area: actions;
  width: auto-fill;
  z-index: ${layers.formContainer};
  background: rgb(34, 34, 34);
  background: linear-gradient(
    90deg,
    rgba(34, 34, 34, 1) 0%,
    rgba(33, 33, 33, 1) 79%,
    rgba(28, 28, 28, 1) 100%
  );

  button {
    height: 38px;
    width: 100%;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    @media ${MEDIA_QUERY.XL} {
      border-right: 3px solid ${({ theme }) => theme.mainColor};

      &:first-of-type {
        margin-top: 32px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.mineShaftLighter};
      }
    }

    @media ${MEDIA_QUERY.MAX_XL} {
      position: absolute;
      left: 14px;
      top: 8px;
      display: block;
      width: 50px;
      height: 50px;
    }
  }
`;

const Content = styled.div`
  grid-area: content;
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  onGoBack?: () => void;
}

const FormContainer = ({ children, className, onGoBack }: Props): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();

  const onBack = () => {
    if (onGoBack) onGoBack();
    else navigate(-1);
  };

  return (
    <div className={className}>
      <Actions>
        <button title={intl.formatMessage({ id: 'Global.GoBack' })} onClick={onBack} type="button">
          <img alt={intl.formatMessage({ id: 'Global.GoBack' })} src={backIcon} />
        </button>
      </Actions>
      <Content>{children}</Content>
    </div>
  );
};

const FormContainerStyled = styled(FormContainer)<{ theme?: Theme }>`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.mineShaftDarker};

  @media ${MEDIA_QUERY.XL} {
    display: grid;
    grid-template-areas: 'actions content';
    grid-template-columns: 96px 1fr;
    overflow-x: hidden;
  }
`;

export { FormContainerStyled as FormContainer };
