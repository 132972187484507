import React, { useCallback, useRef } from 'react';
import mergeRefs from 'react-merge-refs';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';

import closeIcon from 'assets/images/icons/Close.svg';
import { useExtendedTheme } from 'shared/hooks';
import {
  closeIconStyles,
  panelContentSidebarStyles,
  sidebarHeaderStyles,
} from './PanelContentSidebar.styles';
import { Icon } from '../Buttons';

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  headerIcon?: string;
  open: boolean;
  onSidebarClosed: () => void;
  hideYOverflow?: boolean;
}

export const PanelContentSidebar = ({
  children,
  header,
  headerIcon,
  open,
  onSidebarClosed,
}: Props): React.ReactElement => {
  const swipeHandler = useCallback(() => {
    if (open) onSidebarClosed();
  }, [onSidebarClosed, open]);
  const theme = useExtendedTheme();
  const handlers = useSwipeable({
    trackTouch: true,
    trackMouse: true,
    onSwipedRight: swipeHandler,
  });
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition in={open} classNames="transition" timeout={100} nodeRef={nodeRef}>
      <div
        {...handlers}
        className={panelContentSidebarStyles(theme)}
        ref={mergeRefs([nodeRef, handlers.ref])}
      >
        <Icon
          icon={closeIcon}
          pointer
          className={closeIconStyles}
          onClick={onSidebarClosed}
          data-cy="panel-content-sidebar-close"
        />
        <h3 className={sidebarHeaderStyles(theme)}>
          <Icon icon={headerIcon} color={theme.colors.white} />
          {header}
        </h3>
        {children}
      </div>
    </CSSTransition>
  );
};
