import React from 'react';

import { User } from 'shared/types';
import { ConverterPrice } from 'shared/types/converterPrice';
import { Price } from './Price';

interface Props {
  price: ConverterPrice;
  currentUser: User;
  prefix: {
    market?: React.ReactNode;
    hedge?: React.ReactNode;
  };
}

export const MarketHedgePrice = ({ price, currentUser, prefix }: Props): React.ReactElement => {
  const { pdHedgeUsed, ptHedgeUsed, rhHedgeUsed } = currentUser.prices;
  const hedgePriceInUse = [pdHedgeUsed, ptHedgeUsed, rhHedgeUsed].some(hedge => hedge);

  return (
    <>
      <Price
        value={price.marketPrice}
        language={currentUser.language}
        currency={currentUser.currency}
        prefix={hedgePriceInUse && prefix?.market}
      />
      {hedgePriceInUse && (
        <Price
          value={price.hedgePrice}
          language={currentUser.language}
          currency={currentUser.currency}
          prefix={prefix.hedge}
        />
      )}
    </>
  );
};
