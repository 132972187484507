import React, { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { FieldPhotos } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { Form } from 'components/shared/forms/Form/Form';
import { Section } from 'components/shared/forms/Section/Section';
import { LoadableContent } from 'components/shared/Loader';
import { lambdaHostname } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { ConvertersGroup } from 'shared/types';
import { fetchConvertersGroups } from 'store/convertersGroupsSlice';
import { createConvertersGroup } from 'store/createConvertersGroupSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateConvertersGroupPhotos } from 'store/updateConvertersGroupPhotosSlice';
import { MEDIA_QUERY, theme } from 'theme';
import { TypedIntlShape, useTypedIntl } from '../../locale/messages';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupToUpdate: React.Dispatch<React.SetStateAction<ConvertersGroup | null>>;
  groupToUpdate: ConvertersGroup | null;
}

const modalStyles = isPhone => ({
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    overflow: 'auto',
    WebkitBackdropFilter: 'blur(2px)',
    backdropFilter: 'blur(2px)',
  },
  content: {
    minWidth: '80vw',
    backgroundColor: theme.colors.codGrayDarker,
    padding: 0,
    border: 'none',
    top: isPhone ? 0 : '50%',
    left: isPhone ? 0 : '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    ...(!isPhone && { transform: 'translate(-50%, -50%)' }),
    boxShadow:
      'rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0px 9px 6px, rgba(0, 0, 0, 0.23) 0px -1px 4px',
    ...(isPhone && {
      width: '100%',
      height: '100%',
    }),
  },
});

const ModalForm = styled(Form)`
  min-height: unset;

  .form-content {
    grid-template-areas: 'header header' 'content content' 'actions actions';
    width: 100%;

    @media ${MEDIA_QUERY.MAX_XL} {
      grid-template-areas: 'header' 'content' 'actions';
    }

    & > div > fieldset > div > div {
      border-bottom: none;
      border-right: none;

      button {
        z-index: 1;
      }
    }
  }
`;

const convertersGroupFormSchema = (
  allowedSize: number,
  allowedExtensions: string[],
  intl: TypedIntlShape,
): yup.AnyObjectSchema =>
  yup.object().shape({
    files: yup
      .array()
      .max(
        10,
        intl.formatMessage({
          id: 'Converters.PartialConverters.CreateGroup.Errors.Photos.TooLong',
        }),
      )
      .test(
        'fileExtension',
        f =>
          intl.formatMessage(
            { id: 'Converters.PartialConverters.CreateGroup.Errors.Photos.InvalidExtension' },
            {
              fileName: f.value.find(
                ({ name }) => !new RegExp(`${allowedExtensions.join('|')}$`, 'i').test(name),
              )?.name,
            },
          ),
        files =>
          !!files &&
          (files.length === 0 ||
            files.every(file =>
              new RegExp(`${allowedExtensions.join('|')}$`, 'i').test(file.name),
            )),
      )
      .test(
        'fileSize',
        f =>
          intl.formatMessage(
            { id: 'Converters.PartialConverters.CreateGroup.Errors.Photos.FileSize' },
            { fileName: f.value.find(({ size }) => size > allowedSize)?.name },
          ),
        files => !!files && (files.length === 0 || files.every(file => file.size <= allowedSize)),
      ),
  });

const ManageConvertersGroup = ({
  isVisible,
  setIsVisible,
  setGroupToUpdate,
  groupToUpdate,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [activeSection, setActiveSection] = useState('photos');
  const converterPhoto = useAppSelector(state => state.config.upload!.converterPhoto);
  const intl = useTypedIntl();

  const validationSchema = convertersGroupFormSchema(
    converterPhoto.fileSizeLimit,
    converterPhoto.imageExtensions,
    intl,
  );

  const formikContext = useFormik({
    initialValues: {
      files:
        groupToUpdate?.files?.map(({ id, externalId, fileName, mimetype, presignedUrls }) => ({
          file: { name: fileName, type: mimetype } as File,
          data: externalId,
          name: fileName,
          size: 1,
          image: `${lambdaHostname}${presignedUrls!.SMALL}`,
          id,
        })) || [],
      ...(groupToUpdate ? { converters: groupToUpdate.converters } : {}),
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await dispatch(
          groupToUpdate
            ? updateConvertersGroupPhotos(values, groupToUpdate.id)
            : createConvertersGroup(values),
        );
        await dispatch(fetchConvertersGroups());
        dispatch(
          snackBarPushSuccess(
            intl.formatMessage({
              id: `Global.PartialConverters.Group${groupToUpdate ? 'Updated' : 'Created'}`,
            }),
          ),
        );
      } catch {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      } finally {
        setGroupToUpdate(null);
        setIsVisible(false);
      }
    },
  });

  const {
    getFieldMeta,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValidating,
    isValid,
    setFieldTouched,
    touched,
    values,
  } = formikContext;

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  const onCancel = useCallback(() => {
    setGroupToUpdate(null);
    setIsVisible(false);
  }, [setIsVisible]);

  const submitDisabled = !isValid || isValidating || isSubmitting;
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);

  return (
    <ReactModal
      isOpen={isVisible}
      style={modalStyles(isPhone)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <ModalForm
        header={intl.formatMessage({
          id: `Converters.PartialConverters.${groupToUpdate ? 'Update' : 'Add'}Group`,
        })}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submitDisabled={submitDisabled}
        context={formikContext}
        loaderMode={LoadableContent.MODE.MODAL}
      >
        <Section
          setActive={setActiveSection}
          activeSection={activeSection}
          sectionName="photos"
          label={intl.formatMessage({
            id: 'Converters.PartialConverters.CreateGroup.Section.Photos',
          })}
          template={[]}
          singleFieldSection
        >
          <FieldPhotos
            name="files"
            onChange={handleChange}
            onBlur={() => setFieldTouched('files')}
            onTouch={setFieldTouched}
            error={getErrors('files')}
            allowedExtensions={converterPhoto.imageExtensions}
            initialPhotos={values.files}
            data-cy="converter-files"
            fileSizeLimit={converterPhoto.fileSizeLimit}
          />
        </Section>
      </ModalForm>
    </ReactModal>
  );
};

export default ManageConvertersGroup;
