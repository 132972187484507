import React, { Children, useMemo } from 'react';
import styled from '@emotion/styled';
import { FormikContextType, FormikProvider } from 'formik';

import { CancelButton, Submit } from 'components/shared/Buttons';
import { LoadableContent } from 'components/shared/Loader';
import { useTypedIntl } from 'locale/messages';
import { useMediaQuery } from 'shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { FormActions, FormContent, StyledH1, StyledHeader } from './StyledForm';
import { Indicator } from '../Section/components/Indicator/Indicator';

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormikContextType<any>;
  loaderMode?: 'FULL' | 'OVERLAY' | 'MODAL';
  submitLabel?: string;
  className?: string;
  submitDisabled?: boolean;
}
const Form = React.memo(
  ({
    children: sections,
    className,
    header,
    onCancel,
    onSubmit,
    submitDisabled,
    context,
    submitLabel,
    loaderMode = 'FULL',
  }: Props): React.ReactElement => {
    const intl = useTypedIntl();
    const isMaxMd = useMediaQuery(MEDIA_QUERY.MAX_MD);

    const parsedSections = useMemo(
      () =>
        Children.toArray(sections)
          .filter(child => !!child)
          .flat(),
      [sections],
    ) as unknown as React.ReactElement<{
      sectionName: string;
      activeSection: string;
      label: string;
    }>[];
    const baseSubmitLabel = submitLabel || header;
    const submitLabelText =
      isMaxMd && typeof baseSubmitLabel === 'string'
        ? baseSubmitLabel.split(' ')[0]
        : baseSubmitLabel;

    return (
      <FormikProvider value={context}>
        <LoadableContent
          loading={context?.isSubmitting}
          mode={LoadableContent.MODE[loaderMode]}
          label={intl.formatMessage({ id: 'Global.Submitting' })}
        />
        <form className={className} onSubmit={onSubmit} noValidate>
          <div className="form-content">
            <StyledHeader>
              <StyledH1>{header}</StyledH1>
            </StyledHeader>
            <FormContent>
              {parsedSections.map((section, index) => (
                <React.Fragment key={section.props.sectionName}>
                  <Indicator
                    isActive={section.props.sectionName === section.props.activeSection}
                    label={section.props.label}
                    last={index === parsedSections.length - 1}
                    single={parsedSections.length === 1}
                  />
                  {section}
                </React.Fragment>
              ))}
            </FormContent>
            <FormActions>
              <CancelButton styleType="cancel" onClick={onCancel} data-cy="cancel">
                {intl.formatMessage({ id: 'Global.Cancel' })}
              </CancelButton>
              <Submit label={submitLabelText} disabled={submitDisabled} data-cy="submit" />
            </FormActions>
          </div>
        </form>
      </FormikProvider>
    );
  },
);

const FormStyled = styled(Form)`
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.codGrayDarker};

  @media ${MEDIA_QUERY.XL} {
    padding: 32px 64px;
  }

  .form-content {
    width: 100%;
    display: grid;
    grid-column-gap: 32px;
    grid-template-areas: 'header' 'content' 'actions';
    grid-template-columns: auto;
    grid-template-rows: max-content min-content 1fr;

    @media ${MEDIA_QUERY.XL} {
      width: 1280px;
      grid-template-areas: 'header header' 'content .' 'actions .';
      grid-template-columns: auto calc(6 * 32px);
    }
  }
`;

export { FormStyled as Form };
