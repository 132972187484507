import { css } from '@emotion/css';

type Breakpoints = Record<string, number>;
type ScreenSizes<T extends Breakpoints> = Extract<keyof T, string>;
type MediaQuery<T extends Breakpoints> = ScreenSizes<T> | `MAX_${ScreenSizes<T>}`;
type MediaQueries<T extends Breakpoints> = Record<MediaQuery<T>, string>;

export const getMediaQueries = <T extends Breakpoints>(breakpoints: T): MediaQueries<T> => {
  const breakpointEntries = Object.entries(breakpoints);

  return Object.assign(
    {},
    ...breakpointEntries.map(([breakpoint, value]) => ({
      [breakpoint]: `(min-width: ${value}px)`,
      [`MAX_${breakpoint}`]: `not all and (min-width: ${value}px)`, // inverse of above media query
    })),
  );
};

export const getHiddenOnMediaQuery = <T extends Breakpoints>(
  mediaQueries: MediaQueries<T>,
): Record<MediaQuery<T>, string> =>
  Object.assign(
    {},
    ...Object.entries(mediaQueries).map(([mediaQuery, value]) => ({
      [mediaQuery]: css`
        @media ${value} {
          display: none !important;
        }
      `,
    })),
  );
