import styled from '@emotion/styled';

import { theme } from 'theme';

export const StyledPrice = styled.span`
  word-break: keep-all;
`;

export const ListSections = styled.div`
  display: grid;
  row-gap: 30px;
`;

export const ListSection = styled.div`
  h3 {
    font-size: 14px;
  }
`;

export const PricesSourceInfo = styled.p`
  color: ${theme.colors.alphaGray};
  font-size: 12px;
`;
