import React, { MouseEventHandler, useCallback } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { Icon } from 'components/shared/Buttons/Icon';
import { useAlphamartLocation, useExtendedTheme } from 'shared/hooks';
import { NavItem, shouldShow } from './NavList';
import { LeafNav, LeafsContainer, RootLink } from './NavList.styles';

interface NavItemProps {
  item: NavItem;
  compact: boolean;

  toggleMenu(): void;
}

const NavListItem = ({ item, compact, toggleMenu }: NavItemProps) => {
  const location = useAlphamartLocation();
  const theme = useExtendedTheme();

  const { setTriggerRef, setTooltipRef, getTooltipProps, visible } = usePopperTooltip({
    placement: 'right',
    offset: [0, 40],
    defaultVisible: true,
    trigger: 'click',
  });
  const closeMenu = useCallback<MouseEventHandler<HTMLAnchorElement>>(() => {
    if (location.pathname === item.to) return toggleMenu();
  }, [location.pathname, item.to, toggleMenu]);

  const leafs = 'leafs' in item ? item.leafs.filter(shouldShow) : [];

  return (
    <li>
      <RootLink compact={compact} to={item.to} onClick={closeMenu} ref={setTriggerRef}>
        <Icon icon={item.icon} maskSize={item.maskSize} color={theme.colors.greySnow} pointer />
        <span>{item.label}</span>
      </RootLink>
      {leafs.length > 0 && (
        <LeafsContainer
          compact={compact}
          visible={visible}
          ref={setTooltipRef}
          {...(compact ? getTooltipProps() : {})}
        >
          <p>{item.label}</p>
          <ul>
            {leafs.map(leaf => (
              <li key={leaf.to}>
                <LeafNav to={leaf.to} onClick={closeMenu} end>
                  {leaf.label}
                </LeafNav>
              </li>
            ))}
          </ul>
        </LeafsContainer>
      )}
    </li>
  );
};

export default NavListItem;
