import React from 'react';
import styled from '@emotion/styled';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { DEFAULT_FILTERS, FilterableModules, ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { showModalForm } from 'store/modalFormSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { messages, TypedFormattedMessage } from './locale/messages';
import { VehicleSearchModal } from './VehicleSearch/VehicleSearchModal';

const Content = styled.div`
  align-items: center;
  display: grid;
  justify-items: stretch;
  gap: 32px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 100%;
  padding: 20vh 10vw;

  button {
    height: auto;
    min-height: 20vh;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    align-items: normal;
    grid-template-columns: 1fr;
    padding: 0 2vh;

    button {
      min-height: auto;
    }
  }
`;

export const Vehicles = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const handleGrandDenyAccess = () => navigate('/users');
  const handleAddVehicle = () => navigate('/vehicles/create', { state: { prevPath: '/vehicles' } });
  const handleList = () => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.VEHICLES,
        values: { ...DEFAULT_FILTERS.vehicles },
      }),
    );
    navigate('/vehicles/list');
  };

  const handleVinSearch = () =>
    dispatch(showModalForm({ modalType: ModalFormType.VinSearch, params: null }));

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelTemplate>
        <PanelHeader title={<TypedFormattedMessage id="VehiclesMain.Header" />} />
        <PanelContent>
          <PanelContentMain>
            <Content>
              {authorize(PERMISSIONS.VEHICLES.LIST) ? (
                <AppButton type="button" onClick={handleVinSearch}>
                  <TypedFormattedMessage id="VehiclesMain.SearchByVin" />
                </AppButton>
              ) : null}
              {authorize(PERMISSIONS.VEHICLES.LIST) ? (
                <AppButton type="button" onClick={handleList}>
                  <TypedFormattedMessage id="VehiclesMain.ViewAllVehicles" />
                </AppButton>
              ) : null}
              {authorize(PERMISSIONS.VEHICLES.CREATE) ? (
                <AppButton type="button" onClick={handleAddVehicle}>
                  <TypedFormattedMessage id="VehiclesMain.AddVehicle" />
                </AppButton>
              ) : null}
              {authorize(PERMISSIONS.USERS.MANAGE_ACCESS) ? (
                <AppButton type="button" onClick={handleGrandDenyAccess}>
                  <TypedFormattedMessage id="VehiclesMain.GrantDenyAccess" />
                </AppButton>
              ) : null}
            </Content>
          </PanelContentMain>
        </PanelContent>
      </PanelTemplate>
      <VehicleSearchModal />
    </AlphamartIntlProvider>
  );
};
