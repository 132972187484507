import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

interface UpdateConsentsParam {
  termsAndConditionsConsent: boolean;
  latitude: number;
  longitude: number;
}
export const updateConsents =
  (values: UpdateConsentsParam): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await httpClient.put(`${apiHostname}/api/me/consents`, values);
    } catch (error) {
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };
