import { isNil } from 'lodash';

import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useMediaQuery } from 'shared/hooks';
import { matchNormalized, matchSampleNameMultiWord } from 'shared/matchers';
import { Converter, ConverterListItem } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

interface Props {
  converter: ConverterListItem | Converter;
  searchInput: string;
  isGrid?: boolean;
}

export const useSearchableConvertersFields = (props: Props) => {
  const { converter, searchInput, isGrid } = props;
  const intl = useTypedIntl();
  const auth = useAuthorization();
  const seeSampleName = auth(PERMISSIONS.CONVERTERS.LIST_SAMPLE);
  const isMaxXL = useMediaQuery(MEDIA_QUERY.MAX_XL);
  const isMaxLG = useMediaQuery(MEDIA_QUERY.MAX_LG);

  return [
    (isMaxXL || isGrid) && {
      label: intl.formatMessage({ id: 'ConvertersList.TableHeader.Vehicle' }),
      matcher: matchNormalized,
      value: [converter.year, converter.make, converter.model].filter(Boolean).join(' '),
    },
    (isMaxLG || isGrid) &&
      seeSampleName && {
        label: intl.formatMessage({ id: 'ConverterDetails.Assay.SampleName' }),
        matcher: matchSampleNameMultiWord,
        value:
          (converter as Converter).assay?.sampleName ?? (converter as ConverterListItem).sampleName,
      },
    {
      label: intl.formatMessage({ id: 'ConverterDetails.Nicknames' }),
      matcher: matchNormalized,
      value: converter.nicknames,
    },
    {
      label: intl.formatMessage({ id: 'ConverterDetails.OtherNumbers' }),
      matcher: matchNormalized,
      value: converter.otherNumbers?.join(' ') ?? '',
    },
    {
      label: intl.formatMessage({ id: 'ConverterDetails.PartName' }),
      matcher: matchNormalized,
      value: converter.partName,
    },
    {
      label: intl.formatMessage({ id: 'ConverterDetails.Notes' }),
      matcher: matchNormalized,
      value: converter.notes,
    },
  ].filter(p => !!p && !isNil(p.value) && !!searchInput && p.matcher(searchInput)?.test(p.value));
};
