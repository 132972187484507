import { CaseReducer, createSlice } from '@reduxjs/toolkit';

interface ModalState {
  isOpen: boolean;
  message: string | React.ReactElement | null;
  closeMessage: string | React.ReactElement | null | undefined;
  confirmMessage: string | React.ReactElement | null | undefined;
  onClose: (() => void) | null;
  onConfirm: (() => void) | null;
}

type PayloadType = Omit<ModalState, 'isOpen' | 'closeMessage' | 'confirmMessage'> &
  Partial<Pick<ModalState, 'closeMessage' | 'confirmMessage'>>;

const modalSlice = createSlice<
  ModalState,
  {
    showModal: CaseReducer<
      ModalState,
      {
        type: string;
        payload: PayloadType;
      }
    >;
    hideModal: CaseReducer<ModalState>;
  }
>({
  name: 'modal',
  initialState: {
    isOpen: false,
    message: null,
    closeMessage: null,
    confirmMessage: null,
    onClose: null,
    onConfirm: null,
  },
  reducers: {
    showModal: (
      state,
      { payload: { message, closeMessage, confirmMessage, onClose, onConfirm } },
    ) => ({
      isOpen: true,
      message,
      closeMessage,
      confirmMessage,
      onClose,
      onConfirm,
    }),
    hideModal: () => ({
      isOpen: false,
      message: null,
      closeMessage: null,
      confirmMessage: null,
      onClose: null,
      onConfirm: null,
    }),
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
