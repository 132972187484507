import React, { useMemo } from 'react';
import { useFormik } from 'formik';

import { StyledWizardForm } from 'components/shared/forms/Form/StyledForm';
import { Controls } from 'components/shared/forms/Wizard/Controls/Controls';
import { FormWrapper } from 'components/shared/forms/Wizard/FormWrapper/FormWrapper';
import { Wizard } from 'components/shared/forms/Wizard/Wizard';
import { PERMISSIONS, SHARED } from 'shared/constants';
import { roundTimeToFullHour, useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { Hedge } from 'shared/types';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { hedgeFormSchema } from './hedgeFormSchema';
import { HedgeFormStepOne } from './HedgeFormStepOne';
import { HedgeFormStepTwo } from './HedgeFormStepTwo';
import { useTypedIntl } from '../locale/messages';

interface Props {
  onSubmit: (values: HedgeFormSchema) => void;
  initialValues?: HedgeFormSchema;
  editMode?: boolean;
  hedgeData?: Hedge;
}

export const HedgeForm = ({
  onSubmit,
  hedgeData,
  initialValues,
  editMode = false,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const canChangeParentCompany = authorize(PERMISSIONS.HEDGES.CHANGE_PARENT_COMPANY);
  const currentUser = useCurrentUser();

  const validationSchema = useMemo(
    () => hedgeFormSchema(intl, canChangeParentCompany),
    [intl, canChangeParentCompany],
  );
  const formikContext = useFormik<HedgeFormSchema>({
    initialValues: initialValues ?? {
      name: '',
      relatedLot: '',
      note: '',
      parentCompany: currentUser.company.id,
      expiresAt: roundTimeToFullHour(Date.now() + SHARED.TWO_WEEKS_IN_MS),
      ptPriceCust: '',
      ptPriceCustConfirm: '',
      pdPriceCust: '',
      pdPriceCustConfirm: '',
      rhPriceCust: '',
      rhPriceCustConfirm: '',
      placedAt: null,
      assignedUsers: null,
      assignedUsersCompany: null,
    },
    initialTouched: editMode
      ? {
          parentCompany: true,
          ptPriceCust: true,
          pdPriceCust: true,
          rhPriceCust: true,
          relatedLot: true,
          name: true,
          relatedCompanies: true,
          note: true,
          placedAt: true,
          expiresAt: true,
        }
      : {},
    validationSchema,
    onSubmit: async hedge => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'HedgeForm.Modal.Message' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());
            await onSubmit(hedge);
          },
        }),
      );
    },
  });

  const handleGoBack = () => navigate('/hedges');

  return (
    <Wizard>
      <StyledWizardForm onSubmit={formikContext.handleSubmit}>
        <FormWrapper pageIndex={1}>
          <HedgeFormStepOne
            formikContext={formikContext}
            hedgeData={hedgeData}
            editMode={editMode}
          />
        </FormWrapper>
        <FormWrapper pageIndex={2}>
          <HedgeFormStepTwo
            formikContext={formikContext}
            hedgeData={hedgeData}
            editMode={editMode}
          />
        </FormWrapper>
        <Controls
          stepsErrors={[formikContext.errors]}
          isValid={formikContext.isValid}
          touched={formikContext.touched}
          isValidating={formikContext.isValidating}
          isSubmitting={formikContext.isSubmitting}
          onGoBack={handleGoBack}
          lastPageButton={intl.formatMessage({
            id: editMode ? 'HedgeForm.UpdateHeader' : 'HedgeForm.Submit',
          })}
        />
      </StyledWizardForm>
    </Wizard>
  );
};
