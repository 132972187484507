import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { capitalize } from 'lodash';

import { FieldSelectOption, FilterSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { CompanyTypes, DEFAULT_FILTERS, FilterableModules, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetAssignedCompanies, useGetCompanies } from 'shared/queries';
import { Status } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

const getStatusOption = (status, intl) => ({
  value: status,
  label: capitalize(intl.formatMessage({ id: `Global.Status.${status}` })),
});

export interface HedgesFiltersShape {
  status?: Status | null;
  relatedCompanies?: number[] | null;
  parentCompany?: number | null;
}

interface Props {
  onFiltersChanged: (filters?: HedgesFiltersShape) => void;
  onFiltersApplied: () => void;
}

export const HedgesFilters = ({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const location = useLocation();
  const authorize = useAuthorization();
  const [companyId, setCompanyId] = useState<number | undefined>();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.HEDGES]);
  const currentUser = useCurrentUser();
  const companiesForFilter = useGetCompanies(
    { type: CompanyTypes.SISTER, status: Status.ACTIVE },
    { enabled: false },
  );
  const relatedCompanies = useGetAssignedCompanies(companyId);
  const canFilterByParentCompany = authorize(PERMISSIONS.HEDGES.FILTER_BY_PARENT_COMPANY);
  const canFilterRelatedCompanies = authorize(PERMISSIONS.HEDGES.FILTER_BY_COMPANY);
  const queryParentCompany = new URLSearchParams(location.search).get('parentCompany');
  const queryRelatedCompany = new URLSearchParams(location.search).get('relatedCompany');
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const initialFilterValues = {
    ...savedFilters.data,
    ...(queryParentCompany && { parentCompany: +queryParentCompany }),
    ...(queryRelatedCompany && { relatedCompanies: [+queryRelatedCompany].filter(Boolean) }),
  };

  const formikContext = useFormik<HedgesFiltersShape>({
    initialValues: initialFilterValues,
    onSubmit: () => {},
  });
  const { setValues, values } = formikContext;

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  useEffect(() => {
    if (canFilterByParentCompany) companiesForFilter.refetch();
  }, []);

  useEffect(() => {
    if (canFilterRelatedCompanies) {
      if (values.parentCompany) {
        setCompanyId(values.parentCompany);
      }
      if (!canFilterByParentCompany) {
        setCompanyId(currentUser.company.id);
      }
    }
  }, [values.parentCompany]);

  const statusOptions = useMemo(
    () => [Status.ACTIVE, Status.INACTIVE].map(status => getStatusOption(status, intl)),
    [intl],
  );

  const parentCompanyOptions = useMemo<FieldSelectOption[]>(() => {
    if (canFilterByParentCompany) {
      return (
        companiesForFilter.data?.data.map(({ id, name }) => ({
          label: name,
          value: id,
        })) ?? []
      );
    }

    return [
      {
        label: currentUser.company.name,
        value: currentUser.company.id,
      },
    ];
  }, [companiesForFilter.data]);

  const relatedCompaniesOptions = useMemo<FieldSelectOption[]>(() => {
    if (!values.parentCompany && canFilterByParentCompany) return [];

    const parentCompany = values.parentCompany
      ? parentCompanyOptions.find(({ value }) => value === values.parentCompany)
      : { value: currentUser.company.id, label: currentUser.company.name };

    const options =
      relatedCompanies.data?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [];
    if (!parentCompany) return options;
    return [parentCompany, ...options];
  }, [relatedCompanies, values.parentCompany]);

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.hedges);
    onFiltersChanged(DEFAULT_FILTERS.hedges);
  };

  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection
        sectionName="status"
        label={intl.formatMessage({ id: 'HedgesList.Filters.Section.Status' })}
      >
        <FilterSelect
          name="status"
          options={statusOptions}
          label={intl.formatMessage({ id: 'HedgesList.Filters.Status' })}
          value={values.status}
          data-cy="filter-status"
        />
      </FiltersSection>
      {authorize(PERMISSIONS.HEDGES.FILTER_BY_COMPANY) && (
        <FiltersSection
          sectionName="companies"
          label={intl.formatMessage({ id: 'HedgesList.Filters.Section.Companies' })}
        >
          {canFilterByParentCompany && (
            <FilterSelect
              name="parentCompany"
              options={parentCompanyOptions}
              label={intl.formatMessage({ id: 'HedgesList.Filters.ParentCompany' })}
              value={values.parentCompany}
              data-cy="filter-parent-company"
              clearable
            />
          )}
          <FilterSelect
            name="relatedCompanies"
            multi
            options={relatedCompaniesOptions}
            label={intl.formatMessage({ id: 'HedgesList.Filters.RelatedCompanies' })}
            value={values.relatedCompanies}
            data-cy="filter-related-companies"
          />
        </FiltersSection>
      )}
    </FiltersForm>
  );
};
