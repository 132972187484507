import styled from '@emotion/styled';

import { Icon } from 'components/shared/Buttons';
import { Section } from 'components/shared/forms/Section/Section';
import { MEDIA_QUERY, Theme } from 'theme';

export const MainContainer = styled.div`
  @media ${MEDIA_QUERY.XL} {
    margin-top: 25px;
  }
`;
export const SectionsContainer = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0px')};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;
export const StyledSection = styled(Section)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
export const StyledSectionHeader = styled.div<{ theme?: Theme; isOpen: boolean }>`
  position: relative;
  height: 50px;
  border-radius: 8px 8px ${({ isOpen }) => (isOpen ? '0px 0px' : '8px 8px')};
  background-color: ${({ theme }) => theme.colors.codGray};
  border-bottom: ${({ isOpen }) => (isOpen ? 1 : 0)}px solid
    ${({ theme }) => theme.colors.mineShaftLighter};
  margin: 0 2px 0 2px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-gap: 12px;
`;
export const StyledRemoveButton = styled.div<{ theme?: Theme; isOpen: boolean }>`
  grid-column: 3;
  background-color: ${({ theme }) => theme.colors.fuzzyWuzzyBrown};
  border-radius: 0 8px ${({ isOpen }) => (isOpen ? '0px 0px' : '8px 0px')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ToggleContainer = styled.div<{ theme?: Theme; isOpen: boolean }>`
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  background-color: ${({ theme }) => theme.colors.alphaGray};
  border-radius: 8px 0px 0px ${({ isOpen }) => (isOpen ? '0px' : '8px')};
`;
export const StyledToggle = styled(Icon)<{ theme?: Theme; isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? 270 : 90)}deg);
  cursor: pointer;
  width: 14px;
  height: 14px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.doveGray};
  }

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }
`;
export const StyledFormHeader = styled.div<{ theme?: Theme }>`
  grid-column: 2;
  font-size: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.alphaGray};
  overflow: hidden;

  & > span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
export const StyledAddNew = styled.button<{ theme?: Theme; sectionName?: string }>`
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.alphaGray};
  color: ${({ theme }) => theme.colors.alphaGray};
  background-color: ${({ theme }) => theme.colors.codGray};
  margin: 0 2px 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 50px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px dashed ${({ theme }) => theme.colors.codGray};
  }

  @media ${MEDIA_QUERY.XL} {
    margin-top: 25px;
  }
`;
export const StyledMaterialError = styled.div<{ theme?: Theme }>`
  position: absolute;
  top: -25px;
  left: 35%;
  width: 30%;
  height: 25px;
  background-color: ${({ theme }) => theme.colors.fuzzyWuzzyBrown};
  border-radius: 8px 8px 0px 0px;
  color: white;
  font-size: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
