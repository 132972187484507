import { UpdateCompanyMetalsFormData } from 'components/views/Settings/MetalPrices/UpdateMetalPrices';
import { FeedTypes } from 'shared/constants';
import { Metals } from 'shared/types';
import { UpdateCompanyMetalsPriceDto } from 'store/updateMetalAdjustmentSlice';

const getValue = (type: FeedTypes, value: string) =>
  type === FeedTypes.MARKET_FEED || type === FeedTypes.ALPHA_PRICES ? 0 : Number(value);

export const parseCompanyMetalPrice = (
  data: UpdateCompanyMetalsFormData,
): UpdateCompanyMetalsPriceDto => ({
  [Metals.PALLADIUM]: {
    type: data.palladium.type,
    value: getValue(data.palladium.type, data.palladium.value),
    metal: Metals.PALLADIUM,
  },
  [Metals.PLATINUM]: {
    type: data.platinum.type,
    value: getValue(data.platinum.type, data.platinum.value),
    metal: Metals.PLATINUM,
  },
  [Metals.RHODIUM]: {
    type: data.rhodium.type,
    value: getValue(data.rhodium.type, data.rhodium.value),
    metal: Metals.RHODIUM,
  },
});
