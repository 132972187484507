import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { fetchUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateConsents } from 'store/updateConsentsSlice';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from './locale/messages';
import { FieldCheckboxRaw } from '../Fields/FieldCheckbox';
import { ErrorMessage } from '../Fields/FieldWrapper/ErrorMessage/ErrorMessage';
import AppButton from '../forms/AppButton/AppButton';
import { Tooltip } from '../Tooltip/Tooltip';

interface Props {
  checkboxDisabled: boolean;
}

const TermsAndConditionsModalForm = ({ checkboxDisabled }: Props): React.ReactElement => {
  const [isRequestPending, setRequestPending] = useState(false);
  const { position } = useAppSelector(state => state.position);
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: {
      termsAndConditionsConsent: false,
    },
    validationSchema: Yup.object().shape({
      termsAndConditionsConsent: Yup.boolean().oneOf(
        [true],
        intl.formatMessage({ id: 'TermsAndConditionsModal.Checkbox.Required' }),
      ),
    }),
    onSubmit: async formValues => {
      setRequestPending(true);
      try {
        await dispatch(
          updateConsents({
            termsAndConditionsConsent: formValues?.termsAndConditionsConsent,
            latitude: position?.latitude as number,
            longitude: position?.longitude as number,
          }),
        );
        await dispatch(fetchUser());
        dispatch(
          snackBarPushSuccess(
            <FormattedMessage id="Global.TermsAndConditionsModal.Message.Success" />,
          ),
        );
      } catch {
        dispatch(snackBarPushFailure(<FormattedMessage id="Global.Error.SomethingWentWrong" />));
      }
      setRequestPending(false);
    },
  });

  useEffect(() => {
    if (!checkboxDisabled) setFieldValue('termsAndConditionsConsent', true);
  }, [checkboxDisabled]);

  return (
    <form onSubmit={handleSubmit} data-cy="modal-form">
      <section>
        {errors.termsAndConditionsConsent && touched.termsAndConditionsConsent && (
          <ErrorMessage standalone>{errors.termsAndConditionsConsent}</ErrorMessage>
        )}
        <FieldCheckboxRaw
          name="termsAndConditionsConsent"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.termsAndConditionsConsent}
          size="28px"
          disabled={checkboxDisabled}
          data-cy="set-password-checkbox-accept-terms-and-conditions"
        >
          <FormattedMessage id="TermsAndConditionsModal.Agreement" />
        </FieldCheckboxRaw>
      </section>
      <Tooltip
        placement="top"
        tooltip={<FormattedMessage id="TermsAndConditionsModal.PositionRequired" />}
        enabled={!position}
        trigger={['hover', 'click']}
      >
        <AppButton
          disabled={!(isValid && dirty) || isSubmitting || isRequestPending || !position}
          type="submit"
          data-cy="accept-terms-and-conditions-submit"
        >
          <FormattedMessage id="Global.Confirm" />
        </AppButton>
      </Tooltip>
    </form>
  );
};

export { TermsAndConditionsModalForm };
