import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { ErrorCode } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useGetCompanyDetails } from 'shared/queries';
import { AlphamartErrorData, CompanyFormData } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateCompany } from 'store/updateCompanySlice';
import { CompanyForm } from '../CompanyForm/CompanyForm';
import { messages, useTypedIntl } from '../locale/messages';

function UpdateCompanyComponent(): React.ReactElement {
  const companyId = Number(useParams<{ id: string }>().id);
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const { data: company, isFetching } = useGetCompanyDetails(companyId);
  const initialValues: CompanyFormData | undefined = company && {
    city: company.city,
    country: company.country.id,
    maxAssayUsers: company.maxAssayUsers,
    name: company.name,
    note: company.note || '',
    parentCompany: company.parentCompany?.id,
    state: company.state || '',
    street: company.street,
    subdomain: company.subdomain || '',
    terms: {
      treatmentChargePerPound: company.terms.treatmentChargePerPound,
      metalsReturnTermInDays: company.terms.metalsReturnTermInDays,
      metalsReturnFinanceRate: company.terms.metalsReturnFinanceRate,
      platinumReturnRate: company.terms.platinumReturnRate,
      palladiumReturnRate: company.terms.palladiumReturnRate,
      rhodiumReturnRate: company.terms.rhodiumReturnRate,
    },
    type: company.type,
    zipCode: company.zipCode || '',
  };

  const handleSuccess = (): void => {
    dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'CompanyForm.Message.UpdateSuccess' })));
    navigate(`/companies/${companyId}`);
  };

  const handleNotUnique = async (
    values: CompanyFormData,
    name: boolean,
    companyNames: string[],
  ): Promise<void> => {
    const type = name ? 'SameName' : 'SameAddress';
    const containedIn = companyNames.filter(Boolean).length ? '.In' : '';
    dispatch(
      showModal({
        message: intl.formatMessage(
          { id: `CompanyForm.Modal.Warning.${type}${containedIn}` },
          { parentCompanies: companyNames.join(', '), companyName: values.name },
        ),
        onClose: () => dispatch(hideModal()),
        onConfirm: async (): Promise<void> => {
          await dispatch(updateCompany(values, companyId, true));
          await handleSuccess();
          dispatch(hideModal());
        },
      }),
    );
  };

  const handleMaxAssayUsersError = e => {
    dispatch(
      snackBarPushFailure(
        intl.formatMessage(
          {
            id: 'CompanyForm.Modal.Error.USERS_LIMIT_LOWER_THAN_ACTIVE_AND_PENDING_USERS_COUNT',
          },
          e?.errorData,
        ),
      ),
    );
  };

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(updateCompany(values, companyId, false));
        handleSuccess();
      } catch (e) {
        const error = e as AlphamartErrorData;
        const companyNames =
          error?.errorData?.companyNames instanceof Array ? error.errorData.companyNames : [];
        if (error.errorCode === ErrorCode.COMPANY_NAME_ALREADY_EXIST) {
          await handleNotUnique(values, true, companyNames);
        } else if (error.errorCode === ErrorCode.COMPANY_ADDRESS_ALREADY_EXIST) {
          await handleNotUnique(values, false, companyNames);
        } else if (
          error.errorCode === ErrorCode.USERS_LIMIT_LOWER_THAN_ACTIVE_AND_PENDING_USERS_COUNT
        ) {
          handleMaxAssayUsersError(e);
        } else {
          const messageId =
            error.errorCode === ErrorCode.COMPANY_SUBDOMAIN_ALREADY_EXIST
              ? 'CompanyForm.Modal.Error.SameSubdomain'
              : 'Global.Error.SomethingWentWrong';
          dispatch(snackBarPushFailure(intl.formatMessage({ id: messageId })));
        }
      }
    },
    [dispatch, updateCompany],
  );

  return (
    <FormContainer>
      <LoadableContent loading={isFetching} mode={LoadableContent.MODE.MODAL}>
        {company && (
          <CompanyForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            companyId={companyId}
            editMode
          />
        )}
      </LoadableContent>
    </FormContainer>
  );
}

export const UpdateCompany = withAlphamartIntlProvider(UpdateCompanyComponent, messages);
