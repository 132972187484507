import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { RoleListItem } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum RolesKeys {
  GetRoles = 'GetRoles',
}

type GetRolesParams = {
  companyId: number;
};

export const useGetRoles = (
  params: GetRolesParams,
  options: UseQueryOptions<RoleListItem[]> = {},
): UseQueryResult<RoleListItem[]> =>
  useQuery<RoleListItem[]>(
    [RolesKeys.GetRoles, params.companyId],
    async () => {
      if (!params.companyId) return [];

      const response = await getHttpClient().get<RoleListItem[]>(
        `${apiHostname}/api/me/managed-roles?companyId=${params.companyId}`,
      );

      return response.data ?? [];
    },
    { initialData: [], cacheTime: 60000, ...options },
  );
