import * as yup from 'yup';
import { AnySchema } from 'yup';

import {
  CONVERTER_IDENTIFIER,
  NUMBER_3_DIGITS_PRECISION,
  NUMBER_INTEGER,
} from 'shared/constants/regularExpressions';
import { TypedIntlShape } from '../locale/messages';

export const MAX_CONVERTERS_COUNT = 9;

const isExistingOption = (options, value) => options.some(option => value === option);

export const vehicleFormSchema = (
  allowedExtensions: string[],
  allowedSize: number,
  modelOptions: string[],
  makeOptions: string[],
  intl: TypedIntlShape,
): yup.AnyObjectSchema =>
  yup.object().shape({
    vin: yup
      .string()
      .uppercase()
      .matches(/^[A-Z0-9]+$/, {
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Vin.AcceptedCharacters' }),
      })
      .matches(/^[^oiq]+$/i, {
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Vin.ExcludedCharacters' }),
      })
      .min(17, intl.formatMessage({ id: 'VehicleForm.Errors.Vin.TooShort' }))
      .nullable(),
    make: yup
      .string()
      .test({
        name: 'NotMatchRegex',
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Make.NotMatchRegex' }),
        test: value =>
          !value || isExistingOption(makeOptions, value) || /^[0-9A-Z\- /.+]+$/.test(value),
      })
      .required(intl.formatMessage({ id: 'VehicleForm.Errors.Make.Required' }))
      .nullable(),
    model: yup
      .string()
      .test({
        name: 'NotMatchRegex',
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Model.NotMatchRegex' }),
        test: value =>
          !value || isExistingOption(modelOptions, value) || /^[0-9A-Z\- /.+]+$/.test(value),
      })
      .required(intl.formatMessage({ id: 'VehicleForm.Errors.Model.Required' }))
      .nullable(),
    numberOfDoors: yup.number().nullable(),
    year: yup
      .number()
      .min(1990, intl.formatMessage({ id: 'VehicleForm.Errors.Year.Min' }))
      .required(intl.formatMessage({ id: 'VehicleForm.Errors.Year.Required' }))
      .nullable(),
    weight: yup
      .string()
      .nullable()
      .trim()
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Weight.WrongFormat' }),
        test: value => !value || NUMBER_INTEGER.test(value),
      })
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.Weight.TooSmall' }),
        test: value => !value || +value > 0,
      }),
    weightConfirmation: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'VehicleForm.Errors.WeightConfirmation.NotMatch' }),
      test(val) {
        return val === this.parent.weight;
      },
    }),
    photos: yup
      .array()
      .max(10, intl.formatMessage({ id: 'VehicleForm.Errors.Photos.TooLong' }))
      .test(
        'fileExtension',
        f =>
          intl.formatMessage(
            { id: `VehicleForm.Errors.Photos.InvalidExtension` },
            {
              fileName: f.value.find(
                ({ name }) => !new RegExp(`${allowedExtensions.join('|')}$`, 'i').test(name),
              )?.name,
            },
          ),
        files =>
          !!files &&
          (files.length === 0 ||
            files.every(
              file =>
                'url' in file || new RegExp(`${allowedExtensions.join('|')}$`, 'i').test(file.name),
            )),
      )
      .test(
        'fileSize',
        f =>
          intl.formatMessage(
            { id: `VehicleForm.Errors.Photos.FileSize` },
            {
              fileName: f.value.find(({ size }) => size > allowedSize)?.name,
            },
          ),
        files =>
          !!files &&
          (files.length === 0 || files.every(file => 'url' in file || file.size <= allowedSize)),
      ),
    engineDisplacement: yup
      .string()
      .nullable()
      .trim()
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.EngineDisplacement.WrongFormat' }),
        test: value => !value || NUMBER_INTEGER.test(value),
      })
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.EngineDisplacement.TooSmall' }),
        test: value => !value || +value > 0,
      })
      .required(intl.formatMessage({ id: 'VehicleForm.Errors.EngineDisplacement.Required' })),
    engineDisplacementConfirmation: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({
        id: 'VehicleForm.Errors.EngineDisplacementConfirmation.NotMatch',
      }),
      test(val) {
        return val === this.parent.engineDisplacement;
      },
    }),
    enginePower: yup
      .string()
      .nullable()
      .trim()
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.EnginePower.WrongFormat' }),
        test: value => !value || NUMBER_INTEGER.test(value),
      })
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.EnginePower.TooSmall' }),
        test: value => !value || +value > 0,
      }),
    enginePowerConfirmation: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'VehicleForm.Errors.EnginePowerConfirmation.NotMatch' }),
      test(val) {
        return val === this.parent.enginePower;
      },
    }),
    engineType: yup
      .string()
      .required(intl.formatMessage({ id: 'VehicleForm.Errors.EngineType.Required' }))
      .nullable(),
    notes: yup.string(),
    numberOfConverters: yup
      .string()
      .matches(
        NUMBER_INTEGER,
        intl.formatMessage({ id: 'VehicleForm.Errors.NumberOfConverters.Integer' }),
      )
      .required(intl.formatMessage({ id: 'VehicleForm.Errors.NumberOfConverters.Required' }))
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.NumberOfConverters.TooShort' }),
        test: value => (value ? Number(value) >= 1 : true),
      })
      .test({
        message: intl.formatMessage({ id: 'VehicleForm.Errors.NumberOfConverters.TooLarge' }),
        test: value => (value ? Number(value) <= MAX_CONVERTERS_COUNT : true),
      }),
    converters: yup.array().of(
      yup.lazy((values, options) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const opts = options as any;
        const currentIndex = opts.index ?? 0;
        const numberOfConverters = opts.from?.[0]?.value?.numberOfConverters ?? 0;
        const numberOfNonstandardConverters =
          opts.from?.[0]?.value?.numberOfNonstandardConverters ?? 0;
        if (currentIndex >= numberOfConverters - numberOfNonstandardConverters) {
          return yup.object();
        }

        return yup.object().shape({
          identifier: yup
            .string()
            .trim()
            .uppercase()
            .min(2, intl.formatMessage({ id: 'VehicleForm.Errors.Identifier.TooShort' }))
            .test({
              name: 'NotMatchRegex',
              message: intl.formatMessage({ id: 'VehicleForm.Errors.Identifier.NotMatchRegex' }),
              test: value =>
                !value || isExistingOption(makeOptions, value) || CONVERTER_IDENTIFIER.test(value),
            })
            .test({
              name: 'isValid',
              message: intl.formatMessage({
                id: 'VehicleForm.Errors.IdentifierConfirm.NotMatch',
              }),
              test(value) {
                return value === this.parent.identifierConfirm;
              },
            })
            .required(intl.formatMessage({ id: 'VehicleForm.Errors.Identifier.Required' })),
          identifierConfirm: yup
            .string()
            .trim()
            .uppercase()
            .min(2, intl.formatMessage({ id: 'VehicleForm.Errors.Identifier.TooShort' }))
            .required(intl.formatMessage({ id: 'VehicleForm.Errors.Identifier.Required' }))
            .test({
              name: 'NotMatchRegex',
              message: intl.formatMessage({ id: 'VehicleForm.Errors.Identifier.NotMatchRegex' }),
              test: value =>
                !value || isExistingOption(makeOptions, value) || CONVERTER_IDENTIFIER.test(value),
            })
            .test({
              name: 'isValid',
              message: intl.formatMessage({
                id: 'VehicleForm.Errors.IdentifierConfirm.NotMatch',
              }),
              test(value) {
                return value === this.parent.identifier;
              },
            }),
        });
      }) as unknown as AnySchema,
    ),
    numberOfNonstandardConverters: yup
      .string()
      .matches(
        NUMBER_INTEGER,
        intl.formatMessage({ id: 'VehicleForm.Errors.NumberOfNonstandardConverters.Integer' }),
      )
      .required(
        intl.formatMessage({ id: 'VehicleForm.Errors.NumberOfNonstandardConverters.Required' }),
      )
      .test({
        message: intl.formatMessage({
          id: 'VehicleForm.Errors.NumberOfNonstandardConverters.TooSmall',
        }),
        test: value => (value ? Number(value) >= 0 : true),
      })
      .test({
        message: intl.formatMessage({
          id: 'VehicleForm.Errors.NumberOfNonstandardConverters.TooLarge',
        }),
        test: (value, ctx) => (value ? Number(value) <= ctx.parent.numberOfConverters : true),
      }),
    nonstandardConverters: yup.array().of(
      yup.lazy((values, options) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const opts = options as any;
        const currentIndex = opts.index ?? 0;
        const validatedCount = opts.from?.[0]?.value?.numberOfNonstandardConverters ?? 0;
        if (currentIndex >= validatedCount) {
          return yup.object();
        }

        return yup.object().shape({
          nonstandardConverterId: yup
            .number()
            .typeError(
              intl.formatMessage({
                id: 'VehicleForm.Errors.NonstandardConverterId.Required',
              }),
            )
            .required(
              intl.formatMessage({ id: 'VehicleForm.Errors.NonstandardConverterId.Required' }),
            ),
          materialWeight: yup
            .string()
            .matches(NUMBER_3_DIGITS_PRECISION, {
              message: intl.formatMessage({ id: 'Global.Validation.ThreeDigits' }),
            })
            .test({
              message: intl.formatMessage(
                { id: 'Global.Validation.GreaterOrEqual' },
                { number: 0 },
              ),
              test: value => (value ? Number(value) >= 0 : true),
            })
            .test({
              message: intl.formatMessage({ id: 'Global.Validation.TooBig' }),
              test: value => (value ? Number(value) < 500 : true),
            }),
        });
      }) as unknown as AnySchema,
    ),
  });
