import React, { useEffect } from 'react';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { useDefaultRedirect, useExtendedTheme } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { redirectTo } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { messages, TypedFormattedMessage } from './locale/messages';
import {
  authenticationFormContainerStyles,
  authenticationLogoContainerStyles,
  authenticationPhotoStyles,
  authenticationStyles,
} from './Authentication.styles';
import { loggedOutPageFormStyles, loggedOutPageHeaderStyle } from './LoggedOut.styles';

function LoggedOutPage(): React.ReactElement {
  const auth = useAppSelector(state => state.auth);
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const theme = useExtendedTheme();
  const redirectPath = useDefaultRedirect();

  useEffect(() => {
    if (auth.user) {
      navigate(redirectPath);
    } else {
      dispatch(redirectTo(null));
    }
  }, [auth.user, auth.redirectTo, dispatch]);

  return (
    <div className={authenticationStyles()}>
      <section className={authenticationFormContainerStyles()}>
        <header className={authenticationLogoContainerStyles(theme.logo)}>Alphamart</header>
        <div className={loggedOutPageFormStyles()}>
          <h2 className={loggedOutPageHeaderStyle()}>
            <TypedFormattedMessage id="LoggedOut.Header.YouHaveBeenLoggedOut" />
          </h2>
          <AppButton type="submit" data-cy="login-button-login" onClick={() => navigate('/login')}>
            <TypedFormattedMessage id="Login.Button.Login" />
          </AppButton>
        </div>
      </section>
      <div className={authenticationPhotoStyles()} />
    </div>
  );
}

function LoggedOut(): React.ReactElement {
  return (
    <AlphamartIntlProvider messages={messages}>
      <LoggedOutPage />
    </AlphamartIntlProvider>
  );
}

export default LoggedOut;
