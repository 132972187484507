import React, { ReactNode } from 'react';
import { useDrag } from 'react-dnd';
import styled from '@emotion/styled';

import hamburgerIcon from 'assets/images/icons/hamburger.svg';
import { DndTypes, lambdaHostname } from 'shared/constants';
import { theme } from 'theme';
import { Icon } from '../Buttons';
import { ThumbImageWrapper } from '../Gallery/Gallery.styles';
import { IconTooltipWarning } from '../IconTooltip';
import { ListImage } from '../List';
import { PhotoPlaceholder } from '../PhotoPlaceholder';

const DraggableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: 80%;
  height: 64px;
  margin-bottom: 16px;
  background-color: ${theme.colors.mineShaft};
  border-radius: 8px;
  padding: 10px 32px;
  font-size: 12px;
  font-weight: bold;
  color: ${theme.colors.whisper};

  &:hover {
    cursor: grab;
  }

  &:focus,
  &:active {
    cursor: grabbing;
  }

  & > *:not(:last-child) {
    margin-right: 32px;
  }

  & > div {
    min-width: 72px;
  }

  p {
    word-break: break-word;
    min-width: 36px;
    max-width: calc(100% - 162px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface DraggableRowProps<Item extends Record<string, unknown>> {
  item: Item;
  type: DndTypes;
  keysToDisplay: readonly (keyof Item)[];
  photo?: keyof Item;
}

const isNumberOrString = (value: unknown): value is number | string =>
  typeof value === 'string' || typeof value === 'number';

const DraggableRow = <Item extends Record<string, unknown>>({
  item,
  keysToDisplay,
  photo,
  type,
}: DraggableRowProps<Item>): React.ReactElement => {
  const [{ cursor, opacity }, drag] = useDrag({
    item,
    type,
    collect: monitor => ({
      cursor: monitor.isDragging() ? 'grabbing' : 'grab',
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const rows = keysToDisplay
    .map(key => [key, item[key]] as [string, unknown])
    .filter(row => isNumberOrString(row[1]));

  return (
    <DraggableRowContainer ref={drag} style={{ cursor, opacity }}>
      {photo && (
        <ThumbImageWrapper>
          <>
            {(item[photo] as string[]).length ? (
              <ListImage
                alt={item[keysToDisplay[0]] as string}
                src={`${lambdaHostname}${item[photo][0].presignedUrls.SMALL}`}
              />
            ) : (
              <PhotoPlaceholder />
            )}
            {item.counterfeit && <IconTooltipWarning tooltip={item.notes as ReactNode} />}
          </>
        </ThumbImageWrapper>
      )}
      {rows.map(row => (
        <p key={row[0]}>{row[1] as string}</p>
      ))}
      <Icon icon={hamburgerIcon} />
    </DraggableRowContainer>
  );
};

export { DraggableRow, DraggableRowContainer };
