import React, { useState } from 'react';
import { isNull } from 'lodash';

import { Badge } from 'components/shared/Badge';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { DetailsSectionField } from 'components/shared/Details/DetailsSection';
import { Gallery } from 'components/shared/Gallery';
import { HedgePriceInUseBadge } from 'components/shared/HedgePriceInUseBadge';
import { IconTooltipWarning } from 'components/shared/IconTooltip/IconTooltip';
import { MarketHedgePrice } from 'components/shared/Price';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { formatDate } from 'helpers/dateTime/dateTime';
import { removeChecker } from 'helpers/objects/removeChecker';
import { AccessFlag, ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useExtendedTheme } from 'shared/hooks';
import { Converter, ItemAction, User } from 'shared/types';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch } from 'store/shared/hooks';
import {
  DisclaimerContent,
  HeaderWrapper,
  LinkToAssay,
  StyledConvertedWrapper,
  StyledConverterGallery,
  StyledNotes,
} from './ConverterDetails.styles';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

interface Props {
  converter: Converter | null;
  currentUser: User;
}

export const ConverterDetails = ({ converter, currentUser }: Props): React.ReactElement | null => {
  const [areDetailsScrollable, setAreDetailsScrollable] = useState(false);
  const theme = useExtendedTheme();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const canSeeDisclaimer = authorize(PERMISSIONS.CONVERTERS.DISCLAIMER);

  if (!converter) return null;

  const otherNumbersList = converter.otherNumbers?.length
    ? converter.otherNumbers.map(otherNumber => (
        <Badge key={otherNumber.id} color={theme.colors.mineShaftLightest} breakable>
          {otherNumber.original}
        </Badge>
      ))
    : null;

  const assayFields: DetailsSectionField[] = [
    {
      name: 'carbonPercentage',
      label: <FormattedMessage id="ConverterDetails.CarbonPercentage" />,
      value: converter.assay.carbonPercentage
        ? `${converter.assay.carbonPercentage.toFixed(3)} %`
        : null,
    },
  ];

  if (authorize(PERMISSIONS.CONVERTERS.DETAILS.ASSAY)) {
    assayFields.unshift({
      name: 'sampleName',
      label: <FormattedMessage id="ConverterDetails.Assay.SampleName" />,
      value: (
        <LinkToAssay to={`/assays/${converter.assay.id}`}>{converter.assay.sampleName}</LinkToAssay>
      ),
    });
  }

  const actions: ItemAction[] = [
    {
      id: 1,
      label: <FormattedMessage id="ShoppingCart.Modal.Add.Title" />,
      onClick: () =>
        dispatch(
          showModalForm({
            modalType: ModalFormType.AddShoppingCartItems,
            params: { converters: [converter] },
          }),
        ),
      visible: authorize([AccessFlag.SHOPPING_CART_VIEWER]) && !isNull(converter.price.marketPrice),
    },
  ];

  return (
    <Details
      title={
        <HeaderWrapper>
          {converter.isPartial ? (
            <FormattedMessage
              id="ConverterDetails.PartialIdentifier"
              values={{ identifier: converter.identifier }}
            />
          ) : (
            converter.identifier
          )}
          <HedgePriceInUseBadge />
        </HeaderWrapper>
      }
      backUrl="/converters"
      scrollable={!areDetailsScrollable}
      actions={actions}
    >
      {canSeeDisclaimer && (
        <DisclaimerContent>
          <FormattedMessage id="ConvertersList.Header.Disclaimer" />
        </DisclaimerContent>
      )}
      {converter.files?.[0] && (
        <StyledConverterGallery>
          <DetailsSection>
            <Gallery
              images={converter.files}
              onLightboxToggle={isOpened => {
                setAreDetailsScrollable(isOpened);
              }}
              withMainPhoto
              largeImage
            />
          </DetailsSection>
        </StyledConverterGallery>
      )}
      <DetailsSection
        title={<FormattedMessage id="ConverterDetails.Header" />}
        template={['calculatedPrice partName nicknames', 'otherNumbers type counterfeit']}
        fields={[
          {
            name: 'calculatedPrice',
            label: <FormattedMessage id="ConverterDetails.CalculatedPrice" />,
            value: (
              <MarketHedgePrice
                price={converter.price}
                currentUser={currentUser}
                prefix={{
                  market: <FormattedMessage id="ConverterDetails.MarketPricePrefix" />,
                  hedge: <FormattedMessage id="ConverterDetails.HedgePricePrefix" />,
                }}
              />
            ),
          },
          {
            name: 'partName',
            label: <FormattedMessage id="ConverterDetails.PartName" />,
            value: converter.partName,
          },
          {
            name: 'nicknames',
            label: <FormattedMessage id="ConverterDetails.Nicknames" />,
            value: converter.nicknames,
          },
          {
            name: 'otherNumbers',
            label: <FormattedMessage id="ConverterDetails.OtherNumbers" />,
            value: otherNumbersList,
          },
          {
            name: 'type',
            label: <FormattedMessage id="ConverterDetails.Type" />,
            value: converter.type?.name ?? null,
          },
          {
            name: 'counterfeit',
            label: <FormattedMessage id="ConverterDetails.Counterfeit" />,
            value: converter.counterfeit ? (
              <FormattedMessage id="Global.Yes" />
            ) : (
              <FormattedMessage id="Global.No" />
            ),
          },
        ]}
      />
      <DetailsSection
        title={<FormattedMessage id="ConverterDetails.CarHeader" />}
        template={['make model year']}
        fields={[
          {
            name: 'make',
            label: <FormattedMessage id="ConverterDetails.Make" />,
            value: converter.make,
          },
          {
            name: 'model',
            label: <FormattedMessage id="ConverterDetails.Model" />,
            value: converter.model,
          },
          {
            name: 'year',
            label: <FormattedMessage id="ConverterDetails.Year" />,
            value: converter.year,
          },
        ]}
      />
      <DetailsSection
        title={<FormattedMessage id="ConverterDetails.Notes" />}
        template={['notes notes notes']}
        fields={[
          {
            name: 'notes',
            label: '',
            value: converter.counterfeit ? (
              <StyledNotes>
                <IconTooltipWarning
                  tooltip={<FormattedMessage id="ConverterDetails.Counterfeit" />}
                />
                {converter.notes}
              </StyledNotes>
            ) : (
              converter.notes
            ),
          },
        ]}
      />

      <StyledConvertedWrapper>
        <DetailsSection
          title={<FormattedMessage id="ConverterDetails.Assay.Header" />}
          template={[
            authorize(PERMISSIONS.CONVERTERS.DETAILS.ASSAY)
              ? 'sampleName carbonPercentage'
              : 'carbonPercentage carbonPercentage',
          ]}
          fields={assayFields}
        />
      </StyledConvertedWrapper>

      <ProtectedArea permission={PERMISSIONS.CONVERTERS.DETAILS.CREATED_BY}>
        <DetailsFooter
          fields={[
            {
              name: 'createdBy',
              label: <FormattedMessage id="Global.CreatedBy" />,
              value:
                converter.createdBy &&
                `${converter.createdBy.firstName} ${converter.createdBy.lastName}`,
            },
            {
              name: 'createdAt',
              label: <FormattedMessage id="Global.CreatedOn" />,
              value: formatDate(converter.createdAt),
            },
            {
              name: 'updatedBy',
              label: <FormattedMessage id="Global.UpdatedBy" />,
              value:
                converter.updatedBy &&
                `${converter.updatedBy.firstName} ${converter.updatedBy.lastName}`,
            },
            {
              name: 'updatedAt',
              label: <FormattedMessage id="Global.UpdatedOn" />,
              value: converter.updatedAt && formatDate(converter.updatedAt),
            },
            ...(removeChecker(converter)
              ? [
                  {
                    name: 'deletedBy',
                    label: <FormattedMessage id="Global.DeletedBy" />,
                    value:
                      converter.deletedBy &&
                      `${converter.deletedBy.firstName} ${converter.deletedBy.lastName}`,
                  },
                  {
                    name: 'deletedAt',
                    label: <FormattedMessage id="Global.DeletedOn" />,
                    value: converter.deletedAt && formatDate(converter.deletedAt),
                  },
                ]
              : []),
          ]}
        />
      </ProtectedArea>
    </Details>
  );
};
