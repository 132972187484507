import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { getHttpClient } from 'shared/utils/httpClient';

export enum ConverterKeys {
  GetConverterIdentifiers = 'GetConverterIdentifiers',
}

interface GetConverterIdentifiersParams {
  query: string;
  limit: number;
}

export const useGetConverterIdentifiers = (
  { query, limit = 20 }: GetConverterIdentifiersParams,
  options: UseQueryOptions<string[]> = {},
): UseQueryResult<string[]> =>
  useQuery<string[]>(
    [ConverterKeys.GetConverterIdentifiers, query, limit],
    async () => {
      if (query.length < 2) return [];
      const response = await getHttpClient().get<string[]>(
        `${apiHostname}/api/converters/identifier`,
        { params: { query, limit } },
      );
      return response.data ?? [];
    },
    { initialData: [], ...options },
  );
