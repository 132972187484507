import { css } from '@emotion/css';

import { MEDIA_QUERY, NAVBAR_HEIGHT_MOBILE, Theme } from 'theme';

export const headerStyles = (theme: Theme): string => css`
  display: flex;
  border-bottom: 1px solid ${theme.colors.mineShaft};
  align-items: center;
  height: 97px;

  @media ${MEDIA_QUERY.MAX_MD} {
    height: 65px;
    min-height: 65px;
  }
`;

export const headerTitleAndActionsStyles = css`
  display: flex;
  padding: 0 32px;
  align-items: center;
  width: 100%;
  gap: 0 24px;

  @media ${MEDIA_QUERY.MAX_MD} {
    justify-content: space-between;
    height: ${NAVBAR_HEIGHT_MOBILE};
    min-height: ${NAVBAR_HEIGHT_MOBILE};
    padding: 0 16px;
    gap: 12px;
  }
`;

export const headerTitleStyles = (theme: Theme): string => css`
  margin: 0 auto 0 0;
  font-family: ${theme.fonts.montserrat};
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
`;

export const headerUserMenuWrapperStyles = css`
  display: block;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;
  }
`;
