import { apiHostname } from 'shared/constants';
import { GenericThunk } from './shared/createGenericStoreSlice';

export interface UpdateOffZoneParam {
  boundLatitudes?: number[];
  boundLongitudes?: number[];
  centerLongitude?: number;
  centerLatitude?: number;
  company: number;
  id?: number | null;
}

export const updateOffZones =
  (zones: UpdateOffZoneParam[]): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await httpClient.post(`${apiHostname}/api/off-zones`, { zones });
    } catch (error) {
      return Promise.reject(error);
    }
  };
