import React from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { addDays, endOfDay } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldDatePickerRange } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import {
  ModalFormButtonContainer,
  ModalFormContent,
} from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent } from 'components/shared/Loader';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { ModalFormType } from 'shared/constants';
import { useExtendedTheme } from 'shared/hooks';
import { getDateString } from 'shared/utils/getDateString';
import { downloadFile } from 'store/downloadFileSlice';
import { hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { layers, Theme } from 'theme';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const DatePickerWrapper = styled.div<{ theme: Theme }>`
  & button:after {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.doveGray};
  }
`;

const validationSchema = Yup.object().shape({
  addedAt: Yup.object()
    .shape({
      from: Yup.string().required(),
      to: Yup.string().required(),
    })
    .required(),
});

const DownloadUserShoppingCartLogsModalComponent = (): React.ReactElement => {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const isFileDownloading = useAppSelector(state => state.downloadFile.isPending);
  const dispatch = useAppDispatch();

  const formikContext = useFormik({
    initialValues: {
      addedAt: { from: null, to: null },
    },
    validationSchema,
    onSubmit: async x => x,
  });
  const submitDisabled = !formikContext.isValid || Object.keys(formikContext.touched).length === 0;
  const handleCancelClick = () => {
    formikContext.resetForm();
    dispatch(hideModalForm());
  };

  const handleConfirmClick = async () => {
    try {
      const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      await dispatch(
        downloadFile(
          `/api/shopping-cart/export-activity-log`,
          `shoppingCart_history_${formatDateWithTime(new Date())}.xlsx`,
          {
            ...params,
            addedFrom: getDateString(formikContext.values.addedAt.from!),
            addedTo: getDateString(addDays(formikContext.values.addedAt.to!, 1)),
            timezone,
          },
        ),
      );
      dispatch(hideModalForm());
      formikContext.resetForm();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const maxDate = endOfDay(new Date());
  const maxDateRange = { days: 30 };

  return (
    <ReactModal
      isOpen={isOpen && modalType === ModalFormType.DownloadShoppingCartActivityLogs}
      style={ReactModalStyle(false, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          <FiltersSection
            noPadding
            label={intl.formatMessage({ id: 'Global.DownloadLogs.SelectDateRange' })}
          >
            <DatePickerWrapper theme={theme}>
              <FieldDatePickerRange
                name="addedAt"
                value={formikContext.values.addedAt}
                maxDateRange={maxDateRange}
                max={maxDate}
                isClearable
              />
            </DatePickerWrapper>
          </FiltersSection>
          <ModalFormButtonContainer>
            <AppButton styleType="neutral-empty" onClick={handleCancelClick}>
              <FormattedMessage id="Global.Cancel" />
            </AppButton>
            <AppButton disabled={submitDisabled} styleType="ok" onClick={handleConfirmClick}>
              <FormattedMessage id="Global.DownloadLogs.Confirm" />
            </AppButton>
          </ModalFormButtonContainer>
        </FormikProvider>
      </ModalFormContent>
      <LoadableContent mode={LoadableContent.MODE.FULL} loading={isFileDownloading} />
    </ReactModal>
  );
};

export const DownloadUserShoppingCartLogsModal = withAlphamartIntlProvider(
  DownloadUserShoppingCartLogsModalComponent,
  messages,
);
