import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { css } from '@emotion/css';
import { useFormik } from 'formik';
import jwt from 'jsonwebtoken';
import * as Yup from 'yup';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { TextButton } from 'components/shared/Buttons';
import { FieldCheckboxRaw } from 'components/shared/Fields/FieldCheckbox';
import { FieldInputRaw } from 'components/shared/Fields/FieldInput/FieldInput';
import { ErrorMessage } from 'components/shared/Fields/FieldWrapper/ErrorMessage/ErrorMessage';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { TermsAndConditionsModal } from 'components/shared/TermsAndConditionsModal';
import { useAlphamartNavigate, useExtendedTheme } from 'shared/hooks';
import { redirectTo, setPasswordAction } from 'store/setPasswordSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushPersistentFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import {
  AuthenticationMessages,
  messages,
  TypedFormattedMessage,
  useTypedIntl,
} from './locale/messages';
import {
  authenticationFieldTextContainer,
  AuthenticationFormButtonsStyled,
  authenticationFormContainerStyles,
  authenticationFormStyles,
  authenticationHeaderStyle,
  authenticationLogoContainerStyles,
  authenticationPhotoStyles,
  authenticationStyles,
} from './Authentication.styles';

interface SetPasswordFormShape {
  email: string;
  password: string;
  repeatPassword: string;
  termsAndConditionsConsent: boolean;
}

function SetPasswordPage(): React.ReactElement {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDocumentRead, setDocumentRead] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const setPassword = useAppSelector(state => state.setPassword);
  const { position } = useAppSelector(store => store.position);
  const { token } = useParams<{ token: string }>();
  const navigate = useAlphamartNavigate();
  const { sub, exp } = jwt.decode(token!) as { sub: string; exp: number };
  const tokenExpired = exp * 1000 < Date.now();
  const theme = useExtendedTheme();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
    setSubmitting,
    setFieldError,
    setFieldTouched,
    setFieldValue,
  } = useFormik<SetPasswordFormShape>({
    initialValues: {
      email: sub,
      password: '',
      repeatPassword: '',
      termsAndConditionsConsent: false,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(intl.formatMessage({ id: 'SetPassword.Error.Password.Required' }))
        .min(8, intl.formatMessage({ id: 'SetPassword.Error.Password.Length' }))
        .matches(/[!@#$%^]/, {
          message: intl.formatMessage({ id: 'SetPassword.Error.Password.SpecialCharacters' }),
        })
        .matches(/\d/, {
          message: intl.formatMessage({ id: 'SetPassword.Error.Password.Digit' }),
        })
        .matches(/[a-z]/, {
          message: intl.formatMessage({ id: 'SetPassword.Error.Password.SmallLetters' }),
        })
        .matches(/[A-Z]/, {
          message: intl.formatMessage({ id: 'SetPassword.Error.Password.LargeLetters' }),
        }),
      repeatPassword: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          intl.formatMessage({ id: 'SetPassword.Error.RepeatPassword.NotSame' }),
        )
        .required(intl.formatMessage({ id: 'ResetPassword.Error.RepeatPassword.Required' })),
      termsAndConditionsConsent: Yup.boolean().oneOf(
        [true],
        intl.formatMessage({ id: 'SetPassword.Error.TermsAndConditionsConsent.Required' }),
      ),
    }),
    onSubmit: async formValues => {
      try {
        await dispatch(setPasswordAction(token!, { ...formValues, position }));
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'SetPassword.Success' })));
      } catch {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (tokenExpired) {
      dispatch(
        snackBarPushPersistentFailure(intl.formatMessage({ id: 'SetPassword.Error.TokenExpired' })),
      );
    }

    if (setPassword.error) {
      dispatch(
        snackBarPushPersistentFailure(
          intl.formatMessage({ id: setPassword.error as keyof AuthenticationMessages }),
        ),
      );
    }

    if (setPassword.redirectTo) {
      dispatch(redirectTo(null));
      navigate(setPassword.redirectTo);
    }
  }, [setPassword.redirectTo, setPassword.error, tokenExpired, dispatch]);

  useEffect(() => {
    if (isDocumentRead) {
      setFieldError('termsAndConditionsConsent', undefined);
      setFieldTouched('termsAndConditionsConsent', false);
      setFieldValue('termsAndConditionsConsent', true);
    }
  }, [isDocumentRead]);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleCheckboxClick = () => {
    if (!isDocumentRead) {
      setFieldTouched('termsAndConditionsConsent', true, false);
      setFieldError(
        'termsAndConditionsConsent',
        intl.formatMessage({ id: 'SetPassword.Error.TermsAndConditionsConsent.DocumentNotRead' }),
      );
    }
  };

  return (
    <>
      <div className={authenticationStyles()}>
        <section className={authenticationFormContainerStyles()}>
          <h1 className={authenticationLogoContainerStyles(theme.logo)}>Alphamart</h1>
          <form className={authenticationFormStyles()} onSubmit={handleSubmit}>
            <h2 className={authenticationHeaderStyle()}>
              <TypedFormattedMessage id="SetPassword.Header.SetPassword" />
            </h2>
            <section className={authenticationFieldTextContainer()}>
              <ErrorMessage standalone show={Boolean(errors.email)}>
                {errors.email}
              </ErrorMessage>
              <FieldInputRaw
                name="email"
                placeholder={intl.formatMessage({ id: 'SetPassword.Label.Email' })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                data-cy="set-password-input-email"
                disabled
              />
            </section>
            <section className={authenticationFieldTextContainer()}>
              <ErrorMessage standalone show={!!errors.password && touched.password}>
                {errors.password}
              </ErrorMessage>
              <FieldInputRaw
                name="password"
                type="password"
                placeholder={intl.formatMessage({ id: 'SetPassword.Label.Password' })}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                value={values.password}
                data-cy="set-password-input-password"
              />
            </section>
            <section className={authenticationFieldTextContainer()}>
              <ErrorMessage standalone show={!!errors.repeatPassword && touched.repeatPassword}>
                {errors.repeatPassword}
              </ErrorMessage>
              <FieldInputRaw
                name="repeatPassword"
                type="password"
                placeholder={intl.formatMessage({ id: 'SetPassword.Label.RepeatPassword' })}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.repeatPassword}
                value={values.repeatPassword}
                data-cy="set-password-input-repeat-password"
              />
            </section>
            <section className={authenticationFieldTextContainer()}>
              <ErrorMessage
                standalone
                show={!!errors.termsAndConditionsConsent && touched.termsAndConditionsConsent}
              >
                {errors.termsAndConditionsConsent}
              </ErrorMessage>
              <FieldCheckboxRaw
                name="termsAndConditionsConsent"
                onChange={handleChange}
                onClick={handleCheckboxClick}
                onBlur={handleBlur}
                checked={values.termsAndConditionsConsent}
                disabled={!isDocumentRead}
                size="28px"
                data-cy="set-password-checkbox-accept-terms-and-conditions"
              >
                <TypedFormattedMessage
                  id="SetPassword.TermsAndConditions.IAgreeToThe"
                  values={{
                    link: (
                      <TextButton onClick={toggleModal}>
                        <TypedFormattedMessage id="SetPassword.TermsAndConditions.TermsAndConditions" />
                      </TextButton>
                    ),
                  }}
                />
              </FieldCheckboxRaw>
            </section>
            <AuthenticationFormButtonsStyled>
              <Link to="/login">
                <TypedFormattedMessage id="SetPassword.Button.Cancel" />
              </Link>
              <AppButton
                disabled={!(isValid && dirty) || isSubmitting || tokenExpired || !position}
                className={css`
                  margin: 0 0 0 auto;
                `}
                type="submit"
                data-cy="set-password-button-login"
              >
                <TypedFormattedMessage id="SetPassword.Button.Submit" />
              </AppButton>
            </AuthenticationFormButtonsStyled>
          </form>
        </section>
        <div className={authenticationPhotoStyles()} />
      </div>
      <TermsAndConditionsModal
        showModal={isModalOpen}
        toggleModal={toggleModal}
        setDocumentRead={setDocumentRead}
      />
    </>
  );
}

function SetPassword(): React.ReactElement {
  return (
    <AlphamartIntlProvider messages={messages}>
      <SetPasswordPage />
    </AlphamartIntlProvider>
  );
}

export default SetPassword;
