import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import useDeepCompareEffect from 'use-deep-compare-effect';

import filterIcon from 'assets/images/icons/filter.svg';
import {
  AlphamartIntlProvider,
  withAlphamartIntlProvider,
} from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { HedgePricesGroup } from 'components/shared/HedgePricesGroup/HedgePricesGroup';
import { DataList, ListActions, useGetRowId } from 'components/shared/List';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { resolveHedgeStatus } from 'helpers/hedges/resolveHedgeStatus';
import { FilterableModules, PERMISSIONS } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import {
  useAlphamartNavigate,
  useHedgeEditActions,
  useMediaQuery,
  useWindowWidth,
} from 'shared/hooks';
import { HedgeListItem, ItemAction, Status } from 'shared/types';
import { replaceEmpty } from 'shared/utils/replaceEmpty';
import { clearHedgesAction, fetchHedges } from 'store/hedgesSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { HedgesFilters } from './HedgesFilters';
import { RequestHedgePriceModal } from './RequestHedgePriceModal';
import { AssignHedgeModal } from '../AssignHedge/AssignHedgeModal';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const HedgesListComponent = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const intl = useTypedIntl();
  const hedges = useAppSelector(state => state.hedges);
  const listComponent = useAppSelector(state => state.listComponent);
  const [isRequestHedgePriceModalOpen, setIsRequestHedgePriceModalOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { actionsPending, handleUpdate, handleUnassign, handleRemove, handleAssign } =
    useHedgeEditActions();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.HEDGES]);
  const windowWidth = useWindowWidth();
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isXxl = useMediaQuery(MEDIA_QUERY.XXL);

  useDeepCompareEffect(() => {
    if (!actionsPending) {
      dispatch(fetchHedges(listComponent.pageIndex + 1, listComponent.pageSize, savedFilters.data));
    }

    return () => {
      dispatch(clearHedgesAction());
    };
  }, [listComponent.pageIndex, listComponent.pageSize, savedFilters?.data, actionsPending]);

  const hedgesList = useMemo(
    () =>
      hedges.list.map(h => ({
        color: resolveStatusColor(resolveHedgeStatus(h)),
        ...h,
      })),
    [isMd, windowWidth, hedges.list],
  );

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const createHedge = () => navigate('/hedges/create');
  const handleRowClick = (item: HedgeListItem) => {
    navigate(`/hedges/${item.id}`);
  };

  const actions: ItemAction[] = [
    {
      label: <FormattedMessage id="Global.Update" />,
      onClick: handleUpdate,
      dataCy: item => `update-${item.id}`,
      visible: authorize(PERMISSIONS.HEDGES.UPDATE),
    },
    {
      label: <FormattedMessage id="Global.Unassign" />,
      onClick: handleUnassign,
      dataCy: item => `unassign-${item.id}`,
      visible: item => authorize(PERMISSIONS.HEDGES.UNASSIGN) && !!item.relatedUsersCount,
    },
    {
      label: <FormattedMessage id="Global.Remove" />,
      onClick: handleRemove,
      dataCy: item => `remove-${item.id}`,
      visible: authorize(PERMISSIONS.HEDGES.REMOVE),
    },
    {
      label: <FormattedMessage id="HedgeAssign.Action.Name" />,
      onClick: hedge =>
        handleAssign(hedge, () =>
          dispatch(
            fetchHedges(listComponent.pageIndex + 1, listComponent.pageSize, savedFilters.data),
          ),
        ),
      dataCy: item => `assign-${item.id}`,
      visible: item =>
        authorize(PERMISSIONS.HEDGES.ASSIGN.ACTION) && resolveHedgeStatus(item) === Status.ACTIVE,
    },
  ];

  const canSeeHedgePrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);
  const handleRequestHedgePricesClick = () => setIsRequestHedgePriceModalOpen(true);
  const handleRequestHedgePricesCancel = () => setIsRequestHedgePriceModalOpen(false);
  const onFiltersToggle = () => setFiltersOpen(!filtersOpen);

  const onFiltersChanged = newFilters => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.HEDGES,
        values: newFilters,
      }),
    );
    setPageIndex(0);
  };

  const columns: ColumnDef<HedgeListItem>[] = [
    {
      id: 'name',
      header: () => FormattedMessage({ id: 'HedgesList.TableHeader.Name' }),
      cell: ({ row }) => row.original.name,
    },
    {
      id: 'parentCompany',
      header: () => FormattedMessage({ id: 'HedgesList.TableHeader.ParentCompany' }),
      cell: ({ row }) => row.original.parentCompany?.name,
    },
    {
      id: 'relatedCompanies',
      header: () => FormattedMessage({ id: 'HedgesList.TableHeader.RelatedCompanies' }),
      cell: ({ row }) => row.original.relatedCompanies?.map(({ name }) => name)?.join(', '),
    },
    {
      id: 'relatedLot',
      header: () => FormattedMessage({ id: 'HedgesList.TableHeader.RelatedLot' }),
      cell: ({ row }) => replaceEmpty(row.original.relatedLot),
    },
    {
      id: 'hedgePrices',
      header: () => FormattedMessage({ id: 'HedgesList.TableHeader.HedgePrices' }),
      cell: ({ row }) => <HedgePricesGroup hedge={row.original} />,
    },
    {
      id: 'placedAt',
      header: () => FormattedMessage({ id: 'HedgesList.TableHeader.PlacedAt' }),
      cell: ({ row }) => formatDateWithTime(row.original.placedAt),
    },
    {
      id: 'expiresAt',
      header: () => FormattedMessage({ id: 'HedgesList.TableHeader.Expiration' }),
      cell: ({ row }) => formatDateWithTime(row.original.expiresAt),
    },
    {
      id: 'actions',
      header: isMd ? intl.formatMessage({ id: 'Global.Actions' }) : '',
      cell: ({ row }) => <ListActions actions={actions} item={row.original} />,
      meta: {
        widthPx: 90,
      },
    },
  ];

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data: hedgesList,
    getRowId: useGetRowId(),
    pageCount: Math.ceil(hedges.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        parentCompany: authorize(PERMISSIONS.HEDGES.LIST_PARENT_COMPANY) && isXxl,
        relatedCompanies: authorize(PERMISSIONS.HEDGES.LIST_PARENT_COMPANY) && isMd,
        relatedLot: isXxl,
        placedAt: isMd,
        expiresAt: isMd,
        hedgePrices: canSeeHedgePrices,
        actions:
          authorize(PERMISSIONS.HEDGES.UPDATE) ||
          authorize(PERMISSIONS.HEDGES.REMOVE) ||
          authorize(PERMISSIONS.HEDGES.UNASSIGN) ||
          authorize(PERMISSIONS.HEDGES.ASSIGN.ACTION),
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      columnSizing: {
        status: 230,
        name: 200,
        parentCompany: 200,
        relatedCompanies: 200,
        relatedLot: 150,
        hedgePrices: 200,
        applicableOn: 300,
      },
    },
  });

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelTemplate>
        <PanelHeader title={<FormattedMessage id="HedgesList.Header" />}>
          <ProtectedArea permission={PERMISSIONS.HEDGES.CREATE}>
            <AppButton type="button" onClick={createHedge}>
              <FormattedMessage id="Global.Create" />
            </AppButton>
          </ProtectedArea>
          <ProtectedArea permission={PERMISSIONS.HEDGES.REQUEST_HEDGE}>
            <>
              <AppButton
                type="button"
                onClick={handleRequestHedgePricesClick}
                data-cy="request-hedge-open-modal"
              >
                <FormattedMessage id="HedgesList.Header.RequestHedgePrices" />
              </AppButton>
              <RequestHedgePriceModal
                isOpen={isRequestHedgePriceModalOpen}
                onClose={handleRequestHedgePricesCancel}
              />
            </>
          </ProtectedArea>
        </PanelHeader>
        <PanelContent>
          <PanelContentMain>
            <SearchBar
              searchInputVisible={false}
              onFiltersToggle={onFiltersToggle}
              savedFilters={savedFilters}
            />
            <DataList table={table} isLoading={hedges.isPending} onRowClicked={handleRowClick} />
          </PanelContentMain>
          <PanelContentSidebar
            header={<FormattedMessage id="Global.Filters" />}
            headerIcon={filterIcon}
            open={filtersOpen}
            onSidebarClosed={onFiltersToggle}
          >
            <HedgesFilters onFiltersChanged={onFiltersChanged} onFiltersApplied={onFiltersToggle} />
          </PanelContentSidebar>
        </PanelContent>
      </PanelTemplate>
      <Outlet />
      <AssignHedgeModal />
    </AlphamartIntlProvider>
  );
};

export const HedgesList = withAlphamartIntlProvider(HedgesListComponent, messages);
