import { css } from '@emotion/css';
import styled from '@emotion/styled';

import loginPhoto from 'assets/images/login-photo.png';
import { MEDIA_QUERY, Theme } from 'theme';

const authenticationStyles = (): string => css`
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

const authenticationFormContainerStyles = (): string => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
`;

const authenticationLogoContainerStyles = (logo?: string): string => css`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #292929;
  background: url(${logo}) no-repeat center;
  background-size: auto 60px;
  font-size: 0;
`;

const authenticationHeaderStyle = (): string => css`
  margin: 0 0 32px 0;
  font-weight: normal;
  font-size: 18px;
`;

const authenticationFormStyles = (): string => css`
  width: 310px;
  padding: 0 10px;
`;

const AuthenticationFormButtonsStyled = styled.div<{ theme?: Theme }>`
  display: flex;
  align-items: center;
  padding: 16px 0 0 0;

  a {
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px dotted ${({ theme }) => theme.mainColor};
    padding: 0 0 1px 0;
  }
`;

const authenticationPhotoStyles = (): string => css`
  display: none;

  @media ${MEDIA_QUERY.XL} {
    display: block;
    width: 55%;
    background: url(${loginPhoto}) no-repeat center center;
    background-size: cover;
    opacity: 0.1;
  }
`;

const authenticationFieldTextContainer = (): string => css`
  margin: 0 0 16px;
`;

const LoginFormButtonsStyled = styled.div<{ theme?: Theme; hasResendButton?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ hasResendButton }) => (hasResendButton ? 'column' : 'row')};

  a {
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px dotted ${({ theme }) => theme.mainColor};
    padding: 0 0 1px 0;
  }
`;

const LoginFormButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 0 0;
`;

export {
  authenticationFieldTextContainer,
  AuthenticationFormButtonsStyled,
  authenticationFormContainerStyles,
  authenticationFormStyles,
  authenticationHeaderStyle,
  authenticationLogoContainerStyles,
  authenticationPhotoStyles,
  authenticationStyles,
  LoginFormButtonsContainer,
  LoginFormButtonsStyled,
};
