import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { FrequentFlyerListItem } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum FrequentFlyersKeys {
  GetFrequentFlyers = 'GetFrequentFlyers',
}

type GetFrequentFlyersParams = {
  page?: number;
  pageSize?: number;
  distanceInMiles: number;
};

export type FrequentFlyersResponse = {
  data: FrequentFlyerListItem[];
  count: number;
};
export type UseFrequentFlyersResponse = UseQueryResult<FrequentFlyersResponse | undefined>;

export const useGetFrequentFlyers = (
  params: GetFrequentFlyersParams,
  options: UseQueryOptions<FrequentFlyersResponse | undefined> = {},
): UseFrequentFlyersResponse =>
  useQuery<FrequentFlyersResponse | undefined>(
    [FrequentFlyersKeys.GetFrequentFlyers, params],
    async ({ signal }) => {
      if (params.distanceInMiles === undefined) return;

      const response = await getHttpClient().get<FrequentFlyersResponse>(
        `${apiHostname}/api/locations/frequent-flyers`,
        {
          params,
          signal,
        },
      );
      return response.data;
    },
    options,
  );
