import { NavLink } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { layers, MEDIA_QUERY, Theme } from 'theme';

const styledConfig = { shouldForwardProp: prop => isPropValid(prop) };

export const StyledNavList = styled.nav<{ theme?: Theme; menuOpened: boolean }>`
  min-height: calc(100vh - 195px);
  min-height: calc(100dvh - 195px);
  padding: 18px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: 500 14px ${({ theme }) => theme.fonts.montserrat};

  @media ${MEDIA_QUERY.MAX_MD} {
    min-height: calc(100vh - 64px);
    min-height: calc(100dvh - 64px);
    justify-content: space-between;
    padding-bottom: 0;
    ${({ menuOpened }) => !menuOpened && 'display: none;'}
  }
`;

export const NavRoot = styled.ul<{ theme?: Theme }>`
  overflow-y: auto;
  overflow-x: hidden;
  gap: 10px 0;
  margin-bottom: 12px !important;

  &,
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const rootLink = css`
  padding: 10px 0 10px 40px;
  @media ${MEDIA_QUERY.MAX_MD} {
    padding-left: 48px;
  }
  &.active::after {
    opacity: 1;
  }
`;
const rootLinkCompact = (theme: Theme) => css`
  width: 42px;
  height: 42px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border-radius: 8px;

  span:last-of-type {
    display: none;
  }

  &.active {
    background-color: ${theme.colors.darkGray};
  }
`;
export const RootLink = styled(NavLink, styledConfig)<{
  theme?: Theme;
  compact: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0 32px;
  position: relative;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.greySnow};
  span:first-of-type {
    mix-blend-mode: screen;
  }
  &:focus {
    outline-color: ${({ theme }) => theme.mainColor};
  }
  &.active {
    color: ${({ theme }) => theme.fontColor};
    span:first-of-type {
      background-color: ${({ theme }) => theme.fontColor};
    }
  }
  &:not(.active) + div {
    display: none;
  }
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    width: 3px;
    background-color: ${({ theme }) => theme.mainColor};
    opacity: 0;
    transition: opacity 0.3s;
  }
  ${({ compact, theme }) => (compact ? rootLinkCompact(theme) : rootLink)}
`;

const leafsContainer = (theme: Theme) => css`
  & > p {
    display: none;
  }
  & > ul {
    margin: 6px 0 0 52px;
    padding: 0 19px 0 28px;
    @media ${MEDIA_QUERY.MAX_MD} {
      margin-left: 60px;
    }
    position: relative;
    gap: 10px 0;
    &:before {
      position: absolute;
      content: '';
      width: 1px;
      top: 10px;
      bottom: 10px;
      left: 0;
      background-color: ${theme.colors.lighterGray};
    }
  }
`;
const leafsContainerCompact = (theme: Theme, visible: boolean) => css`
  border-radius: 8px;
  background-color: ${theme.colors.darkGray};
  z-index: ${layers.navListLeaf};
  min-width: 140px;
  & > p {
    margin: 0;
    padding: 16px 24px;
    border-bottom: 1px solid ${theme.colors.lighterGray};
  }
  & > ul {
    padding: 12px;
    margin: 0;
    position: relative;
    gap: 10px 0;
  }
  ${!visible && 'display: none;'}
`;
export const LeafsContainer = styled('div', styledConfig)<{
  theme?: Theme;
  compact: boolean;
  visible: boolean;
}>`
  position: relative;
  ${({ compact, visible, theme }) =>
    compact ? leafsContainerCompact(theme, visible) : leafsContainer(theme)}
`;

export const LeafNav = styled(NavLink)<{ theme?: Theme }>`
  display: block;
  border-radius: 5px;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.greySnow};
  &.active {
    color: ${({ theme }) => theme.fontColor};
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;
