import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AdjustmentRates, AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface AdjustmentState extends GenericStoreSlice {
  adjustmentRates: AdjustmentRates;
}

const metalsOrder = ['platinum', 'palladium', 'rhodium'];
const adjustmentsSlice = createSlice<AdjustmentState, GenericStoreReducer<AdjustmentState>>({
  name: 'adjustments',
  initialState: {
    adjustmentRates: {
      list: [],
      createdAt: undefined,
      source: 'Metals-API',
    },
    isPending: false,
    error: undefined,
  },
  reducers: getGenericReducers<AdjustmentState>(payload =>
    payload
      ? {
          adjustmentRates: {
            list:
              payload.data?.list?.sort(
                (firstAdjustment, secondAdjustment) =>
                  metalsOrder.indexOf(firstAdjustment.metal) -
                  metalsOrder.indexOf(secondAdjustment.metal),
              ) ?? [],
            createdAt: payload.data?.createdAt,
            source: 'Metals-API',
          },
        }
      : {
          adjustmentRates: {
            list: [],
            createdAt: null,
            source: 'Metals-API',
          },
        },
  ),
});

export const {
  success: fetchAdjustmentsSuccessAction,
  failure: fetchAdjustmentsFailureAction,
  pending: fetchAdjustmentsAction,
} = adjustmentsSlice.actions;

export default adjustmentsSlice.reducer;

export const fetchAdjustments =
  (companyId: string | number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await dispatch(fetchAdjustmentsAction());
      const adjustments = await httpClient.get(
        `${apiHostname}/api/companies/${companyId}/metal-prices`,
      );
      dispatch(fetchAdjustmentsSuccessAction(adjustments));
    } catch (error) {
      dispatch(
        fetchAdjustmentsFailureAction((error as AlphamartHttpError)?.response?.data.message),
      );
      return Promise.reject(error);
    }
  };
