import React from 'react';
import styled from '@emotion/styled';

import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { SHARED } from 'shared/constants';
import { Hedge } from 'shared/types';

const PriceContainer = styled.div`
  word-break: keep-all;
`;

export function HedgePricesGroup({
  hedge,
}: {
  hedge: Pick<Hedge, 'ptPriceCust' | 'pdPriceCust' | 'rhPriceCust'>;
}): React.ReactElement {
  const priceText = (price: number | null) => (price ? formatCurrency(price) : SHARED.LONG_DASH);

  return (
    <PriceContainer>
      {`Pt:${SHARED.NON_BREAKING_SPACE}${priceText(hedge.ptPriceCust)}`}
      <br />
      {`Pd:${SHARED.NON_BREAKING_SPACE}${priceText(hedge.pdPriceCust)}`}
      <br />
      {`Rh:${SHARED.NON_BREAKING_SPACE}${priceText(hedge.rhPriceCust)}`}
    </PriceContainer>
  );
}
