import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { useWatchPosition } from './hooks/useWatchPosition';

const StyledIframe = styled.iframe`
  display: none;
`;

export default function GeolocationIframe(): React.ReactElement {
  const ref = useRef<HTMLIFrameElement>(null);
  const { framePath } = useWatchPosition(ref);

  return (
    <StyledIframe ref={ref} src={framePath} id="geolocation-iframe" title="Geolocation iframe" />
  );
}
