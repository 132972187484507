import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { components, GroupBase, MultiValueProps } from 'react-select';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Identifier } from 'dnd-core';
import { isArray } from 'lodash';

import { DndTypes } from 'shared/constants';
import { moveArrayItem } from 'shared/helpers';
import {
  FieldSelectBase,
  FieldSelectOnChangeMulti,
  FieldSelectOption,
  Props as FieldSelectProps,
} from './FieldSelect';
import { DraggableProvider } from '../../DraggableProvider/DraggableProvider';
import { SortableItem } from '../../SortableList/SortableList';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

const MultiValue =
  (onSortEnd: (oldIndex: number, newIndex: number) => void) =>
  (props: MultiValueProps<FieldSelectOption, false, GroupBase<FieldSelectOption>>) => {
    const type = DndTypes.SELECT;
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<SortableItem, void, { handlerId: Identifier | null }>({
      accept: type,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop(dragItem) {
        const dragIndex = dragItem.index;
        const hoverIndex = props.index;
        if (!ref.current || dragIndex === hoverIndex) return;
        // Time to actually perform the action
        onSortEnd(dragIndex, hoverIndex);
      },
    });

    const [, drag] = useDrag({
      type,
      item: () => props,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    const innerProps = {
      ...props.innerProps,
      onMouseDown: (e: React.MouseEvent) => e.stopPropagation(),
      ref,
      'data-handler-id': handlerId,
      draggable: true,
    };

    return <components.MultiValue {...props} innerProps={innerProps} />;
  };

function FieldSortableSelectLayout(props: FieldSelectProps): React.ReactElement {
  const reorder = (oldIndex: number, newIndex: number) => {
    if (!isArray(props.value)) return;
    const newValue = moveArrayItem<string | number>(props.value, oldIndex, newIndex).map(
      v => ({ value: v } as FieldSelectOption),
    );
    (props.onChange as FieldSelectOnChangeMulti)?.(newValue);
  };

  return (
    <DraggableProvider>
      <FieldSelectBase {...props} components={{ MultiValue: MultiValue(reorder) }} />
    </DraggableProvider>
  );
}

const FieldSortableSelect = withFieldWrapper<FieldSelectProps>(props => (
  <FieldSortableSelectLayout {...props} />
));

export { FieldSortableSelect };
