import { VehicleFormShape } from 'components/views/Vehicles/VehicleForm';
import { apiHostname } from 'shared/constants';
import { ExternalPhoto, parseVehicle } from 'shared/parsers/parseVehicle';
import { AlphamartHttpError } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

export const createVehicle =
  (values: VehicleFormShape): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const fileIds: { data: string | ExternalPhoto; id?: number }[] = await Promise.all(
        values.photos.map(photo => {
          if ('url' in photo) {
            return httpClient.post<ExternalPhoto>(
              `${apiHostname}/api/upload/vehicle-image/external`,
              {
                url: photo.url,
              },
            );
          }

          const formData = new FormData();
          formData.append('image', photo.file!);
          return httpClient.post<string>(`${apiHostname}/api/upload/vehicle-image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );
      const vehicleDto = parseVehicle(values, fileIds);
      await httpClient.post(`${apiHostname}/api/vehicles`, vehicleDto);
    } catch (error) {
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };
