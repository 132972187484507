import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Props as GridProps } from 'recharts/types/cartesian/CartesianGrid';
import { ContentType } from 'recharts/types/component/Tooltip';
import { AxisDomain } from 'recharts/types/util/types';

import { UNITS } from 'shared/constants';
import { useLanguage, useMediaQuery } from 'shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';

const ChartContainer = styled.div`
  grid-area: chart;
  position: relative;
  height: 100%;
  overflow: hidden;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .recharts-cartesian-grid-horizontal line {
    stroke: ${theme.colors.charcoal};

    @media ${MEDIA_QUERY.MAX_MD} {
      opacity: 0;
    }
  }

  .yAxis {
    line {
      opacity: 0;
    }

    @media ${MEDIA_QUERY.MAX_MD} {
      opacity: 0;
    }
  }
`;

const YAxisLabel = ({
  x = 0,
  y = 0,
  payload,
}: {
  x?: number;
  y?: number;
  payload?: { value: number | string };
}): ReactElement<SVGElement> => {
  const language = useLanguage();

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={-24} dy={4} textAnchor="end" fill={theme.colors.alphaGray}>
        {payload && +payload.value > 1000
          ? `${Math.floor(+payload.value / 100) / 10}${UNITS[`kilo-${language}`]}`
          : payload?.value}
      </text>
    </g>
  );
};

const XAxisLabel = ({
  x = 0,
  y = 0,
  payload,
  generateXLabel,
}: {
  x?: number;
  y?: number;
  payload?: { value: number | string };
  generateXLabel(value: string | number): string;
}): ReactElement<SVGElement> => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={20} textAnchor="end" fill={theme.colors.alphaGray}>
      {payload && generateXLabel(payload.value)}
    </text>
  </g>
);

type Props = {
  data: Record<string, number>[];
  xAxisTicks?: (number | string)[];
  xAxisDataKey: string;
  xAxisDomain: AxisDomain;
  yAxisDataKeys: string[];
  yAxisDomain: AxisDomain;
  lineColors: string[];
  generateXLabel: (val: string | number) => string;
  valuesFormatter: (value: string | number, label: string, props: unknown) => [string, string];
  labelFormatter: (val: string | number, isTooltip?: boolean) => string;
  width?: number;
  height?: number;
  fillOpacity?: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  customTooltip?: ContentType<any, any>;
  tooltipPosition?: {
    x?: number;
    y?: number;
  };
  gridProps?: GridProps;
};

function LinearChart({
  data,
  xAxisDataKey,
  xAxisTicks,
  xAxisDomain,
  yAxisDataKeys,
  yAxisDomain,
  lineColors,
  generateXLabel,
  valuesFormatter,
  labelFormatter,
  width = 100,
  height = 100,
  fillOpacity = 1,
  customTooltip,
  tooltipPosition,
  gridProps,
}: Props): React.ReactElement {
  const isDesktop = useMediaQuery(MEDIA_QUERY.MD);

  return (
    <ChartContainer>
      <div>
        <ResponsiveContainer width={`${width}%`} height={`${height}%`}>
          <AreaChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: isDesktop ? 30 : 6,
              left: isDesktop ? 30 : 6,
              bottom: 5,
            }}
          >
            <defs>
              {yAxisDataKeys.map((key, i) => (
                <linearGradient key={key} id={`color-${key}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={lineColors[i]} stopOpacity={0.6} />
                  <stop offset="90%" stopColor={lineColors[i]} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            {yAxisDataKeys.map((key, i) => (
              <Area
                key={key}
                type="monotone"
                dataKey={key}
                stroke={lineColors[i]}
                strokeWidth={3}
                fillOpacity={fillOpacity}
                fill={`url(#color-${key})`}
              />
            ))}
            {/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */}
            <CartesianGrid vertical={false} {...(gridProps as any)} />
            <XAxis
              dataKey={xAxisDataKey}
              ticks={xAxisTicks}
              tick={props => <XAxisLabel generateXLabel={generateXLabel} {...props} />}
              type="number"
              domain={xAxisDomain}
              interval={xAxisTicks && xAxisTicks.length > 13 ? 3 : 0}
              hide={!data?.length || !isDesktop}
            />
            <YAxis tick={<YAxisLabel />} domain={yAxisDomain} hide={!data?.length || !isDesktop} />
            <Tooltip
              formatter={valuesFormatter}
              labelFormatter={(val: string) => labelFormatter(val, true)}
              contentStyle={{
                backgroundColor: theme.colors.mineShaftLighter,
                borderRadius: 8,
                border: 'none',
              }}
              content={customTooltip}
              position={tooltipPosition}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </ChartContainer>
  );
}

export { LinearChart };
