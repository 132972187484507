import { isEqual } from 'lodash';

import { AccessFlag, ALLOW_ALL, UserRoles } from 'shared/constants';
import { User } from 'shared/types';
import { useCurrentUser } from '../hooks';

export type AllowedFor = (AccessFlag | UserRoles)[] | typeof ALLOW_ALL;

type AuthMethod = (allowedFor: AllowedFor, options?: Options) => boolean;
type NamedPermissionCheck = {
  key: keyof User;
  value: boolean;
};
type Options = {
  namedPermissions: NamedPermissionCheck[];
};

const useAuthorization = (): AuthMethod => {
  const user = useCurrentUser();

  return (allowedFor, options) => {
    if (allowedFor === ALLOW_ALL) return true;

    const hasRole = !!user?.roles?.some(role => allowedFor.includes(role.name));
    const hasAccess = !!user?.accessFlags?.some(access => allowedFor.includes(access));
    const namedPermissionsAccess = checkNamedPermissions(options?.namedPermissions, user);

    return (hasRole || hasAccess) && namedPermissionsAccess;
  };
};

const checkNamedPermissions = (
  namedPermissions: NamedPermissionCheck[] | undefined,
  user: User,
) => {
  let namedPermissionsAccess = true;
  if (Array.isArray(namedPermissions)) {
    for (let i = 0; i < namedPermissions.length; i++) {
      const permission = isEqual(user[namedPermissions[i].key], namedPermissions[i].value);
      if (!permission) {
        namedPermissionsAccess = false;
        break;
      }
    }
  }
  return namedPermissionsAccess;
};

export { useAuthorization };
