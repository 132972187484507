import React, { useCallback } from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { ErrorCode, PERMISSIONS, UserRoles } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate, useCurrentUser } from 'shared/hooks';
import { AlphamartErrorData, ManageUserAccesses, UserFormData } from 'shared/types';
import { setTwoFactorAuthentication } from 'store/auth';
import { createUser } from 'store/createUser';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useProcessMarginWarnings } from '../hooks/useProcessMarginWarnings';
import { messages, useTypedIntl } from '../locale/messages';
import { UserForm } from '../UserForm/UserForm';

const CreateUserComponent = (): React.ReactElement => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const allowedToChangeTermsAdjustment = authorize(PERMISSIONS.USERS.UPDATE_TERMS_ADJUSTMENTS);
  const allowedToChangeDisplayHedgePrices = authorize(
    PERMISSIONS.USERS.SHOW_DISPLAY_HEDGE_PRICES_FIELD,
  );
  const currentUser = useCurrentUser();
  const config = useAppSelector(state => state.config);
  const isCreateUserPending = useAppSelector(state => state.createUser.isPending);
  const isRegularAdmin = currentUser.roles.some(r => r.name === UserRoles.ADMIN);
  const processWarnings = useProcessMarginWarnings();

  const handleSuccess = () => {
    dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'UserForm.Message.Success' })));
    navigate('/users');
  };

  const handlePhoneDuplication = async (
    values: UserFormData,
    parseUserParams: ManageUserAccesses,
    error: AlphamartErrorData,
  ): Promise<unknown> =>
    dispatch(
      showModal({
        message: intl.formatMessage(
          { id: 'UserForm.Modal.Create.ConfirmDuplicateMobilePhone' },
          error.errorData,
        ),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          await dispatch(createUser(values, parseUserParams, true));
          await handleSuccess();
          dispatch(hideModal());
        },
      }),
    );

  const handleEmailOrPhoneError = async (error: AlphamartErrorData): Promise<void> => {
    const isPrivileged = authorize(PERMISSIONS.USERS.KNOW_ABOUT_OTHER_COMPANY_USERS);
    const errorCode = error?.errorCode as
      | ErrorCode.EMAIL_ALREADY_USED
      | ErrorCode.MOBILE_PHONE_ALREADY_USED;
    dispatch(
      snackBarPushFailure(
        intl.formatMessage(
          { id: `UserForm.Errors.${errorCode}${isPrivileged ? '' : '.ADMIN'}` },
          error?.errorData,
        ),
      ),
    );
  };

  const handleLimitReached = async (error: AlphamartErrorData): Promise<void> => {
    const errorData = isRegularAdmin
      ? {
          maxAssayUsers: error?.errorData.maxAssayUsers,
          mobilePhonePrefix: config?.company?.contactPerson?.mobilePhonePrefix,
          mobilePhone: config?.company?.contactPerson?.mobilePhone,
          email: config?.company?.contactPerson?.email,
        }
      : error?.errorData;
    dispatch(
      snackBarPushFailure(
        intl.formatMessage(
          { id: `UserForm.Errors.USERS_LIMIT_REACHED${isRegularAdmin ? '.ADMIN' : ''}` },
          errorData,
        ),
      ),
    );
  };

  const onSubmit = useCallback(
    async (values: UserFormData) => {
      const parseUserParams = {
        allowedToChangeTermsAdjustment,
        allowedToChangeDisplayHedgePrices,
      };

      try {
        await dispatch(createUser(values, parseUserParams));
        await handleSuccess();
      } catch (e) {
        const error = e as AlphamartErrorData;
        const isMobilePhoneDuplicatedError =
          authorize(PERMISSIONS.USERS.CREATE_UPDATE_WITH_DUPLICATED_MOBILE_PHONE) &&
          error?.errorCode === ErrorCode.MOBILE_PHONE_ALREADY_USED_RETRY_ALLOWED;
        const isEmailOrMobileError = [
          ErrorCode.EMAIL_ALREADY_USED,
          ErrorCode.MOBILE_PHONE_ALREADY_USED,
          ErrorCode.EMAIL_ALREADY_USED_IN_OTHER_COMPANY,
        ].includes(error?.errorCode);
        const isUserLimitReachedError = error?.errorCode === ErrorCode.USERS_LIMIT_REACHED;
        const twoFactorAuthenticationError =
          error?.errorCode === ErrorCode.TWO_FACTOR_AUTHENTICATION_VERIFICATION_FAILED;

        if (isMobilePhoneDuplicatedError) {
          await handlePhoneDuplication(values, parseUserParams, error);
        } else if (isEmailOrMobileError) {
          await handleEmailOrPhoneError(error);
        } else if (isUserLimitReachedError) {
          await handleLimitReached(error);
        } else if (twoFactorAuthenticationError) {
          dispatch(
            setTwoFactorAuthentication({
              isRequired: true,
              userAccessFlags: values.roles.userAccessFlags,
              callback: () => onSubmit(values),
            }),
          );
        } else {
          dispatch(
            snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
          );
        }
      }
    },
    [dispatch, config?.company?.contactPerson, intl],
  );

  return (
    <LoadableContent drawContent loading={isCreateUserPending} mode={LoadableContentModes.FULL}>
      <UserForm onSubmit={processWarnings(onSubmit)} />
    </LoadableContent>
  );
};

export const CreateUser = withAlphamartIntlProvider(CreateUserComponent, messages);
