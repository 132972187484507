import React, { useCallback, useState } from 'react';

import { DetailsSection } from 'components/shared/Details';
import { FieldRadioButtonLayout as FieldRadioButton } from 'components/shared/Fields/FieldRadioButton/FieldRadioButton';
import { Language, Languages } from 'shared/constants/languages';
import { useLanguage } from 'shared/hooks/useLanguage';
import { fetchUser } from 'store/auth';
import { changeUserLanguage } from 'store/changeUserLanguageSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useTypedIntl } from './locale/messages';

export function ChangeLanguageActions(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const [disabled, setDisabled] = useState(false);

  const changeLanguage = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const successMessage = intl.formatMessage({ id: 'UserProfile.ChangeLanguage.Success' });
      const failureMessage = intl.formatMessage({ id: 'UserProfile.ChangeLanguage.Error' });

      try {
        setDisabled(true);
        await dispatch(changeUserLanguage(e.target.value as Language));
        await dispatch(fetchUser(true));
        dispatch(snackBarPushSuccess(successMessage));
      } catch {
        dispatch(snackBarPushFailure(failureMessage));
      } finally {
        setDisabled(false);
      }
    },
    [language],
  );

  return (
    <DetailsSection
      id="changeLanguageSection"
      title={intl.formatMessage({ id: 'UserProfile.Language' })}
      template={['changeLanguage changeLanguage changeLanguage']}
      fields={[
        {
          name: 'changeLanguage',
          label: intl.formatMessage({ id: 'UserProfile.ChangeLanguage' }),
          value: (
            <FieldRadioButton
              value={language}
              name="changeLanguageRadio"
              onChange={changeLanguage}
              dataCy="current-profit-margin-field"
              disabled={disabled}
              options={Object.values(Languages).map(value => ({
                label: intl.formatMessage({ id: `UserProfile.Language.${value}` }),
                value: value as string,
              }))}
            />
          ),
        },
      ]}
    />
  );
}
