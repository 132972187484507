import React from 'react';
import ReactModal from 'react-modal';
import { ThemeProvider } from '@emotion/react';

import { ModalButtonsStyle, ModalMessageStyle } from 'components/shared/Modals.styles';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { useAppSelector } from 'store/shared/hooks';
import { layers, Theme } from 'theme';
import AppButton from './forms/AppButton/AppButton';
import { AlphamartIntlProvider } from './AlphamartIntlProvider';
import { CancelButton } from './Buttons';

export const ReactModalStyleConfig = (theme: Theme): ReactModal.Styles => ({
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: layers.modal,
    WebkitBackdropFilter: 'blur(2px)',
    backdropFilter: 'blur(2px)',
  },
  content: {
    background: theme.modal.background,
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '360px',
    padding: '16px',
    boxShadow:
      'rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0px 9px 6px, rgba(0, 0, 0, 0.23) 0px -1px 4px',
  },
});

function AppModal(): React.ReactElement {
  const theme = useExtendedTheme();
  const { isOpen, message, closeMessage, confirmMessage, onClose, onConfirm } = useAppSelector(
    ({ modal }) => modal,
  );

  return (
    <ThemeProvider theme={theme}>
      <ReactModal isOpen={isOpen} style={ReactModalStyleConfig(theme)}>
        <AlphamartIntlProvider>
          <p className={ModalMessageStyle()}>{message}</p>
          <div className={ModalButtonsStyle()}>
            <CancelButton onClick={() => onClose?.()}>
              {closeMessage || <FormattedMessage id="Global.Cancel" />}
            </CancelButton>
            <AppButton onClick={() => onConfirm?.()} data-cy="confirm-modal">
              {confirmMessage || <FormattedMessage id="Global.Confirm" />}
            </AppButton>
          </div>
        </AlphamartIntlProvider>
      </ReactModal>
    </ThemeProvider>
  );
}

export default AppModal;
