import { css } from '@emotion/css';

import { layers, Theme } from 'theme';
import { SelectStyles } from '../FieldSelect/FieldSelect';
import { FieldSelectStyled } from '../FieldSelect/FieldSelect.styles';

export const rangeContainerStyles = css`
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const FieldRangeStyled = (
  error: boolean,
  side: 'from' | 'to',
  theme: Theme,
): SelectStyles => ({
  ...FieldSelectStyled(error, false, {}, null, 'bottom'),
  control: (provided, { isDisabled, menuIsOpen }) => ({
    ...provided,
    minHeight: 48,
    border: `1px solid ${error ? theme.colors.fuzzyWuzzyBrown : theme.colors.alphaGray}`,
    borderLeftWidth: side === 'to' ? 0 : '1px',
    borderRadius: side === 'from' ? '20px 0 0 20px' : '0 20px 20px 0',
    padding: '5px 16px 5px 20px',
    backgroundColor: isDisabled ? theme.colors.mineShaftLightest : 'transparent',
    boxShadow: 'none',
    outline: 'none',
    pointerEvents: 'all',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    fontFamily: theme.fonts.lato,

    ...(menuIsOpen
      ? {
          borderBottom: '1px solid transparent',
          borderRadius: side === 'from' ? '20px 0 0 0' : '0 20px 0 0',
          backgroundColor: theme.colors.mineShaft,
        }
      : {}),

    ':hover': {
      borderColor: isDisabled ? theme.colors.alphaGray : theme.colors.alphaGray,
    },
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    borderRadius: '0 0 20px 20px',
    backgroundColor: theme.colors.mineShaft,
    border: `1px solid ${theme.colors.alphaGray}`,
    borderTop: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    marginLeft: side === 'to' ? '-1px' : 0,
    'z-index': layers.selectMenu,
  }),
});
