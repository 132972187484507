import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { Icon } from 'components/shared/Buttons';
import { layers, Theme } from 'theme';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

const InputContainerStyled = styled.div<{
  interactiveSuffix?: boolean;
  error?: boolean;
  theme?: Theme;
}>`
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 21px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.alphaGray};
  font-size: 12px;
  transition: border-color 0.2s ease;
  caret-color: ${({ theme }) => theme.colors.alphaGray};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  position: relative;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colors.alphaGray};
  }

  & + & {
    margin: 8px 0 0 0;
  }

  & > span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: ${({ interactiveSuffix }) => (interactiveSuffix ? 'auto' : '56px')};
    border-radius: 0 21px 21px 0;
    background-color: ${({ theme }) => theme.colors.mineShaftLighter};
    border-left: 1px solid ${({ theme }) => theme.colors.charcoal};
    right: 0;
    overflow: hidden;
    top: 0;

    ${({ interactiveSuffix, theme, error }) =>
      interactiveSuffix
        ? `
      z-index: ${layers.fieldInputAction};
      border: 1px solid ${error ? theme.colors.fuzzyWuzzyBrown : theme.colors.alphaGray};
      `
        : `border-color: ${theme.colors.alphaGray};`}

    &.prefix {
      border-radius: 21px 0 0 21px;
      border-left: none;
      border-right: 1px solid ${({ theme }) => theme.colors.charcoal};
      left: 0;
    }
  }

  ${({ className }) => className}
`;

const InputStyled = styled.input<{
  theme?: Theme;
  error?: unknown;
  capitalize?: boolean;
  suffix?: React.ReactNode;
  withButton?: boolean;
  hasIcon?: boolean;
}>`
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.colors.fuzzyWuzzyBrown : theme.colors.alphaGray)};
  border-radius: 21px;
  background-color: transparent;
  padding: 0 32px;
  padding-left: ${({ prefix }) => (prefix ? '64px' : '32px')};
  padding-right: ${({ suffix, withButton, hasIcon }) =>
    suffix || withButton || hasIcon ? '64px' : '32px'};
  color: ${({ theme }) => theme.colors.alphaGray};
  font-size: 12px;
  transition: border-color 0.2s ease;
  caret-color: ${({ theme }) => theme.colors.alphaGray};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: bold;
  line-height: 15px;
  order: 0;
  z-index: ${layers.fieldInput};
  position: relative;
  ${({ capitalize }) => capitalize && 'text-transform: uppercase;'}

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px ${({ theme }) => theme.background} inset !important;
    -moz-box-shadow: 0 0 0 100px ${({ theme }) => theme.background} inset !important;
    box-shadow: 0 0 0 100px ${({ theme }) => theme.background} inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.alphaGray} !important;
    -moz-text-fill-color: ${({ theme }) => theme.colors.alphaGray} !important;
  }

  &:focus,
  &:hover:not(:disabled) {
    outline: none;
    border-color: ${({ theme }) => theme.colors.alphaGray};
  }

  &:nth-of-type(2) {
    border-radius: 21px 0 0 21px;
    border-right: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  }
`;

const InputRemoveButtonStyled = styled.button<{ theme?: Theme }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${layers.fieldInputRemove};
  order: 1;
  width: 50px;
  background: ${({ theme }) => theme.colors.alphaGray};
  border: none;
  padding: 0 25px 0 12px;
  color: ${({ theme }) => theme.fontColor};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  height: 48px;
  border-radius: 0 21px 21px 0;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.alphaGray};
    border-radius: 0 22px 22px 0;
  }
`;

export interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  capitalize?: boolean;
  error?: unknown;
  className?: string;
  disabled?: boolean;
  name?: string;
  onRemove?: () => void;
  onIconClick?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  // in React.TouchEvent event.touches[0].target is typed as empty interface, but in browser it's not empty and contains element data like .name .etc
  onTouchStart?: (event?: React.TouchEvent<HTMLInputElement>) => void;
  activeInput?: string;
  protection?: {
    hide?: boolean;
    copy?: boolean;
    paste?: boolean;
  };
  maxLength?: number;
  suffix?: React.ReactNode;
  interactiveSuffix?: boolean;
  prefix?: string;
  icon?: string;
  iconProps?: {
    icon?: string;
    color?: string;
    hoverColor?: string;
    pointer?: boolean;
  };
  autoComplete?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  withButton?: boolean;
  rootClassName?: string;
}

const FieldInputLayout = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      maxLength = 524288,
      onRemove,
      error,
      protection = { hide: false, copy: false, paste: false },
      prefix,
      suffix,
      value,
      capitalize,
      icon,
      onIconClick,
      className,
      iconProps = {},
      onChange,
      interactiveSuffix,
      rootClassName,
      ...props
    },
    ref,
  ) => {
    const maskedValue = useMemo(() => {
      if (protection && protection.hide) {
        return value
          .toString()
          .split('')
          .map(() => '•')
          .join('');
      }
      return value;
    }, [protection, value]);

    const onPaste = e => {
      if (protection && protection.paste) {
        e.preventDefault();
      }
    };

    const onCopy = e => {
      if (protection && protection.paste) {
        e.preventDefault();
      }
    };

    const handleChange = e => {
      const inputValue = e.target.value;
      const parsedValue = inputValue.replace(/\s+/g, ' ').trimStart();
      e.target.value = parsedValue;
      onChange(e);
    };

    return (
      <InputContainerStyled
        interactiveSuffix={interactiveSuffix}
        error={!!error}
        className={rootClassName}
      >
        {onRemove ? (
          <InputRemoveButtonStyled type="button" onClick={onRemove}>
            X
          </InputRemoveButtonStyled>
        ) : (
          ''
        )}
        {prefix && <span className="prefix">{prefix}</span>}
        <InputStyled
          maxLength={maxLength}
          onPaste={onPaste}
          onCopy={onCopy}
          value={maskedValue}
          error={error}
          suffix={suffix}
          capitalize={capitalize}
          prefix={prefix}
          hasIcon={!!icon}
          className={className}
          onChange={handleChange}
          ref={ref}
          {...props}
        />
        {suffix && <span>{suffix}</span>}
        {icon && <Icon icon={icon} onClick={onIconClick} {...iconProps} />}
      </InputContainerStyled>
    );
  },
);

const FieldInput = withFieldWrapper<Props>(props => <FieldInputLayout {...props} />);
const FilterInput = withFilterWrapper<Props>(props => (
  <FieldInputLayout {...props} placeholder={props.label ?? ''} />
));

export { FieldInput, FieldInputLayout as FieldInputRaw, FilterInput };
