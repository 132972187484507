import React, { useCallback } from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { ErrorCode } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { AlphamartErrorData, CompanyFormData } from 'shared/types';
import { createCompany } from 'store/createCompanySlice';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { CompanyForm } from '../CompanyForm/CompanyForm';
import { messages, useTypedIntl } from '../locale/messages';

const CreateCompanyComponent = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();

  const handleSuccess = () => {
    dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'CompanyForm.Message.Success' })));
    navigate(`/companies`);
  };

  const handleNotUnique = async (
    values: CompanyFormData,
    name: boolean,
    companyNames: string[],
  ): Promise<void> => {
    const type = name ? 'SameName' : 'SameAddress';
    const containedIn = companyNames.filter(Boolean).length ? '.In' : '';
    dispatch(
      showModal({
        message: intl.formatMessage(
          { id: `CompanyForm.Modal.Warning.${type}${containedIn}` },
          { parentCompanies: companyNames.join(', '), companyName: values.name },
        ),
        onClose: () => dispatch(hideModal()),
        onConfirm: async () => {
          await dispatch(createCompany(values, true));
          await handleSuccess();
          dispatch(hideModal());
        },
      }),
    );
  };

  const submitHandler = useCallback(
    async values => {
      try {
        await dispatch(createCompany(values, false));
        await handleSuccess();
      } catch (e) {
        const error = e as AlphamartErrorData;
        const companyNames =
          error?.errorData?.companyNames instanceof Array ? error.errorData.companyNames : [];
        if (error.errorCode === ErrorCode.COMPANY_NAME_ALREADY_EXIST) {
          await handleNotUnique(values, true, companyNames);
        } else if (error.errorCode === ErrorCode.COMPANY_ADDRESS_ALREADY_EXIST) {
          await handleNotUnique(values, false, companyNames);
        } else {
          const messageId =
            error.errorCode === ErrorCode.COMPANY_SUBDOMAIN_ALREADY_EXIST
              ? 'CompanyForm.Modal.Error.SameSubdomain'
              : 'Global.Error.SomethingWentWrong';
          dispatch(snackBarPushFailure(intl.formatMessage({ id: messageId })));
        }
      }
    },
    [dispatch, createCompany],
  );

  return (
    <FormContainer>
      <CompanyForm onSubmit={submitHandler} />
    </FormContainer>
  );
};

export const CreateCompany = withAlphamartIntlProvider(CreateCompanyComponent, messages);
