import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import filterIcon from 'assets/images/icons/filter.svg';
import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { LoadableContent } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { FilterButton } from 'components/shared/SearchBar/SearchBar';
import { Tabs } from 'components/shared/Tabs/Tabs';
import { FilterableModules } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { useGetVotesCount } from 'shared/queries';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import Chart from './Chart/Chart';
import { ChartContainer, mainPanelStyles, StyledHeader } from './FeedbacksSummary.styles';
import { FeedbackChartFiltersFormShape, FeedbacksSummaryFilters } from './FeedbacksSummaryFilters';
import { messages, useTypedIntl } from '../locale/messages';

export function FeedbacksSummaryComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const isSm = useMediaQuery(MEDIA_QUERY.SM);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.FEEDBACK_CHART]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const query = useGetVotesCount(savedFilters.data);

  const handleFiltersChanged = (changed: FeedbackChartFiltersFormShape) => {
    dispatch(saveFilters({ filterKey: FilterableModules.FEEDBACK_CHART, values: changed }));
  };
  const handleTabsChanged = (value: number | null) =>
    handleFiltersChanged({ ...savedFilters.data, thresholdInDays: value });

  const stringsVersion = isSm ? 'Long' : 'Short';
  const separator = isSm ? ' ' : '';

  const datesForFilter = [
    {
      label: `1${separator}${intl.formatMessage({
        id: `Global.Fields.Select.Date.Week.${stringsVersion}`,
      })}`,
      value: 7,
    },
    {
      label: `1${separator}${intl.formatMessage({
        id: `Global.Fields.Select.Date.Month.${stringsVersion}`,
      })}`,
      value: 30,
    },
    {
      label: `6${separator}${intl.formatMessage({
        id: `Global.Fields.Select.Date.Months.${stringsVersion}`,
      })}`,
      value: 180,
    },
    {
      label: `1${separator}${intl.formatMessage({
        id: `Global.Fields.Select.Date.Year.${stringsVersion}`,
      })}`,
      value: 365,
    },
    {
      label: intl.formatMessage({ id: 'Global.Fields.Select.Date.All' }),
      value: null,
    },
  ];
  const filtersCount = Object.keys(savedFilters.diff.diff).filter(
    key => key !== 'thresholdInDays',
  ).length;

  return (
    <PanelTemplate>
      <StyledHeader
        title={
          <>
            <FormattedMessage id="FeedbacksSummary.Title" />
            <FilterButton count={filtersCount} onClick={() => setFiltersOpen(true)} />
          </>
        }
      />
      <PanelContent>
        <PanelContentMain className={mainPanelStyles}>
          <Tabs
            list={datesForFilter}
            value={savedFilters.data.thresholdInDays}
            onChange={handleTabsChanged}
          />
          <LoadableContent
            mode={LoadableContent.MODE.OVERLAY}
            drawContent
            loading={query.isFetching}
          >
            <ChartContainer>
              <Chart votesCount={query.data ?? []} />
            </ChartContainer>
          </LoadableContent>
        </PanelContentMain>
        <PanelContentSidebar
          header={intl.formatMessage({ id: 'Global.Filters' })}
          headerIcon={filterIcon}
          open={filtersOpen}
          onSidebarClosed={() => setFiltersOpen(false)}
        >
          <FeedbacksSummaryFilters
            onFiltersChanged={handleFiltersChanged}
            onFiltersApplied={() => setFiltersOpen(false)}
          />
        </PanelContentSidebar>
      </PanelContent>
    </PanelTemplate>
  );
}

export const FeedbacksSummary = withAlphamartIntlProvider(FeedbacksSummaryComponent, messages);
