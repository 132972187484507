import React from 'react';
import { useParams } from 'react-router-dom';

import { Badge } from 'components/shared/Badge';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate, formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { resolveHedgeStatus } from 'helpers/hedges/resolveHedgeStatus';
import { PERMISSIONS } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useHedgeEditActions } from 'shared/hooks';
import { useGetHedgeDetails } from 'shared/queries';
import { ItemAction, Status } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { HedgeDetailsAssignedUsers } from './HedgeDetailsAssignedUsers';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

export const HedgeDetails = (): React.ReactElement => {
  const authorize = useAuthorization();
  const { id } = useParams<{ id: string }>();
  const { data: hedge, isFetching, refetch } = useGetHedgeDetails(+id!);
  const { handleUpdate, handleUnassign, handleRemove, handleAssign } = useHedgeEditActions();
  const usersCount = useAppSelector(state => state.users?.count);
  const canSeeHedgePrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);

  const allowedToSeeParentCompany = authorize(PERMISSIONS.HEDGES.DETAILS.SHOW_PARENT_COMPANY);
  const allowedToSeeRelatedCompany = authorize(PERMISSIONS.HEDGES.DETAILS.SHOW_RELATED_COMPANY);
  let hedgeInfoTemplate = ['name status relatedLot'];
  if (allowedToSeeRelatedCompany) {
    hedgeInfoTemplate = ['name status relatedLot', 'relatedCompany relatedCompany relatedCompany'];
  }
  if (allowedToSeeParentCompany) {
    hedgeInfoTemplate = ['name status relatedLot', 'parentCompany relatedCompany relatedCompany'];
  }

  const actions: ItemAction[] = hedge
    ? [
        {
          id: 1,
          label: <FormattedMessage id="Global.Update" />,
          onClick: () => handleUpdate(hedge),
          visible: authorize(PERMISSIONS.HEDGES.UPDATE),
          dataCy: () => `update-${hedge.id}`,
        },
        {
          id: 2,
          label: <FormattedMessage id="Global.Unassign" />,
          onClick: () => handleUnassign(hedge),
          visible: usersCount > 0 && authorize(PERMISSIONS.HEDGES.UNASSIGN),
          dataCy: () => `unassign-${hedge.id}`,
        },
        {
          id: 3,
          label: <FormattedMessage id="Global.Remove" />,
          onClick: () => handleRemove(hedge),
          visible: authorize(PERMISSIONS.HEDGES.REMOVE),
          dataCy: () => `remove-${hedge.id}`,
        },
        {
          id: 4,
          label: <FormattedMessage id="HedgeAssign.Action.Name" />,
          onClick: () => handleAssign(hedge, refetch),
          visible: () =>
            authorize(PERMISSIONS.HEDGES.ASSIGN.ACTION) &&
            resolveHedgeStatus(hedge) === Status.ACTIVE,
          dataCy: () => `assign-${hedge.id}`,
        },
      ]
    : [];

  return (
    <LoadableContent loading={isFetching} mode={LoadableContent.MODE.FULL}>
      {hedge && (
        <Details title={hedge.name} backUrl="/hedges" actions={actions}>
          <DetailsSection
            title={<FormattedMessage id="HedgeDetails.Section.HedgeInfo" />}
            template={hedgeInfoTemplate}
            fields={[
              {
                name: 'name',
                label: <FormattedMessage id="HedgeDetails.HedgeName" />,
                value: hedge.name,
              },
              {
                name: 'status',
                label: <FormattedMessage id="HedgeDetails.Status" />,
                value: (
                  <Badge color={resolveStatusColor(resolveHedgeStatus(hedge))}>
                    <FormattedMessage id={`Global.Status.${resolveHedgeStatus(hedge)}`} />
                  </Badge>
                ),
              },
              {
                name: 'relatedLot',
                label: <FormattedMessage id="HedgeDetails.RelatedLot" />,
                value: hedge.relatedLot,
              },
              {
                name: 'parentCompany',
                label: <FormattedMessage id="HedgeDetails.ParentCompany" />,
                value: hedge.parentCompany?.name,
                doNotRender: !allowedToSeeParentCompany,
              },
              {
                name: 'relatedCompany',
                label: <FormattedMessage id="HedgeDetails.RelatedCompanies" />,
                value: hedge.relatedCompanies?.map(c => c.name).join(', '),
                doNotRender: !allowedToSeeRelatedCompany,
              },
            ]}
          />
          {canSeeHedgePrices && (
            <DetailsSection
              title={<FormattedMessage id="HedgeDetails.Section.Prices" />}
              fields={[
                {
                  name: 'platinumPriceCust',
                  label: <FormattedMessage id="HedgeDetails.PtPriceCust" />,
                  value: formatCurrency(hedge.ptPriceCust),
                },
                {
                  name: 'palladiumPriceCust',
                  label: <FormattedMessage id="HedgeDetails.PdPriceCust" />,
                  value: formatCurrency(hedge.pdPriceCust),
                },
                {
                  name: 'rhodiumPriceCust',
                  label: <FormattedMessage id="HedgeDetails.RhPriceCust" />,
                  value: formatCurrency(hedge.rhPriceCust),
                },
              ]}
            />
          )}
          <DetailsSection
            title={<FormattedMessage id="HedgeDetails.Section.Dates" />}
            template={['placedAt expiresAt expiresAt']}
            fields={[
              {
                name: 'placedAt',
                label: <FormattedMessage id="HedgeDetails.PlacedOn" />,
                value: formatDateWithTime(hedge.placedAt),
              },
              {
                name: 'expiresAt',
                label: <FormattedMessage id="HedgeDetails.Expiration" />,
                value: formatDateWithTime(hedge.expiresAt),
              },
            ]}
          />
          <DetailsSection
            title={<FormattedMessage id="HedgeDetails.Section.Note" />}
            template={['notes notes notes']}
            fields={[
              {
                name: 'notes',
                label: <FormattedMessage id="HedgeDetails.Note" />,
                value: hedge.note,
              },
            ]}
          />
          <HedgeDetailsAssignedUsers hedgeId={hedge.id} />
          <DetailsFooter
            fields={[
              {
                name: 'createdBy',
                label: <FormattedMessage id="Global.CreatedBy" />,
                value:
                  hedge.createdBy && `${hedge.createdBy.firstName} ${hedge.createdBy.lastName}`,
              },
              {
                name: 'createdAt',
                label: <FormattedMessage id="Global.CreatedOn" />,
                value: formatDate(hedge.createdAt),
              },
              {
                name: 'updatedBy',
                label: <FormattedMessage id="Global.UpdatedBy" />,
                value:
                  hedge.updatedBy && `${hedge.updatedBy.firstName} ${hedge.updatedBy.lastName}`,
              },
              {
                name: 'updatedAt',
                label: <FormattedMessage id="Global.UpdatedOn" />,
                value: hedge.updatedAt && formatDate(hedge.updatedAt),
              },
            ]}
          />
        </Details>
      )}
    </LoadableContent>
  );
};
