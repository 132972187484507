import styled from '@emotion/styled';

import { theme } from 'theme';

export const ModalFormContent = styled.div`
  background-color: ${theme.colors.mineShaft};
  border-radius: 12px;
  max-width: 100%;
  padding: 24px 32px;
  width: 480px;

  fieldset {
    border-bottom: none;
    padding: 0;
  }
`;

export const ModalFormButtonContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${theme.colors.mineShaftLightest};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
`;
