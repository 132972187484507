import React from 'react';

import { DetailsSection } from 'components/shared/Details';
import { UsageThresholds } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

interface Props {
  user?: UsageThresholds;
}

export function UserDetailsUsageThresholds({ user }: Props): React.ReactElement {
  const intl = useTypedIntl();
  return (
    <DetailsSection
      title={intl.formatMessage({ id: 'UserDetails.Thresholds.Label' })}
      fields={[
        {
          name: 'maxConverterViewsPerDay',
          label: intl.formatMessage({ id: 'UserDetails.Thresholds.maxConverterViews' }),
          value: user?.maxConverterViewsPerDay,
        },
        {
          name: 'maxConverterSearchesPerDay',
          label: intl.formatMessage({ id: 'UserDetails.Thresholds.maxConverterSearches' }),
          value: user?.maxConverterSearchesPerDay,
        },
      ]}
    />
  );
}
