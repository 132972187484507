import React from 'react';
import styled from '@emotion/styled';

import addIcon from 'assets/images/icons/Add.svg';
import { Theme } from 'theme';

const SubmitStyled = styled.button<{ theme?: Theme }>`
  display: flex;
  align-items: center;
  height: 48px;
  border: none;
  background-color: transparent;
  border-radius: 20px;
  padding: 16px 24px;
  color: ${({ theme }) => theme.mainColor};
  font-size: 12px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease;

  &:before {
    content: '';
    display: block;
    position: absolute;
    border: 1px solid ${({ theme }) => theme.mainColor};
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 20px;
    opacity: 0.35;
    transition: border-color 0.2s ease;
    box-sizing: border-box;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: #00000060;

    &:before {
      border-color: ${({ theme }) => theme.mainColor};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.mineShaft};
    cursor: not-allowed;

    &:before {
      border-color: ${({ theme }) => theme.colors.mineShaft};
      opacity: 1;
    }
  }

  span {
    background-color: ${({ theme }) => theme.mainColor};
  }

  &:disabled {
    span {
      background-color: ${({ theme }) => theme.colors.mineShaft};
    }
  }
`;

const StyledSubmitIcon = styled.span<{ right?: boolean; left?: boolean; icon: string }>(
  ({ right, left, icon }) => ({
    display: 'block',
    transform: right ? 'rotate(180deg)' : 'rotate(0)',
    height: '15px',
    width: '15px',
    maskImage: `url(${icon})`,
    maskPosition: 'center',
    maskRepeat: 'no-repeat',
    maskSize: 'auto',
    marginLeft: right ? '15px' : '0px',
    marginRight: left ? '15px' : '0px',
    backgroundColor: `silver`,
  }),
);

type Props = {
  disabled?: boolean;
  label: string | React.ReactNode;
};

const Submit = ({ disabled, label }: Props): React.ReactElement => (
  <SubmitStyled type="submit" disabled={disabled} data-cy="submit">
    <StyledSubmitIcon left icon={addIcon} />
    {label}
  </SubmitStyled>
);

export { Submit };
