import React from 'react';

import searchIcon from 'assets/images/icons/search.svg';
import { Icon } from 'components/shared/Buttons';
import {
  highlightableTextStyles,
  HighlightSearch,
  RowProps,
  SearchField,
  SearchFieldLabel,
  SearchHits,
  TableRowBody,
} from 'components/shared/List';
import { Converter, ConverterListItem } from 'shared/types';
import { useSearchableConvertersFields } from './useSearchableConvertersFields';

export const ConverterSearchRow = (props: RowProps<ConverterListItem | Converter>) => {
  const converter = props.row!.original;
  const searchInput = props.table?.getState().globalFilter?.trim() as string;
  const searchableFields = useSearchableConvertersFields({ converter, searchInput });

  return (
    <>
      <TableRowBody {...props} />
      {!!searchableFields.length && !converter.converterGroup && props.row?.depth === 0 && (
        <SearchHits>
          <Icon icon={searchIcon} />
          {searchableFields.map(
            field =>
              field && (
                <SearchField key={field.label}>
                  <SearchFieldLabel>{field.label}</SearchFieldLabel>{' '}
                  <HighlightSearch
                    className={highlightableTextStyles(false)}
                    searchWords={[field.matcher(searchInput)!]}
                    textToHighlight={field.value}
                  />
                </SearchField>
              ),
          )}
        </SearchHits>
      )}
    </>
  );
};
