import React, { useEffect, useMemo, useRef } from 'react';
import Select, { SelectInstance } from 'react-select';
import { useField } from 'formik';

import { useTypedIntl } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { FieldRangeStyled, rangeContainerStyles } from './FieldRange.styles';
import { FieldSelectOption } from '../FieldSelect/FieldSelect';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

type Value = {
  from?: number | string | null;
  to?: number | string | null;
};
interface Props {
  disabled?: boolean;
  name: string;
  onBlur?: () => void;
  onChange?: (value?: Value) => void;
  onFocus?: (event?: React.FocusEvent) => void;
  options: FieldSelectOption[];
  searchable?: boolean;
  value?: Value;
  error?: boolean;
}

const FieldRangeLayout = ({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  searchable = true,
  error,
  value,
}: Props) => {
  const intl = useTypedIntl();
  const [, { touched }, { setValue, setTouched }] = useField(name);
  const selectRef = useRef<SelectInstance<FieldSelectOption>>(null);
  const touchDevice = 'ontouchstart' in window;
  const theme = useExtendedTheme();
  const customFilterOption = (option, rawInput) =>
    option.label.toString().toLowerCase().includes(rawInput.toString().toLowerCase());
  const handleChangeFrom = val => {
    if (onChange) onChange({ ...value, from: val?.value });
    setValue({ ...value, from: val?.value });
  };

  const handleChangeTo = val => {
    if (onChange) onChange({ ...value, to: val?.value });
    setValue({ ...value, to: val?.value });
  };
  const handleFocus = e => {
    if (onFocus) onFocus(e);
  };
  const handleBlur = () => {
    setTouched(true);
    if (onBlur) onBlur();
  };
  useEffect(() => {
    if (value) setTouched(true);
  }, [value]);

  const selectedFrom = useMemo(
    () => options?.find(option => value?.from === option.value) ?? null,
    [options, value],
  );
  const selectedTo = useMemo(
    () => options?.find(option => value?.to === option.value) ?? null,
    [options, value],
  );
  const stylesFrom = useMemo(
    () => FieldRangeStyled(Boolean(touched && error), 'from', theme),
    [error, touched, theme],
  );
  const stylesTo = useMemo(
    () => FieldRangeStyled(Boolean(touched && error), 'to', theme),
    [error, touched, theme],
  );
  const optionsFrom = useMemo(
    () => options.filter(option => option.value <= (value?.to ?? Number.MAX_VALUE)),
    [value, options],
  );
  const optionsTo = useMemo(
    () => options.filter(option => option.value >= (value?.from ?? Number.MIN_VALUE)),
    [value, options],
  );

  return (
    <div data-cy={name} className={rangeContainerStyles}>
      <Select
        ref={selectRef}
        filterOption={customFilterOption}
        placeholder={intl.formatMessage({ id: 'Global.Fields.Select.From' })}
        isDisabled={disabled}
        isSearchable={!touchDevice && searchable}
        name={`${name}From`}
        onChange={handleChangeFrom}
        onBlur={handleBlur}
        onFocus={handleFocus}
        options={optionsFrom}
        styles={stylesFrom}
        value={selectedFrom}
        openMenuOnFocus
      />
      <Select
        ref={selectRef}
        filterOption={customFilterOption}
        placeholder={intl.formatMessage({ id: 'Global.Fields.Select.To' })}
        isDisabled={disabled}
        isSearchable={!touchDevice && searchable}
        name={`${name}To`}
        onChange={handleChangeTo}
        onBlur={handleBlur}
        onFocus={handleFocus}
        options={optionsTo}
        styles={stylesTo}
        value={selectedTo}
        openMenuOnFocus
      />
    </div>
  );
};

const FieldRangeFilters = withFilterWrapper<Props>(props => <FieldRangeLayout {...props} />);

export { FieldRangeFilters };
