import * as fns from 'date-fns';

import { StatisticFiltersFormShape } from 'components/views/Statistics/ConvertersStatistics/StatisticsPageFilters';
import { DashboardFiltersKeys } from 'shared/constants';
import { GetStatisticsParams } from 'shared/queries/statistics';

export function parseGetStatisticsParams(data: StatisticFiltersFormShape): GetStatisticsParams {
  const granulationLevel = graduationLevels[data.date];
  const [timespan, qtt] = data.date.split('-');
  const to = fns.setMilliseconds(Date.now(), 0);
  const from = fns.setMilliseconds(fns[`sub${timespan}`](to, qtt), 0);
  return {
    users: data.users,
    from,
    to,
    granulationLevel,
    minOveruseRatio: 3,
  };
}

const graduationLevels: Record<DashboardFiltersKeys, number> = {
  'Days-1': 3600,
  'Weeks-1': 86400,
  'Months-1': 86400,
  'Years-1': 2592000,
  'Years-5': 2592000,
};
