import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Status } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const changeUserStatusSlice = createSlice<
  GenericStoreSlice,
  GenericStoreReducer<GenericStoreSlice>
>({
  name: 'changeUserStatus',
  initialState: { error: undefined },
  reducers: {
    ...getGenericReducers(),
  },
});

export const { success, failure, pending: submitting } = changeUserStatusSlice.actions;

export default changeUserStatusSlice.reducer;

export const changeUserStatus =
  (id: string | number, status: Status, skipUniqueMobileChecker = false): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(submitting());
      const currentStatus = await httpClient.put(
        `${apiHostname}/api/users/${id}/status?skipUniquenessCheck=${skipUniqueMobileChecker}`,
        { status },
      );
      dispatch(success({}));
      return currentStatus?.data;
    } catch (err) {
      dispatch(failure((err as AlphamartHttpError)?.response?.data?.message));
      throw err;
    }
  };
