import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { getHttpClient } from 'shared/utils/httpClient';
import { AlphamartHttpError } from '../types';

export enum VinImageRecognitionKeys {
  VinImageRecognition = 'VinImageRecognition',
}

export const useVinImageRecognition = (
  options?: UseMutationOptions<string, AlphamartHttpError, File>,
): UseMutationResult<string, AlphamartHttpError, File> =>
  useMutation(
    async file => {
      const formData = new FormData();
      formData.append('image', file);
      const response = await getHttpClient().post<string>(
        `${apiHostname}/api/upload/image-recognition/vin`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        },
      );

      return response.data;
    },
    { mutationKey: [VinImageRecognitionKeys.VinImageRecognition], ...options },
  );
