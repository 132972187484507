import React from 'react';
import styled from '@emotion/styled';

import { formatDate } from 'helpers/dateTime/dateTime';
import { DashboardFiltersKeys, Language } from 'shared/constants';
import { Theme } from 'theme';
import { StatisticsScatterPayload } from './StatisticsChart.helpers';
import { useTypedIntl } from '../../locale/messages';

const TooltipContainer = styled.div<{ theme?: Theme }>`
  background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  border-radius: 8px;
  border: none;
  padding: 10px;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 14px;
  pointer-events: all;
`;
const UserLabel = styled.span<{ theme?: Theme }>`
  color: ${({ theme }) => theme.mainColor};
`;
interface Props {
  active?: boolean;
  payload?: unknown[];
}

export const ChartTooltip = ({ active, payload }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();

  if (active && payload && payload.length) {
    const data = (payload[0] as { payload: StatisticsScatterPayload }).payload;

    return (
      <TooltipContainer>
        <div>
          {data.type === 'user' && (
            <UserLabel>
              {data.user?.firstName} {data.user?.lastName}
            </UserLabel>
          )}
          {data.type === 'average' &&
            intl.formatMessage({ id: 'Statistics.Chart.Tooltip.Avg' }, { value: data.y })}
          {data.type === 'min' &&
            intl.formatMessage({ id: 'Statistics.Chart.Tooltip.Min' }, { value: data.y })}
        </div>
      </TooltipContainer>
    );
  }

  return null;
};

const periodFilterToDateFormatMap: Record<`${DashboardFiltersKeys}-${Language}`, string> = {
  'Days-1-EN': 'hh:mm a',
  'Weeks-1-EN': 'do MMM',
  'Months-1-EN': 'do MMM',
  'Years-1-EN': 'MMM yyyy',
  'Years-5-EN': 'MMM yyyy',
  'Days-1-ES': 'HH:mm',
  'Weeks-1-ES': 'do MMM',
  'Months-1-ES': 'do MMM',
  'Years-1-ES': 'MMM yyyy',
  'Years-5-ES': 'MMM yyyy',
  'Days-1-FR': 'HH:mm',
  'Weeks-1-FR': 'do MMM',
  'Months-1-FR': 'do MMM',
  'Years-1-FR': 'MMM yyyy',
  'Years-5-FR': 'MMM yyyy',
  'Days-1-PL': 'HH:mm',
  'Weeks-1-PL': 'do MMM',
  'Months-1-PL': 'do MMM',
  'Years-1-PL': 'MMM yyyy',
  'Years-5-PL': 'MMM yyyy',
};

export const generateLabel = (
  val: string | number,
  selectedPeriod: DashboardFiltersKeys,
  language: Language,
): string =>
  typeof val === 'number' && Number.isFinite(val)
    ? formatDate(new Date(val), periodFilterToDateFormatMap[`${selectedPeriod}-${language}`])
    : '';
