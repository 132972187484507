import React, { useCallback, useEffect, useMemo } from 'react';
import { FormikContextType } from 'formik';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldPhoneNumber } from 'components/shared/Fields/FieldPhoneNumber/FieldPhoneNumber';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { Section } from 'components/shared/forms/Section/Section';
import { getRoleLang } from 'helpers/roles/roles';
import { PERMISSIONS, UserRoles } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { Sort } from 'shared/constants/sort';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { Company, CompanyListItem, RoleListItem, Status, UserFormData } from 'shared/types';
import { fetchHedges } from 'store/hedgesSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { useTypedIntl } from '../locale/messages';

type Props = {
  editMode?: boolean;
  activeSection: string;
  setActiveSection: (sectionName: string) => void;
  context: FormikContextType<UserFormData>;
  companies: (CompanyListItem | Company)[];
  roles: RoleListItem[];
};

export const UserInfoForm = ({
  editMode = false,
  context,
  activeSection,
  setActiveSection,
  companies,
  roles,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();
  const canChangeCompany = authorize(PERMISSIONS.USERS.CHANGE_COMPANY);

  const { values, handleChange, getFieldMeta, handleBlur, setFieldValue, setFieldTouched } =
    context;

  useEffect(() => {
    if (!editMode) return;

    const canFilter = currentUser.roles.some(r =>
      [UserRoles.SUPER_ADMIN, UserRoles.SISTER_COMPANY_ADMIN].includes(r.name),
    );
    const relatedCompanies = [values.info.company];

    dispatch(
      fetchHedges(0, 0, {
        status: Status.ACTIVE,
        ...(canFilter && { relatedCompanies }),
        orderBy: HedgeOrderBy.NAME,
        sort: Sort.ASC,
      }),
    );
  }, [values.info.company, editMode]);

  const roleOptions = useMemo(
    () => roles.map(role => ({ label: getRoleLang(role.name, intl), value: role.id })),
    [roles],
  );

  useEffect(() => {
    if (editMode && Array.isArray(context.initialValues.roles)) {
      setFieldValue('roles.roles', context.initialValues.roles);
    }
  }, [roleOptions]);

  useEffect(() => {
    if (!values.info.officePhonePrefix) setFieldValue('info.officePhone', '');
  }, [values.info.officePhonePrefix]);

  useEffect(() => {
    if (!values.info.mobilePhonePrefix) setFieldValue('info.mobilePhone', '');
  }, [values.info.mobilePhonePrefix]);

  const companiesOptions = useMemo(
    () => companies.map(company => ({ label: company.name, value: company.id })),
    [companies],
  );

  useDeepCompareEffect(() => {
    const selectedCompany = companies.find(c => c.id === values?.info?.company);
    const canUseMarginSliderPerMetal = selectedCompany?.canUseProfitMarginPerMetal ?? false;
    setFieldValue('prices.canUseMarginSliderPerMetal', !!canUseMarginSliderPerMetal);
  }, [companies, values?.info?.company]);

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(`info.${name}`);
    return fieldTouched && error;
  };

  const handleCompanyChange = useCallback(() => {
    setFieldValue('roles.roles', undefined);
  }, [companies]);

  const handleMobilePhoneChange = useCallback(e => {
    setFieldTouched('info.mobilePhonePrefix');
    return handleChange(e);
  }, []);

  const handleOfficePhoneChange = useCallback(e => {
    setFieldTouched('info.officePhonePrefix');
    return handleChange(e);
  }, []);

  const handlePrefixChange = useCallback((val, fieldName) => {
    setFieldValue(fieldName, val ? val.value : null);
    setFieldTouched(fieldName);
  }, []);

  return (
    <FormInWizard
      context={context}
      header={intl.formatMessage({ id: editMode ? 'UserForm.UpdateHeader' : 'UserForm.Header' })}
    >
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="userInfo"
        label={intl.formatMessage({ id: 'UserForm.Section.UserInfo' })}
        template={[
          'info.firstName info.lastName',
          'info.mobilePhone info.mobilePhone',
          'info.officePhone info.officePhone',
          'info.email .',
        ]}
      >
        <FieldInput
          label={intl.formatMessage({ id: 'UserForm.FirstName' })}
          name="info.firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.info.firstName}
          error={getErrors('firstName')}
          required
          data-cy="first-name"
          autoComplete="disabled-chrome-hack"
          maxLength={100}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'UserForm.LastName' })}
          name="info.lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.info.lastName}
          error={getErrors('lastName')}
          required
          data-cy="last-name"
          autoComplete="disabled-chrome-hack"
          maxLength={100}
        />
        <FieldPhoneNumber
          label={intl.formatMessage({ id: 'UserForm.MobilePhone' })}
          name="info.mobilePhone"
          prefixName="info.mobilePhonePrefix"
          onChange={handleMobilePhoneChange}
          onPrefixChange={val => handlePrefixChange(val, 'mobilePhonePrefix')}
          onBlur={handleBlur}
          value={values.info.mobilePhone}
          prefixValue={values.info.mobilePhonePrefix}
          error={getErrors('mobilePhonePrefix') || getErrors('mobilePhone')}
          phoneError={getErrors('mobilePhone')}
          prefixError={getErrors('mobilePhonePrefix')}
          required
          data-cy="mobile-phone"
          autoComplete="disabled-chrome-hack"
          maxLength={15}
        />
        <FieldPhoneNumber
          label={intl.formatMessage({ id: 'UserForm.OfficePhone' })}
          name="info.officePhone"
          prefixName="info.officePhonePrefix"
          onChange={handleOfficePhoneChange}
          onPrefixChange={val => handlePrefixChange(val, 'officePhonePrefix')}
          onBlur={handleBlur}
          value={values.info.officePhone!}
          prefixValue={values.info.officePhonePrefix}
          error={getErrors('officePhonePrefix') || getErrors('officePhone')}
          phoneError={getErrors('officePhone')}
          prefixError={getErrors('officePhonePrefix')}
          data-cy="office-phone"
          autoComplete="disabled-chrome-hack"
          maxLength={16}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'UserForm.Email' })}
          name="info.email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.info.email}
          error={getErrors('email')}
          disabled={editMode}
          required
          data-cy="email"
          autoComplete="off"
          maxLength={100}
        />
      </Section>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="companyInfo"
        label={intl.formatMessage({ id: 'UserForm.Section.CompanyInfo' })}
        template={['info.company info.company']}
      >
        <FieldSelect
          label={intl.formatMessage({ id: 'UserForm.UserCompany' })}
          name="info.company"
          value={values.info.company}
          error={getErrors('company')}
          options={companiesOptions}
          onChange={handleCompanyChange}
          disabled={!canChangeCompany || editMode}
          required
        />
      </Section>
    </FormInWizard>
  );
};
