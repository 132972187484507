import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { messages, TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import { ErrorCode, twoFactorAuthExclusiveFlags } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import {
  useTwoFactorAuthenticationRequest,
  useTwoFactorAuthenticationVerify,
} from 'shared/mutations';
import { AlphamartHttpError } from 'shared/types';
import { setTwoFactorAuthentication } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, MEDIA_QUERY } from 'theme';
import {
  HeaderContainer,
  ModalFormButtonContainer,
  ModalFormContent,
  SubheaderContainer,
} from './TwoFactorAuthenticationModel.styles';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';
import { FieldInput } from '../Fields/FieldInput/FieldInput';
import AppButton from '../forms/AppButton/AppButton';
import { LoadableContent, LoadableContentModes } from '../Loader';
import { ReactModalStyle } from '../Modals.styles';

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^\d{4}$/)
    .required(),
});

export function TwoFactorAuthenticationModal(): React.ReactElement {
  const [confirmMode, setConfirmMode] = React.useState<boolean>(true);
  const intl = useTypedIntl();

  const twoFactorAuthenticationState = useAppSelector(state => state.auth.twoFactorAuthentication);
  const verifyCodeMutation = useTwoFactorAuthenticationVerify();
  const requestCodeMutation = useTwoFactorAuthenticationRequest();
  const dispatch = useAppDispatch();
  const formikContext = useFormik<{ code: string }>({
    initialValues: {
      code: '',
    },
    validationSchema,
    onSubmit: async x => x,
  });
  const hideModal = () =>
    dispatch(
      setTwoFactorAuthentication({ isRequired: false, userAccessFlags: null, callback: null }),
    );

  useEffect(() => {
    setConfirmMode(true);
    verifyCodeMutation.reset();
    requestCodeMutation.reset();
    formikContext.resetForm();
  }, [twoFactorAuthenticationState?.isRequired]);

  const handleConfirmClick = async () => {
    try {
      await verifyCodeMutation.mutateAsync(formikContext.values.code);
      dispatch(
        snackBarPushSuccess(
          intl.formatMessage({ id: 'Global.TwoFactorAuthentication.Modal.Success' }),
        ),
      );
      twoFactorAuthenticationState?.callback?.();
      hideModal();
    } catch (error) {
      setConfirmMode(true);
      dispatch(
        snackBarPushFailure(
          intl.formatMessage({ id: 'Global.TwoFactorAuthentication.Modal.Error' }),
        ),
      );
    }
  };

  const requestNewCode = async () => {
    try {
      await requestCodeMutation.mutateAsync();
      setConfirmMode(false);
      dispatch(
        snackBarPushSuccess(
          intl.formatMessage({ id: 'Global.TwoFactorAuthentication.SendSms.Success' }),
        ),
      );
    } catch (error) {
      const messageId =
        (error as AlphamartHttpError)?.response?.data?.errorCode ===
        ErrorCode.TWO_FACTOR_AUTHENTICATION_REQUEST_LIMIT_REACHED
          ? 'Global.TwoFactorAuthentication.SendSms.ErrorLimitReached'
          : 'Global.TwoFactorAuthentication.SendSms.Error';
      dispatch(snackBarPushFailure(intl.formatMessage({ id: messageId })));
    }
  };

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const submitDisabled =
    !formikContext.dirty || !formikContext.isValid || verifyCodeMutation.isLoading;
  const showLoader = requestCodeMutation.isLoading || verifyCodeMutation.isLoading;

  const protectedFlags = twoFactorAuthExclusiveFlags.filter(
    flag => twoFactorAuthenticationState?.userAccessFlags?.[flag],
  );
  const protectedFlagsLabels = protectedFlags.map(flag =>
    intl.formatMessage({ id: `Global.TwoFactorAuthentication.Flag.${flag}` }),
  );

  return (
    <AlphamartIntlProvider messages={messages}>
      <ReactModal
        isOpen={!!twoFactorAuthenticationState?.isRequired}
        style={ReactModalStyle(isPhone, layers.twoFactorAuthenticationModal)}
        parentSelector={() => document.querySelector('#root')!}
      >
        <ModalFormContent>
          <FormikProvider value={formikContext}>
            <LoadableContent mode={LoadableContentModes.OVERLAY} loading={showLoader} drawContent>
              {confirmMode ? (
                <>
                  <HeaderContainer>
                    <FormattedMessage id="Global.TwoFactorAuthentication.Modal.Title" />
                    <SubheaderContainer>
                      <FormattedMessage
                        id="Global.TwoFactorAuthentication.Modal.Description"
                        values={{
                          count: protectedFlagsLabels.length,
                          protectedFlags: protectedFlagsLabels.join(', '),
                        }}
                      />
                    </SubheaderContainer>
                  </HeaderContainer>
                  <ModalFormButtonContainer>
                    <AppButton
                      styleType="neutral-empty"
                      onClick={hideModal}
                      data-cy="two-factor-authentication-send-cancel"
                      disabled={requestCodeMutation.isLoading}
                    >
                      <FormattedMessage id="Global.Cancel" />
                    </AppButton>
                    <AppButton
                      onClick={requestNewCode}
                      data-cy="two-factor-authentication-send"
                      disabled={requestCodeMutation.isLoading}
                    >
                      <FormattedMessage id="Global.TwoFactorAuthentication.SendSms.Send" />
                    </AppButton>
                  </ModalFormButtonContainer>
                </>
              ) : (
                <>
                  <HeaderContainer>
                    <FormattedMessage id="Global.TwoFactorAuthentication.Modal.Title" />
                    <SubheaderContainer>
                      <button
                        onClick={requestNewCode}
                        data-cy="two-factor-authentication-resend"
                        type="button"
                      >
                        <FormattedMessage id="Global.TwoFactorAuthentication.SendSms.Resend" />
                      </button>
                    </SubheaderContainer>
                  </HeaderContainer>
                  <FieldInput
                    label={intl.formatMessage({ id: 'Global.TwoFactorAuthentication.Modal.Input' })}
                    name="code"
                    onChange={formikContext.handleChange}
                    value={formikContext.values.code}
                    data-cy="two-factor-authentication-input"
                    autoComplete="off"
                  />
                  <ModalFormButtonContainer>
                    <AppButton
                      styleType="neutral-empty"
                      onClick={hideModal}
                      disabled={verifyCodeMutation.isLoading}
                      data-cy="two-factor-authentication-cancel"
                    >
                      <FormattedMessage id="Global.Cancel" />
                    </AppButton>
                    <AppButton
                      onClick={handleConfirmClick}
                      disabled={submitDisabled}
                      data-cy="two-factor-authentication-submit"
                    >
                      <FormattedMessage id="Global.Confirm" />
                    </AppButton>
                  </ModalFormButtonContainer>
                </>
              )}
            </LoadableContent>
          </FormikProvider>
        </ModalFormContent>
      </ReactModal>
    </AlphamartIntlProvider>
  );
}
