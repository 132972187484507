import { SHARED } from 'shared/constants';

export function replaceEmpty(value: string | number, filler: string = SHARED.LONG_DASH): string {
  if (typeof value === 'undefined') {
    return filler;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  return value?.trim()?.length ? value : filler;
}
