import { DetailsSectionField } from 'components/shared/Details/DetailsSection';

export const templateGenerator = (source: DetailsSectionField[] = [], cols = 3): string[] => {
  const result = source
    .filter(p => !p.doNotRender)
    .reduce<string[][]>((prev, item, i, filtered) => {
      if (!(i % cols)) prev.push([]);
      prev[prev.length - 1].push(filtered[i].name);
      return prev;
    }, []);

  return result.map(p => [...p, ...new Array(cols).fill('.')].slice(0, cols).join(' '));
};
