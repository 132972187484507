import React from 'react';

import { ReactComponent as Tick } from 'assets/images/icons/confirm.svg';
import { Tooltip } from 'components/shared/Tooltip/Tooltip';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { NotificationListItem as NotificationListItemType, NotificationStatus } from 'shared/types';
import { theme } from 'theme';
import { useTypedIntl } from './locale/messages';
import {
  DateText,
  ListItemContainer,
  ListItemContent,
  ListItemStatus,
  UnreadMarker,
} from './NotificationListItem.styles';

interface Props {
  notification: NotificationListItemType;
  onClick: (id: number) => unknown;
}

export function NotificationListItem({ notification, onClick }: Props): React.ReactElement {
  const intl = useTypedIntl();

  const handleItemClick = item => {
    const contentSelected = !!window.getSelection()?.toString().length;
    if (!contentSelected) onClick(item.id);
  };

  return (
    <ListItemContainer onClick={() => handleItemClick(notification)}>
      <ListItemStatus>
        {!notification.readAt && (
          <Tooltip
            placement="left"
            trigger={['hover', 'focus']}
            tooltip={intl.formatMessage({ id: 'Notifications.ListItem.Unread' })}
            bgColor={theme.colors.mineShaftLighter}
            hideArrow
          >
            <UnreadMarker />
          </Tooltip>
        )}
        {notification.status === NotificationStatus.RESOLVED && (
          <Tooltip
            placement="left"
            trigger={['hover', 'focus']}
            tooltip={intl.formatMessage({ id: 'Notifications.ListItem.Status.RESOLVED' })}
            bgColor={theme.colors.mineShaftLighter}
            hideArrow
          >
            <Tick width={23} height={23} />
          </Tooltip>
        )}
      </ListItemStatus>
      <ListItemContent>
        {notification.subject} <br />{' '}
        <DateText>{formatDateWithTime(notification.createdAt)}</DateText>
      </ListItemContent>
    </ListItemContainer>
  );
}
