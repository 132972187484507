import React from 'react';
import { css } from '@emotion/css';

import arrowBackIcon from 'assets/images/icons/arrowBackSilver.svg';
import { keys } from 'helpers/keys/keys';
import { TypedIntlShape, useTypedIntl } from 'locale/messages';
import { SortInterface, SortSelectOptions } from 'shared/constants/sortableModules';
import { useExtendedTheme } from 'shared/hooks';
import {
  ListPaginationEllipsisStyled,
  listPaginationItemStyles,
  ListPaginationMobileButtonsStyled,
  listPaginationMobileStateStyles,
  listPaginationMobileStyles,
  listPaginationStyles,
  ListPaginationWrapper,
} from './ListPagination.styles';
import { PageSizeSelect } from './PageSizeSelect';
import { PageSortSelect } from './PageSortSelect';
import { GoToPageButton } from '../Buttons';

interface IntlProps {
  intl: TypedIntlShape;
}
interface ListPaginationItemProps {
  page: number;
  onClick: () => void;
  isActive?: boolean;
}
interface ListPaginationControlButtonProps {
  label: React.ReactNode;
  onClick: () => void;
  visible: boolean;
}
interface Props {
  pages: number;
  onPageChange: (page: number) => void;
  currentPage: number;
  sortOptions?: SortSelectOptions[];
  handleSortingChange?: (changed: SortInterface | null) => void;
  currentSortValue?: SortInterface;
}

const PrevIcon = ({ intl }: IntlProps): React.ReactElement => (
  <img alt={intl.formatMessage({ id: 'Global.Previous' })} src={arrowBackIcon} />
);

const NextIcon = ({ intl }: IntlProps): React.ReactElement => (
  <img
    alt={intl.formatMessage({ id: 'Global.Next' })}
    src={arrowBackIcon}
    className={css`
      transform: rotate(180deg);
    `}
  />
);

const ListPaginationItem = ({
  page,
  isActive = false,
  onClick,
}: ListPaginationItemProps): React.ReactElement => {
  const theme = useExtendedTheme();
  return (
    <button type="button" onClick={onClick} className={listPaginationItemStyles(isActive, theme)}>
      {page}
    </button>
  );
};
const ListPaginationEllipsis = () => (
  <ListPaginationEllipsisStyled>...</ListPaginationEllipsisStyled>
);

const ListPaginationControlButton = ({
  label,
  onClick,
  visible,
}: ListPaginationControlButtonProps): React.ReactElement => (
  <ListPaginationMobileButtonsStyled type="button" onClick={onClick} visible={visible}>
    {label}
  </ListPaginationMobileButtonsStyled>
);

export const ListPagination = ({
  pages,
  onPageChange,
  currentPage,
  handleSortingChange,
  sortOptions,
  currentSortValue,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();

  const isFirst = page => page === 1;
  const isLast = page => page === pages;
  const fromStart = page => page - 1;
  const fromEnd = page => pages - page;
  const fromCurrent = page => Math.abs(currentPage - page);

  return (
    <div className={listPaginationStyles(theme)}>
      <PageSizeSelect />
      {sortOptions && (
        <PageSortSelect
          customSortTypes={sortOptions}
          handleChange={handleSortingChange}
          currentSortValue={currentSortValue}
        />
      )}
      {pages > 1 && (
        <>
          <ListPaginationWrapper>
            <ListPaginationControlButton
              onClick={() => {
                if (currentPage > 1) {
                  onPageChange(currentPage - 1);
                }
              }}
              label={<PrevIcon intl={intl} />}
              visible={currentPage > 1}
            />
            {keys(pages).map((key, index) => {
              if (
                isFirst(index + 1) ||
                isLast(index + 1) ||
                fromCurrent(index + 1) < 2 ||
                (fromCurrent(index + 1) === 2 &&
                  (fromStart(index + 1) === 1 || fromEnd(index + 1) === 1))
              ) {
                return (
                  <ListPaginationItem
                    key={key}
                    onClick={() => onPageChange(index + 1)}
                    page={index + 1}
                    isActive={currentPage === index + 1}
                  />
                );
              }
              if (fromCurrent(index + 1) > 2 && (index === 1 || index === pages - 2)) {
                return <ListPaginationEllipsis key={key} />;
              }
              return null;
            })}
            <div className={listPaginationMobileStyles(theme)}>
              <div
                className={listPaginationMobileStateStyles(theme)}
              >{`${currentPage} / ${pages}`}</div>
            </div>
            <ListPaginationControlButton
              onClick={() => {
                if (currentPage < pages) {
                  onPageChange(currentPage + 1);
                }
              }}
              label={<NextIcon intl={intl} />}
              visible={currentPage < pages}
            />
          </ListPaginationWrapper>
          <GoToPageButton onClick={onPageChange} max={pages} currentPage={currentPage} />
        </>
      )}
    </div>
  );
};
