import { MarginVisibility, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useGetCompanies } from 'shared/queries';
import { UserFormData } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { useTypedIntl } from '../locale/messages';

export const useProcessMarginWarnings = (): ((
  finalStep: (values: UserFormData) => Promise<unknown>,
) => (values: UserFormData) => Promise<void>) => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const currentUser = useCurrentUser();
  const canFetchCompanies = authorize(PERMISSIONS.COMPANIES.LIST);
  const { data: fetchedCompanies } = useGetCompanies({}, { enabled: canFetchCompanies });
  const companies = canFetchCompanies ? fetchedCompanies?.data : [currentUser.company];

  return finalStep => async values => {
    const zeroProfitMargin =
      values.prices.userProfitMargin?.length === 1 &&
      +values.prices.userProfitMargin?.[0].value === 0 &&
      Number(values.prices.currentProfitMarginPt) === 0 &&
      Number(values.prices.currentProfitMarginPd) === 0 &&
      Number(values.prices.currentProfitMarginRh) === 0;

    const notVisibleProfitMargin =
      values.prices.marginVisibility === MarginVisibility.NO_VISIBILITY;
    const sliderMarginOnly = values.prices.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY;
    const selectedCompany = [...(companies ?? [])].find(c => c.id === values?.info.company);
    const perMetal =
      selectedCompany?.canUseProfitMarginPerMetal ??
      currentUser.company?.canUseProfitMarginPerMetal;

    const warnings: string[] = [];

    if (sliderMarginOnly)
      warnings.push(
        intl.formatMessage(
          { id: `UserForm.SubmitWarning.SliderOnly${perMetal ? '.PerMetal' : ''}` },
          {
            userProfitMargin: values?.prices?.userProfitMargin[0]?.value ?? 0,
            sliderPt: values.prices.currentProfitMarginPt,
            sliderPd: values.prices.currentProfitMarginPd,
            sliderRh: values.prices.currentProfitMarginRh,
          },
        ),
      );

    if (notVisibleProfitMargin)
      warnings.push(
        intl.formatMessage(
          { id: `UserForm.SubmitWarning.NoVisibility${perMetal ? '.PerMetal' : ''}` },
          {
            userProfitMargin: values?.prices?.userProfitMargin[0]?.value ?? 0,
            sliderPt: values.prices.currentProfitMarginPt,
            sliderPd: values.prices.currentProfitMarginPd,
            sliderRh: values.prices.currentProfitMarginRh,
          },
        ),
      );

    if (zeroProfitMargin)
      warnings.push(intl.formatMessage({ id: 'UserForm.SubmitWarning.NoMargins' }));

    await warnings.reduce(
      (prev, message) => async (): Promise<unknown> =>
        dispatch(showModal({ message, onClose: () => dispatch(hideModal()), onConfirm: prev })),
      () => {
        if (warnings.length > 0) dispatch(hideModal());
        finalStep(values);
      },
    )();
  };
};
