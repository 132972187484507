import React, { useEffect, useRef, useState } from 'react';

import { useTypedIntl } from 'locale/messages';
import { useClickAway } from 'shared/hooks';
import {
  Input,
  ListContainer,
  MainContainer,
  SelectedCounter,
} from './FieldMultiselectCheckbox.styles';
import { GroupComponent } from './GroupComponent';
import { GroupOption } from './GroupOption';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

export type OptionValue = string | number;
export type Option = {
  label: string;
  value: OptionValue;
};
export type Group = {
  groupLabel: string;
  options: Option[];
};

interface Props {
  groupedOptions: Group[];
  value: OptionValue[];
  onChange: (selected: OptionValue[]) => void;
  dataCy?: string;
  isInitiallyOpen?: boolean;
}

export const FieldMultiselectCheckbox = ({
  groupedOptions,
  onChange,
  value,
  dataCy = 'field-multi-select-checkbox',
  isInitiallyOpen = false,
}: Props): React.ReactElement => {
  const fieldSelectRef = useRef<HTMLDivElement>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Group[]>(groupedOptions);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const intl = useTypedIntl();
  useClickAway(listContainerRef, () => {
    setIsOpen(false);
    setSearchValue('');
  });
  useEffect(() => {
    setFilteredOptions(groupedOptions);
  }, [groupedOptions]);
  useEffect(() => {
    const newSelectedGroups = groupedOptions
      .filter(group => group.options.every(option => value.includes(option.value)))
      .map(group => group.groupLabel);
    setSelectedGroups(newSelectedGroups);
  }, [value]);
  useEffect(() => {
    setFilteredOptions(filterAvailableOptions(groupedOptions, searchValue));
  }, [searchValue]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleCheckboxChange = (newValue: OptionValue) => {
    const selected = getNewSelectedOptions(newValue, value);
    onChange(selected);
  };
  const handleGroupSelection = (selectedGroup: Group) => {
    const groupWithAllOptions = groupedOptions.find(
      group => group.groupLabel === selectedGroup.groupLabel,
    );
    if (!groupWithAllOptions) return;
    const newSelectedOptions = getNewSelectedOptionsOnGroupSelect(
      groupWithAllOptions,
      selectedGroups,
      value,
    );
    setSearchValue('');
    onChange(newSelectedOptions);
  };

  const openMenu = () => setIsOpen(true);
  const isDisabled = groupedOptions.length === 0;

  return (
    <MainContainer
      onClick={openMenu}
      isMenuOpen={isOpen}
      ref={listContainerRef}
      isDisabled={isDisabled}
      data-cy={dataCy}
    >
      <Input
        onChange={handleInputChange}
        placeholder={intl.formatMessage({ id: 'Global.Fields.MultiselectCheckbox.Placeholder' })}
        value={searchValue}
        data-cy="field-multi-select-search"
      />
      {!isDisabled && (
        <SelectedCounter data-cy="field-multi-select-counter">{value.length}</SelectedCounter>
      )}
      {isOpen && (
        <ListContainer ref={fieldSelectRef} data-cy="field-multi-select-list">
          {filteredOptions.map(group => (
            <div key={`group-${group.groupLabel}`}>
              <GroupComponent
                group={group}
                handleGroupSelection={handleGroupSelection}
                isSelected={selectedGroups.includes(group.groupLabel)}
                intl={intl}
                isInitiallyOpen={isInitiallyOpen}
              >
                {group.options.map(option => (
                  <GroupOption
                    key={option.value}
                    option={option}
                    checked={value.includes(option.value)}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                ))}
              </GroupComponent>
            </div>
          ))}
        </ListContainer>
      )}
    </MainContainer>
  );
};

export function getNewSelectedOptions(
  value: OptionValue,
  previousSelection: OptionValue[],
): OptionValue[] {
  const isValueCurrentlySelected = previousSelection.includes(value);
  const newSelectedOptions = isValueCurrentlySelected
    ? previousSelection.filter(el => el !== value)
    : [...previousSelection, value];
  return newSelectedOptions;
}

export function getNewSelectedOptionsOnGroupSelect(
  selectedGroup: Group,
  currentlySelectedGroups: string[],
  values: OptionValue[],
): OptionValue[] {
  const isGroupCurrentlySelected = currentlySelectedGroups.includes(selectedGroup.groupLabel);

  const groupValues = selectedGroup.options.map(el => el.value);
  const valuesWithoutGroup = [...values.filter(option => !groupValues.includes(option))];
  const newSelectedOptions = isGroupCurrentlySelected
    ? valuesWithoutGroup
    : [...valuesWithoutGroup, ...groupValues];
  return newSelectedOptions;
}

export function filterAvailableOptions(groups: Group[], search: string): Group[] {
  if (!search) {
    return groups;
  }

  return groups
    .map(group => ({
      groupLabel: group.groupLabel,
      options: group.options.filter(options =>
        options.label.toLowerCase().includes(search.toLowerCase().trim()),
      ),
    }))
    .filter(group => group.options.length > 0);
}

export const FilterFieldMultiselectCheckbox = withFilterWrapper<Props>(props => (
  <FieldMultiselectCheckbox {...props} />
));
