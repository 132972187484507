import React from 'react';
import styled from '@emotion/styled';

import { Theme } from 'theme';
import { Option, OptionValue } from './FieldMultiselectCheckbox';

interface GroupProps {
  option: Option;
  handleCheckboxChange: (val: OptionValue) => void;
  checked?: boolean;
}

export const GroupOption = ({
  option,
  handleCheckboxChange,
  checked,
}: GroupProps): React.ReactElement => (
  <OptionContainer
    key={option.value}
    data-cy={`option-${option.value}`}
    onClick={() => handleCheckboxChange(option.value)}
  >
    <OptionLabel>
      <span>{option.label}</span>
    </OptionLabel>
    <Input type="checkbox" checked={checked} readOnly />
    <CheckMarkContainer>
      <CheckMark viewBox="0,0,50,50" checked={checked}>
        <path d="M5 30 L 20 45 L 45 5" />
      </CheckMark>
    </CheckMarkContainer>
  </OptionContainer>
);

const OptionLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const OptionContainer = styled.div<{ theme?: Theme }>`
  color: ${({ theme }) => theme.colors.greySnow};
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;

  :hover {
    background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  }
`;
const CheckMarkContainer = styled.div`
  heigh: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
const CheckMark = styled.svg<{ theme?: Theme; checked?: boolean }>`
  flex-shrink: 0;
  display: block;
  width: 13px;
  height: 13px;
  transition: all 0.2s ease;
  margin: 0 5px;

  path {
    fill: none;
    stroke: ${({ theme }) => theme.mainColor};
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 100;
    stroke-dashoffset: ${({ checked }) => (checked ? 'stroke-dashoffset: 0;' : '101')};
    transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
  }
`;
const Input = styled.input<{ theme?: Theme }>`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
`;
