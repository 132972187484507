import React, {
  FocusEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Select, {
  GroupBase,
  InputActionMeta,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-select';
import { CSSObject } from '@emotion/react';
import { useField } from 'formik';
import { kebabCase } from 'lodash';
import { matchSorter } from 'match-sorter';

import { useTypedIntl } from 'locale/messages';
import { useDropdownPlacement } from 'shared/hooks';
import { usePrevious } from 'shared/hooks/usePrevious';
import { FieldSelectStyled } from './FieldSelect.styles';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

const SELECT_ALL_VALUE = 'SelectAll';

export type SelectStyles = StylesConfig<FieldSelectOption, false, GroupBase<FieldSelectOption>>;
export type SelectStylesOverwrites = Partial<Record<keyof SelectStyles, CSSObject>>;
export type FieldSelectOption = {
  label: string;
  value: string | number;
  searchString?: string;
};

export type CustomSearchFunction = (
  options: FieldSelectOption[],
  inputText: string,
) => FieldSelectOption[];
export type FormatOptionLabel<T = unknown> = (
  data: FieldSelectOption & { labelHtml?: { line1: string; line2: string } } & T,
) => React.ReactNode;
export type FieldSelectValue = string | number | string[] | number[] | null | undefined;
export type FieldSelectOnChangeSingle = (val?: FieldSelectOption) => void;
export type FieldSelectOnChangeMulti = (val?: FieldSelectOption[]) => void;
export interface Props {
  value: FieldSelectValue;
  options: FieldSelectOption[];
  clearable?: boolean;
  disabled?: boolean;
  name: string;
  customSearchFunction?: CustomSearchFunction;
  searchable?: boolean;
  loading?: boolean;
  error?: React.ReactNode;
  placeholder?: React.ReactNode;
  prefixLabel?: string;
  isPrefix?: boolean;
  selectAll?: boolean;
  label?: string;
  multi?: boolean;
  formatOptionLabel?: FormatOptionLabel;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: FieldSelectOnChangeSingle | FieldSelectOnChangeMulti;
  styles?: SelectStylesOverwrites;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onCreateOption?: (inputValue: string) => void;
  components?: SelectComponentsConfig<FieldSelectOption, false, GroupBase<FieldSelectOption>>;
  hideSelectedOptions?: boolean;
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
}

const FieldSelectLayout = React.memo<Props>(
  ({
    clearable = true,
    disabled,
    multi = false,
    name,
    onBlur,
    onChange,
    onFocus,
    options,
    searchable = true,
    loading = false,
    error,
    value,
    placeholder,
    isPrefix = false,
    selectAll = false,
    onInputChange,
    customSearchFunction,
    formatOptionLabel,
    prefixLabel,
    styles: stylesOverrides,
    components,
  }) => {
    const [, { touched }, { setValue, setTouched }] = useField(name);
    const [filteredOptions, setFilteredOptions] = useState<FieldSelectOption[]>([]);
    const previousOptions = usePrevious(options, []);
    const { dropdownPlacement, dropdownRef, refreshDropdownPlacement } =
      useDropdownPlacement('bottom');
    const intl = useTypedIntl();
    const selectAllOption = {
      label: intl.formatMessage({ id: 'Global.Fields.Select.SelectAll' }),
      value: SELECT_ALL_VALUE,
    };

    useEffect(() => {
      setFilteredOptions(selectAll ? [selectAllOption, ...options] : options);
      const newOptionsAreEmpty = previousOptions?.length !== 0 && options?.length === 0;
      const hasValue = Array.isArray(value) ? value.length !== 0 : value !== null;
      if (newOptionsAreEmpty && hasValue) {
        // TODO: unexpected change event on initial empty roles list
        handleChange(multi ? [] : null);
      }
    }, [options]);

    useEffect(() => {
      value && !touched && setTouched(true);
    }, [value, touched]);

    const handleInputChange = useCallback(
      val => {
        if (searchable && val) {
          setFilteredOptions(
            customSearchFunction?.(options, val) || matchSorter(options, val, { keys: ['label'] }),
          );
        } else if (!val) {
          setFilteredOptions(options);
        }

        return val;
      },
      [options],
    );

    const handleFiltersOption = useCallback(
      (option, rawInput) => {
        const isSearchOnlySourceOfTruth = typeof customSearchFunction === 'function';
        if (isSearchOnlySourceOfTruth) {
          return true;
        }

        return option.label.toString().toLowerCase().includes(rawInput.toString().toLowerCase());
      },
      [customSearchFunction],
    );

    const selectAllClicked = val => multi && val.some(item => item.value === SELECT_ALL_VALUE);

    const handleChange = val => {
      const isSelectAllValue = selectAllClicked(val);
      onChange?.(val);

      if (isSelectAllValue) {
        setValue(filteredOptions.filter(v => v.value !== SELECT_ALL_VALUE).map(v => v.value));
      } else {
        setValue(multi ? val?.map(v => v.value ?? null) : val?.value ?? null);
      }
    };

    const handleFocus = useCallback(e => onFocus && onFocus(e), [onFocus]);

    const handleBlur = useCallback(
      e => {
        setTouched(true);
        setFilteredOptions(options);
        onBlur?.(e);
      },
      [setTouched, onBlur, options],
    );

    const selected = useMemo(() => {
      if (!value) return null;

      return multi
        ? [...options]
            .filter(option => (value as (string | number)[])?.some(val => val === option?.value))
            .sort(
              ({ value: v1 }, { value: v2 }) =>
                (value as (string | number)[]).indexOf(v1) -
                (value as (string | number)[]).indexOf(v2),
            )
        : options?.find(option => value === option?.value);
    }, [value, options]);

    const styles = useMemo(
      () =>
        FieldSelectStyled(
          touched && Boolean(error),
          isPrefix,
          stylesOverrides,
          prefixLabel,
          dropdownPlacement,
        ),
      [error, touched, stylesOverrides, dropdownPlacement],
    );
    const handleMenuOpen = () => {
      refreshDropdownPlacement();
    };

    return (
      <div ref={dropdownRef} data-cy={kebabCase(name)}>
        <Select
          filterOption={handleFiltersOption}
          placeholder={
            placeholder || intl.formatMessage({ id: 'Global.Fields.Select.Placeholder' })
          }
          isClearable={disabled ? false : clearable}
          isDisabled={disabled}
          isMulti={multi as unknown as false}
          isLoading={loading}
          menuIsOpen={disabled ? false : undefined}
          isSearchable={searchable}
          name={name}
          onChange={disabled ? undefined : handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onInputChange={onInputChange ?? handleInputChange}
          options={(filteredOptions ?? []).filter(Boolean)}
          styles={styles}
          value={selected}
          formatOptionLabel={formatOptionLabel}
          openMenuOnFocus
          menuPlacement={dropdownPlacement}
          onMenuOpen={handleMenuOpen}
          components={components}
          noOptionsMessage={() => intl.formatMessage({ id: 'Global.Fields.Select.NoOptions' })}
        />
      </div>
    );
  },
);

const FieldSelect = withFieldWrapper<Props>(props => <FieldSelectLayout {...props} />);
const FilterSelect = withFilterWrapper<Props>(props => (
  <FieldSelectLayout {...props} placeholder={props.label ?? ''} />
));

export { FieldSelect, FieldSelectLayout as FieldSelectBase, FilterSelect };
