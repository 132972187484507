import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { Vehicle } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum ExternalPhotoKeys {
  GetExternalPhoto = 'GetExternalPhoto',
}

type Params = Pick<Vehicle, 'make' | 'model' | 'year'>;
type ExternalPhotoResponse = {
  url: string;
};
export type UseGetExternalPhotoResponse = UseQueryResult<ExternalPhotoResponse | null>;

export const useGetExternalPhoto = (
  params: Params,
  options: UseQueryOptions<ExternalPhotoResponse | null> = {},
): UseGetExternalPhotoResponse =>
  useQuery<ExternalPhotoResponse | null>(
    [ExternalPhotoKeys.GetExternalPhoto, params],
    async () => {
      if (!params.make || !params.model || !params.year) return null;
      const response = await getHttpClient().get<ExternalPhotoResponse>(
        `${apiHostname}/api/vehicles/external-photo`,
        {
          params,
        },
      );
      return response.data;
    },
    options,
  );
