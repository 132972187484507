import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  DndProvider,
  DndProviderProps,
  MouseTransition,
  MultiBackendOptions,
  TouchTransition,
} from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

const HTML5toTouch: MultiBackendOptions = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true, delayTouchStart: 100 },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

export const DraggableProvider = ({
  children,
  ...props
}: Partial<DndProviderProps> & {
  children: React.ReactNode;
}): React.ReactElement => (
  <DndProvider options={HTML5toTouch} {...props}>
    {children}
  </DndProvider>
);
