import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';

import { useAlphamartLocation } from 'shared/hooks/useAlphamartRouter';
import { usePrevious } from './usePrevious';

const getModuleFromLocation = (location: Location) => location.pathname.split('/')?.[1];

export const useModuleChange = (callback: () => unknown): void => {
  const location = useAlphamartLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    const moduleChanged =
      prevLocation && getModuleFromLocation(prevLocation) !== getModuleFromLocation(location);
    if (moduleChanged) {
      callback();
    }
  }, [location]);
};
