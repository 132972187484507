import { Store } from '@reduxjs/toolkit';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

import { ErrorCode } from 'shared/constants';
import { HttpStatus } from 'shared/constants/httpStatus';
import {
  PriceTimestampHeaders,
  validatePricesTimestamp,
  validateVersion,
  VersionHeaders,
} from 'shared/helpers';
import { AlphamartHttpError } from 'shared/types';
import { SessionListener } from 'shared/utils/sessionListener';
import { RootState } from 'store';
import { logout } from 'store/auth';
import { setError } from 'store/errorsSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AlphamartReduxStore = { defaultStore: Store<RootState, any> };

class HttpClient {
  instance: AxiosInstance;

  stores?: AlphamartReduxStore;

  obsoleteVersion: boolean;

  constructor(stores?: AlphamartReduxStore) {
    this.obsoleteVersion = false;
    this.instance = axios.create({
      withCredentials: true,
    });

    this.stores = stores;

    this.instance.interceptors.response.use(
      response => {
        SessionListener.setTimeout(() => {
          this.stores?.defaultStore.dispatch(logout());
        });
        if (!this.obsoleteVersion && this.stores) {
          this.obsoleteVersion = validateVersion(
            response.headers as unknown as VersionHeaders,
            this.stores,
          );
        }

        validatePricesTimestamp(response.headers as unknown as PriceTimestampHeaders);

        return response;
      },
      async err => {
        const error: AlphamartHttpError = err;
        if (axios.isCancel(error)) return Promise.reject(error);
        if (
          error?.response?.status === HttpStatus.UNAUTHORIZED &&
          !this.stores?.defaultStore.getState().auth.isPending
        ) {
          this.stores?.defaultStore.dispatch(logout());
        }

        if (error?.response?.status === HttpStatus.NOT_FOUND) {
          this.stores?.defaultStore.dispatch(
            setError({
              httpStatus: HttpStatus.NOT_FOUND,
              errorCode: error?.response?.data?.errorCode,
            }),
          );
        }

        if (
          error?.response?.status === HttpStatus.FORBIDDEN_ACCESS &&
          error?.response?.data?.errorCode !==
            ErrorCode.TWO_FACTOR_AUTHENTICATION_VERIFICATION_FAILED
        ) {
          this.stores?.defaultStore.dispatch(
            setError({
              httpStatus: HttpStatus.FORBIDDEN_ACCESS,
              errorCode: error?.response?.data?.errorCode,
            }),
          );
        }

        return Promise.reject(error);
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.get(url, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.post(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.put(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch<T = any>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.patch(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.delete(url, config);
  }

  getCancelToken(): CancelTokenSource {
    const { CancelToken } = axios;
    return CancelToken.source();
  }

  isCancel(error: unknown): boolean {
    return axios.isCancel(error);
  }
}

let httpClient: HttpClient;

export function setHttpClient(client: HttpClient): void {
  httpClient = client;
}

export function getHttpClient(): HttpClient {
  if (!httpClient) {
    throw new Error('HttpClient is not initialized. Make sure redux store is initialized first.');
  }
  return httpClient;
}

export default HttpClient;
