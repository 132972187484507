import { matchNormalized } from 'shared/matchers';
import { AssayListItem } from 'shared/types/assayListItem';
import { useTypedIntl } from '../locale/messages';

interface Props {
  assay: AssayListItem;
  searchInput: string;
}

export const useSearchableAssaysFields = (props: Props) => {
  const { assay, searchInput } = props;
  const intl = useTypedIntl();

  return [
    {
      label: intl.formatMessage({ id: 'AssaysDetails.Make' }),
      matcher: matchNormalized,
      value: assay.converterMake,
    },
    {
      label: intl.formatMessage({ id: 'AssaysDetails.Model' }),
      matcher: matchNormalized,
      value: assay.converterModel,
    },
    {
      label: intl.formatMessage({ id: 'AssaysDetails.Nicknames' }),
      matcher: matchNormalized,
      value: assay.converterNicknames,
    },
    {
      label: intl.formatMessage({ id: 'AssaysDetails.OtherNumbers' }),
      matcher: matchNormalized,
      value: assay.converterOtherNumbers?.join(' ') ?? '',
    },
    {
      label: intl.formatMessage({ id: 'AssaysDetails.Notes' }),
      matcher: matchNormalized,
      value: assay.notes,
    },
    {
      label: intl.formatMessage({ id: 'AssaysDetails.ConverterNotes' }),
      matcher: matchNormalized,
      value: assay.converterNotes,
    },
  ].filter(p => p && searchInput && p.value && p.matcher(searchInput)?.test(p.value));
};
