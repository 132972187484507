import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { getDeviceDetails } from 'helpers/devices/deviceDetails';
import { AccessFlag, apiHostname, ErrorCode } from 'shared/constants';
import { parseProfitMargins, ParseProfitMarginsParams } from 'shared/parsers/parseProfitMargins';
import { AlphamartHttpError, User } from 'shared/types';
import { SessionListener } from 'shared/utils/sessionListener';
import { GenericThunk } from './shared/createGenericStoreSlice';

export interface AuthState {
  user: {
    data: User;
  } | null;
  loginAttempts: number;
  redirectTo: string | null;
  error: {
    message: string;
    code?: string;
  } | null;
  isPending: boolean;
  isLogoutPending: boolean;
  twoFactorAuthentication?: {
    isRequired: boolean;
    userAccessFlags?: Record<AccessFlag, boolean> | null;
    callback?: (() => void) | (() => Promise<void>) | null;
  };
  loggedInInThisSession: boolean;
}

type AuthReducer = {
  loginSuccess: CaseReducer<AuthState, { type: string; payload: { data: User } }>;
  logoutSuccess: CaseReducer<AuthState, { type: string }>;
  submitting: CaseReducer<AuthState, { type: string }>;
  logoutSubmitting: CaseReducer<AuthState, { type: string }>;
  failure: CaseReducer<
    AuthState,
    { type: string; payload: { message: string; code?: string } | null }
  >;
  redirectTo: CaseReducer<AuthState, { type: string; payload: string | null }>;
  blockUserAction: CaseReducer<AuthState, { type: string }>;
  setTwoFactorAuthentication: CaseReducer<
    AuthState,
    {
      type: string;
      payload: {
        isRequired: boolean;
        userAccessFlags?: Record<AccessFlag, boolean> | null;
        callback?: (() => void) | null;
      };
    }
  >;
  markFreshLogin: CaseReducer<AuthState, { type: string; payload: boolean }>;
};

export interface UpdateCurrentProfitMarginParams {
  currentProfitMarginPt: number;
  currentProfitMarginPd: number;
  currentProfitMarginRh: number;
  saveSelectedProfitMargin: boolean;
  selectedProfitMargin: number;
}

const authSlice = createSlice<AuthState, AuthReducer>({
  name: 'auth',
  initialState: {
    user: null,
    error: null,
    loginAttempts: 0,
    redirectTo: null,
    isPending: false,
    isLogoutPending: false,
    twoFactorAuthentication: {
      isRequired: false,
    },
    loggedInInThisSession: false,
  },
  reducers: {
    loginSuccess: (state, { payload }) => ({
      ...state,
      user: payload,
      error: null,
      loginAttempts: 0,
      isPending: false,
    }),
    logoutSuccess: state => ({
      ...state,
      user: null,
      error: null,
      loginAttempts: 0,
      isLogoutPending: false,
    }),
    submitting: state => ({
      ...state,
      isPending: true,
      error: null,
    }),
    logoutSubmitting: state => ({
      ...state,
      isLogoutPending: true,
      error: null,
    }),
    failure: (state, { payload }) => ({
      ...state,
      user: null,
      error: payload,
      loginAttempts: payload ? state.loginAttempts + 1 : state.loginAttempts,
      isPending: false,
    }),
    redirectTo: (state, { payload }) => ({
      ...state,
      redirectTo: payload,
    }),
    blockUserAction: state => ({
      ...state,
      ...(state.user && { user: { ...state.user, data: { ...state.user.data, isBlocked: true } } }),
    }),
    setTwoFactorAuthentication: (state, { payload }) => ({
      ...state,
      twoFactorAuthentication: payload,
    }),
    markFreshLogin: (state, { payload }) => ({
      ...state,
      loggedInInThisSession: payload,
    }),
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  failure,
  redirectTo,
  submitting,
  logoutSubmitting,
  blockUserAction,
  setTwoFactorAuthentication,
  markFreshLogin,
} = authSlice.actions;

export default authSlice.reducer;

export const fetchUser =
  (suppressStoreEvents = false): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      if (!suppressStoreEvents) dispatch(submitting());
      const user = await httpClient.get<User>(`${apiHostname}/api/me`);
      dispatch(markFreshLogin(false));
      dispatch(loginSuccess(user));
    } catch (err) {
      dispatch(failure(null));
    }
  };

export const logout =
  (redirect: '/logged-out' | '/login' = '/logged-out'): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await dispatch(logoutSubmitting());
      await httpClient.post(`${apiHostname}/api/auth/logout`);
    } finally {
      dispatch(markFreshLogin(false));
      dispatch(logoutSuccess());
      if (window.location.pathname !== '/login') dispatch(redirectTo(redirect));
    }
  };

export const login =
  (email: string, password: string, recaptchaToken: string): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const deviceDetails = await getDeviceDetails();

      await dispatch(submitting());
      const user = await httpClient.post(`${apiHostname}/api/auth/login`, {
        email,
        password,
        deviceDetails: JSON.stringify(deviceDetails),
        recaptchaToken,
      });

      SessionListener.setSessionEnd(user.headers['session-duration'] as unknown as number);
      SessionListener.setTimeout(() => {
        dispatch(logout());
      });

      dispatch(markFreshLogin(true));
      await dispatch(loginSuccess(user));
    } catch (err) {
      let message;
      switch ((err as AlphamartHttpError)?.response?.data?.errorCode) {
        case ErrorCode.UNRECOGNIZED_DEVICE:
          message = 'Login.Error.UnrecognizedDevice';
          break;
        case ErrorCode.INVALID_CAPTCHA_ERROR:
          message = 'Login.Error.InvalidCaptcha';
          break;
        default:
          message = 'Login.Error.WrongEmailOrPassword';
      }

      dispatch(failure({ message, code: (err as AlphamartHttpError)?.response?.data?.errorCode }));
    }
  };

export const updateUserCurrentProfitMargin =
  (data: UpdateCurrentProfitMarginParams, parseParams: ParseProfitMarginsParams): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    const parsedData = parseProfitMargins(data, parseParams);
    await httpClient.put(`${apiHostname}/api/me/current-profit-margin`, parsedData);
  };
