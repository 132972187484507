import { CaseReducer, createSlice } from '@reduxjs/toolkit';

interface State {
  isVisible: boolean;
}

const virtualKeyboardSlice = createSlice<
  State,
  { toggleKeyboard: CaseReducer<State, { type: string; payload: boolean | undefined }> }
>({
  name: 'virtualKeyboard',
  initialState: {
    isVisible: false,
  },
  reducers: {
    toggleKeyboard: (state, { payload }) => ({
      isVisible: payload ?? !state.isVisible,
    }),
  },
});

export const { toggleKeyboard } = virtualKeyboardSlice.actions;

export default virtualKeyboardSlice.reducer;
