import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { isNil, omitBy } from 'lodash';

import { FeedbackChartFiltersFormShape } from 'components/views/Feedbacks/FeedbacksSummary/FeedbacksSummaryFilters';
import { apiHostname, FeedbackReviewStatus, QuestionnaireStatus } from 'shared/constants';
import { FEEDBACK_TYPES } from 'shared/constants/feedbackTypes';
import { CompanyListItem, Feedback, FeedbacksListItem, PaginatedData } from 'shared/types';
import { getDateString } from 'shared/utils/getDateString';
import { getHttpClient } from 'shared/utils/httpClient';

export enum FeedbacksKeys {
  GetFeedback = 'GetFeedback',
  GetFeedbacks = 'GetFeedbacks',
  GetFeedbackCompanies = 'GetFeedbackCompanies',
  GetVotesCount = 'GetVotesCount',
  GetQuestionnaireStatus = 'GetQuestionnaireStatus',
}

export const useGetFeedback = (
  id: number,
  options: UseQueryOptions<Feedback> = {},
): UseQueryResult<Feedback> =>
  useQuery<Feedback>(
    [FeedbacksKeys.GetFeedback, id],
    async () => {
      const response = await getHttpClient().get<Feedback>(`${apiHostname}/api/feedback/${id}`);

      return response.data;
    },
    options,
  );

interface GetFeedbacksParams {
  page: number;
  pageSize: number;
  type: FEEDBACK_TYPES | null;
  rating: number[] | null;
  company: number | null;
  status: FeedbackReviewStatus | null;
  query?: string;
  dates?: {
    from?: Date | null;
    to?: Date | null;
  };
}

export const useGetFeedbacks = (
  params: GetFeedbacksParams,
  options: UseQueryOptions<PaginatedData<FeedbacksListItem>> = {},
): UseQueryResult<PaginatedData<FeedbacksListItem>> => {
  const { dates, ...rest } = params;

  const filters = {
    ...rest,
    query: rest.query || undefined,
    from: dates?.from && getDateString(dates.from),
    to: dates?.to && getDateString(dates.to),
  };

  return useQuery<PaginatedData<FeedbacksListItem>>(
    [FeedbacksKeys.GetFeedbacks, filters],
    async () => {
      const response = await getHttpClient().get<PaginatedData<FeedbacksListItem>>(
        `${apiHostname}/api/feedback`,
        { params: filters },
      );
      return response.data;
    },
    {
      initialData: { data: [], count: 0 },
      ...options,
    },
  );
};

export const useGetFeedbackCompanies = (
  options: UseQueryOptions<PaginatedData<CompanyListItem>> = {},
): UseQueryResult<PaginatedData<CompanyListItem>> =>
  useQuery<PaginatedData<CompanyListItem>>(
    [FeedbacksKeys.GetFeedbackCompanies],
    async () => {
      const response = await getHttpClient().get<PaginatedData<CompanyListItem>>(
        `${apiHostname}/api/feedback/companies`,
      );
      return response.data;
    },
    {
      initialData: { data: [], count: 0 },
      ...options,
    },
  );

export const useGetVotesCount = (
  params: FeedbackChartFiltersFormShape,
  options: UseQueryOptions<number[]> = {},
): UseQueryResult<number[]> =>
  useQuery<number[]>(
    [FeedbacksKeys.GetVotesCount, params],
    async () => {
      const response = await getHttpClient().get<number[]>(
        `${apiHostname}/api/feedback/votes-count`,
        { params: omitBy(params, isNil) },
      );
      return response.data;
    },
    options,
  );

type ReturnType = null | QuestionnaireStatus;

export const useGetQuestionnaireStatus = (
  options: UseQueryOptions<ReturnType> = {},
): UseQueryResult<ReturnType> =>
  useQuery<ReturnType>(
    [FeedbacksKeys.GetQuestionnaireStatus],
    async () => {
      const response = await getHttpClient().get<{
        status: ReturnType;
      }>(`${apiHostname}/api/feedback/questionnaire/should-fill`);
      return response.data.status;
    },
    {
      initialData: null,
      ...options,
    },
  );
