import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { isNil } from 'lodash';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Form } from 'components/shared/forms/Form/Form';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { FeedTypes } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { Adjustment, Metals } from 'shared/types';
import { fetchAdjustments } from 'store/adjustmentsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateMetalAdjustment } from 'store/updateMetalAdjustmentSlice';
import { updateMetalPricesFormSchema } from './updateMetalPricesFormSchema';
import { SingleMetalSettings } from './UpdateMetalPricesSingleMetalSettings';
import { messages, useTypedIntl } from '../locale/messages';

type UpdateCompanyMetalPrice = {
  type: FeedTypes;
  value: string;
  confirm: string;
};

export interface UpdateCompanyMetalsFormData {
  [Metals.PALLADIUM]: UpdateCompanyMetalPrice;
  [Metals.PLATINUM]: UpdateCompanyMetalPrice;
  [Metals.RHODIUM]: UpdateCompanyMetalPrice;
}

interface Props {
  currentAdjustments: Adjustment[];
}

const UpdateMetalPricesLayout = ({ currentAdjustments }: Props): React.ReactElement | null => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const user = useCurrentUser();
  const config = useAppSelector(state => state.config);
  const [activeInput, setActiveInput] = useState<string>('');
  useEffect(() => {
    dispatch(fetchAdjustments(user.company.id));
  }, [dispatch, user]);
  const palladiumData = currentAdjustments.find(el => el.metal === Metals.PALLADIUM)!;
  const platinumData = currentAdjustments.find(el => el.metal === Metals.PLATINUM)!;
  const rhodiumData = currentAdjustments.find(el => el.metal === Metals.RHODIUM)!;

  const sources: { label: string; value: FeedTypes }[] = [
    {
      label: intl.formatMessage({ id: 'Settings.MetalPrices.Source.MarketFeed' }),
      value: FeedTypes.MARKET_FEED,
    },
    {
      label: intl.formatMessage({ id: 'Settings.MetalPrices.Source.MarketFeedAdjusted' }),
      value: FeedTypes.MARKET_FEED_ADJUSTED,
    },
    {
      label: intl.formatMessage({ id: 'Settings.MetalPrices.Source.ManualEntry' }),
      value: FeedTypes.MANUAL_ENTRY,
    },
    config.company?.canUseAlphaPrices && {
      label: intl.formatMessage({ id: 'Settings.MetalPrices.Source.AlphaPrices' }),
      value: FeedTypes.ALPHA_PRICES,
    },
  ].filter(isTruthy);

  const validationSchema = updateMetalPricesFormSchema(
    {
      [Metals.PALLADIUM]: palladiumData?.feedPrice ?? 0,
      [Metals.PLATINUM]: platinumData?.feedPrice ?? 0,
      [Metals.RHODIUM]: rhodiumData?.feedPrice ?? 0,
    },
    intl,
  );

  const formikContext = useFormik<UpdateCompanyMetalsFormData>({
    initialValues: {
      palladium: {
        type: palladiumData?.adjustmentType,
        value: palladiumData?.adjustmentValue?.toString() ?? '0',
        confirm: palladiumData?.adjustmentValue?.toString() ?? '0',
      },
      platinum: {
        type: platinumData?.adjustmentType,
        value: platinumData?.adjustmentValue?.toString() ?? '0',
        confirm: platinumData?.adjustmentValue?.toString() ?? '0',
      },
      rhodium: {
        type: rhodiumData?.adjustmentType,
        value: rhodiumData?.adjustmentValue?.toString() ?? '0',
        confirm: rhodiumData?.adjustmentValue?.toString() ?? '0',
      },
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await dispatch(updateMetalAdjustment(user.company.id, values));
        dispatch(
          snackBarPushSuccess(intl.formatMessage({ id: 'Settings.MetalPrices.Message.Success' })),
        );
        navigate('/company/settings');
      } catch (error) {
        formikContext.setSubmitting(false);
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    getFieldMeta,
    handleBlur,
    isValidating,
    isSubmitting,
    isValid,
    setFieldValue,
  } = formikContext;

  useEffect(() => {
    if (!values.palladium.value) setFieldValue('palladium.confirm', '');
    if (!values.platinum.value) setFieldValue('platinum.confirm', '');
    if (!values.rhodium.value) setFieldValue('rhodium.confirm', '');
  }, [values.palladium.value, values.platinum.value, values.rhodium.value]);

  useEffect(() => {
    if (values.palladium.type !== palladiumData.adjustmentType) {
      setFieldValue('palladium.confirm', '');
      setFieldValue('palladium.value', '');
    }
  }, [values.palladium.type]);

  useEffect(() => {
    if (values.platinum.type !== platinumData.adjustmentType) {
      setFieldValue('platinum.confirm', '');
      setFieldValue('platinum.value', '');
    }
  }, [values.platinum.type]);

  useEffect(() => {
    if (values.rhodium.type !== rhodiumData.adjustmentType) {
      setFieldValue('rhodium.confirm', '');
      setFieldValue('rhodium.value', '');
    }
  }, [values.rhodium.type]);

  const onCancel = () =>
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Settings.MetalPrices.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/company/settings');
          dispatch(hideModal());
        },
      }),
    );

  const submitDisabled = !(isValid && Object.keys(touched).length) || isValidating || isSubmitting;
  const getErrors = (field: Metals, key: string) => {
    const meta = getFieldMeta(field);
    return meta?.touched && meta.error?.[key];
  };

  return currentAdjustments ? (
    <Form
      header={intl.formatMessage({ id: 'Settings.MetalPrices.Header' })}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={submitDisabled}
      context={formikContext}
      submitLabel={intl.formatMessage({ id: 'Global.Save' })}
    >
      <SingleMetalSettings
        sectionName="platinum"
        metal={Metals.PLATINUM}
        intl={intl}
        getErrors={getErrors}
        sources={sources}
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setActiveInput={setActiveInput}
        activeInput={activeInput}
      />
      <SingleMetalSettings
        sectionName="palladium"
        metal={Metals.PALLADIUM}
        intl={intl}
        getErrors={getErrors}
        sources={sources}
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setActiveInput={setActiveInput}
        activeInput={activeInput}
      />
      <SingleMetalSettings
        sectionName="rhodium"
        metal={Metals.RHODIUM}
        intl={intl}
        getErrors={getErrors}
        sources={sources}
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setActiveInput={setActiveInput}
        activeInput={activeInput}
      />
    </Form>
  ) : null;
};

export const UpdateMetalPrices = (): React.ReactElement | null => {
  const currentAdjustments = useAppSelector(state => state.adjustments.adjustmentRates.list);
  const user = useCurrentUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAdjustments(user.company.id));
  }, [dispatch, user?.id]);

  return !isNil(currentAdjustments) && currentAdjustments.length > 0 ? (
    <AlphamartIntlProvider messages={messages}>
      <UpdateMetalPricesLayout currentAdjustments={currentAdjustments} />
    </AlphamartIntlProvider>
  ) : null;
};
