import React, { useRef, useState } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { isEqual } from 'lodash';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Details } from 'components/shared/Details';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { LoadableContent } from 'components/shared/Loader';
import { SortableItem, SortableList } from 'components/shared/SortableList/SortableList';
import { DndTypes, ErrorCode } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useSortNonstandardConverters } from 'shared/mutations';
import { useGetNonstandardConverters } from 'shared/queries';
import { AlphamartHttpError } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, MEDIA_QUERY, theme } from 'theme';
import { messages, TypedFormattedMessage, useTypedIntl } from '../locale/messages';

const saveButtonStyles = css`
  @media ${MEDIA_QUERY.MAX_MD} {
    position: fixed;
    z-index: ${layers.floatingButton};
    bottom: 24px;
    right: 24px;
    font-size: 16px;
    background-color: ${theme.colors.mineShaftLighter};
    user-select: none;
  }
`;

const previewStyles = css`
  width: calc(100% - 72px);
  @media ${MEDIA_QUERY.MD} {
    width: calc(65% - 48px);
  }
`;

const Container = styled.div`
  padding: 20px 24px 20px 24px;
  @media (${MEDIA_QUERY.MD}) {
    padding: 20px 24px;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 12px 24px;
  flex-wrap: wrap;
  align-items: center;
`;

interface Props {
  onUpdate: () => void;
}

const SortNonstandardConvertersComponent = ({ onUpdate }: Props): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const [items, setItems] = useState<SortableItem[]>([]);

  const nonstandardConverters = useGetNonstandardConverters(undefined, {
    onSuccess: data => {
      setItems(data.data.map((p, index) => ({ id: p.id, index, content: p.material })));
    },
  });
  const sortNonstandardConverters = useSortNonstandardConverters();

  const isUnchanged =
    nonstandardConverters.isFetching ||
    isEqual(
      items.map(p => p.id),
      nonstandardConverters.data?.data.map(p => p.id),
    );

  const handleSave = async () => {
    const nonstandardConvertersIds = items.map(item => item.id);
    try {
      await sortNonstandardConverters.mutateAsync({ nonstandardConvertersIds });
      onUpdate();
      dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'SortNonstandardConverters.Result.Success' })),
      );
      navigate('/nonstandard-converters');
    } catch (error) {
      if (
        (error as AlphamartHttpError)?.response?.data?.errorData?.message ===
        ErrorCode.NONSTANDARD_CONVERTER_INVALID_IDS_IN_RANK
      ) {
        dispatch(
          snackBarPushFailure(
            intl.formatMessage({ id: 'SortNonstandardConverters.Result.Failure' }),
          ),
        );
        return nonstandardConverters.refetch();
      }
      throw error;
    }
  };

  const onBeforeClose = (onClose: () => void): void => {
    if (isUnchanged) return onClose();

    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'SortNonstandardConverters.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          dispatch(hideModal());
          return onClose();
        },
      }),
    );
  };

  return (
    <Details
      title={
        <Header>
          <span>
            <TypedFormattedMessage id="SortNonstandardConverters.Header" />
          </span>
          <AppButton
            onClick={handleSave}
            disabled={isUnchanged || sortNonstandardConverters.isLoading}
            className={saveButtonStyles}
          >
            <TypedFormattedMessage id="Global.Save" />
          </AppButton>
        </Header>
      }
      onBeforeClose={onBeforeClose}
      backUrl="/nonstandard-converters"
      ref={scrollContainerRef}
      swipeToClose={false}
    >
      <LoadableContent
        loading={nonstandardConverters.isFetching || sortNonstandardConverters.isLoading}
        mode={LoadableContent.MODE.FULL}
        drawContent
      >
        <Container>
          <SortableList
            items={items}
            setItems={setItems}
            type={DndTypes.NONSTANDARD_CONVERTERS}
            autoScrollOptions={{ scrollableElement: scrollContainerRef.current?.children[0] }}
            previewClassName={previewStyles}
          />
        </Container>
      </LoadableContent>
    </Details>
  );
};

export const SortNonstandardConverters = withAlphamartIntlProvider(
  SortNonstandardConvertersComponent,
  messages,
);
