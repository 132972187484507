import { UpdateCurrentProfitMarginParams } from 'store/auth';

export interface ParseProfitMarginsParams {
  canUseMarginSlider: boolean;
  canUseMarginSliderPerMetal: boolean;
  canSelectProfitMargin: boolean;
}

export const parseProfitMargins = (
  data: UpdateCurrentProfitMarginParams,
  {
    canUseMarginSlider,
    canUseMarginSliderPerMetal,
    canSelectProfitMargin,
  }: ParseProfitMarginsParams,
): Partial<UpdateCurrentProfitMarginParams> => ({
  saveSelectedProfitMargin: data.saveSelectedProfitMargin,
  selectedProfitMargin: canSelectProfitMargin ? data.selectedProfitMargin : undefined,
  ...(canUseMarginSlider && {
    currentProfitMarginPt: data.currentProfitMarginPt,
    currentProfitMarginPd: canUseMarginSliderPerMetal
      ? data.currentProfitMarginPd
      : data.currentProfitMarginPt,
    currentProfitMarginRh: canUseMarginSliderPerMetal
      ? data.currentProfitMarginRh
      : data.currentProfitMarginPt,
  }),
});
