import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { AlphamartHttpError, ConvertersGroup } from '../shared/types';

interface ConvertersGroupDetailsState extends GenericStoreSlice {
  convertersGroup: ConvertersGroup | null;
}

const convertersGroupDetailsSlice = createSlice<
  ConvertersGroupDetailsState,
  GenericStoreReducer<ConvertersGroupDetailsState>
>({
  name: 'convertersGroupDetails',
  initialState: {
    convertersGroup: null,
    error: undefined,
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      convertersGroup: payload,
    })),
  },
});

export const {
  pending: fetchConvertersGroupAction,
  success: fetchConvertersGroupSuccessAction,
  failure: fetchConvertersGroupFailureAction,
} = convertersGroupDetailsSlice.actions;

export const fetchConvertersGroup =
  (id: string | number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const url = `${apiHostname}/api/converters/groups/${id}`;

      dispatch(fetchConvertersGroupAction());
      const { data } = await httpClient.get(url);
      dispatch(fetchConvertersGroupSuccessAction(data));
    } catch (error) {
      dispatch(
        fetchConvertersGroupFailureAction((error as AlphamartHttpError)?.response?.data.message),
      );
      return Promise.reject(error);
    }
  };

export const clearConvertersGroup = (): ReturnType<typeof fetchConvertersGroupSuccessAction> =>
  fetchConvertersGroupSuccessAction(null);

export default convertersGroupDetailsSlice.reducer;
