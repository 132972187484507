import { createSlice } from '@reduxjs/toolkit';

import { apiHostname, ErrorCode } from 'shared/constants';
import { serializeLocationHeader } from 'shared/helpers';
import { AlphamartHttpError, Coords } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { blockUserAction } from './auth';
import { Assay } from '../shared/types';

interface AssayDetailsState extends GenericStoreSlice {
  assay: Assay | null;
}

const assayDetailsSlice = createSlice<AssayDetailsState, GenericStoreReducer<AssayDetailsState>>({
  name: 'assayDetails',
  initialState: {
    assay: null,
    error: undefined,
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      assay: payload,
    })),
  },
});

export const {
  pending: fetchAssayAction,
  success: fetchAssaySuccessAction,
  failure: fetchAssayFailureAction,
} = assayDetailsSlice.actions;

export const fetchAssay =
  (id: number, position: Coords | undefined = undefined): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchAssayAction());
      const headers = position && { 'user-location': serializeLocationHeader(position) };

      const { data } = await httpClient.get(`${apiHostname}/api/assays/${id}`, { headers });
      dispatch(fetchAssaySuccessAction(data));
    } catch (error) {
      dispatch(fetchAssayFailureAction((error as AlphamartHttpError)?.response?.data.message));

      if (
        (error as AlphamartHttpError).response?.data?.errorCode ===
        ErrorCode.USER_BLOCKED_OFF_LIMIT_AREA_ENTERED
      )
        dispatch(blockUserAction());

      return Promise.reject(error);
    }
  };

export const clearAssay = (): ReturnType<typeof fetchAssaySuccessAction> =>
  fetchAssaySuccessAction(null);

export default assayDetailsSlice.reducer;
