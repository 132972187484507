import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { isNil, isUndefined } from 'lodash';

interface Options {
  topOffset?: number;
}

export type DropdownPlacement = 'top' | 'bottom';
export type DropdownSide = 'center' | 'left' | 'right';

interface ReturnType {
  refreshDropdownPlacement: () => void;
  dropdownRef: (node?: Element | null) => void;
  dropdownPlacement: DropdownPlacement;
  dropdownSide: DropdownSide;
}

export function useDropdownPlacement(
  initialPlacement: DropdownPlacement,
  initialSide: DropdownSide = 'center',
  options: Options = {},
): ReturnType {
  const [dropdownPlacement, setDropdownPlacement] = useState<DropdownPlacement>(initialPlacement);
  const [dropdownSide, setDropdownSide] = useState<DropdownSide>(initialSide);
  const isInView = useInView({ root: document.querySelector('body') });

  const refreshDropdownPlacement = () => {
    if (isUndefined(isInView.entry) || isNil(isInView.entry.rootBounds)) return;
    const isInLowerHalfOfScreen =
      isInView.entry.boundingClientRect.top - (options.topOffset ?? 0) >
      isInView.entry.rootBounds.height / 2;
    setDropdownPlacement(isInLowerHalfOfScreen ? 'top' : 'bottom');
    const isTooFarOnRight =
      isInView.entry.rootBounds.width - isInView!.entry.boundingClientRect.right < 60 &&
      isInView.entry.boundingClientRect.width < 120;
    const isTooFarOnLeft =
      isInView.entry.rootBounds.width - isInView.entry.boundingClientRect.right >
      isInView.entry.rootBounds.width - 60;
    let side: DropdownSide = 'center';
    if (isTooFarOnRight) {
      side = 'left';
    }
    if (isTooFarOnLeft) {
      side = 'right';
    }
    setDropdownSide(side);
  };

  return {
    refreshDropdownPlacement,
    dropdownRef: isInView.ref,
    dropdownPlacement,
    dropdownSide,
  };
}
