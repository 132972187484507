import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname, GuideTypes } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum MarkGuideAsCompletedKeys {
  MarkGuideAsCompleted = 'MarkGuideAsCompleted',
}

export const useMarkGuideAsCompleted = (
  options?: UseMutationOptions<void, AlphamartHttpError, GuideTypes>,
) =>
  useMutation<void, AlphamartHttpError, GuideTypes>(
    async (guideType: GuideTypes) => {
      await getHttpClient().put<number>(`${apiHostname}/api/me/guides`, {
        guideType,
      });
    },
    {
      ...options,
      mutationKey: [MarkGuideAsCompletedKeys.MarkGuideAsCompleted],
    },
  );
