import * as yup from 'yup';

import { CompanyTypes } from 'shared/constants';
import { Country, Province } from 'shared/types';
import { CompanyMessages, TypedIntlShape } from '../locale/messages';

export const companyFormSchema = (
  countries: Country[],
  states: Province[],
  intl: TypedIntlShape,
  editMode: boolean,
  allowedToChangeSubdomain: boolean,
  companyType?: CompanyTypes,
): yup.AnyObjectSchema => {
  const prefix = 'CompanyForm.Errors.';
  const textFieldSchema = (name: string, min: number): yup.StringSchema =>
    yup
      .string()
      .trim()
      .min(min, intl.formatMessage({ id: `${prefix}${name}.TooSmall` as keyof CompanyMessages }))
      .required(intl.formatMessage({ id: `${prefix}${name}.Required` as keyof CompanyMessages }));

  const decimalSchema = (name: string, min: number, max: number): yup.AnySchema =>
    yup
      .mixed()
      .required(intl.formatMessage({ id: `${prefix}${name}.Required` as keyof CompanyMessages }))
      .test({
        message: intl.formatMessage({
          id: `${prefix}${name}.CharactersAllowed` as keyof CompanyMessages,
        }),
        test: value => /^-?\d+(\.\d+)?$/.test(value),
      })
      .test({
        message: intl.formatMessage({ id: `${prefix}${name}.Precision` as keyof CompanyMessages }),
        test: value => /^-?\d+(\.\d{1,2})?$/.test(value),
      })
      .test({
        message: intl.formatMessage({ id: `${prefix}${name}.TooSmall` as keyof CompanyMessages }),
        test: value => Number(value) >= min,
      })
      .test({
        message: intl.formatMessage({ id: `${prefix}${name}.TooBig` as keyof CompanyMessages }),
        test: value => Number(value) <= max,
      })
      .transform(value => (value?.toString()?.trim().length ? +value : null));

  const integerSchema = (name: string, min: number, max: number): yup.AnySchema =>
    yup
      .number()
      .typeError(intl.formatMessage({ id: `${prefix}${name}.Integer` as keyof CompanyMessages }))
      .integer(intl.formatMessage({ id: `${prefix}${name}.Integer` as keyof CompanyMessages }))
      .required(intl.formatMessage({ id: `${prefix}${name}.Required` as keyof CompanyMessages }))
      .min(min, intl.formatMessage({ id: `${prefix}${name}.TooSmall` as keyof CompanyMessages }))
      .max(max, intl.formatMessage({ id: `${prefix}${name}.TooBig` as keyof CompanyMessages }));

  const zipCodeSchema = yup
    .string()
    .test({
      message: intl.formatMessage({ id: 'CompanyForm.Errors.Type.ZipCode' }),
      test(val) {
        const country = countries.find(({ id }) => id === this.parent.country);
        if (country?.alphaCode3 === 'USA' && val) return /^\d{5}(-\d{4})?$/.test(val);
        if (country?.alphaCode3 === 'CAN' && val) return /^[A-Z]\d[A-Z] ?\d[A-Z]\d$/.test(val);
        return true;
      },
    })
    .when('countryId', {
      is: val => {
        const country = countries.find(({ id }) => id === val);

        return country?.alphaCode3 === 'USA' || country?.alphaCode3 === 'CAN';
      },
      then: yup
        .string()
        .required(intl.formatMessage({ id: 'CompanyForm.Errors.ZipCode.Required' })),
    })
    .uppercase();

  const subdomainSchema = yup.string().when('type', {
    is: type => type === CompanyTypes.SISTER,
    then: yup
      .string()
      .matches(
        /^[a-z\d-]+$/,
        intl.formatMessage({ id: 'CompanyForm.Errors.Subdomain.CharactersAllowed' }),
      )
      .matches(
        /^(?!.*--)[a-z\d](?:[a-z\d-]*[a-z\d])?$/,
        intl.formatMessage({ id: 'CompanyForm.Errors.Subdomain.HyphenPosition' }),
      )
      .required(intl.formatMessage({ id: 'CompanyForm.Errors.Subdomain.Required' })),
    otherwise: yup
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value)),
  });

  return yup.object().shape({
    name: textFieldSchema('Name', 3),
    ...(editMode
      ? null
      : {
          type: yup
            .string()
            .required(intl.formatMessage({ id: 'CompanyForm.Errors.Type.Required' }))
            .nullable(),
          parentCompany: yup
            .number()
            .required(intl.formatMessage({ id: 'CompanyForm.Errors.ParentCompany.Required' }))
            .nullable(),
        }),
    ...(allowedToChangeSubdomain && { subdomain: subdomainSchema }),
    ...(editMode && {
      maxAssayUsers: integerSchema(
        'MaxAssayUsers',
        companyType === CompanyTypes.ASSAY ? 1 : 0,
        1000000,
      ),
    }),
    street: textFieldSchema('Street', 0),
    city: textFieldSchema('City', 0),
    country: yup
      .number()
      .nullable()
      .required(intl.formatMessage({ id: 'CompanyForm.Errors.Country.Required' })),
    state: states?.length
      ? yup.string().required(intl.formatMessage({ id: 'CompanyForm.Errors.State.Required' }))
      : yup.string(),
    zipCode: zipCodeSchema,
    note: yup.string(),
    terms: yup.object().shape({
      treatmentChargePerPound: decimalSchema('TreatmentChargePerPound', 0, 100000),
      metalsReturnTermInDays: integerSchema('MetalsReturnTermInDays', 0, 150),
      metalsReturnFinanceRate: decimalSchema('MetalsReturnFinanceRate', 0, 100),
      platinumReturnRate: decimalSchema('PlatinumReturnRate', 0, 100),
      palladiumReturnRate: decimalSchema('PalladiumReturnRate', 0, 100),
      rhodiumReturnRate: decimalSchema('RhodiumReturnRate', 0, 100),
    }),
  });
};
