import { apiHostname, SUPPORTED_PORTAL_CURRENCIES } from 'shared/constants';
import { GenericThunk } from './shared/createGenericStoreSlice';

type CreateCurrencyAdjustmentParam = {
  adjustment: number | null;
  currency: SUPPORTED_PORTAL_CURRENCIES;
};
export const createCurrencyAdjustment =
  (params: CreateCurrencyAdjustmentParam): GenericThunk =>
  async (dispatch, getState, httpClient) =>
    httpClient.post(`${apiHostname}/api/exchange-rates/adjustments`, params);
