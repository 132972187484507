import { zonedTimeToUtc } from 'helpers/dateTime/dateTime';
import { HedgeFormSchema } from '../types/hedgeFormSchema ';
import { HedgeUpdateSchema } from '../types/hedgeUpdateSchema';

export const parseHedge = (hedge: HedgeFormSchema, editMode = false): HedgeUpdateSchema => ({
  name: hedge.name.replace(/\s+/g, ' ').trim(),
  relatedLot: hedge.relatedLot,
  relatedCompanies: hedge.relatedCompanies,
  note: hedge.note,
  ptPriceCust: hedge.ptPriceCust === '' ? null : Number(hedge.ptPriceCust),
  pdPriceCust: hedge.pdPriceCust === '' ? null : Number(hedge.pdPriceCust),
  rhPriceCust: hedge.rhPriceCust === '' ? null : Number(hedge.rhPriceCust),
  placedAt: zonedTimeToUtc(hedge.placedAt!),
  expiresAt: zonedTimeToUtc(hedge.expiresAt),
  parentCompany: editMode ? undefined : hedge.parentCompany,
  assignedUsersIds: hedge.assignedUsers,
});
