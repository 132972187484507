import { CaseReducer, createSlice } from '@reduxjs/toolkit';

interface State {
  isVisible: boolean;
}
const userProfileSlice = createSlice<
  State,
  {
    openProfile: CaseReducer<State>;
    closeProfile: CaseReducer<State>;
  }
>({
  name: 'userProfile',
  initialState: {
    isVisible: false,
  },
  reducers: {
    openProfile: () => ({
      isVisible: true,
    }),
    closeProfile: () => ({
      isVisible: false,
    }),
  },
});

export const { openProfile, closeProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
