import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import filterIcon from 'assets/images/icons/filter.svg';
import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { MapComponent } from 'components/shared/MapComponent';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { DEFAULT_FILTERS, FilterableModules } from 'shared/constants';
import { randomHexColor } from 'shared/helpers';
import { UserLocationMarker } from 'shared/types';
import {
  clearLocationsAction,
  fetchLocations,
  LocationItem,
  ShapeParam,
} from 'store/locationsSlice';
import { fetchOffZones } from 'store/offZonesSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, Theme } from 'theme';
import { DrawingPanel } from './DrawingPanel';
import { MapFilters, MapFiltersShape } from './MapFilters';
import { messages, TypedFormattedMessage, useTypedIntl } from '../locale/messages';

const mainContentAdditionalClass = css`
  padding: 0;

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 0;
  }
`;

const Header = styled.h1`
  position: absolute;
  z-index: 1;
  top: 32px;
  left: 36px;
  font-weight: normal;
  font-size: 18px;

  @media ${MEDIA_QUERY.MAX_MD} {
    top: 0px;
  }
`;

const StyledButton = styled.div<{ theme?: Theme }>`
  & div {
    margin-bottom: 0px;
  }

  & button {
    top: 60px;
    position: absolute;
    z-index: 3;
    right: 16px;
    background-color: ${({ theme }) => theme.colors.mineShaftLighter};

    ::before {
      border-color: ${({ theme }) => theme.colors.mineShaftLighter};
    }
  }
`;

function UserLocationsView(): React.ReactElement {
  const dispatch = useAppDispatch();
  const { position } = useAppSelector(state => state.position);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.GEOLOCATION]);
  const { drawingMode } = useAppSelector(state => state.mapDrawing);
  const { locations } = useAppSelector(state => state.locations);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [savedShape, saveShape] = useState({});
  const [savedColors, saveColors] = useState({});
  const intl = useTypedIntl();

  const onFiltersToggle = useCallback(() => setFiltersOpen(!filtersOpen), [filtersOpen]);

  useEffect(() => {
    // Remove possible existing locations
    dispatch(clearLocationsAction());
    dispatch(fetchOffZones());
    return () => {
      dispatch(
        saveFilters({
          filterKey: FilterableModules.GEOLOCATION,
          values: DEFAULT_FILTERS.geolocation,
        }),
      );
    };
  }, []);

  const getMarkers = (shape: ShapeParam, filters?: MapFiltersShape) => {
    dispatch(fetchLocations(shape, filters ?? savedFilters.data));
    saveShape(shape);
  };

  const userColors = useMemo(() => {
    const colors = locations.reduce(
      (acc, { user }) => ({
        ...acc,
        ...(Object.keys(acc).includes(`${user.id}`)
          ? {}
          : {
              [user.id]: {
                color: savedColors[user.id]?.color ?? randomHexColor(63),
                label: `${user.firstName} ${user.lastName}`,
              },
            }),
      }),
      {},
    );

    saveColors(colors);
    return colors;
  }, [locations]);

  const parseMark = (id: number, location: LocationItem) => ({
    id,
    position: {
      lat: location.latitude,
      lng: location.longitude,
    },
    user: location.user,
    lastSeen: (location.updatedAt ?? location.createdAt) as unknown as Date,
    color: userColors[location.user.id].color,
  });

  const markers = useMemo(
    () =>
      locations.reduce<(UserLocationMarker | UserLocationMarker[])[]>((acc, location, id) => {
        const sameLocationMark = acc.find((l, i) => {
          const found = Array.isArray(l)
            ? l[0].position.lat === location.latitude && l[0].position.lng === location.longitude
            : l.position.lat === location.latitude && l.position.lng === location.longitude;

          if (found) acc?.splice(i, 1);

          return found;
        });

        if (sameLocationMark) {
          const newLocations = Array.isArray(sameLocationMark)
            ? sameLocationMark
            : [sameLocationMark];
          acc.push([...newLocations, parseMark(id, location)]);
        } else {
          acc.push(parseMark(id, location));
        }

        return acc;
      }, []),
    [locations],
  );

  const onFiltersChanged = changed => {
    dispatch(saveFilters({ filterKey: FilterableModules.GEOLOCATION, values: changed }));
    getMarkers(savedShape, { ...savedFilters, ...changed });
  };

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelTemplate>
        <PanelContent>
          <PanelContentMain className={mainContentAdditionalClass}>
            <Header>
              <TypedFormattedMessage id="Geolocation.Map" />
            </Header>
            {!drawingMode && (
              <StyledButton>
                <SearchBar
                  searchInputVisible={false}
                  onFiltersToggle={onFiltersToggle}
                  savedFilters={savedFilters}
                />
              </StyledButton>
            )}
            <DrawingPanel getMarkers={getMarkers} />
            <MapComponent
              initialPosition={position}
              markers={markers}
              getMarkers={getMarkers}
              filters={savedFilters.data}
              drawingMode={drawingMode}
            />
          </PanelContentMain>
          {!drawingMode && (
            <PanelContentSidebar
              header={intl.formatMessage({ id: 'Global.Filters' })}
              headerIcon={filterIcon}
              open={filtersOpen}
              onSidebarClosed={onFiltersToggle}
            >
              <MapFilters onFiltersChanged={onFiltersChanged} onFiltersApplied={onFiltersToggle} />
            </PanelContentSidebar>
          )}
        </PanelContent>
      </PanelTemplate>
    </AlphamartIntlProvider>
  );
}

export { UserLocationsView };
