import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { store } from '../../index';

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
