import React, { useMemo } from 'react';
import { useFormik } from 'formik';

import { FieldDatePickerRange } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import { FieldSelectOption, FilterSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { DEFAULT_FILTERS, FilterableModules } from 'shared/constants';
import { FeedbackReviewStatus } from 'shared/constants/feedbackReviewStatus';
import { FEEDBACK_TYPES } from 'shared/constants/feedbackTypes';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetFeedbackCompanies } from 'shared/queries';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface FeedbacksFiltersShape {
  status: FeedbackReviewStatus | null;
  type: FEEDBACK_TYPES | null;
  rating: number[] | null;
  dates: { from: Date | null; to: Date | null };
  company: number | null;
  query?: string;
}

interface Props {
  onFiltersChanged: (filters: FeedbacksFiltersShape) => void;
  onFiltersApplied: () => void;
}

export const FeedbacksFilters = ({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.FEEDBACKS]);

  const formikContext = useFormik<FeedbacksFiltersShape>({
    initialValues: savedFilters.data,
    onSubmit: () => {},
  });
  const { setFieldValue, setValues, values } = formikContext;
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.feedbacks);
    onFiltersChanged(DEFAULT_FILTERS.feedbacks);
  };

  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };

  const datesClearable = !!(values?.dates?.from || values?.dates?.to);

  const onDatesClear = () => {
    setFieldValue('dates', DEFAULT_FILTERS.feedbacks.dates);
    onFiltersChanged({ ...values, dates: DEFAULT_FILTERS.feedbacks.dates });
  };

  const companiesForFilters = useGetFeedbackCompanies();

  const companyOptions = useMemo<FieldSelectOption[]>(
    () =>
      companiesForFilters.data?.data.map(company => ({ value: company.id, label: company.name })) ??
      [],
    [companiesForFilters.data?.data],
  );

  const ratingOptions: FieldSelectOption[] = [1, 2, 3, 4, 5].map(rating => ({
    value: rating,
    label: rating.toString(),
  }));

  const typeOptions: FieldSelectOption[] = [
    FEEDBACK_TYPES.WITH_COMMENT,
    FEEDBACK_TYPES.WITHOUT_COMMENT,
  ].map(type => ({
    value: type,
    label: intl.formatMessage({ id: `Feedbacks.Filters.Types.${type}` }),
  }));

  const statusValues = Object.values(FeedbackReviewStatus);
  const statusOptions: FieldSelectOption[] = statusValues.map(value => ({
    value,
    label: intl.formatMessage({ id: `Feedbacks.Filters.Status.${value}` }),
  }));

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection
        sectionName="status"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Status' })}
      >
        <FilterSelect name="status" options={statusOptions} value={values.status} />
      </FiltersSection>
      <FiltersSection
        sectionName="company"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Company' })}
      >
        <FilterSelect
          name="company"
          options={companyOptions}
          label={intl.formatMessage({ id: 'Feedbacks.Filters.Company' })}
          value={values.company}
          clearable
        />
      </FiltersSection>
      <FiltersSection
        sectionName="type"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Type' })}
      >
        <FilterSelect
          name="type"
          options={typeOptions}
          label={intl.formatMessage({ id: 'Feedbacks.Filters.Type' })}
          value={values.type}
          clearable
        />
      </FiltersSection>
      <FiltersSection
        sectionName="rating"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Rating' })}
      >
        <FilterSelect
          name="rating"
          options={ratingOptions}
          label={intl.formatMessage({ id: 'Feedbacks.Filters.Rating' })}
          value={values.rating}
          clearable
          multi
        />
      </FiltersSection>

      <FiltersSection
        sectionName="dates"
        label={intl.formatMessage({ id: 'Feedbacks.Filters.Dates' })}
        clearable={datesClearable}
        onSectionClear={onDatesClear}
      >
        <FieldDatePickerRange name="dates" value={values.dates} />
      </FiltersSection>
    </FiltersForm>
  );
};
