import React from 'react';
import styled from '@emotion/styled';

import { Badge } from 'components/shared/Badge';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useMediaQuery } from 'shared/hooks';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { openProfile } from 'store/shared/userProfileSlice';
import { MEDIA_QUERY, Theme } from 'theme';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';
import { TextButton } from '../Buttons';

const BadgeText = styled.span<{ theme?: Theme; isMobile: boolean }>`
  color: ${({ theme }) => theme.mainColor};
  ${({ isMobile }) =>
    isMobile ? 'font-size: 0.7em; span { margin-bottom: 0; };' : 'padding: 0 0px;'};
`;
export function HedgePriceInUseBadge(): React.ReactElement {
  const dispatch = useAppDispatch();
  const prices = useAppSelector(state => state.auth.user?.data.prices);
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_MD);
  const sources = [prices?.ptPriceSource, prices?.pdPriceSource, prices?.rhPriceSource];
  const usingHedge = sources.includes('HEDGE');
  const usingMarket = sources.includes('MARKET');
  const priceSource = `${usingHedge ? 'hedge' : ''}${usingMarket ? 'market' : ''}` as
    | 'market'
    | 'hedge';

  return (
    <AlphamartIntlProvider>
      <BadgeText isMobile={isMobile} data-cy={`${priceSource}-price-in-use`}>
        <Badge>
          <TextButton onClick={() => dispatch(openProfile())}>
            <FormattedMessage id={`Global.User.${priceSource || 'market'}PricesInUse`} />
          </TextButton>
        </Badge>
      </BadgeText>
    </AlphamartIntlProvider>
  );
}
