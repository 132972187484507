import { NonstandardConverterFormShape } from 'components/views/NonstandardConverters/NonstandardConvertersForm/NonstandardConvertersForm';
import { checkNumber } from 'shared/checkers';
import { NonstandardConverter } from 'shared/types';

export function parseNonstandardConverter(
  data: NonstandardConverterFormShape,
): NonstandardConverter {
  return {
    material: data.material,
    materialUnit: data.pricePer,
    buyingPercentAdjustment: checkNumber(data.buyingPercentAdjustment),
    dollarPriceAdjustment: checkNumber(data.dollarPriceAdjustment),
    weightDryLbs: checkNumber(data.weightDryLbs),
    platinumAssay: checkNumber(data.platinumAssay),
    palladiumAssay: checkNumber(data.palladiumAssay),
    rhodiumAssay: checkNumber(data.rhodiumAssay),
  };
}
