import { formatDistance as externalFormatDistance } from 'date-fns';
import format from 'date-fns/format';
import { zonedTimeToUtc as externalZonedTimeToUtc } from 'date-fns-tz';
import { range } from 'lodash';

import { FieldSelectOption } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { Language, Languages, LocalesByLanguage, SHARED } from 'shared/constants';
import { store } from '../../index';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const getLocaleOptions = (): { locale: Locale } => {
  const { language } = store.getState().currentLanguage;
  return { locale: LocalesByLanguage[language] };
};

export const formatDate = (
  dateTime: Date | string | number | null | undefined,
  dateFormat = 'd MMMM yyyy',
): string => {
  if (!dateTime) {
    return SHARED.LONG_DASH;
  }

  return format(new Date(dateTime), dateFormat, getLocaleOptions());
};

export const formatDateWithTime = (dateTime: Date | string | number): string => {
  if (!dateTime) {
    return SHARED.LONG_DASH;
  }

  const { language } = store.getState().currentLanguage;
  const pattern = getDateTimeFormat(language);

  return format(new Date(dateTime), pattern, getLocaleOptions());
};

export const getDateFormat = (language: Language): string => {
  const dateFormat = language !== Languages.EN ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  return dateFormat;
};

export const getDateTimeFormat = (language: Language): string => {
  const dateTimeFormat = language !== Languages.EN ? 'dd/MM/yyyy HH:mm' : 'MM/dd/yyyy hh:mm a';
  return dateTimeFormat;
};

export const getDateTimeFormatForExport = (language: Language): string => {
  const dateTimeFormat = language !== Languages.EN ? 'dd-MM-yyyy hh:mm' : 'MM-dd-yyyy hh:mm a';
  return dateTimeFormat;
};

export const getYearOptions = (
  startYear = 1990,
  endYear = new Date().getFullYear(),
): FieldSelectOption[] =>
  range(endYear, startYear - 1).map(i => ({ value: i, label: i.toString() }));

export const zonedTimeToUtc = (date: Date | string | number) =>
  externalZonedTimeToUtc(date, timeZone);

export const distanceFromNow = (date: Date | string | number): string =>
  externalFormatDistance(new Date(date), new Date(), getLocaleOptions());
