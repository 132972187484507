import React from 'react';
import Select from 'react-select';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { isNull } from 'lodash';

import { changePageSizeAction } from 'store/listComponentSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { FieldSelectOption, SelectStyles } from '../Fields/FieldSelect/FieldSelect';
import { FieldSelectStyled } from '../Fields/FieldSelect/FieldSelect.styles';

const optionValues = [12, 24, 48];
const options: FieldSelectOption[] = optionValues.map(value => ({ label: String(value), value }));

const PageSizeSelect = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { pageSize } = useAppSelector(state => state.listComponent);
  const value = options.find(option => option.value === pageSize);
  const styles: SelectStyles = FieldSelectStyled(
    false,
    false,
    {
      container: {
        minWidth: 96,
      },
      menu: {
        width: 96,
      },
    },
    null,
    'top',
  );
  const handleChange = (val: OnChangeValue<FieldSelectOption, false>) => {
    // page size is stored globally, because of business requirement to keep it save through session
    !isNull(val) && dispatch(changePageSizeAction(+val.value));
  };

  return (
    <Select
      id="page-size-select"
      name="pageSize"
      inputId="pageSize"
      onChange={handleChange}
      options={options}
      value={value}
      isSearchable={false}
      styles={styles}
      openMenuOnFocus
      data-cy="page-size-select"
      menuPlacement="top"
    />
  );
};

export { PageSizeSelect };
