import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { NonstandardConverterListItem, PaginatedData } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum NonstandardConvertersKeys {
  GetNonstandardConverters = 'GetNonstandardConverters',
  GetNonstandardConverter = 'GetNonstandardConverter',
}

interface GetNonstandardConvertersParams {
  page: number;
  pageSize: number;
  query?: string;
  searchSession?: string;
}

export const useGetNonstandardConverters = (
  params?: GetNonstandardConvertersParams,
  options: UseQueryOptions<PaginatedData<NonstandardConverterListItem>> = {},
): UseQueryResult<PaginatedData<NonstandardConverterListItem>> =>
  useQuery<PaginatedData<NonstandardConverterListItem>>(
    [NonstandardConvertersKeys.GetNonstandardConverters, params],
    async () => {
      const response = await getHttpClient().get<PaginatedData<NonstandardConverterListItem>>(
        `${apiHostname}/api/nonstandard-converters`,
        { params },
      );
      return response.data;
    },
    {
      initialData: { data: [], count: 0 },
      ...options,
    },
  );

export const useGetNonstandardConverter = (
  id: string,
  options: UseQueryOptions<NonstandardConverterListItem> = {},
): UseQueryResult<NonstandardConverterListItem> =>
  useQuery<NonstandardConverterListItem>(
    [NonstandardConvertersKeys.GetNonstandardConverter, id],
    async () => {
      const response = await getHttpClient().get<NonstandardConverterListItem>(
        `${apiHostname}/api/nonstandard-converters/${id}`,
      );
      return response.data;
    },
    {
      ...options,
    },
  );
