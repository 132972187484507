import * as yup from 'yup';

import { SHARED } from 'shared/constants';
import {
  CONVERTER_IDENTIFIER,
  NUMBER_3_DIGITS_PRECISION,
  NUMBER_DECIMAL,
  SAMPLE_NAME,
} from 'shared/constants/regularExpressions';
import { TypedIntlShape } from '../locale/messages';

const notNegativeTest = {
  name: 'notNegative',
  test: value => {
    if (typeof value !== 'string' || Number.isNaN(Number(value))) return true;
    return Number(value) >= 0;
  },
};

const isExistingOption = (options, value) => options.some(option => value === option);

export const assayFormSchema = (
  allowedSize: number,
  allowedExtensions: string[],
  modelOptions: string[],
  makeOptions: string[],
  intl: TypedIntlShape,
): yup.AnyObjectSchema =>
  yup.object().shape({
    converter: yup.object().shape({
      identifier: yup
        .string()
        .uppercase()
        .min(2, intl.formatMessage({ id: 'ConverterForm.Errors.Identifier.TooShort' }))
        .matches(
          CONVERTER_IDENTIFIER,
          intl.formatMessage({ id: 'ConverterForm.Errors.Identifier.NotMatchRegex' }),
        )
        .required(intl.formatMessage({ id: 'ConverterForm.Errors.Identifier.Required' })),
      identifierConfirmation: yup
        .string()
        .uppercase()
        .test({
          name: 'isValid',
          message: intl.formatMessage({
            id: 'ConverterForm.Errors.IdentifierConfirmation.NotMatch',
          }),
          test(val) {
            return val === this.parent.identifier;
          },
        })
        .test({
          name: 'isRequired',
          message: intl.formatMessage({
            id: 'ConverterForm.Errors.IdentifierConfirmation.Required',
          }),
          test(val) {
            const identifier = this.parent.identifier as string;
            return !identifier || val === identifier;
          },
        })
        .matches(CONVERTER_IDENTIFIER, {
          message: intl.formatMessage({
            id: 'ConverterForm.Errors.IdentifierConfirmation.NotMatchRegex',
          }),
        })
        .min(2, intl.formatMessage({ id: 'ConverterForm.Errors.IdentifierConfirmation.TooShort' })),
      partName: yup
        .string()
        .uppercase()
        .matches(SAMPLE_NAME, {
          message: intl.formatMessage({ id: 'ConverterForm.Errors.PartName.NotMatchRegex' }),
        }),
      nicknames: yup.string(),
      otherNumbers: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            id: yup.mixed().notRequired(),
          }),
        )
        .max(10),
      notes: yup.string().when('counterfeit', {
        is: true,
        then: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'ConverterForm.Errors.Notes.Required' })),
      }),
      vehicle: yup.object().shape({
        make: yup.string().test({
          name: 'NotMatchRegex',
          message: intl.formatMessage({ id: 'ConverterForm.Errors.Make.NotMatchRegex' }),
          test: value =>
            !value || isExistingOption(makeOptions, value) || /^[0-9A-Z\- /.+]+$/.test(value),
        }),
        model: yup.string().test({
          name: 'NotMatchRegex',
          message: intl.formatMessage({ id: 'ConverterForm.Errors.Model.NotMatchRegex' }),
          test: value =>
            !value || isExistingOption(modelOptions, value) || /^[0-9A-Z\- /.+]+$/.test(value),
        }),
      }),
      photos: yup
        .array()
        .max(10, intl.formatMessage({ id: 'AssaysForm.Errors.Photos.TooLong' }))
        .test(
          'fileExtension',
          f =>
            intl.formatMessage(
              { id: 'AssaysForm.Errors.Photos.InvalidExtension' },
              {
                fileName: f.value.find(
                  ({ name }) => !new RegExp(`${allowedExtensions.join('|')}$`, 'i').test(name),
                )?.name,
              },
            ),
          files =>
            !files ||
            files.length === 0 ||
            files.every(file => new RegExp(`${allowedExtensions.join('|')}$`, 'i').test(file.name)),
        )
        .test(
          'fileSize',
          f =>
            intl.formatMessage(
              { id: 'AssaysForm.Errors.Photos.FileSize' },
              { fileName: f.value.find(({ size }) => size > allowedSize)?.name },
            ),
          files => !files || files.length === 0 || files.every(file => file.size <= allowedSize),
        ),
      type: yup
        .number()
        .required(intl.formatMessage({ id: 'ConverterForm.Errors.Type.Required' }))
        .nullable(),
      isPartial: yup
        .string()
        .required(intl.formatMessage({ id: 'ConverterForm.Errors.IsPartial.Required' })),
      folder: yup
        .number()
        .required(intl.formatMessage({ id: 'ConverterForm.Errors.Folder.Required' }))
        .nullable(),
    }),
    sampleName: yup
      .string()
      .uppercase()
      .min(6, intl.formatMessage({ id: 'AssaysForm.Errors.SampleName.TooShort' }))
      .matches(SAMPLE_NAME, {
        message: intl.formatMessage({ id: 'AssaysForm.Errors.SampleName.NotMatchRegex' }),
      })
      .test({
        message: intl.formatMessage({ id: `AssaysForm.Errors.SampleName.Prefix` }),
        test: value => !!value?.startsWith(`${SHARED.ASSAY_PREFIX}`),
      })
      .required(intl.formatMessage({ id: 'AssaysForm.Errors.Required' })),
    sampleDate: yup.string().required(intl.formatMessage({ id: 'AssaysForm.Errors.Required' })),
    wetWeight: yup
      .string()
      .matches(NUMBER_DECIMAL, {
        message: intl.formatMessage({ id: 'AssaysForm.Errors.WetWeight.NotMatchRegex' }),
      })
      .matches(NUMBER_3_DIGITS_PRECISION, {
        message: intl.formatMessage({
          id: 'AssaysForm.Errors.WetWeight.NumberOfDigitsAfterDotTooLong',
        }),
      })
      .test({
        name: 'greaterThan0',
        message: intl.formatMessage({ id: 'AssaysForm.Errors.WetWeight.GreaterThan' }),
        test(val) {
          if (!val) {
            return true;
          }
          return Number(val) > 0;
        },
      }),
    confirmWetWeight: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'AssaysForm.Errors.WetWeightConfirmation.NotMatch' }),
      test(val) {
        return val === this.parent.wetWeight;
      },
    }),
    moisture: yup
      .string()
      .min(1, intl.formatMessage({ id: 'AssaysForm.Errors.Moisture.TooShort' }))
      .test({
        ...notNegativeTest,
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Moisture.NotNegative' }),
      })
      .matches(/^\d+(\.\d+)*$/, {
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Moisture.NotMatchRegex' }),
      })
      .matches(NUMBER_3_DIGITS_PRECISION, {
        message: intl.formatMessage({
          id: 'AssaysForm.Errors.Moisture.NumberOfDigitsAfterDotTooLong',
        }),
      })
      .test({
        name: 'greaterThan30',
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Moisture.LessThan' }),
        test(val) {
          if (!val) {
            return true;
          }
          return Number(val) <= 30;
        },
      }),
    confirmMoisture: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'AssaysForm.Errors.MoistureConfirmation.NotMatch' }),
      test(val) {
        return val === this.parent.moisture;
      },
    }),
    carbon: yup
      .string()
      .min(1, intl.formatMessage({ id: 'AssaysForm.Errors.Carbon.TooShort' }))
      .test({
        ...notNegativeTest,
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Carbon.NotNegative' }),
      })
      .matches(/^\d+(\.\d+)*$/, {
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Carbon.NotMatchRegex' }),
      })
      .matches(NUMBER_3_DIGITS_PRECISION, {
        message: intl.formatMessage({
          id: 'AssaysForm.Errors.Carbon.NumberOfDigitsAfterDotTooLong',
        }),
      })
      .test({
        name: 'greaterThan0',
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Carbon.LessThan' }),
        test(val) {
          if (!val) {
            return true;
          }
          return Number(val) <= 100;
        },
      }),
    platinum: yup
      .string()
      .min(1, intl.formatMessage({ id: 'AssaysForm.Errors.Platinum.TooShort' }))
      .test({
        ...notNegativeTest,
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Platinum.NotNegative' }),
      })
      .matches(/^\d+(\.\d+)*$/, {
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Platinum.NotMatchRegex' }),
      })
      .matches(NUMBER_3_DIGITS_PRECISION, {
        message: intl.formatMessage({
          id: 'AssaysForm.Errors.Platinum.NumberOfDigitsAfterDotTooLong',
        }),
      }),
    confirmPlatinum: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'AssaysForm.Errors.PlatinumConfirmation.NotMatch' }),
      test(val) {
        return val === this.parent.platinum;
      },
    }),
    palladium: yup
      .string()
      .min(1, intl.formatMessage({ id: 'AssaysForm.Errors.Palladium.TooShort' }))
      .test({
        ...notNegativeTest,
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Palladium.NotNegative' }),
      })
      .matches(/^\d+(\.\d+)*$/, {
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Palladium.NotMatchRegex' }),
      })
      .matches(NUMBER_3_DIGITS_PRECISION, {
        message: intl.formatMessage({
          id: 'AssaysForm.Errors.Palladium.NumberOfDigitsAfterDotTooLong',
        }),
      }),
    confirmPalladium: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'AssaysForm.Errors.PalladiumConfirmation.NotMatch' }),
      test(val) {
        return val === this.parent.palladium;
      },
    }),
    rhodium: yup
      .string()
      .min(1, intl.formatMessage({ id: 'AssaysForm.Errors.Rhodium.TooShort' }))
      .test({
        ...notNegativeTest,
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Rhodium.NotNegative' }),
      })
      .matches(/^\d+(\.\d+)*$/, {
        message: intl.formatMessage({ id: 'AssaysForm.Errors.Rhodium.NotMatchRegex' }),
      })
      .matches(NUMBER_3_DIGITS_PRECISION, {
        message: intl.formatMessage({
          id: 'AssaysForm.Errors.Rhodium.NumberOfDigitsAfterDotTooLong',
        }),
      }),
    confirmRhodium: yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'AssaysForm.Errors.RhodiumConfirmation.NotMatch' }),
      test(val) {
        return val === this.parent.rhodium;
      },
    }),
    note: yup.string(),
  });
