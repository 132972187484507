import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const StyledControls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 36px;

  @media ${MEDIA_QUERY.XL} {
    max-width: 829px;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 0 24px;
    margin-bottom: 24px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    flex-direction: column;
  }
`;

export const StyledBackButton = styled.div`
  button {
    display: flex;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100%;
    margin-bottom: 5px;

    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const StyledBackIcon = styled.span<{ right?: boolean; left?: boolean; icon?: string }>(
  ({ right, left, icon }) => ({
    display: 'block',
    transform: right ? 'rotate(180deg)' : 'rotate(0)',
    height: '15px',
    width: '10px',
    maskImage: `url(${icon})`,
    maskPosition: 'center',
    maskRepeat: 'no-repeat',
    maskSize: 'auto',
    marginLeft: right ? '15px' : '0px',
    marginRight: left ? '15px' : '0px',
    backgroundColor: `silver`,
  }),
);

export const StyledButtonsWrapper = styled.div<{ theme?: Theme }>`
  display: flex;

  button {
    display: flex;

    @media ${MEDIA_QUERY.MAX_MD} {
      width: 100%;
      justify-content: center;
    }

    span {
      background-color: ${({ theme }) => theme.mainColor};
    }

    &:disabled {
      span {
        background-color: ${({ theme }) => theme.colors.mineShaft};
      }
    }
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    flex-direction: column;
  }
`;

export const StyledCreateButtonWrapper = styled.div`
  margin-left: 22px;

  @media ${MEDIA_QUERY.MAX_MD} {
    margin-top: 20px;
    margin-left: 0;
  }
`;
