import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { css } from '@emotion/css';
import { useFormik } from 'formik';
import jwt from 'jsonwebtoken';
import * as Yup from 'yup';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInputRaw } from 'components/shared/Fields/FieldInput/FieldInput';
import { ErrorMessage } from 'components/shared/Fields/FieldWrapper/ErrorMessage/ErrorMessage';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { redirectTo, setPasswordAction } from 'store/setPasswordSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushPersistentFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import {
  AuthenticationMessages,
  messages,
  TypedFormattedMessage,
  useTypedIntl,
} from './locale/messages';
import {
  authenticationFieldTextContainer,
  AuthenticationFormButtonsStyled,
  authenticationFormContainerStyles,
  authenticationFormStyles,
  authenticationHeaderStyle,
  authenticationLogoContainerStyles,
  authenticationPhotoStyles,
  authenticationStyles,
} from './Authentication.styles';

interface ResetPasswordFormShape {
  email: string;
  password: string;
  repeatPassword: string;
}

function ResetPasswordPage(): React.ReactElement {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const setPassword = useAppSelector(state => state.setPassword);
  const { token } = useParams<{ token: string }>();
  const navigate = useAlphamartNavigate();
  const { sub, exp } = jwt.decode(token!) as { sub: string; exp: number };
  const tokenExpired = exp * 1000 < Date.now();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
    setSubmitting,
  } = useFormik<ResetPasswordFormShape>({
    initialValues: {
      email: sub,
      password: '',
      repeatPassword: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(intl.formatMessage({ id: 'ResetPassword.Error.Password.Required' }))
        .min(8, intl.formatMessage({ id: 'ResetPassword.Error.Password.Length' }))
        .matches(/[!@#$%^]/, {
          message: intl.formatMessage({ id: 'ResetPassword.Error.Password.SpecialCharacters' }),
        })
        .matches(/\d/, {
          message: intl.formatMessage({ id: 'ResetPassword.Error.Password.Digit' }),
        })
        .matches(/[a-z]/, {
          message: intl.formatMessage({ id: 'ResetPassword.Error.Password.SmallLetters' }),
        })
        .matches(/[A-Z]/, {
          message: intl.formatMessage({ id: 'ResetPassword.Error.Password.LargeLetters' }),
        }),
      repeatPassword: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          intl.formatMessage({ id: 'ResetPassword.Error.RepeatPassword.NotSame' }),
        )
        .required(intl.formatMessage({ id: 'ResetPassword.Error.RepeatPassword.Required' })),
    }),
    onSubmit: async formValues => {
      try {
        await dispatch(setPasswordAction(token!, { password: formValues.password }));
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'ResetPassword.Success' })));
      } catch {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (tokenExpired)
      dispatch(
        snackBarPushPersistentFailure(intl.formatMessage({ id: 'SetPassword.Error.TokenExpired' })),
      );
  }, [tokenExpired]);

  useEffect(() => {
    if (setPassword.error) {
      dispatch(
        snackBarPushPersistentFailure(
          intl.formatMessage({ id: setPassword.error as keyof AuthenticationMessages }),
        ),
      );
    }
  }, [setPassword.error]);

  useEffect(() => {
    if (setPassword.redirectTo) {
      dispatch(redirectTo(null));
      navigate(setPassword.redirectTo);
    }
  }, [setPassword.redirectTo]);

  return (
    <div className={authenticationStyles()}>
      <section className={authenticationFormContainerStyles()}>
        <h1 className={authenticationLogoContainerStyles()}>Alphamart</h1>
        <form className={authenticationFormStyles()} onSubmit={handleSubmit}>
          <h2 className={authenticationHeaderStyle()}>
            <TypedFormattedMessage id="ResetPassword.Header" />
          </h2>
          <section className={authenticationFieldTextContainer()}>
            <ErrorMessage standalone show={!!errors.email}>
              {errors.email}
            </ErrorMessage>
            <FieldInputRaw
              name="email"
              placeholder={intl.formatMessage({ id: 'SetPassword.Label.Email' })}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              data-cy="set-password-input-email"
              disabled
            />
          </section>
          <section className={authenticationFieldTextContainer()}>
            <ErrorMessage standalone show={!!errors.password && touched.password}>
              {errors.password}
            </ErrorMessage>
            <FieldInputRaw
              name="password"
              type="password"
              placeholder={intl.formatMessage({ id: 'SetPassword.Label.Password' })}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password}
              value={values.password}
              data-cy="set-password-input-password"
            />
          </section>
          <section className={authenticationFieldTextContainer()}>
            <ErrorMessage standalone show={!!errors.repeatPassword && touched.repeatPassword}>
              {errors.repeatPassword}
            </ErrorMessage>
            <FieldInputRaw
              name="repeatPassword"
              type="password"
              placeholder={intl.formatMessage({ id: 'SetPassword.Label.RepeatPassword' })}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.repeatPassword}
              value={values.repeatPassword}
              data-cy="set-password-input-repeat-password"
            />
          </section>
          <AuthenticationFormButtonsStyled>
            <Link to="/login">
              <TypedFormattedMessage id="ResetPassword.Button.Cancel" />
            </Link>
            <AppButton
              disabled={!(isValid && dirty) || isSubmitting || tokenExpired}
              className={css`
                margin: 0 0 0 auto;
              `}
              type="submit"
              data-cy="set-password-button-login"
            >
              <TypedFormattedMessage id="ResetPassword.Button.Submit" />
            </AppButton>
          </AuthenticationFormButtonsStyled>
        </form>
      </section>
      <div className={authenticationPhotoStyles()} />
    </div>
  );
}

function ResetPassword(): React.ReactElement {
  return (
    <AlphamartIntlProvider messages={messages}>
      <ResetPasswordPage />
    </AlphamartIntlProvider>
  );
}

export default ResetPassword;
