import React from 'react';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import { Dot } from 'recharts';

import { StatisticsScatterPayload } from './StatisticsChart.helpers';

interface ContainerProps {
  children: React.ReactElement;
  userId: number;
  isZoomedIn?: boolean;
  isMobile?: boolean;
}

const Container = ({
  children,
  userId,
  isZoomedIn,
  isMobile,
}: ContainerProps): React.ReactElement =>
  isMobile && !isZoomedIn ? (
    children
  ) : (
    <Link to={`/users/${userId}`} target="blank">
      {children}
    </Link>
  );

export interface CustomScatterPointProps extends StatisticsScatterPayload {
  cx: number;
  cy: number;
  fill: string;
  isZoomedIn?: boolean;
  isMobile?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  windowWidth?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  windowHeight?: number;
}

export const CustomScatterPoint = React.memo(
  ({
    cx,
    cy,
    fill,
    user,
    isZoomedIn,
    isMobile,
  }: CustomScatterPointProps): React.ReactElement<SVGElement> | null => {
    if (isNil(cx) || isNil(cy)) {
      return null;
    }

    return user ? (
      <g>
        <Container userId={user.id} isZoomedIn={isZoomedIn} isMobile={isMobile}>
          <Dot cx={cx} cy={cy} r={5} fill={fill} />
        </Container>
      </g>
    ) : (
      <Dot cx={cx} cy={cy} r={5} fill={fill} />
    );
  },
  (prev, next) => {
    const cxNotChanged = prev.cx === next.cx;
    const cyNotChanged = prev.cy === next.cy;
    const mobileNotChanged = prev.isMobile === next.isMobile;
    const zoomNotChanged = prev.isZoomedIn === next.isZoomedIn;
    const windowSizeNotChanged =
      prev.windowWidth === next.windowWidth && prev.windowHeight === next.windowHeight;
    return (
      mobileNotChanged && zoomNotChanged && windowSizeNotChanged && cxNotChanged && cyNotChanged
    );
  },
);
