import React, { ReactNode } from 'react';
import {
  FormattedMessage as ReactIntlFormattedMessage,
  IntlFormatters,
  MessageDescriptor,
  useIntl as useReactIntl,
} from 'react-intl';
import { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message';

import enMessages from './en.json';

export type GlobalMessageKeys = keyof typeof enMessages;
export type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;

type FormattedMessageProps<T> = ReactIntlFormattedMessageProps<Record<string, ReactNode>> & {
  // eslint-disable-next-line react/no-unused-prop-types
  id: GlobalMessageKeys | T;
};

export function TypedFormattedMessageFactory<T>() {
  return (props: FormattedMessageProps<T>) =>
    React.createElement(ReactIntlFormattedMessage, { ...props });
}

export type TypedFormatMessageAlias<T> = (
  descriptor: FormatMessageArgs[1] & {
    id: GlobalMessageKeys | T;
  },
  values?: FormatMessageArgs[1],
  options?: FormatMessageArgs[2],
) => string;

function useTypedIntl<Type>() {
  const { formatMessage, ...rest } = useReactIntl();

  const typedFormatMessage = ((descriptor: MessageDescriptor, values, options) =>
    formatMessage(descriptor, values, options)) as TypedFormatMessageAlias<Type>;

  return {
    ...rest,
    formatMessage: typedFormatMessage,
  };
}

export function useTypedIntlFactory<T>() {
  return () => useTypedIntl<T>();
}
