import React from 'react';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { DataList, useGetRowId, VehicleDetailsNestedTableContainer } from 'components/shared/List';
import { SHARED, UNITS } from 'shared/constants';
import { useCurrentUser, useMediaQuery } from 'shared/hooks';
import { NonstandardConverterDetails } from 'shared/types/nonstandardConverterDetails';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';
import { StyledPrice } from '../VehiclesList/VehiclesList';

export const VehicleNonstandardConvertersList = ({
  data,
}: {
  data: NonstandardConverterDetails[];
}) => {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();

  const columns: ColumnDef<NonstandardConverterDetails>[] = [
    {
      id: 'converterIdentifier',
      header: intl.formatMessage({ id: 'VehicleForm.NonstandardConverter' }),
      cell: ({ row }) => row.original.material,
    },
    {
      id: 'materialWeight',
      header: intl.formatMessage({ id: 'VehicleForm.MaterialWeight' }),
      cell: ({ row }) =>
        row.original.materialWeight
          ? `${row.original.materialWeight} ${UNITS.LBS}`
          : SHARED.LONG_DASH,
    },
    {
      id: 'materialUnit',
      header: intl.formatMessage({ id: 'VehicleForm.MaterialUnit' }),
      cell: ({ row }) => row.original.materialUnit,
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'VehicleDetails.Value.SamplePrice' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.price,
          currency: currentUser.currency!,
          language: currentUser.language!,
        }),
    },
  ];

  const table = useReactTable({
    columns,
    data,
    getRowId: useGetRowId(),
    getCoreRowModel: getCoreRowModel(),
    state: { columnVisibility: { image: useMediaQuery(MEDIA_QUERY.LG) } },
    enableRowSelection: false,
  });

  return <DataList table={table} outerContainerComponent={VehicleDetailsNestedTableContainer} />;
};
