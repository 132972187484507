import React from 'react';
import styled from '@emotion/styled';

import bellIcon from 'assets/images/icons/notificationBell.svg';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';

type Size = 'S' | 'M' | 'L';
interface Props {
  size?: Size;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const getSize = (size: Size) => {
  switch (size) {
    case 'M': {
      return '24px';
    }
    case 'S': {
      return '18px';
    }
    case 'L': {
      return '32px';
    }
  }
};

const BellContainer = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  position: relative;
`;

const Icon = styled.span<{ color: string; size: Size }>`
  display: inline-block;
  background-color: ${({ color }) => color};
  mask-image: url(${bellIcon});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
  min-width: ${({ size }) => getSize(size)};
  width: ${({ size }) => getSize(size)};
  min-height: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  transition: background-color 0.3s;
  cursor: pointer;
`;

const CounterIcon = styled.span<{ count: number }>`
  position: absolute;
  display: flex;
  top: 15px;
  right: 15px;
  background: ${theme.mainColor};
  color: ${theme.fontColor};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: ${({ count }) => (count > 9 ? '12px' : '15px')};
  font-weight: bold;
  align-items: center;
  justify-content: center;

  @media ${MEDIA_QUERY.MD} {
    top: -5px;
    right: -5px;
  }
`;

export function NotificationsBell({ size = 'M', onClick }: Props): React.ReactElement {
  const count = useAppSelector(state => state.notificationCount?.count);

  return (
    <BellContainer onClick={onClick} data-cy="bell-container">
      <Icon size={size} color={count ? theme.fontColor : theme.colors.zambezi} />
      {count ? <CounterIcon count={count}>{count > 9 ? '9+' : count}</CounterIcon> : null}
    </BellContainer>
  );
}
