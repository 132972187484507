import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

const Logo = styled.div<{ desktop: string; mobile: string; compact?: boolean }>`
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  background: url(${({ desktop }) => desktop}) no-repeat center / contain;

  ${({ compact, mobile }) =>
    compact &&
    `
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    background-image: url(${mobile});
  `}

  @media ${MEDIA_QUERY.MAX_MD} {
    height: 45px;
    min-width: 100px;
  }
`;

export { Logo };
