import React from 'react';
import { CaseReducer, createSlice } from '@reduxjs/toolkit';

type RawMessage = React.ReactNode;
export type MessageShape = {
  type: 'success' | 'failure';
  message: RawMessage;
  id: number;
  timeout?: number;
};
interface State {
  messages: MessageShape[];
}

const snackBarSlice = createSlice<
  State,
  {
    snackBarPushSuccess: CaseReducer<State, { type: string; payload: RawMessage }>;
    snackBarPushFailure: CaseReducer<State, { type: string; payload: RawMessage }>;
    snackBarRemoveMessage: CaseReducer<State, { type: string; payload: number }>;
    snackBarPushPersistentFailure: CaseReducer<State, { type: string; payload: RawMessage }>;
  }
>({
  name: 'snackBar',
  initialState: {
    messages: [],
  },
  reducers: {
    snackBarPushSuccess: (state, { payload }) => ({
      messages: [
        ...state.messages,
        {
          type: 'success',
          message: payload,
          id: Date.now(),
        },
      ],
    }),
    snackBarPushFailure: (state, { payload }) => ({
      messages: [
        ...state.messages,
        {
          type: 'failure',
          message: payload,
          id: Date.now(),
        },
      ],
    }),
    snackBarPushPersistentFailure: (state, { payload }) => ({
      messages: [
        ...state.messages,
        {
          type: 'failure',
          message: payload,
          id: Date.now(),
          timeout: 999999,
        },
      ],
    }),
    snackBarRemoveMessage: (state, { payload }) => ({
      messages: [...state.messages.filter(message => message.id !== payload)],
    }),
  },
});

export const {
  snackBarPushSuccess,
  snackBarPushFailure,
  snackBarRemoveMessage,
  snackBarPushPersistentFailure,
} = snackBarSlice.actions;

export default snackBarSlice.reducer;
