import React, { forwardRef, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';

import hamburgerIcon from 'assets/images/icons/hamburger.svg';
import { DndTypes } from 'shared/constants';
import { SortableItem } from './SortableList';
import { ListItem, ListItemContent, VirtualizedRowWrapper } from './SortableList.styles';
import { Icon } from '../Buttons';

export interface SortableItemProps {
  item: SortableItem;
  type: DndTypes;
  overBy?: number;
  reorder(dragIndex: number, hoverIndex: number): void;
  style: React.CSSProperties;
}

interface SortableListItemComponentProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  item: SortableItem;
  isDragging?: boolean;
}

export const SortableListItemComponent = forwardRef<HTMLDivElement, SortableListItemComponentProps>(
  ({ item, ...props }, ref): React.ReactElement => (
    <ListItem {...props} ref={ref}>
      <VirtualizedRowWrapper isDragging={props.isDragging}>
        <ListItemContent>{item.content}</ListItemContent>
        <Icon icon={hamburgerIcon} size="22px" />
      </VirtualizedRowWrapper>
    </ListItem>
  ),
);

export const SortableListItem = ({
  item: hoverItem,
  type,
  overBy = 0.2,
  reorder,
  style,
}: SortableItemProps): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<SortableItem, void, { handlerId: Identifier | null }>({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(dragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = hoverItem.index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();

      const lowerPart = hoverBoundingRect.height * overBy;
      const upperPart = hoverBoundingRect.height - lowerPart;
      const hoverClientY = (monitor.getClientOffset()?.y ?? 0) - hoverBoundingRect.top;

      // Only perform the move when pointer crossed `overBy` of the item's
      // height from the top or bottom depending on the direction of the move
      if (
        (dragIndex < hoverIndex && hoverClientY < lowerPart) ||
        (dragIndex > hoverIndex && hoverClientY > upperPart)
      ) {
        return;
      }

      // Time to actually perform the action
      reorder(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      dragItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => hoverItem,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <SortableListItemComponent
      item={hoverItem}
      data-handler-id={handlerId}
      ref={ref}
      draggable
      isDragging={isDragging}
      style={style}
    />
  );
};
