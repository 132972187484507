import { useMemo } from 'react';
import { getDate, getHours, getMonth, getYear, parseISO } from 'date-fns';
import { groupBy } from 'lodash';

import { FilterableModules } from 'shared/constants';
import { MetalPrice } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';

type ChartData = {
  data: {
    createdAt: number;
    platinum: number;
    palladium: number;
    rhodium: number;
    pdChangePercentage: number;
    ptChangePercentage: number;
    rhChangePercentage: number;
  }[];
  maxPrice: number;
  xAxisTicks: number[];
};

export const useMarketPricesChartData = (metalPrices: MetalPrice[]): ChartData => {
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.DASHBOARD]);

  const data = useMemo(
    () =>
      metalPrices.map(item => ({
        ...item,
        createdAt: parseISO(item.createdAt).valueOf(),
      })),
    [metalPrices],
  );

  const maxPrice = useMemo(
    () =>
      Math.max(
        ...data
          .map(({ platinum, palladium, rhodium }) => [
            +platinum + 0.05 * platinum,
            +palladium + 0.05 * palladium,
            +rhodium + 0.05 * rhodium,
          ])
          .flat(),
      ),
    [data.length],
  );

  const xAxisTicks = useMemo(
    () =>
      Object.values(
        groupBy(data ?? [], ({ createdAt }) => {
          switch (savedFilters.data.from) {
            case 'Days-1':
              return `${getHours(createdAt)}-${getDate(createdAt)}`;
            case 'Weeks-1':
              return getDate(createdAt);
            case 'Months-1':
              return `${getDate(createdAt)}-${getMonth(createdAt)}`;
            case 'Years-1':
              return `${getMonth(createdAt)}-${getYear(createdAt)}`;
            default:
              return getYear(createdAt);
          }
        }),
      )
        .map(([first]) => first.createdAt)
        .sort()
        .filter((item, index) => index > 0),
    [data, savedFilters.data.from],
  );

  return {
    data,
    maxPrice,
    xAxisTicks,
  };
};
