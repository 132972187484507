import React from 'react';

import infoIcon from 'assets/images/icons/info.svg';
import warningIcon from 'assets/images/icons/warning.svg';
import { theme } from 'theme';
import { Icon } from '../Buttons';
import { Tooltip } from '../Tooltip/Tooltip';

interface BaseProps {
  tooltip: string | React.ReactNode;
  className?: string;
  size?: string;
}

interface Props extends BaseProps {
  color?: string;
  icon: string;
}

const IconTooltip = ({ icon, color, tooltip, className, size }: Props): React.ReactElement => (
  <Tooltip
    placement="top"
    trigger={['hover', 'focus']}
    tooltip={tooltip}
    bgColor={color}
    className={className}
  >
    <Icon icon={icon} color={color} size={size} />
  </Tooltip>
);

const IconTooltipWarning = ({ tooltip, className, size }: BaseProps): React.ReactElement => (
  <Tooltip
    placement="top"
    trigger={['hover', 'focus']}
    tooltip={tooltip}
    bgColor={theme.colors.fuzzyWuzzyBrown}
    className={className}
  >
    <Icon
      icon={warningIcon}
      color={theme.colors.fuzzyWuzzyBrown}
      size={size}
      data-cy="icon-tooltip-warning"
    />
  </Tooltip>
);

const IconTooltipInfo = ({ tooltip, className, size = '32px' }: BaseProps): React.ReactElement => (
  <Tooltip
    placement="top"
    trigger={['hover', 'focus']}
    tooltip={tooltip}
    bgColor={theme.colors.mineShaftLighter}
    className={className}
  >
    <Icon
      icon={infoIcon}
      color={theme.colors.alphaGray}
      data-cy="icon-tooltip-warning"
      size={size}
    />
  </Tooltip>
);

export { IconTooltip, IconTooltipInfo, IconTooltipWarning };
