import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikContextType } from 'formik';

import { FieldCheckboxRaw } from 'components/shared/Fields/FieldCheckbox';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldMultiText } from 'components/shared/Fields/FieldMultiText/FieldMultiText';
import { FieldRadioButton } from 'components/shared/Fields/FieldRadioButton/FieldRadioButton';
import { FieldCreatableSelect } from 'components/shared/Fields/FieldSelect/FieldCreatableSelect';
import { FieldSelect, FieldSelectOption } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { Section } from 'components/shared/forms/Section/Section';
import { getYearOptions } from 'helpers/dateTime/dateTime';
import { Folder } from 'shared/types';
import { createFolder } from 'store/createFolderSlice';
import { fetchFolders, success as fetchFoldersSuccessAction } from 'store/foldersSlice';
import { fetchMakes } from 'store/makesSlice';
import { fetchModels } from 'store/modelsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { fetchTypes } from 'store/typesSlice';
import { AssayFormShape } from '../CreateAssay/CreateAssay';
import { useTypedIntl } from '../locale/messages';

type ConverterFormParams = {
  activeSection: string | undefined;
  setActiveSection: (section?: string) => void;
  handleBlur: (e?: React.FocusEvent) => void;
  handleChange: (e?: React.ChangeEvent<unknown>) => void;
  values: AssayFormShape;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  touched: any;
  getErrors: (fieldName: string) => string | false | undefined;
  getArrayErrors: (fieldName: string) => string | React.ReactElement | undefined;
  context: FormikContextType<AssayFormShape>;
  onBlur: (event?: React.FocusEvent<HTMLInputElement>) => void;
  setActiveInput: (input: string) => void;
  activeInput: string | null;
  setFieldError: (fieldName: string, value: string | undefined) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  setFieldTouched: (fieldName: string) => void;
  editMode?: boolean;
};

const ConverterForm = ({
  activeSection,
  setActiveSection,
  handleBlur,
  handleChange,
  values,
  getErrors,
  getArrayErrors,
  context,
  onBlur,
  activeInput,
  setActiveInput,
  setFieldError,
  setFieldValue,
  setFieldTouched,
  touched,
  editMode = false,
}: ConverterFormParams): React.ReactElement => {
  const { isPending: isCreatingFolder } = useAppSelector(state => state.createFolder);
  const intl = useTypedIntl();
  const [yearOptions, setYearOptions] = useState<FieldSelectOption[]>([]);
  const dispatch = useAppDispatch();
  const types = useAppSelector(state => state.types.types)?.map(type => ({
    value: type.id,
    label: type.name,
  }));
  const { folders } = useAppSelector(state => state.folders);
  const { models } = useAppSelector(state => state.models);
  const { makes } = useAppSelector(state => state.makes);

  const foldersOptions = useMemo(
    () =>
      folders?.map(folder => ({
        value: folder.id,
        label: folder.name,
      })),
    [folders],
  );

  const makesOptions = useMemo(
    () =>
      makes?.map(make => ({
        value: make,
        label: make,
      })),
    [makes],
  );

  const modelsOptions = useMemo(
    () =>
      models?.map(model => ({
        value: model,
        label: model,
      })),
    [models],
  );

  const isPartialOptions = [
    { label: intl.formatMessage({ id: 'Global.Yes' }), value: 'true' },
    { label: intl.formatMessage({ id: 'Global.No' }), value: 'false' },
  ];

  useEffect(() => {
    setYearOptions(getYearOptions());
  }, []);

  useEffect(() => {
    if (values.converter.identifier) return;
    if (!values.converter.identifier) setFieldValue('converter.identifierConfirmation', '');
  }, [values.converter.identifier]);

  useEffect(() => {
    const triggerDispatch = async () => {
      await dispatch(fetchTypes());
      await dispatch(fetchMakes());
      await dispatch(fetchModels());
      await dispatch(fetchFolders());
    };
    triggerDispatch();
  }, []);

  const handleFolderChange = useCallback(
    async value => {
      if (!value || typeof value?.value === 'number')
        return setFieldValue('converter.folder', value?.value);
      if (getErrors('converter.folder')) return;

      try {
        const folder = (await dispatch(createFolder(value.value))) as unknown as Folder;
        dispatch(fetchFoldersSuccessAction([...folders, folder]));
        dispatch(
          snackBarPushSuccess(intl.formatMessage({ id: 'AssaysForm.CreateFolder.Success' })),
        );
        setFieldValue('converter.folder', (folder as unknown as { id: number })?.id);
      } catch {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
    [folders, getErrors],
  );

  const handleMakeChange = value => setFieldValue('converter.vehicle.make', value?.value);

  const handleModelChange = value => setFieldValue('converter.vehicle.model', value?.value);

  const validateNewFolder = val => {
    if (!val) return setFieldError('converter.folder', undefined);
    if (!touched?.converter?.folder) setFieldTouched('converter.folder');

    if (!/^[A-Z0-9 \-/()]+$/.test(val))
      return setFieldError(
        'converter.folder',
        intl.formatMessage({
          id: 'ConverterForm.Errors.Folder.NotMatchRegex',
        }),
      );

    setFieldError('converter.folder', undefined);
  };

  const validateNewMake = val => {
    if (!touched?.converter?.vehicle?.make) setFieldTouched('converter.vehicle.make');

    if (!/^[0-9A-Z\- /.+]+$/.test(val))
      return setFieldError(
        'converter.vehicle.make',
        intl.formatMessage({
          id: 'ConverterForm.Errors.Make.NotMatchRegex',
        }),
      );

    setFieldError('converter.vehicle.make', undefined);
  };

  const validateNewModel = val => {
    if (!touched?.converter?.vehicle?.model) setFieldTouched('converter.vehicle.model');

    if (!/^[0-9A-Z\- /.+]+$/.test(val))
      return setFieldError(
        'converter.vehicle.model',
        intl.formatMessage({
          id: 'ConverterForm.Errors.Model.NotMatchRegex',
        }),
      );

    setFieldError('converter.vehicle.model', undefined);
  };

  const handleProtectedBlur = e => {
    setFieldTouched(`${e.target.name}Confirmation`);
    return onBlur(e);
  };

  return (
    <FormInWizard
      context={context}
      header={
        editMode
          ? intl.formatMessage({ id: 'AssaysForm.UpdateConverter' })
          : intl.formatMessage({ id: 'AssaysForm.Converter' })
      }
    >
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="partDetails"
        label={intl.formatMessage({ id: 'ConverterForm.Section.PartDetails' })}
        template={[
          `converter.identifier converter.identifierConfirmation`,
          'converter.partName converter.nicknames',
          'converter.otherNumbers converter.type',
          'converter.isPartial converter.folder',
        ]}
      >
        <FieldInput
          label={intl.formatMessage({ id: 'ConverterForm.Identifier' })}
          name="converter.identifier"
          onChange={handleChange}
          onBlur={handleProtectedBlur}
          value={values.converter.identifier}
          error={getErrors('converter.identifier')}
          required
          data-cy="identifier"
          onFocus={e => setActiveInput(e.target.name)}
          capitalize
          protection={{
            hide: activeInput === 'converter.identifierConfirmation',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          maxLength={100}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'ConverterForm.IdentifierConfirmation' })}
          name="converter.identifierConfirmation"
          onChange={handleChange}
          onBlur={onBlur}
          value={values.converter.identifierConfirmation}
          error={getErrors('converter.identifierConfirmation')}
          required
          disabled={!values.converter.identifier}
          data-cy="identifier-confirmation"
          onFocus={e => setActiveInput(e.target.name)}
          capitalize
          protection={{
            hide: activeInput === 'converter.identifier',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          maxLength={100}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'ConverterForm.PartName' })}
          name="converter.partName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.converter.partName}
          error={getErrors('converter.partName')}
          data-cy="part-name"
          capitalize
          onFocus={e => setActiveInput(e.target.name)}
          autoComplete="off"
          maxLength={100}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'ConverterForm.Nicknames' })}
          name="converter.nicknames"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.converter.nicknames}
          error={getErrors('converter.nicknames')}
          data-cy="nicknames"
          onFocus={e => setActiveInput(e.target.name)}
          autoComplete="off"
          maxLength={100}
        />
        <FieldMultiText
          label={intl.formatMessage({ id: 'ConverterForm.OtherNumbers' })}
          addLabel={intl.formatMessage({ id: 'ConverterForm.AddNumber' })}
          name="converter.otherNumbers"
          onChange={handleChange}
          onTouch={setFieldTouched}
          onBlur={handleBlur}
          value={values.converter.otherNumbers as unknown as { value: string }[]}
          error={getArrayErrors('converter.otherNumbers')}
          onFocus={e => setActiveInput(e.target.name)}
          capitalize
          data-cy="other-numbers"
          maxLength={100}
        />
        <FieldSelect
          label={intl.formatMessage({ id: 'ConverterForm.Type' })}
          name="converter.type"
          options={types}
          value={values.converter.type}
          error={getErrors('converter.type')}
          required
          data-cy="type"
          onFocus={e => setActiveInput(e.target.name)}
        />
        <FieldRadioButton
          label={intl.formatMessage({ id: 'ConverterForm.IsPartial' })}
          name="converter.isPartial"
          options={isPartialOptions}
          value={values.converter.isPartial}
          error={getErrors('converter.isPartial')}
          onChange={handleChange}
          data-cy="is-partial"
          required
        />
        <FieldCreatableSelect
          label={intl.formatMessage({ id: 'ConverterForm.Folder' })}
          name="converter.folder"
          isLoading={isCreatingFolder}
          options={foldersOptions}
          onChange={handleFolderChange}
          value={values.converter.folder}
          error={getErrors('converter.folder')}
          validateInput={validateNewFolder}
          required
          data-cy="folder"
          onFocus={e => setActiveInput(e.target.name)}
          maxLength={50}
        />
      </Section>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="carDetails"
        label={intl.formatMessage({ id: 'ConverterForm.Section.CarDetails' })}
        template={['converter.vehicle.make converter.vehicle.model', 'converter.vehicle.year .']}
      >
        <FieldCreatableSelect
          label={intl.formatMessage({ id: 'ConverterForm.Make' })}
          name="converter.vehicle.make"
          options={makesOptions}
          onChange={handleMakeChange}
          value={values.converter.vehicle.make}
          error={getErrors('converter.vehicle.make')}
          validateInput={validateNewMake}
          data-cy="make"
          onFocus={e => setActiveInput(e.target.name)}
          maxLength={100}
        />
        <FieldCreatableSelect
          label={intl.formatMessage({ id: 'ConverterForm.Model' })}
          name="converter.vehicle.model"
          options={modelsOptions}
          onChange={handleModelChange}
          value={values.converter.vehicle.model}
          error={getErrors('converter.vehicle.model')}
          validateInput={validateNewModel}
          data-cy="model"
          onFocus={e => setActiveInput(e.target.name)}
          maxLength={100}
        />
        <FieldSelect
          label={intl.formatMessage({ id: 'ConverterForm.Year' })}
          name="converter.vehicle.year"
          options={yearOptions}
          value={values.converter.vehicle.year}
          data-cy="year"
          onFocus={e => setActiveInput(e.target.name)}
        />
      </Section>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="notes"
        label={intl.formatMessage({ id: 'ConverterForm.Note' })}
        template={[
          'converter.notes converter.notes',
          'converter.counterfeit converter.counterfeit',
        ]}
      >
        <FieldTextArea
          label={intl.formatMessage({ id: 'ConverterForm.Note' })}
          name="converter.notes"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.converter.notes}
          error={getErrors('converter.notes')}
          data-cy="notes"
          onFocus={e => setActiveInput(e.target.name)}
          required={values.converter.counterfeit}
          maxLength={255}
        />
        <FieldCheckboxRaw
          name="converter.counterfeit"
          onChange={handleChange}
          checked={values.converter.counterfeit}
          size="22px"
          data-cy="counterfeit"
          onBlur={handleBlur}
        >
          {intl.formatMessage({ id: 'ConverterForm.Counterfeit' })}
        </FieldCheckboxRaw>
      </Section>
    </FormInWizard>
  );
};

export { ConverterForm };
