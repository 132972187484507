import React, { useContext, useEffect } from 'react';

import { WizardContext } from '../Wizard';

interface Props {
  children: React.ReactNode;
  pageIndex: number;
}

const FormWrapper = ({ children, pageIndex }: Props): React.ReactElement => {
  const { currentPage, updatePageIndexes } = useContext(WizardContext);

  useEffect(() => {
    updatePageIndexes(pageIndex);
  });

  return (currentPage === pageIndex ? children : null) as React.ReactElement;
};

export { FormWrapper };
