import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Hedge } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';
import { parseHedge } from '../parsers/parseHedge';
import { HedgeFormSchema } from '../types/hedgeFormSchema ';

export enum CreateHedgeKeys {
  CreateHedge = 'CreateHedge',
}

export const useCreateHedge = (
  options?: UseMutationOptions<Hedge, AlphamartHttpError, HedgeFormSchema>,
) =>
  useMutation<Hedge, AlphamartHttpError, HedgeFormSchema, unknown>(
    async (values: HedgeFormSchema) => {
      const hedgeDto = parseHedge(values);
      const response = await getHttpClient().post<Hedge>(`${apiHostname}/api/hedges`, hedgeDto);

      return response.data;
    },
    {
      mutationKey: [CreateHedgeKeys.CreateHedge],
      ...options,
    },
  );
