import React, { useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import arrowBackIcon from 'assets/images/icons/Back.svg';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { isBetween } from 'shared/helpers';
import { useEventListener } from 'shared/hooks';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { toggleKeyboard } from 'store/shared/virtualKeyboardSlice';
import { theme } from 'theme';
import { Icon } from './Icon';

type Props = {
  className?: string;
  currentPage?: number;
  max: number;
  onClick: (page: number) => void;
};

const GoToPageButtonLayout = ({
  className,
  currentPage,
  onClick,
  max,
}: Props): React.ReactElement => {
  const { isVisible: isInputActive } = useAppSelector(
    ({ virtualKeyboard }) => virtualKeyboard ?? { isVisible: false },
  );
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (inputRef.current && currentPage !== +inputRef.current.value) {
      formRef.current?.reset();
    }
  }, [currentPage]);

  const isLabelHidden = inputRef.current?.value || isInputActive;

  const handleClick = useCallback(() => {
    if (!inputRef.current) return;
    const selectedPage = +inputRef.current.value;
    if (isBetween(selectedPage, 1, max) && Number.isInteger(selectedPage)) {
      inputRef.current?.blur();
      dispatch(toggleKeyboard(false));
      onClick(selectedPage);
    }
  }, [onClick, max]);

  const handleEnter = (e: React.KeyboardEvent) => e.key === 'Enter' && handleClick();
  useEventListener<React.KeyboardEvent>('keydown', handleEnter);

  const handlePress = (event: React.KeyboardEvent) =>
    (event.charCode < 48 || event.charCode > 57) && event.preventDefault();

  const handleFocus = () => {
    dispatch(toggleKeyboard(true));
  };
  const handleBlur = () => {
    dispatch(toggleKeyboard(false));
  };

  return (
    <form className={className} ref={formRef}>
      <label className={isLabelHidden ? 'hide' : ''} htmlFor="pageInput">
        <FormattedMessage id="Global.GoToPage" />
      </label>
      <input
        ref={inputRef}
        id="pageInput"
        type="number"
        min="1"
        max={max}
        pattern="[0-9]"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handlePress}
        data-cy="page-input"
      />
      <button type="button" onClick={handleClick} data-cy="page-input-submit">
        <Icon icon={arrowBackIcon} size="18px" />
      </button>
    </form>
  );
};

const GoToPageButton = styled(GoToPageButtonLayout)`
  margin-left: auto;
  height: 48px;
  width: 128px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: ${theme.colors.alphaGray};

  :hover {
    & > * {
      transition: border-color 0.3s ease;

      border-color: ${theme.colors.alphaGray};
    }
  }

  & > label {
    position: absolute;
    left: 16px;
    top: 15px;

    &.hide {
      display: none;
    }

    :hover {
      cursor: text;
    }
  }

  & > input {
    background-color: transparent;
    width: 96px;
    height: 100%;
    border-radius: 20px 0 0 20px;
    border: 1px solid ${theme.colors.mineShaft};
    border-right: none;
    padding: 0 16px;
    color: ${theme.colors.alphaGray};
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    :focus {
      outline: none;
    }
  }

  & > button {
    background-color: transparent;
    width: 32px;
    height: 100%;
    border-radius: 0 20px 20px 0;
    border: 1px solid ${theme.colors.mineShaft};
    border-left: none;
    cursor: pointer;

    :focus {
      outline: none;
    }

    & > span {
      transform: rotate(180deg) translateY(-2px);
    }
  }
`;

export { GoToPageButton };
