import React from 'react';
import styled from '@emotion/styled';

import { Theme } from 'theme';

const TextButtonStyled = styled.button<{
  theme?: Theme;
  fontSize: number;
  isActive?: boolean;
}>`
  display: inline;
  border: none;
  background-color: transparent;
  color: ${({ theme, color }) => color || `${theme.mainColor}CC`};
  font-size: ${({ fontSize }) => fontSize}px;
  padding: 0;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  text-align: left;

  ${({ isActive }) =>
    isActive &&
    `
    text-decoration: underline;
  `}

  :disabled {
    color: ${({ theme }) => theme.colors.mineShaft};
    cursor: not-allowed;
  }

  :hover {
    color: ${({ theme }) => theme.mainColor};
  }

  :active {
    text-decoration: underline;
  }
`;

type Props = {
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  isActive?: boolean;
  color?: string;
  fontSize?: number;
};

const TextButton = ({
  disabled,
  children,
  onClick,
  isActive,
  color,
  fontSize = 12,
}: Props): React.ReactElement => (
  <TextButtonStyled
    type="button"
    disabled={disabled}
    onClick={onClick}
    fontSize={fontSize}
    isActive={isActive}
    color={color}
    data-cy="text-btn"
  >
    {children}
  </TextButtonStyled>
);

export { TextButton };
