import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Folder } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface FetchFoldersState extends GenericStoreSlice {
  folders: Folder[];
}

const fetchFoldersSlice = createSlice<
  FetchFoldersState,
  GenericStoreReducer<FetchFoldersState, Folder[]>
>({
  name: 'folders',
  initialState: {
    folders: [],
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers<FetchFoldersState, Folder[]>(payload => ({
      folders: payload,
    })),
  },
});

export const { success, pending, failure } = fetchFoldersSlice.actions;

export const fetchFolders = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    dispatch(pending());
    const folders = await httpClient.get<Folder[]>(`${apiHostname}/api/folders`);
    dispatch(success(folders.data));
  } catch (error) {
    dispatch(failure((error as AlphamartHttpError)?.response?.data.message));
    return Promise.reject(error);
  }
};

export default fetchFoldersSlice.reducer;
