import { css } from '@emotion/css';

import { MEDIA_QUERY, Theme } from 'theme';

export const searchBarStyles = (searchInputVisible: boolean) => css`
  ${searchInputVisible && `display: grid;`}

  grid-template-columns: 340px auto;
  gap: 16px;
  margin-bottom: 16px;
  position: relative;
  align-items: start;

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const sidebarButtonStyles = (theme: Theme): string => css`
  border-radius: 8px;
  font-size: 12px;
  color: ${theme.colors.alphaGray};
  padding: 15px;
  margin-left: 16px;

  span:first-of-type {
    margin-right: 10px;
    height: 18px;
    width: 22px;
  }

  @media ${MEDIA_QUERY.XL} {
    display: none;
  }
`;

export const sidebarStyles = chooseListType => css`
  justify-self: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media ${MEDIA_QUERY.MAX_MD} {
    justify-content: ${chooseListType ? 'space-between' : 'flex-end'};
  }
`;
