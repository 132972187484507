import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { Hedge } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum HedgeDetailsKeys {
  GetHedgeDetails = 'GetHedgeDetails',
}

export const useGetHedgeDetails = (id: number, options?: UseQueryOptions<Hedge>) =>
  useQuery<Hedge>(
    [HedgeDetailsKeys.GetHedgeDetails, id],
    async () => {
      const response = await getHttpClient().get<Hedge>(`${apiHostname}/api/hedges/${id}`);
      return response.data;
    },
    options,
  );
