import React from 'react';
import styled from '@emotion/styled';

import { NotificationsBell } from 'components/shared/NotificationsBell/NotificationsBell';
import { useTypedIntl } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { openNotifications } from 'store/notificationsList';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { notificationMenuStyles } from './NotificationMenu.styles';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';

interface Props {
  compact?: boolean;
}

const NotificationMenuWrapper = styled.div``;

export const NotificationsMenu = ({ compact }: Props) => {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const notificationsList = useAppSelector(state => state.notificationsList);

  const handleNotificationBellClick = event => {
    if (!notificationsList?.isOpen) {
      event.stopPropagation();
      dispatch(openNotifications());
    }
  };

  return (
    <AlphamartIntlProvider>
      <NotificationMenuWrapper
        onClick={handleNotificationBellClick}
        className={notificationMenuStyles(theme, compact)}
      >
        <NotificationsBell size="L" />
        {!compact && <span>{intl.formatMessage({ id: 'Global.Notifications' })}</span>}
      </NotificationMenuWrapper>
    </AlphamartIntlProvider>
  );
};
