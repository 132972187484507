import React, { useMemo } from 'react';
import { sum } from 'lodash';

import { Rating } from 'components/shared/Rating/Rating';
import {
  BarsContainer,
  SingleBar,
  SingleBarColumn,
  SingleBarLabel,
} from '../FeedbacksSummary.styles';

const toPercentage = (curr: number, total: number) =>
  total !== 0 ? Math.round((curr / total) * 100) : 0;

export default ({ votesCount }: { votesCount: number[] }): React.ReactElement => {
  const bars = useMemo(() => {
    const total = sum(votesCount);

    return votesCount
      .map((curr, index) => ({
        rating: index + 1,
        percentage: toPercentage(curr, total),
      }))
      .sort((a, b) => b.rating - a.rating);
  }, [votesCount]);

  return (
    <BarsContainer>
      {bars.map(({ rating, percentage }) => (
        <SingleBar key={rating} data-cy={`chart-bar-${rating}`}>
          <SingleBarLabel data-cy="single-bar-label">{percentage}%</SingleBarLabel>
          <SingleBarColumn percentage={percentage} data-cy="single-bar-column" />
          <Rating rating={rating} />
        </SingleBar>
      ))}
    </BarsContainer>
  );
};
