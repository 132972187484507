import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';

import { MapFiltersShape } from 'components/views/Geolocation/UserLocationsView/MapFilters';
import { formatDate } from 'helpers/dateTime/dateTime';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Status } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

export interface LocationItem {
  createdAt: string;
  device: {
    activatedAt: string;
    browserFamily: string;
    createdAt: string;
    name: string;
    os: string;
    owner?: string;
    updatedAt?: string;
  };
  id: number;
  latitude: number;
  longitude: number;
  updatedAt: string;
  user: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    status: Status;
  };
}

interface LocationsState extends GenericStoreSlice {
  locations: LocationItem[];
}

export interface ShapeParam {
  path?: google.maps.LatLng[];
  radius?: number;
  circleCenter?: {
    latitude?: number;
    longitude?: number;
  };
}

type LocationsReducer = GenericStoreReducer<LocationsState> & {
  clearLocationsAction: CaseReducer<LocationsState, { type: string }>;
};

const initialState: LocationsState = {
  locations: [],
  isPending: false,
  error: undefined,
};

const fetchLocationsSlice = createSlice<LocationsState, LocationsReducer>({
  name: 'locations',
  initialState: { ...initialState },
  reducers: {
    ...getGenericReducers(payload => ({
      locations: payload,
    })),
    clearLocationsAction: () => ({ ...initialState }),
  },
});

export const {
  pending: fetchLocationsAction,
  success: fetchLocationsSuccessAction,
  failure: fetchLocationsFailureAction,
  clearLocationsAction,
} = fetchLocationsSlice.actions;

export const fetchLocations =
  (
    { path, radius, circleCenter }: ShapeParam,
    filters: Partial<MapFiltersShape> = {},
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    if (path === undefined && radius === undefined && circleCenter === undefined) {
      return;
    }

    const usersFilter = [...(filters.users ?? [])].filter(isTruthy);
    const bounds = path?.map(({ lat, lng }) => ({ latitude: lat(), longitude: lng() })) ?? [];

    try {
      const params: Record<string, unknown> = {};

      if (radius) {
        params.radius = radius;
        params.centerLatitude = circleCenter?.latitude ?? 0;
        params.centerLongitude = circleCenter?.longitude ?? 0;
      } else {
        params.boundLatitudes = bounds.map(({ latitude }) => latitude);
        params.boundLongitudes = bounds.map(({ longitude }) => longitude);
      }

      params.addedFrom = formatDate(
        filters.timeSpan?.from ? new Date(filters.timeSpan.from) : startOfDay(new Date()),
        "yyyy-MM-dd'T'HH:mm:ss",
      );

      params.addedTo = formatDate(
        filters.timeSpan?.to ? new Date(filters.timeSpan.to) : startOfDay(new Date()),
        "yyyy-MM-dd'T'HH:mm:ss",
      );

      if (filters.company) params.company = filters.company;
      if (usersFilter?.length) params.users = usersFilter;

      params.showInactive = filters.showInactive ?? true;

      dispatch(fetchLocationsAction());
      const locations = await httpClient.get(`${apiHostname}/api/locations`, { params });
      dispatch(fetchLocationsSuccessAction(locations.data));
    } catch (error) {
      dispatch(fetchLocationsFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default fetchLocationsSlice.reducer;
