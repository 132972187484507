import { useEffect } from 'react';

import { fetchNotificationCount } from 'store/notificationCount';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';

const intervalTime = 10000;

export function useNotificationCount(enable: boolean): void {
  const dispatch = useAppDispatch();
  const { isPending: detailsPending, notification: details } = useAppSelector(
    state => state.notificationDetails,
  );

  useEffect(() => {
    if (enable) {
      dispatch(fetchNotificationCount());
      const intervalHandle = setInterval(() => dispatch(fetchNotificationCount()), intervalTime);

      return () => clearInterval(intervalHandle);
    }
  }, [enable]);

  useEffect(() => {
    if (!detailsPending && details) dispatch(fetchNotificationCount());
  }, [detailsPending, details]);
}
