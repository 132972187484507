import styled from '@emotion/styled';

import { MEDIA_QUERY, theme } from 'theme';

export const ModalFormContent = styled.div`
  background-color: ${theme.colors.mineShaft};
  border-radius: 12px;
  max-width: 100%;
  padding: 24px 32px;
  width: 430px;

  fieldset {
    border-bottom: none;
    padding: 0;
  }
`;

export const ModalFormButtonContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${theme.colors.mineShaftLightest};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
`;

export const ModalFormInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  div[data-cy='tooltip-component'] {
    margin-top: 20px;

    @media ${MEDIA_QUERY.MAX_MD} {
      margin-top: 0;
      height: 48px;
    }

    & > span {
      width: 32px;
      height: 32px;
      opacity: 0.5;
      color: ${theme.colors.white};
      background: ${theme.colors.white};

      @media ${MEDIA_QUERY.MAX_MD} {
        width: 48px;
        height: 48px;
      }
    }
  }

  .tooltip-container {
    color: ${theme.colors.white} !important;
  }

  & > div:nth-child(1) {
    padding: 5px 10px;
    flex-basis: 100%;

    @media ${MEDIA_QUERY.MAX_MD} {
      padding: 10px 0 0 0;
      flex-basis: 82%;
    }
  }

  & > div:nth-child(2) {
    width: 50px;
    text-align: center;
    align-self: self-end;
    margin-bottom: 10px;

    @media ${MEDIA_QUERY.MAX_MD} {
      margin-bottom: 0;
    }
  }
`;
