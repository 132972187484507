import React from 'react';
import styled from '@emotion/styled';

import { Theme } from 'theme';

const CancelStyled = styled.button<{ theme?: Theme; styleType?: string }>`
  height: 48px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  border-radius: 20px;
  padding: 16px 32px;
  font-size: 12px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  color: silver;
  outline: none;
  transition: border-color 0.2s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: silver;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.mineShaftLightest};
    cursor: not-allowed;
  }

  ${({ styleType }) => `
    ${styleType === 'flex' ? 'padding: 0' : ''}
  `}
`;

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  styleType?: string;
};

const CancelButton = ({ children, disabled, onClick, styleType }: Props): React.ReactElement => (
  <CancelStyled
    data-cy="cancel-button"
    styleType={styleType}
    onClick={onClick}
    disabled={disabled}
    type="button"
  >
    {children}
  </CancelStyled>
);

export { CancelButton, CancelStyled };
