export enum VEHICLE_TYPES {
  VAN = 'van',
  COUPE = 'coupe',
  CABRIOLET = 'cabriolet',
  SALOON = 'saloon',
  ESTATE_WAGON = 'estate_wagon',
  SPORT = 'sport',
  SUV = 'suv',
  PICKUP = 'pickup',
  MINIVAN = 'minivan',
  HATCHBACK_LIFTBACK_NOTCHBACK = 'hatchback_liftback_notchback',
  BUS = 'bus',
  TRUCK = 'truck',
  CUV = 'crossover',
  MOTORCYCLE = 'motorcycle',
  TRACTOR = 'tractor',
}
