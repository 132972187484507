import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, SuccessResponse } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum ShoppingCartKeys {
  RenameShoppingCarts = 'RenameShoppingCarts',
  CreateShoppingCart = 'CreateShoppingCart',
  RefreshShoppingCart = 'RefreshShoppingCart',
  AddItemToShoppingCart = 'AddItemToShoppingCart',
  RemoveShoppingCart = 'RemoveShoppingCart',
  ClearShoppingCart = 'ClearShoppingCart',
  EditItemInShoppingCart = 'EditItemInShoppingCart',
  EditItemsGroupInShoppingCart = 'EditItemsGroupInShoppingCart',
  RemoveItemFromShoppingCart = 'RemoveItemFromShoppingCart',
  RemoveItemsGroupFromShoppingCart = 'RemoveItemsGroupFromShoppingCart',
}

interface ShoppingCartParams {
  id: number;
  name: string;
}

export const useRenameShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, ShoppingCartParams>,
) =>
  useMutation<void, AlphamartHttpError, ShoppingCartParams, unknown>(
    async (params: ShoppingCartParams) => {
      await getHttpClient().put<SuccessResponse>(
        `${apiHostname}/api/shopping-cart/${params.id}/rename`,
        {
          name: params.name,
        },
      );
    },
    {
      mutationKey: [ShoppingCartKeys.RenameShoppingCarts],
      ...options,
    },
  );

export const useCreateShoppingCart = (
  options?: UseMutationOptions<number, AlphamartHttpError, string>,
) =>
  useMutation<number, AlphamartHttpError, string>(
    async name => {
      const response = await getHttpClient().post<number>(`${apiHostname}/api/shopping-cart`, {
        name,
      });
      return response.data;
    },
    {
      ...options,
      mutationKey: [ShoppingCartKeys.CreateShoppingCart],
    },
  );

export const useRefreshShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, number>,
) =>
  useMutation<void, AlphamartHttpError, number, unknown>(
    async params => {
      await getHttpClient().put<SuccessResponse>(
        `${apiHostname}/api/shopping-cart/${params}/refresh`,
      );
    },
    {
      mutationKey: [ShoppingCartKeys.RefreshShoppingCart],
      ...options,
    },
  );

export interface AddItemToShoppingCart {
  shoppingCartId: number;
  convertersIds: number[];
  unitsCount: number;
  partOfConverter: number;
}

export const useAddItemsToShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, AddItemToShoppingCart>,
) =>
  useMutation<void, AlphamartHttpError, AddItemToShoppingCart>(
    async values => {
      const { shoppingCartId, ...params } = values;
      await getHttpClient().post<SuccessResponse>(
        `${apiHostname}/api/shopping-cart/${shoppingCartId}/item`,
        params,
      );
    },
    {
      ...options,
      mutationKey: [ShoppingCartKeys.AddItemToShoppingCart],
    },
  );

interface RemoveParams {
  id: number;
}

export const useRemoveShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, RemoveParams>,
) =>
  useMutation(
    async data => {
      await getHttpClient().delete<SuccessResponse>(`${apiHostname}/api/shopping-cart/${data.id}`);
    },
    {
      mutationKey: [ShoppingCartKeys.RemoveShoppingCart],
      ...options,
    },
  );

export const useClearShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, number>,
) =>
  useMutation<void, AlphamartHttpError, number>(
    async cartId => {
      await getHttpClient().post<SuccessResponse>(
        `${apiHostname}/api/shopping-cart/${cartId}/clear`,
      );
    },
    {
      ...options,
      mutationKey: [ShoppingCartKeys.ClearShoppingCart],
    },
  );

export interface EditItemInShoppingCart {
  id: number;
  unitsCount: number;
  partOfConverter?: number;
}

export const useEditItemInShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, EditItemInShoppingCart>,
) =>
  useMutation<void, AlphamartHttpError, EditItemInShoppingCart>(
    async values => {
      const { id, ...params } = values;
      await getHttpClient().put<SuccessResponse>(
        `${apiHostname}/api/shopping-cart/item/${id}`,
        params,
      );
    },
    {
      ...options,
      mutationKey: [ShoppingCartKeys.EditItemInShoppingCart],
    },
  );

export const useRemoveItemFromShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, number>,
) =>
  useMutation<void, AlphamartHttpError, number>(
    async id => {
      await getHttpClient().delete<SuccessResponse>(`${apiHostname}/api/shopping-cart/item/${id}`);
    },
    {
      ...options,
      mutationKey: [ShoppingCartKeys.RemoveItemFromShoppingCart],
    },
  );

export const useRemoveItemsGroupFromShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, number>,
) =>
  useMutation<void, AlphamartHttpError, number>(
    async id => {
      await getHttpClient().delete<SuccessResponse>(
        `${apiHostname}/api/shopping-cart/item-group/${id}`,
      );
    },
    {
      ...options,
      mutationKey: [ShoppingCartKeys.RemoveItemsGroupFromShoppingCart],
    },
  );

export interface EditItemsGroupInShoppingCart {
  id: number;
  unitsCount: number;
  partOfConverter?: number;
}

export const useEditItemsGroupInShoppingCart = (
  options?: UseMutationOptions<void, AlphamartHttpError, EditItemsGroupInShoppingCart>,
) =>
  useMutation<void, AlphamartHttpError, EditItemsGroupInShoppingCart>(
    async values => {
      const { id, ...params } = values;
      await getHttpClient().put<SuccessResponse>(
        `${apiHostname}/api/shopping-cart/item-group/${id}`,
        params,
      );
    },
    {
      ...options,
      mutationKey: [ShoppingCartKeys.EditItemsGroupInShoppingCart],
    },
  );
