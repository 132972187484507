import { QueryClient } from '@tanstack/react-query';

export * from './assignableUsers';
export * from './companies';
export * from './converters';
export * from './countries';
export * from './decodeVin';
export * from './devices';
export * from './feedbacks';
export * from './frequentFlyers';
export * from './guides';
export * from './hedgeDetails';
export * from './nonstandardConverters';
export * from './provinces';
export * from './roles';
export * from './shoppingCarts';
export * from './statistics';
export * from './users';
export * from './vehicles';

export function configureQueryClient(): QueryClient {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
      mutations: {
        retry: false,
      },
    },
  });

  return queryClient;
}
