import React, { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';
import { DetailsField } from './DetailsField';

export interface FooterField {
  label: ReactNode;
  value: ReactNode;
  name: string;
}
interface DetailsFooterProps {
  fields: FooterField[];
}

const FooterLayout = styled.footer<{ theme?: Theme; fields: FooterField[] }>`
  align-items: end;
  display: grid;
  align-content: space-between;
  grid-template-columns: ${({ fields }) => `repeat(${fields.length}, 1fr)`};
  grid-row-end: none;

  & > div {
    min-width: unset;

    @media ${MEDIA_QUERY.XL} {
      :not(:first-of-type):not(:last-of-type) {
        padding: 20px 4px;
      }

      :first-of-type {
        padding: 20px 4px 20px 34px;
      }

      :last-of-type {
        padding: 20px 34px 20px 4px;
      }
    }
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    align-items: unset;
    background: ${({ theme }) => theme.colors.mineShaftLighter};
    grid-template-columns: ${({ fields }) =>
      `repeat(${fields.length > 2 ? 2 : fields.length}, 1fr)`};
  }

  @media ${MEDIA_QUERY.MAX_SM} {
    grid-template-columns: 100%;
  }
`;

export const DetailsFooter = ({ fields = [] }: DetailsFooterProps): ReactElement => (
  <FooterLayout fields={fields}>
    {fields.map(field => (
      <DetailsField name="" key={field.name} label={field.label} value={field.value} />
    ))}
  </FooterLayout>
);
