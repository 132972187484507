import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';

import { NoConnection } from 'components/views';
import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useExtendedTheme, useInitUserFilters, useNotificationCount } from 'shared/hooks';
import { SessionListener } from 'shared/utils/sessionListener';
import { logout } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { withClearCache } from './shared/ClearCache';
import GeolocationIframe from './shared/GeolocationIframe/GeolocationIframe';
import SEO from './shared/SEO/SEO';
import { routes } from './RootRoute';

const router = createBrowserRouter(routes);

function MainApp() {
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const theme = useExtendedTheme();
  const auth = useAppSelector(state => state.auth);

  useNotificationCount(!!auth?.user?.data && authorize(PERMISSIONS.NOTIFICATIONS.MAIN));
  useInitUserFilters();

  useEffect(() => {
    window.sessionStorage.setItem('alphamartAppState', 'loaded');
  }, []);

  useEffect(() => {
    SessionListener.setTimeout(() => {
      dispatch(logout('/logged-out'));
    });
  }, []);

  if (!navigator.onLine) return <NoConnection />;

  return (
    <>
      <SEO />
      <GeolocationIframe />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
}

const ClearCacheElement = withClearCache(MainApp);

function App(): React.ReactElement {
  return <ClearCacheElement />;
}

export default App;
