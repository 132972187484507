import styled from '@emotion/styled';

import { Theme } from 'theme';

export const MapUserInfoContent = styled.div<{ theme?: Theme }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  padding: 12px;

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.alphaGray};
    height: 50%;
    margin: 0;
    padding-top: 12px;

    &:nth-of-type(odd) {
      font-weight: bold;
      color: ${({ theme }) => theme.mainColor};
    }

    &:nth-of-type(even) {
      margin-bottom: 12px;
    }

    &:nth-of-type(odd):not(:last-of-type):not(:first-of-type) {
      &::after {
        content: '';
        position: absolute;
        width: calc(100% + 0px);
        height: 1px;
        background-color: ${({ theme }) => theme.colors.charcoal};
        left: 0;
        transform: translateY(-12px);
      }
    }
  }

  span.status {
    color: ${({ theme }) => theme.colors.whisper};
    text-transform: uppercase;
    font-weight: 200;
  }
`;
