import React, { useContext } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import arrowBackIcon from 'assets/images/icons/arrowBackSilver.svg';
import { CancelButton, Submit } from 'components/shared/Buttons';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import {
  StyledBackButton,
  StyledBackIcon,
  StyledButtonsWrapper,
  StyledControls,
  StyledCreateButtonWrapper,
} from './Controls.styles';
import { WizardContext } from '../Wizard';

interface Props {
  stepsErrors?: (FormikErrors<unknown> | undefined)[];
  touched: FormikTouched<unknown>;
  isSubmitting: boolean;
  isValid: boolean;
  isValidating: boolean;
  editMode?: boolean;
  onGoBack?: () => void;
  lastPageButton?: string;
}

const Controls = ({
  touched,
  stepsErrors = [],
  isValid,
  isSubmitting,
  isValidating,
  editMode = false,
  onGoBack,
  lastPageButton,
}: Props): React.ReactElement => {
  const { changePage, currentPage, pageIndexes } = useContext(WizardContext);
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const onCancel = () =>
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Modal.Confirm' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          if (onGoBack) {
            onGoBack();
          } else {
            navigate(-1);
          }
          dispatch(hideModal());
        },
      }),
    );

  const errorsCountForCurrentStep =
    currentPage && stepsErrors?.length && stepsErrors?.[currentPage - 1]
      ? Object.keys(stepsErrors[currentPage - 1]!)?.length
      : 0;

  const currentPageValid = errorsCountForCurrentStep === 0;
  const isLastPage = pageIndexes?.at(-1) === currentPage;

  const submitDisabled =
    !isValid ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !Object.keys(editMode ? { ...touched, ...(touched as any).converter } : touched).length ||
    isValidating ||
    isSubmitting;

  const lastPageButtonText = lastPageButton ?? intl.formatMessage({ id: 'Global.Submit' });

  return (
    <StyledControls>
      <StyledBackButton>
        {currentPage > 1 && (
          <CancelButton onClick={() => changePage(currentPage - 1)}>
            <StyledBackIcon left icon={arrowBackIcon} />
            <FormattedMessage id="Global.Back" />
          </CancelButton>
        )}
      </StyledBackButton>
      <StyledButtonsWrapper>
        <CancelButton onClick={() => onCancel()}>
          <FormattedMessage id="Global.Cancel" />
        </CancelButton>
        <StyledCreateButtonWrapper>
          {isLastPage ? (
            <Submit label={lastPageButtonText} disabled={submitDisabled} data-cy="submit" />
          ) : (
            <AppButton
              type="button"
              disabled={!currentPageValid}
              onClick={() => changePage(currentPage + 1)}
              data-cy="form-wizard-next"
            >
              <FormattedMessage id="Global.Next" />
              <StyledBackIcon right icon={arrowBackIcon} />
            </AppButton>
          )}
        </StyledCreateButtonWrapper>
      </StyledButtonsWrapper>
    </StyledControls>
  );
};

export { Controls };
