import React, { useCallback } from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useCreateHedge } from 'shared/mutations';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { HedgeForm } from '../HedgeForm/HedgeForm';
import { messages, useTypedIntl } from '../locale/messages';

const CreateHedgeComponent = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const { mutateAsync: createHedge, isLoading: isCreateHedgeLoading } = useCreateHedge();

  const onSubmit = useCallback(
    async values => {
      try {
        await createHedge(values);
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'HedgeForm.Create.Success' })));
      } catch {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
      navigate('/hedges');
    },
    [dispatch],
  );

  return (
    <LoadableContent drawContent mode={LoadableContent.MODE.FULL} loading={isCreateHedgeLoading}>
      <FormContainer>
        <HedgeForm onSubmit={onSubmit} />
      </FormContainer>
    </LoadableContent>
  );
};

export const CreateHedge = withAlphamartIntlProvider(CreateHedgeComponent, messages);
