import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { ModalFormType } from 'shared/constants';
import {
  Converter,
  Hedge,
  ShoppingCart,
  ShoppingCartItem,
  ShoppingCartItemsGroup,
  User,
  UserListItem,
} from 'shared/types';

export interface DownloadCompanyLocationsForm {
  companyId: number;
}

export interface DownloadUserLogsForm {
  userId: number;
}

export interface DownloadCompanyLogsExcelForm {
  companyId: number;
  companyName: string;
}

export interface ThresholdsModalForm {
  assignedId: number;
  assignedName: string;
  maxConverterSearchesPerDay?: number;
  maxConverterViewsPerDay?: number;
}

export interface AssignedVehicle {
  id: number;
  year: string;
  make: string;
  model: string;
}

export interface AssignedVehiclesModal {
  assignedVehicles: AssignedVehicle[];
}

export interface VinSearchModal {
  vinCode: string;
}

export interface HedgeAssignParams {
  hedge: Hedge;
  onSuccess: () => void;
}

export interface ShoppingCartParams {
  cart: ShoppingCart;
}

export interface AddShoppingCartItemParams {
  converters: Converter[];
}

export interface EditShoppingCartItemParams {
  shoppingCartElement: ShoppingCartItem;
  type: 'single';
}

export interface EditShoppingCartItemsGroupParams {
  shoppingCartElement: ShoppingCartItemsGroup;
  type: 'multi';
}

export interface ModalFormState {
  isOpen: boolean;
  modalType: ModalFormType | null;
  params:
    | User
    | UserListItem
    | DownloadCompanyLogsExcelForm
    | DownloadUserLogsForm
    | DownloadCompanyLocationsForm
    | ThresholdsModalForm
    | VinSearchModal
    | HedgeAssignParams
    | AssignedVehiclesModal
    | ShoppingCartParams
    | AddShoppingCartItemParams
    | EditShoppingCartItemParams
    | EditShoppingCartItemsGroupParams
    | null;
}

type ModalFormReducer = {
  showModalForm: CaseReducer<
    ModalFormState,
    { type: string; payload: Omit<ModalFormState, 'isOpen'> }
  >;
  hideModalForm: CaseReducer<ModalFormState>;
};

const modalFormSlice = createSlice<ModalFormState, ModalFormReducer>({
  name: 'modalForm',
  initialState: {
    isOpen: false,
    modalType: null,
    params: null,
  },
  reducers: {
    showModalForm: (state, { payload }) => ({
      isOpen: true,
      ...payload,
    }),
    hideModalForm: () => ({
      isOpen: false,
      modalType: null,
      params: null,
    }),
  },
});

export const { showModalForm, hideModalForm } = modalFormSlice.actions;

export default modalFormSlice.reducer;
