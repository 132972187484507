import React, { Children, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikContextType, FormikProvider } from 'formik';

import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useExtendedTheme, useMediaQuery } from 'shared/hooks';
import { FilterData } from 'store/shared/filtersSlice';
import { MEDIA_QUERY, Theme } from 'theme';
import AppButton from '../AppButton/AppButton';
import { FiltersFooter } from '../FiltersFooter/FiltersFooter';

export const SidebarFormContent = styled.div<{ theme?: Theme }>`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 32px;
  font-family: ${({ theme }) => theme.fonts.montserrat};

  fieldset:last-child {
    border-bottom: none;
  }
`;

const StyledForm = styled.form`
  height: calc(100% - 180px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const clearFiltersButton = (theme: Theme) => css`
  font-size: 12px;
  color: ${theme.colors.alphaGray};
  padding: 15px;
  border-radius: 8px;

  &:before {
    border-radius: 8px;
  }
`;

const applyFiltersButton = css`
  font-size: 12px;
  padding: 15px;
  border-radius: 8px;

  &:before {
    border-radius: 8px;
  }
`;

interface Props<Values> {
  children: React.ReactNode;
  onFiltersClear: React.MouseEventHandler;
  onFiltersApply: React.MouseEventHandler;
  savedFilters: FilterData;
  context: FormikContextType<Values>;
}

export const FiltersForm = <Values,>({
  children: sections,
  context,
  onFiltersClear,
  onFiltersApply,
  savedFilters,
}: Props<Values>): React.ReactElement => {
  const parsedSections = useMemo(
    () => Children.toArray(sections).filter(Boolean).flat(),
    [sections],
  ) as unknown as React.ReactElement<{ sectionName: string }>[];
  const theme = useExtendedTheme();
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  return (
    <FormikProvider value={context}>
      <StyledForm noValidate>
        <div className="form-content">
          <SidebarFormContent>
            {parsedSections.map((section, index) => (
              <React.Fragment key={section?.props?.sectionName ?? index}>{section}</React.Fragment>
            ))}
          </SidebarFormContent>
        </div>
      </StyledForm>
      <FiltersFooter>
        <AppButton
          className={clearFiltersButton(theme)}
          styleType="neutral"
          onClick={onFiltersClear}
          disabled={savedFilters.diff.count === 0}
        >
          <FormattedMessage id="Global.Filters.Clear" />
        </AppButton>
        {isMobile && (
          <AppButton
            type="button"
            className={applyFiltersButton}
            onClick={onFiltersApply}
            disabled={context.errors && Object.keys(context.errors).length !== 0}
          >
            <FormattedMessage id="Global.Filters.Apply" />
          </AppButton>
        )}
      </FiltersFooter>
    </FormikProvider>
  );
};
