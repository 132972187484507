import * as React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import icon from 'assets/images/icons/Back.svg';
import { theme } from 'theme';
import { TypedFormattedMessage as FormattedMessage } from './locale/messages';

interface Props {
  visible?: boolean;
}
const upDownMove = keyframes`
  from, to { transform: rotate(-90deg) translate(-3px,0); }
  50% { transform: rotate(-90deg) translate(3px, 0); }
`;

const PromptDownIcon = styled.span`
  display: inline-block;
  background-color: ${theme.colors.white};
  mask-image: url(${icon});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  width: 22px;
  height: 22px;
  animation: ${upDownMove} 2s ease infinite;
  padding: 5px 0;
`;

const PromptWrapper = styled.span<{ visible?: boolean }>`
  display: inline-flex;
  align-items: center;
  background: ${theme.colors.doveGray};
  border-radius: 4px;
  padding: 5px 10px;
  transition: opacity 0.1s ease-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const ScrollDownPrompt = ({ visible = true }: Props): React.ReactElement | null =>
  visible ? (
    <PromptWrapper visible={visible}>
      <FormattedMessage id="TermsAndConditionsModal.ScrollPrompt" />
      <PromptDownIcon />
    </PromptWrapper>
  ) : null;
