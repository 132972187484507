import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { getHttpClient } from 'shared/utils/httpClient';

export enum AssignableUsersKeys {
  GetAssignableUsers = 'GetAssignableUsers',
  GetAssignableUsersForCreate = 'GetAssignableUsersForCreate',
}

export interface AssignableHedgeUser {
  id: number;
  firstName: string;
  lastName: string;
  company: {
    id: number;
    name: string;
  };
}

interface GetAssignableUsersParams {
  hedgeId: number | string;
  relatedCompanies?: string[] | number[];
}

export const useGetAssignableUsers = (
  params: GetAssignableUsersParams,
  options: UseQueryOptions<AssignableHedgeUser[]> = {},
): UseQueryResult<AssignableHedgeUser[]> =>
  useQuery(
    [AssignableUsersKeys.GetAssignableUsers, params] as QueryKey,
    async () => {
      const response = await getHttpClient().get<AssignableHedgeUser[]>(
        `${apiHostname}/api/hedges/${params.hedgeId}/assign/list`,
        { params: { relatedCompanies: params.relatedCompanies } },
      );
      return response.data;
    },
    {
      initialData: [],
      ...options,
    },
  );

export const useGetAssignableUsersForCreate = (
  relatedCompanies: string[] | number[],
  options: UseQueryOptions<AssignableHedgeUser[]> = {},
): UseQueryResult<AssignableHedgeUser[]> =>
  useQuery(
    [AssignableUsersKeys.GetAssignableUsersForCreate, relatedCompanies] as QueryKey,
    async () => {
      const response = await getHttpClient().get<AssignableHedgeUser[]>(
        `${apiHostname}/api/hedges/assign/list-for-create`,
        { params: { relatedCompanies } },
      );
      return response.data;
    },
    {
      initialData: [],
      ...options,
    },
  );
