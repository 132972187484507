import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { apiHostname } from 'shared/constants';
import { GenericThunk } from './shared/createGenericStoreSlice';

export interface CreateConverterGroupParam {
  files: PhotoShape[];
}

export const createConvertersGroup =
  (values: CreateConverterGroupParam): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const fileIds: { data: string; id?: number }[] = await Promise.all(
        values.files.map(photo => {
          const formData = new FormData();
          formData.append('image', photo.file as File);
          return httpClient.post(`${apiHostname}/api/upload/image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );
      const data = {
        files: values.files.map((photo, index) => ({
          externalId: fileIds[index].data,
          fileName: photo.name,
          mimetype: photo.file?.type,
          sort: index,
          ...(fileIds[index].id ? { id: fileIds[index].id } : {}),
        })),
      };

      await httpClient.post(`${apiHostname}/api/converters/groups`, data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
