import { createSlice } from '@reduxjs/toolkit';

import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface PricesTimestampState extends GenericStoreSlice {
  timestamp: number;
}
const setPricesTimestampSlice = createSlice<
  PricesTimestampState,
  GenericStoreReducer<PricesTimestampState>
>({
  name: 'pricesTimestamp',
  initialState: {
    timestamp: 0,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      timestamp: payload,
    })),
  },
});

export const { success: setPricesTimestampSuccessAction } = setPricesTimestampSlice.actions;

export const setPricesTimestamp =
  (timestamp: number): GenericThunk =>
  async dispatch => {
    dispatch(setPricesTimestampSuccessAction(timestamp));
  };

export default setPricesTimestampSlice.reducer;
