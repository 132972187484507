import React from 'react';
import styled from '@emotion/styled';

import basketIcon from 'assets/images/icons/Basket.svg';
import { theme } from 'theme';
import { Icon } from '../Buttons';

interface Props {
  onClick?: () => void;
}

const BasketWrapper = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
`;

export function Basket({ onClick }: Props): React.ReactElement {
  return (
    <BasketWrapper onClick={onClick} data-cy="basket">
      <Icon pointer icon={basketIcon} size="24px" color={theme.colors.white} />
    </BasketWrapper>
  );
}
