import * as yup from 'yup';

import { testEmail } from 'helpers/validation/testEmail';
import { testPhoneNumber } from 'helpers/validation/testPhoneNumber';
import { MarginVisibility, METAL_ABBR, PRICE_SOURCES, SHARED, UserRoles } from 'shared/constants';
import { TypedIntlShape, UsersMessages } from '../locale/messages';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const userFormSchema = (
  intl: TypedIntlShape,
  roles?: {
    name: UserRoles;
    id: number;
  }[],
) => {
  const hedgeUsedSchema = (name, dependency) =>
    yup
      .string()
      .nullable()
      .when(dependency, {
        is: PRICE_SOURCES.HEDGE,
        then: yup
          .string()
          .nullable()
          .required(intl.formatMessage({ id: `${name}.Required` as keyof UsersMessages }))
          .min(1, intl.formatMessage({ id: `${name}.Required` as keyof UsersMessages })),
      });

  const profitMarginSchema = yup
    .string()
    .matches(/^-?\d+$/, {
      message: intl.formatMessage({
        id: 'UserForm.Errors.UserProfitMargin.NotMatchRegex',
      }),
    })
    .test({
      name: 'isRequired',
      message: intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.Required' }),
      test(val) {
        return !!val;
      },
    })
    .test({
      name: 'lessThan40',
      message: intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.LessThan' }),
      test(val) {
        return Number(val) <= 40;
      },
    })
    .test({
      name: 'moreOrEqual-0',
      message: intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.MoreThan' }),
      test(val) {
        return Number(val) >= 0;
      },
    });

  const termsAdjustmentsSchema = metal =>
    yup
      .number()
      .typeError(
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.InvalidType` as keyof UsersMessages,
        }),
      )
      .min(
        -100,
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.Min` as keyof UsersMessages,
        }),
      )
      .max(
        SHARED.MAX_DB_INTEGER,
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.Max` as keyof UsersMessages,
        }),
      )
      .integer(
        intl.formatMessage({
          id: `UserForm.Errors.${metal}TermsAdjustment.Integer` as keyof UsersMessages,
        }),
      )
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  return yup.object().shape({
    info: yup.object().shape({
      firstName: yup
        .string()
        .required(intl.formatMessage({ id: 'UserForm.Errors.FirstName.Required' })),
      lastName: yup
        .string()
        .required(intl.formatMessage({ id: 'UserForm.Errors.LastName.Required' })),
      mobilePhone: yup
        .string()
        .test({
          name: 'isValid',
          message: intl.formatMessage({ id: 'UserForm.Errors.MobilePhone.Invalid' }),
          test(val) {
            return testPhoneNumber(val, this.parent.mobilePhonePrefix);
          },
        })
        .required(intl.formatMessage({ id: 'UserForm.Errors.MobilePhone.Required' })),
      mobilePhonePrefix: yup.mixed().test({
        name: 'isValid',
        message: intl.formatMessage({ id: 'UserForm.Errors.MobilePhonePrefix.Required' }),
        test: val => !!val,
      }),
      officePhone: yup
        .string()
        .test({
          name: 'isValid',
          message: intl.formatMessage({ id: 'UserForm.Errors.OfficePhone.Invalid' }),
          test(val) {
            return val ? testPhoneNumber(val, this.parent.officePhonePrefix) : true;
          },
        })
        .when('$officePhonePrefix', (officePhonePrefix, schema) =>
          officePhonePrefix
            ? schema.required(intl.formatMessage({ id: 'UserForm.Errors.OfficePhone.Required' }))
            : schema,
        ),
      officePhonePrefix: yup.mixed().when('$officePhone', (officePhone, schema) =>
        officePhone
          ? schema.test({
              name: 'isValid',
              message: intl.formatMessage({ id: 'UserForm.Errors.OfficePhonePrefix.Required' }),
              test: val => !!val,
            })
          : schema,
      ),
      email: yup
        .string()
        .test({
          name: 'isEmailValid',
          message: intl.formatMessage({ id: 'UserForm.Errors.Email.Invalid' }),
          test: testEmail,
        })
        .required(intl.formatMessage({ id: 'UserForm.Errors.Email.Required' })),
      company: yup
        .string()
        .required(intl.formatMessage({ id: 'UserForm.Errors.Company.Required' }))
        .nullable(),
    }),

    roles: yup.object().shape({
      roles: yup
        .array()
        .ensure()
        .min(1, intl.formatMessage({ id: 'UserForm.Errors.Role.Required' }))
        .test({
          name: 'isMoreThanPrimaryContant',
          message: intl.formatMessage({ id: 'UserForm.Errors.Role.AlonePrimaryRole' }), // TODO
          test: val => {
            if (val?.length === 0) return true;

            if (
              val?.some(roleId =>
                roles?.some(role => role.name === UserRoles.PRIMARY_CONTACT && role.id === roleId),
              )
            ) {
              return val.length > 1;
            }
            return true;
          },
        }),
    }),

    prices: yup.object().shape({
      userProfitMargin: yup.mixed().when('marginVisibility', {
        is: MarginVisibility.NO_VISIBILITY,
        then: yup
          .array()
          .ensure()
          .of(yup.object({ value: profitMarginSchema }).nullable())
          .max(10),
        otherwise: yup
          .array()
          .ensure()
          .of(yup.object({ value: profitMarginSchema }).nullable())
          .max(1),
      }),
      assignedHedges: yup.array().ensure(),
      prices: yup.object().shape({
        ptPriceSource: yup.string(),
        pdPriceSource: yup.string(),
        rhPriceSource: yup.string(),
        ptHedgeUsed: hedgeUsedSchema('UserForm.Errors.PtHedgeUsed', 'ptPriceSource'),
        pdHedgeUsed: hedgeUsedSchema('UserForm.Errors.PdHedgeUsed', 'pdPriceSource'),
        rhHedgeUsed: hedgeUsedSchema('UserForm.Errors.RhHedgeUsed', 'rhPriceSource'),
      }),
      ptTermsAdjustment: termsAdjustmentsSchema(METAL_ABBR.PT),
      pdTermsAdjustment: termsAdjustmentsSchema(METAL_ABBR.PD),
      rhTermsAdjustment: termsAdjustmentsSchema(METAL_ABBR.RH),
    }),
  });
};
