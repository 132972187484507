import React from 'react';
import { css } from '@emotion/css';

import { theme } from 'theme';

type Size = 'M' | 'S';
type Props = {
  color?: string;
  className?: string;
  children: React.ReactNode;
  breakable?: boolean;
  size?: Size;
};

const getPadding = (size: Size) => {
  switch (size) {
    case 'M': {
      return 'padding: 5px 12px;';
    }
    case 'S': {
      return 'padding: 3px 8px;';
    }
  }
};
const getFontSize = (size: Size) => {
  switch (size) {
    case 'M': {
      return '';
    }
    case 'S': {
      return 'font-size: 12px;';
    }
  }
};
const badgeStyles = (color: string, breakable: boolean, size: Size) => css`
  background: ${color};
  display: inline-block;
  ${getPadding(size)}
  margin-right: 8px;
  margin-bottom: 4px;
  border-radius: 11px;
  word-break: ${breakable ? 'break-word' : 'keep-all'};
  ${getFontSize(size)}
`;

export const Badge = ({
  color,
  className = '',
  children,
  breakable = false,
  size = 'M',
}: Props): React.ReactElement => (
  <span
    className={`${badgeStyles(
      color ?? theme.colors.mineShaftLightest,
      breakable,
      size,
    )} ${className}`}
  >
    {children}
  </span>
);
