import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Forbidden } from 'components/views/Forbidden/Forbidden';
import { AllowedFor, useAuthorization } from 'shared/helpers';
import { useAppSelector } from 'store/shared/hooks';

interface Props {
  allowedFor?: AllowedFor;
  children?: React.ReactElement;
}

const ProtectedRoute = ({ allowedFor, children }: Props): React.ReactElement | null => {
  const auth = useAppSelector(state => state.auth);
  const authorize = useAuthorization();
  const authorized = !allowedFor || authorize(allowedFor);

  if (auth.user && authorized) return children ?? <Outlet />;
  if (auth.user && !authorized) return <Forbidden />;
  if (auth.isPending) return null;
  return <Navigate to="/login" />;
};

export { ProtectedRoute };
