import { apiHostname } from 'shared/constants';
import { parseConvertersGroup } from 'shared/parsers/parseConvertersGroup';
import { AlphamartHttpError, Converter, ConvertersGroup } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

export interface UpdateConvertersGroupFile {
  id?: number;
  file: File | { name: string; type: string };
  image: string;
  name: string;
  size: number;
  data?: string;
}

export interface UpdateConvertersGroupPhotosParam {
  converters?: Partial<Converter>[];
  files: UpdateConvertersGroupFile[];
}

export const updateConvertersGroupPhotos =
  (values: UpdateConvertersGroupPhotosParam, id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const files = await Promise.all(
        values.files.map(async photo => {
          if (photo.id) {
            return photo;
          }

          const formData = new FormData();
          formData.append('image', photo.file as File);
          return httpClient.post<string>(`${apiHostname}/api/upload/image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );
      const updatedValues = parseConvertersGroup(values, files);

      const convertersGroup = await httpClient.put<ConvertersGroup>(
        `${apiHostname}/api/converters/groups/${id}`,
        updatedValues,
      );
      return convertersGroup.data;
    } catch (error) {
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };
