import React, { useMemo } from 'react';
import Select from 'react-select';

import { FieldSelectOption } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldSelectStyled } from 'components/shared/Fields/FieldSelect/FieldSelect.styles';
import { useDropdownPlacement } from 'shared/hooks';
import { ShoppingCart as ShoppingCartType } from 'shared/types';
import { HIDDEN_ON_MEDIA_QUERY } from 'theme';

interface Props {
  carts: ShoppingCartType[];
  activeCartId: number | null;
  setActiveCartId: (id: number) => void;
}

const CartSelect = ({ carts, activeCartId, setActiveCartId }: Props): React.ReactElement => {
  const { dropdownPlacement, dropdownRef, refreshDropdownPlacement } =
    useDropdownPlacement('bottom');

  const selectOptions: FieldSelectOption[] = useMemo(
    () => carts.map(cart => ({ label: `${cart.name} (${cart.itemsCount})`, value: cart.id })),
    [carts],
  );
  const selectedOption: FieldSelectOption = useMemo(
    () => selectOptions.find(cart => cart.value === activeCartId)!,
    [selectOptions, activeCartId],
  );

  const styles = FieldSelectStyled(undefined, undefined, undefined, undefined, dropdownPlacement);

  return (
    <div ref={dropdownRef}>
      <Select
        name="shoppingCart"
        value={selectedOption}
        options={selectOptions}
        onChange={option => option && setActiveCartId(+option.value)}
        styles={styles}
        openMenuOnFocus
        menuPlacement={dropdownPlacement}
        onMenuOpen={refreshDropdownPlacement}
        className={HIDDEN_ON_MEDIA_QUERY.LG}
      />
    </div>
  );
};

export default CartSelect;
