import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Modifier, StrictModifierNames } from 'react-popper';
import { add, Duration, endOfDay } from 'date-fns';
import { useField } from 'formik';
import { isNil } from 'lodash';

import { getDateFormat } from 'helpers/dateTime/dateTime';
import { useTypedIntl } from 'locale/messages';
import { useExtendedTheme, useLanguage } from 'shared/hooks';
import { containerDatePickerStyles, datePickerStyles } from './FieldDatePickerRange.styles';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

export type DateValue = {
  from?: number | Date | string | null;
  to?: number | Date | string | null;
};

interface Props {
  name: string;
  value: DateValue | null | undefined;
  max?: Date | number | null;
  min?: Date | number | null;
  onChange?: (val?: DateValue) => void;
  dateFormat?: string;
  isClearable?: boolean;
  maxDateRange?: Duration;
  resetOnChange?: boolean;
  fillInBounds?: boolean;
}

const FieldDatePickerRange = ({
  name,
  value,
  onChange,
  dateFormat,
  min,
  max,
  maxDateRange,
  isClearable = false,
  resetOnChange = true,
  fillInBounds = false,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const [, , { setValue, setTouched }] = useField(name);
  const handleDateChangeRaw = e => {
    e.preventDefault();
    setTouched(!!name);
  };
  const rawLanguage = useLanguage();
  const currentLang = rawLanguage.toLowerCase();
  const defaultDateFormat = getDateFormat(rawLanguage);

  const stylesDatePickerFrom = useMemo(() => datePickerStyles('from', theme), []);
  const stylesDatePickerTo = useMemo(() => datePickerStyles('to', theme), []);
  const popperModifiers: ReadonlyArray<Modifier<StrictModifierNames>> = [
    {
      name: 'preventOverflow',
      options: {
        padding: 5,
        rootBoundary: 'viewport',
      },
    },
  ];

  const getMaxDate = (fromValue?: string | number | Date | null) => {
    if (!maxDateRange || !fromValue) return max;
    const maxFromTimeSpan = add(new Date(fromValue), maxDateRange);
    return !max || maxFromTimeSpan <= max ? maxFromTimeSpan : max;
  };

  const setFromValue = (fromValue?: string | number | Date | null) => {
    let newValue = { ...value, from: fromValue };

    if (resetOnChange && value?.to !== null && !isNil(fromValue)) {
      newValue = { from: fromValue, to: null };
    }

    if (fillInBounds && maxDateRange && !isNil(fromValue)) {
      newValue = { from: fromValue, to: endOfDay(getMaxDate(fromValue)!) };
    }

    setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <div className={containerDatePickerStyles(theme)}>
      <div>
        <DatePicker
          className={stylesDatePickerFrom}
          value={value?.from as string}
          name={`${name}From`}
          minDate={min as Date}
          maxDate={max as Date}
          selected={value?.from as Date}
          dateFormat={dateFormat ?? defaultDateFormat}
          onChangeRaw={handleDateChangeRaw}
          onChange={val => {
            setTouched(true);
            setFromValue(val);
          }}
          onFocus={event => event.target.blur()}
          placeholderText={intl.formatMessage({ id: 'Global.Fields.DateRange.From' })}
          popperModifiers={popperModifiers}
          locale={currentLang}
          isClearable={isClearable}
        />
      </div>
      <div>
        <DatePicker
          className={stylesDatePickerTo}
          value={value?.to as string}
          name={`${name}To`}
          selected={value?.to as Date}
          dateFormat={dateFormat ?? defaultDateFormat}
          onChangeRaw={handleDateChangeRaw}
          onChange={val => {
            setTouched(true);
            setValue({ ...value, to: val });
            if (onChange) onChange({ ...value, to: val });
          }}
          onFocus={event => event.target.blur()}
          placeholderText={intl.formatMessage({ id: 'Global.Fields.DateRange.To' })}
          popperModifiers={popperModifiers}
          minDate={(value?.from || min) as Date}
          maxDate={getMaxDate(value?.from) as Date}
          locale={currentLang}
          isClearable={isClearable}
        />
      </div>
    </div>
  );
};

const FieldDatePickerRangeWrapped = withFilterWrapper<Props>(props => (
  <FieldDatePickerRange {...props} />
));

export { FieldDatePickerRangeWrapped as FieldDatePickerRange };
