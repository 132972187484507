import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const Container = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-areas: 'label error' 'input input';
  grid-template-columns: auto auto;
  grid-column-gap: 32px;

  @media ${MEDIA_QUERY.XL} {
    grid-template-columns: calc(2 * 32px) 1fr;
    grid-template-areas: 'error error' 'label input';
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: 1fr 2fr;
  }
`;
