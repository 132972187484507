export enum SUPPORTED_CURRENCIES {
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CLP = 'CLP',
  EUR = 'EUR',
  ILS = 'ILS',
  MXN = 'MXN',
  PLN = 'PLN',
  USD = 'USD',
}

export enum SUPPORTED_PORTAL_CURRENCIES {
  CAD = 'CAD',
  EUR = 'EUR',
  MXN = 'MXN',
  PLN = 'PLN',
  USD = 'USD',
}
