import React, { forwardRef, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

import iconClose from 'assets/images/icons/Close.svg';
import { AlphamartHistoryShape, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { ItemAction } from 'shared/types';
import { sleep } from 'shared/utils/sleep';
import { layers, MEDIA_QUERY, NAVBAR_HEIGHT_MOBILE, Theme } from 'theme';
import { IconButton } from '../Buttons';
import { ActionsButton } from '../Buttons/ActionsButton';

type Options = {
  direction?: 'left' | 'right';
  width?: string;
  offset?: string;
  header?: {
    padding: 'M' | 'L';
  };
};

type OnCloseProps =
  | {
      onClose: () => void;
    }
  | { backUrl: string; backState?: AlphamartHistoryShape };

type DetailsProps = {
  open?: boolean;
  children: ReactNode;
  title: ReactNode;
  scrollable?: boolean;
  actions?: ItemAction[];
  onBeforeClose?: (onClose: () => void) => void | Promise<void>;
  swipeToClose?: boolean;
  options?: Options;
  className?: string;
} & OnCloseProps;

const DetailsOverlay = styled.div<{ theme?: Theme; options?: Options }>`
  position: fixed;
  top: 0;
  ${({ options }) => options?.direction ?? 'right'}: ${({ options }) => options?.offset ?? '0px'};
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: ${layers.details};

  @media ${MEDIA_QUERY.MAX_MD} {
    margin-top: ${NAVBAR_HEIGHT_MOBILE};
    ${({ options }) => options?.direction ?? 'right'}: 0px;
  }
`;

const DetailsLayout = styled.div<{ theme?: Theme; scrollable: boolean; options?: Options }>`
  background: ${({ theme }) => theme.colors.codGrayDarker};
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ options }) => options?.direction ?? 'right'}: 0px;
  min-width: ${({ options }) => options?.width ?? '65vw'};
  max-width: ${({ options }) => options?.width ?? '65vw'};
  overflow-y: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
  overflow-x: hidden;
  transition: transform 0.2s ease;
  z-index: ${layers.details};

  &.slide-enter {
    ${({ options }) => (options?.direction === 'left' ? 'left: -100%;' : 'right: -100%;')};
    transform: translateX(${({ options }) => (options?.direction === 'left' ? '-' : '')}100%);
  }

  &.slide-enter-active {
    transform: translateX(${({ options }) => (options?.direction === 'left' ? '-' : '')}100%);
  }

  &.slide-exit {
    transform: translateX(0);
  }

  &.slide-exit-active {
    transform: translateX(${({ options }) => (options?.direction === 'left' ? '-' : '')}100%);
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    height: calc(100% - ${NAVBAR_HEIGHT_MOBILE});
    min-width: 100%;
    max-width: 100%;
    width: 100vw;
  }
`;

const HeaderLayout = styled.header<{ theme?: Theme; options?: Options }>`
  display: flex;
  align-items: center;
  padding: 30px ${({ options }) => (options?.header?.padding === 'M' ? '36' : '102')}px;
  min-height: 100px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  color: ${({ theme }) => theme.fontColor};
  font-size: 18px;
  word-break: break-word;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;

    & > button {
      margin-left: 16px;
    }
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding-right: 56px;
    padding-left: 56px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    align-items: flex-start;
    padding-right: 40px;
    padding-left: 40px;

    & > div {
      flex-direction: column;

      & > button {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
`;

const ContentLayout = styled.div<{ sectionsCount: number; options?: Options }>`
  height: calc(100vh - 100px);
  display: grid;
  align-items: end;
  position: absolute;
  width: ${({ options }) => options?.width ?? '65vw'};
  grid-template-columns: 100%;
  grid-template-rows: ${({ sectionsCount }) =>
    sectionsCount > 2 ? `repeat(${sectionsCount}, auto) 1fr auto` : `1fr auto`};

  > div:last-of-type {
    padding-bottom: 56px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export const Details = forwardRef<HTMLDivElement, DetailsProps>(
  (
    {
      open = true,
      title,
      children,
      scrollable = true,
      actions = [],
      onBeforeClose,
      swipeToClose = true,
      options,
      className,
      ...props
    }: DetailsProps,
    ref,
  ): React.ReactElement => {
    const [isMounted, setMounted] = useState(false);
    const navigate = useAlphamartNavigate();

    const onClose = async (): Promise<void> => {
      setMounted(false);
      await sleep(200);

      if ('onClose' in props) return props.onClose();
      return navigate(props.backUrl, { state: props.backState });
    };

    const onCloseAttempt = async (): Promise<void> => {
      if (!onBeforeClose) return onClose();

      return onBeforeClose(onClose);
    };

    const childrenFiltered = React.Children.toArray(children).filter(Boolean).length;

    const swipeHandler = useCallback(
      async ({ event }) => {
        const ids = ['gallery', 'imageLightbox', 'profileProfitMarginSlider'];
        const [galleryCoords, lightbox, pmSliderCoords] = ids.map(id =>
          document.querySelector(`#${id}`)?.getBoundingClientRect(),
        );
        const touch =
          event.type === 'touchend'
            ? event.touches[0] || event.changedTouches[0]
            : { clientX: event.clientX, clientY: event.clientY };
        const mouseCoords = {
          x: touch.clientX,
          y: touch.clientY,
        };
        const isTouchDevice = 'ontouchstart' in window;
        const isSwipingDisabled = [galleryCoords, lightbox, pmSliderCoords].some(
          el =>
            el &&
            mouseCoords?.x >= el.left &&
            mouseCoords?.x <= el.right &&
            mouseCoords?.y >= el.top &&
            mouseCoords?.y <= el.bottom,
        );

        if (isSwipingDisabled || !isTouchDevice || !swipeToClose) return;

        if (isMounted) {
          await onClose();
        }
      },
      [onClose, isMounted, swipeToClose],
    );

    const handlers = useSwipeable({
      trackTouch: true,
      trackMouse: true,
      onSwipedRight: swipeHandler,
    });

    useEffect(() => {
      setMounted(true);

      return () => {
        setMounted(false);
      };
    }, []);

    useEffect(() => {
      if (!open) {
        onClose();
      }
    }, [open]);

    const nodeRef = useRef<HTMLDivElement>(null);

    return (
      <DetailsOverlay
        onClick={onCloseAttempt}
        ref={ref}
        options={options}
        data-cy="details-overlay"
        className={className}
      >
        <CSSTransition in={isMounted} timeout={200} classNames="slide" nodeRef={nodeRef}>
          <DetailsLayout
            scrollable={scrollable}
            onClick={e => e.stopPropagation()}
            options={options}
            data-cy="details-layout"
            {...handlers}
            ref={mergeRefs([nodeRef, handlers.ref])}
          >
            <HeaderLayout options={options}>
              <HeaderWrapper>
                <div>{title}</div>
                <ActionsButton actions={actions} />
              </HeaderWrapper>
              <IconButton icon={iconClose} onClick={onCloseAttempt} />
            </HeaderLayout>
            <ContentLayout sectionsCount={childrenFiltered} options={options}>
              {children}
            </ContentLayout>
          </DetailsLayout>
        </CSSTransition>
      </DetailsOverlay>
    );
  },
);
