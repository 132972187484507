import { createSlice } from '@reduxjs/toolkit';

import { apiHostname, DrawingTools } from 'shared/constants';
import { parseOffZone } from 'shared/parsers/parseOffZone';
import { AlphamartHttpError, BackendOffZone } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { setOffZones } from './mapDrawingSlice';

interface OffZoneState extends GenericStoreSlice {
  offZones: BackendOffZone[];
}

const fetchOffZonesSlice = createSlice<OffZoneState, GenericStoreReducer<OffZoneState>>({
  name: 'offZones',
  initialState: {
    offZones: [],
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      offZones: payload,
    })),
  },
});

export const {
  pending: fetchOffZonesAction,
  success: fetchOffZonesSuccessAction,
  failure: fetchOffZonesFailureAction,
} = fetchOffZonesSlice.actions;

export const fetchOffZones = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    dispatch(fetchOffZonesAction());
    const offZones = await httpClient.get<BackendOffZone[]>(`${apiHostname}/api/off-zones`);
    dispatch(
      setOffZones({
        [DrawingTools.CIRCLE]: [],
        [DrawingTools.POLYGON]: offZones.data.map(parseOffZone),
        [DrawingTools.RECTANGLE]: [],
      }),
    );
    dispatch(fetchOffZonesSuccessAction(offZones.data));
  } catch (error) {
    dispatch(fetchOffZonesFailureAction((error as AlphamartHttpError)?.response?.data.message));
    return Promise.reject(error);
  }
};

export default fetchOffZonesSlice.reducer;
