import React from 'react';
import styled from '@emotion/styled';

import { Tooltip } from 'components/shared/Tooltip/Tooltip';
import { Theme, theme as globalTheme } from 'theme';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

interface Props {
  disabled?: boolean;
  dataCy?: string;
  value: string | number | readonly string[] | boolean | null | undefined;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  options: {
    label: React.ReactNode;
    value: string | number | readonly string[];
    description?: string;
  }[];
  onClear?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  hideContainerBorder?: boolean;
  radioButtonBorderColor?: string;
  overflowMode?: string;
  disableRounding?: boolean;
}

type ContainerProps = {
  disabled?: boolean;
  hideBorders?: boolean;
  disableRounding?: boolean;
  theme?: Theme;
  overflowMode?: string;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-around;
  border: ${({ hideBorders }) => (hideBorders ? '0' : '1')}px solid
    ${({ theme }) => theme.colors.alphaGray};
  ${({ disableRounding }) => (disableRounding ? '' : 'border-radius: 20px;')}
  transition: border-color 0.2s ease;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 12px;
  text-align: center;
  overflow: ${({ overflowMode }) => overflowMode};

  :hover {
    border-color: ${({ theme }) => theme.colors.alphaGray};
  }

  div:first-of-type > input + label {
    ${({ disableRounding }) => (disableRounding ? '' : 'border-radius: 20px 0 0 20px;')}
  }

  div:last-of-type > input + label {
    ${({ disableRounding }) => (disableRounding ? '' : 'border-radius: 0 20px 20px 0;')}
  }

  div:only-of-type > input + label {
    border-radius: 0px;
  }

  ${({ disabled, theme }) => disabled && `color: ${theme.colors.alphaGray}; border-style: dashed;`}
`;

const RadioButton = styled.div<{ borderColor?: string; theme?: Theme }>`
  width: 100%;

  input {
    opacity: 0;
    position: fixed;
    width: 0;

    &:checked + label {
      color: ${({ theme }) => theme.mainColor};
      background-color: ${({ theme }) => theme.colors.mineShaft};
    }

    &:hover + label {
      cursor: pointer;
    }
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    transition: color 0.2s ease;
  }
  &:not(:last-of-type) {
    border-right: 1px solid ${({ borderColor, theme }) => borderColor ?? theme.colors.alphaGray};
  }

  input:disabled + label {
    cursor: not-allowed;
  }

  input[disabled][checked] + label {
    background-color: ${({ theme }) => theme.colors.mineShaft};
  }
`;

const getKey = (name, label, value) => `${name}_${typeof label === 'string' ? label : value}`;

const FieldRadioButtonLayout = ({
  disabled,
  options,
  name,
  value: currentValue,
  dataCy = 'field-radio-button',
  hideContainerBorder = false,
  overflowMode = 'auto',
  radioButtonBorderColor,
  disableRounding,
  onChange,
  onClear,
  onBlur,
}: Props): React.ReactElement => (
  <Container
    disabled={disabled}
    hideBorders={hideContainerBorder}
    overflowMode={overflowMode}
    disableRounding={disableRounding}
  >
    {options?.length > 0 &&
      options.map(({ label, value, description }, i) => (
        <RadioButton
          key={getKey(name, label, value)}
          data-cy={`${dataCy}-${i}`}
          borderColor={radioButtonBorderColor}
        >
          <Tooltip
            placement="top"
            trigger={['hover', 'focus']}
            tooltip={description}
            bgColor={globalTheme.colors.mineShaftLighter}
            enabled={!!description}
          >
            <input
              key={getKey(name, label, value)}
              type="radio"
              name={name}
              id={getKey(name, label, value)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onClick={() => value === currentValue && onClear?.()}
              checked={value === currentValue}
              disabled={disabled}
            />
            <label htmlFor={getKey(name, label, value)}>{label}</label>
          </Tooltip>
        </RadioButton>
      ))}
  </Container>
);

const FieldRadioButton = withFieldWrapper<Props>(props => <FieldRadioButtonLayout {...props} />);
const FieldRadioButtonFilters = withFilterWrapper<Props>(props => (
  <FieldRadioButtonLayout {...props} />
));

export { FieldRadioButton, FieldRadioButtonFilters, FieldRadioButtonLayout };
