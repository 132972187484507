import { useEffect } from 'react';

import { Status } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { clearUsersAction, fetchUsers, UsersListState } from 'store/usersListSlice';

export function useHedgeUsers(hedgeId: number): UsersListState {
  const dispatch = useAppDispatch();
  const users = useAppSelector(state => state.users);

  useEffect(() => {
    if (hedgeId) {
      dispatch(fetchUsers({ hedges: [hedgeId], status: Status.ACTIVE }));
    }
    return () => {
      dispatch(clearUsersAction());
    };
  }, [hedgeId]);

  return users;
}
