import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import useDeepCompareEffect from 'use-deep-compare-effect';

import filterIcon from 'assets/images/icons/filter.svg';
import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Badge } from 'components/shared/Badge';
import { ActionsButton } from 'components/shared/Buttons/ActionsButton';
import { DownloadLogsModal } from 'components/shared/DownloadLogsModal/DownloadLogsModal';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { DataList, HighlightSearch, ListActions, useGetRowId } from 'components/shared/List';
import { LoadableContent } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { ThresholdsModal } from 'components/shared/ThresholdsModal';
import { DownloadUserLocationsModal } from 'components/views/Companies/DownloadUserLocationsModal/DownloadUserLocationsModal';
import { DownloadUserShoppingCartLogsModal } from 'components/views/Companies/DownloadUserShoppingCartLogsModal/DownloadUserShoppingCartLogsModal';
import { formatDate } from 'helpers/dateTime/dateTime';
import { getRoleLang } from 'helpers/roles/roles';
import { FilterableModules, PERMISSIONS, UserRoles } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useMediaQuery, useUserActions } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useLanguage } from 'shared/hooks/useLanguage';
import { ItemAction, Status, ThresholdsType, UserListItem } from 'shared/types';
import { downloadFile } from 'store/downloadFileSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { clearUser, fetchUser } from 'store/userDetailsSlice';
import { fetchUsers } from 'store/usersListSlice';
import { MEDIA_QUERY, theme } from 'theme';
import { UsersFilters } from './UsersFilters';
import { useUserItemActions } from '../hooks/useUserItemActions';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  UsersMessages,
  useTypedIntl,
} from '../locale/messages';
import { UserDetails } from '../UserDetails/UserDetails';

const UsersListComponent = (): React.ReactElement => {
  const authorize = useAuthorization();
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const intl = useTypedIntl();
  const listComponent = useAppSelector(state => state.listComponent);
  const users = useAppSelector(state => state.users);
  const userDetails = useAppSelector(state => state.userDetails);
  const changeStatusSubmitting = useAppSelector(state => state.changeUserStatus.isPending);
  const userDeletedSubmitting = useAppSelector(state => state.deleteUser.isPending);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.USERS]);
  const { id } = useParams<{ id: string }>();
  const contentRef = useRef(null);
  const language = useLanguage();
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isLg = useMediaQuery(MEDIA_QUERY.LG);
  const searchWords = savedFilters.data.query ? [savedFilters.data.query] : [];

  const tableColumns: ColumnDef<UserListItem>[] = [
    {
      id: 'status',
      header: intl.formatMessage({ id: 'UsersList.TableHeader.Status' }),
      cell: ({ row }) => (
        <span>
          <Badge color={resolveStatusColor(row.original.status, row.original.isBlocked)}>
            {intl.formatMessage({
              id: `Global.Status.${
                row.original.isBlocked ? Status.BLOCKED : row.original.status
              }` as keyof UsersMessages,
            })}
          </Badge>
        </span>
      ),
    },
    {
      id: 'roles',
      header: intl.formatMessage({ id: 'UsersList.TableHeader.Roles' }),
      cell: ({ row }) =>
        row.original.roles.map(({ name }) => (
          <span key={name}>
            <Badge color={theme.colors.mineShaftLightest}>{getRoleLang(name, intl)}</Badge>
          </span>
        )),
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'UsersList.TableHeader.Name' }),
      cell: ({ row }) => (
        <HighlightSearch
          autoEscape
          searchWords={searchWords}
          textToHighlight={`${row.original.firstName} ${row.original.lastName}`}
        />
      ),
    },
    {
      id: 'companyName',
      header: intl.formatMessage({ id: 'UsersList.TableHeader.CompanyName' }),
      cell: ({ row }) => (
        <HighlightSearch
          autoEscape
          searchWords={searchWords}
          textToHighlight={row.original.company.name}
        />
      ),
    },
    {
      id: 'email',
      header: intl.formatMessage({ id: 'UsersList.TableHeader.Email' }),
      cell: ({ row }) => (
        <HighlightSearch
          autoEscape
          searchWords={searchWords}
          textToHighlight={row.original.email}
        />
      ),
    },
    {
      id: 'totalMargin',
      header: intl.formatMessage({ id: 'UsersList.TableHeader.TotalMargin' }),
      cell: ({ row }) =>
        row.original.company.canUseProfitMarginPerMetal
          ? FormattedMessage({
              id: 'UsersList.TotalProfitMarginPdPtRh',
              values: {
                pd: row.original.totalMarginPd,
                pt: row.original.totalMarginPt,
                rh: row.original.totalMarginRh,
              },
            })
          : `${row.original.totalMarginPt}%`,
    },
    {
      id: 'actions',
      header: isMd ? intl.formatMessage({ id: 'Global.Actions' }) : '',
      cell: ({ row }) => <ListActions actions={actions} item={row.original} />,
      meta: {
        widthPx: 80,
      },
    },
  ];

  const createUser = useCallback(() => navigate('/users/create'), [navigate]);
  const onFiltersToggle = useCallback(() => setFiltersOpen(!filtersOpen), [filtersOpen]);

  useDeepCompareEffect(() => {
    if (!changeStatusSubmitting && !userDeletedSubmitting)
      dispatch(
        fetchUsers({
          ...savedFilters.data,
          page: listComponent.pageIndex + 1,
          pageSize: listComponent.pageSize,
        }),
      );
  }, [
    listComponent.pageIndex,
    listComponent.pageSize,
    savedFilters,
    dispatch,
    changeStatusSubmitting,
    userDeletedSubmitting,
  ]);

  useEffect(() => {
    if (!changeStatusSubmitting) {
      dispatch(id ? fetchUser(+id) : clearUser());
    }
  }, [id, dispatch, changeStatusSubmitting]);

  const usersList = useMemo(
    () =>
      users.list.map(user => ({
        color: !isMd ? resolveStatusColor(user.status, user.isBlocked) : '',
        ...user,
      })),
    [isMd, users.list],
  );

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const onFiltersChanged = newFilters => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.USERS,
        values: newFilters,
      }),
    );
    setPageIndex(0);
  };
  const goToUser = item => {
    setFiltersOpen(false);
    navigate(`/users/${item.id}`);
  };
  const fetchUsersListWithFilters = async () =>
    dispatch(
      fetchUsers({
        ...savedFilters,
        page: listComponent.pageIndex + 1,
        pageSize: listComponent.pageSize,
      }),
    );
  const { isPending: areUserActionsPending } = useUserActions();
  const onUserUnblock = async () =>
    dispatch(
      fetchUsers({
        ...savedFilters,
        page: listComponent.pageIndex + 1,
        pageSize: listComponent.pageSize,
      }),
    );

  const loaderEnabled =
    (!users.list && !userDetails.isPending && !userDetails.user) || areUserActionsPending;

  const actions = useUserItemActions(onUserUnblock);

  const handleGenerateBtnClick = async () => {
    try {
      await dispatch(
        downloadFile(
          '/api/users/report',
          `${intl.formatMessage({ id: 'UsersList.ReportName' })} ${formatDate(
            new Date(),
            language === 'EN' ? 'MM-dd-yyyy' : 'dd-MM-yyyy',
          )}.xlsx`,
        ),
      );
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const buttonActions: ItemAction[] = [
    {
      id: 1,
      label: <FormattedMessage id="UsersList.Display.GenerateReport" />,
      onClick: () => {
        handleGenerateBtnClick();
      },
      dataCy: () => 'users-list-display-generate-report',
      visible: authorize([UserRoles.SUPER_ADMIN]),
    },
  ];

  const table = useReactTable({
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    data: usersList,
    getRowId: useGetRowId(),
    pageCount: Math.ceil(users.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        status: isMd,
        state: isLg,
        companyName: authorize(PERMISSIONS.USERS.LIST_COMPANY_NAME) && isLg,
        email: isMd,
        totalMargin: isLg,
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      columnSizing: {
        status: 100,
        roles: 100,
        name: 100,
        companyName: 100,
        email: 100,
        totalMargin: 100,
      },
    },
  });

  return (
    <>
      <PanelTemplate>
        <PanelHeader title={<FormattedMessage id="UsersList.Header.Users" />}>
          <AppButton type="button" onClick={createUser}>
            <FormattedMessage id="Global.Create" />
          </AppButton>
          <ActionsButton actions={buttonActions} />
        </PanelHeader>
        <PanelContent>
          <PanelContentMain disableScroll={loaderEnabled} ref={contentRef}>
            <SearchBar
              isSearchPending={users.isPending}
              onSearchChanged={onFiltersChanged}
              initialQuery={savedFilters.data.query}
              onFiltersToggle={onFiltersToggle}
              savedFilters={savedFilters}
            />
            <DataList
              table={table}
              isLoading={users.isPending || areUserActionsPending}
              onRowClicked={goToUser}
            />
          </PanelContentMain>
          <PanelContentSidebar
            header={intl.formatMessage({ id: 'Global.Filters' })}
            headerIcon={filterIcon}
            open={filtersOpen}
            onSidebarClosed={onFiltersToggle}
          >
            <UsersFilters onFiltersChanged={onFiltersChanged} onFiltersApplied={onFiltersToggle} />
          </PanelContentSidebar>
        </PanelContent>
        <DownloadUserLocationsModal />
        <DownloadUserShoppingCartLogsModal />
        <DownloadLogsModal />
        <LoadableContent loading={userDetails.isPending}>
          <UserDetails user={userDetails.user} onUserUnblock={fetchUsersListWithFilters} />
        </LoadableContent>
      </PanelTemplate>
      <ThresholdsModal modalEndpoint={ThresholdsType.USER} />
    </>
  );
};

export const UsersList = withAlphamartIntlProvider(UsersListComponent, messages);
