import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useFormik, yupToFormErrors } from 'formik';

import { StyledWizardForm } from 'components/shared/forms/Form/StyledForm';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { Controls } from 'components/shared/forms/Wizard/Controls/Controls';
import { FormWrapper } from 'components/shared/forms/Wizard/FormWrapper/FormWrapper';
import { Wizard } from 'components/shared/forms/Wizard/Wizard';
import { AccessFlag, MarginVisibility, PERMISSIONS, PRICE_SOURCES } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useGetCompanies, useGetCountries, useGetRoles } from 'shared/queries';
import { Status, UserFormData } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { MEDIA_QUERY } from 'theme';
import { UserInfoForm } from './UserFormInfo';
import { UserPricingForm } from './UserFormPricing';
import { UserRolesForm } from './UserFormRoles';
import { userFormSchema } from './userFormSchema';
import { useTypedIntl } from '../locale/messages';

interface Props {
  editMode?: boolean;
  onSubmit?: (user: UserFormData) => Promise<void>;
  allowRolesChange?: boolean;
  initialValues?: Partial<UserFormData>;
}

export const HedgeSelectOption = styled.div`
  display: flex;

  strong {
    margin-right: 5px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    flex-direction: column;
  }
`;

export const UserForm = ({
  initialValues,
  editMode,
  onSubmit,
  allowRolesChange = true,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();
  const authorization = useAuthorization();
  const { data: countries } = useGetCountries();
  const [activeSection, setActiveSection] = useState<string>('');
  const canFetchCompanies = authorization(PERMISSIONS.COMPANIES.LIST);

  const formikContext = useFormik<UserFormData>({
    initialValues: initialValues
      ? ({ ...initialValues } as UserFormData)
      : ({
          info: {
            mobilePhonePrefix: countries?.find(country => country.alphaCode2 === 'US')?.phonePrefix,
            mobilePhone: '',
            firstName: '',
            lastName: '',
            email: '',
            company: canFetchCompanies ? null : currentUser.company.id,
          },
          roles: {
            roles: [],
            privileges: { additionalDevice: 'false' },
            userAccessFlags: Object.fromEntries(Object.keys(AccessFlag).map(flag => [flag, false])),
          },
          prices: {
            userProfitMargin: [{ value: '0' }],
            marginVisibility: MarginVisibility.NO_VISIBILITY,
            currentProfitMarginPt: 0,
            currentProfitMarginPd: 0,
            currentProfitMarginRh: 0,
            ptTermsAdjustment: '',
            pdTermsAdjustment: '',
            rhTermsAdjustment: '',
            prices: {
              ptPriceSource: PRICE_SOURCES.MARKET,
              pdPriceSource: PRICE_SOURCES.MARKET,
              rhPriceSource: PRICE_SOURCES.MARKET,
            },
          },
        } as unknown as UserFormData),
    enableReinitialize: true,
    initialTouched: {
      userProfitMargin: true,
      ...(editMode
        ? {
            firstName: true,
            lastName: true,
            mobilePhone: true,
            mobilePhonePrefix: true,
            officePhone: true,
            officePhonePrefix: true,
            email: true,
            company: true,
            roles: true,
            marginVisibility: true,
            prices: { ptHedgeUsed: true, pdHedgeUsed: true, rhHedgeUsed: true },
          }
        : { officePhone: true }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    validate: values =>
      userFormSchema(intl, roles)
        .validate(values, {
          abortEarly: false,
          context: values,
        })
        .then(() => ({}))
        .catch(err => yupToFormErrors(err)),
    onSubmit: async newUser => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'UserForm.Modal.Message' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());
            await onSubmit?.(newUser);
          },
        }),
      );

      formikContext.setSubmitting(false);
    },
  });

  const { values, handleSubmit, touched, getFieldMeta, isValidating, isSubmitting, isValid } =
    formikContext;

  const { data: roles } = useGetRoles({ companyId: values.info.company });
  const { data: fetchedCompanies } = useGetCompanies(
    { status: Status.ACTIVE },
    { enabled: canFetchCompanies },
  );

  const companies = useMemo(
    () => (canFetchCompanies ? fetchedCompanies?.data : [currentUser.company]),
    [canFetchCompanies, fetchedCompanies?.data, currentUser.company],
  );

  useEffect(() => {
    if (!roles) return;
    const chosenRoles = roles.filter(role => values.roles?.roles?.includes(role.id));
    formikContext.setFieldValue('roles.chosenRoles', chosenRoles);
  }, [values?.roles?.roles, roles]);

  const getArrayErrors = name => {
    const indexesNotToValidate =
      getFieldMeta(name)
        .value?.map((val, i) => val.value === '' && i)
        .filter(Boolean) ?? [];

    const yupResult = ([] as ({ value: string } | undefined)[])
      .concat(getFieldMeta(name)?.error as unknown as { value: string })
      .filter((_, i) => !indexesNotToValidate.includes(i))
      .filter(Boolean)
      .shift()?.value;

    if (yupResult) return yupResult;

    if (
      values.prices?.userProfitMargin
        ?.map(pM => ((pM?.value || pM?.value === 0) && pM?.value !== '-' ? +pM.value : null))
        ?.filter((e, i, a) => a.indexOf(e) !== i).length
    ) {
      return intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.Unique' });
    }
  };

  const submitDisabled = !!(
    !isValid ||
    isValidating ||
    isSubmitting ||
    getArrayErrors('prices.userProfitMargin')
  );

  return (
    <FormContainer>
      <Wizard>
        <StyledWizardForm onSubmit={handleSubmit}>
          <FormWrapper pageIndex={1}>
            <UserInfoForm
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              context={formikContext}
              editMode={editMode}
              companies={companies!}
              roles={roles!}
            />
          </FormWrapper>
          <FormWrapper pageIndex={2}>
            <UserRolesForm
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              context={formikContext}
              editMode={editMode}
              allowRolesChange={allowRolesChange}
              roles={roles!}
            />
          </FormWrapper>
          <FormWrapper pageIndex={3}>
            <UserPricingForm
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              context={formikContext}
              editMode={editMode}
              roles={roles}
              companies={companies}
            />
          </FormWrapper>
          <Controls
            stepsErrors={[formikContext.errors.info, formikContext.errors.roles]}
            isValid={!submitDisabled}
            touched={touched}
            isValidating={isValidating}
            isSubmitting={isSubmitting}
            editMode={editMode}
            lastPageButton={intl.formatMessage({
              id: editMode ? 'UserForm.Submit.Edit' : 'UserForm.Submit.Add',
            })}
          />
        </StyledWizardForm>
      </Wizard>
    </FormContainer>
  );
};
