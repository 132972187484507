import { css } from '@emotion/css';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { Theme } from 'theme';
import { StyleType } from './AppButton';

interface Props {
  theme: Theme;
  styleType?: StyleType;
  className?: string | SerializedStyles;
  inline?: boolean;
}

export const buttonStyles = ({
  theme,
  styleType = 'default',
  className,
  inline,
}: Props): string => css`
  height: 48px;
  background: transparent;
  padding: 0 32px;
  border-radius: 20px;
  cursor: pointer;
  display: ${inline ? 'flex-inline' : 'flex'};
  align-items: center;
  justify-content: center;
  font-family: ${theme.fonts.montserrat};
  font-weight: bold;
  outline: none;
  word-break: normal;
  position: relative;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: #00000060;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    border: 1px solid ${theme.mainColor};
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 20px;
    opacity: 0.35;
    box-sizing: border-box;
  }

  ${styleType === 'default' &&
  `
    color: ${theme.mainColor};
  `}
  ${styleType === 'cancel' &&
  `

    color: ${theme.colors.fuzzyWuzzyBrown};

    &:before {
      border: 1px solid ${theme.colors.fuzzyWuzzyBrown};
      opacity: 1;
    }

  `}
  ${styleType === 'ok' &&
  `
    color: ${theme.colors.emerald};

    &:before {
      border: 1px solid ${theme.colors.emerald};
      opacity: 1;
    }
  `}
  ${styleType === 'neutral' &&
  `
    background-color: ${theme.colors.mineShaft};
    color: ${theme.colors.alphaGray};

    &:before {
      border: 1px solid ${theme.colors.mineShaft};
      opacity: 1;
    }

    &:hover:not(:disabled), &:focus:not(:disabled) {
      background-color: ${theme.colors.mineShaftLighter};

      &:before {
        border: 1px solid ${theme.colors.mineShaftLighter};
        opacity: 1;
      }
    }
  `}
  ${styleType === 'neutral-empty' &&
  `
      color: ${theme.colors.greySnow};
      background-color: transparent;

      &::before {
        border: 1px solid ${theme.colors.mineShaftLightest};
        opacity: 1;
      }

      &:hover:not(:disabled), &:focus:not(:disabled) {
        background-color: transparent;

        &:before {
          border: 1px solid ${theme.colors.emperor};
          opacity: 1;
        }
      }
  `}
  &:disabled {
    color: ${theme.colors.doveGray};
    cursor: not-allowed;

    &:before {
      border: 1px solid ${theme.colors.mineShaftLightest};
      opacity: 1;
    }
  }

  ${className}
`;

export const CounterIcon = styled.span<{ theme?: Theme; count: number }>`
  position: absolute;
  display: flex;
  top: -5px;
  right: -5px;
  background: ${({ theme }) => theme.mainColor};
  color: ${({ theme }) => theme.fontColor};
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: ${({ count }) => (count > 9 ? '12px' : '15px')};
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;
