import { NavigateOptions } from 'react-router/dist/lib/context';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { To } from '@remix-run/router';
import { Location } from 'history';

export interface AlphamartHistoryShape {
  prevPath?: string;
}

interface AlphamartNavigateOptions extends NavigateOptions {
  state?: AlphamartHistoryShape;
}

interface AlphamartNavigateFunction {
  (to: To, options?: AlphamartNavigateOptions): void;

  (delta: number): void;
}

export const useAlphamartNavigate = (): AlphamartNavigateFunction => useNavigate();

export const useAlphamartLocation = (): Location<AlphamartHistoryShape> => useLocation();
