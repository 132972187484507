import { AccessFlag } from 'shared/constants';
import { useAppSelector } from 'store/shared/hooks';

export function useDefaultRedirect() {
  const auth = useAppSelector(state => state.auth);
  const redirectPath = auth.user?.data?.accessFlags?.includes(AccessFlag.CONVERTER_VIEWER)
    ? '/converters'
    : '/dashboard';
  return redirectPath;
}
