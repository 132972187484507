import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';

import { FieldSelectOption, FilterSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { DEFAULT_FILTERS, FilterableModules } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { useGetCompanies } from 'shared/queries';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface FeedbackChartFiltersFormShape {
  company: number | null;
  thresholdInDays: number | null;
}

interface Props {
  onFiltersChanged: (values: FeedbackChartFiltersFormShape) => void;
  onFiltersApplied: () => void;
}

export function FeedbacksSummaryFilters({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement {
  const intl = useTypedIntl();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.FEEDBACK_CHART]);
  const formikContext = useFormik<FeedbackChartFiltersFormShape>({
    initialValues: DEFAULT_FILTERS[FilterableModules.FEEDBACK_CHART],
    onSubmit: () => {},
  });
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const companiesForFilters = useGetCompanies({});
  const companyOptions = useMemo<FieldSelectOption[]>(
    () =>
      companiesForFilters.data?.data.map(company => ({ value: company.id, label: company.name })) ??
      [],
    [companiesForFilters.data?.data],
  );

  useEffect(() => {
    !isMobile && onFiltersChanged(formikContext.values);
  }, [formikContext.values]);

  const handleFiltersApply = () => {
    onFiltersChanged(formikContext.values);
    onFiltersApplied();
  };

  const handleFiltersClear = () => {
    formikContext.setValues(DEFAULT_FILTERS[FilterableModules.FEEDBACK_CHART]);
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={handleFiltersClear}
      onFiltersApply={handleFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection label={intl.formatMessage({ id: 'FeedbacksSummary.Filters.Company' })}>
        <FilterSelect
          name="company"
          options={companyOptions}
          label={intl.formatMessage({ id: 'FeedbacksSummary.Filters.All' })}
          value={formikContext.values.company ?? ''}
          data-cy="filter-company"
        />
      </FiltersSection>
    </FiltersForm>
  );
}
