import React from 'react';
import styled from '@emotion/styled';

import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import { Label } from './Label/Label';
import { Container } from './Container';

const Content = styled.div`
  grid-area: input;
`;

interface Props {
  id?: string;
  error?: React.ReactNode;
  label?: React.ReactNode;
  labelSuffix?: string | null;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  labelHeight?: string;
}
const FieldWrapper = React.memo(
  ({
    className,
    label,
    labelSuffix,
    id,
    disabled,
    children,
    error,
    required,
    labelHeight,
  }: Props): React.ReactElement => (
    <Container className={className}>
      <Label
        id={id}
        disabled={disabled}
        required={required}
        labelSuffix={labelSuffix}
        height={labelHeight}
      >
        {label}
      </Label>
      <Content>{children}</Content>
      <ErrorMessage label={label} show={!!error && !disabled}>
        {error}
      </ErrorMessage>
    </Container>
  ),
);

function withFieldWrapper<T>(
  C: React.FunctionComponent<T>,
  defaultProps?: Partial<Props>,
): (props: T & Props) => React.ReactElement {
  return (props: T & Props) => (
    <FieldWrapper {...props} {...defaultProps}>
      <C {...props} />
    </FieldWrapper>
  );
}

export { FieldWrapper, withFieldWrapper };
