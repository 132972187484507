import { CompanyTheme } from 'shared/types';

// eslint-disable-next-line
export const parseTheme = (theme: any, logo: any, icon: any, watermark: any): CompanyTheme => ({
  logo: logo
    ? {
        externalId: logo.data,
        fileName: theme.logo[0].name,
        mimetype: theme.logo[0].file.type,
        ...(theme.logo[0].id ? { id: theme.logo[0].id } : {}),
      }
    : null,
  icon: icon
    ? {
        externalId: icon.data,
        fileName: theme.icon[0].name,
        mimetype: theme.icon[0].file.type,
        ...(theme.icon[0].id ? { id: theme.icon[0].id } : {}),
      }
    : null,
  watermark: watermark
    ? {
        externalId: watermark.data,
        fileName: theme.watermarkLogo[0].name,
        mimetype: theme.watermarkLogo[0].file.type,
        ...(theme.watermarkLogo[0].id ? { id: theme.watermarkLogo[0].id } : {}),
      }
    : null,
  mainColor: theme.mainColor,
  navigationBarColor: theme.navigationBarColor,
  watermarkOpacity: theme.watermarkOpacity,
});
