import React from 'react';
import { NavLink } from 'react-router-dom';

import { useTypedIntl } from 'locale/messages';
import { AccessFlag } from 'shared/constants';
import { useExtendedTheme } from 'shared/hooks';
import { shoppingCartMenuStyles } from './ShoppingCartMenu.styles';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';
import { Basket } from '../Basket/Basket';
import { ProtectedArea } from '../ProtectedArea/ProtectedArea';

interface Props {
  compact?: boolean;
  hide?: boolean;
  'data-guide'?: string;
}

export const ShoppingCartMenu = ({ compact, hide, 'data-guide': dataGuide }: Props) => {
  const intl = useTypedIntl();
  const handleShoppingCartMenuClick = () => {};
  const theme = useExtendedTheme();

  return !hide ? (
    <ProtectedArea permission={[AccessFlag.SHOPPING_CART_VIEWER]}>
      <AlphamartIntlProvider>
        <NavLink
          to="/converters/cart"
          onClick={handleShoppingCartMenuClick}
          className={shoppingCartMenuStyles(theme)}
          data-guide={dataGuide}
        >
          <Basket />
          {!compact && <span>{intl.formatMessage({ id: 'Global.ShoppingCart' })}</span>}
        </NavLink>
      </AlphamartIntlProvider>
    </ProtectedArea>
  ) : null;
};
