import * as Yup from 'yup';

import { testEmail } from 'helpers/validation/testEmail';
import { TypedIntlShape } from './locale/messages';

export const getLoginSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    password: Yup.string().required(intl.formatMessage({ id: 'Login.Error.Password.Required' })),
    email: Yup.string()
      .test({
        name: 'isEmailValid',
        message: intl.formatMessage({ id: 'Login.Error.Email.Invalid' }),
        test: testEmail,
      })
      .required(intl.formatMessage({ id: 'Login.Error.Email.Required' })),
  });

export const getChallengeSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    challenge: Yup.string()
      .required(intl.formatMessage({ id: 'Login.Error.Challenge.Required' }))
      .matches(/^\d{4}$/, { message: intl.formatMessage({ id: 'Login.Error.Challenge.Format' }) }),
  });
