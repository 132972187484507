import React, { FormEvent } from 'react';
import { SerializedStyles } from '@emotion/react';

import { useExtendedTheme } from 'shared/hooks';
import { buttonStyles, CounterIcon } from './AppButton.styles';

export type StyleType = 'default' | 'cancel' | 'ok' | 'neutral' | 'neutral-empty';
export interface Props {
  children: React.ReactNode;
  className?: string | SerializedStyles;
  'data-cy'?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | ((e: FormEvent<HTMLFormElement>) => void);
  styleType?: StyleType;
  type?: 'button' | 'submit' | 'reset';
  inline?: boolean;
  count?: number;
}

const AppButton = React.forwardRef(
  (
    {
      type = 'button',
      styleType = 'default',
      children,
      onClick,
      'data-cy': dataCy,
      className,
      disabled,
      inline,
      count,
    }: Props,
    ref?: React.ForwardedRef<HTMLButtonElement>,
  ): React.ReactElement => {
    const theme = useExtendedTheme();

    return (
      <button
        // https://github.com/yannickcr/eslint-plugin-react/issues/1555
        // eslint-disable-next-line react/button-has-type
        type={type}
        data-cy={dataCy}
        className={buttonStyles({ theme, styleType, className, inline })}
        disabled={disabled}
        onClick={onClick as React.MouseEventHandler}
        ref={ref}
      >
        {children}
        {count ? (
          <CounterIcon count={count} data-cy="app-button-counter">
            {count > 9 ? '9+' : count}
          </CounterIcon>
        ) : null}
      </button>
    );
  },
);

export default AppButton;
