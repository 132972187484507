import React, { useEffect, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

/**
 * A useEffect hook does that not run on mount, but only on subsequent updates.
 *
 * @param effect
 * @param deps
 *
 * @see https://react-hooks-library.vercel.app/core/useEffectAfterMount
 */
export function useEffectAfterMount(
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined,
  hook?: (effect: React.EffectCallback, deps?: React.DependencyList | undefined) => void,
) {
  const useEffectHook = hook ?? useEffect;
  const isMounted = useRef(false);

  useEffectHook(() => {
    if (isMounted.current) {
      return effect();
    }

    isMounted.current = true;
  }, deps);
}

export const useDeepCompareEffectAfterMount = (
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined,
) => useEffectAfterMount(effect, deps, useDeepCompareEffect);
