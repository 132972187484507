import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { geoLocationErrorCodes } from 'shared/constants';
import { Coords } from 'shared/types';

type Error = { code?: geoLocationErrorCodes; message: string };

interface PositionShape {
  position?: Coords;
  accuracy?: number;
  error?: Error;
}

const modalSlice = createSlice<
  PositionShape,
  {
    setPosition: CaseReducer<PositionShape, { type: string; payload: Coords }>;
    setError: CaseReducer<PositionShape, { type: string; payload: Error | undefined }>;
  }
>({
  name: 'position',
  initialState: { position: undefined, error: undefined },
  reducers: {
    setPosition: (state, { payload }) => ({ position: payload, error: undefined }),
    setError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const { setPosition, setError } = modalSlice.actions;

export default modalSlice.reducer;
