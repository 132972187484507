import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInputRaw } from 'components/shared/Fields/FieldInput/FieldInput';
import { ErrorMessage } from 'components/shared/Fields/FieldWrapper/ErrorMessage/ErrorMessage';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { LoadableContent } from 'components/shared/Loader';
import { testEmail } from 'helpers/validation/testEmail';
import { forgotPasswordAction } from 'store/forgotPasswordSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, TypedFormattedMessage, useTypedIntl } from './locale/messages';
import {
  authenticationFieldTextContainer,
  AuthenticationFormButtonsStyled,
  authenticationFormContainerStyles,
  authenticationFormStyles,
  authenticationHeaderStyle,
  authenticationLogoContainerStyles,
  authenticationPhotoStyles,
  authenticationStyles,
} from './Authentication.styles';

interface ForgotPasswordFormShape {
  email: string;
}

function ForgotPasswordPage(): React.ReactElement {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
    isSubmitting,
    setSubmitting,
  } = useFormik<ForgotPasswordFormShape>({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .test({
          name: 'isEmailValid',
          message: intl.formatMessage({ id: 'ForgotPassword.Errors.Email.Invalid' }),
          test: testEmail,
        })
        .required(intl.formatMessage({ id: 'ForgotPassword.Errors.Email.Required' })),
    }),
    onSubmit: async formValues => {
      try {
        await dispatch(forgotPasswordAction(formValues.email));
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'ForgotPassword.Success' })));
      } catch {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
      setSubmitting(false);
    },
  });

  return (
    <div className={authenticationStyles()}>
      <LoadableContent loading={isSubmitting}>
        <section className={authenticationFormContainerStyles()}>
          <h1 className={authenticationLogoContainerStyles()}>Alphamart</h1>
          <form className={authenticationFormStyles()} onSubmit={handleSubmit}>
            <h2 className={authenticationHeaderStyle()}>
              <TypedFormattedMessage id="ForgotPassword.Header" />
            </h2>
            <section className={authenticationFieldTextContainer()}>
              <ErrorMessage standalone show={!!(errors.email && touched.email)}>
                {errors.email}
              </ErrorMessage>
              <FieldInputRaw
                name="email"
                placeholder={intl.formatMessage({ id: 'ForgotPassword.Label.Email' })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                data-cy="set-password-input-email"
                maxLength={100}
              />
            </section>
            <AuthenticationFormButtonsStyled>
              <Link to="/login">
                <TypedFormattedMessage id="ForgotPassword.Button.Login" />
              </Link>
              <AppButton
                disabled={!(isValid && dirty) || isSubmitting}
                className={css`
                  margin: 0 0 0 auto;
                `}
                type="submit"
                data-cy="set-password-button-login"
              >
                <TypedFormattedMessage id="ForgotPassword.Button.Submit" />
              </AppButton>
            </AuthenticationFormButtonsStyled>
          </form>
        </section>
        <div className={authenticationPhotoStyles()} />
      </LoadableContent>
    </div>
  );
}

export default withAlphamartIntlProvider(ForgotPasswordPage, messages);
