import { difference } from 'lodash';

import { checkNumber } from 'shared/checkers';
import { PRICE_SOURCES, UserRoles } from 'shared/constants';
import { ManageUserAccesses, UserFormData } from 'shared/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseUser = (
  user: UserFormData,
  editMode,
  { allowedToChangeTermsAdjustment }: ManageUserAccesses,
) => {
  const chosenUserAccessFlags = Object.entries(user.roles?.userAccessFlags ?? [])
    .filter(([, value]) => value)
    .map(([key]) => key);
  const userAccessFlags = difference(
    chosenUserAccessFlags,
    user.roles.chosenRoles.flatMap(({ accessFlags }) => accessFlags),
  );
  const parsedUserIsBuyer = user.roles.chosenRoles.some(role => role.name === UserRoles.BUYER);

  return {
    firstName: user.info.firstName.trim(),
    lastName: user.info.lastName.trim(),
    mobilePhone: user.info.mobilePhone,
    mobilePhonePrefix: user.info.mobilePhonePrefix,
    email: user.info.email.toLowerCase().trim(),
    company: checkNumber(user.info.company),
    roles: user.roles.roles,
    marginVisibility: user.prices.marginVisibility,
    privileges: Object.fromEntries(
      Object.entries(user.roles?.privileges).map(([key, value]) => [
        key,
        [true, 'true'].includes(value),
      ]),
    ),
    userAccessFlags,
    ...((user.info.officePhone || editMode) && { officePhone: user.info.officePhone || null }),
    ...((user.info.officePhonePrefix || editMode) && {
      officePhonePrefix: user.info.officePhonePrefix || null,
    }),
    ...{
      profitMargins: user.prices.userProfitMargin.map(pM => ({ ...pM, value: +pM.value })),
    },
    ...(editMode && {
      assignedHedges: user.prices.assignedHedges,
    }),
    ...(user.prices.prices &&
      editMode && {
        prices: {
          ...user.prices.prices,
          ptHedgeUsed:
            user.prices.prices.ptPriceSource === PRICE_SOURCES.HEDGE
              ? +user.prices.prices.ptHedgeUsed!
              : null,
          pdHedgeUsed:
            user.prices.prices.pdPriceSource === PRICE_SOURCES.HEDGE
              ? +user.prices.prices.pdHedgeUsed!
              : null,
          rhHedgeUsed:
            user.prices.prices.rhPriceSource === PRICE_SOURCES.HEDGE
              ? +user.prices.prices.rhHedgeUsed!
              : null,
        },
      }),
    ...(allowedToChangeTermsAdjustment &&
      parsedUserIsBuyer && {
        ptTermsAdjustment: user.prices.ptTermsAdjustment
          ? +user.prices.ptTermsAdjustment
          : undefined,
        pdTermsAdjustment: user.prices.pdTermsAdjustment
          ? +user.prices.pdTermsAdjustment
          : undefined,
        rhTermsAdjustment: user.prices.rhTermsAdjustment
          ? +user.prices.rhTermsAdjustment
          : undefined,
      }),
    currentProfitMarginPt: user.prices.currentProfitMarginPt,
    currentProfitMarginPd: user.prices.canUseMarginSliderPerMetal
      ? user.prices.currentProfitMarginPd
      : user.prices.currentProfitMarginPt,
    currentProfitMarginRh: user.prices.canUseMarginSliderPerMetal
      ? user.prices.currentProfitMarginRh
      : user.prices.currentProfitMarginPt,
  };
};
