import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { ScatterChart } from 'components/shared/ScatterChart/ScatterChart';
import { useLanguage, useMediaQuery } from 'shared/hooks';
import { Statistics } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { ChartTooltip, generateLabel } from './ChartTooltip';
import { CustomScatterPoint } from './CustomScatterPoint';
import { getXAxisTicks, getYAxisTicks, parseData } from './StatisticsChart.helpers';

const ChartContainer = styled.div`
  flex: 1 1 0;
  position: relative;
`;
const ChartInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

interface Props {
  data?: Statistics;
  isPending?: boolean;
}

export const StatisticsChart = ({ data, isPending }: Props): React.ReactElement | null => {
  const savedFilters = useAppSelector(state => state.filters.statistics);
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);
  const language = useLanguage();
  const parsedData = useMemo(() => parseData(data), [data]);

  if (!data) return null;

  const xAxisTicks = getXAxisTicks(data?.series?.range, isMobile, savedFilters.data.date);
  const yAxisTicks = getYAxisTicks(data?.max, 25);

  const chartValuesFormatter = (value: string | number, name: string): [string, string] => [
    name,
    String(value),
  ];
  const handleGenerateLabel = (val: string | number) =>
    generateLabel(val, savedFilters.data.date, language);

  return (
    <ChartContainer>
      <ChartInner>
        <ScatterChart
          data={parsedData}
          xAxisTicks={xAxisTicks}
          yAxisTicks={yAxisTicks}
          generateXLabel={handleGenerateLabel}
          valuesFormatter={chartValuesFormatter}
          customTooltip={ChartTooltip}
          gridProps={{
            horizontal: true,
            vertical: true,
            strokeDasharray: '3 3',
            stroke: theme.colors.charcoal,
          }}
          CustomScatterPoint={CustomScatterPoint}
          isPending={isPending}
        />
      </ChartInner>
    </ChartContainer>
  );
};
