import { css } from '@emotion/css';

const loggedOutPageHeaderStyle = (): string => css`
  margin: 0 0 32px 0;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
`;

const loggedOutPageFormStyles = (): string => css`
  width: 310px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { loggedOutPageFormStyles, loggedOutPageHeaderStyle };
