import React from 'react';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

export const JSXToStaticHTML = (children: React.ReactNode): string => {
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(children);
  });
  return div.innerHTML;
};
