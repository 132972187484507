import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { UserListItem } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';
import { FetchUsersParams } from 'store/usersListSlice';

export enum UsersKeys {
  GetUsers = 'GetUsers',
}

export const useGetUsers = (
  params: FetchUsersParams,
  options: UseQueryOptions<UserListItem[]> = {},
): UseQueryResult<UserListItem[]> =>
  useQuery<UserListItem[]>(
    [UsersKeys.GetUsers, params],
    async () => {
      const response = await getHttpClient().get<{ data: UserListItem[] }>(
        `${apiHostname}/api/users`,
        { params },
      );
      return response.data.data;
    },
    options,
  );
