import styled from '@emotion/styled';

import { unsetOrPxVal } from 'shared/helpers';
import { layers, MEDIA_QUERY, Theme } from 'theme';
import { ArrowPosition, TooltipPosition } from './Guide.helpers';

export const GuideOverlay = styled.div`
  z-index: ${layers.guide};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const GuideOverlayBackground = styled.div<{
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  theme?: Theme;
}>`
  clip-path: polygon(
    0% 0%,
    0% 100%,
    ${({ left }) => left}px 100%,
    ${({ left }) => left}px ${({ top }) => top}px,
    ${({ right }) => right}px ${({ top }) => top}px,
    ${({ right }) => right}px ${({ bottom }) => bottom}px,
    ${({ left }) => left}px ${({ bottom }) => bottom}px,
    ${({ left }) => left}px 100%,
    100% 100%,
    100% 0%
  );
  position: absolute;
  background: ${({ theme }) => theme.colors.mineShaftDarker};
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: -1;
`;
const arrowBottom = (arrowOffset?: number) => `
top: calc(100% - 5px);
left: ${arrowOffset}px;
transform: rotate(45deg);
`;
const arrowTop = (arrowOffset?: number) => `
top: -6px;
left: ${arrowOffset}px;
transform: rotate(225deg);
`;
const arrowLeft = (arrowOffset?: number) => `
top: ${arrowOffset}px;
left: -6px;
transform: rotate(135deg);
`;
const arrowRight = (arrowOffset?: number) => `
top: ${arrowOffset}px;
left: calc(100% - 5px);
transform: rotate(315deg);
`;
const getArrowStyles = (arrowPosition?: ArrowPosition, arrowOffset?: number): string => {
  switch (arrowPosition) {
    case 'bottom':
      return arrowBottom(arrowOffset);
    case 'top':
      return arrowTop(arrowOffset);
    case 'left':
      return arrowLeft(arrowOffset);
    case 'right':
      return arrowRight(arrowOffset);
    default:
      return 'bottom';
  }
};
export const StepContainer = styled.div<{
  position: TooltipPosition;
  theme?: Theme;
}>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  max-width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  right: ${({ position }) => unsetOrPxVal(position.rightOffset)};
  left: ${({ position }) => unsetOrPxVal(position.leftOffset)};
  top: ${({ position }) => unsetOrPxVal(position.topOffset)};
  &:after {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: inherit;
    border-bottom: inherit;
    border-right: inherit;
    ${({ position }) => getArrowStyles(position.arrowPosition, position.arrowOffset)}
  }
  @media ${MEDIA_QUERY.MAX_SM} {
    bottom: ${({ position }) => unsetOrPxVal(position.bottomOffset)};
    width: 90%;
    left: 5%;
    top: ${({ position }) => unsetOrPxVal(position.topOffset)};
  }
`;
export const StepHeader = styled.div`
  font-size: 14px;
`;
export const StepContent = styled.div`
  font-size: 12px;
`;
export const StepFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
`;
export const StepCounter = styled.div`
  font-size: 12px;
`;
export const StepButtons = styled.div`
  display: flex;
  flex-direction: row;
  button {
    height: 32px;
    padding: 12px;
    margin-left: 5px;
  }
`;
