import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { DrawingModes, DrawingTools } from 'shared/constants';
import { ShapeParam } from './locationsSlice';

export type Polygon = google.maps.Polygon & {
  path: { lat: number; lng: number }[];
  id: number;
};
export type Circle = google.maps.CircleLiteral;
export type Rectangle = google.maps.Rectangle & google.maps.RectangleOptions;

export interface OffZones {
  circle?: Circle[];
  polygon?: Polygon[];
  rectangle?: Rectangle[];
}

export interface PolygonShape {
  type: DrawingTools;
  overlay: Polygon;
}

export interface CircleShape {
  type: DrawingTools;
  overlay: Circle;
}

export interface RectangleShape {
  type: DrawingTools;
  overlay: Rectangle;
}

export interface MapShape {
  type: DrawingTools;
  overlay: google.maps.OverlayView;
}

export type Shape = PolygonShape | CircleShape | RectangleShape | MapShape;

export interface MapDrawingSlice {
  area: number;
  map: google.maps.Map | null;
  drawingTool: DrawingTools | null;
  drawingMode: DrawingModes | null;
  shouldErase: boolean;
  eraseOnClick: boolean;
  currentShape: Shape | null;
  lastShape: Shape | null;
  offZones: OffZones;
  savedPath?: ShapeParam | null;
}

type ListModeReducer = {
  setDrawingMode: CaseReducer<MapDrawingSlice, { type: string; payload: DrawingModes }>;
  setDrawingTool: CaseReducer<MapDrawingSlice, { type: string; payload: DrawingTools }>;
  setShouldErase: CaseReducer<MapDrawingSlice, { type: string; payload: boolean }>;
  setLastShape: CaseReducer<MapDrawingSlice, { type: string; payload: Shape }>;
  setMap: CaseReducer<MapDrawingSlice, { type: string; payload: google.maps.Map | null }>;
  setMapDrawing: CaseReducer<MapDrawingSlice, { type: string; payload: Partial<MapDrawingSlice> }>;
  setOffZones: CaseReducer<MapDrawingSlice, { type: string; payload: OffZones }>;
};
const mapDrawingSlice = createSlice<MapDrawingSlice, ListModeReducer>({
  name: 'mapDrawing',
  initialState: {
    drawingMode: null,
    drawingTool: null,
    shouldErase: false,
    eraseOnClick: false,
    currentShape: null,
    lastShape: null,
    map: null,
    savedPath: null,
    area: 0,
    offZones: {
      circle: [],
      polygon: [],
      rectangle: [],
    },
  },
  reducers: {
    setDrawingMode: (state, { payload }) => ({
      ...state,
      drawingMode: payload,
    }),
    setDrawingTool: (state, { payload }) => ({
      ...state,
      drawingTool: payload,
    }),
    setShouldErase: (state, { payload }) => ({
      ...state,
      shouldErase: payload,
    }),
    setLastShape: (state, { payload }) => ({
      ...state,
      lastShape: payload,
    }),
    setMap: (state, { payload }: { payload: google.maps.Map | null }) => ({
      ...state,
      map: payload,
    }),
    setMapDrawing: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setOffZones: (state, { payload }) => ({
      ...state,
      offZones: payload,
    }),
  },
});

export const {
  setDrawingMode,
  setDrawingTool,
  setShouldErase,
  setLastShape,
  setMap,
  setMapDrawing,
  setOffZones,
} = mapDrawingSlice.actions;

export default mapDrawingSlice.reducer;
