import React from 'react';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import subMonths from 'date-fns/subMonths';
import { utcToZonedTime } from 'date-fns-tz';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Badge } from 'components/shared/Badge';
import { DataList, useGetRowId } from 'components/shared/List';
import { MapFiltersShape } from 'components/views/Geolocation/UserLocationsView/MapFilters';
import { FilterableModules, SHARED } from 'shared/constants';
import { resolveStatusColor } from 'shared/helpers';
import { useAlphamartNavigate, useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetFrequentFlyers } from 'shared/queries';
import { FrequentFlyerListItem, Status } from 'shared/types';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../../locale/messages';

const FrequentFlyersListComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const listComponent = useAppSelector(state => state.listComponent);
  const intl = useTypedIntl();
  const isLg = useMediaQuery(MEDIA_QUERY.LG);

  const savedFilters = useAppSelector(state => state.filters[FilterableModules.FREQUENT_FLYERS]);

  const frequentFlyers = useGetFrequentFlyers(
    {
      page: listComponent.pageIndex + 1,
      pageSize: listComponent.pageSize,
      distanceInMiles: savedFilters.data.distanceInMiles,
    },
    {
      initialData: {
        data: [],
        count: 0,
      },
    },
  );

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  useEffectAfterMount(() => {
    setPageIndex(0);
  }, [savedFilters.data]);

  const goToUserGeolocation = async (row: FrequentFlyerListItem) => {
    const values: MapFiltersShape = {
      timeSpan: {
        from: subMonths(new Date(), 3),
        to: utcToZonedTime(new Date(), 'UTC').setHours(23, 59, 59, 999),
      },
      company: (row as FrequentFlyerListItem).user.company.id,
      users: [(row as FrequentFlyerListItem).user.id],
      showInactive: true,
    };
    dispatch(saveFilters({ filterKey: FilterableModules.GEOLOCATION, values }));
    navigate(`/geolocation`);
  };

  const columns: ColumnDef<FrequentFlyerListItem>[] = [
    {
      id: 'distance',
      header: intl.formatMessage({ id: 'FrequentFlyersList.TableHeader.Distance' }),
      cell: ({ row: { original: item } }) => (
        <span>
          <Badge>
            <FormattedMessage
              id="FrequentFlyersList.FormattedDistance"
              values={{ value: item.distance }}
            />
          </Badge>
        </span>
      ),
    },
    {
      id: 'status',
      header: intl.formatMessage({ id: 'FrequentFlyersList.TableHeader.Status' }),
      cell: ({ row: { original: item } }) => (
        <span>
          <Badge color={resolveStatusColor(item.user.status, item.user.isBlocked)}>
            <FormattedMessage
              id={`Global.Status.${
                item.user.status === Status.ACTIVE && item.user.isBlocked
                  ? Status.BLOCKED
                  : item.user.status
              }`}
            />
          </Badge>
        </span>
      ),
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'FrequentFlyersList.TableHeader.Name' }),
      cell: ({ row: { original: item } }) => `${item.user.firstName} ${item.user.lastName}`,
    },
    {
      id: 'company',
      header: intl.formatMessage({ id: 'FrequentFlyersList.TableHeader.CompanyName' }),
      cell: ({ row: { original: item } }) => item.user.company.name,
    },
    {
      id: 'parentCompany',
      header: intl.formatMessage({ id: 'FrequentFlyersList.TableHeader.ParentCompanyName' }),
      cell: ({ row: { original: item } }) =>
        item.user.company.parentCompany?.name || SHARED.LONG_DASH,
    },
  ];

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    data: frequentFlyers.data!.data,
    getRowId: useGetRowId(),
    columns,
    manualPagination: true,
    enableRowSelection: false,
    pageCount: Math.ceil(frequentFlyers.data!.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    state: {
      columnVisibility: {
        status: isLg,
        company: isLg,
        parentCompany: isLg,
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      columnSizing: {
        distance: 60,
        status: 60,
      },
    },
  });

  return (
    <DataList
      table={table}
      isLoading={frequentFlyers.isFetching}
      onRowClicked={goToUserGeolocation}
    />
  );
};
export const FrequentFlyersList = withAlphamartIntlProvider(FrequentFlyersListComponent, messages);
