import { useEffect } from 'react';

import { FilterableModules, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { Status } from 'shared/types';
import { saveInitialFilterForCurrentUser } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { FEEDBACK_TYPES } from '../constants/feedbackTypes';

export const useInitUserFilters = (): void => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);
  const authorize = useAuthorization();

  useEffect(() => {
    if (!auth?.user?.data?.id) return;

    dispatch(
      saveInitialFilterForCurrentUser({
        filterKey: FilterableModules.USERS,
        values: {
          status: Status.ACTIVE,
          company: authorize(PERMISSIONS.USERS.FILTER_BY_COMPANY)
            ? auth.user.data.company.id
            : null,
        },
      }),
    );

    dispatch(
      saveInitialFilterForCurrentUser({
        filterKey: FilterableModules.HEDGES,
        values: {
          status: Status.ACTIVE,
          relatedCompanies: [],
          parentCompany: authorize(PERMISSIONS.HEDGES.FILTER_BY_PARENT_COMPANY)
            ? auth.user?.data?.company?.id
            : null,
        },
      }),
    );

    dispatch(
      saveInitialFilterForCurrentUser({
        filterKey: FilterableModules.COMPANIES,
        values: {
          status: Status.ACTIVE,
        },
      }),
    );

    dispatch(
      saveInitialFilterForCurrentUser({
        filterKey: FilterableModules.FEEDBACKS,
        values: {
          type: FEEDBACK_TYPES.WITH_COMMENT,
        },
      }),
    );
  }, [auth?.user?.data]);
};
