import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Badge } from 'components/shared/Badge';
import { TextButton } from 'components/shared/Buttons';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { TermsAndConditionsModal } from 'components/shared/TermsAndConditionsModal';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatDeviceData } from 'helpers/devices/formatDeviceData';
import { getRoleLang } from 'helpers/roles/roles';
import { useCurrentUser, useExtendedTheme } from 'shared/hooks';
import { useGetDevices } from 'shared/queries';
import { Status } from 'shared/types';
import { fetchUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { closeProfile } from 'store/shared/userProfileSlice';
import { layers, MEDIA_QUERY, Theme } from 'theme';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from './locale/messages';
import { ChangeLanguageActions } from './ChangeLanguageActions';
import { UserProfileChangeCurrency } from './UserProfileChangeCurrency';
import { UserProfileNotifications } from './UserProfileNotifications';
import { UserProfilePriceSources } from './UserProfilePriceSources';
import { UserProfileProfitMargin } from './UserProfileProfitMargin';
import { UserProfileTotalMargins } from './UserProfileTotalMargins';

const StyledLink = styled.a<{ theme: Theme }>`
  color: ${({ theme }) => theme.colors.sliver};
`;

const Footer = styled.div`
  width: 100%;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserProfileModalStyle = {
  overlay: {
    backgroundColor: 'transparent',
    zIndex: layers.userProfile,
    [MEDIA_QUERY.MAX_MD]: {
      top: 64,
    },
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

const DeviceField = styled.dl<{ theme: Theme }>`
  margin: 0;

  dt {
    font-family: ${({ theme }) => theme.fonts.lato};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.doveGray};
    margin-bottom: 8px;
  }

  dd {
    margin-left: 0;
    margin-bottom: 8px;
  }
`;

function UserProfile(): React.ReactElement | null {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const theme = useExtendedTheme();
  const [isModalOpen, setModalOpen] = useState(false);
  const { isVisible } = useAppSelector(state => state.userProfile);
  const currentUser = useCurrentUser();
  const { data: devices } = useGetDevices(
    { userId: currentUser?.id, status: [Status.ACTIVE] },
    { initialData: [] },
  );
  const { isPending: notificationsSettingsPending } = useAppSelector(
    state => state.changeUserNotifications,
  );

  const [requestPending, setRequestPending] = useState(false);

  const rolesList = currentUser?.roles?.map(role => (
    <Badge key={role.id} color={theme.colors.mineShaftLightest}>
      {getRoleLang(role.name, intl)}
    </Badge>
  ));

  useEffect(() => {
    if (isVisible) dispatch(fetchUser(true));
  }, [isVisible]);

  const close = useCallback(() => {
    dispatch(closeProfile());
    return true;
  }, [dispatch]);
  const toggleModal = () => setModalOpen(!isModalOpen);

  if (!currentUser) return null;

  return (
    <AlphamartIntlProvider messages={messages}>
      <ReactModal
        isOpen={isVisible}
        style={UserProfileModalStyle}
        parentSelector={() => document.querySelector('#root')!}
      >
        <LoadableContent
          mode={LoadableContent.MODE.FULL}
          loading={requestPending || notificationsSettingsPending}
          drawContent
        >
          <Details title={<FormattedMessage id="UserProfile.Title" />} open onClose={close}>
            <DetailsSection
              title={<FormattedMessage id="UserProfile.Details" />}
              template={['userName email mobilePhone', 'company role officePhone']}
              fields={[
                {
                  name: 'userName',
                  label: <FormattedMessage id="UserProfile.UserName" />,
                  value: `${currentUser.firstName} ${currentUser.lastName}`,
                },
                {
                  name: 'email',
                  label: <FormattedMessage id="UserProfile.Email" />,
                  value: (
                    <StyledLink href={`mailto:${currentUser.email}`} theme={theme}>
                      {currentUser.email}
                    </StyledLink>
                  ),
                },
                {
                  name: 'mobilePhone',
                  label: <FormattedMessage id="UserProfile.MobilePhone" />,
                  value: currentUser.mobilePhone,
                },
                {
                  name: 'company',
                  label: <FormattedMessage id="UserProfile.CompanyName" />,
                  value: currentUser.company?.name,
                },
                {
                  name: 'role',
                  label: <FormattedMessage id="UserProfile.Role" />,
                  value: rolesList,
                },
                {
                  name: 'officePhone',
                  label: <FormattedMessage id="UserProfile.OfficePhone" />,
                  value: currentUser.officePhone,
                },
              ]}
            />
            <UserProfilePriceSources />
            <DetailsSection
              title={<FormattedMessage id="UserProfile.AuthorizedDevices" />}
              fields={devices?.map(({ name, os, browserFamily, createdAt }, index) => ({
                name: `device${index + 1}`,
                label: null,
                value: (
                  <DeviceField theme={theme}>
                    <dt>
                      <FormattedMessage id="UserProfile.DeviceName" />
                    </dt>
                    <dd>{formatDeviceData(name, os, browserFamily)} </dd>
                    <dt>
                      <FormattedMessage id="UserProfile.DeviceAddDate" />
                    </dt>
                    <dd>{formatDate(createdAt)}</dd>
                  </DeviceField>
                ),
              }))}
            />

            <UserProfileTotalMargins user={currentUser} />
            <UserProfileProfitMargin user={currentUser} setRequestPending={setRequestPending} />
            <UserProfileNotifications user={currentUser} />
            <ChangeLanguageActions />
            <UserProfileChangeCurrency />
            <DetailsFooter
              fields={[
                {
                  name: 'termsAndConditions',
                  label: '',
                  value: (
                    <Footer>
                      <TextButton onClick={toggleModal}>
                        <FormattedMessage id="UserProfile.TermsAndConditions" />
                      </TextButton>
                    </Footer>
                  ),
                },
              ]}
            />
          </Details>
        </LoadableContent>
      </ReactModal>
      <TermsAndConditionsModal
        showModal={isModalOpen}
        toggleModal={toggleModal}
        showPrompt={false}
      />
    </AlphamartIntlProvider>
  );
}

export { UserProfile };
