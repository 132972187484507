import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { parseConvertersGroup } from 'shared/parsers/parseConvertersGroup';
import { AlphamartHttpError, ConvertersGroup, isAlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface UpdateConvertersGroupState extends GenericStoreSlice {
  convertersGroup: ConvertersGroup | undefined;
}

export type UpdateConvertersGroupParam = Pick<ConvertersGroup, 'converters' | 'files'>;

const updateConvertersGroupSlice = createSlice<
  UpdateConvertersGroupState,
  GenericStoreReducer<UpdateConvertersGroupState>
>({
  name: 'updateConvertersGroup',
  initialState: {
    isPending: false,
    convertersGroup: undefined,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers<UpdateConvertersGroupState>(payload => ({
      convertersGroup: payload.convertersGroup,
    })),
  },
});

export const {
  pending: updateConvertersGroupAction,
  success: updateConvertersGroupSuccessAction,
  failure: updateConvertersGroupFailureAction,
} = updateConvertersGroupSlice.actions;

export const updateConvertersGroup =
  (values: UpdateConvertersGroupParam, id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(updateConvertersGroupAction());
      const updatedValues = parseConvertersGroup(values);
      const convertersGroup = await httpClient.put(
        `${apiHostname}/api/converters/groups/${id}`,
        updatedValues,
      );

      dispatch(updateConvertersGroupSuccessAction(convertersGroup.data));

      return convertersGroup.data as ConvertersGroup;
    } catch (error) {
      isAlphamartHttpError(error) &&
        dispatch(updateConvertersGroupFailureAction(error.response?.data.message));
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };

export default updateConvertersGroupSlice.reducer;
