import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useIsFetching } from '@tanstack/react-query';
import useDeepCompareEffect from 'use-deep-compare-effect';

import filterIcon from 'assets/images/icons/filter.svg';
import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { LoadableContent } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { ThresholdsModal } from 'components/shared/ThresholdsModal/ThresholdsModal';
import { FilterableModules } from 'shared/constants';
import { useCompanyActions, useCurrentUser } from 'shared/hooks';
import { UsersKeys } from 'shared/queries';
import { useGetSearchStatistics, useGetViewsStatistics } from 'shared/queries/statistics';
import { Status, ThresholdsType, UsageThresholds } from 'shared/types';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { ChangeTabs } from './Chart/ChangeTabs';
import { SelectPeriod } from './Chart/SelectPeriod';
import { StatisticsChart } from './Chart/StatisticsChart';
import { FrequentFlyersList } from './FrequentFlyers/FrequentFlyersList';
import { SelectDistance } from './FrequentFlyers/SelectDistance';
import { StatisticFiltersFormShape, StatisticsPageFilters } from './StatisticsPageFilters';
import { messages, TypedFormattedMessage, useTypedIntl } from '../locale/messages';

export type ChartType = 'view' | 'search' | 'flyers';

const Header = styled.div`
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;
const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media ${MEDIA_QUERY.MAX_MD} {
    gap: 5px;
  }
`;

const StyledHeader = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  width: 100%;

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 32px 16px;
    margin-bottom: 0;

    & div > div > div {
      margin: 0;
    }
  }

  @media ${MEDIA_QUERY.MAX_SM} {
    & > div {
      flex-direction: column-reverse;

      & > span {
        margin-top: 20px;
      }

      & > div > div {
        margin: 0;
      }
    }
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    overflow-y: hidden;
  }
`;

export function StatisticsPage(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const { usersThresholds } = useCurrentUser();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState<ChartType>('search');
  const { handleThresholdsModalOpen } = useCompanyActions();
  const savedFilters = useAppSelector(state => state.filters.statistics);
  const viewStatisticsData = useGetViewsStatistics(savedFilters.data, { enabled: false });
  const searchStatisticData = useGetSearchStatistics(savedFilters.data, { enabled: false });
  const isFetchingUsers = useIsFetching([
    UsersKeys.GetUsers,
    { status: [Status.ACTIVE, Status.INACTIVE] },
  ]);

  const filterUser: UsageThresholds = usersThresholds!.find(
    (item: UsageThresholds) => item.assignedTo === currentUser.id,
  )!;
  const handleRefetch = () => {
    const { companies, users, date } = savedFilters.data;
    if (companies.length !== 0 && users.length !== 0 && date) {
      if (currentChart === 'search') {
        searchStatisticData.refetch();
      }
      if (currentChart === 'view') {
        viewStatisticsData.refetch();
      }
    }
  };
  // cache is cleared to avoid unnecessary renders of scatter chart which is resource-heavy
  useEffect(
    () => () => {
      viewStatisticsData.remove();
      searchStatisticData.remove();
    },
    [],
  );

  useDeepCompareEffect(() => {
    handleRefetch();
  }, [savedFilters.data]);

  const handleClick = () => {
    handleThresholdsModalOpen({
      assignedId: currentUser.id,
      assignedName: currentUser.firstName,
      maxConverterSearchesPerDay: filterUser?.maxConverterSearchesPerDay,
      maxConverterViewsPerDay: filterUser?.maxConverterViewsPerDay,
    });
  };

  useEffect(() => {
    handleRefetch();
  }, [currentChart]);

  const handleFiltersChanged = (changed: StatisticFiltersFormShape) => {
    dispatch(saveFilters({ filterKey: FilterableModules.STATISTICS, values: changed }));
  };

  const handleFiltersToggle = () => setFiltersOpen(!filtersOpen);
  const data = currentChart === 'search' ? searchStatisticData.data : viewStatisticsData.data;

  const shownFlyers = currentChart === 'flyers';

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelTemplate>
        <PanelHeader title={<TypedFormattedMessage id="Statistics.Header" />}>
          <AppButton type="button" onClick={handleClick}>
            <TypedFormattedMessage id="Statistics.Header.SetThresholds" />
          </AppButton>
        </PanelHeader>
        <PanelContent>
          <PanelContentMain className={StyledHeader}>
            <Header>
              {shownFlyers ? <SelectDistance /> : <SelectPeriod />}
              <HeaderRight>
                <ChangeTabs selected={currentChart} onChartTypeChange={setCurrentChart} />
                {!shownFlyers && (
                  <SearchBar
                    searchInputVisible={false}
                    onFiltersToggle={handleFiltersToggle}
                    savedFilters={savedFilters}
                  />
                )}
              </HeaderRight>
            </Header>
            <LoadableContent
              loading={
                viewStatisticsData.isFetching || searchStatisticData.isFetching || !!isFetchingUsers
              }
              mode={LoadableContent.MODE.FULL}
              drawContent
            >
              {shownFlyers ? (
                <FrequentFlyersList />
              ) : (
                <StatisticsChart
                  data={data}
                  isPending={viewStatisticsData.isFetching || searchStatisticData.isFetching}
                />
              )}
            </LoadableContent>
          </PanelContentMain>
          {!shownFlyers && (
            <PanelContentSidebar
              header={intl.formatMessage({ id: 'Global.Filters' })}
              headerIcon={filterIcon}
              open={filtersOpen}
              onSidebarClosed={handleFiltersToggle}
            >
              <StatisticsPageFilters
                onFiltersChanged={handleFiltersChanged}
                onFiltersApplied={handleFiltersToggle}
              />
            </PanelContentSidebar>
          )}
        </PanelContent>
      </PanelTemplate>
      <ThresholdsModal modalEndpoint={ThresholdsType.USER} />
    </AlphamartIntlProvider>
  );
}
