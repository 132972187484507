import { RootState } from 'store';
import { fetchUser } from 'store/auth';
import { setPricesTimestamp } from 'store/pricesTimestampSlice';
import { store } from '../../index';

export interface PriceTimestampHeaders {
  'user-prices-update-timestamp': string | null;
}

export function validatePricesTimestamp({
  'user-prices-update-timestamp': pricesTimestamp,
}: PriceTimestampHeaders): void {
  if (!pricesTimestamp) return;

  const state = store.getState() as RootState;

  if (!state.pricesTimestamp.timestamp) {
    // @ts-ignore store.dispatch is not aware of redux-thunk
    store.dispatch(setPricesTimestamp(+pricesTimestamp));
    return;
  }

  if (+pricesTimestamp !== state.pricesTimestamp.timestamp) {
    // @ts-ignore store.dispatch is not aware of redux-thunk
    store.dispatch(setPricesTimestamp(+pricesTimestamp));
    // @ts-ignore store.dispatch is not aware of redux-thunk
    if (pricesTimestamp) store.dispatch(fetchUser(true));
  }
}
