import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const removeAssaySlice = createSlice<GenericStoreSlice, GenericStoreReducer<GenericStoreSlice>>({
  name: 'removeAssay',
  initialState: {
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(),
  },
});

export const {
  pending: removeAssayAction,
  success: removeAssaySuccessAction,
  failure: removeAssayFailureAction,
} = removeAssaySlice.actions;

export const removeAssay =
  (id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(removeAssayAction());

      await httpClient.patch(`${apiHostname}/api/assays/${id}/remove`);

      return dispatch(removeAssaySuccessAction({}));
    } catch (error) {
      dispatch(removeAssayFailureAction());

      return Promise.reject(error);
    }
  };

export default removeAssaySlice.reducer;
