import { IntlShape } from 'react-intl';

import { UserRoles } from 'shared/constants';

export const getRoleLang = (name: UserRoles, intl: IntlShape): string => {
  const pascalCased = name
    .toLowerCase()
    .replace(/(^|_)[a-z]/g, match => match.replace('_', '').toUpperCase());
  return intl.formatMessage({ id: `Global.Role.${pascalCased}` });
};
