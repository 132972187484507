import React from 'react';

import logoutIcon from 'assets/images/icons/Logout.svg';
import profileIcon from 'assets/images/icons/User-profile.svg';
import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Icon } from 'components/shared/Buttons';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { logout } from 'store/auth';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { openProfile } from 'store/shared/userProfileSlice';
import { userInfoMenuItemStyles } from '../UserMenu.styles';

interface Props {
  closeMenuHandler: () => void;
}

export const UserMenuLinks = ({ closeMenuHandler }: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const theme = useExtendedTheme();

  const openLogoutModal = () => {
    if (closeMenuHandler) {
      closeMenuHandler();
    }
    dispatch(
      showModal({
        message: <FormattedMessage id="Modal.Logout.Label.DoYouWantToLogout" />,
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          await dispatch(hideModal());
          await dispatch(logout());
          navigate('/logged-out');
        },
      }),
    );
  };

  const menuLinks = [
    {
      key: 'profile',
      title: <FormattedMessage id="Global.Profile" />,
      onClick: () => {
        dispatch(openProfile());
        return closeMenuHandler();
      },
      icon: profileIcon,
    },
    {
      key: 'logout',
      title: <FormattedMessage id="Global.Logout" />,
      onClick: () => {
        openLogoutModal();
        return closeMenuHandler();
      },
      icon: logoutIcon,
    },
  ];

  return (
    <AlphamartIntlProvider>
      {menuLinks.map(link => (
        <button
          key={link.key}
          data-cy={`user-menu-button-${link.key}`}
          className={userInfoMenuItemStyles(theme)}
          type="button"
          onClick={link.onClick}
        >
          <Icon icon={link.icon} />
          {link.title}
        </button>
      ))}
    </AlphamartIntlProvider>
  );
};
