import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { capitalize, debounce, isNil } from 'lodash';
import * as yup from 'yup';

import {
  FilterFieldInputRange,
  RangeValue,
} from 'components/shared/Fields/FieldInputRange/FieldInputRange';
import { FilterSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { getYearOptions } from 'helpers/dateTime/dateTime';
import { DEFAULT_FILTERS, ENGINE_TYPES } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { fetchMakes } from 'store/makesSlice';
import { fetchModels } from 'store/modelsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { TypedIntlShape, useTypedIntl, VehiclesMessages } from '../locale/messages';

export interface VehicleFiltersShape {
  make: string | null;
  model: string | null;
  engineType: string | null;
  year: string | null;
  numberOfConverters: number | null;
  engineDisplacement: {
    from: number | null;
    to: number | null;
  };
}

const getValidationSchema = (intl: TypedIntlShape) =>
  yup.object().shape({
    engineDisplacement: yup.object().shape({
      from: yup.mixed().test({
        name: 'isValid',
        message: intl.formatMessage({ id: 'VehiclesList.Filters.Errors.EngineDisplacement' }),
        test(val) {
          if (val == null || this.parent.to == null) {
            return true;
          }
          return val <= this.parent.to;
        },
      }),
      to: yup.mixed(),
    }),
  });

const numberOfConvertersOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(el => ({
  label: String(el),
  value: el,
}));

interface Props {
  onFiltersChanged: (filters: VehicleFiltersShape) => void;
  onFiltersApplied: () => void;
}

export function VehicleListFilters({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { models } = useAppSelector(state => state.models);
  const { makes } = useAppSelector(state => state.makes);
  const savedFilters = useAppSelector(state => state.filters.vehicles);
  const { isOpen, modalType } = useAppSelector(state => state.modalForm);
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const formikContext = useFormik<VehicleFiltersShape>({
    initialValues: savedFilters.data,
    validationSchema: getValidationSchema(intl),
    validateOnChange: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (isOpen) return;

    formikContext.setValues(savedFilters.data);
  }, [isOpen, modalType]);

  const onFiltersChangeWithValidation = (data: VehicleFiltersShape) => {
    try {
      // we validate schema directly because formik validation is async and formikContext.errors.engineDisplacement.from may show outdated results
      getValidationSchema(intl).validateSync(data);
      onFiltersChanged(data);
      // eslint-disable-next-line
    } catch {}
  };

  useEffect(() => {
    dispatch(fetchMakes());
    dispatch(fetchModels());
  }, []);
  useEffect(() => {
    !isMobile && onFiltersChangeWithValidation(formikContext.values);
  }, [
    formikContext.values.engineType,
    formikContext.values.make,
    formikContext.values.model,
    formikContext.values.numberOfConverters,
    formikContext.values.year,
  ]);

  const setQueryDataDebounced = useCallback(debounce(onFiltersChangeWithValidation, 500), []);
  useEffect(() => {
    setQueryDataDebounced({ ...formikContext.values });
  }, [formikContext.values.engineDisplacement.from, formikContext.values.engineDisplacement.to]);

  const handleFiltersClear = () => {
    formikContext.setValues(DEFAULT_FILTERS.vehicles);
  };
  const { make, model, engineType, year, numberOfConverters, engineDisplacement } =
    formikContext.values;
  const makesOptions = makes?.map(makeOption => ({ label: makeOption, value: makeOption }));
  const modelsOptions = models?.map(modelOption => ({ label: modelOption, value: modelOption }));

  if (!isNil(make) && !makes.includes(make)) makesOptions.push({ label: make, value: make });
  if (!isNil(model) && !models.includes(model)) modelsOptions.push({ label: model, value: model });

  const engineTypes = Object.values(ENGINE_TYPES).map(engine => ({
    label: intl.formatMessage({
      id: `VehicleForm.EngineType.${capitalize(engine)}` as keyof VehiclesMessages,
    }),
    value: engine,
  }));

  const yearOptions = getYearOptions();
  const handleDisplacementChange = (value: RangeValue) => {
    formikContext.setFieldValue('engineDisplacement', value);
  };

  const onFiltersApply = () => {
    onFiltersChanged(formikContext.values);
    onFiltersApplied();
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={handleFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection>
        <FilterSelect
          name="year"
          options={yearOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.Year' })}
          value={year}
        />
        <FilterSelect
          name="make"
          options={makesOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.Make' })}
          value={make}
        />
        <FilterSelect
          name="model"
          options={modelsOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.Model' })}
          value={model}
        />
        <FilterSelect
          name="engineType"
          options={engineTypes}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.EngineType' })}
          value={engineType}
        />
        <FilterSelect
          name="numberOfConverters"
          options={numberOfConvertersOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.NumberOfConverters' })}
          value={numberOfConverters}
        />
      </FiltersSection>
      <FiltersSection label={intl.formatMessage({ id: 'VehiclesList.Filters.EngineDisplacement' })}>
        <FilterFieldInputRange
          onChange={handleDisplacementChange}
          value={engineDisplacement}
          min={0}
          max={1000000}
          error={formikContext?.errors?.engineDisplacement?.from}
        />
      </FiltersSection>
    </FiltersForm>
  );
}
