import React from 'react';
import styled from '@emotion/styled';

import { Theme } from 'theme';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

type Value = string | ReadonlyArray<string> | number;

interface Option {
  label: React.ReactNode;
  value: Value;
  disabled?: boolean;
}

interface Props {
  disabled?: boolean;
  dataCy?: string;
  value: Value | null | undefined;
  name: string;
  options: Option[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const Container = styled.div<{ theme?: Theme }>`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 12px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  label > span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.charcoal};
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
  input {
    display: none;
  }
  input:disabled + label {
    color: ${({ theme }) => theme.colors.mineShaftLightest};
    & > span {
      background-color: ${({ theme }) => theme.colors.mineShaftLightest};
    }
  }
  input:checked + label > span::before {
    background-color: ${({ theme }) => theme.colors.alto};
  }
`;

const getKey = (name: string, value: Value) => `${name}_${value}`;

const FieldRadioLayout = ({
  disabled,
  options,
  name,
  value: currentValue,
  dataCy = 'field-radio',
  onChange,
  onBlur,
}: Props): React.ReactElement => (
  <Container>
    {options.map(({ label, value, disabled: optionDisabled }) => (
      <div key={getKey(name, value)}>
        <input
          name={name}
          id={getKey(name, value)}
          type="radio"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          checked={String(value) === currentValue}
          disabled={disabled || optionDisabled}
        />
        <label data-cy={`${dataCy}-${getKey(name, value)}`} htmlFor={getKey(name, value)}>
          <span />
          {label}
        </label>
      </div>
    ))}
  </Container>
);

const FieldRadio = withFieldWrapper<Props>(props => <FieldRadioLayout {...props} />);

export { FieldRadio, FieldRadioLayout };
