import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { Country } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum CountriesKeys {
  GetCountries = 'GetCountries',
}

export const useGetCountries = (
  options: UseQueryOptions<Country[]> = {},
): UseQueryResult<Country[]> =>
  useQuery<Country[]>(
    [CountriesKeys.GetCountries],
    async () => {
      const response = await getHttpClient().get<Country[]>(`${apiHostname}/api/countries`);
      return response?.data ?? [];
    },
    { initialData: [], cacheTime: 60000, ...options },
  );
