import React, { useEffect, useMemo } from 'react';
import ReactModal from 'react-modal';
import { FormikProvider, useFormik } from 'formik';
import { isNil } from 'lodash';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldSelect, FieldSelectOption } from 'components/shared/Fields/FieldSelect/FieldSelect';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useAssignHedgeToUsers } from 'shared/mutations';
import { useGetAssignableUsers } from 'shared/queries';
import { HedgeAssignParams, hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, MEDIA_QUERY } from 'theme';
import {
  ModalFormButtonContainer,
  ModalFormContent,
  SelectUsers,
  Subheader,
} from './AssignHedgeModal.styles';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  TypedIntlShape,
  useTypedIntl,
} from '../locale/messages';

const validationSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    users: Yup.array()
      .of(Yup.number())
      .required()
      .min(1, intl.formatMessage({ id: 'HedgeAssign.Modal.Form.Users.MinError' }))
      .max(20, intl.formatMessage({ id: 'HedgeAssign.Modal.Form.Users.MaxError' }))
      .ensure(),
  });

function AssignHedgeModalComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const hedgeAssignParams = params as HedgeAssignParams;
  const { hedge } = hedgeAssignParams ?? {};
  const isModalOpen = modalType === ModalFormType.AssignHedge && isOpen && !isNil(hedge);
  const assignHedgeToUser = useAssignHedgeToUsers();
  const getAssignableUsers = useGetAssignableUsers(
    { hedgeId: hedge?.id },
    { enabled: !isNil(hedge?.id) },
  );
  const canChangeCompany = authorize(PERMISSIONS.HEDGES.ASSIGN.SHOW_FULL_HEDGE_DATA);
  const companiesOptions: FieldSelectOption[] = useMemo(
    () =>
      (canChangeCompany ? hedge?.relatedCompanies : [currentUser?.company])?.map(
        ({ id: value, name: label }) => ({ value, label }),
      ),
    [canChangeCompany, hedge, currentUser],
  );
  const formikContext = useFormik<{ company: number | null; users: number[] | null }>({
    initialValues: {
      company: companiesOptions?.some(company => company.value === currentUser.company.id)
        ? (companiesOptions?.[0]?.value as number)
        : null,
      users: null,
    },
    enableReinitialize: true,
    onSubmit: async x => x,
    validationSchema: validationSchema(intl),
  });
  const hideModal = () => {
    dispatch(hideModalForm());
  };
  const handleConfirmClick = async () => {
    try {
      await assignHedgeToUser.mutateAsync({
        hedgeId: hedge.id,
        usersIds: formikContext.values.users,
      });
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'HedgeAssign.Modal.Success' })));
      if (typeof hedgeAssignParams.onSuccess === 'function') {
        hedgeAssignParams.onSuccess();
      }
      hideModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      formikContext.resetForm();
    }
  }, [isModalOpen, hedge]);

  const usersOptions: FieldSelectOption[] = useMemo(
    () =>
      getAssignableUsers.data
        ?.filter(user => user.company.id === formikContext.values.company)
        .map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        })) ?? [],
    [getAssignableUsers.data, formikContext.values.company],
  );

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const submitDisabled =
    !formikContext.dirty ||
    !formikContext.isValid ||
    getAssignableUsers.isFetching ||
    assignHedgeToUser.isLoading;

  const displayHedgePrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);

  return (
    <ReactModal
      isOpen={isModalOpen}
      style={ReactModalStyle(isPhone, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      {isModalOpen && (
        <ModalFormContent>
          <FormikProvider value={formikContext}>
            <FormattedMessage id="HedgeAssign.Modal.Header" />
            <Subheader data-cy="assign-hedge-subheader">
              {hedge.name} {formatDateWithTime(hedge.placedAt)}
              <br />
              {displayHedgePrices &&
                [
                  `Pt: ${formatCurrency(hedge.ptPriceCust)}`,
                  `Pd: ${formatCurrency(hedge.pdPriceCust)}`,
                  `Rh: ${formatCurrency(hedge.rhPriceCust)}`,
                ].join(', ')}
            </Subheader>
            <FieldSelect
              label={intl.formatMessage({ id: 'HedgeAssign.Modal.Company' })}
              name="company"
              value={formikContext.values.company}
              options={companiesOptions}
              disabled={!canChangeCompany}
              required
            />
            <SelectUsers
              label={intl.formatMessage({ id: 'HedgeAssign.Modal.Users' })}
              name="users"
              value={formikContext.values.users}
              options={usersOptions}
              required
              multi
              placeholder={intl.formatMessage({ id: 'HedgeAssign.Modal.Select' })}
              error={formikContext.touched.users && formikContext.errors.users}
            />
            <ModalFormButtonContainer>
              <AppButton
                styleType="neutral-empty"
                onClick={hideModal}
                data-cy="assign-hedge-cancel"
                disabled={assignHedgeToUser.isLoading}
              >
                <FormattedMessage id="Global.Cancel" />
              </AppButton>
              <AppButton
                onClick={handleConfirmClick}
                disabled={submitDisabled}
                data-cy="assign-hedge-confirm"
              >
                <FormattedMessage id="Global.Confirm" />
              </AppButton>
            </ModalFormButtonContainer>
          </FormikProvider>
        </ModalFormContent>
      )}
    </ReactModal>
  );
}

export const AssignHedgeModal = withAlphamartIntlProvider(AssignHedgeModalComponent, messages);
