import { createSlice } from '@reduxjs/toolkit';

import { getDeviceDetails } from 'helpers/devices/deviceDetails';
import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const fetchMakesSlice = createSlice<GenericStoreSlice, GenericStoreReducer<GenericStoreSlice>>({
  name: 'devices',
  initialState: {
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(),
  },
});

export const { pending, success, failure } = fetchMakesSlice.actions;

export const addDevice = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    const deviceDetails = await getDeviceDetails();

    dispatch(pending());
    await httpClient.post(`${apiHostname}/api/devices`, {
      deviceDetails: JSON.stringify(deviceDetails),
    });
    dispatch(success(null));
  } catch (error) {
    dispatch(failure((error as AlphamartHttpError)?.response?.data.message));
    return Promise.reject(error);
  }
};

export const verifyChallenge =
  (challenge: string): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(pending());
      await httpClient.put(`${apiHostname}/api/devices/challenge`, { challenge });
      dispatch(success(null));
    } catch (error) {
      dispatch(failure((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export const resendDeviceChallenge = (): GenericThunk => async (dispatch, getState, httpClient) => {
  await httpClient.post(`${apiHostname}/api/devices/challenge/resend`);
};

export default fetchMakesSlice.reducer;
