import React from 'react';
import { css } from '@emotion/css';

import { MEDIA_QUERY } from 'theme';

const panelContentStyles = css`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  overflow: hidden;
  position: relative;

  @media ${MEDIA_QUERY.MAX_MD} {
    overflow: visible;
  }
`;

const PanelContent = ({ children }: { children: React.ReactNode }): React.ReactElement => (
  <div className={panelContentStyles}>{children}</div>
);

export { PanelContent };
