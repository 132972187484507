import React from 'react';
import { css } from '@emotion/css';

import { Checkbox, Props as BaseProps } from './FieldCheckbox';

interface Props extends BaseProps {
  value?: string;
}

const checkboxItem = css`
  margin-top: 25px;
`;

export const FieldCheckboxGroupItem = ({
  checked,
  children,
  disabled,
  name,
  onBlur,
  onChange,
  onClick,
  size,
  value,
}: Props): React.ReactElement => (
  <div className={checkboxItem}>
    <Checkbox size={size} onClick={onClick}>
      <input
        type="checkbox"
        id={`${name}${value}`}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        value={value}
      />
      <label htmlFor={`${name}${value}`}>
        <svg viewBox="0,0,50,50">
          <path d="M5 30 L 20 45 L 45 5" />
        </svg>
      </label>
      <div>{children}</div>
    </Checkbox>
  </div>
);
