import styled from '@emotion/styled';

import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { Theme } from 'theme';

export const ModalFormButtonContainer = styled.div<{ theme?: Theme }>`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
`;
export const ModalFormContent = styled.div<{ theme?: Theme }>`
  background-color: ${({ theme }) => theme.colors.mineShaft};
  border-radius: 12px;
  max-width: 100%;
  margin: auto;
  padding: 24px 32px;
  width: 430px;
`;

export const Subheader = styled.div<{ theme?: Theme }>`
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  padding-top: 5px;
`;

export const SelectUsers = styled(FieldSelect)`
  margin-top: 5px;
`;
