import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useFormik } from 'formik';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldSelect, FieldSelectOption } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { Form } from 'components/shared/forms/Form/Form';
import { Section } from 'components/shared/forms/Section/Section';
import { CompanyTypes, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useLanguage } from 'shared/hooks/useLanguage';
import { useGetCompanies, useGetCountries, useGetProvinces } from 'shared/queries';
import { CompanyFormData, Province } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { Theme } from 'theme';
import { companyFormSchema } from './companyFormSchema';
import { useTypedIntl } from '../locale/messages';

type Props = {
  companyId?: number;
  editMode?: boolean;
  onSubmit?: (unknown) => Promise<void>;
  initialValues?: CompanyFormData;
};

const MetalsFinanceRateWrapper = styled(Section)<{
  theme?: Theme;
  sectionName?: string;
}>`
  & > fieldset > div > div:nth-last-child(2) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
  }
`;

const CompanyForm = ({
  companyId,
  editMode,
  onSubmit,
  initialValues,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const currentUser = useCurrentUser();
  const allowedToChangeType = authorize(PERMISSIONS.COMPANIES.CHANGE_COMPANY_TYPE);
  const allowedToChangeSubdomain =
    authorize(PERMISSIONS.COMPANIES.CHANGE_SUBDOMAIN) && companyId !== currentUser.company.id;
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [specialCountriesIds, setSpecialCountriesIds] = useState<Record<string, unknown>>({
    US: null,
    CA: null,
  });
  const companiesForFilters = useGetCompanies(
    { type: CompanyTypes.SISTER },
    { enabled: allowedToChangeType },
  );
  const { data: countries } = useGetCountries();
  const language = useLanguage();

  const validationSchema = useMemo(
    () =>
      companyFormSchema(
        countries!,
        provinces,
        intl,
        !!editMode,
        allowedToChangeSubdomain,
        initialValues?.type,
      ),
    [intl, countries, provinces, editMode, initialValues?.type],
  );

  const formikContext = useFormik({
    initialValues: initialValues || {
      terms: {},
      ...(!allowedToChangeType
        ? { type: CompanyTypes.ASSAY, parentCompany: currentUser?.company.id }
        : {}),
    },
    validationSchema,
    ...(editMode ? { initialTouched: { zipCode: true, state: true } } : {}),
    onSubmit: rawValues =>
      onSubmit?.(validationSchema.cast(rawValues, { stripUnknown: true })).catch(() =>
        formikContext.setSubmitting(false),
      ),
  });

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    getFieldMeta,
    handleBlur,
    isValidating,
    isSubmitting,
    isValid,
  } = formikContext;

  useGetProvinces(
    { country: values.country },
    { onSuccess: data => setProvinces(data as Province[]) },
  );

  const countriesOptions = useMemo(() => {
    const specialCountries = countries!.reduce<Record<string, unknown>>(
      (acc, { id, alphaCode2 }) => {
        if (alphaCode2 === 'CA' || alphaCode2 === 'US') {
          return { ...acc, [alphaCode2]: id };
        }

        return acc;
      },
      {},
    );

    setSpecialCountriesIds({
      US: specialCountries.US,
      CA: specialCountries.CA,
    });

    return countries!.reduce<FieldSelectOption[]>((acc, { id: value, name, alphaCode2 }) => {
      if (alphaCode2 === 'US' || alphaCode2 === 'CA') {
        return [{ label: name[language], value }, ...acc];
      }

      return [...acc, { label: name[language], value }];
    }, []);
  }, [countries]);
  const provincesOptions = useMemo(
    () => provinces.map(({ abbreviation: value, name }) => ({ value, label: name[language] })),
    [provinces],
  );
  const companiesOptions = useMemo(
    () =>
      companiesForFilters.data?.data
        .map(({ id: value, name: label }) => ({
          value,
          label: label.length > 19 ? `${label.slice(0, 19)}...` : label,
        }))
        .filter(c => (editMode ? c.value !== Number(companyId) : true)) ?? [],
    [companiesForFilters.data, companyId, editMode],
  );

  const getErrors = name => {
    const meta = getFieldMeta(name);
    return meta.touched && meta.error;
  };

  const onCancel = useCallback(() => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'CompanyForm.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/companies');
          dispatch(hideModal());
        },
      }),
    );
  }, [dispatch, intl]);

  const handleCountryChange = useCallback(
    val => {
      if (val?.value !== values.country) formikContext.setFieldValue('state', undefined);
    },
    [validationSchema, values],
  );

  const handleCompanyTypeChange = useCallback(value => {
    if (value?.value === CompanyTypes.SISTER) {
      formikContext.setFieldValue('parentCompany', currentUser?.company.id);
    }
    formikContext.setFieldValue('subdomain', '');
  }, []);

  const isSpecialCountry = Object.values(specialCountriesIds).includes(values.country);

  const getStateError = () => {
    if (isSpecialCountry && !values.state) {
      return intl.formatMessage({ id: 'CompanyForm.Errors.State.Required' });
    }

    const meta = getFieldMeta('state');
    return meta.touched && meta.error;
  };

  const showMaxAssayUsersField =
    editMode &&
    initialValues?.type === CompanyTypes.ASSAY &&
    authorize(PERMISSIONS.COMPANIES.MAX_ASSAY_USERS);
  const typeInfoTemplate = allowedToChangeSubdomain ? 'parentCompany subdomain' : 'parentCompany .';
  const companyInfoTemplate = allowedToChangeType ? ['name type', typeInfoTemplate] : ['name name'];
  const companySectionTemplate = showMaxAssayUsersField
    ? [...companyInfoTemplate, 'maxAssayUsers .']
    : companyInfoTemplate;
  const submitDisabled = !!(
    !(isValid && Object.keys(touched).length) ||
    isValidating ||
    isSubmitting ||
    getStateError()
  );

  return (
    <Form
      header={intl.formatMessage({
        id: editMode ? 'CompanyForm.Header.Update' : 'CompanyForm.Header',
      })}
      submitLabel={intl.formatMessage({
        id: editMode ? 'CompanyForm.Header.Update' : 'CompanyForm.Submit',
      })}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={submitDisabled}
      context={formikContext}
    >
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="companyInfo"
        label={intl.formatMessage({ id: 'CompanyForm.Section.CompanyInfo' })}
        template={companySectionTemplate}
      >
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.Name' })}
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name ?? ''}
          error={getErrors('name')}
          required
          autoComplete="off"
          data-cy="name"
          maxLength={100}
        />
        {allowedToChangeType && (
          <FieldSelect
            label={intl.formatMessage({ id: 'CompanyForm.Type' })}
            name="type"
            options={[
              {
                value: CompanyTypes.SISTER,
                label: intl.formatMessage({ id: 'CompanyForm.Type.SISTER' }),
              },
              {
                value: CompanyTypes.ASSAY,
                label: intl.formatMessage({ id: 'CompanyForm.Type.ASSAY' }),
              },
            ]}
            value={values.type}
            onChange={handleCompanyTypeChange}
            error={getErrors('type')}
            disabled={editMode}
            required
            data-cy="type"
          />
        )}
        {allowedToChangeType && (!editMode || values.parentCompany) && (
          <FieldSelect
            label={intl.formatMessage({ id: 'CompanyForm.ParentCompany' })}
            name="parentCompany"
            options={companiesOptions}
            value={values.parentCompany}
            error={getErrors('parentCompany')}
            disabled={values.type === CompanyTypes.SISTER || editMode}
            required
            data-cy="parent-company"
          />
        )}
        {allowedToChangeSubdomain && (!editMode || values.parentCompany) && (
          <FieldInput
            label={intl.formatMessage({ id: 'CompanyForm.Subdomain' })}
            name="subdomain"
            disabled={values.type !== CompanyTypes.SISTER}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.subdomain ?? ''}
            error={getErrors('subdomain')}
            required
            autoComplete="off"
            data-cy="subdomain"
            maxLength={63}
          />
        )}
        {showMaxAssayUsersField && (
          <FieldInput
            label={intl.formatMessage({ id: 'CompanyForm.MaxAssayUsers' })}
            name="maxAssayUsers"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.maxAssayUsers ?? ''}
            error={getErrors('maxAssayUsers')}
            required
            autoComplete="off"
            data-cy="max-assay-users"
          />
        )}
      </Section>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="address"
        label={intl.formatMessage({ id: 'CompanyForm.Section.Address' })}
        template={['city zipCode', 'street street', 'country state']}
      >
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.City' })}
          name="city"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city ?? ''}
          error={getErrors('city')}
          required
          autoComplete="off"
          data-cy="city"
          maxLength={100}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.ZipCode' })}
          name="zipCode"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.zipCode ?? ''}
          error={getErrors('zipCode')}
          required={isSpecialCountry}
          autoComplete="off"
          data-cy="zip-code"
          capitalize
          maxLength={20}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.Street' })}
          name="street"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.street ?? ''}
          error={getErrors('street')}
          required
          autoComplete="off"
          data-cy="street"
          maxLength={100}
        />
        <FieldSelect
          label={intl.formatMessage({ id: 'CompanyForm.Country' })}
          name="country"
          value={values.country}
          onChange={handleCountryChange}
          error={getErrors('country')}
          options={countriesOptions}
          required
          data-cy="country"
        />
        {isSpecialCountry ? (
          <FieldSelect
            label={intl.formatMessage({ id: 'CompanyForm.State' })}
            name="state"
            value={values.state}
            error={getStateError()}
            options={provincesOptions}
            disabled={!provincesOptions.length}
            required
            data-cy="state"
          />
        ) : (
          <FieldInput
            label={intl.formatMessage({ id: 'CompanyForm.State' })}
            name="state"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state ?? ''}
            error={getStateError()}
            autoComplete="off"
            data-cy="state"
            maxLength={100}
          />
        )}
      </Section>
      <MetalsFinanceRateWrapper
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="terms"
        label={intl.formatMessage({ id: 'CompanyForm.Section.Terms' })}
        template={[
          'terms.treatmentChargePerPound terms.metalsReturnTermInDays',
          'terms.metalsReturnFinanceRate terms.metalsReturnFinanceRate',
          'terms.platinumReturnRate terms.platinumReturnRate',
          'terms.palladiumReturnRate terms.palladiumReturnRate',
          'terms.rhodiumReturnRate terms.rhodiumReturnRate',
        ]}
      >
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.TreatmentChargePerPound' })}
          name="terms.treatmentChargePerPound"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.terms.treatmentChargePerPound ?? ''}
          error={getErrors('terms.treatmentChargePerPound')}
          required
          autoComplete="off"
          prefix="$"
          data-cy="terms-treatment-charge-per-pound"
        />
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.MetalsReturnTermInDays' })}
          name="terms.metalsReturnTermInDays"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.terms.metalsReturnTermInDays ?? ''}
          error={getErrors('terms.metalsReturnTermInDays')}
          required
          autoComplete="off"
          data-cy="terms-metals-return-term-in-days"
          maxLength={3}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.MetalsReturnFinanceRate' })}
          name="terms.metalsReturnFinanceRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.terms.metalsReturnFinanceRate ?? ''}
          error={getErrors('terms.metalsReturnFinanceRate')}
          required
          autoComplete="off"
          suffix="%"
          data-cy="terms-metals-return-finance-rate"
          maxLength={5}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.PlatinumReturnRate' })}
          name="terms.platinumReturnRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.terms.platinumReturnRate ?? ''}
          error={getErrors('terms.platinumReturnRate')}
          required
          autoComplete="off"
          suffix="%"
          data-cy="terms-platinum-return-rate"
          maxLength={5}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.PalladiumReturnRate' })}
          name="terms.palladiumReturnRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.terms.palladiumReturnRate ?? ''}
          error={getErrors('terms.palladiumReturnRate')}
          required
          autoComplete="off"
          suffix="%"
          data-cy="terms-palladium-return-rate"
          maxLength={5}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'CompanyForm.RhodiumReturnRate' })}
          name="terms.rhodiumReturnRate"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.terms.rhodiumReturnRate ?? ''}
          error={getErrors('terms.rhodiumReturnRate')}
          required
          autoComplete="off"
          suffix="%"
          data-cy="terms-rhodium-return-rate"
          maxLength={5}
        />
      </MetalsFinanceRateWrapper>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="note"
        label={intl.formatMessage({ id: 'CompanyForm.Section.Note' })}
        template={['note note']}
      >
        <FieldTextArea
          label={intl.formatMessage({ id: 'CompanyForm.Note' })}
          name="note"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.note}
          data-cy="note"
          maxLength={255}
        />
      </Section>
    </Form>
  );
};

export { CompanyForm };
