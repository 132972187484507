import React, { ReactElement, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import icon from 'assets/images/icons/arrowBackWhite.svg';
import { useExtendedTheme } from 'shared/hooks';
import { layers, MEDIA_QUERY, Theme } from 'theme';
import 'slick-carousel/slick/slick.css';

interface CarouselProps {
  children: ReactElement[];
  customPaging: (i: number) => React.ReactElement;
}

const StyledCarousel = styled.div<{ theme?: Theme }>`
  position: relative;
  overflow: hidden;
  margin: 0 -24px;
  padding: 0 20px;

  @media ${MEDIA_QUERY.MD} {
    display: none;
  }

  .slick-arrow {
    position: absolute;
    z-index: ${layers.carousel};
    top: 50%;
    height: 88px;
    width: 88px;
    border-radius: 88px;
    border: none;
    background: ${({ theme }) => theme.colors.mineShaftLightest};
    margin: -44px 0 0 0;
    outline: none;
    font-size: 0;
    background-image: url(${icon});
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: right 0px top 50%;
  }

  .slick-arrow.slick-prev {
    left: -84px;
  }

  .slick-arrow.slick-next {
    right: -84px;
    transform: rotate(180deg);
  }

  img {
    width: calc(100% - 8px);
    height: auto;
    display: block;
    border-radius: 10px;
    border: 1px solid transparent;
  }
`;

const slickDots = (theme: Theme) => css`
  overflow-x: scroll;
  display: flex !important;
  padding: 0 4px;
  list-style: none;
  align-items: center;
  gap: 8px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  img {
    height: 57px !important;
    width: 95px !important;
    image-fit: cover;
  }

  .slick-active img {
    border: 2px solid ${theme.mainColor};
  }
`;

export const Carousel = ({ children, customPaging }: CarouselProps): ReactElement => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const slickRef = useRef<Slider>(null);
  const theme = useExtendedTheme();

  const settings: Settings = {
    customPaging,
    dots: true,
    dotsClass: slickDots(theme),
    beforeChange: (_, nextSlide) => {
      carouselRef.current
        ?.querySelector(`li:nth-child(${nextSlide + 1})`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    },
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <StyledCarousel ref={carouselRef}>
      <Slider ref={slickRef} {...settings}>
        {children}
      </Slider>
    </StyledCarousel>
  );
};
