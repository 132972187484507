import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const deleteUserSlice = createSlice<GenericStoreSlice, GenericStoreReducer<GenericStoreSlice>>({
  name: 'deleteUser',
  initialState: { error: undefined },
  reducers: getGenericReducers(),
});

export const { success, failure, pending: submitting } = deleteUserSlice.actions;

export default deleteUserSlice.reducer;

export const deleteUser =
  (id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(submitting());
      await httpClient.delete(`${apiHostname}/api/users/${id}`);
      dispatch(success({}));
    } catch (err) {
      dispatch(failure((err as AlphamartHttpError)?.response?.data?.message));
      return Promise.reject((err as AlphamartHttpError)?.response?.data);
    }
  };
