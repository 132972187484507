import React from 'react';
import styled from '@emotion/styled';
import { chain } from 'lodash';

import { Badge } from 'components/shared/Badge';
import { TextButton } from 'components/shared/Buttons';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate } from 'helpers/dateTime/dateTime';
import { getRoleLang } from 'helpers/roles/roles';
import { AccessFlag, PERMISSIONS, UserRoles } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import {
  useAlphamartNavigate,
  useCurrentUser,
  useExtendedTheme,
  useUserActions,
} from 'shared/hooks';
import { useGetDevices } from 'shared/queries';
import { Status, UsageThresholds, User } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { fetchUser } from 'store/userDetailsSlice';
import { theme as mainTheme } from 'theme';
import { UserDetailsDevices } from './UserDetailsDevices';
import { UserDetailsHedges } from './UserDetailsHedges';
import { UserDetailsLocations } from './UserDetailsLastLocation';
import { UserDetailsLastLogin } from './UserDetailsLastLogin';
import { UserDetailsProfitMargins } from './UserDetailsProfitMargins';
import { UserDetailsUsageThresholds } from './UserDetailsThresholds';
import { useUserItemActions } from '../hooks/useUserItemActions';
import {
  TypedFormattedMessage as FormattedMessage,
  UsersMessages,
  useTypedIntl,
} from '../locale/messages';

const StyledLink = styled.a`
  color: ${mainTheme.colors.sliver};
`;

type Props = {
  user?: User;
  onUserUnblock?: () => unknown;
};

const UserDetails = ({ user, onUserUnblock = () => ({}) }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const currentUser = useCurrentUser();
  const { data: detailsDevices } = useGetDevices(
    { userId: user?.id, status: [Status.ACTIVE, Status.INACTIVE] },
    { initialData: [] },
  );
  const canNavigateToCompanyDetails = authorize(PERMISSIONS.COMPANIES.LIST);
  const canSeeDevicesDetails = authorize(PERMISSIONS.USERS.DETAILS.SHOW_USER_DEVICES_DETAILS);
  const canSeeUserTermsAdjustments =
    authorize(PERMISSIONS.USERS.DETAILS.SHOW_USER_TERMS_ADJUSTMENTS_FIELD) &&
    user?.roles.some(({ name }) => name === UserRoles.BUYER);
  const canSeeDisplayHedgePrices = authorize(PERMISSIONS.USERS.SHOW_DISPLAY_HEDGE_PRICES_FIELD);
  const canSeeUsageThresholds = authorize(PERMISSIONS.USER_PROFILE.EDIT_USAGES_THRESHOLDS);
  const { usersThresholds } = useCurrentUser();
  const filterUser: UsageThresholds | undefined = usersThresholds?.find(
    (item: UsageThresholds) => item.assignedTo === user?.id,
  );
  const canSeeTermsAndConditionsConsents = authorize(
    PERMISSIONS.USERS.SEE_TERMS_AND_CONDITIONS_CONSENT_DETAILS,
  );

  const canSeeParentCompanyDetails = authorize(PERMISSIONS.USERS.DETAILS.SHOW_PARENT_COMPANY);
  const userParentCompanyDetails = user?.company?.parentCompany;

  const theme = useExtendedTheme();
  const onUserUnblockSuccess = async () => {
    if (!user) return;
    await onUserUnblock();
    await dispatch(fetchUser(user.id));
  };
  const { isPending: areUserActionsPending } = useUserActions();

  const actions = useUserItemActions(onUserUnblockSuccess, user);

  if (!user) return null;

  const isBlocked = user.status === Status.ACTIVE && user.isBlocked;
  const colorCalc = status => {
    if (isBlocked) return theme.colors.fuzzyWuzzyBrown;

    return status === Status.ACTIVE ? theme.colors.emerald : theme.colors.mineShaftLightest;
  };

  const rolesList = user?.roles?.map(role => (
    <Badge key={role.id} color={theme.colors.mineShaftLightest}>
      {getRoleLang(role.name, intl)}
    </Badge>
  ));

  const consents: {
    id: number;
    termsAndConditionsConsentDate: string | null;
    ip: string | null;
    formattedAddress: string | null;
  }[] = chain(user.consents).orderBy('termsAndConditionsConsentDate', 'desc').value();
  if (!consents?.length)
    consents.push({
      termsAndConditionsConsentDate: null,
      ip: null,
      formattedAddress: null,
      id: 0,
    });

  return (
    <LoadableContent loading={areUserActionsPending} mode={LoadableContent.MODE.FULL} drawContent>
      <Details title={`${user.firstName} ${user.lastName}`} backUrl="/users" actions={actions}>
        <DetailsSection
          title={<FormattedMessage id="UserDetails.Title" />}
          fields={[
            {
              name: 'userName',
              label: <FormattedMessage id="UserDetails.UserName" />,
              value: `${user.firstName} ${user.lastName}`,
            },
            {
              name: 'status',
              label: <FormattedMessage id="UserDetails.Status" />,
              value: (
                <Badge color={colorCalc(user.status)}>
                  <FormattedMessage
                    id={
                      `Global.Status.${
                        isBlocked ? Status.BLOCKED : user.status?.toUpperCase()
                      }` as keyof UsersMessages
                    }
                  />
                </Badge>
              ),
            },
            {
              name: 'role',
              label: <FormattedMessage id="UserDetails.Role" />,
              value: rolesList,
            },
            {
              name: 'email',
              label: <FormattedMessage id="UserDetails.Email" />,
              value: <StyledLink href={`mailto:${user.email}`}>{user.email}</StyledLink>,
            },
            {
              name: 'company',
              label: <FormattedMessage id="UserDetails.Company" />,
              value: canNavigateToCompanyDetails ? (
                <TextButton onClick={() => navigate(`/companies/${user.company?.id}`)}>
                  {user.company?.name}
                </TextButton>
              ) : (
                user.company?.name
              ),
            },
            {
              name: 'parentCompany',
              label: <FormattedMessage id="UserDetails.ParentCompany.Name" />,
              value: userParentCompanyDetails?.id ? (
                <TextButton onClick={() => navigate(`/companies/${userParentCompanyDetails?.id}`)}>
                  {userParentCompanyDetails?.name}
                </TextButton>
              ) : null,
              doNotRender: !canSeeParentCompanyDetails,
            },
            {
              name: 'mobilePhone',
              label: <FormattedMessage id="UserDetails.MobilePhone" />,
              value: `${user.mobilePhonePrefix} ${user.mobilePhone}`,
            },
            {
              name: 'officePhone',
              label: <FormattedMessage id="UserDetails.OfficePhone" />,
              value: user.officePhone && `${user.officePhonePrefix} ${user.officePhone}`,
            },
          ]}
        />

        <UserDetailsProfitMargins user={user} />
        {canSeeUserTermsAdjustments && (
          <DetailsSection
            title={<FormattedMessage id="UserDetails.TermsAdjustments" />}
            fields={[
              {
                name: 'ptTermsAdjustment',
                label: <FormattedMessage id="Global.Metals.Platinum" />,
                value: `${user.ptTermsAdjustment} %`,
              },
              {
                name: 'pdTermsAdjustment',
                label: <FormattedMessage id="Global.Metals.Palladium" />,
                value: `${user.pdTermsAdjustment} %`,
              },
              {
                name: 'rhTermsAdjustment',
                label: <FormattedMessage id="Global.Metals.Rhodium" />,
                value: `${user.rhTermsAdjustment} %`,
              },
            ]}
          />
        )}

        <UserDetailsHedges user={user} />

        <DetailsSection
          title={<FormattedMessage id="UserDetails.Privileges" />}
          template={['additionalDevice . .']}
          fields={[
            {
              name: 'additionalDevice',
              label: <FormattedMessage id="UserDetails.additionalDevice" />,
              value: (
                <FormattedMessage
                  id={`Global.${user.privileges.additionalDevice ? 'Yes' : 'No'}`}
                />
              ),
            },
          ]}
        />

        {canSeeDisplayHedgePrices ? (
          <DetailsSection
            title={<FormattedMessage id="UserDetails.Hedges" />}
            template={['displayHedgePrices displayHedgePrices displayHedgePrices']}
            fields={[
              {
                name: 'displayHedgePrices',
                label: <FormattedMessage id="UserDetails.DisplayHedgePrices" />,
                value: (
                  <FormattedMessage
                    id={`Global.${
                      user.accessFlags.includes(AccessFlag.HEDGE_PRICE_VIEWER) ? 'Yes' : 'No'
                    }`}
                  />
                ),
              },
            ]}
          />
        ) : null}

        {canSeeDevicesDetails && detailsDevices && detailsDevices.length > 0 ? (
          <UserDetailsDevices userDevices={detailsDevices} />
        ) : null}

        {user.loginLogs && user.loginLogs.length > 0 && (
          <UserDetailsLastLogin userLoginLog={user.loginLogs[0]} />
        )}

        {user.loginLogs && user.loginLogs.length > 0 && (
          <UserDetailsLocations location={user.locations?.[0]} />
        )}

        <DetailsSection
          title={<FormattedMessage id="UserDetails.ConsentsSection" />}
          fields={consents?.flatMap(consent =>
            [
              {
                name: `consentsDate${consent.id}`,
                label: <FormattedMessage id="UserDetails.ConsentsSection.Date" />,
                value: formatDate(consent.termsAndConditionsConsentDate),
              },
              {
                name: `consentsAddress${consent.id}`,
                label: <FormattedMessage id="UserDetails.ConsentsSection.Address" />,
                value: consent.formattedAddress,
                doNotRender: !canSeeTermsAndConditionsConsents,
              },
              {
                name: `consentsIp${consent.id}`,
                label: <FormattedMessage id="UserDetails.ConsentsSection.Ip" />,
                value: consent.ip,
                doNotRender: !canSeeTermsAndConditionsConsents,
              },
            ].filter(p => !p.doNotRender),
          )}
        />

        {canSeeUsageThresholds && user.id !== currentUser?.id ? (
          <UserDetailsUsageThresholds user={filterUser} />
        ) : null}

        <DetailsFooter
          fields={[
            {
              name: 'createdBy',
              label: <FormattedMessage id="Global.CreatedBy" />,
              value: user.createdBy && `${user.createdBy.firstName} ${user.createdBy.lastName}`,
            },
            {
              name: 'createdAt',
              label: <FormattedMessage id="Global.CreatedOn" />,
              value: formatDate(user.createdAt),
            },
            {
              name: 'updatedBy',
              label: <FormattedMessage id="Global.UpdatedBy" />,
              value: user.updatedBy && `${user.updatedBy.firstName} ${user.updatedBy.lastName}`,
            },
            {
              name: 'updatedAt',
              label: <FormattedMessage id="Global.UpdatedOn" />,
              value: user.updatedAt && formatDate(user.updatedAt),
            },
          ]}
        />
      </Details>
    </LoadableContent>
  );
};

export { UserDetails };
