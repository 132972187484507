import styled from '@emotion/styled';

import { MEDIA_QUERY, theme } from 'theme';

export const ModalFormContent = styled.div`
  background-color: ${theme.colors.mineShaft};
  border-radius: 12px;
  max-width: 100%;
  padding: 24px 32px;
  width: 430px;

  @media ${MEDIA_QUERY.MAX_SM} {
    max-height: 100%;
    overflow-y: scroll;
  }

  fieldset {
    border-bottom: none;
    padding: 0;
  }
`;

export const ModalFormButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
`;

export const ModalHeader = styled.h1`
  display: flex;
  font-size: 0.9rem;
  color: white;
  font-weight: normal;
  align-items: center;
  margin: 0;
  margin-bottom: 15px;
`;

export const ModalFormInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  & > div:nth-child(1) {
    flex-basis: 100%;
  }

  & > div:nth-child(2) {
    width: 50px;
    text-align: center;
    align-self: self-end;
    margin-bottom: 10px;

    @media ${MEDIA_QUERY.MAX_MD} {
      margin-bottom: 0;
    }
  }
`;
