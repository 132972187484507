import { css } from '@emotion/css';

import { DropdownPlacement } from 'shared/hooks';
import { layers, MEDIA_QUERY, theme } from 'theme';
import { SelectStyles, SelectStylesOverwrites } from './FieldSelect';

export const FieldSelectStyled = (
  error?: boolean,
  isPrefix?: boolean,
  stylesOverrides?: SelectStylesOverwrites,
  prefixLabel?: string | null,
  placement?: DropdownPlacement,
): SelectStyles => ({
  container: provided => ({
    ...provided,
    color: theme.colors.alphaGray,
    fontSize: 12,
    outline: 'none',
    ...stylesOverrides?.container,
  }),
  control: (provided, { isMulti, isDisabled, menuIsOpen }) => ({
    ...provided,
    minHeight: 48,
    border: `1px solid ${error ? theme.colors.fuzzyWuzzyBrown : theme.colors.alphaGray}`,
    borderRadius: 20,
    padding: isMulti ? '5px 16px 5px 20px' : '5px 16px 5px 24px',
    backgroundColor: isDisabled ? theme.colors.mineShaftLightest : 'transparent',
    boxShadow: 'none',
    outline: 'none',
    pointerEvents: 'all',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    fontFamily: theme.fonts.lato,

    ...(isPrefix
      ? {
          borderRadius: '20px 0 0 20px',
          [`@media ${MEDIA_QUERY.MAX_SM}`]: {
            borderRadius: '20px',
          },
        }
      : {}),

    ...(menuIsOpen
      ? {
          borderBottom: placement === 'bottom' ? 'none' : '1px solid',
          borderTop: placement === 'bottom' ? '1px solid' : 'none',
          ...(isPrefix
            ? {
                borderRadius: placement === 'bottom' ? '20px 0 0 0' : '0px',
                [`@media ${MEDIA_QUERY.MAX_SM}`]: {
                  borderRadius: '20px 20px 0 0',
                },
              }
            : {
                borderRadius: placement === 'bottom' ? '20px 20px 0 0' : '0 0 20px 20px',
              }),
          backgroundColor: theme.colors.mineShaft,
        }
      : {}),

    ':hover': {
      borderColor: isDisabled ? theme.colors.doveGray : theme.colors.alphaGray,
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    opacity: state.isDisabled ? 0 : 1,

    ':hover': {
      color: 'white',
    },
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 0,

    ':hover': {
      color: 'white',
    },
  }),
  input: provided => ({
    ...provided,
    outline: 'none',
    color: theme.colors.alphaGray,
    maxWidth: 138,
    'input:not(:focus)': {
      width: 0,
      height: 0,
    },
  }),
  menu: provided => ({
    ...provided,
    paddingTop: placement === 'bottom' ? 0 : 12,
    marginTop: 0,
    borderRadius: placement === 'bottom' ? '0 0 20px 20px' : '20px 20px 0 0',
    backgroundColor: theme.colors.mineShaft,
    border: `1px solid ${theme.colors.alphaGray}`,
    borderTop: placement === 'bottom' ? 'none' : `1px solid ${theme.colors.alphaGray}`,
    borderBottom: placement === 'bottom' ? `1px solid ${theme.colors.alphaGray}` : 'none',
    marginBottom: 0,
    boxShadow: 'none',
    cursor: 'pointer',
    'z-index': layers.selectMenu,
    ...stylesOverrides?.menu,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    borderRadius: placement === 'bottom' ? '0px 00px 20px 20px' : '5px 5px 0 0',
    '::-webkit-scrollbar-track': {
      marginBottom: '5%',
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-button:vertical:increment:end': {
      backgroundColor: 'transparent',
      display: 'block',
      height: 15,
    },
  }),
  option: (provided, { isFocused, isSelected, isDisabled }) => ({
    ...provided,
    borderTop: `1px solid ${theme.colors.mineShaftLighter}`,
    backgroundColor: isSelected || isFocused ? theme.colors.mineShaftLighter : 'transparent',
    padding: '16px 32px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ':hover, :active': {
      backgroundColor: theme.colors.mineShaftLighter,
    },

    '&:last-of-type': {
      borderRadius: placement === 'bottom' ? '0 0 0px 20px' : '0px 0px 0px 0px',
    },

    [`@media ${MEDIA_QUERY.MOBILE_LANDSCAPE}`]: {
      [`@media  ${MEDIA_QUERY.MAX_MD}`]: {
        fontSize: 8,
      },
    },
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: 12,
    color: theme.colors.alphaGray,
    '::before': {
      content: `"${prefixLabel ?? ''}"`,
    },
  }),
  noOptionsMessage: provided => ({
    ...provided,
    padding: '12px 32px',
    borderTop: `1px solid ${theme.colors.mineShaftLighter}`,
    textAlign: 'left',
  }),
  multiValue: provided => ({
    ...provided,
    height: 32,
    margin: 0,
    minWidth: 96,
    maxWidth: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 16px',
    backgroundColor: theme.colors.mineShaftLightest,
    borderRadius: 6,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 4,

    '+ div:last-child': {
      lineHeight: 0,
      padding: 0,
      margin: 0,
    },
    '+ div:last-child input:not(:focus)': {
      width: 0,
      height: 0,
    },

    [`@media ${MEDIA_QUERY.MAX_XL}`]: {
      margin: 2,

      ':nth-of-type(n + 2)': {
        marginTop: 2,
      },
    },

    ...stylesOverrides?.multiValue,
  }),
  multiValueRemove: (provided, { isDisabled }) => ({
    ...provided,
    padding: 0,
    fontSize: 12,

    ':hover': {
      backgroundColor: 'transparent',
      ...(!isDisabled ? { color: 'white' } : {}),
    },
  }),
  multiValueLabel: (provided, { isDisabled }) => ({
    ...provided,
    color: theme.colors.alphaGray,
    fontSize: 12,
    fontFamily: theme.fonts.lato,

    ':hover': !isDisabled ? { color: 'white' } : {},
  }),
});

export const HighlightedTextStyle = css`
  background-color: ${theme.colors.whisper};
  color: ${theme.background};
`;
