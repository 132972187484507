import React, { useEffect } from 'react';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { DataList, useGetRowId } from 'components/shared/List';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { FeedTypes, SHARED } from 'shared/constants';
import { useCurrentUser, useMediaQuery } from 'shared/hooks';
import { Adjustment } from 'shared/types';
import { fetchAdjustments } from 'store/adjustmentsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { ListSection, PricesSourceInfo } from './MetalPrices.styles';
import { TypedFormattedMessage, useTypedIntl } from '../locale/messages';

export const MetalPrices = (): React.ReactElement => {
  const { adjustmentRates, isPending } = useAppSelector(state => state.adjustments);
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const isLg = useMediaQuery(MEDIA_QUERY.LG);

  useEffect(() => {
    dispatch(fetchAdjustments(user.company.id));
  }, [dispatch, user?.id]);

  const columns: ColumnDef<Adjustment>[] = [
    {
      id: 'metal',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.Metal' }),
      cell: ({ row }) => TypedFormattedMessage({ id: `Settings.Metals.${row.original.metal}` }),
    },
    {
      id: 'source',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.Source' }),
      cell: ({ row }) =>
        TypedFormattedMessage({
          id: `Settings.AdjustmentType.${row.original.adjustmentType}`,
        }),
    },
    {
      id: 'feedPrice',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.FeedPrice' }),
      cell: ({ row }) => formatCurrency(row.original.feedPrice, 'USD'),
    },
    {
      id: 'adjustment',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.Adjustment' }),
      cell: ({ row }) =>
        row.original.sourceAdjustmentType === FeedTypes.MARKET_FEED_ADJUSTED
          ? formatCurrency(row.original.adjustmentValue, 'USD')
          : SHARED.LONG_DASH,
    },
    {
      id: 'totalPrice',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.TotalPrice' }),
      cell: ({ row }) => formatCurrency(row.original.totalPrice, 'USD'),
    },
    {
      id: 'updatedBy',
      header: intl.formatMessage({ id: 'Settings.Adjustments.TableHeader.UpdatedBy' }),
      cell: ({ row }) =>
        row.original.updatedBy
          ? `${row.original.updatedBy.firstName} ${row.original.updatedBy.lastName}`
          : SHARED.LONG_DASH,
    },
    {
      id: 'updatedOn',
      header: intl.formatMessage({ id: 'Global.UpdatedOn' }),
      cell: ({ row }) => formatDate(row.original.updatedAt),
    },
  ];

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data: adjustmentRates.list,
    getRowId: useGetRowId(),
    enableRowSelection: false,
    state: {
      columnVisibility: {
        source: useMediaQuery(MEDIA_QUERY.SM),
        feedPrice: isLg,
        adjustment: isLg,
        updatedBy: useMediaQuery(MEDIA_QUERY.XL),
        updatedOn: isLg,
      },
      columnSizing: {
        metal: 100,
        source: 100,
        feedPrice: 100,
        adjustment: 100,
        totalPrice: 100,
        updatedBy: 100,
        updatedOn: 100,
      },
    },
  });

  return (
    <ListSection>
      <h3>
        <TypedFormattedMessage id="Settings.Adjustments.Header.Title" />
      </h3>
      <PricesSourceInfo>
        <TypedFormattedMessage id="Settings.SourceInfo.Source" />
        <strong>{adjustmentRates.source}</strong>
        <TypedFormattedMessage id="Settings.SourceInfo.Updated" />
        <strong>
          {adjustmentRates.createdAt ? formatDate(adjustmentRates.createdAt, 'd MMMM yyyy p') : '-'}
        </strong>
      </PricesSourceInfo>
      <DataList table={table} isLoading={isPending} />
    </ListSection>
  );
};
