import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useQueryClient } from '@tanstack/react-query';
import { FormikProvider, useFormik } from 'formik';
import { isNil } from 'lodash';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import {
  ModalFormButtonContainer,
  ModalFormContent,
} from 'components/shared/forms/Form/ModalForm.styles';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { ModalHeader } from 'components/views/Vehicles/VehicleSearch/VehicleSearchModal.styles';
import { ModalFormType } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { useRenameShoppingCart } from 'shared/mutations';
import { ShoppingCartsKeys } from 'shared/queries';
import { hideModalForm, ShoppingCartParams } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, MEDIA_QUERY } from 'theme';
import { messages, TypedFormattedMessage, useTypedIntl } from '../../locale/messages';

function RenameModalComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);

  const cartParams = params as ShoppingCartParams;
  const { cart } = cartParams ?? {};
  const isModalOpen = modalType === ModalFormType.RenameShoppingCart && isOpen && !isNil(cart);
  const renameShoppingCart = useRenameShoppingCart();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName.RequiredError' }))
      .ensure(),
  });

  const formikContext = useFormik<{ name: string }>({
    initialValues: {
      name: cart?.name ?? '',
    },
    enableReinitialize: true,
    onSubmit: async x => x,
    validateOnChange: true,
    validationSchema,
  });

  const { values, handleChange, errors, handleBlur, isValid, dirty } = formikContext;
  const queryClient = useQueryClient();

  const hideModal = () => {
    dispatch(hideModalForm());
  };

  const handleConfirmClick = async () => {
    try {
      await renameShoppingCart.mutateAsync({ id: cart.id, name: values.name });
      await queryClient.invalidateQueries({ queryKey: [ShoppingCartsKeys.GetShoppingCarts] });
      dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'ShoppingCart.Modal.Rename.Success' })),
      );
      hideModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      formikContext.resetForm();
    }
  }, [isModalOpen, cart]);

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const submitDisabled = !dirty || !isValid || renameShoppingCart.isLoading;

  return (
    <ReactModal
      isOpen={isModalOpen}
      style={ReactModalStyle(isPhone, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          <ModalHeader>
            <TypedFormattedMessage id="ShoppingCart.Modal.Rename.Header" />
          </ModalHeader>
          <FieldInput
            label={intl.formatMessage({ id: 'ShoppingCart.Modal.Name' })}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name}
            value={values.name}
            data-cy="rename-shopping-cart-name"
            autoComplete="off"
            maxLength={50}
          />
          <ModalFormButtonContainer>
            <AppButton
              styleType="neutral-empty"
              onClick={hideModal}
              data-cy="rename-shopping-cart-cancel"
            >
              <TypedFormattedMessage id="Global.Cancel" />
            </AppButton>
            <AppButton
              onClick={handleConfirmClick}
              disabled={submitDisabled}
              data-cy="rename-shopping-cart-confirm"
            >
              <TypedFormattedMessage id="Global.Confirm" />
            </AppButton>
          </ModalFormButtonContainer>
        </FormikProvider>
      </ModalFormContent>
    </ReactModal>
  );
}

export const RenameCartModal = withAlphamartIntlProvider(RenameModalComponent, messages);
