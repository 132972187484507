import React, { useCallback } from 'react';
import { Cell, flexRender, Row, RowData, Table } from '@tanstack/react-table';
import { has } from 'lodash';

import addIcon from 'assets/images/icons/Add.svg';
import minusIcon from 'assets/images/icons/minus.svg';
import { useTypedIntl } from 'locale/messages';
import { SortDirection, SortSelectOptions, SortTypes } from 'shared/constants/sortableModules';
import { useDeepCompareEffectAfterMount, useExtendedTheme } from 'shared/hooks';
import { CellProps, DataListContainer, NoResultsRow, RowProps } from './DataList.styles';
import {
  HeaderCell,
  TableCell,
  TableCellContent,
  TableContainer,
  TableRowBody,
  TableRowControlBtn,
  TableRowHeader,
} from './DataListTable.styles';
import { ListPagination } from './ListPagination';
import { IconButton } from '../Buttons';
import { LoadableContent } from '../Loader';

// eslint-disable-next-line @typescript-eslint/ban-types
type ComponentWithChildren<P = {}> = React.ComponentType<{ children?: React.ReactNode } & P>;

interface DataListProps<T extends RowData> {
  table: Table<T>;
  isLoading?: boolean;
  onRowClicked?: (item: T) => void;
  subComponent?: React.ComponentType<{ row: Row<T> }>;
  headerBodyComponent?: ComponentWithChildren | null;
  headerRowComponent?: ComponentWithChildren<RowProps<T>>;
  headerCellComponent?: ComponentWithChildren<CellProps<T>>;
  rowComponent?: ComponentWithChildren<RowProps<T>>;
  cellComponent?: ComponentWithChildren<CellProps<T>>;
  cellContentComponent?: ComponentWithChildren<CellProps<T>>;
  dataBodyComponent?: ComponentWithChildren;
  outerContainerComponent?: ComponentWithChildren;
  emptyDataComponent?: ComponentWithChildren;
  sortOptions?: SortSelectOptions[];
  noDataMessage?: React.ReactNode | string;
}

interface DataListRowProps<T extends RowData> {
  row: Row<T>;
  parentRow?: Row<T>;
  table: Table<T>;
  onRowClicked?: (item: T) => void;
  subComponent?: React.ComponentType<{ row: Row<T> }>;
  rowComponent: ComponentWithChildren<RowProps<T>>;
  cellComponent: ComponentWithChildren<CellProps<T>>;
  cellContentComponent: React.ComponentType<CellProps<T>>;
}

export function getCellByColumnId<T>(row: Row<T>, columnId: string): Cell<T, unknown> | undefined {
  return row.getAllCells().find(cell => cell.column.id === columnId);
}

export function useGetRowId() {
  return useCallback(
    <T extends { id?: number | string }>(
      originalRow: T,
      index: number,
      parent?: Row<T>,
    ): string => {
      if (parent) {
        return [parent.id, originalRow.id ?? index].join('.');
      }
      return `${originalRow.id ?? index}`;
    },
    [],
  );
}

function BodyRow<T>({
  onRowClicked,
  row,
  table,
  parentRow,
  subComponent: SubComponent,
  rowComponent: DataRow,
  cellComponent: CellContainer,
  cellContentComponent: CellContent,
}: DataListRowProps<T>): React.ReactElement {
  const theme = useExtendedTheme();

  const handleRowClick = () => {
    const contentSelected = !!window.getSelection()?.toString();
    if (contentSelected) return;

    if (row.getCanSelect()) row.toggleSelected();
    else if (onRowClicked) onRowClicked(row.original);
    else if (row.getCanExpand()) row.toggleExpanded();
  };

  const getRowColor = (item: T, key: string): string => (has(item, key) ? item[key] : '');

  return (
    <>
      <DataRow
        rowColor={getRowColor(row.original, 'color')}
        row={row}
        table={table}
        parentRow={parentRow}
        onClick={handleRowClick}
        isClickable={!!onRowClicked}
      >
        {row.getVisibleCells().map(cell => (
          <CellContainer cell={cell} column={cell.column} key={cell.id}>
            <CellContent cell={cell} />
          </CellContainer>
        ))}
        {row.getCanExpand() && (
          <IconButton
            className={TableRowControlBtn(row.getCanSelect())}
            icon={row.getIsExpanded() ? minusIcon : addIcon}
            iconColor={theme.colors.whisper}
            size={row.getIsExpanded() ? '16px' : '26px'}
            btnSize={26}
            onClick={e => {
              e.stopPropagation();
              row.toggleExpanded();
            }}
          />
        )}
      </DataRow>
      {SubComponent && row.getIsExpanded() && <SubComponent row={row} />}
    </>
  );
}

export function DataList<T extends RowData>({
  table,
  isLoading,
  onRowClicked,
  subComponent,
  headerRowComponent: HeaderRow = TableRowHeader,
  headerCellComponent: HeaderCellComponent = HeaderCell,
  headerBodyComponent: HeaderBody = React.Fragment,
  rowComponent: DataRow = TableRowBody,
  cellComponent: CellContainer = TableCell,
  cellContentComponent: CellContent = TableCellContent,
  dataBodyComponent: DataBody = React.Fragment,
  outerContainerComponent: Container = TableContainer,
  emptyDataComponent: EmptyDataComponent = NoResultsRow,
  sortOptions,
  noDataMessage,
}: DataListProps<T>): React.ReactElement {
  const intl = useTypedIntl();
  const paginationState = table.getState().pagination;
  const sortingState = table.getState().sorting;
  const { rows, rowsById } = table.getRowModel();

  const rowsIds = Object.keys(rowsById);
  useDeepCompareEffectAfterMount(() => {
    table.resetExpanded(true);
  }, [table, rowsIds]);

  const getParentRow = (row: Row<T>): Row<T> => {
    const rowsAscendancy = row.id.split('.');
    return rowsById[rowsAscendancy.slice(0, rowsAscendancy.length - 1).join('.')];
  };

  return (
    <DataListContainer>
      <LoadableContent loading={isLoading} mode={LoadableContent.MODE.OVERLAY}>
        <Container>
          {HeaderBody && (
            <HeaderBody>
              <HeaderRow table={table}>
                {table.getHeaderGroups().map(headerGroup =>
                  headerGroup.headers.map(header => (
                    <HeaderCellComponent key={header.id} column={header.column}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </HeaderCellComponent>
                  )),
                )}
              </HeaderRow>
            </HeaderBody>
          )}
          <DataBody>
            {rows.map(row => (
              <BodyRow
                key={row.id}
                onRowClicked={onRowClicked}
                row={row}
                parentRow={getParentRow(row)}
                table={table}
                subComponent={subComponent}
                cellComponent={CellContainer}
                cellContentComponent={CellContent}
                rowComponent={DataRow}
              />
            ))}
          </DataBody>
        </Container>
        {!rows?.length && (
          <EmptyDataComponent>
            {noDataMessage ?? intl.formatMessage({ id: 'Global.NoResults' })}
          </EmptyDataComponent>
        )}
        {table.options.manualPagination && (
          <ListPagination
            onPageChange={page =>
              table.setPagination({ pageIndex: page - 1, pageSize: paginationState.pageSize })
            }
            pages={table.getPageCount()}
            currentPage={paginationState.pageIndex + 1}
            currentSortValue={
              sortingState.length
                ? {
                    sortDirection: sortingState[0].desc ? SortDirection.DESC : SortDirection.ASC,
                    sortType: sortingState[0].id as SortTypes,
                  }
                : undefined
            }
            handleSortingChange={sorting =>
              table.setSorting(
                sorting
                  ? [{ id: sorting.sortType, desc: sorting.sortDirection === SortDirection.DESC }]
                  : [],
              )
            }
            sortOptions={sortOptions}
          />
        )}
      </LoadableContent>
    </DataListContainer>
  );
}
