import { METAL_ABBR, PriceSources } from 'shared/constants';
import { fetchUser } from 'store/auth';
import { changePriceSource } from 'store/changePriceSourceSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useTypedIntl } from '../locale/messages';

type UseChangeHedgeType = {
  isPending: boolean;
  changeHedge: (source: PriceSources, hedgeId?: number) => void;
};

export const useChangeHedge = (metalAbbr: METAL_ABBR): UseChangeHedgeType => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const isChangePriceSourcePending = useAppSelector(state => state.changePriceSource.isPending);
  const isUserPending = useAppSelector(state => state.auth.isPending);
  const marketFeedSuccessMessage = intl.formatMessage({
    id: `UserProfile.MarketFeedChange.${metalAbbr}.Success`,
  });
  const hedgeFeedSuccessMessage = intl.formatMessage({
    id: `UserProfile.HedgePricesChange.${metalAbbr}.Success`,
  });

  const isPending = isChangePriceSourcePending || isUserPending;
  const changeHedge = async (source: PriceSources, hedgeId?: number) => {
    try {
      await dispatch(changePriceSource(metalAbbr, source, hedgeId));
      await dispatch(fetchUser());
      dispatch(
        snackBarPushSuccess(
          source === 'MARKET' ? marketFeedSuccessMessage : hedgeFeedSuccessMessage,
        ),
      );
    } catch {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  return {
    isPending,
    changeHedge,
  };
};
