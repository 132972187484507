import React, { useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import { useMediaQuery, useWindowHeight, useWindowWidth } from 'shared/hooks';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { MEDIA_QUERY } from 'theme';
import {
  calculatePositionOnDesktop,
  calculatePositionOnMobile,
  checkIsBlocked,
  checkStepPathname,
  TooltipPosition,
} from './Guide.helpers';
import {
  GuideOverlay,
  GuideOverlayBackground,
  StepButtons,
  StepContainer,
  StepContent,
  StepCounter,
  StepFooter,
  StepHeader,
} from './Guide.styles';
import AppButton from '../forms/AppButton/AppButton';

export interface GuideStep {
  querySelector: string;
  title: string;
  message: string;
  /**
   * Pathname pattern required for this step
   * ex. '/users', ''/users/:id','/users/:id/edit'.
   * More info at https://github.com/pillarjs/path-to-regexp#match
   */
  pathnameToMatch: string;
  /**
   * If pathnameToMatch is not matched user will be redirected to following pathname.
   */
  pathnameToRedirect: string;
  onStepCompleted: (() => Promise<void>) | (() => void);
}

interface Props {
  steps: GuideStep[];
  redirectFunction: (path: string) => void;
  onFinish: (() => Promise<void>) | (() => void);
  /**
   * If any of elements with these selectors are rendered in DOM, guide won't render anything.
   * You can use this prop for conditional rendering when some elements have higher priority than guide overlay
   */
  blockingQuerySelectors?: string[];
}

export const Guide = ({
  redirectFunction,
  steps,
  onFinish,
  blockingQuerySelectors = [],
}: Props): React.ReactElement | null => {
  const [isBlocked, setIsBlocked] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [elementToHighlight, setElementToHighlight] = useState<Element | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({});
  const elementCheckInterval = useRef<NodeJS.Timer>();
  const pathCheckInterval = useRef<NodeJS.Timer>();
  const renderedStep = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight(5);
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const proceedToStep = (stepIndex: number) => {
    clearInterval(pathCheckInterval.current);
    clearInterval(elementCheckInterval.current);
    setElementToHighlight(null);
    const step = steps[stepIndex] as GuideStep;
    const isOnCorrectPath = checkStepPathname(step.pathnameToMatch);
    if (isOnCorrectPath) {
      setCurrentStepIndex(stepIndex);
      highlightElement(stepIndex);
      return;
    }

    redirectFunction(step.pathnameToRedirect);
    pathCheckInterval.current = setInterval(() => {
      if (checkStepPathname(step.pathnameToRedirect)) {
        clearInterval(pathCheckInterval.current);
        setCurrentStepIndex(stepIndex);
        highlightElement(stepIndex);
      }
    }, 50);
  };
  const highlightElement = (stepIndex: number) => {
    const element = document.querySelector(steps[stepIndex].querySelector);
    const isGuideBlocked = checkIsBlocked(blockingQuerySelectors);
    if (!element || isBlocked) {
      setIsBlocked(isGuideBlocked);
      elementCheckInterval.current = setInterval(() => {
        const elementFound = document.querySelector(steps[stepIndex].querySelector);
        const isStillBlocked = checkIsBlocked(blockingQuerySelectors);
        if (elementFound && !isStillBlocked) {
          clearInterval(elementCheckInterval.current);
          setElementToHighlight(elementFound);
          setIsBlocked(isStillBlocked);
        }
      }, 50);
      return;
    }
    setIsBlocked(false);
    setElementToHighlight(element);
  };

  useDeepCompareEffect(() => {
    proceedToStep(0);
  }, [steps]);
  useEffect(() => {
    if (elementToHighlight !== null && !isBlocked) {
      const rect = elementToHighlight?.getBoundingClientRect();
      if (isPhone) {
        setTooltipPosition(
          calculatePositionOnMobile(rect, renderedStep.current!.getBoundingClientRect()),
        );
      } else {
        setTooltipPosition(
          calculatePositionOnDesktop(rect, renderedStep.current!.getBoundingClientRect()),
        );
      }
    }
  }, [elementToHighlight, windowWidth, windowHeight, isPhone, isBlocked]);
  useEffect(
    () => () => {
      clearInterval(pathCheckInterval.current);
      clearInterval(elementCheckInterval.current);
    },
    [],
  );

  const handleNextClick = async () => {
    setIsPending(true);
    try {
      await steps[currentStepIndex].onStepCompleted();
      proceedToStep(currentStepIndex + 1);
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
    setIsPending(false);
  };
  const handlePreviousClick = () => {
    proceedToStep(currentStepIndex - 1);
  };
  const handleFinishClick = async () => {
    setIsPending(true);
    try {
      await steps[currentStepIndex].onStepCompleted();
      await onFinish();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
    setIsPending(false);
  };
  const boundsToHighlight = elementToHighlight?.getBoundingClientRect();

  return !isBlocked ? (
    <GuideOverlay>
      <GuideOverlayBackground
        left={boundsToHighlight?.left}
        top={boundsToHighlight?.top}
        bottom={boundsToHighlight?.bottom}
        right={boundsToHighlight?.right}
      />
      {elementToHighlight && (
        <StepContainer position={tooltipPosition} ref={renderedStep}>
          <StepHeader>{steps[currentStepIndex]?.title}</StepHeader>
          <StepContent>{steps[currentStepIndex]?.message}</StepContent>
          <StepFooter>
            <StepCounter>
              {currentStepIndex + 1} <FormattedMessage id="Global.Guide.Counter" /> {steps.length}
            </StepCounter>
            <StepButtons>
              {currentStepIndex > 0 && (
                <AppButton
                  onClick={handlePreviousClick}
                  styleType="neutral-empty"
                  data-cy="guide-back"
                  disabled={isPending}
                >
                  <FormattedMessage id="Global.Guide.Back" />
                </AppButton>
              )}
              {currentStepIndex + 1 === steps.length ? (
                <AppButton
                  onClick={handleFinishClick}
                  styleType="default"
                  data-cy="guide-finish"
                  disabled={isPending}
                >
                  <FormattedMessage id="Global.Guide.Finish" />
                </AppButton>
              ) : (
                <AppButton
                  onClick={handleNextClick}
                  styleType="default"
                  data-cy="guide-next"
                  disabled={isPending}
                >
                  <FormattedMessage id="Global.Guide.Next" />
                </AppButton>
              )}
            </StepButtons>
          </StepFooter>
        </StepContainer>
      )}
    </GuideOverlay>
  ) : null;
};
