import { css } from '@emotion/css';

import { layers, MEDIA_QUERY, Theme } from 'theme';

export const panelContentSidebarStyles = (theme?: Theme): string => css`
  flex-basis: 0;
  width: 288px;
  border-left: 1px solid ${theme?.colors.mineShaftLighter};
  height: 100%;
  max-height: 100%;
  background-color: ${theme?.colors.codGray};
  transition: transform 100ms ease-in;

  @media ${MEDIA_QUERY.MAX_XL} {
    transform: translateX(100%);
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${layers.sidebar};

    &.transition-enter-active,
    &.transition-enter-done {
      transform: translateX(0);
    }
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    position: fixed;
    width: 100vw;
    top: 64px;
    bottom: 0;
    height: auto;
  }
`;

export const sidebarHeaderStyles = (theme?: Theme): string => css`
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  margin: 0;
  padding: 32px;
  font-size: 14px;
  border-bottom: 1px solid ${theme?.colors.mineShaftLightest};
`;

export const closeIconStyles = css`
  position: absolute;
  right: 32px;
  top: 32px;

  @media ${MEDIA_QUERY.XL} {
    && {
      display: none;
    }
  }
`;
