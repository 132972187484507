import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { apiHostname, ErrorCode } from 'shared/constants';
import { AlphamartHttpError, Coords } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface SetPasswordState extends GenericStoreSlice {
  redirectTo: string | null;
}

type SetPasswordReducer = GenericStoreReducer<SetPasswordState> & {
  redirectTo: CaseReducer<SetPasswordState, { type: string; payload: string | null }>;
};

interface SetPasswordParam {
  password: string;
  termsAndConditionsConsent?: boolean;
  position?: Coords;
}

const setPasswordSlice = createSlice<SetPasswordState, SetPasswordReducer>({
  name: 'setPassword',
  initialState: {
    error: undefined,
    redirectTo: null,
  },
  reducers: {
    ...getGenericReducers(),
    redirectTo: (state, { payload }) => ({
      ...state,
      redirectTo: payload,
    }),
  },
});

export const { success, failure, redirectTo, pending: submitting } = setPasswordSlice.actions;

export default setPasswordSlice.reducer;

export const setPasswordAction =
  (
    token: string,
    { password, termsAndConditionsConsent, position }: SetPasswordParam,
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(submitting());

      const values: {
        token: string;
        password: string;
        consents: {
          termsAndConditionsConsent: boolean;
          latitude?: number;
          longitude?: number;
        } | null;
      } = {
        token,
        password,
        consents: null,
      };

      if (!isNil(termsAndConditionsConsent)) {
        values.consents = {
          termsAndConditionsConsent,
          latitude: position?.latitude,
          longitude: position?.longitude,
        };
      }
      await httpClient.post(`${apiHostname}/api/auth/password`, values);
      dispatch(success({}));
      dispatch(redirectTo('/login'));
    } catch (err) {
      switch ((err as AlphamartHttpError).response?.data.errorCode) {
        case ErrorCode.CONFIRM_ACCOUNT_ERROR:
          dispatch(failure('SetPassword.Error.InvalidToken'));
          break;
        case ErrorCode.PASSWORD_ALREADY_SET:
          dispatch(failure('SetPassword.Error.TokenUsed'));
          break;
        case ErrorCode.TOKEN_ALREADY_USED:
          dispatch(failure('ResetPassword.Error.TokenUsed'));
          break;
        case ErrorCode.PASSWORD_HISTORY_ERROR:
          dispatch(failure('ResetPassword.Error.PasswordHistoryError'));
          break;
        case ErrorCode.PASSWORD_CONTAINS_USER_INFO:
          dispatch(failure('ResetPassword.Error.PasswordContainsUserInfo'));
          break;
        case ErrorCode.PASSWORD_IS_TOO_COMMON:
          dispatch(failure('ResetPassword.Error.PasswordIsTooCommon'));
          break;
        default:
          dispatch(failure('Global.Error.SomethingWentWrong'));
      }
      throw err;
    }
  };
