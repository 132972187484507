import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useUserActions } from 'shared/hooks';
import { ItemAction, Status, User, UserListItem } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

export type UserUnion = User | UserListItem;

export const useUserItemActions = (
  onUserUnblock: () => unknown,
  userToPerformActionOn?: UserUnion,
): ItemAction<UserUnion | undefined, UserUnion | undefined>[] => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const currentUser = useCurrentUser();
  const {
    handleUnblock,
    handleUpdate,
    handleLocationsModalOpen,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
    handleInvitationResend,
    handleStatusChange,
    handleThresholdsModalOpen,
    handleDeleteUser,
    handleShoppingCartActivityLogsModalOpen,
  } = useUserActions();

  const wrap =
    <T,>(cb: (user: UserUnion) => T) =>
    (item: UserUnion | undefined): T =>
      cb((userToPerformActionOn ?? item)!);

  return [
    {
      id: 1,
      label: intl.formatMessage({ id: 'Global.Update' }),
      onClick: wrap(item => handleUpdate(item)),
      dataCy: wrap(item => `update-${item.id}`),
    },
    {
      id: 3,
      label: intl.formatMessage({ id: 'Global.Thresholds' }),
      onClick: wrap(item =>
        handleThresholdsModalOpen({
          assignedId: item.id,
          assignedName: item.firstName,
        }),
      ),
      dataCy: wrap(item => `usage-thresholds-${item.id}`),
      visible: wrap(
        item => authorize(PERMISSIONS.USERS.EDIT_USAGES_THRESHOLDS) && item.id !== currentUser.id,
      ),
    },
    {
      id: 4,
      label: intl.formatMessage({ id: 'Global.InvitationResend' }),
      onClick: wrap(item => handleInvitationResend(item)),
      visible: wrap(item => item.status === Status.PENDING),
    },
    {
      id: 5,
      label: intl.formatMessage({ id: 'Global.User.Unblock' }),
      onClick: wrap(item => handleUnblock(item, onUserUnblock)),
      visible: wrap(item => authorize(PERMISSIONS.USERS.UNBLOCK) && !!item?.isBlocked),
      dataCy: wrap(item => `unblock-${item.id}`),
    },
    {
      id: 6,
      label: intl.formatMessage({ id: 'Global.Export.LocationsLogs' }),
      onClick: wrap(item => handleLocationsModalOpen({ userId: item.id })),
      visible: () => authorize(PERMISSIONS.USERS.EXPORT_LOCATIONS),
      dataCy: wrap(item => `export-${item.id}`),
    },
    {
      id: 7,
      label: intl.formatMessage({ id: 'Global.Export.HedgeUseLogs' }),
      onClick: wrap(item => handleHedgeLogsModalOpen({ userId: item.id })),
      visible: () => authorize(PERMISSIONS.COMPANIES.EXPORT_HEDGE_USE_LOGS),
      dataCy: wrap(item => `company-export-hedge-use-logs-${item.id}`),
    },
    {
      id: 8,
      label: intl.formatMessage({ id: 'Global.Export.SearchLogs' }),
      onClick: wrap(item => handleConverterLogsModalOpen({ userId: item.id })),
      visible: () => authorize(PERMISSIONS.COMPANIES.EXPORT_CONVERTER_LOGS),
      dataCy: wrap(item => `company-export-view-search-logs-${item.id}`),
    },
    {
      id: 9,
      label: intl.formatMessage({ id: 'Global.Export.ShoppingCartLogs' }),
      onClick: wrap(item => handleShoppingCartActivityLogsModalOpen({ userId: item.id })),
      visible: () => authorize(PERMISSIONS.USERS.EXPORT_SHOPPING_CART_LOGS),
      dataCy: wrap(item => `export-shopping-cart-logs-${item.id}`),
    },
    {
      id: 10,
      label: intl.formatMessage({ id: 'Global.Remove' }),
      onClick: wrap(item => handleDeleteUser(item, !!userToPerformActionOn)),
      visible: wrap(item => authorize(PERMISSIONS.USERS.DELETE) && item.id !== currentUser.id),
    },
    {
      id: 11,
      label: intl.formatMessage({ id: 'Global.Inactivate' }),
      onClick: wrap(item => handleStatusChange(item, Status.INACTIVE)),
      visible: wrap(
        item =>
          (item.status === Status.ACTIVE || item.status === Status.PENDING) &&
          item.id !== currentUser.id &&
          !item.isBlocked,
      ),
    },
    {
      id: 12,
      label: intl.formatMessage({ id: 'Global.Reactivate' }),
      onClick: wrap(item => handleStatusChange(item, Status.ACTIVE)),
      visible: wrap(item => item.status === Status.INACTIVE),
    },
  ];
};
