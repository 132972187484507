import React, { useCallback, useEffect, useMemo } from 'react';
import { FormikContextType } from 'formik';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { FieldCheckbox } from 'components/shared/Fields/FieldCheckbox';
import { FieldRadioButton } from 'components/shared/Fields/FieldRadioButton/FieldRadioButton';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { Section, SectionProps } from 'components/shared/forms/Section/Section';
import { Tooltip } from 'components/shared/Tooltip/Tooltip';
import { getRoleLang } from 'helpers/roles/roles';
import {
  AccessFlag,
  accessFlagsAllowedForRoles,
  accessFlagsDependencies,
  METAL_ABBR,
  superAdminExclusiveFlags,
  UserRoles,
} from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { Sort } from 'shared/constants/sort';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { RoleListItem, Status, UserFormData } from 'shared/types';
import { fetchHedges } from 'store/hedgesSlice';
import { useAppDispatch } from 'store/shared/hooks';
import { theme } from 'theme';
import { useTypedIntl } from '../locale/messages';

type Props = {
  editMode?: boolean;
  activeSection: string;
  setActiveSection: (sectionName: string) => void;
  context: FormikContextType<UserFormData>;
  allowRolesChange?: boolean;
  roles: RoleListItem[];
};

const useCanFlagBeApplied = (chosenRoles: RoleListItem[]): ((access: AccessFlag) => boolean) => {
  const operator = useCurrentUser();

  const isOperatorSuperAdmin = operator.roles.some(r => r.name === UserRoles.SUPER_ADMIN);
  const flagsApplicableByOperator = isOperatorSuperAdmin
    ? Object.values(AccessFlag)
    : operator.accessFlags;

  const chosenRolesNames = chosenRoles.map(p => p.name);
  const flagsFromChosenRoles = chosenRoles.flatMap(p => p.accessFlags);

  return (access: AccessFlag) => {
    if (flagsFromChosenRoles.includes(access)) return true;
    if (!flagsApplicableByOperator.includes(access)) return false;
    if ((superAdminExclusiveFlags as AccessFlag[]).includes(access) && !isOperatorSuperAdmin)
      return false;
    return accessFlagsAllowedForRoles.some(
      ([a, r]) => a.includes(access) && r.some(p => chosenRolesNames.includes(p)),
    );
  };
};

type RoleSectionProps = Omit<SectionProps, 'children'> & {
  accessFlags: AccessFlag[];
  context: FormikContextType<UserFormData>;
  userRoles: RoleListItem[];
};

const RoleSection = ({
  userRoles,
  accessFlags,
  context,
  ...sectionProps
}: RoleSectionProps): React.ReactElement => {
  const canFlagBeApplied = useCanFlagBeApplied(context.values.roles.chosenRoles);
  return (
    <Section {...sectionProps}>
      {accessFlags.filter(canFlagBeApplied).map(flag => (
        <RoleCheckbox key={flag} context={context} access={flag} userRoles={userRoles} />
      ))}
    </Section>
  );
};

const RoleCheckbox = ({
  access,
  context,
  userRoles,
}: {
  access: AccessFlag;
  context: FormikContextType<UserFormData>;
  userRoles: RoleListItem[];
}): React.ReactElement => {
  const intl = useTypedIntl();
  const flagsFromRoles = userRoles
    .filter(({ id }) => context.values.roles.roles.includes(id))
    .flatMap(p => p.accessFlags);
  const chosenAccessFlags = context.values.roles.userAccessFlags;
  const flagDeliveredFromRole = flagsFromRoles.includes(access);
  const dependencyRequired = !!accessFlagsDependencies[access]?.some(
    dependency => !chosenAccessFlags?.[dependency] && !flagsFromRoles.includes(dependency),
  );

  return (
    <FieldCheckbox
      name={`roles.userAccessFlags.${access}`}
      value={access}
      onChange={context.handleChange}
      onBlur={context.handleBlur}
      size="22px"
      disabled={flagDeliveredFromRole || dependencyRequired}
      checked={
        (context.values.roles.userAccessFlags?.[access] && !dependencyRequired) ||
        flagDeliveredFromRole
      }
    >
      <Tooltip
        tooltip={intl.formatMessage({ id: `UserForm.Access.Flag.${access}.Description` })}
        placement="top"
        bgColor={theme.colors.mineShaftLighter}
      >
        {intl.formatMessage({ id: `UserForm.Access.Flag.${access}` })}*
      </Tooltip>
    </FieldCheckbox>
  );
};

export const UserRolesForm = ({
  editMode = false,
  allowRolesChange = true,
  context,
  activeSection,
  setActiveSection,
  roles,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();

  const { values, touched, getFieldMeta, setFieldValue, errors } = context;

  useEffect(() => {
    if (!editMode) return;

    const canFilter = currentUser.roles.some(r =>
      [UserRoles.SUPER_ADMIN, UserRoles.SISTER_COMPANY_ADMIN].includes(r.name),
    );
    const relatedCompanies = [values.info.company];

    dispatch(
      fetchHedges(0, 0, {
        status: Status.ACTIVE,
        ...(canFilter && { relatedCompanies }),
        orderBy: HedgeOrderBy.NAME,
        sort: Sort.ASC,
      }),
    );
  }, [values.info.company, editMode]);

  const roleOptions = useMemo(
    () => roles.map(role => ({ label: getRoleLang(role.name, intl), value: role.id })),
    [roles],
  );

  useEffect(() => {
    if (editMode || !!touched.roles?.privileges?.additionalDevice) return;
    setFieldValue(
      'roles.privileges.additionalDevice',
      values.roles.chosenRoles
        .some(role => [UserRoles.SUPER_ADMIN, UserRoles.SISTER_COMPANY_ADMIN].includes(role.name))
        .toString(),
    );
  }, [values.roles?.chosenRoles, touched.roles?.privileges?.additionalDevice, editMode]);

  useDeepCompareEffect(() => {
    const chosenFlags = context?.values?.roles?.userAccessFlags;
    const flagsFromRoles = roles
      .filter(({ id }) => context?.values?.roles?.roles?.includes(id))
      .flatMap(p => p.accessFlags);
    Object.entries(accessFlagsDependencies).forEach(([dependent, requirements]) => {
      if (
        chosenFlags?.[dependent] &&
        requirements.every(
          requirement => !chosenFlags[requirement] && !flagsFromRoles.includes(requirement),
        )
      ) {
        context.setFieldValue(`roles.userAccessFlags.${dependent}`, false);
      }
    });
  }, [context?.values?.roles?.userAccessFlags, context?.values?.roles?.roles]);

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(`roles.${name}`);
    return fieldTouched && error;
  };

  const handleRolesChange = useCallback(() => {
    const shouldChangeTermsAdjustmentVal = metalAbbr => {
      const fieldName = `prices.${metalAbbr}TermsAdjustment`;
      return !values[fieldName] || errors[fieldName];
    };

    if (shouldChangeTermsAdjustmentVal(METAL_ABBR.PT)) setFieldValue('prices.ptTermsAdjustment', 0);
    if (shouldChangeTermsAdjustmentVal(METAL_ABBR.PD)) setFieldValue('prices.pdTermsAdjustment', 0);
    if (shouldChangeTermsAdjustmentVal(METAL_ABBR.RH)) setFieldValue('prices.rhTermsAdjustment', 0);
  }, [
    values.prices.ptTermsAdjustment,
    values.prices.pdTermsAdjustment,
    values.prices.rhTermsAdjustment,
    errors?.['values.prices.ptTermsAdjustment'],
    errors?.['values.prices.pdTermsAdjustment'],
    errors?.['values.prices.rhTermsAdjustment'],
    roles,
  ]);

  const canFlagBeApplied = useCanFlagBeApplied(context.values.roles.chosenRoles);

  useEffect(() => {
    const applicableFlags = Object.entries(values.roles.userAccessFlags).reduce(
      (acc, [flag, value]) => {
        acc[flag] = canFlagBeApplied(flag as AccessFlag) && value;
        return acc;
      },
      {},
    );

    context.setFieldValue('roles.userAccessFlags', applicableFlags);
  }, [values.roles.chosenRoles]);

  const roleSections: Pick<RoleSectionProps, 'sectionName' | 'label' | 'accessFlags'>[] = [
    {
      sectionName: 'assayAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Assay' }),
      accessFlags: [AccessFlag.ASSAY_VIEWER, AccessFlag.ASSAY_EDITOR],
    },
    {
      sectionName: 'companyAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Companies' }),
      accessFlags: [AccessFlag.COMPANY_MANAGER],
    },
    {
      sectionName: 'converterAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Converters' }),
      accessFlags: [
        AccessFlag.CONVERTER_VIEWER,
        AccessFlag.PARTIAL_CONVERTER_MANAGER,
        AccessFlag.FOIL_VIEWER,
        AccessFlag.SHOPPING_CART_VIEWER,
      ],
    },
    {
      sectionName: 'dashboardAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Dashboard' }),
      accessFlags: [AccessFlag.DASHBOARD_VIEWER],
    },
    {
      sectionName: 'geolocationAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Geolocation' }),
      accessFlags: [AccessFlag.GEOLOCATION_MANAGER],
    },
    {
      sectionName: 'hedgeAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Hedges' }),
      accessFlags: [
        AccessFlag.HEDGE_BASIC_VIEWER,
        AccessFlag.HEDGE_EDITOR,
        AccessFlag.HEDGE_PRICE_VIEWER,
      ],
    },
    {
      sectionName: 'nonStandardConverters',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.NonStandardConverters' }),
      accessFlags: [
        AccessFlag.NON_STANDARD_CONVERTER_VIEWER,
        AccessFlag.NON_STANDARD_CONVERTER_EDITOR,
      ],
    },
    {
      sectionName: 'settingsAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Settings' }),
      accessFlags: [AccessFlag.SETTINGS_MANAGER],
    },
    {
      sectionName: 'statisticsAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Statistics' }),
      accessFlags: [AccessFlag.STATISTICS_VIEWER],
    },
    {
      sectionName: 'usersAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Users' }),
      accessFlags: [AccessFlag.USER_MANAGER, AccessFlag.USER_DELETER],
    },
    {
      sectionName: 'vehicles',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Vehicles' }),
      accessFlags: [
        AccessFlag.VEHICLE_BASIC_VIEWER,
        AccessFlag.VEHICLE_ADVANCED_VIEWER,
        AccessFlag.VEHICLE_CREATOR,
        AccessFlag.VEHICLE_EDITOR,
      ],
    },
    {
      sectionName: 'reportsAccess',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Reports' }),
      accessFlags: [AccessFlag.REPORT_BASIC_GENERATOR, AccessFlag.REPORT_ADVANCED_GENERATOR],
    },
    {
      sectionName: 'feedbacks',
      label: intl.formatMessage({ id: 'UserForm.Access.Flag.Module.Feedbacks' }),
      accessFlags: [AccessFlag.FEEDBACK_MANAGER],
    },
  ].filter(p => p.accessFlags.some(canFlagBeApplied));

  return (
    <FormInWizard
      context={context}
      header={intl.formatMessage({ id: editMode ? 'UserForm.UpdateHeader' : 'UserForm.Header' })}
    >
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="roles"
        label={intl.formatMessage({ id: 'UserForm.Section.Roles' })}
        template={['roles.roles roles.roles']}
      >
        <FieldSelect
          label={intl.formatMessage({ id: 'UserForm.Role' })}
          name="roles.roles"
          value={values.roles?.roles}
          error={getErrors('roles')}
          onChange={handleRolesChange}
          options={roleOptions}
          multi
          required
          disabled={!roleOptions?.length || !allowRolesChange}
        />
      </Section>
      {roleSections.map(roleSection => (
        <RoleSection
          key={roleSection.sectionName}
          setActive={setActiveSection}
          activeSection={activeSection}
          context={context}
          userRoles={roles}
          {...roleSection}
        />
      ))}

      <Section
        sectionName="privileges"
        setActive={setActiveSection}
        activeSection={activeSection}
        label={intl.formatMessage({ id: 'UserForm.Privileges' })}
        template={['roles.privileges.additionalDevice roles.privileges.additionalDevice']}
      >
        <FieldRadioButton
          label={intl.formatMessage({ id: 'UserForm.additionalDevice' })}
          name="roles.privileges.additionalDevice"
          value={values.roles.privileges.additionalDevice}
          onChange={context.handleChange}
          onBlur={context.handleBlur}
          options={[
            { label: intl.formatMessage({ id: 'Global.Yes' }), value: 'true' },
            { label: intl.formatMessage({ id: 'Global.No' }), value: 'false' },
          ]}
          data-cy="privileges-additional-device"
          required
        />
      </Section>
    </FormInWizard>
  );
};
