import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { isNil } from 'lodash';

import infoIcon from 'assets/images/icons/info.svg';
import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { withFieldWrapper } from 'components/shared/Fields/FieldWrapper/FieldWrapper';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { Rate } from 'components/shared/Rating/Rate';
import { QuestionnaireStatus } from 'shared/constants';
import { compareCurrentHour } from 'shared/helpers';
import { useMediaQuery } from 'shared/hooks';
import {
  useCancelQuestionnaire,
  useFillUpQuestionnaire,
  useSkipQuestionnaire,
} from 'shared/mutations';
import { useGetQuestionnaireStatus } from 'shared/queries';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import {
  hideModal as hideConfirmationModal,
  showModal as showConfirmationModal,
} from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, MEDIA_QUERY } from 'theme';
import {
  ButtonsContainer,
  MessageIcon,
  MessageInfo,
  ModalFormButtonContainer,
  ModalFormContent,
  RateContainer,
  RateInfo,
  RateInfoContainer,
  SubmitButton,
} from './QuestionnaireModal.styles';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const FieldRate = withFieldWrapper(Rate);

function QuestionnaireModalComponent(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const authData = useAppSelector(store => store.auth);
  const config = useAppSelector(store => store.config);
  const [rating, setRating] = useState<number | null>(null);
  const [message, setMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const cancelQuestionnaire = useCancelQuestionnaire();
  const skipQuestionnaire = useSkipQuestionnaire();
  const fillUpQuestionnaire = useFillUpQuestionnaire();
  const questionnaireStatus = useGetQuestionnaireStatus({ enabled: false, retry: false });
  const skipTimeoutRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (!config?.questionnaire || !authData.user) {
      return;
    }

    if (compareCurrentHour(config.questionnaire.checkHour)) {
      questionnaireStatus.refetch();
    }
    const interval = setInterval(() => {
      if (compareCurrentHour(config.questionnaire!.checkHour)) {
        questionnaireStatus.refetch();
      }
    }, config.questionnaire.checkIntervalInMinutes * 60000);

    return () => {
      clearInterval(interval);
      skipTimeoutRef.current && clearTimeout(skipTimeoutRef.current);
    };
  }, [
    config.questionnaire?.checkIntervalInMinutes,
    config.questionnaire?.checkHour,
    authData.user,
  ]);

  useEffect(() => {
    setIsModalOpen(questionnaireStatus.data !== null);
  }, [questionnaireStatus.data]);

  useEffect(() => {
    if (authData.user && authData.loggedInInThisSession) {
      questionnaireStatus.refetch();
    }
  }, [authData.user, authData.loggedInInThisSession]);

  const closeModal = () => {
    questionnaireStatus.remove();
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (isNil(rating)) return;
      await fillUpQuestionnaire.mutateAsync({
        rating,
        message,
      });
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Feedback.Questionnaire.Success' })));
      closeModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  const handleSkip = async () => {
    try {
      await skipQuestionnaire.mutateAsync();
      skipTimeoutRef.current = setTimeout(() => {
        questionnaireStatus.refetch();
      }, config.questionnaire!.skipTimeInMinutes * 60000);
      closeModal();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  const handleCancel = async () => {
    dispatch(
      showConfirmationModal({
        message: intl.formatMessage({ id: 'Feedback.Questionnaire.ConfirmCancel' }),
        closeMessage: intl.formatMessage({ id: 'Feedback.Questionnaire.ConfirmCancel.No' }),
        confirmMessage: intl.formatMessage({ id: 'Feedback.Questionnaire.ConfirmCancel.Yes' }),
        onClose: () => dispatch(hideConfirmationModal()),
        onConfirm: async () => {
          try {
            await cancelQuestionnaire.mutateAsync();
            closeModal();
          } catch (error) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
          dispatch(hideConfirmationModal());
        },
      }),
    );
  };
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const submitDisabled = rating === null;
  const isSkipAvailable = questionnaireStatus.data === QuestionnaireStatus.REQUESTED;

  return (
    <ReactModal
      isOpen={Boolean(authData.user) && isModalOpen}
      style={ReactModalStyle(isPhone, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <ModalFormContent data-cy="questionnaire-content" data-guide-blocker>
        <FormattedMessage id="Feedback.Questionnaire.Header" />
        <RateContainer>
          <FieldRate
            onChange={setRating}
            value={rating}
            label={intl.formatMessage({ id: 'Feedback.Questionnaire.Rate' })}
            required
          />
          <RateInfoContainer data-cy="questionnaire-star-info">
            <RateInfo>
              <FormattedMessage id="Feedback.Questionnaire.OneStar" />
            </RateInfo>
            <RateInfo>
              <FormattedMessage id="Feedback.Questionnaire.FiveStars" />
            </RateInfo>
          </RateInfoContainer>
        </RateContainer>
        {rating !== null && (
          <MessageInfo data-cy="questionnaire-message-info">
            <MessageIcon icon={infoIcon} size="18px" />
            {rating >= 3 ? (
              <FormattedMessage id="Feedback.Questionnaire.InfoGood" />
            ) : (
              <FormattedMessage id="Feedback.Questionnaire.InfoBad" />
            )}
          </MessageInfo>
        )}
        <FieldTextArea
          label={intl.formatMessage({ id: 'Feedback.Questionnaire.Comment' })}
          name="message"
          onChange={event => setMessage(event.target.value)}
          value={message}
          data-cy="questionnaire-message"
          maxLength={5000}
        />
        <ModalFormButtonContainer isSkipAvailable={isSkipAvailable}>
          {isSkipAvailable && (
            <AppButton styleType="neutral-empty" onClick={handleSkip} data-cy="questionnaire-skip">
              <FormattedMessage
                id="Feedback.Questionnaire.Skip"
                values={{ minutes: config.questionnaire?.skipTimeInMinutes }}
              />
            </AppButton>
          )}
          <ButtonsContainer>
            <AppButton
              styleType="neutral-empty"
              onClick={handleCancel}
              data-cy="questionnaire-cancel"
            >
              <FormattedMessage id="Global.Cancel" />
            </AppButton>
            <SubmitButton isSkipAvailable={isSkipAvailable}>
              <AppButton
                data-cy="questionnaire-submit"
                disabled={submitDisabled}
                onClick={handleSubmit}
              >
                <FormattedMessage id="Global.Submit" />
              </AppButton>
            </SubmitButton>
          </ButtonsContainer>
        </ModalFormButtonContainer>
      </ModalFormContent>
    </ReactModal>
  );
}

export const QuestionnaireModal = withAlphamartIntlProvider(QuestionnaireModalComponent, messages);
