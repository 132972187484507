import { ApiFile } from 'shared/types';

export interface ConvertersGroupDto {
  converters: {
    id: number;
  }[];
  files: Pick<ApiFile, 'externalId' | 'fileName' | 'id' | 'mimetype' | 'sort'>[];
}

// eslint-disable-next-line
export const parseConvertersGroup = (values: any, fileIds: any[] = []): ConvertersGroupDto => ({
  converters: values.converters?.map(({ id }) => ({
    id,
  })),
  files: values.files.map((photo, index) => ({
    id: photo.id,
    externalId: fileIds[index]?.data ?? photo.externalId,
    fileName: photo.name ?? photo.fileName,
    mimetype: photo.file?.type ?? photo.mimetype,
    sort: index,
    ...(fileIds[index]?.id ? { id: fileIds[index].id } : {}),
  })),
});
