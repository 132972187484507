import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import ghostIcon from 'assets/images/icons/ghost.svg';
import wheelIcon from 'assets/images/icons/wheel.svg';
import { Icon } from 'components/shared/Buttons';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { InfoPageContainer } from 'components/shared/InfoPageContainer';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useDefaultRedirect, useExtendedTheme, useMediaQuery } from 'shared/hooks';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const shrink = (color: string): string => keyframes`
  0% {
    transform: scale(1) translateY(-20px);
    background-color: ${color}99;
    box-shadow: 0 0 0 0 ${color}99;
  }
  50% {
    transform: scale(1.2) translateY(0px);
    background-color: ${color}44;
    box-shadow: 0 0 9px 0 ${color}44;
  }
  100% {
    transform: scale(1) translateY(-20px);
    background-color: ${color}99;
    box-shadow: 0 0 0 0 ${color}99;
  }
`;

const Ghost = styled.div<{
  top: number | string;
  left: number | string;
  color: string;
}>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  height: fit-content;

  animation: ${float} 6s ease-in-out infinite;

  &::before {
    content: '';
    width: 88px;
    height: 22px;
    border-radius: 50%;
    background-color: ${({ color }) => color}99;
    position: absolute;
    bottom: -8px;
    left: 4px;

    animation: ${({ color }) => shrink(color)} 6s ease-in-out infinite;
  }
`;

function NotFound(): React.ReactElement {
  const theme = useExtendedTheme();
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_MD);
  const config = useAppSelector(store => store.config);
  const redirectTo = useDefaultRedirect();

  const goBack = () => window.location.replace(redirectTo);

  return (
    <InfoPageContainer theme={theme}>
      <div>
        4
        <Icon icon={wheelIcon} size={isPhone ? '128px' : '240px'} color={theme.colors.whisper} />4
      </div>
      <p>
        <FormattedMessage id="Global.PageNotFound" />
      </p>
      {!config.error && (
        <AppButton onClick={goBack} data-cy="go-back-btn">
          <FormattedMessage id="Global.TakeMeHome" />
        </AppButton>
      )}
      <Ghost top={0} left="70%" color={theme.mainColor}>
        <Icon icon={ghostIcon} size="96px" color={theme.mainColor} />
      </Ghost>
      <Ghost top="10%" left={isPhone ? '10%' : '20%'} color={theme.mainColor}>
        <Icon icon={ghostIcon} size="96px" color={theme.mainColor} />
      </Ghost>
      <Ghost top={isPhone ? '70%' : '50%'} left="65%" color={theme.mainColor}>
        <Icon icon={ghostIcon} size="96px" color={theme.mainColor} />
      </Ghost>
    </InfoPageContainer>
  );
}

export { NotFound };
