import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

export enum LocationKeys {
  GetCurrentIpLocation = 'GetCurrentIpLocation',
}

export interface LocationResponse {
  location: {
    lat: number;
    lng: number;
  };
  accuracy: number;
}

export const useGetCurrentIpLocation = (
  apiKey?: string,
  options: UseQueryOptions = {},
): UseQueryResult<LocationResponse> =>
  useQuery(
    [LocationKeys.GetCurrentIpLocation, apiKey],
    async () => {
      const response = await axios.post<LocationResponse>(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`,
        { considerId: true },
      );
      return response.data;
    },
    // eslint-disable-next-line
    options as any,
  );
