import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { MEDIA_QUERY, theme } from 'theme';

export const DisplaySettingsMain = styled.div`
  display: flex;
  height: auto;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  align-items: stretch;

  @media ${MEDIA_QUERY.MAX_MD} {
    height: 100%;
    min-height: unset;
    max-height: unset;
  }
`;

export const DisplaySettingsSidebar = styled.div`
  min-width: 330px;
  width: 330px;
  min-height: 100vh;
  background: ${theme.colors.mineShaft};
  overflow-y: scroll;

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100%;
  }
`;

export const DisplaySettingsFieldSection = styled.div`
  padding: 30px 53px;
  border-bottom: 1px solid ${theme.colors.mineShaftLighter};

  &:last-child {
    border-bottom: none;
  }

  & > div:nth-child(odd):not(:first-child) {
    margin-top: 20px;
  }
`;

export const DisplaySettingsFieldSectionTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${theme.colors.alphaGray};
  margin: 0 0 20px 0;
  font-family: ${theme.fonts.lato};
`;

export const DisplaySettingsFieldButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
`;

export const DisplaySettingsTitle = styled.div`
  background: ${theme.colors.mineShaftLighter};
  height: 108px;
  padding: 0 45px;
  display: flex;
  align-items: center;
`;

export const DisplaySettingsMockupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-grow: 1;
  overflow: hidden;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;
  }
`;

export const clearSettingsButton = css`
  font-size: 12px;
  padding: 15px;
  border-radius: 8px;
  margin-top: 16px;

  &::before {
    border-radius: 8px;
  }
`;
