import React, { useEffect, useState } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { JSXToStaticHTML } from 'shared/helpers';
import { useExtendedTheme } from 'shared/hooks';
import { UserLocationMarker } from 'shared/types';
import { MapUserInfo } from '../CustomMapComponents';
import { generateCircle, getMarkerIcon } from '../CustomMapComponents/generateIcons';
import { MapUserInfoContent } from '../CustomMapComponents/MapUserInfo.styles';

export const useMarkerClustering = (
  map: google.maps.Map | null,
  markers: (UserLocationMarker | UserLocationMarker[])[],
): void => {
  const theme = useExtendedTheme();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const [nativeClusterer, setNativeClusterer] = useState<MarkerClusterer>();
  const [infoWindowMarker, setInfoWindowMarker] = useState<google.maps.Marker>();
  const [activeMarker, setActiveMarker] = useState<number[]>([]);
  const [nativeMarkers, setNativeMarkers] = useState<
    { marker: UserLocationMarker[]; native: google.maps.Marker }[]
  >([]);

  useEffect(() => {
    if (!map) return;

    const newClusterer = new MarkerClusterer({
      map,
      renderer: {
        render: ({ count, position }) =>
          new google.maps.Marker({
            position,
            icon: { url: generateCircle('white', 0.95), scaledSize: new google.maps.Size(45, 45) },
            label: { text: String(count), color: 'black', fontSize: '12px', fontWeight: 'bold' },
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          }),
      },
    });
    setNativeClusterer(newClusterer);

    const newInfoWindowMarker = new google.maps.Marker({
      map,
      icon: { url: generateCircle('white', 0), scaledSize: new google.maps.Size(45, 45) },
    });
    setInfoWindowMarker(newInfoWindowMarker);

    const newInfoWindow = new google.maps.InfoWindow();
    setInfoWindow(newInfoWindow);

    map.addListener('click', () => {
      newInfoWindow.close();
      newInfoWindowMarker.setVisible(false);
      setActiveMarker([]);
    });
  }, [map]);

  useEffect(() => {
    if (!(map && nativeClusterer && infoWindow && infoWindowMarker)) return;

    const normalizedMarkers =
      markers?.map(marker => ([] as UserLocationMarker[]).concat(marker)) ?? [];

    const newMarkers = normalizedMarkers.map(marker => {
      const isActive = !activeMarker.length || marker.some(m => activeMarker.includes(m.user.id));

      const native = new google.maps.Marker({
        position: marker[0].position,
        icon: { url: getMarkerIcon(marker, isActive, theme) },
        clickable: true,
        optimized: true,
      });

      native.addListener('click', (e: google.maps.IconMouseEvent) => {
        setActiveMarker(marker.map(({ user }) => user.id));
        infoWindow.setContent(
          JSXToStaticHTML(
            <MapUserInfoContent theme={theme}>
              {marker.map(m => (
                <MapUserInfo key={m.id} marker={m} />
              ))}
            </MapUserInfoContent>,
          ),
        );
        infoWindowMarker.setVisible(true);
        infoWindowMarker.setPosition(e.latLng);
        infoWindow.open({ anchor: infoWindowMarker, map, shouldFocus: false });
      });

      return { marker, native };
    });

    nativeClusterer.clearMarkers();
    nativeClusterer.addMarkers(newMarkers.map(({ native }) => native));

    setNativeMarkers(newMarkers);
  }, [markers, !!(map && nativeClusterer && infoWindow && infoWindowMarker)]);

  useEffect(() => {
    nativeMarkers.forEach(({ marker, native }) => {
      const isActive = !activeMarker.length || marker.some(m => activeMarker.includes(m.user.id));
      native.setIcon({ url: getMarkerIcon(marker, isActive, theme) });
    });
  }, [activeMarker?.join()]);
};
