import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const Controls = styled.div<{ theme?: Theme }>`
  display: flex;
  padding: 32px 24px;
  justify-content: space-between;

  button > span {
    background-color: ${({ theme }) => theme.mainColor};
  }
`;

export const eraseBtnStyles = (showActive: boolean): SerializedStyles => css`
  color: white;
  height: 32px;
  width: 132px;
  padding: 8px;
  padding-left: 4px;
  margin: 24px 0;
  justify-content: flex-start;
  background-color: ${showActive ? '#4E4E4E' : 'transparent'};
  transition: background-color 0.2s ease;

  &::before {
    border: none;
  }

  &:focus:not(:disabled) {
    background-color: ${showActive ? '#4E4E4E' : 'transparent'};
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background-color: ${showActive ? '#4E4E4E' : '#4e4e4e'};
  }

  & > span {
    background-color: white;
    margin-right: 12px;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 0 8px;
  }
`;

export const drawingPanelStyles = (theme?: Theme): SerializedStyles => css`
  position: fixed;
  left: ${theme?.nav.thin};
  z-index: 2;
  height: 100vh;
  width: 330px;
  background-color: ${theme?.colors.mineShaft};
  overflow-y: auto;

  header {
    height: 108px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 48px;
    background-color: ${theme?.colors.mineShaftLighter};
    color: ${theme?.colors.white};
    font-size: 18px;

    @media ${MEDIA_QUERY.MAX_SM} {
      height: 48px;
    }
  }

  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;

    &:checked + label {
      background-color: ${theme?.colors.mineShaftLightest};
    }
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 132px;
    height: 32px;
    margin: 16px 0;
    border-radius: 16px;
    font-size: 12px;
    padding: 8px;

    span {
      margin-right: 16px;
      background-color: ${theme?.colors.white};
    }
  }

  .navigate-map {
    padding: 16px 32px 0;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    left: 0;
  }

  @media ${MEDIA_QUERY.MAX_SM} {
    bottom: 0;
    width: 100vw;
    height: 30vh;
    overflow-y: scroll;
  }

  @media ${MEDIA_QUERY.MOBILE_LANDSCAPE} {
    height: calc(100vh - 64px);
  }
`;
