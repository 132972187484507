import * as React from 'react';
import { ErrorInfo } from 'react';
import { connect } from 'react-redux';

import { Forbidden } from 'components/views/Forbidden/Forbidden';
import { NotFound } from 'components/views/NotFound/NotFound';
import { apiHostname } from 'shared/constants';
import { HttpStatus } from 'shared/constants/httpStatus';
import { getHttpClient } from 'shared/utils/httpClient';
import { ErrorState } from 'store/errorsSlice';
import { clear } from 'store/shared/clearStore';
import { Theme } from 'theme';
import { ErrorPage } from '../ErrorPage/ErrorPage';

interface ErrorBoundaryProps {
  clearAction: () => void;
  theme: Theme;
  errors: ErrorState;
  children: React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundaryBase extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { clearAction } = this.props;

    console.error(error, info);
    this.submitError(error, info);

    clearAction();
  }

  private async submitError(error: Error, info: ErrorInfo) {
    const url = window.location.href;
    await getHttpClient().post(
      `${apiHostname}/api/logs`,
      { error, info, url },
      { validateStatus: () => true },
    );
  }

  render() {
    const { hasError } = this.state;
    const { children, theme, errors } = this.props;

    if (hasError) return <ErrorPage theme={theme} />;
    if (errors.httpStatus === HttpStatus.NOT_FOUND) return <NotFound />;
    if (errors.httpStatus === HttpStatus.FORBIDDEN_ACCESS) return <Forbidden />;

    return children;
  }
}

const mapDispatchToProps = {
  clearAction: clear,
};

const mapStateToProps = state => ({
  theme: state.config?.theme,
  errors: state.errors,
});

const ErrorBoundary = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryBase);

export { ErrorBoundary };
