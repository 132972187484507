import logoDesktop from 'assets/images/logo-alpha.png';
import logoMobile from 'assets/images/mobile-logo-alpha.png';
import { useAppSelector } from 'store/shared/hooks';
import { Theme, theme } from 'theme';

type ExtendedTheme = Theme & {
  mainColor: string;
  navigationBarColor: string;
  logo: string;
  icon: {
    small: string;
    large: string;
  };
  watermarkOpacity: number;
};

export function useExtendedTheme(): ExtendedTheme {
  const config = useAppSelector(store => store.config);

  return {
    ...theme,
    ...(config.theme && {
      mainColor: config.theme.mainColor || theme.mainColor,
      navigationBarColor: config.theme.navigationBarColor,
      logo: config.theme.logo ? config.theme.logo.presignedUrls!.ORIGINAL : logoDesktop,
      icon: config.theme.icon
        ? {
            small: config.theme.icon.presignedUrls!.ICON_SMALL,
            large: config.theme.icon.presignedUrls!.ICON_LARGE,
          }
        : {
            small: logoMobile,
            large: logoMobile,
          },
      watermarkOpacity: config.theme.watermarkOpacity,
    }),
  } as ExtendedTheme;
}
