import React from 'react';

import { DetailsSection } from 'components/shared/Details';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatDeviceData } from 'helpers/devices/formatDeviceData';
import { UserDetailsLocation } from 'shared/types';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

interface Props {
  location?: UserDetailsLocation;
}

export function UserDetailsLocations({ location }: Props): React.ReactElement | null {
  return location ? (
    <DetailsSection
      title={<FormattedMessage id="UserDetails.LastLocation" />}
      fields={[
        {
          name: 'date',
          label: <FormattedMessage id="UserDetails.LastLocation.Date" />,
          value: formatDateWithTime(location.createdAt),
        },
        {
          name: 'device',
          label: <FormattedMessage id="UserDetails.LastLocation.Device" />,
          value: formatDeviceData(
            location.device?.name,
            location.device?.os,
            location.device?.browserFamily,
          ),
        },
        {
          name: 'address',
          label: <FormattedMessage id="UserDetails.LastLocation.Location" />,
          value: location.formattedAddress,
        },
      ]}
    />
  ) : null;
}
