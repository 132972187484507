import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';

import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { LoadableContent } from 'components/shared/Loader';
import { removeChecker } from 'helpers/objects/removeChecker';
import { lambdaHostname } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { BooleanString } from 'shared/types';
import { clearAssay, fetchAssay } from 'store/assayDetailsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { AssayFormShape, CreateAssay } from '../CreateAssay/CreateAssay';

const UpdateAssay = (): React.ReactElement => {
  const assayId = +useParams<{ id: string }>().id!;
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const { assay, isPending } = useAppSelector(({ assayDetails }) => assayDetails);
  const { position } = useAppSelector(state => state.position);

  if (assay && removeChecker(assay)) {
    navigate('/assays');
  }

  useEffect(() => {
    const triggerDispatch = async () => {
      await dispatch(fetchAssay(assayId, position));
    };

    triggerDispatch();

    return () => {
      dispatch(clearAssay());
    };
  }, [assayId]);

  const initialValues: AssayFormShape | null = assay && {
    converter: {
      identifier: assay.converter.identifier,
      identifierConfirmation: assay.converter.identifier,
      partName: assay.converter.partName || '',
      nicknames: assay.converter.nicknames || '',
      otherNumbers:
        assay.converter.otherNumbers?.map(num => ({
          value: String(num.original),
        })) || [],
      notes: assay.converter.notes || '',
      photos:
        assay.converter.files?.map(
          ({ id, fileName, mimetype, presignedUrls, externalId }) =>
            ({
              file: { name: fileName, type: mimetype },
              name: fileName,
              size: 1,
              image: `${lambdaHostname}${presignedUrls?.SMALL}`,
              externalId,
              id,
            } as PhotoShape),
        ) || [],
      vehicle: {
        make: assay.converter.make || '',
        model: assay.converter.model || '',
        year: assay.converter.year || null,
      },
      type: assay.converter.type?.id || null,
      folder: assay.converter.folder?.id || null,
      isPartial: (assay.converter.isPartial?.toString() as BooleanString) || 'false',
      counterfeit: assay.converter.counterfeit || false,
    },
    sampleName: assay.sampleName || '',
    sampleDate: assay.sampleDate ? new Date(assay.sampleDate) : new Date(),
    wetWeight: isNil(assay.weightWetGrams) ? '' : String(assay.weightWetGrams),
    confirmWetWeight: isNil(assay.weightWetGrams) ? '' : String(assay.weightWetGrams),
    moisture: isNil(assay.moisturePercentage) ? '' : String(assay.moisturePercentage),
    confirmMoisture: isNil(assay.moisturePercentage) ? '' : String(assay.moisturePercentage),
    carbon: isNil(assay.carbonPercentage) ? '' : String(assay.carbonPercentage),
    platinum: isNil(assay.platinumPpm) ? '' : String(assay.platinumPpm),
    confirmPlatinum: isNil(assay.platinumPpm) ? '' : String(assay.platinumPpm),
    palladium: isNil(assay.palladiumPpm) ? '' : String(assay.palladiumPpm),
    confirmPalladium: isNil(assay.palladiumPpm) ? '' : String(assay.palladiumPpm),
    rhodium: isNil(assay.rhodiumPpm) ? '' : String(assay.rhodiumPpm),
    confirmRhodium: isNil(assay.rhodiumPpm) ? '' : String(assay.rhodiumPpm),
    notes: assay.notes || '',
  };

  return (
    <LoadableContent loading={isPending}>
      {assay && (
        <CreateAssay
          initialValues={initialValues}
          assayId={assayId}
          converterId={assay.converter.id}
          editMode
        />
      )}
    </LoadableContent>
  );
};

export { UpdateAssay };
