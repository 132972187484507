import React from 'react';

import { DetailsSection } from 'components/shared/Details';
import { UsageThresholds } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

interface Props {
  company?: UsageThresholds;
}

export function CompanyDetailsThresholds({ company }: Props): React.ReactElement {
  const intl = useTypedIntl();
  return (
    <DetailsSection
      title={intl.formatMessage({ id: 'CompanyDetails.Thresholds.Label' })}
      fields={[
        {
          name: 'maxConverterViewsPerDay',
          label: intl.formatMessage({ id: 'CompanyDetails.Thresholds.maxConverterViews' }),
          value: company?.maxConverterViewsPerDay,
        },
        {
          name: 'maxConverterSearchesPerDay',
          label: intl.formatMessage({ id: 'CompanyDetails.Thresholds.maxConverterSearches' }),
          value: company?.maxConverterSearchesPerDay,
        },
      ]}
    />
  );
}
