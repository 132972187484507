import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { isNil, isUndefined } from 'lodash';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { StyledWizardForm } from 'components/shared/forms/Form/StyledForm';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { Controls } from 'components/shared/forms/Wizard/Controls/Controls';
import { FormWrapper } from 'components/shared/forms/Wizard/FormWrapper/FormWrapper';
import { Wizard } from 'components/shared/forms/Wizard/Wizard';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { appendThumbnailLink } from 'helpers/watermarks/appendThumbnailLink';
import { ErrorCode } from 'shared/constants';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useGetVehicle } from 'shared/queries';
import { AlphamartHttpError } from 'shared/types';
import { fetchMakes } from 'store/makesSlice';
import { fetchModels } from 'store/modelsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateVehicle } from 'store/updateVehicleSlice';
import { messages, useTypedIntl } from '../locale/messages';
import {
  vehicleFormSchema,
  VehicleFormShape,
  VehicleFormStepOne,
  VehicleFormStepTwo,
} from '../VehicleForm';

const UpdateVehicleComponent = (): React.ReactElement => {
  const { models } = useAppSelector(state => state.models);
  const { makes } = useAppSelector(state => state.makes);
  const [activeSection, setActiveSection] = useState<string | null | undefined>('');
  const [activeInput, setActiveInput] = useState<string | null>('');
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const { fileSizeLimit, imageExtensions } = useAppSelector(
    state => state.config.upload!.vehiclePhoto,
  );
  const { id: vehicleId } = useParams<{ id: string }>();
  const {
    data: vehicleData,
    isFetching,
    isFetched,
  } = useGetVehicle(vehicleId!, { enabled: !isUndefined(vehicleId) });

  const validationSchema = useMemo(() => {
    if (isFetching) return;
    return vehicleFormSchema(imageExtensions, fileSizeLimit, models, makes, intl);
  }, [imageExtensions, fileSizeLimit, models, makes, isFetching]);

  if (isFetched && !vehicleData) navigate('/vehicles/list');

  const formikContext = useFormik<VehicleFormShape>({
    initialValues: {
      vin: vehicleData?.vin || '',
      make: vehicleData?.make || '',
      model: vehicleData?.model || '',
      year: vehicleData?.year ?? new Date().getFullYear(),
      vehicleType: vehicleData?.vehicleType || '',
      photos:
        vehicleData?.files?.map(
          ({ id, fileName, mimetype, presignedUrls, externalId, source }) =>
            ({
              file: { name: fileName, type: mimetype },
              name: fileName,
              size: 1,
              image: appendThumbnailLink(presignedUrls?.SMALL ?? ''),
              id,
              externalId,
              source,
            } as PhotoShape),
        ) || [],
      engineType: vehicleData?.engineType ?? '',
      engineDisplacement: vehicleData?.engineDisplacement ?? '',
      engineDisplacementConfirmation: vehicleData?.engineDisplacement ?? '',
      transmission: vehicleData?.transmission ?? null,
      enginePower: vehicleData?.enginePower ?? '',
      enginePowerConfirmation: vehicleData?.enginePower ?? '',
      numberOfConverters: vehicleData?.numberOfConverters ?? null,
      numberOfNonstandardConverters: vehicleData?.numberOfNonstandardConverters ?? null,
      converters:
        vehicleData?.converters?.map(({ identifier }, index) => ({
          key: `converter-${index}`,
          identifier,
          identifierConfirm: identifier,
        })) ?? [],
      nonstandardConverters:
        vehicleData?.nonstandardConverters?.map(
          ({ nonstandardConverterId, materialWeight }, index) => ({
            key: `nonstandard-converter-${index}`,
            nonstandardConverterId,
            ...(isNil(materialWeight) ? {} : { materialWeight }),
          }),
        ) || [],
      weight: vehicleData?.weight ?? '',
      weightConfirmation: vehicleData?.weight ?? '',
      numberOfDoors: vehicleData?.numberOfDoors ?? 0,
      notes: vehicleData?.notes ?? '',
    },
    enableReinitialize: true,
    initialTouched: { vin: true },
    validationSchema,
    async onSubmit(values) {
      try {
        if (!vehicleData || !vehicleId) return;
        await dispatch(updateVehicle(values, +vehicleId));
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Global.VehicleUpdate.Success' })));
        navigate(`/vehicles/list/${vehicleData.id}`, { state: location.state });
      } catch (e) {
        const error = e as AlphamartHttpError;
        const vinUniqueChecker = error?.response?.data.errorCode === ErrorCode.VIN_NOT_UNIQUE;
        let errorStatus: string;
        if (vinUniqueChecker) {
          errorStatus = intl.formatMessage({ id: 'Global.VehicleUpdate.VehicleExist' });
        } else {
          errorStatus = intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' });
        }
        dispatch(snackBarPushFailure(errorStatus));
      }
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    getFieldMeta,
    isValid,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    isValidating,
  } = formikContext;

  const getErrors = (name: string): string | false => {
    const { touched: fieldTouched, error } = getFieldMeta(name);

    return (fieldTouched && error) ?? false;
  };

  useEffect(() => {
    dispatch(fetchMakes());
    dispatch(fetchModels());
  }, []);

  const errorFilter = { ...formikContext.errors };
  delete errorFilter.converters;
  delete errorFilter.nonstandardConverters;
  delete errorFilter.numberOfNonstandardConverters;

  return (
    <FormContainer>
      <Wizard>
        <StyledWizardForm onSubmit={formikContext.handleSubmit}>
          <LoadableContent loading={isFetching} mode={LoadableContentModes.FULL} drawContent>
            <FormWrapper pageIndex={1}>
              <VehicleFormStepOne
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                values={values}
                getErrors={getErrors}
                context={formikContext}
                setActiveInput={setActiveInput}
                activeInput={activeInput}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
                touched={touched}
                editMode
              />
            </FormWrapper>
            <FormWrapper pageIndex={2}>
              <VehicleFormStepTwo
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                getErrors={getErrors}
                context={formikContext}
                activeInput={activeInput}
                setFieldValue={setFieldValue}
                setActiveInput={setActiveInput}
                editMode
              />
            </FormWrapper>
            <Controls
              editMode
              stepsErrors={[errorFilter]}
              isValid={isValid}
              touched={touched}
              isValidating={isValidating}
              isSubmitting={isSubmitting}
              lastPageButton={intl.formatMessage({ id: 'VehicleForm.UpdateVehicle' })}
            />
          </LoadableContent>
        </StyledWizardForm>
      </Wizard>
    </FormContainer>
  );
};

export const UpdateVehicle = withAlphamartIntlProvider(UpdateVehicleComponent, messages);
