import React from 'react';
import styled from '@emotion/styled';

import { DetailsSection } from 'components/shared/Details';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatDeviceData } from 'helpers/devices/formatDeviceData';
import { useExtendedTheme } from 'shared/hooks';
import { UserDevice } from 'shared/types';
import { Theme } from 'theme';
import { TypedFormattedMessage, useTypedIntl } from '../locale/messages';

const DeviceField = styled.dl<{ theme: Theme }>`
  margin: 0;

  dt {
    font-weight: bold;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.doveGray};
  }

  dd {
    margin-left: 0;
    margin-bottom: 8px;
  }
`;

export function UserDetailsDevices({
  userDevices,
}: {
  userDevices: UserDevice[];
}): React.ReactElement | null {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();

  return userDevices.length > 0 ? (
    <DetailsSection
      title={intl.formatMessage({ id: 'UserDetails.Devices.Label' })}
      fields={userDevices.map(({ name, os, browserFamily, createdAt, status }, index) => ({
        name: `device${index + 1}`,
        label: null,
        value: (
          <>
            <DeviceField theme={theme}>
              <dt>
                <TypedFormattedMessage id="UserDetails.Devices.OS" />
              </dt>
              <dd>
                <span>{formatDeviceData(name, os, browserFamily)}</span>
              </dd>
            </DeviceField>
            <DeviceField theme={theme}>
              <dt>
                <TypedFormattedMessage id="UserDetails.Devices.Date" />
              </dt>
              <dd>{formatDate(createdAt)}</dd>
            </DeviceField>
            <DeviceField theme={theme}>
              <dt>
                <TypedFormattedMessage id="UserDetails.Devices.Status" />
              </dt>
              <dd>
                <TypedFormattedMessage id={`Global.Status.${status}`} />
              </dd>
            </DeviceField>
          </>
        ),
      }))}
    />
  ) : null;
}
