import React, { useEffect, useState } from 'react';
import isAfter from 'date-fns/isAfter';

const buildDateGreaterThan = (latestDate: number, currentDate: number) => {
  const latestDateTime = new Date(latestDate);
  const currentDateTime = new Date(currentDate);

  return isAfter(latestDateTime, currentDateTime);
};
// eslint-disable-next-line
export function withClearCache(
  Component: React.ComponentType,
): (props: unknown) => React.ReactElement | null {
  function ClearCacheComponent(props): React.ReactElement | null {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState<boolean>(false);

    const refreshCacheAndReload = () => {
      if (caches) {
        caches.keys().then(names => {
          names.map(name => caches.delete(name));
        });
      }

      window.location.reload();
    };

    useEffect(() => {
      fetch('/meta.json')
        .then(response => response.json())
        .then(meta => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = +process.env.REACT_APP_TIMESTAMP;

          const shouldRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
          if (shouldRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    return isLatestBuildDate ? <Component {...props} /> : null;
  }

  return ClearCacheComponent;
}
