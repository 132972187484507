import { Converter } from 'shared/types';

export const validateConverters = (converters: Converter[]): boolean =>
  !converters.every(converter =>
    converters.every(
      c =>
        (!c.make || !converter.make || c.make === converter.make) &&
        (!c.model || !converter.model || c.model === converter.model) &&
        (!c.year || !converter.year || c.year === converter.year),
    ),
  );
