import { apiHostname } from 'shared/constants';
import { serializeLocationHeader } from 'shared/helpers';
import { parseAssay } from 'shared/parsers/parseAssay';
import { Coords } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

export const createAssay =
  (
    values: any, // eslint-disable-line
    position: Coords | null = null,
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const headers = position ? { 'user-location': serializeLocationHeader(position) } : undefined;
      const fileIds: { data: string; id?: number }[] = await Promise.all(
        values.converter.photos.map(photo => {
          const formData = new FormData();
          formData.append('image', photo.file);
          return httpClient.post(`${apiHostname}/api/upload/image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );

      const assayDto = parseAssay(values, fileIds);

      await httpClient.post(`${apiHostname}/api/assays`, assayDto, { headers });
    } catch (error) {
      return Promise.reject(error);
    }
  };
