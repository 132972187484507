import React, { useEffect, useMemo } from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { add, addDays, differenceInDays } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { FieldDatePickerRange } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import { FilterSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import {
  ModalFormButtonContainer,
  ModalFormContent,
} from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent } from 'components/shared/Loader';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import { ModalFormType } from 'shared/constants';
import { useExtendedTheme } from 'shared/hooks';
import { Status } from 'shared/types';
import { getDateString } from 'shared/utils/getDateString';
import { downloadFile } from 'store/downloadFileSlice';
import {
  DownloadCompanyLogsExcelForm,
  DownloadUserLogsForm,
  hideModalForm,
} from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { fetchUsers } from 'store/usersListSlice';
import { layers, Theme } from 'theme';

const DatePickerWrapper = styled.div<{ theme: Theme }>`
  & button:after {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.doveGray};
  }
`;

export const DownloadLogsModal = (): React.ReactElement => {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const users = useAppSelector(state => state.users.list);
  const isFileDownloading = useAppSelector(state => state.downloadFile.isPending);
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const paramsTyped = params as DownloadCompanyLogsExcelForm | DownloadUserLogsForm;
  const dispatch = useAppDispatch();

  const hedgeLogsModal =
    modalType === ModalFormType.HedgeLogs || modalType === ModalFormType.UserHedgeLogs;
  const converterLogsModal =
    modalType === ModalFormType.ConverterLogs || modalType === ModalFormType.UserConverterLogs;
  const userLogsModal =
    modalType === ModalFormType.UserHedgeLogs || modalType === ModalFormType.UserConverterLogs;
  const shouldRenderModal = hedgeLogsModal || converterLogsModal;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        addedAt: Yup.object()
          .shape({
            from: Yup.string().required(),
            to: Yup.string().required(),
          })
          .required(),
        ...(!userLogsModal ? { users: Yup.array().required().min(1).ensure() } : {}),
      }),
    [userLogsModal],
  );

  const formikContext = useFormik<{
    addedAt: { from: Date | null; to: Date | null };
    users: number[] | null;
  }>({
    initialValues: {
      addedAt: { from: null, to: null },
      users: null,
    },
    validationSchema,
    onSubmit: async x => x,
  });

  const submitDisabled = !formikContext.isValid || Object.keys(formikContext.touched).length === 0;
  const handleCancelClick = () => {
    formikContext.resetForm();
    dispatch(hideModalForm());
  };

  const handleConfirmClick = async () => {
    try {
      const { from, to } = formikContext.values.addedAt;
      if (!from || !to) return;
      const usersIds = userLogsModal
        ? [(paramsTyped as DownloadUserLogsForm).userId]
        : formikContext.values?.users;
      const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      const path = hedgeLogsModal ? '/api/hedge-use-logs/export' : '/api/converters/logs';
      const prefix = hedgeLogsModal ? 'hedge' : 'converters';
      await dispatch(
        downloadFile(path, `${prefix}_logs_${formatDateWithTime(new Date())}.xlsx`, {
          addedFrom: getDateString(from),
          addedTo: getDateString(addDays(to, 1)),
          usersIds,
          timezone,
        }),
      );
      dispatch(hideModalForm());
      formikContext.resetForm();
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  useEffect(() => {
    paramsTyped &&
      'companyId' in paramsTyped &&
      shouldRenderModal &&
      dispatch(
        fetchUsers({
          company: paramsTyped.companyId,
          ...(hedgeLogsModal && { status: Status.ACTIVE }),
        }),
      );
  }, [(paramsTyped as DownloadCompanyLogsExcelForm)?.companyId]);

  const mappedUsers = users.map(user => ({
    label: `${user.lastName} ${user.firstName}`,
    value: user.id,
  }));

  const maxDaysDiff =
    formikContext.values?.users?.length && formikContext.values.users.length > 1 ? 6 : 30;
  const maxDate =
    formikContext.values?.addedAt?.from &&
    add(new Date(formikContext.values.addedAt.from), { days: maxDaysDiff });

  useEffect(() => {
    const { from, to } = formikContext.values.addedAt;
    if (from && to && differenceInDays(new Date(to), new Date(from)) > maxDaysDiff) {
      formikContext.setFieldValue('addedAt', { from, to: null });
    }
  }, [maxDaysDiff]);

  return (
    <ReactModal
      isOpen={isOpen && shouldRenderModal}
      style={ReactModalStyle(false, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <ModalFormContent>
        <FormikProvider value={formikContext}>
          {!userLogsModal && (
            <FiltersSection
              noPadding
              label={intl.formatMessage({ id: 'Global.DownloadLogs.Users' })}
            >
              <FilterSelect
                options={mappedUsers}
                value={formikContext.values.users}
                selectAll
                multi
                name="users"
                clearable
                searchable
              />
            </FiltersSection>
          )}

          <FiltersSection
            noPadding
            label={intl.formatMessage({ id: 'Global.DownloadLogs.SelectDateRange' })}
          >
            <DatePickerWrapper theme={theme}>
              <FieldDatePickerRange
                name="addedAt"
                value={formikContext.values.addedAt}
                max={maxDate}
                isClearable
              />
            </DatePickerWrapper>
          </FiltersSection>
          <ModalFormButtonContainer>
            <AppButton styleType="neutral-empty" onClick={handleCancelClick}>
              <FormattedMessage id="Global.Cancel" />
            </AppButton>
            <AppButton disabled={submitDisabled} styleType="ok" onClick={handleConfirmClick}>
              <FormattedMessage id="Global.DownloadLogs.Confirm" />
            </AppButton>
          </ModalFormButtonContainer>
        </FormikProvider>
      </ModalFormContent>
      <LoadableContent mode={LoadableContent.MODE.FULL} loading={isFileDownloading} />
    </ReactModal>
  );
};
