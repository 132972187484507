import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';

import { WizardContext } from 'components/shared/forms/Wizard/Wizard';
import { createFileId } from 'helpers/files/createFileId';
import { bytesToMB } from 'helpers/files/formatSize';
import { FileReadResult, readFile } from 'helpers/files/readFile';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { useTypedIntl } from 'locale/messages';
import { moveArrayItem } from 'shared/helpers';
import { VehicleFileSource } from 'shared/parsers/parseVehicle';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { PhotoPreviewList } from './PhotoPreviewList';
import { ErrorMessage } from '../FieldWrapper/ErrorMessage/ErrorMessage';

interface CommonPhotoShape {
  id?: number | string;
  name?: string;
  externalId?: string;
  source?: VehicleFileSource;
}

export interface PhotoShape extends CommonPhotoShape {
  file?: File;
  image?: string;
  size?: number;
  url?: string;
}

export interface ExternalPhotoShape extends CommonPhotoShape {
  url: string;
}

export type PhotoShapeUnion = PhotoShape | ExternalPhotoShape;

export type PhotoEvent = {
  target: {
    name: string;
    value: PhotoShape[];
  };
};

export const MAX_PHOTOS_COUNT = 10;

interface Props {
  allowedExtensions: string[];
  fileSizeLimit: number;
  initialPhotos?: PhotoShapeUnion[];
  onBlur?: () => void;
  onChange: (event: PhotoEvent) => void;
  onTouch?: (name: string) => void;
  name: string;
  error?: boolean | string;
  single?: boolean;
  sortable?: boolean;
  addPhotoMessage?: ReactNode;
  fetchPhotoMessage?: ReactNode;
  largePreview?: boolean;
  canFetchPhoto?: boolean;
  isFetchingPhoto?: boolean;
  externalPhotoAvailable?: boolean;
  onFetchPhoto?: () => void;
}

const FieldPhotos = ({
  onChange,
  name,
  onBlur,
  onTouch,
  error,
  allowedExtensions,
  initialPhotos,
  single = false,
  sortable = true,
  addPhotoMessage,
  fetchPhotoMessage,
  largePreview,
  fileSizeLimit,
  canFetchPhoto,
  isFetchingPhoto,
  externalPhotoAvailable,
  onFetchPhoto,
}: Props): React.ReactElement => {
  const [photos, setPhotos] = useState<PhotoShapeUnion[]>([]);
  const arrayPhotos = useRef<PhotoShapeUnion[]>([]);
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const { contextPhotos, setContextPhotos } = useContext(WizardContext);
  useEffect(() => {
    if (!isNil(initialPhotos)) {
      setPhotos(initialPhotos);
      setContextPhotos(initialPhotos);
    }
  }, [initialPhotos]);

  useEffect(() => {
    if (photos.length !== 0) {
      setContextPhotos(photos);
    }
  }, [photos]);

  const handleNotifications = (tooBigFiles: string[], addedPhotos: number, limit: number) => {
    if (tooBigFiles.length === 0) {
      return;
    }

    if (tooBigFiles.length === 1 && addedPhotos === 0) {
      dispatch(
        snackBarPushFailure(
          intl.formatMessage(
            {
              id: 'Global.Fields.Photos.Error.FileSizeLimit',
            },
            { limitInMB: bytesToMB(limit) },
          ),
        ),
      );
      return;
    }

    dispatch(
      snackBarPushFailure(
        intl.formatMessage(
          {
            id: 'Global.Fields.Photos.Error.FileSizeLimitMultiple',
          },
          { limitInMB: bytesToMB(limit), omitted: tooBigFiles.join(',') },
        ),
      ),
    );
  };

  const photosMaxCount = single ? 1 : MAX_PHOTOS_COUNT;

  const onInputChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!target.files) return;
      const tooBigFiles: string[] = [];
      const preparedFiles = Array.from(target.files)
        .slice(0, photosMaxCount - photos.length)
        .filter(file => {
          if (file.size > fileSizeLimit) {
            tooBigFiles.push(file.name);
            return false;
          }
          return true;
        })
        .filter(file => !photos.find(photo => photo.id === createFileId(file)));
      const addedPhotos: FileReadResult[] = (await Promise.all(preparedFiles.map(readFile))).filter(
        isTruthy,
      );

      handleNotifications(tooBigFiles, addedPhotos.length, fileSizeLimit);
      if (addedPhotos.length === 0) {
        return;
      }

      const newPhotosSet = [...photos, ...addedPhotos];
      setPhotos(newPhotosSet);
      setContextPhotos(newPhotosSet);
      onBlur?.();
      onChange({
        target: {
          name,
          value: [
            ...photos,
            ...addedPhotos.map(({ file, image }) => ({
              file,
              image,
              name: file.name,
              size: file.size,
            })),
          ],
        },
      });
    } catch {
      console.error('Something went wrong with photos serialization');
    }
    // eslint-disable-next-line no-param-reassign
    target.value = '';
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const items = moveArrayItem(arrayPhotos.current, oldIndex, newIndex);

    setPhotos(items);
    setContextPhotos(items);
    onBlur && onBlur();
    onChange({
      target: {
        name,
        value: items,
      },
    });
  };

  const removePhoto = async photoToRemove => {
    onTouch && onTouch(name);
    if (single) {
      setPhotos([]);
      setContextPhotos([]);

      return onChange({
        target: {
          name,
          value: [],
        },
      });
    }

    const filterPhotos = (photo: PhotoShapeUnion) =>
      photo.id ? photo.id !== photoToRemove.id : photo.name !== photoToRemove.name;

    setPhotos(photos.filter(filterPhotos));
    setContextPhotos(contextPhotos.filter(filterPhotos));

    onChange({
      target: {
        name,
        value: arrayPhotos.current.filter(filterPhotos),
      },
    });
  };
  const setArrayPhotos = (newPhotos: PhotoShapeUnion[]) => {
    arrayPhotos.current = newPhotos;
  };

  return (
    <>
      {error && (
        <ErrorMessage standalone show={!!error}>
          {error}
        </ErrorMessage>
      )}
      <PhotoPreviewList
        photos={photos}
        onRemovePhoto={removePhoto}
        contextPhotos={contextPhotos}
        onNewPhotos={onInputChange}
        onSortEnd={onSortEnd}
        allowedExtensions={allowedExtensions}
        single={single}
        sortable={sortable}
        addPhotoMessage={addPhotoMessage}
        fetchPhotoMessage={fetchPhotoMessage}
        largePreview={largePreview}
        setArrayPhotos={setArrayPhotos}
        canFetchPhoto={canFetchPhoto}
        isFetchingPhoto={isFetchingPhoto}
        externalPhotoAvailable={externalPhotoAvailable}
        onFetchPhoto={onFetchPhoto}
      />
    </>
  );
};

export { FieldPhotos };
