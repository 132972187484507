import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';

import { LoadableContent } from 'components/shared/Loader';
import { fetchNotificationDetails } from 'store/notificationDetails';
import { fetchNotifications } from 'store/notificationsList';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { useTypedIntl } from './locale/messages';
import { NotificationListItem } from './NotificationListItem';
import { NotificationsListLastItem } from './NotificationsListLastItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  position: relative;
`;
const DEFAULT_PAGE_SIZE = 20;

export function NotificationsList(): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const notificationsListData = useAppSelector(state => state.notificationsList);
  const notificationsCount = useAppSelector(state => state.notificationCount.count);
  const notificationResolveIsPending = useAppSelector(state => state.notificationResolve.isPending);
  const isInView = useInView();
  const hasMoreData = notificationsListData.count > notificationsListData.notifications.length;
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [initialNotificationsLoaded, setInitialNotificationsLoaded] = useState(false);

  useEffect(() => {
    if (notificationResolveIsPending) return;
    const fetchData = async () => {
      await fetchNextPage(currentPageSize);
      setInitialNotificationsLoaded(true);
    };
    fetchData();
  }, [notificationsCount, notificationResolveIsPending, notificationsListData.statusFilter]);

  useEffect(() => {
    if (
      isInView.inView &&
      hasMoreData &&
      initialNotificationsLoaded &&
      !notificationsListData.isPending
    ) {
      const newPageSize = currentPageSize + 10;
      setCurrentPageSize(newPageSize);
      fetchNextPage(newPageSize);
    }
  }, [isInView.inView, hasMoreData, initialNotificationsLoaded]);

  const handleNotificationListClick = (notificationId: number) =>
    dispatch(fetchNotificationDetails(notificationId));

  const fetchNextPage = async (pageSize: number) => {
    try {
      await dispatch(
        fetchNotifications({
          page: 1,
          pageSize,
          status:
            notificationsListData.statusFilter === 'ALL'
              ? null
              : notificationsListData.statusFilter,
        }),
      );
    } catch {
      snackBarPushFailure(
        intl.formatMessage({
          id: 'Notifications.List.FailedToFetch',
        }),
      );
    }
  };

  let lastItemText = intl.formatMessage({ id: 'Notifications.ListItem.NoData' });

  if (notificationsListData.count) {
    lastItemText = hasMoreData
      ? intl.formatMessage(
          { id: 'Notifications.ListItem.LoadMore' },
          { count: notificationsListData.count - notificationsListData.notifications.length },
        )
      : intl.formatMessage({ id: 'Notifications.ListItem.NoMoreDataToLoad' });
  }

  return (
    <LoadableContent
      drawContent
      loading={notificationsListData.isPending}
      mode={LoadableContent.MODE.INLINE}
    >
      <Container>
        {notificationsListData.notifications.map(notification => (
          <NotificationListItem
            notification={notification}
            key={notification.id}
            onClick={handleNotificationListClick}
          />
        ))}
        {(notificationsListData.count || !notificationsListData.isPending) && (
          <NotificationsListLastItem ref={isInView.ref} text={lastItemText} />
        )}
      </Container>
    </LoadableContent>
  );
}
