import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldNumber } from 'components/shared/Fields/FieldNumber/FieldNumber';
import { FieldRadio } from 'components/shared/Fields/FieldRadio/FieldRadio';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { ModalFormButtonContainer } from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { Tooltip } from 'components/shared/Tooltip/Tooltip';
import { ModalFormType, SHARED } from 'shared/constants';
import { useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useAddItemsToShoppingCart, useCreateShoppingCart } from 'shared/mutations/shoppingCart';
import { useGetShoppingCarts } from 'shared/queries';
import { hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, MEDIA_QUERY } from 'theme';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  TypedIntlShape,
  useTypedIntl,
} from '../../locale/messages';
import {
  ALLOWED_PARTS_OF_CONVERTER,
  formatPartOfConverter,
  isUsingHedgePricing,
} from '../ShoppingCart.helpers';
import { StyledFieldRadioButton, StyledModalFormContent } from '../ShoppingCart.styles';

const NEW_CART_ID = 'new-cart';

interface FormValues {
  unitsCount: number;
  partOfConverter: string;
  shoppingCart?: string;
  newCartName: string;
}

const validationSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    unitsCount: Yup.number()
      .required(intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.RequiredError' }))
      .min(1, intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.MinError' }))
      .max(
        SHARED.MAX_DB_INTEGER,
        intl.formatMessage(
          { id: 'ShoppingCart.Modal.UnitsCount.MaxError' },
          { max: SHARED.MAX_DB_INTEGER },
        ),
      ),
    partOfConverter: Yup.number().required().oneOf(ALLOWED_PARTS_OF_CONVERTER),
    shoppingCart: Yup.string().required(),
    newCartName: Yup.string()
      .min(1, intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName' }))
      .max(50)
      .when('shoppingCart', {
        is: NEW_CART_ID,
        then: Yup.string().required(
          intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName.RequiredError' }),
        ),
      }),
  });

const AddShoppingCartItemsModalComponent = () => {
  const intl = useTypedIntl();
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);

  const enabled = isOpen && modalType === ModalFormType.AddShoppingCartItems;
  const config = useAppSelector(state => state.config.shoppingCart);
  const carts = useGetShoppingCarts({ enabled });
  const createCart = useCreateShoppingCart();
  const addItemsToCart = useAddItemsToShoppingCart();

  const currentUser = useCurrentUser();
  const hedgePriceInUse = isUsingHedgePricing(currentUser);

  const partOfConverterOptions = ALLOWED_PARTS_OF_CONVERTER.map(value => ({
    value: String(value),
    label: formatPartOfConverter(value, intl, true),
  }));

  const shoppingCartOptions = [
    ...carts.data!.map(cart => ({
      value: String(cart.id),
      label: (
        <Tooltip
          tooltip={intl.formatMessage({ id: 'ShoppingCart.Modal.SelectCart.PriceMismatch' })}
          enabled={hedgePriceInUse !== cart.hedgePricing}
        >
          {cart.name}
        </Tooltip>
      ),
      disabled: hedgePriceInUse !== cart.hedgePricing,
    })),
    {
      value: NEW_CART_ID,
      label: intl.formatMessage({ id: 'ShoppingCart.Modal.NewCart' }),
    },
  ].slice(0, config?.maxCartsPerUser ?? 0);

  const onClose = () => dispatch(hideModalForm());

  const formikContext = useFormik<FormValues>({
    initialValues: {
      unitsCount: 1,
      partOfConverter: '',
      newCartName: '',
      shoppingCart: undefined,
    },
    validationSchema: validationSchema(intl),
    onSubmit: async values => {
      if (!params || !('converters' in params)) return;

      try {
        let shoppingCartId: number;
        if (values.shoppingCart === NEW_CART_ID) {
          shoppingCartId = await createCart.mutateAsync(values.newCartName);
        } else {
          shoppingCartId = Number(values.shoppingCart);
        }

        await addItemsToCart.mutateAsync({
          convertersIds: params.converters.map(c => c.id),
          unitsCount: values.unitsCount,
          partOfConverter: Number(values.partOfConverter),
          shoppingCartId,
        });
        onClose();

        dispatch(
          snackBarPushSuccess(
            intl.formatMessage(
              { id: 'ShoppingCart.Modal.Add.Success' },
              { identifier: params.converters[0].converterIdentifier },
            ),
          ),
        );
      } catch (e) {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
  });
  const { values, handleChange, handleBlur, touched, errors, resetForm, handleSubmit } =
    formikContext;

  useEffect(() => {
    formikContext.setFieldValue('newCartName', '');
    formikContext.setTouched({ newCartName: false });
  }, [values.shoppingCart]);

  const submitDisabled =
    !formikContext.dirty ||
    !formikContext.isValid ||
    formikContext.isSubmitting ||
    carts.isFetching ||
    createCart.isLoading ||
    addItemsToCart.isLoading;

  useEffect(() => {
    resetForm();
  }, [enabled]);

  return (
    <ReactModal
      isOpen={enabled}
      style={ReactModalStyle(isPhone, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <StyledModalFormContent>
        <LoadableContent
          loading={addItemsToCart.isLoading || !carts.isFetched}
          mode={LoadableContentModes.OVERLAY}
          drawContent
        >
          <h3>
            <FormattedMessage id="ShoppingCart.Modal.Add.Title" />
          </h3>
          <FormikProvider value={formikContext}>
            <form onSubmit={handleSubmit}>
              <StyledFieldRadioButton
                label={intl.formatMessage({ id: 'ShoppingCart.Modal.PartOfConverter' })}
                name="partOfConverter"
                value={values.partOfConverter}
                onChange={handleChange}
                onBlur={handleBlur}
                options={partOfConverterOptions}
                error={touched.partOfConverter && errors.partOfConverter}
                required
              />
              <FieldNumber
                label={intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount' })}
                name="unitsCount"
                value={values.unitsCount}
                onChange={handleChange}
                onBlur={handleBlur}
                min={1}
                max={SHARED.MAX_DB_INTEGER as number}
                error={touched.unitsCount && errors.unitsCount}
                required
              />
              <FieldRadio
                label={intl.formatMessage({ id: 'ShoppingCart.Modal.SelectCart' })}
                name="shoppingCart"
                value={values.shoppingCart}
                onChange={handleChange}
                onBlur={handleBlur}
                options={shoppingCartOptions}
                error={touched.shoppingCart && errors.shoppingCart}
                required
              />
              {values.shoppingCart === NEW_CART_ID && (
                <FieldInput
                  label={intl.formatMessage({ id: 'ShoppingCart.Modal.ShoppingCartName' })}
                  name="newCartName"
                  maxLength={50}
                  value={values.newCartName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.newCartName && errors.newCartName}
                  required
                />
              )}
              <ModalFormButtonContainer>
                <AppButton styleType="neutral-empty" onClick={onClose}>
                  <FormattedMessage id="Global.Cancel" />
                </AppButton>
                <AppButton styleType="ok" type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="Global.Confirm" />
                </AppButton>
              </ModalFormButtonContainer>
            </form>
          </FormikProvider>
        </LoadableContent>
      </StyledModalFormContent>
    </ReactModal>
  );
};

export const AddItemsModal = withAlphamartIntlProvider(
  AddShoppingCartItemsModalComponent,
  messages,
);
