import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import {
  AlphamartHttpError,
  NotificationListItem,
  NotificationStatus,
  PaginatedData,
} from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

export type NotificationFilter = NotificationStatus | 'ALL';

interface NotificationsState extends GenericStoreSlice {
  notifications: NotificationListItem[];
  count: number;
  isOpen: boolean;
  statusFilter: NotificationFilter;
}

interface FetchListParams {
  page: number;
  pageSize: number;
  status?: NotificationStatus | null;
}

type GetNotificationsResponse = PaginatedData<NotificationListItem>;
type NotificationsReducer = GenericStoreReducer<NotificationsState> & {
  openNotifications: CaseReducer<NotificationsState, { type: string }>;
  closeNotifications: CaseReducer<NotificationsState, { type: string }>;
  setStatusFilter: CaseReducer<NotificationsState, { type: string; payload: NotificationFilter }>;
};
const fetchNotificationsSlice = createSlice<NotificationsState, NotificationsReducer>({
  name: 'notificationsList',
  initialState: {
    notifications: [],
    count: 0,
    isPending: false,
    error: undefined,
    isOpen: false,
    statusFilter: 'ALL',
  },
  reducers: {
    ...getGenericReducers<NotificationsState, GetNotificationsResponse>(payload => ({
      notifications: payload.data,
      count: payload.count,
    })),
    openNotifications: (state: NotificationsState): NotificationsState => ({
      ...state,
      isOpen: true,
    }),
    closeNotifications: (state: NotificationsState): NotificationsState => ({
      ...state,
      isOpen: false,
    }),
    setStatusFilter: (state: NotificationsState, action): NotificationsState => ({
      ...state,
      statusFilter: action.payload,
    }),
  },
});

export const { pending, success, failure, openNotifications, closeNotifications, setStatusFilter } =
  fetchNotificationsSlice.actions;

export const fetchNotifications =
  (params: FetchListParams): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(pending());
      const notifications = await httpClient.get<GetNotificationsResponse>(
        `${apiHostname}/api/notifications`,
        {
          params,
        },
      );
      dispatch(success(notifications.data));
    } catch (error) {
      dispatch(failure((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default fetchNotificationsSlice.reducer;
