import React, { Children, useContext, useMemo } from 'react';
import { FormikContextType, FormikProvider } from 'formik';

import { WizardContext } from 'components/shared/forms/Wizard/Wizard';
import { LoadableContent } from 'components/shared/Loader';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import {
  FormContent,
  StyledH1,
  StyledHeader,
  StyledLi,
  StyledSpan,
  StyledSteps,
  StyledStepsWrapper,
  StyledUl,
} from './StyledForm';
import { Indicator } from '../Section/components/Indicator/Indicator';

interface Props {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormikContextType<any>;
  header: string;
}

export const FormInWizard = ({
  children: sections,
  context,
  header,
}: Props): React.ReactElement => {
  const { currentPage, pageIndexes } = useContext(WizardContext);
  const intl = useTypedIntl();
  const { isSubmitting } = context ?? {};
  const parsedSections = useMemo(
    () =>
      Children.toArray(sections)
        .filter(child => !!child)
        .flat(),
    [sections],
  ) as unknown as React.ReactElement<{
    sectionName: string;
    activeSection: string;
    label: string;
  }>[];

  return (
    <FormikProvider value={context}>
      <LoadableContent
        loading={isSubmitting}
        mode={LoadableContent.MODE.FULL}
        label={intl.formatMessage({ id: 'Global.Submitting' })}
      />
      <div className="form-content">
        <StyledHeader>
          <StyledH1>{header}</StyledH1>
          <StyledStepsWrapper>
            <StyledSteps>
              <StyledSpan>
                <FormattedMessage id="Global.Step" />
                {` ${currentPage}/${pageIndexes.length}`}
              </StyledSpan>
              <StyledUl>
                {pageIndexes.map(pageIndex => (
                  <StyledLi key={pageIndex} active={currentPage === pageIndex} />
                ))}
              </StyledUl>
            </StyledSteps>
          </StyledStepsWrapper>
        </StyledHeader>
        <FormContent>
          {parsedSections.map((section, index) => (
            <React.Fragment key={section.props.sectionName}>
              <Indicator
                key={section.props.sectionName}
                isActive={section.props.sectionName === section.props.activeSection}
                label={section.props.label}
                last={index === parsedSections.length - 1}
                single={parsedSections.length === 1}
              />
              {section}
            </React.Fragment>
          ))}
        </FormContent>
      </div>
    </FormikProvider>
  );
};
