import React from 'react';

import { useAlphamartLocation, useExtendedTheme } from 'shared/hooks';
import {
  headerStyles,
  headerTitleAndActionsStyles,
  headerTitleStyles,
  headerUserMenuWrapperStyles,
} from './PanelHeader.styles';
import { ShoppingCartMenu } from '../ShoppingCartMenu/ShoppingCartMenu';

interface Props {
  children?: React.ReactNode;
  title: React.ReactNode;
  className?: string;
}

export const PanelHeader = ({ children, title, className = '' }: Props): React.ReactElement => {
  const theme = useExtendedTheme();
  const location = useAlphamartLocation();

  return (
    <header className={`${headerStyles(theme)} ${className}`}>
      <div className={headerTitleAndActionsStyles}>
        <h3 className={headerTitleStyles(theme)}>{title}</h3>
        {children}
      </div>
      <div className={headerUserMenuWrapperStyles}>
        <ShoppingCartMenu
          hide={!location.pathname.startsWith('/converters')}
          data-guide="shopping-cart"
        />
      </div>
    </header>
  );
};
