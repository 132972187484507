import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { Gallery } from 'components/shared/Gallery';
import { HedgePriceInUseBadge } from 'components/shared/HedgePriceInUseBadge';
import { MarketHedgePrice } from 'components/shared/Price';
import { formatDate } from 'helpers/dateTime/dateTime';
import { PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { ConvertersGroup, User } from 'shared/types';
import { replaceEmpty } from 'shared/utils/replaceEmpty';
import { DisclaimerContent, HeaderWrapper } from '../ConverterDetails/ConverterDetails.styles';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

interface Props {
  group: ConvertersGroup | null;
  currentUser: User;
}
const StyledConverterGallery = styled.div`
  padding: 32px 0 8px;
`;

const LinkToConverter = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

const ConvertersGroupDetails = ({ group, currentUser }: Props): React.ReactElement | null => {
  const [areDetailsScrollable, setAreDetailsScrollable] = useState(false);
  const authorize = useAuthorization();
  const canSeeDisclaimer = authorize(PERMISSIONS.CONVERTERS.DISCLAIMER);
  const sortedConverters = useMemo(
    () =>
      group &&
      [...group.converters].sort((converter1, converter2) =>
        converter1.identifier.localeCompare(converter2.identifier),
      ),
    [group],
  );

  if (!group) return null;

  const renderVehicleInfo = item => {
    const converterWithVehicle = item.converters.find(converter => !!converter.make);

    return (
      (converterWithVehicle &&
        replaceEmpty(
          `${converterWithVehicle.year ? `${converterWithVehicle.year} ` : ''} ${
            converterWithVehicle.make ? `${converterWithVehicle.make} ` : ''
          } ${converterWithVehicle.model ? `${converterWithVehicle.model} ` : ''}`,
        )) ||
      SHARED.LONG_DASH
    );
  };

  return (
    <Details
      title={
        <HeaderWrapper>
          {sortedConverters?.map(({ identifier }) => identifier).join(', ')}
          <HedgePriceInUseBadge />
        </HeaderWrapper>
      }
      backUrl="/converters"
      scrollable={!areDetailsScrollable}
    >
      {canSeeDisclaimer && (
        <DisclaimerContent>
          <FormattedMessage id="ConvertersList.Header.Disclaimer" />
        </DisclaimerContent>
      )}
      <StyledConverterGallery>
        <DetailsSection>
          <Gallery
            images={group.files}
            onLightboxToggle={isOpened => {
              setAreDetailsScrollable(isOpened);
            }}
            withMainPhoto
            largeImage
          />
        </DetailsSection>
      </StyledConverterGallery>
      <DetailsSection
        title={<FormattedMessage id="GroupDetails.Header" />}
        template={['calculatedPrice vehicle vehicle']}
        fields={[
          {
            name: 'calculatedPrice',
            label: <FormattedMessage id="GroupDetails.CalculatedGroupPrice" />,
            value: (
              <MarketHedgePrice
                price={{
                  marketPrice: sortedConverters?.reduce<null | number>(
                    (acc, curr) =>
                      Number.isFinite(curr.price.marketPrice)
                        ? (acc as number) + (curr.price.marketPrice as number)
                        : acc,
                    null,
                  ),
                  hedgePrice: sortedConverters?.reduce<null | number>(
                    (acc, curr) =>
                      Number.isFinite(curr.price.hedgePrice)
                        ? (acc as number) + (curr.price.hedgePrice as number)
                        : acc,
                    null,
                  ),
                }}
                currentUser={currentUser}
                prefix={{
                  market: <FormattedMessage id="ConverterDetails.MarketPricePrefix" />,
                  hedge: <FormattedMessage id="ConverterDetails.HedgePricePrefix" />,
                }}
              />
            ),
          },
          {
            name: 'vehicle',
            label: <FormattedMessage id="ConverterDetails.CarHeader" />,
            value: renderVehicleInfo(group),
          },
        ]}
      />
      <DetailsSection
        title={<FormattedMessage id="GroupDetails.Converters" />}
        hasRoundedBorders={false}
        fields={sortedConverters
          ?.map(converter => [
            {
              name: `identifier${converter.id}`,
              label: <FormattedMessage id="ConverterDetails.Identifier" />,
              value: (
                <LinkToConverter to={`/converters/${converter.id}`}>
                  {converter.identifier}
                </LinkToConverter>
              ),
            },
            {
              name: `vehicle${converter.id}`,
              label: <FormattedMessage id="ConverterDetails.CarHeader" />,
              value:
                converter.make || converter.model || converter.year
                  ? `${converter.make || ''} ${converter.model || ''} ${converter.year || ''}`
                  : SHARED.LONG_DASH,
            },
            {
              name: `price${converter.id}`,
              label: <FormattedMessage id="ConverterDetails.CalculatedPrice" />,
              value: (
                <MarketHedgePrice
                  price={converter.price}
                  currentUser={currentUser}
                  prefix={{
                    market: <FormattedMessage id="ConverterDetails.MarketPricePrefix" />,
                    hedge: <FormattedMessage id="ConverterDetails.HedgePricePrefix" />,
                  }}
                />
              ),
            },
          ])
          .flat()}
        rowGap
      />
      <DetailsFooter
        fields={[
          {
            name: 'createdBy',
            label: <FormattedMessage id="Global.CreatedBy" />,
            value: group.createdBy && `${group.createdBy.firstName} ${group.createdBy.lastName}`,
          },
          {
            name: 'createdAt',
            label: <FormattedMessage id="Global.CreatedOn" />,
            value: formatDate(group.createdAt),
          },
          {
            name: 'updatedBy',
            label: <FormattedMessage id="Global.UpdatedBy" />,
            value: group.updatedBy && `${group.updatedBy.firstName} ${group.updatedBy.lastName}`,
          },
          {
            name: 'updatedAt',
            label: <FormattedMessage id="Global.UpdatedOn" />,
            value: group.updatedAt && formatDate(group.updatedAt),
          },
        ]}
      />
    </Details>
  );
};

export { ConvertersGroupDetails };
