import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { isNil, isNull } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

import basketIcon from 'assets/images/icons/Basket.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { HedgePriceInUseBadge } from 'components/shared/HedgePriceInUseBadge';
import {
  DataGridContainer,
  DataList,
  GridCell,
  GridCellContent,
  highlightableTextStyles,
  HighlightSearch,
  ItemThumbnail,
  TableCell,
  TableCellContent,
  useGetRowId,
} from 'components/shared/List';
import { LoadableContent } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { MarketHedgePrice } from 'components/shared/Price';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { Forbidden } from 'components/views/Forbidden/Forbidden';
import { formatDate } from 'helpers/dateTime/dateTime';
import { FilterableModules, LIST_MODE, ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { matchNormalized, matchSampleNameMultiWord } from 'shared/matchers';
import { ApiFile, Converter, ConverterListItem } from 'shared/types';
import { replaceEmpty } from 'shared/utils/replaceEmpty';
import { replaceNonAlphanumeric } from 'shared/utils/replaceNonAlphanumeric';
import { clearConverter, fetchConverter } from 'store/converterDetailsSlice';
import { clearConvertersGroup, fetchConvertersGroup } from 'store/convertersGroupDetailsSlice';
import { fetchConverters } from 'store/convertersListSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { showModalForm } from 'store/modalFormSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { AveragePrice } from './AveragePrice';
import {
  AddToCartButton,
  averagePriceSpacer,
  ConvertersHeaderWrapper,
  DisclaimerContent,
  DisclaimerContentMobile,
} from './ConverterList.styles';
import { ConverterSearchRow } from './ConverterSearchRow';
import { ConvertersFilters, ConvertersFiltersShape } from './ConvertersFilters';
import { ConvertersHeaderPrice } from './ConvertersHeaderPrice';
import { ConvertersListGridItem } from './ConvertersListGridItem';
import { ConverterDetails } from '../ConverterDetails/ConverterDetails';
import { ConvertersGroupDetails } from '../ConvertersGroupDetails/ConvertersGroupDetails';
import useIsCartOpen from '../hooks/useIsCartOpen';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';
import { AddItemsModal } from '../ShoppingCart/AddShoppingCartItemModal/AddItemsModal';
import { ShoppingCart } from '../ShoppingCart/ShoppingCart/ShoppingCart';

const getFilledFields = (converter: Converter | ConverterListItem): (string | number)[] =>
  [converter.year, converter.make, converter.model].filter(p => !isNil(p)) as (string | number)[];
const converterToName = (converter: Converter | ConverterListItem): string =>
  getFilledFields(converter).join(' ');
const getVehicleName = (converter: Converter | ConverterListItem): string => {
  const converters = converter.converterGroup?.converters;
  if (!converters?.length) {
    return converterToName(converter);
  }

  const [first] = [...converters].sort(
    (a, b) => getFilledFields(b).length - getFilledFields(a).length,
  );
  return converterToName(first);
};

const ConvertersListComponent = (): React.ReactElement => {
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const location = useLocation();
  const intl = useTypedIntl();
  const { id } = useParams<{ id: string }>();
  const converters = useAppSelector(state => state.converters);
  const converterDetails = useAppSelector(state => state.converterDetails);
  const convertersGroupDetails = useAppSelector(state => state.convertersGroup);
  const currentUser = useCurrentUser();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.CONVERTERS]);
  const listComponent = useAppSelector(state => state.listComponent);
  const { position } = useAppSelector(state => state.position);
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [averageMode, setAverageMode] = useState(false);

  const onFiltersToggle = useCallback(() => setFiltersOpen(!filtersOpen), [filtersOpen]);
  const canSeeDisclaimer = authorize(PERMISSIONS.CONVERTERS.DISCLAIMER);

  const isCartOpen = useIsCartOpen();
  const SEARCH_AFTER = 2;

  const onAddToCart = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: Row<Converter | ConverterListItem>,
  ) => {
    event.stopPropagation();
    dispatch(
      showModalForm({
        modalType: ModalFormType.AddShoppingCartItems,
        params: { converters: [row.original] as Converter[] },
      }),
    );
  };

  const columns: ColumnDef<ConverterListItem | Converter>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Image' }),
      cell: ({ row }) => (
        <ItemThumbnail
          attrAlt={
            row.original.converterGroup
              ? row.original.converterGroup.converters.map(c => c.identifier).join(', ')
              : row.original.identifier
          }
          counterfeit={
            row.original.converterGroup
              ? undefined
              : { notes: row.original.notes, show: row.original.counterfeit }
          }
          photo={
            (row.original.converterGroup?.files?.[0] ||
              (row.original as Converter)?.files?.[0] ||
              row.original.converterPhoto) as ApiFile
          }
          size={listComponent.listMode === LIST_MODE.GRID ? 'MEDIUM' : 'LARGE'}
        />
      ),
    },
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Identifier' }),
      cell: ({ row }) => (
        <>
          <div>
            <HighlightSearch
              className={highlightableTextStyles(false)}
              searchWords={[matchNormalized(savedFilters.data.query)!]}
              textToHighlight={
                row.original.converterGroup?.converters?.map(c => c.identifier)?.join(', ') ||
                row.original.converterIdentifier ||
                row.original.identifier
              }
            />
          </div>
          {!row.original.converterGroup && row.original.isPartial && (
            <div>{intl.formatMessage({ id: 'ConvertersList.PartialIdentifier' })}</div>
          )}
        </>
      ),
    },
    {
      id: 'sampleName',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.SampleName' }),
      cell: ({ row }) =>
        row.original.converterGroup
          ? null
          : HighlightSearch({
              className: highlightableTextStyles(false),
              searchWords: [matchSampleNameMultiWord(savedFilters.data.query!)!],
              textToHighlight:
                (row.original as Converter).assay?.sampleName ??
                (row.original as ConverterListItem).sampleName,
            }),
    },
    {
      id: 'sampleDate',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.SampleDate' }),
      cell: ({ row }) =>
        row.original.converterGroup
          ? null
          : formatDate(
              (row.original as Converter).assay?.sampleDate ??
                (row.original as ConverterListItem).sampleDate,
            ),
    },
    {
      id: 'vehicle',
      header: intl.formatMessage({ id: 'ConvertersList.TableHeader.Vehicle' }),
      cell: ({ row }) => (
        <HighlightSearch
          className={highlightableTextStyles(false)}
          searchWords={[matchNormalized(savedFilters.data.query)!]}
          textToHighlight={replaceEmpty(getVehicleName(row.original))}
        />
      ),
    },
    {
      id: 'price',
      header: () =>
        ConvertersHeaderPrice({
          currentUser,
          prefix: {
            market: intl.formatMessage({ id: 'ConvertersList.TableHeader.PriceInfoMarket' }),
            hedge: intl.formatMessage({ id: 'ConvertersList.TableHeader.PriceInfoHedge' }),
          },
          showComingSoon: false,
          title: intl.formatMessage({ id: 'ConvertersList.TableHeader.Price' }),
        }),
      cell: ({ row }) =>
        MarketHedgePrice({
          price: row.original.converterGroup
            ? {
                hedgePrice: row.original.converterGroup.converters.reduce<number | null>(
                  (acc, curr) =>
                    Number.isFinite(curr.price.hedgePrice)
                      ? (acc as number) + (curr.price.hedgePrice as number)
                      : acc,
                  null,
                ),
                marketPrice: row.original.converterGroup.converters.reduce<number | null>(
                  (acc, curr) =>
                    Number.isFinite(curr.price.marketPrice)
                      ? (acc as number) + (curr.price.marketPrice as number)
                      : acc,
                  null,
                ),
              }
            : row.original.price,
          currentUser,
          prefix: {
            market: intl.formatMessage({ id: 'ConvertersList.MarketPricePrefix' }),
            hedge: intl.formatMessage({ id: 'ConvertersList.HedgePricePrefix' }),
          },
        }),
    },
    {
      id: 'addToCart',
      cell: ({ row }) =>
        !row.getCanExpand() &&
        !isNull(row.original.price.marketPrice) && (
          <AddToCartButton
            icon={basketIcon}
            onClick={ev => onAddToCart(ev, row)}
            data-cy={`add-to-cart-${row.original.id}`}
          />
        ),
      meta: { widthPx: 65 },
    },
  ];

  const table = useReactTable<ConverterListItem | Converter>({
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    data: converters.list,
    getRowId: useGetRowId(),
    getSubRows: row => row.converterGroup?.converters ?? [],
    getRowCanExpand: row => !!row.original.converterGroup?.converters?.length,
    enableRowSelection: averageMode,
    pageCount: Math.ceil(converters.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    onRowSelectionChange: setSelected,
    manualPagination: true,
    state: {
      columnVisibility: {
        sampleName: authorize(PERMISSIONS.CONVERTERS.LIST_SAMPLE) && useMediaQuery(MEDIA_QUERY.LG),
        sampleDate: authorize(PERMISSIONS.CONVERTERS.LIST_SAMPLE) && useMediaQuery(MEDIA_QUERY.XXL),
        vehicle: useMediaQuery(MEDIA_QUERY.XL),
        addToCart: authorize(PERMISSIONS.SHOPPING_CART.MAIN),
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      rowSelection: selected,
      columnSizing: {
        image: 240,
        identifier: 190,
        sampleName: 220,
        vehicle: 220,
        price: 150,
      },
      globalFilter: savedFilters?.data?.query,
    },
  });

  useDeepCompareEffect(() => {
    if (position) {
      dispatch(
        fetchConverters(
          listComponent.pageIndex + 1,
          listComponent.pageSize,
          savedFilters.data,
          position,
        ),
      );
    }
    setAverageMode(false);
    setSelected({});
  }, [
    listComponent.pageIndex,
    listComponent.pageSize,
    savedFilters?.data,
    currentUser,
    !!position,
  ]);

  useDeepCompareEffect(() => {
    if (position) {
      if (isCartOpen) {
        return;
      }
      if (id && location.pathname.includes('group')) {
        dispatch(clearConverter());
        dispatch(fetchConvertersGroup(id));
      } else if (id) {
        dispatch(clearConvertersGroup());
        dispatch(fetchConverter(id, position));
      } else {
        dispatch(clearConverter());
        dispatch(clearConvertersGroup());
      }
    }
  }, [id, dispatch, location, !!position, currentUser, isCartOpen]);

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const onFiltersChanged = (changed: Partial<ConvertersFiltersShape>) => {
    if (changed.query && replaceNonAlphanumeric(changed.query).length < SEARCH_AFTER) {
      return;
    }

    setPageIndex(0);

    dispatch(saveFilters({ filterKey: FilterableModules.CONVERTERS, values: changed }));
  };

  const onConverterClick = (item: ConverterListItem | Converter) => {
    setFiltersOpen(false);
    navigate(
      item?.converterGroup?.converters?.length
        ? `/converters/group/${item.converterGroup?.id}`
        : `/converters/${item.id}`,
    );
  };

  const goToPartialConverters = () => navigate('/converters/partial');

  const onAverageModeToggle = () => {
    setAverageMode(!averageMode);
  };

  const loadingEnabled =
    !converters.list && !converterDetails.isPending && !converterDetails.converter;

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);

  if (isCartOpen && !authorize(PERMISSIONS.SHOPPING_CART.MAIN)) {
    return <Forbidden />;
  }
  return (
    <PanelTemplate>
      {canSeeDisclaimer && (
        <DisclaimerContentMobile>
          <FormattedMessage id="ConvertersList.HeaderList.Disclaimer" />
        </DisclaimerContentMobile>
      )}
      <ConvertersHeaderWrapper>
        <PanelHeader title={<FormattedMessage id="ConvertersList.Header" />}>
          {canSeeDisclaimer && (
            <DisclaimerContent>
              <FormattedMessage id="ConvertersList.HeaderList.Disclaimer" />
            </DisclaimerContent>
          )}
          <HedgePriceInUseBadge />
          <ProtectedArea permission={PERMISSIONS.CONVERTERS.PARTIAL_CONVERTERS}>
            <AppButton type="button" onClick={goToPartialConverters}>
              <FormattedMessage
                id={`ConvertersList.${isPhone ? 'Grid.Partial' : 'PartialConverters'}`}
              />
            </AppButton>
          </ProtectedArea>
        </PanelHeader>
      </ConvertersHeaderWrapper>
      <PanelContent>
        <PanelContentMain disableScroll={loadingEnabled} className={averagePriceSpacer}>
          <SearchBar
            onSearchChanged={onFiltersChanged}
            searchAfter={SEARCH_AFTER}
            onFiltersToggle={onFiltersToggle}
            initialQuery={savedFilters.data.query}
            chooseListType
            isSearchPending={converters.isPending}
            addSearchSession
            savedFilters={savedFilters}
          />
          <DataList
            table={table}
            isLoading={converters.isPending}
            onRowClicked={onConverterClick}
            cellComponent={listComponent.listMode === LIST_MODE.GRID ? GridCell : TableCell}
            cellContentComponent={
              listComponent.listMode === LIST_MODE.GRID ? GridCellContent : TableCellContent
            }
            rowComponent={
              listComponent.listMode === LIST_MODE.GRID
                ? ConvertersListGridItem
                : ConverterSearchRow
            }
            headerBodyComponent={listComponent.listMode === LIST_MODE.GRID ? null : React.Fragment}
            dataBodyComponent={
              listComponent.listMode === LIST_MODE.GRID
                ? DataGridContainer(listComponent.columnsQuantity)
                : React.Fragment
            }
          />
          {!converters.isPending && !!converters?.count && !savedFilters?.data.showComingSoon && (
            <AveragePrice
              enabled={averageMode}
              onAverageModeToggle={onAverageModeToggle}
              onSelectAll={() => table.toggleAllRowsSelected(true)}
              onClear={() => table.toggleAllRowsSelected(false)}
              converters={table.getSelectedRowModel().flatRows.map(p => p.original as Converter)}
            />
          )}
        </PanelContentMain>
        <PanelContentSidebar
          header={intl.formatMessage({ id: 'Global.Filters' })}
          headerIcon={filterIcon}
          open={filtersOpen}
          onSidebarClosed={onFiltersToggle}
        >
          <ConvertersFilters
            onFiltersChanged={onFiltersChanged}
            onFiltersApplied={onFiltersToggle}
          />
        </PanelContentSidebar>
      </PanelContent>
      <LoadableContent loading={converterDetails.isPending}>
        <ConverterDetails converter={converterDetails.converter} currentUser={currentUser} />
      </LoadableContent>
      <LoadableContent loading={convertersGroupDetails.isPending}>
        <ConvertersGroupDetails
          group={convertersGroupDetails.convertersGroup}
          currentUser={currentUser}
        />
      </LoadableContent>
      <ShoppingCart />
      <AddItemsModal />
    </PanelTemplate>
  );
};

export const ConvertersList = withAlphamartIntlProvider(ConvertersListComponent, messages);
