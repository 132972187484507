import { TypedFormattedMessageFactory, useTypedIntlFactory } from 'locale/typedIntl';
import { Language } from 'shared/constants';
import enMessages from './en.json';
import esMessages from './es.json';
import frMessages from './fr.json';
import plMessages from './pl.json';

export type GuidesMessages = typeof enMessages;
export const messages: Record<Language, GuidesMessages> = {
  EN: enMessages,
  ES: esMessages,
  FR: frMessages,
  PL: plMessages,
};
export const useTypedIntl = useTypedIntlFactory<keyof GuidesMessages>();
export const TypedFormattedMessage = TypedFormattedMessageFactory<keyof GuidesMessages>();
export type TypedIntlShape = ReturnType<typeof useTypedIntl>;
