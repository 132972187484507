import React from 'react';

import starEmptyIcon from 'assets/images/icons/star-empty.svg';
import starFullIcon from 'assets/images/icons/star-full.svg';
import { useExtendedTheme } from 'shared/hooks';
import { Icon } from '../Buttons';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  filled?: boolean;
  size?: string;
}

const Star = ({ filled = true, size = '20px', ...props }: Props): React.ReactElement => {
  const theme = useExtendedTheme();
  return (
    <Icon
      color={filled ? theme.mainColor : theme.colors.greySnow}
      icon={filled ? starFullIcon : starEmptyIcon}
      data-cy={filled ? 'star-full' : 'star-empty'}
      size={size}
      {...props}
    />
  );
};

export { Star };
