import React from 'react';

import searchIcon from 'assets/images/icons/search.svg';
import { Icon } from 'components/shared/Buttons';
import {
  highlightableTextStyles,
  HighlightSearch,
  RowProps,
  SearchField,
  SearchFieldLabel,
  SearchHits,
  TableRowBody,
} from 'components/shared/List';
import { AssayListItem } from 'shared/types/assayListItem';
import { useSearchableAssaysFields } from './useSearchableAssaysFields';

export const AssaysSearchRow = (props: RowProps<AssayListItem>) => {
  const assay = props.row!.original;
  const searchInput = props.table!.getState().globalFilter?.trim();
  const searchableFields = useSearchableAssaysFields({ assay, searchInput });

  return (
    <>
      <TableRowBody {...props} />
      {!!searchableFields.length && (
        <SearchHits>
          <Icon icon={searchIcon} />
          {searchableFields.map(
            field =>
              field && (
                <SearchField key={field.label}>
                  <SearchFieldLabel>{field.label}</SearchFieldLabel>{' '}
                  <HighlightSearch
                    className={highlightableTextStyles(false)}
                    searchWords={[field.matcher(searchInput)!]}
                    textToHighlight={field.value}
                  />
                </SearchField>
              ),
          )}
        </SearchHits>
      )}
    </>
  );
};
