import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldNumber } from 'components/shared/Fields/FieldNumber/FieldNumber';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { ModalFormButtonContainer } from 'components/shared/forms/Form/ModalForm.styles';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { ModalFormType, SHARED } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import {
  useEditItemInShoppingCart,
  useEditItemsGroupInShoppingCart,
} from 'shared/mutations/shoppingCart';
import { EditShoppingCartItemParams, hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, MEDIA_QUERY } from 'theme';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  TypedIntlShape,
  useTypedIntl,
} from '../../locale/messages';
import { ALLOWED_PARTS_OF_CONVERTER, formatPartOfConverter } from '../ShoppingCart.helpers';
import { StyledFieldRadioButton, StyledModalFormContent } from '../ShoppingCart.styles';

interface FormValues {
  unitsCount: number;
  partOfConverter: string;
}

const validationSchema = (intl: TypedIntlShape) =>
  Yup.object().shape({
    unitsCount: Yup.number()
      .required(intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.RequiredError' }))
      .min(1, intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount.MinError' }))
      .max(
        SHARED.MAX_DB_INTEGER,
        intl.formatMessage(
          { id: 'ShoppingCart.Modal.UnitsCount.MaxError' },
          { max: SHARED.MAX_DB_INTEGER },
        ),
      ),
    partOfConverter: Yup.number().required().oneOf(ALLOWED_PARTS_OF_CONVERTER),
  });

const EditShoppingCartItemModalComponent = ({
  onUpdate,
}: {
  onUpdate(): Promise<unknown>;
}): React.ReactElement => {
  const intl = useTypedIntl();
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const dispatch = useAppDispatch();
  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);
  const typedParams = params as EditShoppingCartItemParams;

  const enabled = isOpen && modalType === ModalFormType.EditShoppingCartItem;
  const editItemInCart = useEditItemInShoppingCart();
  const editItemsGroupInCart = useEditItemsGroupInShoppingCart();

  const partOfConverterOptions = ALLOWED_PARTS_OF_CONVERTER.map(value => ({
    value: String(value),
    label: formatPartOfConverter(value, intl, true),
  }));

  const onClose = () => dispatch(hideModalForm());

  const formikContext = useFormik<FormValues>({
    initialValues: {
      unitsCount: typedParams?.shoppingCartElement?.unitsCount,
      partOfConverter: String(typedParams?.shoppingCartElement?.partOfConverter),
    },
    validationSchema: validationSchema(intl),
    onSubmit: async values => {
      if (!params || !('shoppingCartElement' in params)) return;

      const dataToSave = {
        id: typedParams.shoppingCartElement.id,
        unitsCount: values.unitsCount,
        partOfConverter: Number(values.partOfConverter),
      };

      try {
        if (typedParams.type === 'single') {
          await editItemInCart.mutateAsync(dataToSave);
        } else if (typedParams.type === 'multi') {
          await editItemsGroupInCart.mutateAsync(dataToSave);
        }
        onClose();
        await onUpdate();

        dispatch(
          snackBarPushSuccess(intl.formatMessage({ id: 'ShoppingCart.Modal.Edit.Success' })),
        );
      } catch (e) {
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
    enableReinitialize: true,
  });
  const { values, handleChange, handleBlur, touched, errors, resetForm, handleSubmit } =
    formikContext;

  const isLoading = editItemInCart.isLoading || editItemsGroupInCart.isLoading;

  const submitDisabled =
    !formikContext.dirty || !formikContext.isValid || formikContext.isSubmitting || isLoading;

  useEffect(() => {
    resetForm();
  }, [enabled]);

  return (
    <ReactModal
      isOpen={enabled}
      style={ReactModalStyle(isPhone, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <StyledModalFormContent>
        <LoadableContent loading={isLoading} mode={LoadableContentModes.OVERLAY} drawContent>
          <h3>
            <FormattedMessage id="ShoppingCart.Modal.Edit.Title" />
          </h3>
          <FormikProvider value={formikContext}>
            <form onSubmit={handleSubmit}>
              <StyledFieldRadioButton
                label={intl.formatMessage({ id: 'ShoppingCart.Modal.PartOfConverter' })}
                name="partOfConverter"
                value={values.partOfConverter}
                onChange={handleChange}
                onBlur={handleBlur}
                options={partOfConverterOptions}
                error={touched.partOfConverter && errors.partOfConverter}
                required
              />
              <FieldNumber
                label={intl.formatMessage({ id: 'ShoppingCart.Modal.UnitsCount' })}
                name="unitsCount"
                value={values.unitsCount}
                onChange={handleChange}
                onBlur={handleBlur}
                min={1}
                max={SHARED.MAX_DB_INTEGER as number}
                error={touched.unitsCount && errors.unitsCount}
                required
              />
              <ModalFormButtonContainer>
                <AppButton styleType="neutral-empty" onClick={onClose}>
                  <FormattedMessage id="Global.Cancel" />
                </AppButton>
                <AppButton styleType="ok" type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="Global.Confirm" />
                </AppButton>
              </ModalFormButtonContainer>
            </form>
          </FormikProvider>
        </LoadableContent>
      </StyledModalFormContent>
    </ReactModal>
  );
};

export const EditItemModal = withAlphamartIntlProvider(
  EditShoppingCartItemModalComponent,
  messages,
);
