import { match } from 'path-to-regexp';

export type ArrowPosition = 'bottom' | 'top' | 'left' | 'right';
export interface TooltipPosition {
  topOffset?: number;
  bottomOffset?: number;
  arrowOffset?: number;
  leftOffset?: number;
  rightOffset?: number;
  arrowPosition?: ArrowPosition;
}
export const checkStepPathname = (pathnameToMatch: string) => {
  const matcher = match(pathnameToMatch, { decode: decodeURIComponent });
  return Boolean(matcher(window.location.pathname));
};

export const calculatePositionOnMobile = (
  highlightedArea: DOMRect,
  tooltipRect: DOMRect,
): TooltipPosition => {
  if (!highlightedArea) {
    return {};
  }

  const minSpace = tooltipRect.height * 1.05;
  const spaceForArrow = 15;
  const minArrowOffset = 5;
  const actualArrowSize = 10;
  const maxArrowOffset = window.innerWidth * 0.9;
  const arrowOffset = Math.max(
    minArrowOffset,
    Math.min(highlightedArea.left + actualArrowSize, maxArrowOffset),
  );
  const hasSpaceOnTop = highlightedArea.top > minSpace;
  if (hasSpaceOnTop) {
    return {
      bottomOffset: window.innerHeight - highlightedArea.top + spaceForArrow,
      arrowPosition: 'bottom',
      arrowOffset,
    };
  }
  return { topOffset: highlightedArea.bottom + spaceForArrow, arrowPosition: 'top', arrowOffset };
};

export const calculatePositionOnDesktop = (
  highlightedArea: DOMRect,
  tooltipRect: DOMRect,
): TooltipPosition => {
  if (!highlightedArea) {
    return {};
  }

  const minSpaceHorizontal = tooltipRect.width * 1.05;
  const minSpaceVertical = tooltipRect.height * 1.05;
  const spaceForArrow = 15;
  const arrowOffset = highlightedArea.height * 0.5;
  const hasSpaceOnLeft = highlightedArea.left > minSpaceHorizontal;
  if (hasSpaceOnLeft) {
    const minTopOffset = 5;
    return {
      rightOffset: window.innerWidth - highlightedArea.left + spaceForArrow,
      topOffset: Math.min(minTopOffset, window.innerHeight - minSpaceVertical),
      arrowPosition: 'right',
      arrowOffset,
    };
  }
  return {
    leftOffset: highlightedArea.left + highlightedArea.width + spaceForArrow,
    topOffset: Math.min(highlightedArea.top, window.innerHeight - minSpaceVertical),
    arrowPosition: 'left',
    arrowOffset,
  };
};

export const checkIsBlocked = (querySelectors: string[]): boolean =>
  querySelectors.some(qs => document.querySelector(qs));
