import React from 'react';
import { isBefore } from 'date-fns';

import AppButton from 'components/shared/forms/AppButton/AppButton';
import { IconTooltipInfo } from 'components/shared/IconTooltip';
import { useCurrentUser } from 'shared/hooks';
import { useRefreshShoppingCart } from 'shared/mutations';
import { ShoppingCart } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../../locale/messages';
import { isUsingHedgePricing } from '../ShoppingCart.helpers';
import { refreshButtonStyles } from '../ShoppingCart.styles';

interface Props {
  activeCart: ShoppingCart;
  onRefresh(): Promise<void>;
}

const RefreshButton = ({ activeCart, onRefresh }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const isUsingHedge = isUsingHedgePricing(currentUser);
  const refreshCart = useRefreshShoppingCart();

  const refresh = async () => {
    try {
      await refreshCart.mutateAsync(activeCart.id);
      await onRefresh();
      dispatch(
        snackBarPushSuccess(
          intl.formatMessage({
            id: `ShoppingCart.RefreshCart.Success.${activeCart.hedgePricing ? 'Hedge' : 'Market'}`,
          }),
        ),
      );
    } catch (error) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  const handleRefreshCart = async () => {
    const hedgesExpired =
      !!activeCart.hedgeExpiresAt && isBefore(new Date(activeCart.hedgeExpiresAt), new Date());
    const requireModal = isUsingHedge && (hedgesExpired || activeCart.hedgesDiffer);

    if (!requireModal) {
      return refresh();
    }

    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'ShoppingCart.RefreshCart.ExpiredHedge' }),
        closeMessage: intl.formatMessage({ id: 'Global.No' }),
        confirmMessage: intl.formatMessage({ id: 'Global.Yes' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          dispatch(hideModal());
          await refresh();
        },
      }),
    );
  };

  const priceMismatch = isUsingHedge !== activeCart.hedgePricing;

  return (
    <AppButton
      onClick={handleRefreshCart}
      disabled={priceMismatch || refreshCart.isLoading}
      className={refreshButtonStyles}
    >
      {priceMismatch && (
        <IconTooltipInfo
          tooltip={intl.formatMessage({ id: 'ShoppingCart.RefreshCart.PriceMismatch' })}
          size="20px"
        />
      )}
      <FormattedMessage id="ShoppingCart.RefreshCart" />
    </AppButton>
  );
};

export default RefreshButton;
