import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { keyframes } from '@emotion/css';
import styled from '@emotion/styled';

import { DraggableRow } from 'components/shared/DraggableRow/DraggableRow';
import { DndTypes } from 'shared/constants';
import { useAutoScroll, useMediaQuery } from 'shared/hooks';
import { Converter, ConvertersGroup } from 'shared/types';
import { fetchConvertersGroupsSuccessAction } from 'store/convertersGroupsSlice';
import {
  clearPartialConverters,
  fetchPartialConverters,
  fetchPartialConvertersSuccessAction,
} from 'store/partialConvertersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateConvertersGroup } from 'store/updateConvertersGroupSlice';
import { MEDIA_QUERY } from 'theme';
import { TypedFormattedMessage } from '../../locale/messages';

const expand = keyframes`
  from {
    transform: scale(1,0);
  }
   to {
    transform: scale(1,1);
  }
`;

const PartialConvertersList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 38px;
  max-height: calc(100vh - 99px);
  overflow-y: auto;

  @media ${MEDIA_QUERY.MAX_XL} {
    max-height: unset;
  }

  & > p {
    padding: 4px 8px;
    font-size: 16px;
    transform-origin: top;
    animation: ${expand} 0.3s ease;
  }
`;

const PartialConvertersSection = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { partialConverters } = useAppSelector(state => state.partialConverters);
  const { convertersGroups } = useAppSelector(state => state.convertersGroups);
  const keysToDisplay = ['identifier'] as const;

  useAutoScroll({
    enabled: useMediaQuery(MEDIA_QUERY.MAX_XL),
  });

  const [{ isOver, canDrop }, drop] = useDrop<
    { group: ConvertersGroup; id: number },
    unknown,
    { isOver: boolean; canDrop: boolean }
  >({
    accept: DndTypes.CONVERTERS_GROUPS,
    drop: async item => {
      const { group, ...removedConverter } = item;
      if (group) {
        const converters = group.converters.filter(c => c.id !== removedConverter.id);
        const newGroups = convertersGroups.map(g =>
          g.id === group.id
            ? { ...g, converters: g.converters.filter(c => c.id !== removedConverter.id) }
            : g,
        );

        await dispatch(
          fetchPartialConvertersSuccessAction([...partialConverters, removedConverter]),
        );
        await dispatch(fetchConvertersGroupsSuccessAction(newGroups));

        try {
          const data = await dispatch(
            updateConvertersGroup({ converters, files: group.files }, group.id),
          );
          dispatch(
            snackBarPushSuccess(
              <TypedFormattedMessage
                id={
                  (data as unknown)
                    ? 'Global.PartialConverters.GroupUpdated'
                    : 'Global.PartialConverters.GroupRemoved'
                }
              />,
            ),
          );
        } catch {
          dispatch(
            snackBarPushFailure(<TypedFormattedMessage id="Global.Error.SomethingWentWrong" />),
          );
        }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  useEffect(() => {
    dispatch(fetchPartialConverters());

    return () => {
      dispatch(clearPartialConverters());
    };
  }, []);

  return (
    <PartialConvertersList ref={drop}>
      {isActive && (
        <p>
          <TypedFormattedMessage id="Global.DropToAssign" />
        </p>
      )}
      {[...(partialConverters as unknown as Converter[])]
        .sort((a, b) => a.identifier.localeCompare(b.identifier))
        .map(converter => (
          <DraggableRow
            key={converter.id}
            item={{ ...converter }}
            keysToDisplay={keysToDisplay}
            photo="files"
            type={DndTypes.CONVERTERS_GROUPS}
          />
        ))}
    </PartialConvertersList>
  );
};

export { PartialConvertersSection };
