import React from 'react';
import styled from '@emotion/styled';

import { Star } from './Star';

const RatingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-row: column;
  gap: 6px;
`;

interface Props {
  rating: number;
  max?: number;
}

const Rating = ({ rating = 1, max = 5 }: Props): React.ReactElement => (
  <RatingWrapper>
    {[...Array(max)].map((el, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Star key={index} filled={index < rating} />
    ))}
  </RatingWrapper>
);

export { Rating };
