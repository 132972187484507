import React from 'react';
import styled from '@emotion/styled';

import { Badge } from 'components/shared/Badge';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate } from 'helpers/dateTime/dateTime';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useExtendedTheme } from 'shared/hooks';
import { CompanyTheme, ItemAction, NotificationStatus } from 'shared/types';
import { clearNotificationDetails } from 'store/notificationDetails';
import { notificationResolve } from 'store/notificationResolve';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useTypedIntl } from './locale/messages';

const NotificationMessageContainer = styled.div<{ theme?: CompanyTheme }>`
  a {
    color: ${({ theme }) => theme.mainColor} !important;
  }
`;

const StyledNotificationDetails = styled.div`
  align-self: flex-start;
`;

export const NotificationDetails = (): React.ReactElement | null => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const authorization = useAuthorization();
  const notification = useAppSelector(state => state.notificationDetails.notification!);
  const resolveIsPending = useAppSelector(state => state.notificationResolve.isPending);
  const theme = useExtendedTheme();

  const handleMarkAsResolved = async () => {
    try {
      await dispatch(notificationResolve(notification.id));
      await dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'NotificationDetails.Resolve.Success' })),
      );
      dispatch(clearNotificationDetails());
    } catch {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  if (!notification) return null;

  const actions: ItemAction[] = [
    {
      id: 1,
      label: intl.formatMessage({ id: 'NotificationDetails.Resolve' }),
      onClick: () => handleMarkAsResolved(),
      visible: notification.status === NotificationStatus.OPEN,
      dataCy: () => `btn-mark-as-resolve`,
    },
  ];

  return (
    <LoadableContent loading={resolveIsPending} mode={LoadableContent.MODE.FULL} drawContent>
      <Details
        title={intl.formatMessage({ id: 'NotificationDetails.Title' })}
        onClose={() => {
          dispatch(clearNotificationDetails());
          return true;
        }}
        open={!!notification}
        actions={actions}
      >
        <StyledNotificationDetails>
          <DetailsSection
            title={intl.formatMessage({ id: 'NotificationDetails.Details' })}
            template={[
              authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS)
                ? 'subject subject status'
                : 'subject subject subject',
              'text text text',
            ]}
            fields={[
              {
                name: 'subject',
                label: intl.formatMessage({ id: 'NotificationDetails.Subject' }),
                value: notification.subject,
              },
              authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS) && {
                name: 'status',
                label: intl.formatMessage({ id: 'NotificationDetails.Status' }),
                value: (
                  <Badge
                    size="S"
                    color={
                      notification.status === NotificationStatus.RESOLVED
                        ? theme.colors.emerald
                        : theme.colors.pictonBlue
                    }
                  >
                    {intl.formatMessage({
                      id: `Notifications.ListItem.Status.${notification.status}`,
                    })}
                  </Badge>
                ),
              },
              {
                name: 'text',
                label: intl.formatMessage({ id: 'NotificationDetails.Text' }),
                // eslint-disable-next-line react/no-danger
                value: (
                  <NotificationMessageContainer
                    dangerouslySetInnerHTML={{ __html: notification.text }}
                  />
                ),
              },
            ].filter(isTruthy)}
          />
          {authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS) && (
            <DetailsSection
              title={intl.formatMessage({ id: 'NotificationDetails.Recipients' })}
              fields={notification.recipients?.map((recipient, index) => ({
                name: `recipient${index + 1}`,
                label: recipient.company.name,
                value: `${recipient.firstName} ${recipient.lastName}`,
              }))}
            />
          )}
        </StyledNotificationDetails>
        <DetailsFooter
          fields={[
            {
              name: 'createdAt',
              label: intl.formatMessage({ id: 'Global.CreatedOn' }),
              value: formatDate(notification.createdAt),
            },
            {
              name: 'readAt',
              label: intl.formatMessage({ id: 'NotificationDetails.ReadAt' }),
              value: formatDate(notification.readAt),
            },
            authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS) && {
              name: 'resolvedAt',
              label: intl.formatMessage({ id: 'NotificationDetails.ResolvedAt' }),
              value: notification.resolvedAt
                ? formatDate(notification.resolvedAt)
                : SHARED.LONG_DASH,
            },
            authorization(PERMISSIONS.NOTIFICATIONS.ALL_DETAILS) && {
              name: 'resolvedBy',
              label: intl.formatMessage({ id: 'NotificationDetails.ResolvedBy' }),
              value: notification.resolvedBy
                ? `${notification.resolvedBy.firstName} ${notification.resolvedBy.lastName}`
                : SHARED.LONG_DASH,
            },
          ].filter(isTruthy)}
        />
      </Details>
    </LoadableContent>
  );
};
