import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Identifier } from 'dnd-core';
import { isNil } from 'lodash';

import closeIcon from 'assets/images/icons/Close.svg';
import { Icon } from 'components/shared/Buttons';
import { DndTypes } from 'shared/constants';
import { useExtendedTheme } from 'shared/hooks';
import { PhotoShapeUnion } from './FieldPhotos';
import {
  FieldPhotosPhotoContainerStyle,
  FieldPhotosPhotoRemoveButtonStyle,
  FieldPhotosPhotoStyle,
  FiledPhotosInvalidPlaceholder,
} from './FieldPhotos.style';
import { SortableItem } from '../../SortableList/SortableList';

type PhotoWithIndex = PhotoShapeUnion & { index: number };
interface PhotoPreviewProps {
  onRemove: () => void;
  photo: PhotoWithIndex;
  allowedExtensions: string[];
  onSortEnd?: (oldIndex: number, newIndex: number) => void;
  largePreview?: boolean;
  onFocus?: () => void;
  disabled?: boolean;
}

const PhotoPreviewImage = ({
  photo,
  allowedExtensions,
}: Pick<PhotoPreviewProps, 'photo' | 'allowedExtensions'>): React.ReactElement => {
  if ('url' in photo) {
    return <img alt={photo.name} src={photo.url} draggable={false} />;
  }
  if (
    allowedExtensions.some(
      ext => !isNil(photo.file?.name) && new RegExp(`${ext}$`, 'i').test(photo.file!.name),
    )
  ) {
    return <img alt={photo.file?.name ?? photo.name} src={photo.image} draggable={false} />;
  }
  return <FiledPhotosInvalidPlaceholder title={photo.file?.name} />;
};

export const PhotoPreview = ({
  photo,
  onRemove,
  onFocus,
  allowedExtensions,
  largePreview,
  disabled,
  onSortEnd,
}: PhotoPreviewProps) => {
  const theme = useExtendedTheme();

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<SortableItem, void, { handlerId: Identifier | null }>({
    accept: DndTypes.PHOTO_PREVIEW_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(dragItem) {
      const dragIndex = dragItem.index;
      const dropIndex = photo.index;
      if (!ref.current || dragIndex === dropIndex) return;
      onSortEnd?.(dragIndex, dropIndex);
      // eslint-disable-next-line no-param-reassign
      dragItem.index = dropIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    canDrag: !disabled,
    type: DndTypes.PHOTO_PREVIEW_ITEM,
    item: () => photo,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      data-handler-id={handlerId}
      ref={ref}
      draggable
      className={FieldPhotosPhotoContainerStyle(theme, largePreview, isDragging)}
    >
      <div className={FieldPhotosPhotoStyle(largePreview)}>
        <PhotoPreviewImage photo={photo} allowedExtensions={allowedExtensions} />
      </div>
      <button
        type="button"
        className={FieldPhotosPhotoRemoveButtonStyle(theme)}
        onClick={onRemove}
        onFocus={onFocus}
      >
        <Icon icon={closeIcon} size="18px" />
      </button>
    </div>
  );
};
