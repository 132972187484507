import * as yup from 'yup';

import {
  NUMBER_2_DIGITS_PRECISION,
  NUMBER_3_DIGITS_PRECISION,
  NUMBER_DECIMAL,
} from 'shared/constants/regularExpressions';
import { TypedIntlShape } from '../../locale/messages';

export const singleNonstandardConverterFormSchema = (intl: TypedIntlShape): yup.AnyObjectSchema => {
  const assaySchema = () =>
    yup
      .string()
      .nullable()
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.GreaterOrEqual' }, { number: 0 }),
        test: value => (value ? Number(value) >= 0 : true),
      })
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.ThreeDigits' }),
        test: value => (value ? NUMBER_DECIMAL.test(value) : true),
      })
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.ThreeDigits' }),
        test: value => (value ? NUMBER_3_DIGITS_PRECISION.test(value) : true),
      });

  const confirmSchema = (field: string) =>
    yup.string().test({
      name: 'isValid',
      message: intl.formatMessage({ id: 'NonstandardConvertersForm.Errors.ConfirmField' }),
      test(val) {
        return val === this.parent[field];
      },
    });

  return yup.object().shape({
    material: yup
      .string()
      .required(intl.formatMessage({ id: 'NonstandardConvertersForm.Errors.Material' }))
      .trim()
      .min(1, intl.formatMessage({ id: 'NonstandardConvertersForm.Errors.Material' }))
      .max(50),
    pricePer: yup.string().required(),
    buyingPercentAdjustment: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'NonstandardConvertersForm.Errors.BuyingPercentAdjustmentRequired',
        }),
      )
      .test({
        name: '0to150',
        message: intl.formatMessage({
          id: 'NonstandardConvertersForm.Errors.BuyingPercentAdjustment',
        }),
        test(val) {
          if (!val) {
            return true;
          }
          return Number(val) >= 0 && Number(val) <= 150;
        },
      })
      .matches(NUMBER_DECIMAL, {
        message: intl.formatMessage({ id: 'Global.Validation.TwoDigits' }),
      })
      .matches(NUMBER_2_DIGITS_PRECISION, {
        message: intl.formatMessage({
          id: 'Global.Validation.TwoDigits',
        }),
      }),
    dollarPriceAdjustment: yup
      .string()
      .required(
        intl.formatMessage({ id: 'NonstandardConvertersForm.Errors.DollarPriceAdjustment' }),
      )
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.TwoDigits' }),
        test: value => (value ? /^-?\d+(\.\d{1,2})?$/.test(value) : true),
      }),
    weightDryLbs: yup
      .string()
      .test({
        message: intl.formatMessage({ id: 'Global.Validation.TooSmall' }),
        test: value => (value ? Number(value) > 0 : true),
      })
      .matches(NUMBER_DECIMAL, {
        message: intl.formatMessage({ id: 'Global.Validation.ThreeDigits' }),
      })
      .matches(NUMBER_3_DIGITS_PRECISION, {
        message: intl.formatMessage({ id: 'Global.Validation.ThreeDigits' }),
      }),
    platinumAssay: assaySchema(),
    palladiumAssay: assaySchema(),
    rhodiumAssay: assaySchema(),
    confirmWeightDryLbs: confirmSchema('weightDryLbs'),
    confirmPlatinumAssay: confirmSchema('platinumAssay'),
    confirmPalladiumAssay: confirmSchema('palladiumAssay'),
    confirmRhodiumAssay: confirmSchema('rhodiumAssay'),
  });
};
