import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, SuccessResponse } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum TwoFactorAuthenticationKeys {
  TwoFactorAuthenticationVerify = 'TwoFactorAuthenticationVerify',
  TwoFactorAuthenticationRequest = 'TwoFactorAuthenticationRequest',
}

export const useTwoFactorAuthenticationVerify = (
  options?: UseMutationOptions<void, AlphamartHttpError, string>,
) =>
  useMutation<void, AlphamartHttpError, string>(
    async code => {
      await getHttpClient().post<SuccessResponse>(
        `${apiHostname}/api/two-factor-authentication/verify-sms-code`,
        { code },
      );
    },
    { mutationKey: [TwoFactorAuthenticationKeys.TwoFactorAuthenticationVerify], ...options },
  );

export const useTwoFactorAuthenticationRequest = (
  options?: UseMutationOptions<void, AlphamartHttpError>,
) =>
  useMutation<void, AlphamartHttpError>(
    async () => {
      await getHttpClient().post<SuccessResponse>(
        `${apiHostname}/api/two-factor-authentication/request-sms-code`,
      );
    },
    { mutationKey: [TwoFactorAuthenticationKeys.TwoFactorAuthenticationRequest], ...options },
  );
