import React from 'react';
import { FormattedMessage } from 'react-intl';

import filterIcon from 'assets/images/icons/filter.svg';
import { useExtendedTheme } from 'shared/hooks';
import { FilterData } from 'store/shared/filtersSlice';
import { searchBarStyles, sidebarButtonStyles, sidebarStyles } from './SearchBar.styles';
import { SearchChangEvent, SearchInput } from './SearchInput';
import { Icon } from '../Buttons';
import AppButton from '../forms/AppButton/AppButton';
import { ListModeButton } from '../ListModeButton/ListModeButton';

interface FilterButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  count?: number;
}

export const FilterButton = (props: FilterButtonProps): React.ReactElement => {
  const theme = useExtendedTheme();
  return (
    <AppButton styleType="neutral" className={sidebarButtonStyles(theme)} {...props}>
      <Icon icon={filterIcon} color={theme.colors.white} />
      <FormattedMessage id="Global.Filters" />
    </AppButton>
  );
};

interface SearchBarProps {
  onFiltersToggle?: () => void;
  savedFilters?: FilterData;
  onSearchChanged?: (changed: SearchChangEvent) => void;
  searchAfter?: number;
  chooseListType?: boolean;
  isSearchPending?: boolean;
  initialQuery?: string;
  searchInputVisible?: boolean;
  addSearchSession?: boolean;
}

export const SearchBar = ({
  onSearchChanged,
  onFiltersToggle,
  initialQuery,
  savedFilters,
  searchAfter = 3,
  chooseListType = false,
  isSearchPending,
  searchInputVisible = true,
  addSearchSession = false,
}: SearchBarProps): React.ReactElement => (
  <div className={searchBarStyles(searchInputVisible)} data-cy="search-bar">
    {searchInputVisible && (
      <SearchInput
        onSearchChanged={ev => onSearchChanged?.(ev)}
        loadingState={isSearchPending}
        searchAfter={searchAfter}
        initialQuery={initialQuery}
        addSearchSession={addSearchSession}
      />
    )}
    <div className={sidebarStyles(chooseListType)}>
      {chooseListType && <ListModeButton />}
      {onFiltersToggle && (
        <FilterButton count={savedFilters?.diff.count} onClick={onFiltersToggle} />
      )}
    </div>
  </div>
);
