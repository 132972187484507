import React from 'react';
import styled from '@emotion/styled';

import { Theme } from 'theme';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import { withFilterWrapper } from '../FieldWrapper/FilterWrapper';

export const Checkbox = styled.div<{ theme?: Theme; size?: string; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;

  & > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    cursor: pointer;

    &:checked + label svg path {
      stroke-dashoffset: 0;
    }

    &:focus:not(:disabled) + label {
      transform: scale(1.03);
    }

    &:hover:not(:disabled) + label {
      border-color: ${({ theme }) => theme.colors.whisper};
    }

    & + label {
      flex-shrink: 0;
      display: block;
      border: 2px solid ${({ theme }) => theme.colors.alphaGray};
      width: ${({ size }) => size ?? '48px'};
      height: ${({ size }) => size ?? '48px'};
      border-radius: 10%;
      cursor: pointer;
      transition: all 0.2s ease;
      margin-right: 16px;

      &:active {
        transform: scale(1.05);
        border-radius: 20%;
      }

      svg {
        pointer-events: none;
        path {
          fill: none;
          stroke: ${({ theme, disabled }) => (disabled ? theme.colors.alphaGray : theme.mainColor)};
          stroke-width: 6px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 100;
          stroke-dashoffset: 101;
          transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
        }
      }
    }

    &:disabled + label {
      transition: all 0.2s ease;
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.mineShaftLightest};
      border-color: ${({ theme }) => theme.colors.doveGray};

      &:active {
        transform: scale(1);
        border-radius: 10%;
      }
    }
  }
`;

export interface Props {
  children: React.ReactNode;
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  onClick?: (event?: React.SyntheticEvent) => void;
  size?: string;
  checked?: boolean;
  disabled?: boolean;
  onBlur?: (event?: React.FocusEvent) => void;
  value?: string;
}

const FieldCheckboxLayout = ({
  children,
  onBlur,
  onChange,
  onClick,
  name,
  size,
  disabled,
  checked,
  value,
}: Props): React.ReactElement => (
  <Checkbox size={size} onClick={onClick} disabled={disabled}>
    <input
      type="checkbox"
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      value={value}
    />
    <label htmlFor={name}>
      <svg viewBox="0,0,50,50">
        <path d="M5 30 L 20 45 L 45 5" />
      </svg>
    </label>
    <div>{children}</div>
  </Checkbox>
);

const FieldCheckbox = withFieldWrapper<Props>(props => <FieldCheckboxLayout {...props} />, {
  labelHeight: '0',
});
const FilterCheckbox = withFilterWrapper<Props>(props => <FieldCheckboxLayout {...props} />);

export { FieldCheckbox, FieldCheckboxLayout as FieldCheckboxRaw, FilterCheckbox };
