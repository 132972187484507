import React, { memo } from 'react';

import { SpinButton } from 'components/shared/SpinButton/SpinButton';
import { useEditItemInShoppingCart } from 'shared/mutations';
import { ShoppingCartItem } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { useTypedIntl } from '../../locale/messages';

interface Props {
  item: ShoppingCartItem;
  onUpdate(): Promise<void>;
}

const UpdateCount = ({ item, onUpdate }: Props): React.ReactElement => {
  const editItemInCart = useEditItemInShoppingCart();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();

  const onUpdateCount = async (value: number) => {
    try {
      await editItemInCart.mutateAsync({ id: item.id, unitsCount: value });
      await onUpdate();
    } catch (e) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  return (
    <SpinButton
      initialValue={item.unitsCount}
      min={1}
      onChange={onUpdateCount}
      disabled={editItemInCart.isLoading}
      data-cy={`shopping-cart-item-${item.id}-count`}
    />
  );
};

export default memo(UpdateCount);
