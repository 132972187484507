import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

const StyledH1 = styled.h1<{ theme?: Theme }>`
  display: flex;
  font-size: 22px;
  color: white;
  font-weight: normal;
  align-items: center;
  margin: 0;
  height: 38px;

  @media ${MEDIA_QUERY.MAX_XL} {
    justify-content: center;
    background: ${({ theme }) => theme.colors.mineShaft};
    border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
    width: 100%;
    text-align: center;
    height: 66px;
    margin: 0;
  }
`;

const StyledHeader = styled.header`
  display: grid;
  align-content: center;
  grid-template-columns: 1fr;

  @media ${MEDIA_QUERY.XL} {
    margin-bottom: 36px;
    grid-template-columns: auto 1fr;
  }
`;

const StyledStepsWrapper = styled.div<{ theme?: Theme }>`
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.lato};
  width: 100%;

  @media ${MEDIA_QUERY.MAX_XL} {
    margin: 17px 0;
  }
`;

const StyledSteps = styled.div<{ theme?: Theme }>`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.mineShaft};
  border-radius: 8px;
  padding: 12px 24px;

  @media ${MEDIA_QUERY.MAX_XL} {
    width: 50%;
    margin: 0 24px;
    justify-content: center;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100%;
  }
`;

const StyledSpan = styled.span`
  font-size: 12px;
  padding-right: 28px;
`;

const StyledUl = styled.ul`
  display: flex;
  flex-flow: wrap;
  padding: 0;
  margin: 0;
`;

const StyledLi = styled.li<{ theme?: Theme; active?: boolean }>`
  display: inline-block;
  padding: 6px 16px;
  border-radius: 8px;
  margin: 5px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.alphaGray : theme.colors.mineShaftLighter};
`;

const FormContent = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 32px;

  @media ${MEDIA_QUERY.XL} {
    grid-template-columns: calc(6 * 32px) 1fr;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 0 24px;
  }

  & > * {
    margin-bottom: 17px;

    :last-child {
      margin-bottom: 32px;
    }

    @media ${MEDIA_QUERY.XL} {
      margin-bottom: 32px;
    }
  }
`;

const FormActions = styled.div`
  grid-area: actions;
  display: flex;
  justify-content: flex-end;

  @media ${MEDIA_QUERY.MAX_XL} {
    margin: 0 24px 24px;
  }

  *:not(:last-child) {
    margin-right: 32px;
  }
`;

const StyledWizardForm = styled.form<{ theme?: Theme }>`
  height: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.codGrayDarker};
  flex-direction: column;
  align-items: center;

  @media ${MEDIA_QUERY.XL} {
    padding: 32px 64px;
  }

  .form-content {
    max-width: 100%;
    width: calc(40 * 32px);
    display: grid;
    grid-column-gap: 32px;
    grid-template-areas: 'header' 'content' 'actions';
    grid-template-columns: auto;
    grid-template-rows: max-content min-content 1fr;

    @media ${MEDIA_QUERY.XL} {
      grid-template-areas: 'header header' 'content .' 'actions .';
      grid-template-columns: auto calc(6 * 32px);
    }
  }
`;

export {
  FormActions,
  FormContent,
  StyledH1,
  StyledHeader,
  StyledLi,
  StyledSpan,
  StyledSteps,
  StyledStepsWrapper,
  StyledUl,
  StyledWizardForm,
};
