import React from 'react';
import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

const Required = styled.span<{ required?: boolean; theme?: Theme }>`
  visibility: ${({ required }) => (required ? 'visible' : 'hidden')};
  color: ${({ theme }) => theme.mainColor};
  font-size: 16px;
  padding: 0 2px;

  @media ${MEDIA_QUERY.XL} {
    position: absolute;
    left: 100%;
  }
`;

interface Props {
  id?: string;
  children?: React.ReactNode;
  labelSuffix?: string | null;
  disabled?: boolean;
  className?: string;
  required?: boolean;
}

const Label = ({
  id,
  children,
  labelSuffix = ':',
  required,
  disabled,
  className,
}: Props): React.ReactElement => (
  <div className={className}>
    <label htmlFor={id}>
      {children}
      {children && labelSuffix}
      <Required required={required && !disabled}>*</Required>
    </label>
  </div>
);

const LabelStyled = React.memo(styled(Label)<{ theme?: Theme; height?: string }>`
  grid-area: label;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.alphaGray};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: bold;
  position: relative;
  height: ${({ height }) => height || '48px'};
  margin: 0 0 auto 0;

  @media ${MEDIA_QUERY.XL} {
    justify-content: flex-end;
    text-align: right;
  }
`);

export { LabelStyled as Label };
