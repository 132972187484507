import { css } from '@emotion/css';

import { layers, MEDIA_QUERY, Theme } from 'theme';
import 'react-datepicker/dist/react-datepicker.css';

export const containerDatePickerStyles = (theme: Theme): string => css`
  display: grid;
  grid-template-columns: 50% 50%;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: ${layers.datePickerPopper};
    margin-top: 5px;
    left: -11px !important;

    @media ${MEDIA_QUERY.MAX_XL} {
      left: 0 !important;
    }

    .react-datepicker {
      background-color: ${theme.colors.mineShaftLighter};
      border-color: ${theme.colors.alphaGray};
      border-radius: 21px;
      overflow: hidden;
      font-family: ${theme.fonts.lato};

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__navigation {
        outline: none;

        &--previous:focus {
          border-right-color: ${theme.mainColor};
        }

        &--next:focus {
          border-left-color: ${theme.mainColor};
        }
      }

      .react-datepicker__day--keyboard-selected {
        background-color: ${theme.colors.mineShaftLighter};
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          background-color: ${theme.colors.mineShaftLighter};

          .react-datepicker__current-month {
            color: ${theme.colors.alphaGray};
          }

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              color: ${theme.colors.alphaGray};
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              color: ${theme.colors.alphaGray};

              &:hover {
                border-radius: 50%;
                color: ${theme.colors.mineShaft};
              }
            }

            .react-datepicker__day--disabled {
              color: ${theme.colors.zambezi};
              cursor: not-allowed;

              &:hover {
                color: ${theme.colors.zambezi};
              }
            }

            .react-datepicker__day--selected,
            .react-datepicker__day--keyboard-selected {
              background-color: ${theme.colors.mineShaft};
              color: ${theme.colors.whisper};
              border-radius: 50%;

              &:hover {
                color: ${theme.colors.white};
              }
            }

            .react-datepicker__day--outside-month {
              color: ${theme.colors.doveGray};
            }
          }
        }
      }
    }
  }
`;

export const datePickerStyles = (side: 'from' | 'to', theme: Theme): string => css`
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.alphaGray};
  border-left-width: ${side === 'from' ? '1px' : 0};
  border-radius: ${side === 'from' ? '20px 0 0 20px' : '0 20px 20px 0'};
  background-color: transparent;
  padding: 0 16px;
  text-align: center;
  color: ${theme.colors.alphaGray};
  font-size: 12px;
  transition: border-color 0.2s ease;
  caret-color: ${theme.colors.alphaGray};
  font-family: ${theme.fonts.lato};
  font-weight: bold;
  line-height: 15px;
  order: 0;
  z-index: ${layers.datePicker};

  &:focus,
  &:hover {
    outline: none;
    border-color: ${theme.colors.alphaGray};
    cursor: pointer;
  }

  &:nth-of-type(2) {
    border-radius: 21px 0 0 21px;
    border-right: none;
  }

  + .react-datepicker__close-icon::after {
    background-color: transparent;
  }
`;
