export enum geoLocationErrors {
  USER_DENIED = 'User denied Geolocation',
  NOT_SUPPORTED = 'Geolocation is not supported',
  TIMEOUT = 'Timed out',
  POSITION_UNAVAILABLE = 'Position unavailable',
}

export enum geoLocationErrorCodes {
  USER_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}
