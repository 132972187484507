import { MouseEventHandler } from 'react';
import styled from '@emotion/styled';
import { Cell, Column, Row, Table } from '@tanstack/react-table';

import { MEDIA_QUERY, Theme } from 'theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowProps<T = any> = {
  rowColor?: string;
  table?: Table<T>;
  row?: Row<T>;
  parentRow?: Row<T> | null;
  theme?: Theme;
  onClick?: MouseEventHandler;
  isClickable?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CellProps<T = any> = {
  cell?: Cell<T, unknown>;
  column?: Column<T, unknown>;
};

export const ItemThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;

export const NoResultsRow = styled.div<{
  theme?: Theme;
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mineShaft};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.whisper};
  display: flex;
  font: bold 12px ${({ theme }) => theme.fonts.montserrat};
  grid-column: -1/1;
  justify-content: center;
  min-height: 54px;
  padding: 10px 32px;
`;

export const DataListContainer = styled.div`
  position: relative;
  min-height: calc(100% - 80px);

  @media ${MEDIA_QUERY.MAX_MD} {
    min-height: calc(100% - 128px);
  }
`;
