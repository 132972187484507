import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useDefaultRedirect } from 'shared/hooks';
import { MEDIA_QUERY, Theme, theme as defaultTheme } from 'theme';
import AppButton from '../forms/AppButton/AppButton';

interface Props {
  className?: string;
}

function ErrorPageLayout({ className }: Props): React.ReactElement {
  const redirectTo = useDefaultRedirect();
  const handleRedirect = () => window.location.replace(redirectTo);

  return (
    <div className={className}>
      <p className="error">
        <FormattedMessage id="Global.Error" />
      </p>
      <div className="errorCode">
        500
        <div className="glitch">500</div>
      </div>
      <p className="message">
        <FormattedMessage id="Global.ErrorPageMessage" />
        <AppButton onClick={handleRedirect}>
          <FormattedMessage id="Global.ErrorPageButton" />
        </AppButton>
      </p>
    </div>
  );
}

const glitchFrame = keyframes`
	 0% {
        clip: rect(62px, 9999px, 188px, 0);
    }
    10% {
        clip: rect(224px, 9999px, 154px, 0);
    }
    20% {
        clip: rect(170px, 9999px, 154px, 0);
    }
    30% {
        clip: rect(54px, 9999px, 194px, 0);
    }
    40% {
        clip: rect(128px, 9999px, 196px, 0);
    }
    50% {
        clip: rect(122px, 9999px, 170px, 0);
    }
    60% {
        clip: rect(198px, 9999px, 228px, 0);
    }
    70% {
        clip: rect(68px, 9999px, 230px, 0);
    }
    80% {
        clip: rect(196px, 9999px, 258px, 0);
    }
    90% {
        clip: rect(86px, 9999px, 192px, 0);
    }
    100% {
        clip: rect(164px, 9999px, 128px, 0);
    }
`;

const glitchBorder = (top: boolean) => keyframes`
	 0% {
        transform: translateY(${top ? 62 : 188}px);
    }
    10% {
        transform: translateY(${top ? 224 : 154}px);;
    }
    20% {
        transform: translateY(${top ? 170 : 154}px);
    }
    30% {
        transform: translateY(${top ? 54 : 194}px);
    }
    40% {
        transform: translateY(${top ? 128 : 196}px);
    }
    50% {
        transform: translateY(${top ? 122 : 170}px);
    }
    60% {
        transform: translateY(${top ? 198 : 228}px);
    }
    70% {
        transform: translateY(${top ? 68 : 230}px);
    }
    80% {
        transform: translateY(${top ? 196 : 258}px);
    }
    90% {
        transform: translateY(${top ? 86 : 192}px);
    }
    100% {
        transform: translateY(${top ? 164 : 128}px);
    }
`;

const ErrorPage = styled(ErrorPageLayout)<{ theme?: Theme }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 256px;
  color: ${defaultTheme.colors.whisper};
  background: ${({ theme }) => theme.background || defaultTheme.background};
  font-family: ${defaultTheme.fonts.montserrat};

  @media ${MEDIA_QUERY.MAX_SM} {
    font-size: 180px;
  }

  & > .errorCode {
    position: relative;
    font-weight: bold;

    ::after,
    ::before {
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.background || defaultTheme.background};
    }

    ::after {
      animation: ${glitchBorder(true)} 2500ms infinite linear alternate-reverse;
    }
    ::before {
      animation: ${glitchBorder(false)} 2500ms infinite linear alternate-reverse;
    }

    & > .glitch {
      position: absolute;
      top: 0;
      left: -2px;
      width: 100%;
      color: $headline-color;
      text-shadow: 5px 0 ${defaultTheme.background}, -1px 0 #90195c, -3px 0 #2c8ae1;
      overflow: hidden;
      animation: ${glitchFrame} 2500ms infinite linear alternate-reverse;
    }
  }

  p.error {
    font-size: 48px;
    color: ${defaultTheme.colors.fuzzyWuzzyBrown};
    margin: 0;
  }

  p.message {
    font-size: 24px;
    margin: 0 16px;
    text-align: center;

    button {
      display: inline-block;
      text-decoration: underline;
      font-size: 24px;
      padding: 0 8px;
      height: unset;

      &:hover {
        background-color: transparent;
      }

      ::before {
        border: none;
      }
    }

    @media ${MEDIA_QUERY.MAX_SM} {
      font-size: 16px;

      button {
        font-size: 16px;
        padding: 0 4px;
      }
    }
  }
`;

export { ErrorPage };
