import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const Line = styled.div<{ theme?: Theme }>`
  grid-area: line;
  box-sizing: border-box;
  height: 100%;
  width: 1px;
  border: 1px dashed ${({ theme }) => theme.colors.alphaGray};
  opacity: 0.32;
  margin: 0px auto;
  display: none;

  @media ${MEDIA_QUERY.XL} {
    display: block;
  }
`;
