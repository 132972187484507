import { css } from '@emotion/css';

import { layers, MEDIA_QUERY, Theme } from 'theme';

export const userInfoStyles = (
  menuOpened: boolean,
  theme: Theme,
  compact?: boolean,
  fullWidth?: boolean,
): string => css`
  display: flex;
  align-items: center;
  background: ${theme.colors.charcoal};
  padding: 12px ${compact ? '24px' : '40px'};
  justify-content: ${compact ? 'center' : 'space-between'};
  position: relative;
  max-width: 288px;
  border-left: 1px solid ${theme.colors.mineShaft};
  z-index: ${menuOpened ? layers.userProfile : 'unset'};
  height: 54px;

  ${fullWidth &&
  `
    max-width: 100%;
    padding-right: 56px;
  `} @media ${MEDIA_QUERY.MAX_MD} {
    display: none;
  }
`;

export const userInfoMenuStyles = (theme: Theme): string => css`
  position: absolute;
  bottom: 25%;
  background: ${theme.colors.mineShaftLighter};
  left: 90%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
`;

export const userInfoNameStyles = (theme: Theme): string => css`
  font-size: 12px;
  font-weight: bold;
  color: ${theme.colors.alto};
  font-family: ${theme.fonts.montserrat};
  line-height: 12px;
  word-break: break-word;
`;

export const userInfoMenuButtonStyles = css`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

export const userInfoMenuItemStyles = (theme: Theme): string => css`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  width: 100%;
  border: none;
  cursor: pointer;
  color: ${theme.colors.alto};
  background: ${theme.colors.mineShaftLighter};
  border-top: 1px solid ${theme.colors.mineShaft};
  outline: none;
  font-family: ${theme.fonts.montserrat};
  font-weight: bold;
  font-size: 12px;

  @media ${MEDIA_QUERY.MAX_MD} {
    padding: 0 32px 0 48px;
    background: ${theme.colors.codGrayDarker};
    border-top: none;
  }

  &:hover {
    background-color: ${theme.colors.mineShaft};
  }

  span:first-of-type {
    margin-right: 40px;
  }
`;

export const userInfoMenuItemIconStyles = css`
  margin: 0 32px 0 0;
  display: block;
`;

export const userInfoToggleIconStyles = css`
  display: block;
`;
