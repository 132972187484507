import React from 'react';
import styled from '@emotion/styled';

import arrowBackSilver from 'assets/images/icons/arrowBackSilver.svg';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from 'locale/messages';
import { shouldRender } from 'shared/helpers';
import { ItemAction } from 'shared/types';
import AppButton from '../forms/AppButton/AppButton';
import { ListActions } from '../List';

interface Props {
  actions?: ItemAction[];
  className?: string;
}

const ActionsButtonIcon = styled.img<{ isOpen: boolean }>`
  transition: transform 0.2s ease;
  ${({ isOpen }) => `transform: rotate(${isOpen ? '90' : '270'}deg);`}
`;

export function ActionsButton({ actions = [], className = '' }: Props): React.ReactElement | null {
  const intl = useTypedIntl();
  const availableActions = actions?.filter(shouldRender);

  return availableActions?.length > 0 ? (
    <div className={className}>
      {availableActions?.length === 1 ? (
        <AppButton
          onClick={availableActions[0].onClick}
          data-cy={availableActions[0].dataCy?.(null)}
        >
          {availableActions[0].label}
        </AppButton>
      ) : (
        <ListActions
          actions={availableActions}
          renderCustomOpenButton={({ toggleMenu, isMenuOpen, dropdownRef }) => (
            <AppButton onClick={toggleMenu} ref={dropdownRef}>
              <FormattedMessage id="Global.Actions" />
              <ActionsButtonIcon
                isOpen={isMenuOpen}
                src={arrowBackSilver}
                alt={intl.formatMessage({ id: 'Global.ToggleActionsMenu' })}
              />
            </AppButton>
          )}
        />
      )}
    </div>
  ) : null;
}
