import React from 'react';
import styled from '@emotion/styled';
import { isNil } from 'lodash';

import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { Language, SHARED, SUPPORTED_PORTAL_CURRENCIES } from 'shared/constants';
import { theme } from 'theme';

type PriceProps = {
  className?: string;
  value: number | null | undefined;
  currency?: SUPPORTED_PORTAL_CURRENCIES;
  language?: Language;
  prefix?: string | React.ReactNode | null;
};

const PrefixWrapper = styled.div`
  display: flex;
  gap: 5px;
  & > span:first-of-type {
    word-break: keep-all;
  }
`;

const nbspRemoval = (value: string) => {
  const nonBreakingSpace = new RegExp(String.fromCharCode(160), 'g');
  return value.replace(nonBreakingSpace, ' ');
};

const PriceLayout = React.memo(
  ({ className, value, currency, language, prefix }: PriceProps): React.ReactElement => {
    if (value === undefined) return <span>{SHARED.LONG_DASH}</span>;
    if (value === null)
      return (
        <span className={className}>
          <FormattedMessage id="Global.ComingSoon" />
        </span>
      );
    const formattedValue = formatCurrency(value, currency, 2, language);
    const formattedResult =
      value <= 0 || !value ? <FormattedMessage id="Global.NoValue" /> : nbspRemoval(formattedValue);

    return prefix ? (
      <PrefixWrapper>
        <span>{prefix}</span>
        <span className={className}>{formattedResult}</span>
      </PrefixWrapper>
    ) : (
      <span className={className}>{formattedResult}</span>
    );
  },
);

const Price = styled(PriceLayout)<{ value?: number | null; light?: boolean }>`
  color: ${({ value, light }) => {
    if (isNil(value)) return light ? theme.colors.whisper : theme.colors.alphaGray;
    if (value <= 0) return theme.colors.fuzzyWuzzyBrown;

    return theme.colors.emerald;
  }};
  overflow-wrap: break-word;
`;

export { nbspRemoval, Price };
