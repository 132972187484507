import React from 'react';
import DatePicker from 'react-datepicker';
import styled from '@emotion/styled';
import { setHours, setMinutes } from 'date-fns';

import { useTypedIntl } from 'locale/messages';
import { useLanguage } from 'shared/hooks';
import { layers, MEDIA_QUERY, Theme } from 'theme';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';
import 'react-datepicker/dist/react-datepicker.css';

const StyledContainerDatePicker = styled.div<{ theme?: Theme; withTime?: boolean }>`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: ${layers.datePickerPopper};
    margin-top: 5px;
    width: 330px;

    .react-datepicker {
      background-color: ${({ theme }) => theme.colors.mineShaftLighter};
      border-color: ${({ theme }) => theme.colors.alphaGray};
      border-radius: 21px;
      overflow: hidden;
      font-family: ${({ theme }) => theme.fonts.lato};

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__navigation {
        outline: none;

        &--previous:focus {
          border-right-color: ${({ theme }) => theme.mainColor};
        }

        &--next:focus {
          border-left-color: ${({ theme }) => theme.mainColor};
        }
      }

      .react-datepicker__day--keyboard-selected {
        background-color: ${({ theme }) => theme.colors.mineShaftLighter};
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          background-color: ${({ theme }) => theme.colors.mineShaftLighter};

          .react-datepicker__current-month {
            color: ${({ theme }) => theme.colors.alphaGray};
          }

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              color: ${({ theme }) => theme.colors.alphaGray};

              @media ${MEDIA_QUERY.MAX_SM} {
                ${({ withTime }) => withTime && `width: 1.3rem;`}
              }
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              color: ${({ theme }) => theme.colors.alphaGray};

              &:hover {
                border-radius: 50%;
                color: ${({ theme }) => theme.colors.mineShaft};
              }

              @media ${MEDIA_QUERY.MAX_SM} {
                ${({ withTime }) => withTime && `width: 1.3rem;`}
              }
            }

            .react-datepicker__day--disabled {
              color: ${({ theme }) => theme.colors.zambezi};
              cursor: not-allowed;

              &:hover {
                color: ${({ theme }) => theme.colors.zambezi};
              }
            }

            .react-datepicker__day--selected,
            .react-datepicker__day--keyboard-selected {
              background-color: ${({ theme }) => theme.colors.mineShaft};
              color: ${({ theme }) => theme.colors.whisper};
              border-radius: 50%;

              &:hover {
                color: ${({ theme }) => theme.colors.white};
              }
            }

            .react-datepicker__day--outside-month {
              color: ${({ theme }) => theme.colors.doveGray};
            }
          }
        }
      }

      .react-datepicker__time-container {
        .react-datepicker__header--time {
          background-color: ${({ theme }) => theme.colors.mineShaftLighter};
          height: 59px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.alphaGray};
          display: flex;
          align-items: center;
          justify-content: center;

          .react-datepicker-time__header {
            color: ${({ theme }) => theme.colors.alphaGray};
          }
        }

        .react-datepicker__time {
          height: 209px;
          background-color: ${({ theme }) => theme.colors.mineShaftLighter};
          color: ${({ theme }) => theme.colors.alphaGray};

          .react-datepicker__time-box {
            height: 100%;

            .react-datepicker__time-list {
              height: 100% !important;
              width: 92px;

              .react-datepicker__time-list-item {
                &:hover {
                  color: ${({ theme }) => theme.colors.mineShaft};
                }

                &--disabled,
                &--disabled:hover {
                  cursor: not-allowed;
                  color: ${({ theme }) => theme.colors.charcoal};
                }

                &--selected {
                  background-color: ${({ theme }) => theme.colors.mineShaft};
                  color: ${({ theme }) => theme.colors.whisper};

                  &:hover {
                    color: ${({ theme }) => theme.colors.white};
                  }
                }
              }
            }
          }
        }
      }
    }

    @media ${MEDIA_QUERY.MAX_SM} {
      ${({ withTime }) => withTime && `min-width: 270px;`}
    }
  }
`;

const StyledDatePicker = styled(DatePicker)<{ theme?: Theme }>`
  grid-area: input;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.alphaGray};
  border-radius: 21px;
  background-color: transparent;
  padding: 0 32px;
  color: ${({ theme }) => theme.colors.alphaGray};
  font-size: 12px;
  transition: border-color 0.2s ease;
  caret-color: ${({ theme }) => theme.colors.alphaGray};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: bold;
  line-height: 15px;
  order: 0;
  z-index: ${layers.datePicker};

  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colors.alphaGray};
  }

  &:nth-of-type(2) {
    border-radius: 21px 0 0 21px;
    border-right: none;
  }
`;

const FieldDatePickerLayout = ({
  name,
  value,
  onChange,
  onTouch,
  onFocus,
  dateFormat,
  min,
  max,
  minTime,
  maxTime,
  showTimeSelect = false,
  timeIntervals = 60,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const handleDateChangeRaw = e => {
    e.preventDefault();
    onTouch?.(name);
  };

  const handleFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    if (onFocus) onFocus(event);

    onTouch?.(name);
    event.target.blur();
  };

  const defaultMinTime = setHours(setMinutes(new Date(), 0), 0);
  const defaultMaxTime = setHours(setMinutes(new Date(), 59), 23);
  const currentlang = useLanguage().toLowerCase();

  return (
    <StyledContainerDatePicker withTime={showTimeSelect}>
      <StyledDatePicker
        value={value as unknown as string}
        name={name}
        selected={value}
        dateFormat={dateFormat}
        onChangeRaw={handleDateChangeRaw}
        onChange={val => {
          onTouch?.(name);
          onChange?.(name, val as unknown as number);
        }}
        onFocus={handleFocus}
        showTimeSelect={showTimeSelect}
        timeCaption={intl.formatMessage({ id: 'Global.DateTimePicker.TimeCaption' })}
        timeIntervals={timeIntervals}
        minDate={min}
        maxDate={max}
        minTime={minTime ?? defaultMinTime}
        maxTime={maxTime ?? defaultMaxTime}
        locale={currentlang}
      />
    </StyledContainerDatePicker>
  );
};

interface Props {
  name: string;
  value?: Date;
  onChange?: (name: string, value: number) => void;
  onTouch?: (name: string) => void;
  dateFormat?: string;
  max?: Date;
  maxTime?: Date;
  showTimeSelect?: boolean;
  timeIntervals?: number;
  min?: Date;
  minTime?: Date;
  onFocus?: (event?: React.FocusEvent<HTMLDivElement>) => void;
}

const FieldDatePicker = withFieldWrapper<Props>(props => <FieldDatePickerLayout {...props} />);

export { FieldDatePicker };
