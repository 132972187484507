import React, { PropsWithChildren, ReactElement } from 'react';
import { IntlProvider } from 'react-intl';

import globalEnMessages from 'locale/en.json';
import globalEsMessages from 'locale/es.json';
import globalFrMessages from 'locale/fr.json';
import globalPlMessages from 'locale/pl.json';
import { Language } from 'shared/constants';
import { useLanguage } from 'shared/hooks/useLanguage';

type Messages = Record<Language, Record<string, string>>;
interface Props {
  messages?: Messages;
}

export function AlphamartIntlProvider({
  messages,
  children,
}: PropsWithChildren<Props>): ReactElement {
  const language = useLanguage();

  const intlMessages = {
    EN: { ...globalEnMessages, ...messages?.EN },
    PL: { ...globalPlMessages, ...messages?.PL },
    FR: { ...globalFrMessages, ...messages?.FR },
    ES: { ...globalEsMessages, ...messages?.ES },
  };

  return (
    <IntlProvider locale={language.toLocaleLowerCase()} messages={intlMessages[language]}>
      {children}
    </IntlProvider>
  );
}

export function withAlphamartIntlProvider<T>(
  C: React.FunctionComponent<T>,
  messages?: Messages,
): (props: React.JSX.IntrinsicAttributes & T) => React.ReactElement {
  return props => (
    <AlphamartIntlProvider messages={messages}>
      <C {...props} />
    </AlphamartIntlProvider>
  );
}
