import styled from '@emotion/styled';

import { Theme } from 'theme';

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  input:nth-of-type(1) {
    border-radius: 21px 0px 0px 21px;
    border-width: 1px 1px 1px 1px;
  }

  input:nth-of-type(2) {
    border-radius: 0px 21px 21px 0px;
    border-width: 1px 1px 1px 0px;
  }
`;

export const InputStyled = styled.input<{ theme?: Theme }>`
  height: 48px;
  width: 50%;
  box-sizing: border-box;
  border-radius: 21px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.alphaGray};
  transition: border-color 0.2s ease;
  font-family: ${({ theme }) => theme.fonts.lato};
  border-color: ${({ theme }) => theme.colors.alphaGray};
  border-style: solid;
  background-color: transparent;
  padding: 0 32px;
  color: ${({ theme }) => theme.colors.alphaGray};
  font-size: 12px;
  line-height: 15px;
  outline: none;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px ${({ theme }) => theme.background} inset !important;
    -moz-box-shadow: 0 0 0 100px ${({ theme }) => theme.background} inset !important;
    box-shadow: 0 0 0 100px ${({ theme }) => theme.background} inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.alphaGray} !important;
    -moz-text-fill-color: ${({ theme }) => theme.colors.alphaGray} !important;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
