import React, { ReactElement, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { Form } from 'components/shared/forms/Form/Form';
import { Section } from 'components/shared/forms/Section/Section';
import { SUPPORTED_PORTAL_CURRENCIES } from 'shared/constants';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { createCurrencyAdjustment } from 'store/createCurrencyAdjustmentSlice';
import { fetchCurrencyAdjustment } from 'store/currencyAdjustmentSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../locale/messages';

const UpdateExchangeRatesAdjustmentLayout = () => {
  const { currency } = useParams<{ currency: SUPPORTED_PORTAL_CURRENCIES }>();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const { currencyAdjustment } = useAppSelector(state => state.currencyAdjustment);

  const header = intl.formatMessage({
    id: currencyAdjustment?.id
      ? `UpdateExchangeRatesAdjustment.Header`
      : `CreateExchangeRatesAdjustment.Header`,
  });
  const successMessage = intl.formatMessage({
    id: currencyAdjustment?.id
      ? `UpdateExchangeRatesAdjustment.Message.Success`
      : `CreateExchangeRatesAdjustment.Message.Success`,
  });

  useEffect(() => {
    dispatch(fetchCurrencyAdjustment(currency!));
  }, [dispatch]);

  const validationSchema = yup.object().shape({
    adjustment: yup
      .string()
      .test({
        name: 'range',
        message: intl.formatMessage({
          id: 'UpdateExchangeRatesAdjustment.Errors.Adjustment.Range',
        }),
        test: val => !val || (Number(val) >= -10 && Number(val) <= 10),
      })
      .test({
        message: intl.formatMessage({
          id: 'UpdateExchangeRatesAdjustment.Errors.Adjustment.Precision',
        }),
        test: value => (value ? /^-?\d+(\.\d{1,2})?$/.test(value) : true),
      }),
  });

  const formikContext = useFormik({
    initialValues: { adjustment: currencyAdjustment?.adjustment ?? '' },
    initialTouched: { adjustment: true },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      try {
        await dispatch(
          createCurrencyAdjustment({
            adjustment: values.adjustment ? Number(values.adjustment) : null,
            currency: currency!,
          }),
        );
        dispatch(snackBarPushSuccess(successMessage));
        navigate('/company/settings');
      } catch (e) {
        formikContext.setSubmitting(false);
        dispatch(
          snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
        );
      }
    },
  });

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    getFieldMeta,
    handleBlur,
    isValidating,
    isSubmitting,
    isValid,
  } = formikContext;

  const onCancel = useCallback(() => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'UpdateExchangeRatesAdjustment.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/company/settings');
          dispatch(hideModal());
        },
      }),
    );
  }, [location, dispatch]);

  const submitDisabled = !(isValid && Object.keys(touched).length) || isValidating || isSubmitting;
  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  return (
    <Form
      header={header}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitDisabled={submitDisabled}
      context={formikContext}
    >
      <Section sectionName="hedgeInfo" template={['adjustment adjustment']}>
        <FieldInput
          label={intl.formatMessage({ id: 'UpdateExchangeRatesAdjustment.Adjustment' })}
          name="adjustment"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.adjustment}
          error={getErrors('adjustment')}
          suffix="%"
          data-cy="adjustment"
        />
      </Section>
    </Form>
  );
};

export const UpdateExchangeRatesAdjustment = (): ReactElement => (
  <AlphamartIntlProvider messages={messages}>
    <UpdateExchangeRatesAdjustmentLayout />
  </AlphamartIntlProvider>
);
