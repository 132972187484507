import { isNil } from 'lodash';
import * as yup from 'yup';

import { FeedTypes } from 'shared/constants';
import { Metals } from 'shared/types';
import { TypedIntlShape } from '../locale/messages';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateMetalPricesFormSchema = (
  totalPrice: {
    [Metals.PALLADIUM]: number;
    [Metals.PLATINUM]: number;
    [Metals.RHODIUM]: number;
  },
  intl: TypedIntlShape,
) => {
  const singleEntryValidation = (metal: Metals) =>
    yup.object().shape({
      type: yup
        .string()
        .required(intl.formatMessage({ id: 'Settings.MetalPrices.Errors.Source.Required' }))
        .nullable(),
      value: yup.mixed().when('type', {
        is: FeedTypes.MARKET_FEED_ADJUSTED,
        then: yup
          .mixed()
          .required(intl.formatMessage({ id: 'Settings.MetalPrices.Errors.Adjustment.Required' }))
          .test({
            message: intl.formatMessage({
              id: 'Settings.MetalPrices.Errors.Adjustment.CharactersAllowed',
            }),
            test: value => /^-?\d+(\.\d+)?$/.test(value),
          })
          .test({
            message: intl.formatMessage({ id: 'Settings.MetalPrices.Errors.Adjustment.Precision' }),
            test: value => (value ? /^-?\d+(\.\d{1,2})?$/.test(value) : true),
          })
          .test({
            message: intl.formatMessage(
              { id: 'Settings.MetalPrices.Errors.Adjustment.TooSmall' },
              { totalPrice: totalPrice[metal] },
            ),
            test: value => (value < 0 ? totalPrice[metal] >= Math.abs(value) : true),
          }),
        otherwise: yup.mixed().when('type', {
          is: FeedTypes.MANUAL_ENTRY,
          then: yup
            .mixed()
            .required(
              intl.formatMessage({ id: 'Settings.MetalPrices.Errors.ManualEntry.Required' }),
            )
            .test({
              message: intl.formatMessage({
                id: 'Settings.MetalPrices.Errors.ManualEntry.CharactersAllowed',
              }),
              test: value => /^-?\d+(\.\d+)?$/.test(value),
            })
            .test({
              message: intl.formatMessage({
                id: 'Settings.MetalPrices.Errors.ManualEntry.Precision',
              }),
              test: value => (value ? /^-?\d+(\.\d{1,2})?$/.test(value) : true),
            })
            .test({
              message: intl.formatMessage({
                id: 'Settings.MetalPrices.Errors.ManualEntry.TooSmall',
              }),
              test: value => !(value < 0),
            }),
        }),
      }),
      confirm: yup.mixed().when('type', {
        is: FeedTypes.MANUAL_ENTRY,
        then: yup
          .string()
          .nullable()
          .test({
            name: 'isValid',
            message: intl.formatMessage({ id: 'Settings.MetalPrices.Errors.ManualEntry.Confirm' }),
            test(val) {
              const parentValuePresent = !isNil(this.parent.value);
              return parentValuePresent ? val === this.parent.value : true;
            },
          }),
      }),
    });

  return yup.object().shape({
    [Metals.PALLADIUM]: singleEntryValidation(Metals.PALLADIUM),
    [Metals.PLATINUM]: singleEntryValidation(Metals.PLATINUM),
    [Metals.RHODIUM]: singleEntryValidation(Metals.RHODIUM),
  });
};
