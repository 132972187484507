import { DrawingTools } from 'shared/constants';
import { ShapeParam } from 'store/locationsSlice';
import { CircleShape, PolygonShape, RectangleShape, Shape } from 'store/mapDrawingSlice';

type RectPath = google.maps.LatLng[];
const squareMilesMultiplier = 3.86102159 * 10 ** -7;

export const getRectanglePath = (bounds: google.maps.LatLngBounds): RectPath => {
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();

  return [
    new window.google.maps.LatLng(sw.lat(), sw.lng()),
    new window.google.maps.LatLng(sw.lat(), ne.lng()),
    new window.google.maps.LatLng(ne.lat(), ne.lng()),
    new window.google.maps.LatLng(ne.lat(), sw.lng()),
  ];
};

type Path = ({ lat: number; lng: number } | google.maps.LatLng)[] | ShapeParam;
export const calculatePathAndArea = (
  shape: Shape,
): {
  area: number;
  path: Path;
} => {
  let path, area; // Drawn element area in miles^2
  switch (shape.type) {
    case DrawingTools.POLYGON:
      area =
        window.google?.maps.geometry.spherical.computeArea(
          (shape as PolygonShape).overlay.getPath(),
        ) * squareMilesMultiplier;
      path = { path: (shape as PolygonShape).overlay.getPath().getArray() };

      break;
    case DrawingTools.CIRCLE:
      area = ((shape as CircleShape).overlay.radius * 0.000621371192) ** 2 * Math.PI;
      path = {
        radius: (shape as CircleShape).overlay.radius,
        circleCenter: {
          // @ts-ignore lat() is callable
          latitude: (shape as CircleShape).overlay.center.lat(),
          // @ts-ignore lng() is callable
          longitude: (shape as CircleShape).overlay.center.lng(),
        },
      };

      break;
    case DrawingTools.RECTANGLE:
      path = {
        path: getRectanglePath(
          (shape as RectangleShape).overlay.bounds as google.maps.LatLngBounds,
        ),
      };
      area = window.google?.maps.geometry.spherical.computeArea(path.path) * squareMilesMultiplier;

      break;
    default:
      area = 0;
      path = null;
  }

  return { path, area };
};
