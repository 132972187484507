import React from 'react';

import searchIcon from 'assets/images/icons/search.svg';
import { Icon } from 'components/shared/Buttons';
import {
  GridSearchHits,
  highlightableTextStyles,
  HighlightSearch,
  RowProps,
  SearchField,
  SearchFieldLabel,
  SearchGridContainer,
} from 'components/shared/List';
import { AssayListItem } from 'shared/types/assayListItem';
import { useSearchableAssaysFields } from './useSearchableAssaysFields';

export const AssaysSearchGrid = (props: React.PropsWithChildren<RowProps<AssayListItem>>) => {
  const assay = props.row!.original;
  const searchInput = props.table!.getState().globalFilter?.trim();
  const searchableFields = useSearchableAssaysFields({ assay, searchInput });

  return searchableFields.length ? (
    <SearchGridContainer>
      <GridSearchHits>
        <Icon icon={searchIcon} />
        {searchableFields.map(
          field =>
            field && (
              <SearchField key={field.label}>
                <SearchFieldLabel>{field.label}</SearchFieldLabel>{' '}
                <HighlightSearch
                  className={highlightableTextStyles(false)}
                  searchWords={[field.matcher(searchInput)!]}
                  textToHighlight={field.value}
                />
              </SearchField>
            ),
        )}
      </GridSearchHits>
    </SearchGridContainer>
  ) : null;
};
