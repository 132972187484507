import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import styled from '@emotion/styled';

import gridIcon from 'assets/images/icons/grid.svg';
import hamburgerIcon from 'assets/images/icons/hamburger.svg';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { LIST_MODE } from 'shared/constants';
import { useClickAway, useExtendedTheme, useMediaQuery } from 'shared/hooks';
import { changeColumnsQuantityAction, changeListModeAction } from 'store/listComponentSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { layers, MEDIA_QUERY, Theme } from 'theme';
import { Icon } from '../Buttons';
import { FieldRadioButtonLayout as FieldRadioButton } from '../Fields/FieldRadioButton/FieldRadioButton';

interface Props {
  className?: string;
  onChange?: () => void;
}

const GridButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const selectStyles = theme => ({
  container: provided => ({
    ...provided,
    color: theme.mainColor,
    backgroundColor: 'transparent',
    fontSize: 12,
    outline: 'none',
    width: 44,
  }),
  control: (provided, { menuIsOpen }) => ({
    ...provided,
    backgroundColor: menuIsOpen ? theme.colors.codGrayDarker : 'transparent',
    boxShadow: 'none',
    outline: 'none',
    pointerEvents: 'all',
    cursor: 'pointer',
    fontFamily: theme.fonts.lato,
    marginLeft: 2,
    minHeight: 48,
    width: 44,
    border: `1px solid ${menuIsOpen ? theme.colors.alphaGray : 'transparent'}`,
    borderRadius: '0 20px 0 0',
    borderBottom: 'none',
    borderLeft: 'none',

    ':hover': {
      borderColor: menuIsOpen ? theme.colors.alphaGray : 'transparent',
    },
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: 12,
    color: theme.mainColor,
    display: 'flex',
    alignItems: 'center',

    ':hover': {
      color: `${theme.mainColor}BB`,
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '12px 5px',
    marginLeft: 2,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.mainColor,

    ':hover': {
      color: `${theme.mainColor}BB`,
    },
  }),
  menu: provided => ({
    ...provided,
    minHeight: 'fit-content',
    width: 72,
    marginTop: 0,
    right: -2,
    borderRadius: '0 0 16px 0',
    backgroundColor: theme.colors.mineShaft,
    border: `1px solid ${theme.colors.alphaGray}`,
    boxShadow: 'none',
    cursor: 'pointer',
    'z-index': layers.selectMenu,

    '&::before': {
      content: '""',
      position: 'absolute',
      width: 1,
      height: 48,
      backgroundColor: theme.colors.alphaGray,
      top: -49,
      left: -1,
    },
  }),
  option: (provided, { isFocused, isSelected, isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    borderTop: `1px solid ${theme.colors.mineShaftLighter}`,
    backgroundColor: isSelected || isFocused ? theme.colors.mineShaftLighter : 'transparent',
    color: isSelected ? theme.mainColor : theme.colors.alphaGray,
    padding: '4px 10px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    fontSize: 12,
    overflow: 'hidden',
    'text-overflow': 'ellipsis',

    ':hover, :active': {
      backgroundColor: theme.colors.mineShaftLighter,
    },

    '&:last-of-type': {
      borderRadius: '0 0 16px 0',
    },

    [`@media ${MEDIA_QUERY.MOBILE_LANDSCAPE}`]: {
      [`@media  ${MEDIA_QUERY.MAX_MD}`]: {
        fontSize: 8,
      },
    },
  }),
});

const ListModeButtonLayout = ({ className, onChange }: Props): React.ReactElement => {
  const gridButtonRef = useRef(null);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useExtendedTheme();
  const { columnsQuantity, listMode } = useAppSelector(state => state.listComponent);

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const isTablet = useMediaQuery(MEDIA_QUERY.MAX_MD);

  useClickAway(gridButtonRef, () => setSelectOpen(false));
  useEffect(() => {
    if (isPhone && columnsQuantity > 2) dispatch(changeColumnsQuantityAction(2));
    else if (isTablet && columnsQuantity > 3) dispatch(changeColumnsQuantityAction(3));
  }, [isPhone, isTablet]);
  const gridColumnOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    !isPhone && { label: '3', value: 3 },
    !isTablet && { label: '4', value: 4 },
  ].filter(isTruthy);

  const selected = gridColumnOptions.find(p => p && p.value === columnsQuantity);

  const onSelectChange = (newValue: OnChangeValue<{ value: number }, false>) => {
    dispatch(changeColumnsQuantityAction(newValue!.value));
    return setSelectOpen(false);
  };

  const listModes = [
    {
      label: (
        <Icon
          icon={hamburgerIcon}
          color={listMode === LIST_MODE.TABLE ? theme.mainColor : theme.colors.alphaGray}
          hoverColor={listMode === LIST_MODE.TABLE ? theme.mainColor : theme.colors.alphaGray}
          size="16px"
          pointer
        />
      ),
      value: LIST_MODE.TABLE,
    },
    {
      label: (
        <GridButton ref={gridButtonRef}>
          <Icon
            icon={gridIcon}
            color={listMode === LIST_MODE.GRID ? theme.mainColor : theme.colors.alphaGray}
            hoverColor={listMode === LIST_MODE.GRID ? theme.mainColor : theme.colors.alphaGray}
            size="14px"
            pointer
          />
          {listMode === LIST_MODE.GRID && (
            <Select
              options={gridColumnOptions}
              value={selected}
              styles={selectStyles(theme)}
              menuIsOpen={isSelectOpen}
              onFocus={e => {
                e.stopPropagation();
                setSelectOpen(true);
              }}
              onChange={onSelectChange}
              isSearchable={false}
            />
          )}
        </GridButton>
      ),
      value: LIST_MODE.GRID,
    },
  ];

  const changeListMode = useCallback(
    e => {
      dispatch(changeListModeAction(e.target.value));
      if (onChange) onChange();
    },
    [dispatch],
  );

  return (
    <div className={className}>
      <FieldRadioButton
        value={listMode}
        name="currentListMode"
        onChange={changeListMode}
        options={listModes}
        dataCy="current-list-mode"
        overflowMode="unset"
      />
    </div>
  );
};

export const ListModeButton = styled(ListModeButtonLayout)<{ theme?: Theme }>`
  width: 124px;
  height: 48px;

  & > div > div {
    height: 48px;

    &:first-of-type {
      border-right: none;
    }
  }

  input + label {
    height: 46px;
  }

  input:checked + label {
    background-color: ${({ theme }) => theme.colors.codGrayDarker} !important;
  }
`;
