import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';

export const Label = styled.p<{ theme?: Theme; isActive?: boolean }>`
  grid-area: label;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: ${({ theme }) => theme.colors.alphaGray};
  margin: 0;

  @media ${MEDIA_QUERY.XL} {
    margin-right: 36px;
    color: ${({ isActive, theme }) => (isActive ? theme.colors.alphaGray : theme.colors.charcoal)};
    text-align: right;
  }
`;
