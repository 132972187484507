import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { ShoppingCart, ShoppingCartItem, ShoppingCartItemsGroup } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum ShoppingCartsKeys {
  GetShoppingCarts = 'GetShoppingCarts',
  GetShoppingCartItems = 'GetShoppingCartItems',
}

export const useGetShoppingCarts = (
  options: UseQueryOptions<ShoppingCart[]> = {},
): UseQueryResult<ShoppingCart[]> =>
  useQuery<ShoppingCart[]>(
    [ShoppingCartsKeys.GetShoppingCarts],
    async () => {
      const response = await getHttpClient().get<ShoppingCart[]>(
        `${apiHostname}/api/shopping-cart`,
      );
      return response.data;
    },
    { initialData: [], ...options },
  );

export interface GetShoppingCartResponse {
  shoppingCartItems: ShoppingCartItem[];
  shoppingCartItemsGroups: ShoppingCartItemsGroup[];
}

export const useGetShoppingCartItems = (
  id: number,
  options: UseQueryOptions<GetShoppingCartResponse> = {},
): UseQueryResult<GetShoppingCartResponse> =>
  useQuery<GetShoppingCartResponse>(
    [ShoppingCartsKeys.GetShoppingCartItems, id],
    async () => {
      const response = await getHttpClient().get<GetShoppingCartResponse>(
        `${apiHostname}/api/shopping-cart/${id}`,
      );

      return response.data;
    },
    { initialData: { shoppingCartItems: [], shoppingCartItemsGroups: [] }, ...options },
  );
