import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, SuccessResponse } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum FeedbackKeys {
  FillUpQuestionnaire = 'FillUpQuestionnaire',
  SkipQuestionnaire = 'SkipQuestionnaire',
  CancelQuestionnaire = 'CancelQuestionnaire',
  RemoveFeedbacks = 'RemoveFeedbacks',
  SaveFeedback = 'SaveFeedback',
}

export interface FillQuestionnaireParams {
  rating: number;
  message?: string;
}

export const useFillUpQuestionnaire = (
  options?: UseMutationOptions<void, AlphamartHttpError, FillQuestionnaireParams>,
) =>
  useMutation<void, AlphamartHttpError, FillQuestionnaireParams>(
    async (values: FillQuestionnaireParams) => {
      await getHttpClient().post<SuccessResponse>(
        `${apiHostname}/api/feedback/questionnaire/fill`,
        {
          rating: values.rating,
          message: values.message || null,
        },
      );
    },
    {
      ...options,
      mutationKey: [FeedbackKeys.FillUpQuestionnaire],
    },
  );

export const useSkipQuestionnaire = (options?: UseMutationOptions<void, AlphamartHttpError>) =>
  useMutation<void, AlphamartHttpError>(
    async () => {
      await getHttpClient().post<SuccessResponse>(`${apiHostname}/api/feedback/questionnaire/skip`);
    },
    {
      ...options,
      mutationKey: [FeedbackKeys.SkipQuestionnaire],
    },
  );

export const useCancelQuestionnaire = (options?: UseMutationOptions<void, AlphamartHttpError>) =>
  useMutation<void, AlphamartHttpError>(
    async () => {
      await getHttpClient().post<SuccessResponse>(
        `${apiHostname}/api/feedback/questionnaire/cancel`,
      );
    },
    {
      ...options,
      mutationKey: [FeedbackKeys.CancelQuestionnaire],
    },
  );

export const useRemoveFeedbacks = (
  options?: UseMutationOptions<void, AlphamartHttpError, number[]>,
) =>
  useMutation<void, AlphamartHttpError, number[]>(
    async (ids: number[]) => {
      await getHttpClient().delete<SuccessResponse>(`${apiHostname}/api/feedback`, {
        params: { ids },
      });
    },
    {
      mutationKey: [FeedbackKeys.RemoveFeedbacks],
      ...options,
    },
  );

export const useSaveFeedback = (options?: UseMutationOptions<void, AlphamartHttpError, number>) =>
  useMutation(
    async id => {
      await getHttpClient().put<void>(`${apiHostname}/api/feedback/${id}/save`);
    },
    {
      mutationKey: [FeedbackKeys.SaveFeedback],
      ...options,
    },
  );
