import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { DetailsSection } from 'components/shared/Details';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import {
  ModalFormButtonContainer,
  ModalFormContent,
} from 'components/shared/forms/Form/ModalForm.styles';
import { ReactModalStyle } from 'components/shared/Modals.styles';
import { ModalFormType } from 'shared/constants';
import { AssignedVehiclesModal, hideModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { layers } from 'theme';
import {
  LinkToVehicle,
  StyledHeader,
  StyledModal,
  StyledModalContent,
} from './NonstandardConvertersVehicleModal.styles';
import { messages, TypedFormattedMessage as FormattedMessage } from '../locale/messages';

const NonstandardConvertersVehicleModal = (): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { isOpen, modalType, params } = useAppSelector(state => state.modalForm);

  const handleCancelClick = () => {
    dispatch(hideModalForm());
  };

  useEffect(() => {
    dispatch(hideModalForm());
  }, [location]);

  if (!params) return null;

  const { assignedVehicles } = params as AssignedVehiclesModal;

  return (
    <ReactModal
      isOpen={isOpen && modalType === ModalFormType.AssignedVehicles}
      style={ReactModalStyle(false, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <StyledModal>
        <ModalFormContent>
          <StyledHeader>
            <FormattedMessage id="NonstandardConvertersList.HeaderModal" />
          </StyledHeader>
          <StyledModalContent>
            {assignedVehicles.map((vehicle, index) => (
              <DetailsSection
                key={vehicle.id}
                template={[`make${index} make${index} make${index}`]}
                fields={[
                  {
                    name: `make${index}`,
                    label: <FormattedMessage id="NonstandardConvertersList.YearMakeModel" />,
                    value: (
                      <LinkToVehicle to={`/vehicles/list/${vehicle.id}`}>
                        <FormattedMessage
                          id="NonstandardConvertersList.Values"
                          values={{
                            year: vehicle.year,
                            make: vehicle.make,
                            model: vehicle.model,
                          }}
                        />
                      </LinkToVehicle>
                    ),
                  },
                ]}
              />
            ))}
          </StyledModalContent>
          <ModalFormButtonContainer>
            <AppButton styleType="neutral-empty" onClick={handleCancelClick}>
              <FormattedMessage id="Global.Close" />
            </AppButton>
          </ModalFormButtonContainer>
        </ModalFormContent>
      </StyledModal>
    </ReactModal>
  );
};

export const NonstandardConverterVehiclesModal = withAlphamartIntlProvider(
  NonstandardConvertersVehicleModal,
  messages,
);
