import { createSlice } from '@reduxjs/toolkit';

import { apiHostname, ErrorCode } from 'shared/constants';
import { serializeLocationHeader } from 'shared/helpers';
import { AlphamartHttpError, Converter, Coords } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { blockUserAction } from './auth';

interface ConverterDetailsState extends GenericStoreSlice {
  converter: Converter | null;
}

const converterDetailsSlice = createSlice<
  ConverterDetailsState,
  GenericStoreReducer<ConverterDetailsState>
>({
  name: 'converterDetails',
  initialState: {
    converter: null,
    error: undefined,
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      converter: payload,
    })),
  },
});

export const {
  pending: fetchConverterAction,
  success: fetchConverterSuccessAction,
  failure: fetchConverterFailureAction,
} = converterDetailsSlice.actions;

export const fetchConverter =
  (id: string | number, position: Coords): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const url = `${apiHostname}/api/converters/${id}`;
      const headers = position && { 'user-location': serializeLocationHeader(position) };

      dispatch(fetchConverterAction());
      const { data } = await httpClient.get(url, { headers });
      dispatch(fetchConverterSuccessAction(data));
    } catch (error) {
      dispatch(fetchConverterFailureAction((error as AlphamartHttpError)?.response?.data.message));

      if (
        (error as AlphamartHttpError).response?.data?.errorCode ===
        ErrorCode.USER_BLOCKED_OFF_LIMIT_AREA_ENTERED
      )
        dispatch(blockUserAction());

      return Promise.reject(error);
    }
  };

export const clearConverter = (): ReturnType<typeof fetchConverterSuccessAction> =>
  fetchConverterSuccessAction(null);

export default converterDetailsSlice.reducer;
