import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const getHeight = (): number => document.documentElement.clientHeight || window.innerHeight;

export const useWindowHeight = (delayInMs = 200): number => {
  const [windowHeight, setWindowHeight] = useState<number>(getHeight());

  const handleHeightChange = debounce(() => {
    setWindowHeight(getHeight());
  }, delayInMs);

  useEffect(() => {
    window.addEventListener('resize', handleHeightChange);
    return () => {
      window.removeEventListener('resize', handleHeightChange);
    };
  }, [delayInMs]);

  return windowHeight;
};
