import React, { useCallback, useEffect, useState } from 'react';
import { DrawingManager, GoogleMap, LoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { isNil } from 'lodash';

import { MapFiltersShape } from 'components/views/Geolocation/UserLocationsView/MapFilters';
import { DrawingModes } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { UserLocationMarker } from 'shared/types';
import { sleep } from 'shared/utils/sleep';
import { ShapeParam } from 'store/locationsSlice';
import { MapShape, setMap, setMapDrawing, setOffZones } from 'store/mapDrawingSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, NAVBAR_HEIGHT_MOBILE, Theme, theme as appTheme } from 'theme';
import { useMarkerClustering } from './hooks/useMarkerClustering';
import { calculatePathAndArea, getRectanglePath, OffZones } from './CustomMapComponents';
import { MapComponentStyles } from './MapComponent.styles';
import { stylers } from './stylers';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const containerPhoneStyle = bodyHeight => ({
  height: `calc(${bodyHeight}px - ${NAVBAR_HEIGHT_MOBILE})`,
});

const initialRadius = 200000;

export type MapComponentProps = {
  initialPosition?: { latitude: number; longitude: number };
  markers: (UserLocationMarker | UserLocationMarker[])[];
  getMarkers: (shape: ShapeParam, filters?: MapFiltersShape) => void;
  filters: MapFiltersShape;
  theme?: Theme;
  drawingMode: DrawingModes | null;
};

export function MapComponent(props: MapComponentProps): React.ReactElement | null {
  const { initialPosition, markers, getMarkers, filters } = props;
  const dispatch = useAppDispatch();
  const { maps: mapsConfig } = useAppSelector(store => store.config);
  const { drawingMode, shouldErase, drawingTool, currentShape, map, savedPath, offZones } =
    useAppSelector(state => state.mapDrawing);
  const [zoom, setZoom] = useState(9);
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const language = useAppSelector(state => state.currentLanguage.language);

  useMarkerClustering(map, markers);

  useEffect(() => {
    if (!shouldErase || !map) return;
    if (currentShape) (currentShape as MapShape).overlay.setMap(null);
    dispatch(
      setMapDrawing({
        currentShape: null,
        lastShape: null,
        area: 0,
        savedPath: null,
        shouldErase: false,
      }),
    );

    const path = { path: getRectanglePath(map.getBounds()!) };

    getMarkers(path);
  }, [shouldErase]);

  const handleLoad = useCallback(
    async loadedMap => {
      await sleep(0);

      const bounds = new window.google.maps.LatLngBounds();

      getMarkers({ circleCenter: initialPosition ?? {}, radius: initialRadius });
      loadedMap.fitBounds(bounds);
      dispatch(setMap(loadedMap));
    },
    [filters],
  );

  const handleUnmount = useCallback(() => {
    dispatch(setMap(null));
  }, []);

  const handleLoadMarkers = () => {
    // Map is unininitialized or returns default values
    if (!map || !map.getBounds() || !map.getCenter()?.lat() || map.getCenter()?.lng() === 180)
      return;

    const path = savedPath ?? { path: getRectanglePath(map.getBounds()!) };
    getMarkers(path);
  };

  const handleZoom = () => {
    const currentZoom = map?.getZoom();
    if (!isNil(currentZoom) && zoom !== currentZoom) {
      handleLoadMarkers();
      setZoom(currentZoom);
    }
  };

  const handleCenterChange = () => {
    handleLoadMarkers();
  };

  const handleOverlayComplete = useCallback(
    shape => {
      switch (drawingMode) {
        case DrawingModes.FILTERS: {
          if (currentShape) (currentShape as MapShape).overlay.setMap(null);

          const { path, area } = calculatePathAndArea(shape);

          if (path) getMarkers(path as ShapeParam);
          dispatch(
            setMapDrawing({
              currentShape: shape,
              savedPath: path as ShapeParam,
              area: Math.round(area * 10) / 10,
            }),
          );
          break;
        }
        case DrawingModes.OFF_ZONES: {
          dispatch(
            setOffZones({ ...offZones, [shape.type]: [...offZones[shape.type], shape.overlay] }),
          );
          shape.overlay.setMap(null);
          break;
        }
      }
    },
    [currentShape, drawingMode, setOffZones, offZones],
  );

  const mapCenter = map?.getCenter();
  const isCenterLoaded =
    mapCenter?.lat() && mapCenter?.lat() !== 0 && mapCenter?.lng() && mapCenter?.lng() !== 180;
  const center = {
    lat: isCenterLoaded ? mapCenter?.lat() ?? 0 : initialPosition?.latitude ?? 0,
    lng: isCenterLoaded ? mapCenter?.lng() ?? 0 : initialPosition?.longitude ?? 0,
  };

  if (!mapsConfig?.key) return null;

  const shapesOptions =
    drawingMode === DrawingModes.OFF_ZONES
      ? {
          fillColor: appTheme.colors.fuzzyWuzzyBrown,
          strokeColor: appTheme.colors.fuzzyWuzzyBrown,
          fillOpacity: 0.1,
        }
      : { fillColor: appTheme.colors.black };
  const bodyHeight = document.querySelector('body')?.getBoundingClientRect().height;

  return (
    <MapComponentStyles {...props}>
      <LoadScript googleMapsApiKey={`${mapsConfig.key}&libraries=drawing`} language={language}>
        <GoogleMap
          mapContainerStyle={isPhone ? containerPhoneStyle(bodyHeight) : containerStyle}
          center={center}
          zoom={zoom}
          onLoad={handleLoad}
          onUnmount={handleUnmount}
          onZoomChanged={handleZoom}
          onDragEnd={handleCenterChange}
          clickableIcons={false}
          options={{
            styles: stylers,
          }}
        >
          {drawingMode ? (
            <DrawingManager
              onOverlayComplete={handleOverlayComplete}
              drawingMode={drawingTool as unknown as google.maps.drawing.OverlayType}
              options={{
                drawingControl: false,
                circleOptions: shapesOptions,
                polygonOptions: shapesOptions,
                rectangleOptions: shapesOptions,
              }}
            />
          ) : (
            <StreetViewPanorama />
          )}
          <OffZones />
        </GoogleMap>
      </LoadScript>
    </MapComponentStyles>
  );
}
