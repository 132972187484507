import React from 'react';
import styled from '@emotion/styled';

import { Badge } from 'components/shared/Badge';
import { TextButton } from 'components/shared/Buttons';
import { DetailsSection } from 'components/shared/Details';
import { DetailsSectionField } from 'components/shared/Details/DetailsSection';
import { HedgePricesGroup } from 'components/shared/HedgePricesGroup/HedgePricesGroup';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { resolveHedgeStatus } from 'helpers/hedges/resolveHedgeStatus';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { PERMISSIONS, PRICE_SOURCES, SHARED } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { Hedge, HedgeListItem, User } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

const Container = styled.div`
  & > div > div {
    grid-template-columns: unset;
    grid-auto-columns: minmax(0, 1fr);
  }
`;

const GroupedValue = styled.div`
  line-height: 1.5;
`;

function HedgeButton({ hedge }: { hedge?: Hedge | HedgeListItem }): React.ReactElement {
  const navigate = useAlphamartNavigate();

  if (!hedge) return <span>{SHARED.LONG_DASH}</span>;

  return (
    <TextButton onClick={() => navigate(`/hedges/${hedge.id}`)}>
      {hedge.name}
      <br />
      {formatDateWithTime(hedge.placedAt)}
    </TextButton>
  );
}

type UserDetailsHedgesProps = { user: Pick<User, 'prices' | 'assignedHedges'> };

export function UserDetailsHedges({ user }: UserDetailsHedgesProps): React.ReactElement {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const auth = useAuthorization();
  const getMetalPrice = metalAbbr => {
    const price = user.prices?.[`${metalAbbr}HedgeUsed`]?.[`${metalAbbr}PriceCust`];
    const source = user.prices?.[`${metalAbbr}PriceSource`];
    return price && source === PRICE_SOURCES.HEDGE ? formatCurrency(price) : SHARED.LONG_DASH;
  };

  const canSeeHedgePrices = auth(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);

  const associatedHedgesTemplate =
    user.assignedHedges?.map(hedge =>
      [
        `hedge${hedge.id}Name`,
        `hedge${hedge.id}Dates`,
        `hedge${hedge.id}${canSeeHedgePrices ? 'Prices' : 'Dates'}`,
        `hedge${hedge.id}Status`,
      ].join(' '),
    ) ?? [];

  const associatedHedgesMobileTemplate = user.assignedHedges?.reduce<string[]>(
    (prev, hedge) => [
      ...prev,
      `hedge${hedge.id}Name hedge${hedge.id}Dates hedge${hedge.id}Dates`,
      `hedge${hedge.id}${canSeeHedgePrices ? 'Prices' : 'Status'} hedge${hedge.id}Status hedge${
        hedge.id
      }Status`,
    ],
    [],
  );

  const associatedHedgesFields: DetailsSectionField[] = (
    user.assignedHedges?.reduce<(DetailsSectionField | false)[]>(
      (prev, hedge) => [
        ...prev,
        ...[
          {
            name: `hedge${hedge.id}Status`,
            label: intl.formatMessage({ id: 'UserDetails.Hedges.Status' }),
            value: (
              <Badge color={resolveStatusColor(resolveHedgeStatus(hedge))}>
                {intl.formatMessage({ id: `Global.Status.${resolveHedgeStatus(hedge)}` })}
              </Badge>
            ),
          },
          {
            name: `hedge${hedge.id}Name`,
            label: intl.formatMessage({ id: 'UserDetails.Hedges.Name' }),
            value: (
              <GroupedValue>
                <TextButton onClick={() => navigate(`/hedges/${hedge.id}`)}>
                  {hedge.name}
                  <br />
                  {hedge.relatedLot ?? SHARED.LONG_DASH}
                </TextButton>
              </GroupedValue>
            ),
          },
          {
            name: `hedge${hedge.id}Dates`,
            label: intl.formatMessage({ id: 'UserDetails.Hedges.Dates' }),
            value: (
              <GroupedValue>
                {formatDateWithTime(hedge.placedAt)} <br />
                {formatDateWithTime(hedge.expiresAt)}
              </GroupedValue>
            ),
          },
          canSeeHedgePrices && {
            name: `hedge${hedge.id}Prices`,
            label: intl.formatMessage({ id: 'UserDetails.Hedges.Prices' }),
            value: (
              <GroupedValue>
                <HedgePricesGroup hedge={hedge} />
              </GroupedValue>
            ),
          },
        ],
      ],
      [],
    ) ?? []
  ).filter(isTruthy);

  const pricesFields: DetailsSectionField[] = [
    {
      name: 'ptSource',
      label: intl.formatMessage({ id: 'UserDetails.PlatinumSource' }),
      value: intl.formatMessage({
        id: `Global.PriceSource.${user.prices?.ptPriceSource ?? 'MARKET'}`,
      }),
    },
    {
      name: 'pdSource',
      label: intl.formatMessage({ id: 'UserDetails.PalladiumSource' }),
      value: intl.formatMessage({
        id: `Global.PriceSource.${user.prices?.pdPriceSource ?? 'MARKET'}`,
      }),
    },
    {
      name: 'rhSource',
      label: intl.formatMessage({ id: 'UserDetails.RhodiumSource' }),
      value: intl.formatMessage({
        id: `Global.PriceSource.${user.prices?.rhPriceSource ?? 'MARKET'}`,
      }),
    },
    {
      name: 'ptHedgeUsed',
      label: intl.formatMessage({ id: 'UserDetails.PtHedgeUsed' }),
      value: <HedgeButton hedge={user.prices?.ptHedgeUsed} />,
    },
    {
      name: 'pdHedgeUsed',
      label: intl.formatMessage({ id: 'UserDetails.PdHedgeUsed' }),
      value: <HedgeButton hedge={user.prices?.pdHedgeUsed} />,
    },
    {
      name: 'rhHedgeUsed',
      label: intl.formatMessage({ id: 'UserDetails.RhHedgeUsed' }),
      value: <HedgeButton hedge={user.prices?.rhHedgeUsed} />,
    },
    canSeeHedgePrices && {
      name: 'ptPrice',
      label: intl.formatMessage({ id: 'UserDetails.PlatinumPrice' }),
      value: getMetalPrice('pt'),
    },
    canSeeHedgePrices && {
      name: 'pdPrice',
      label: intl.formatMessage({ id: 'UserDetails.PalladiumPrice' }),
      value: getMetalPrice('pd'),
    },
    canSeeHedgePrices && {
      name: 'rhPrice',
      label: intl.formatMessage({ id: 'UserDetails.RhodiumPrice' }),
      value: getMetalPrice('rh'),
    },
  ].filter(isTruthy);

  const pricesTemplate: string[] = [
    'ptSource pdSource rhSource',
    canSeeHedgePrices && 'ptPrice pdPrice rhPrice',
    'ptHedgeUsed pdHedgeUsed rhHedgeUsed',
  ].filter(isTruthy);

  const pricesMobileTemplate: string[] = [
    'ptSource ptHedgeUsed',
    canSeeHedgePrices && 'ptPrice ptPrice',
    'pdSource pdHedgeUsed',
    canSeeHedgePrices && 'pdPrice pdPrice',
    'rhSource rhHedgeUsed',
    canSeeHedgePrices && 'rhPrice rhPrice',
  ].filter(isTruthy);

  return (
    <Container>
      <DetailsSection
        title={intl.formatMessage({ id: 'UserDetails.UserPrices' })}
        template={pricesTemplate}
        mobileTemplate={pricesMobileTemplate}
        fields={pricesFields}
      />
      {user?.assignedHedges && user.assignedHedges.length > 0 && (
        <DetailsSection
          title={intl.formatMessage({ id: 'UserDetails.AssignedHedges' })}
          template={associatedHedgesTemplate}
          mobileTemplate={associatedHedgesMobileTemplate}
          fields={associatedHedgesFields}
        />
      )}
    </Container>
  );
}
