import React, { useCallback } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Config as PopperConfig } from 'react-popper-tooltip/dist/types';
import styled from '@emotion/styled';

import { theme } from 'theme';
import 'react-popper-tooltip/dist/styles.css';

interface Props extends PopperConfig {
  tooltip: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  hideArrow?: boolean;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  trigger?: ('click' | 'right-click' | 'hover' | 'focus')[];
  enabled?: boolean;
}

const TooltipLayout = ({
  className,
  children,
  tooltip,
  hideArrow,
  enabled = true,
  ...props
}: Props): React.ReactElement => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip(props);

  const preventBubbling = useCallback(e => e.stopPropagation(), []);

  if (!enabled) {
    return children as React.ReactElement;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={className} onClick={preventBubbling}>
      <div ref={setTriggerRef} data-cy="tooltip-component">
        {children}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: `tooltip-container ${className}` })}
        >
          {!hideArrow && <div {...getArrowProps({ className: `tooltip-arrow ${className}` })} />}
          {tooltip}
        </div>
      )}
    </div>
  );
};

const Tooltip = styled(TooltipLayout)<{ bgColor?: string }>`
  &.tooltip-container {
    pointer-events: none;
    background-color: ${({ bgColor }) => bgColor || theme.colors.doveGray};
    border-color: ${({ bgColor }) => bgColor || theme.colors.doveGray};
    font-size: 12px;
    color: ${theme.colors.whisper};
    max-width: 500px;
    margin-bottom: 4px;

    .tooltip-arrow {
      ::before {
        border-top-color: ${({ bgColor }) => bgColor || theme.colors.doveGray} !important;
      }
      ::after {
        border-top-color: ${({ bgColor }) => bgColor || theme.colors.doveGray} !important;
      }
    }
  }
`;

export { Tooltip };
