import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { capitalize } from 'lodash';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { TextButton } from 'components/shared/Buttons';
import { IndicatorDot } from 'components/shared/forms/Section/components/IndicatorDot/IndicatorDot';
import { dashboardFilters, dashboardFiltersLabels, METAL_ABBR } from 'shared/constants';
import { useLanguage } from 'shared/hooks';
import { HIDDEN_ON_MEDIA_QUERY, MEDIA_QUERY, theme } from 'theme';
import { messages, TypedFormattedMessage } from '../locale/messages';

const headerStyles = css`
  grid-area: header;
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  & > div > * {
    margin-left: 8px;
  }
`;

const MetalHeaders = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;

  & > p {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      margin-right: 8px;
    }
  }
`;

const MetalLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 50px;
`;

interface Props {
  onFilterClick?: (key: string) => void;
  currentFilter?: string;
  colors: string[];
}

const DashboardHeader = ({ onFilterClick, currentFilter, colors }: Props): React.ReactElement => {
  const language = useLanguage();

  return (
    <AlphamartIntlProvider messages={messages}>
      <div className={headerStyles}>
        <Header>
          <TypedFormattedMessage id="Dashboard.MetalPrices" />
          <div>
            {Object.entries(dashboardFilters).map(([key, value]) => (
              <TextButton
                key={key}
                color={currentFilter !== key ? theme.colors.alphaGray : undefined}
                isActive={currentFilter === key}
                onClick={() => onFilterClick?.(key)}
              >
                {dashboardFiltersLabels[`${value}-${language}`]}
              </TextButton>
            ))}
          </div>
        </Header>
        <MetalHeaders className={HIDDEN_ON_MEDIA_QUERY.MAX_MD}>
          {Object.values(METAL_ABBR).map((metal, i) => (
            <MetalLabel key={metal}>
              <IndicatorDot bgColor={colors[i]} display="block" />{' '}
              {capitalize(metal.toLocaleLowerCase())}
            </MetalLabel>
          ))}
        </MetalHeaders>
        <span />
      </div>
    </AlphamartIntlProvider>
  );
};

export { DashboardHeader };
