import React from 'react';
import { registerLocale } from 'react-datepicker';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import pl from 'date-fns/locale/pl';
import { PersistGate } from 'redux-persist/integration/react';
import 'intl-pluralrules';

import App from 'components/App';
import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppModal from 'components/shared/AppModal';
import { ErrorBoundary } from 'components/shared/ErrorBoundary/ErrorBoundary';
import { configureQueryClient } from 'shared/queries';
import { injectManifest } from 'shared/utils/injectManifest';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

injectManifest();

ReactModal.setAppElement(document.getElementById('root')!);
registerLocale('pl', pl);

export const { store, persistor } = configureStore();
const queryClient = configureQueryClient();

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AlphamartIntlProvider>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <ErrorBoundary>
              <App />
              <AppModal />
            </ErrorBoundary>
          </React.StrictMode>
        </PersistGate>
      </AlphamartIntlProvider>
    </QueryClientProvider>
  </Provider>,
);

serviceWorker.register();
