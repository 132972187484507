// TODO file one will be fixed in WFA-2912-Change how profit margins are adjusted by users
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { FormikProvider, useFormik } from 'formik';

import { CancelButton } from 'components/shared/Buttons';
import { DetailsSection } from 'components/shared/Details';
import { FieldCheckboxRaw } from 'components/shared/Fields/FieldCheckbox';
import { FieldRadioButtonLayout as FieldRadioButton } from 'components/shared/Fields/FieldRadioButton/FieldRadioButton';
import { FieldSelectBase } from 'components/shared/Fields/FieldSelect/FieldSelect';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { Slider } from 'components/shared/Slider/Slider';
import { MarginVisibility, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useExtendedTheme, useMediaQuery } from 'shared/hooks';
import { User } from 'shared/types';
import { fetchUser, updateUserCurrentProfitMargin } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { MEDIA_QUERY } from 'theme';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from './locale/messages';

const getTemplate = (
  displayProfitMarginSelection: boolean,
  showMarginSlider: boolean,
  canUseMarginSliderPerMetal: boolean,
) => {
  const baseTemplate = [
    'selectedProfitMargin selectedProfitMargin selectedProfitMargin',
    'saveSelectedProfitMargin saveSelectedProfitMargin saveSelectedProfitMargin',
  ];
  const generalMarginSlider = ['currentProfitMarginPt currentProfitMarginPt currentProfitMarginPt'];
  const perMetalSlider = [
    'currentProfitMarginPt currentProfitMarginPt currentProfitMarginPt',
    'currentProfitMarginPd currentProfitMarginPd currentProfitMarginPd',
    'currentProfitMarginRh currentProfitMarginRh currentProfitMarginRh',
  ];

  let template: string[] = [];
  if (displayProfitMarginSelection) {
    template = baseTemplate;
  }
  if (showMarginSlider && canUseMarginSliderPerMetal) {
    template = [...template, ...perMetalSlider];
  } else if (showMarginSlider) {
    template = [...template, ...generalMarginSlider];
  }

  return template;
};

const ConfirmProfitMargin = styled.div`
  display: flex;
  justify-content: flex-end;
  word-break: normal;

  *:not(:last-child) {
    margin-right: 32px;
  }
`;

type UserProfileProfitMarginParams = {
  user: User;
  setRequestPending: (pending: boolean) => void;
};

function UserProfileProfitMargin({
  user,
  setRequestPending,
}: UserProfileProfitMarginParams): React.ReactElement | null {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const theme = useExtendedTheme();
  const { slider } = useAppSelector(state => state.config.profitMarginLimit!);
  const formikContext = useFormik({
    initialValues: {
      selectedProfitMargin: user?.selectedProfitMargin?.toString(),
      saveSelectedProfitMargin: user?.saveSelectedProfitMargin,
      currentProfitMarginPd: user?.currentProfitMarginPd?.toString(),
      currentProfitMarginPt: user?.currentProfitMarginPt?.toString(),
      currentProfitMarginRh: user?.currentProfitMarginRh?.toString(),
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleSaveButtonClick(values);
    },
  });
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_MD);
  const handleCurrentProfitMarginChange = (metal: string) => (val: number | readonly number[]) =>
    formikContext.setFieldValue(`currentProfitMargin${metal}`, val, false);

  const handleSaveButtonClick = values => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Modal.Confirm' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          dispatch(hideModal());
          await handleConfirmSaveClick(values);
        },
      }),
    );
  };

  const canUseMarginSliderPerMetal = !!(
    user?.company?.canUseProfitMarginPerMetal ||
    user?.company?.parentCompany?.canUseProfitMarginPerMetal
  );

  const availableProfitMargins = useMemo(
    () =>
      user?.profitMargins
        ?.map(({ value }) => ({
          label: `${value} %`,
          value,
        }))
        ?.sort((pM1, pM2) => pM1.value - pM2.value) ?? [],
    [user],
  );

  const canChangeProfitMargin =
    (authorize(PERMISSIONS.USER_PROFILE.CHANGE_PROFIT_MARGIN) && user.profitMargins?.length > 1) ||
    user?.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN ||
    user?.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY;
  const displayProfitMarginSelection = availableProfitMargins?.length > 1;
  const profitMarginTemplate = getTemplate(
    displayProfitMarginSelection,
    user?.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN ||
      user?.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY,
    canUseMarginSliderPerMetal,
  );

  const handleConfirmSaveClick = async values => {
    try {
      setRequestPending(true);
      await dispatch(
        updateUserCurrentProfitMargin(
          {
            currentProfitMarginPt: +values?.currentProfitMarginPt,
            currentProfitMarginPd: +values?.currentProfitMarginPd,
            currentProfitMarginRh: +values?.currentProfitMarginRh,
            saveSelectedProfitMargin: values?.saveSelectedProfitMargin,
            selectedProfitMargin: +values?.selectedProfitMargin,
          },
          {
            canUseMarginSlider:
              user.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN ||
              user.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY,
            canUseMarginSliderPerMetal,
            canSelectProfitMargin: displayProfitMarginSelection,
          },
        ),
      );
      await dispatch(fetchUser());
      dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'UserProfile.ChangeProfitMargin.Success' })),
      );
    } catch {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
    setRequestPending(false);
  };

  if (!user) return null;
  if (!canChangeProfitMargin) return null;

  const selectProps = {
    value: +formikContext.values.selectedProfitMargin!,
    name: 'selectedProfitMargin',
    options: availableProfitMargins,
    dataCy: 'selected-profit-margin',
    disabled: availableProfitMargins?.length === 1,
  };

  return (
    <FormikProvider value={formikContext}>
      <DetailsSection
        id="profileProfitMarginSlider"
        title={<FormattedMessage id="UserProfile.ProfitMargin" />}
        template={profitMarginTemplate}
        fields={[
          {
            name: 'selectedProfitMargin',
            label: <FormattedMessage id="UserProfile.ChangeProfitMargin" />,
            value:
              isMobile && availableProfitMargins?.length > 4 ? (
                <FieldSelectBase {...selectProps} />
              ) : (
                <FieldRadioButton {...selectProps} onChange={formikContext.handleChange} />
              ),
            doNotRender: !displayProfitMarginSelection,
          },
          {
            name: 'saveSelectedProfitMargin',
            value: (
              <FieldCheckboxRaw
                name="saveSelectedProfitMargin"
                onChange={formikContext.handleChange}
                size="22px"
                checked={formikContext.values.saveSelectedProfitMargin}
              >
                <FormattedMessage id="UserProfile.ChangeSaveProfitMargin" />
              </FieldCheckboxRaw>
            ),
            doNotRender: !displayProfitMarginSelection,
          },
          {
            name: 'currentProfitMarginPt',
            label: canUseMarginSliderPerMetal ? (
              <FormattedMessage id="Global.Metals.Platinum" />
            ) : (
              <FormattedMessage id="UserProfile.UserProfitMarginSlider" />
            ),
            value: (
              <Slider
                min={0}
                max={slider}
                value={+formikContext.values.currentProfitMarginPt!}
                onChange={handleCurrentProfitMarginChange('Pt')}
              />
            ),
            doNotRender: !profitMarginTemplate.some(section =>
              section.includes('currentProfitMarginPt'),
            ),
          },
          {
            name: 'currentProfitMarginPd',
            label: <FormattedMessage id="Global.Metals.Palladium" />,
            value: (
              <Slider
                min={0}
                max={slider}
                value={+formikContext.values.currentProfitMarginPd!}
                onChange={handleCurrentProfitMarginChange('Pd')}
              />
            ),
            doNotRender: !profitMarginTemplate.some(section =>
              section.includes('currentProfitMarginPd'),
            ),
          },
          {
            name: 'currentProfitMarginRh',
            label: <FormattedMessage id="Global.Metals.Rhodium" />,
            value: (
              <Slider
                min={0}
                max={slider}
                value={+formikContext.values.currentProfitMarginRh!}
                onChange={handleCurrentProfitMarginChange('Rh')}
              />
            ),
            doNotRender: !profitMarginTemplate.some(section =>
              section.includes('currentProfitMarginRh'),
            ),
          },
        ]}
      />
      <DetailsSection
        background={theme.colors.codGrayDarker}
        template={['confirmProfitMargin confirmProfitMargin confirmProfitMargin']}
        fields={[
          {
            name: 'confirmProfitMargin',
            label: '',
            value: (
              <ConfirmProfitMargin>
                <CancelButton onClick={formikContext.handleReset} disabled={!formikContext.dirty}>
                  <FormattedMessage id="Global.Cancel" />
                </CancelButton>
                <AppButton onClick={formikContext.handleSubmit} disabled={!formikContext.dirty}>
                  <FormattedMessage id="UserProfile.UpdateProfitMargin" />
                </AppButton>
              </ConfirmProfitMargin>
            ),
            padding: { h: 0 },
          },
        ]}
        hasRoundedBorders={false}
      />
    </FormikProvider>
  );
}

export { UserProfileProfitMargin };
