import React from 'react';

import { Badge } from 'components/shared/Badge';
import { TextButton } from 'components/shared/Buttons';
import { DetailsSection } from 'components/shared/Details';
import { DetailsSectionField } from 'components/shared/Details/DetailsSection';
import { LoadableContent } from 'components/shared/Loader';
import { getRoleLang } from 'helpers/roles/roles';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { theme } from 'theme';
import { useHedgeUsers } from './useHedgeUsers';
import { useTypedIntl } from '../locale/messages';

export const HedgeDetailsAssignedUsers = ({
  hedgeId,
}: {
  hedgeId: number;
}): React.ReactElement | null => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const { isPending, list, count } = useHedgeUsers(hedgeId);

  if (count === 0) return null;

  return (
    <LoadableContent loading={isPending} mode={LoadableContent.MODE.INLINE}>
      <DetailsSection
        title={intl.formatMessage({ id: 'HedgeDetails.AssignedUsers' })}
        template={list.map(user => `${user.id}Role ${user.id}Name ${user.id}Company`)}
        fields={list.reduce<DetailsSectionField[]>(
          (prev, user) => [
            ...prev,
            {
              name: `${user.id}Role`,
              label: intl.formatMessage({ id: 'HedgeDetails.AssignedUsers.Roles' }),
              value: user?.roles?.map(role => (
                <Badge key={role.id} color={theme.colors.mineShaftLightest}>
                  {getRoleLang(role.name, intl)}
                </Badge>
              )),
            },
            {
              name: `${user.id}Name`,
              label: intl.formatMessage({ id: 'HedgeDetails.AssignedUsers.Name' }),
              value: (
                <TextButton onClick={() => navigate(`/users/${user.id}`)}>
                  {user.firstName} {user.lastName}
                </TextButton>
              ),
            },
            {
              name: `${user.id}Company`,
              label: intl.formatMessage({ id: 'HedgeDetails.AssignedUsers.Company' }),
              value: user.company.name,
            },
          ],
          [],
        )}
      />
    </LoadableContent>
  );
};
