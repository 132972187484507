import React, { useMemo } from 'react';

import iconConfirm from 'assets/images/icons/confirm.svg';
import { Icon } from 'components/shared/Buttons';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { useRemoveFeedbacks } from 'shared/mutations';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { theme } from 'theme';
import { floatingButtonStyles, RemoveFeedbacksContainer } from './FeedbacksList.styles';
import { TypedFormattedMessage, useTypedIntl } from '../locale/messages';

interface Props {
  enableSelection: boolean;
  setEnableSelection: (value: boolean) => void;
  selection: Record<string, boolean>;
  resetSelection: () => void;
  removeFeedbacks: ReturnType<typeof useRemoveFeedbacks>;
  onRemove: () => void;
}

export const RemoveFeedbacks = ({
  enableSelection,
  setEnableSelection,
  selection,
  resetSelection,
  removeFeedbacks,
  onRemove,
}: Props) => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();

  const exitSelection = () => {
    resetSelection();
    setEnableSelection(false);
  };

  const selectedIds = useMemo(
    () =>
      Object.keys(selection)
        .filter(key => selection[key])
        .map(Number),
    [selection],
  );

  const onRemoveCallback = async () => {
    const messageType = selectedIds.length > 1 ? 'Plural' : 'Singular';
    dispatch(
      showModal({
        message: intl.formatMessage({
          id: `FeedbacksList.RemoveFeedbacks.RemoveSelected.${messageType}`,
        }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          dispatch(hideModal());

          try {
            await removeFeedbacks.mutateAsync(selectedIds);
            dispatch(
              snackBarPushSuccess(
                intl.formatMessage({
                  id: `FeedbacksList.RemoveFeedbacks.SuccessMessage.${messageType}`,
                }),
              ),
            );
            onRemove();
          } catch (e) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
        },
      }),
    );
  };

  if (enableSelection) {
    return (
      <RemoveFeedbacksContainer>
        <span>
          <TypedFormattedMessage
            id="FeedbacksList.RemoveFeedbacks.SelectedCount"
            values={{ count: selectedIds.length }}
          />
        </span>
        <AppButton onClick={exitSelection}>
          <TypedFormattedMessage id="Global.Cancel" />
        </AppButton>
        <AppButton onClick={onRemoveCallback} disabled={!selectedIds.length}>
          <TypedFormattedMessage id="FeedbacksList.RemoveFeedbacks.RemoveSelected" />
        </AppButton>
      </RemoveFeedbacksContainer>
    );
  }

  return (
    <AppButton
      styleType="neutral"
      className={floatingButtonStyles}
      onClick={() => setEnableSelection(true)}
    >
      <Icon icon={iconConfirm} color={theme.colors.white} />
      <TypedFormattedMessage id="FeedbacksList.RemoveFeedbacks.SelectForRemoval" />
    </AppButton>
  );
};
