import React, { Fragment } from 'react';

import { formatDate } from 'helpers/dateTime/dateTime';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { Status, UserLocationMarker } from 'shared/types';

export const MapUserInfo = ({ marker }: { marker: UserLocationMarker }): React.ReactElement => (
  <Fragment key={marker.id}>
    <p>
      {marker.user.firstName} {marker.user.lastName}
    </p>
    {marker.user.status === Status.INACTIVE ? (
      <span className="status">
        <FormattedMessage id="Global.Status.INACTIVE" />
      </span>
    ) : null}
    <p>{formatDate(marker.lastSeen, 'd MMM yyyy p')}</p>
  </Fragment>
);
