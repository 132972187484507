import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { components, GroupBase, InputProps, SelectInstance } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useField } from 'formik';
import { matchSorter } from 'match-sorter';

import { useTypedIntl } from 'locale/messages';
import { useDropdownPlacement } from 'shared/hooks';
import { FieldSelectOption, Props as FieldSelectProps } from './FieldSelect';
import { FieldSelectStyled, HighlightedTextStyle } from './FieldSelect.styles';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomFilterFunction<T = any> = (
  option?: FieldSelectOption & T,
  rawData?: string,
) => boolean;

interface Props
  extends Pick<
    FieldSelectProps,
    | 'name'
    | 'clearable'
    | 'disabled'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'onInputChange'
    | 'options'
    | 'searchable'
    | 'loading'
    | 'value'
    | 'error'
    | 'placeholder'
    | 'isPrefix'
    | 'formatOptionLabel'
    | 'styles'
    | 'multi'
    | 'onCreateOption'
    | 'hideSelectedOptions'
    | 'noOptionsMessage'
  > {
  isLoading?: boolean;
  validateInput?: (value?: string) => void;
  maxLength: number;
  customFilterFunction?: CustomFilterFunction;
}

const optionRenderer = ({ label }: { label: string }, { inputValue }: { inputValue: string }) => {
  if (/^Create:.+$/.test(label)) {
    return label;
  }
  return (
    <Highlighter
      searchWords={[inputValue]}
      highlightClassName={HighlightedTextStyle}
      textToHighlight={label}
      autoEscape
    />
  );
};

const FieldCreatableSelectLayout = ({
  clearable = true,
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  searchable = true,
  error,
  value,
  placeholder,
  isLoading,
  validateInput,
  customFilterFunction,
  maxLength,
  onInputChange,
  onCreateOption,
  hideSelectedOptions,
  noOptionsMessage,
}: Props): React.ReactElement => {
  const [, { touched }, { setValue, setTouched }] = useField(name);
  const [filteredOptions, setFilteredOptions] = useState<FieldSelectOption[]>([]);
  const { dropdownPlacement, dropdownRef, refreshDropdownPlacement } =
    useDropdownPlacement('bottom');
  const selectRef =
    useRef<SelectInstance<FieldSelectOption, false, GroupBase<FieldSelectOption>>>(null);

  const customFilterOption = useCallback(
    (option, rawInput) =>
      option.label.toString().toLowerCase().includes(rawInput.toString().toLowerCase()),
    [],
  );

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleChange = useCallback(
    val => {
      if (onChange) onChange(val);
      else setValue(val?.value);
    },
    [onChange, setValue, setTouched, selectRef],
  );

  const handleFocus = useCallback(
    e => {
      if (onFocus) onFocus(e);
    },
    [onFocus],
  );

  const handleInputChange = useCallback(
    (val, actionType) => {
      if (onInputChange) onInputChange(val, actionType);
      if (actionType.action === 'input-change') {
        const upperCasedVal = val.toUpperCase();
        if (upperCasedVal) {
          validateInput?.(upperCasedVal);
        }
        if (searchable) {
          setFilteredOptions(matchSorter(options, val, { keys: ['label'] }));
        }

        if (val) return upperCasedVal;
      }
    },
    [validateInput, onInputChange],
  );

  const handleBlur = useCallback(
    event => {
      setTouched(true);
      setFilteredOptions(options);
      if (onBlur) onBlur(event);
    },
    [setTouched, onBlur, options],
  );

  useEffect(() => {
    if (value) setTouched(true);
  }, [value]);

  const selected = useMemo(() => {
    if (!value) return null;

    return options?.find(option => value === option.value);
  }, [value, options]);

  const styles = useMemo(
    () => FieldSelectStyled(touched && Boolean(error), false, {}, null, dropdownPlacement),
    [error, touched, dropdownPlacement],
  );

  const intl = useTypedIntl();
  const formatCreateLabel = inputValue =>
    intl.formatMessage({ id: 'Global.Fields.Select.Create' }, { value: inputValue });

  const handleMenuOpen = () => {
    refreshDropdownPlacement();
  };

  const Input = useCallback(
    (props: InputProps<FieldSelectOption, false, GroupBase<FieldSelectOption>>) => (
      <components.Input {...props} maxLength={maxLength} />
    ),
    [],
  );

  return (
    <div data-cy={name} ref={dropdownRef}>
      <CreatableSelect
        ref={selectRef}
        filterOption={customFilterFunction || customFilterOption}
        formatCreateLabel={formatCreateLabel}
        placeholder={placeholder || intl.formatMessage({ id: 'Global.Fields.Select.Placeholder' })}
        isClearable={!disabled || clearable}
        isDisabled={disabled}
        isLoading={isLoading}
        menuIsOpen={disabled ? false : undefined}
        isSearchable={searchable}
        name={name}
        onChange={disabled ? undefined : handleChange}
        onCreateOption={onCreateOption}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        options={filteredOptions}
        formatOptionLabel={optionRenderer}
        styles={styles}
        value={selected}
        openMenuOnFocus
        menuPlacement={dropdownPlacement}
        onMenuOpen={handleMenuOpen}
        components={{ Input }}
        hideSelectedOptions={hideSelectedOptions}
        noOptionsMessage={noOptionsMessage}
      />
    </div>
  );
};

const FieldCreatableSelect = withFieldWrapper<Props>(props => (
  <FieldCreatableSelectLayout {...props} />
));

export { FieldCreatableSelect };
