import React from 'react';

import assaysIcon from 'assets/images/icons/assays.svg';
import carIcon from 'assets/images/icons/car.svg';
import companiesIcon from 'assets/images/icons/companies.svg';
import convertersIcon from 'assets/images/icons/converters.svg';
import dashboardIcon from 'assets/images/icons/dashboard.svg';
import feedbackIcon from 'assets/images/icons/feedback.svg';
import hedgeIcon from 'assets/images/icons/hedge.svg';
import nsConvertersIcon from 'assets/images/icons/ns-converters.svg';
import pinIcon from 'assets/images/icons/pin.svg';
import settingsIcon from 'assets/images/icons/Settings-icon.svg';
import statisticsIcon from 'assets/images/icons/statistics.svg';
import usersIcon from 'assets/images/icons/users.svg';
import { UserMenuLinks } from 'components/shared/UserMenu/UserMenuLinks/UserMenuLinks';
import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartLocation, useMediaQuery } from 'shared/hooks';
import { NonEmptyArray } from 'shared/types';
import { HIDDEN_ON_MEDIA_QUERY, MEDIA_QUERY } from 'theme';
import { NavRoot, StyledNavList } from './NavList.styles';
import NavListItem from './NavListItem';
import { useTypedIntl } from '../locale/messages';

interface RootNavItem {
  to: string;
  label: string;
  icon: string;
  maskSize?: string;
}

interface NavLink {
  to: string;
  label: string;
  show: boolean;
}

export type NavItem = (RootNavItem & NavLink) | (RootNavItem & { leafs: NonEmptyArray<NavLink> });

export const shouldShow = (item: NavItem | NavLink): boolean =>
  'leafs' in item ? item.leafs.some(subItem => subItem.show) : item.show;

type NavListProps = {
  toggleMenu?: () => void;
  menuOpened?: boolean;
};

const NavList = ({ toggleMenu = () => {}, menuOpened }: NavListProps): React.ReactElement => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const location = useAlphamartLocation();

  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isMaxXl = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const noLabelLocations = ['/geolocation'];
  const compact =
    isMd && (isMaxXl || noLabelLocations.some(path => location.pathname.includes(path)));

  const navListConfig: NavItem[] = [
    {
      to: '/dashboard',
      icon: dashboardIcon,
      label: intl.formatMessage({ id: 'Sidebar.Dashboard' }),
      show: authorize(PERMISSIONS.DASHBOARD.SHOW),
    },
    {
      to: '/assays',
      icon: assaysIcon,
      label: intl.formatMessage({ id: 'Sidebar.Assays' }),
      show: authorize(PERMISSIONS.ASSAYS.LIST),
    },
    {
      to: '/converters',
      icon: convertersIcon,
      label: intl.formatMessage({ id: 'Sidebar.Converters' }),
      show: authorize(PERMISSIONS.CONVERTERS.LIST),
    },
    {
      to: '/nonstandard-converters',
      icon: nsConvertersIcon,
      label: intl.formatMessage({ id: 'Sidebar.NonstandardConverters' }),
      show: authorize(PERMISSIONS.NONSTANDARD_CONVERTERS.MAIN),
    },
    {
      to: '/hedges',
      icon: hedgeIcon,
      label: intl.formatMessage({ id: 'Sidebar.Hedges' }),
      show: authorize(PERMISSIONS.HEDGES.LIST),
    },
    {
      to: '/vehicles',
      icon: carIcon,
      label: intl.formatMessage({ id: 'Sidebar.Vehicles' }),
      show: authorize(PERMISSIONS.VEHICLES.MAIN),
      maskSize: 'contain',
    },
    {
      to: '/users',
      icon: usersIcon,
      label: intl.formatMessage({ id: 'Sidebar.Users' }),
      show: authorize(PERMISSIONS.USERS.LIST),
    },
    {
      to: '/companies',
      icon: companiesIcon,
      label: intl.formatMessage({ id: 'Sidebar.Companies' }),
      show: authorize(PERMISSIONS.COMPANIES.LIST),
    },
    {
      to: '/company/settings',
      icon: settingsIcon,
      label: intl.formatMessage({ id: 'Sidebar.Settings' }),
      show: authorize(PERMISSIONS.COMPANY_SETTINGS),
    },
    {
      to: '/geolocation',
      icon: pinIcon,
      label: intl.formatMessage({ id: 'Sidebar.Geolocation' }),
      show: authorize(PERMISSIONS.GEOLOCATION.DISPLAY),
    },
    {
      to: '/statistics',
      icon: statisticsIcon,
      label: intl.formatMessage({ id: 'Sidebar.Statistics' }),
      show: authorize(PERMISSIONS.STATISTICS.LIST),
    },
    {
      to: '/feedback',
      icon: feedbackIcon,
      label: intl.formatMessage({ id: 'Sidebar.Feedback' }),
      leafs: [
        {
          to: '/feedback',
          label: intl.formatMessage({ id: 'Sidebar.Feedback.Reviews' }),
          show: authorize(PERMISSIONS.FEEDBACKS.MAIN),
        },
        {
          to: '/feedback/summary',
          label: intl.formatMessage({ id: 'Sidebar.Feedback.Summary' }),
          show: authorize(PERMISSIONS.FEEDBACKS.MAIN),
        },
      ],
    },
  ];

  return (
    <StyledNavList menuOpened={!!menuOpened}>
      <NavRoot>
        {navListConfig.filter(shouldShow).map(item => (
          <NavListItem key={item.to} item={item} toggleMenu={toggleMenu} compact={compact} />
        ))}
      </NavRoot>
      <div className={HIDDEN_ON_MEDIA_QUERY.MD}>
        <UserMenuLinks closeMenuHandler={toggleMenu} />
      </div>
    </StyledNavList>
  );
};

export { NavList };
