export enum SortableModules {
  VEHICLES = 'vehicles',
}

export enum SortTypes {
  UPDATED_AT = 'updatedAt',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortInterface {
  sortType: SortTypes;
  sortDirection: SortDirection;
}

export type SortSelectOptions = {
  label: string;
  value: SortInterface;
};

export interface SortState {
  [SortableModules.VEHICLES]: SortInterface | null;
}

export const defaultSorting: SortState = {
  [SortableModules.VEHICLES]: null,
};
