export const matchSampleName = (query: string): RegExp | undefined => {
  if (typeof query !== 'string') return;

  return new RegExp(
    query
      .replace(/[^\w]|_/g, '')
      .split('')
      .join('[^a-z\\d]*?'),
    'ig',
  );
};

export const matchSampleNameMultiWord = (query: string): RegExp | undefined => {
  if (!query) return;

  const queryWords = query
    .replaceAll(/\s+/g, ' ')
    .replaceAll(/\\[\\]/g, '')
    .split(' ');

  const regexWithAlternatives = Array.from(new Set([query, ...queryWords])).map(word =>
    word
      .replace(/[^\w\\*]|_/g, '')
      .replaceAll('*', '(?:.*?)')
      .split(/(?![^(]*\))/)
      .join('[^a-z\\d]*?')
      .replace(/[LI1]/gi, '[LI1]')
      .replace(/[O0]/gi, '[O0]'),
  );

  return new RegExp(`${regexWithAlternatives.join('|')}`, 'ig');
};
