import React, { useState } from 'react';
import styled from '@emotion/styled';

import { Badge } from 'components/shared/Badge';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { Gallery } from 'components/shared/Gallery';
import { IconTooltipWarning } from 'components/shared/IconTooltip/IconTooltip';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate } from 'helpers/dateTime/dateTime';
import { removeChecker } from 'helpers/objects/removeChecker';
import { useAssayEditActions } from 'shared/hooks';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { Assay, ItemAction } from 'shared/types';
import { theme } from 'theme';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

const StyledNotes = styled.div`
  align-items: center;
  display: flex;

  > div {
    margin-right: 10px;
  }
`;

const StyledGallery = styled.div`
  margin-bottom: 35px;
`;

type AssayDetailsParam = {
  assay: Assay;
};

const AssayDetails = ({ assay }: AssayDetailsParam): React.ReactElement | null => {
  const [isDetailsScrollable, setIsDetailsScrollable] = useState(false);
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const { actionsPending, handleRemove } = useAssayEditActions();

  if (!assay) return null;

  const otherNumbersList =
    assay.converter.otherNumbers && assay.converter.otherNumbers.length
      ? assay.converter.otherNumbers.map(otherNumber => (
          <Badge key={otherNumber.id} color={theme.colors.mineShaftLightest} breakable>
            {otherNumber.original}
          </Badge>
        ))
      : null;

  const actions: ItemAction[] = [
    {
      id: 1,
      label: <FormattedMessage id="Global.Remove" />,
      onClick: () => handleRemove(assay, 'assays', location.state),
      visible: !removeChecker(assay),
    },
    {
      id: 2,
      label: <FormattedMessage id="Global.Update" />,
      onClick: () => navigate(`/assays/${assay.id}/edit`, { state: location.state }),
      visible: !removeChecker(assay),
    },
  ];

  return (
    <LoadableContent loading={actionsPending} mode={LoadableContent.MODE.OVERLAY} drawContent>
      <Details
        title={assay.sampleName}
        backUrl="/assays"
        backState={location.state}
        scrollable={!isDetailsScrollable}
        actions={actions}
      >
        <DetailsSection
          title={<FormattedMessage id="AssaysDetails.ConverterDetails" />}
          fields={[]}
        >
          {assay.converter.files[0] && (
            <StyledGallery>
              <Gallery
                images={assay.converter.files}
                onLightboxToggle={isOpened => {
                  setIsDetailsScrollable(isOpened);
                }}
              />
            </StyledGallery>
          )}
        </DetailsSection>
        <DetailsSection
          fields={[
            {
              name: 'identifier',
              label: <FormattedMessage id="AssaysDetails.Identifier" />,
              value: assay.converter.identifier,
            },
            {
              name: 'partName',
              label: <FormattedMessage id="AssaysDetails.PartName" />,
              value: assay.converter.partName,
            },
            {
              name: 'nicknames',
              label: <FormattedMessage id="AssaysDetails.Nicknames" />,
              value: assay.converter.nicknames,
            },
            {
              name: 'partialConverter',
              label: <FormattedMessage id="AssaysDetails.PartialConverter" />,
              value: assay.converter.isPartial ? (
                <FormattedMessage id="Global.Yes" />
              ) : (
                <FormattedMessage id="Global.No" />
              ),
            },
            {
              name: 'type',
              label: <FormattedMessage id="AssaysDetails.Type" />,
              value: assay.converter.type?.name,
            },
            {
              name: 'folder',
              label: <FormattedMessage id="AssaysDetails.Folder" />,
              value: assay.converter.folder?.name,
            },
            {
              name: 'make',
              label: <FormattedMessage id="AssaysDetails.Make" />,
              value: assay.converter.make,
            },
            {
              name: 'model',
              label: <FormattedMessage id="AssaysDetails.Model" />,
              value: assay.converter.model,
            },
            {
              name: 'year',
              label: <FormattedMessage id="AssaysDetails.Year" />,
              value: assay.converter.year,
            },
            {
              name: 'otherNumbers',
              label: <FormattedMessage id="AssaysDetails.OtherNumbers" />,
              value: otherNumbersList,
            },
            {
              name: 'counterfeit',
              label: <FormattedMessage id="AssaysDetails.Counterfeit" />,
              value: assay.converter.counterfeit ? (
                <FormattedMessage id="Global.Yes" />
              ) : (
                <FormattedMessage id="Global.No" />
              ),
            },
            {
              name: 'notes',
              label: <FormattedMessage id="AssaysDetails.ConverterNotes" />,
              value: assay.converter.counterfeit ? (
                <StyledNotes>
                  <IconTooltipWarning
                    tooltip={<FormattedMessage id="AssaysDetails.Counterfeit" />}
                  />
                  {assay.converter.notes}
                </StyledNotes>
              ) : (
                assay.converter.notes
              ),
            },
          ]}
        />

        <DetailsSection
          title={<FormattedMessage id="AssaysDetails.AssayDetails" />}
          fields={[
            {
              name: 'sampleDate',
              label: <FormattedMessage id="AssaysDetails.SampleDate" />,
              value: assay.sampleDate && formatDate(assay.sampleDate),
            },
            {
              name: 'weightWet',
              label: <FormattedMessage id="AssaysDetails.WeightWet" />,
              value: assay.weightWetGrams ? `${assay.weightWetGrams.toFixed(3)} g` : null,
            },
            {
              name: 'weightDry',
              label: <FormattedMessage id="AssaysDetails.WeightDry" />,
              value: assay.weightDryLbs ? `${assay.weightDryLbs.toFixed(3)} lbs` : null,
            },
            {
              name: 'moisturePercentage',
              label: <FormattedMessage id="AssaysDetails.MoisturePercentage" />,
              value: assay.moisturePercentage ? `${assay.moisturePercentage.toFixed(3)} %` : null,
            },
            {
              name: 'carbonPercentage',
              label: <FormattedMessage id="AssaysDetails.CarbonPercentage" />,
              value: assay.carbonPercentage ? `${assay.carbonPercentage.toFixed(3)} %` : null,
            },
            {
              name: 'notes',
              label: <FormattedMessage id="AssaysDetails.Notes" />,
              value: assay.notes,
            },
          ]}
        />
        <DetailsSection
          title={<FormattedMessage id="AssaysDetails.Analysis" />}
          fields={[
            {
              name: 'platinumPpm',
              label: <FormattedMessage id="AssaysDetails.PlatinumPpm" />,
              value: Number.isFinite(assay.platinumPpm)
                ? `${assay.platinumPpm.toFixed(3)} ppm`
                : null,
            },
            {
              name: 'palladiumPpm',
              label: <FormattedMessage id="AssaysDetails.PalladiumPpm" />,
              value: Number.isFinite(assay.palladiumPpm)
                ? `${assay.palladiumPpm.toFixed(3)} ppm`
                : null,
            },
            {
              name: 'rhodiumPpm',
              label: <FormattedMessage id="AssaysDetails.RhodiumPpm" />,
              value: Number.isFinite(assay.rhodiumPpm)
                ? `${assay.rhodiumPpm.toFixed(3)} ppm`
                : null,
            },
          ]}
        />
        <DetailsFooter
          fields={[
            {
              name: 'createdBy',
              label: <FormattedMessage id="Global.CreatedBy" />,
              value: assay.createdBy && `${assay.createdBy.firstName} ${assay.createdBy.lastName}`,
            },
            {
              name: 'createdAt',
              label: <FormattedMessage id="Global.CreatedOn" />,
              value: formatDate(assay.createdAt),
            },
            {
              name: 'updatedBy',
              label: <FormattedMessage id="Global.UpdatedBy" />,
              value: assay.updatedBy && `${assay.updatedBy.firstName} ${assay.updatedBy.lastName}`,
            },
            {
              name: 'updatedAt',
              label: <FormattedMessage id="Global.UpdatedOn" />,
              value: assay.updatedAt && formatDate(assay.updatedAt),
            },
            ...(removeChecker(assay)
              ? [
                  {
                    name: 'deletedBy',
                    label: <FormattedMessage id="Global.DeletedBy" />,
                    value:
                      assay.deletedBy && `${assay.deletedBy.firstName} ${assay.deletedBy.lastName}`,
                  },
                  {
                    name: 'deletedAt',
                    label: <FormattedMessage id="Global.DeletedOn" />,
                    value: assay.deletedAt && formatDate(assay.deletedAt),
                  },
                ]
              : []),
          ]}
        />
      </Details>
    </LoadableContent>
  );
};

export { AssayDetails };
