import React from 'react';

import searchIcon from 'assets/images/icons/search.svg';
import { Icon } from 'components/shared/Buttons';
import {
  GridSearchHits,
  highlightableTextStyles,
  HighlightSearch,
  RowProps,
  SearchField,
  SearchFieldLabel,
  SearchGridContainer,
} from 'components/shared/List';
import { Converter, ConverterListItem } from 'shared/types';
import { useSearchableConvertersFields } from './useSearchableConvertersFields';

export const ConvertersSearchGrid = (
  props: React.PropsWithChildren<RowProps<ConverterListItem | Converter>>,
) => {
  const converter = props.row!.original;
  const searchInput = props.table!.getState().globalFilter?.trim();
  const searchableFields = useSearchableConvertersFields({ converter, searchInput, isGrid: true });

  return (
    <SearchGridContainer>
      {!!searchableFields.length && (
        <GridSearchHits>
          <Icon icon={searchIcon} />
          {searchableFields.map(
            field =>
              field && (
                <SearchField key={field.label}>
                  <SearchFieldLabel>{field.label}</SearchFieldLabel>{' '}
                  <HighlightSearch
                    className={highlightableTextStyles(false)}
                    searchWords={[field.matcher(searchInput)!]}
                    textToHighlight={field.value}
                  />
                </SearchField>
              ),
          )}
        </GridSearchHits>
      )}
    </SearchGridContainer>
  );
};
