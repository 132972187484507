import { createFileId } from './createFileId';

export interface FileReadResult {
  file: File;
  id: string;
  image: string;
}

export const readFile = async (file: File): Promise<FileReadResult | void> => {
  try {
    const reader = new FileReader();
    const promise = new Promise<FileReadResult>(resolve => {
      reader.onload = e => {
        if (!e.target?.result || typeof e.target.result !== 'string') return;
        resolve({ file, id: createFileId(file), image: e.target.result });
      };
    });
    reader.readAsDataURL(file);
    return promise;
  } catch (error) {
    console.error(error);
  }
};
