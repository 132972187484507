// Threshold can be between 0 and 127:
// the higher it is, the more colors are considered to be too grey-like.
export const randomHexColor = (threshold = 50): string => {
  // eslint-disable-next-line prefer-const
  let [redPart, greenPart, bluePart] = Array.from(Array(3), () =>
    Math.floor(Math.random() * 256),
  ).sort((a, b) => a - b);

  if (bluePart - redPart < threshold) {
    const exclude = Math.min(255, redPart + threshold) - Math.max(0, bluePart - threshold);
    greenPart = Math.floor(Math.random() * (256 - exclude));
    if (greenPart >= bluePart - threshold) greenPart += exclude;
  }

  const color = [redPart, greenPart, bluePart].map(p => `0${p.toString(16)}`.substr(-2)).join('');

  return `#${color}`;
};
