import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { PaginationState, Updater } from '@tanstack/react-table';

import { LIST_MODE } from 'shared/constants';
import {
  defaultSorting,
  SortableModules,
  SortDirection,
  SortState,
  SortTypes,
} from 'shared/constants/sortableModules';
import { BREAKPOINTS } from 'theme';
import { GenericThunk } from './shared/createGenericStoreSlice';

const INITIAL_PAGE = 0;

interface ListComponentState {
  listMode: LIST_MODE;
  pageIndex: number;
  pageSize: number;
  columnsQuantity: number;
  sortState: SortState;
}

type ListModeReducer = {
  changeListModeAction: CaseReducer<ListComponentState, { type: string; payload: LIST_MODE }>;
  changePageIndexAction: CaseReducer<ListComponentState, { type: string; payload: number }>;
  changePageSizeAction: CaseReducer<ListComponentState, { type: string; payload: number }>;
  changeColumnsQuantityAction: CaseReducer<ListComponentState, { type: string; payload: number }>;
  setSortState: CaseReducer<
    ListComponentState,
    {
      type: string;
      payload: {
        moduleName: SortableModules;
        value: { sortType: SortTypes; sortDirection: SortDirection } | null;
      };
    }
  >;
  resetListOnModuleChange: CaseReducer<ListComponentState, { type: string }>;
};

const listComponentSlice = createSlice<ListComponentState, ListModeReducer>({
  name: 'listComponent',
  initialState: {
    pageIndex: INITIAL_PAGE,
    pageSize: 12,
    listMode: LIST_MODE.TABLE,
    columnsQuantity:
      document.documentElement.clientWidth || window.innerWidth > BREAKPOINTS.SM ? 4 : 2,
    sortState: defaultSorting,
  },
  reducers: {
    changeListModeAction: (state, { payload }) => ({
      ...state,
      listMode: payload,
    }),
    changePageIndexAction: (state, { payload }) => ({
      ...state,
      pageIndex: payload,
    }),
    changePageSizeAction: (state, { payload }) => ({
      ...state,
      pageIndex: INITIAL_PAGE, // Page number is reset to default when page size is changed
      pageSize: payload,
    }),
    changeColumnsQuantityAction: (state, { payload }) => ({
      ...state,
      columnsQuantity: payload,
    }),
    setSortState: (state, { payload }) => ({
      ...state,
      sortState: {
        ...state.sortState,
        [payload.moduleName]: payload.value,
      },
    }),
    resetListOnModuleChange: state => ({
      ...state,
      pageIndex: INITIAL_PAGE,
    }),
  },
});

export const {
  changePageIndexAction,
  changePageSizeAction,
  changeListModeAction,
  changeColumnsQuantityAction,
  setSortState,
  resetListOnModuleChange,
} = listComponentSlice.actions;

export const changePagination =
  (updater: Updater<PaginationState>): GenericThunk =>
  (dispatch, getState) => {
    const old = getState().listComponent;
    const { pageIndex, pageSize } =
      typeof updater === 'function'
        ? updater({ pageIndex: old.pageIndex, pageSize: old.pageSize })
        : updater;
    dispatch(changePageSizeAction(pageSize));
    dispatch(changePageIndexAction(pageIndex));
  };

export default listComponentSlice.reducer;
