import axios, { AxiosError } from 'axios';

import { ErrorCode } from 'shared/constants';

type ErrorData = Record<string, string | string[]> & { message?: string };

export interface AlphamartErrorData<T = ErrorData> {
  message: string;
  httpCode: number;
  errorCode: ErrorCode;
  name: string;
  errorData: T;
}

export type AlphamartHttpError<T = ErrorData> = AxiosError<AlphamartErrorData<T>>;

export const isAlphamartHttpError = <T = ErrorData>(
  error: unknown,
): error is AlphamartHttpError<T> => axios.isAxiosError(error);
