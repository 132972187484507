import React from 'react';

import lockIcon from 'assets/images/icons/lock.svg';
import { Icon } from 'components/shared/Buttons';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { InfoPageContainer } from 'components/shared/InfoPageContainer';
import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useDefaultRedirect, useMediaQuery } from 'shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';

function Forbidden(): React.ReactElement {
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_MD);
  const redirectTo = useDefaultRedirect();

  const goBack = () => window.location.replace(redirectTo);

  return (
    <InfoPageContainer theme={theme} data-cy="forbidden-page">
      <div>
        4
        <Icon icon={lockIcon} size={isPhone ? '128px' : '240px'} color={theme.colors.whisper} />3
      </div>
      <p>
        <FormattedMessage id="Global.NoAccess" />
      </p>
      <AppButton onClick={goBack}>
        <FormattedMessage id="Global.TakeMeHome" />
      </AppButton>
    </InfoPageContainer>
  );
}

export { Forbidden };
