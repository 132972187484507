import { parseISO } from 'date-fns';

import { ScatterData } from 'components/shared/ScatterChart/ScatterChart';
import { DashboardFiltersKeys } from 'shared/constants';
import { Statistics, UserListItem } from 'shared/types';

export interface StatisticsScatterPayload {
  type: 'min' | 'average' | 'user';
  x: number;
  y: number;
  user?: Pick<UserListItem, 'firstName' | 'lastName' | 'id'>;
}

const COLORS = {
  USER: '#F54952',
  AVG: '#7279D1',
  MIN: '#71CF93',
};

export const parseData = (statistics?: Statistics): ScatterData[] => {
  if (!statistics) return [];

  const {
    series: { range, max, min, average, userSeries },
  } = statistics;

  const rangeMapped = range.map(el => parseISO(el).valueOf());
  const maxFuzziness = (rangeMapped.length > 1 ? rangeMapped[1] - rangeMapped[0] : 0) * 0.4;
  const calcFuzziness = (y: number, dateMax: number) => {
    if (!dateMax || !y) return 0;
    return (Math.random() - 0.5) * maxFuzziness * (1 - y / dateMax);
  };
  const minData = {
    fill: COLORS.MIN,
    lineJointType: 'monotoneX' as const,
    line: true,
    data: rangeMapped.map((x, i) => ({ x, y: min[i] ?? 0, type: 'min' })),
    reactKey: `${rangeMapped.join()}_min`,
  };

  const averageData = {
    fill: COLORS.AVG,
    lineJointType: 'monotoneX' as const,
    line: true,
    data: rangeMapped.map((x, i) => ({ x, y: average[i] ?? 0, type: 'average' })),
    reactKey: `${rangeMapped.join()}_avg`,
  };

  const userData = userSeries.map(user => {
    const nonEmptyData = rangeMapped
      .map((x, i) => ({
        x: x + calcFuzziness(user.data[i], max[i]),
        y: user.data[i],
        type: 'user',
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      }))
      .filter(d => d.y !== null);
    return {
      fill: COLORS.USER,
      line: false,
      data: nonEmptyData,
      reactKey: `${nonEmptyData.map(data => `${data.y}_${data.x}_${user.id}`).join('_')}`,
    };
  });

  return [minData, averageData, ...userData];
};

const xAxisHideNthTickMap: Record<DashboardFiltersKeys, number> = {
  'Days-1': 0,
  'Weeks-1': 2,
  'Months-1': 2,
  'Years-1': 2,
  'Years-5': 3,
};
export const getXAxisTicks = (
  dates: string[],
  isMobile: boolean,
  selectedPeriod?: DashboardFiltersKeys,
): number[] => {
  if (!dates || !Array.isArray(dates) || dates.length === 0) return [Date.now()];

  if (isMobile) {
    const hideNthTick = selectedPeriod && xAxisHideNthTickMap[selectedPeriod];
    const safeDates = hideNthTick ? dates.filter((el, i) => i % hideNthTick === 0) : dates;
    return safeDates.map(el => parseISO(el).valueOf());
  }

  return dates.map(el => parseISO(el).valueOf());
};

export const getYAxisTicks = (max: number, step = 25): number[] => {
  const ticks = [0];
  while (ticks[ticks.length - 1] < max) {
    ticks.push(ticks[ticks.length - 1] + step);
  }
  return ticks;
};
