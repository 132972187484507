import React from 'react';
import styled from '@emotion/styled';
import { kebabCase } from 'lodash';

import { TypedIntlShape } from 'locale/messages';
import { Theme } from 'theme';
import { Group } from './FieldMultiselectCheckbox';
import { GroupCaret } from './GroupCaret';

interface GroupLabelProps {
  group: Group;
  handleGroupSelection: (selectedGroup: Group) => void;
  isSelected: boolean;
  intl: TypedIntlShape;
  children: React.ReactNode;
  isInitiallyOpen?: boolean;
}
export const GroupComponent = ({
  group,
  handleGroupSelection,
  isSelected,
  intl,
  children,
  isInitiallyOpen = false,
}: GroupLabelProps): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(isInitiallyOpen);
  const toggleOpen = () => setIsOpen(!isOpen);

  const dataCyAppendix = group.groupLabel ? `-${kebabCase(group.groupLabel)}` : '';

  return (
    <>
      <GroupContainer>
        <GroupToggleVisibility
          onClick={toggleOpen}
          data-cy={`field-multi-select-caret${dataCyAppendix}`}
        >
          {group.groupLabel && (
            <>
              <GroupCaret isOpen={isOpen} />
              <GroupLabel>{group.groupLabel}</GroupLabel>
            </>
          )}
        </GroupToggleVisibility>
        <ToggleGroupSelection
          onClick={() => handleGroupSelection(group)}
          data-cy={`field-multi-select-toggle${dataCyAppendix}`}
        >
          {isSelected
            ? intl.formatMessage({ id: 'Global.Fields.Select.DeselectAll' })
            : intl.formatMessage({ id: 'Global.Fields.Select.SelectAll' })}
        </ToggleGroupSelection>
      </GroupContainer>
      {isOpen && children}
    </>
  );
};

const GroupContainer = styled.div<{ theme?: Theme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 3px;
  :hover {
    background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  }
`;
const GroupToggleVisibility = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;
const GroupLabel = styled.div<{ theme?: Theme }>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  align: left;
  width: 115px;
`;
const ToggleGroupSelection = styled.div<{ theme?: Theme }>`
  padding-left: 5px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.alphaGray};
  width: 80px;
  text-align: right;
  user-select: none;
`;
