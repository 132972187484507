import styled from '@emotion/styled';

import { Icon } from 'components/shared/Buttons';
import { MEDIA_QUERY, Theme } from 'theme';

export const ModalFormButtonContainer = styled.div<{ theme?: Theme; isSkipAvailable: boolean }>`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  display: flex;
  flex-direction: row;
  justify-content: ${({ isSkipAvailable }) => (isSkipAvailable ? 'space-between' : 'flex-end')};
  margin-top: 20px;
  padding-top: 20px;
  ${({ isSkipAvailable }) =>
    isSkipAvailable
      ? `
      @media ${MEDIA_QUERY.MAX_SM} {
        & button {
          padding: 0 20px;
        font-size: 11px;
        }
      }`
      : ''}
`;

export const ModalFormContent = styled.div<{ theme?: Theme }>`
  background-color: ${({ theme }) => theme.colors.mineShaft};
  border-radius: 12px;
  max-width: 100%;
  margin: auto;
  padding: 24px 32px;
  width: 545px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${MEDIA_QUERY.MAX_SM} {
    margin-left: 2px;
  }
`;

export const SubmitButton = styled.div<{ isSkipAvailable?: boolean }>`
  margin-left: 10px;
  @media ${MEDIA_QUERY.MAX_SM} {
    margin-left: ${({ isSkipAvailable }) => (isSkipAvailable ? '2px' : '20px')};
`;

export const MessageInfo = styled.div<{ theme?: Theme }>`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const MessageIcon = styled(Icon)`
  margin-right: 10px;
`;

export const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RateInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const RateInfo = styled.div<{ theme?: Theme }>`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mineShaftLighter};
  font-size: 12px;
  text-align: center;
  @media ${MEDIA_QUERY.MAX_SM} {
    margin-top: 10px;
    text-align: start;
    margin-left: 0px;
  }
`;
