import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';

import addIcon from 'assets/images/icons/Add.svg';
import minusIcon from 'assets/images/icons/minus.svg';
import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { IconButton } from 'components/shared/Buttons';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { StyledLi, StyledUl } from 'components/shared/forms/Form/StyledForm';
import { Gallery } from 'components/shared/Gallery';
import { Price } from 'components/shared/Price';
import { formatDate } from 'helpers/dateTime/dateTime';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { PERMISSIONS, UNITS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { ItemAction, VehicleConverters, VehicleDetailsParams } from 'shared/types';
import { NonstandardConverterDetails } from 'shared/types/nonstandardConverterDetails';
import { theme } from 'theme';
import { VehicleConvertersList } from './StandardConvertersList';
import { HeaderWrapper, StyledDetails, StyledMessageWrapper } from './VehicleDetails.styles';
import { VehicleNonstandardConvertersList } from './VehicleNonstandardConvertersList';
import {
  messages,
  TypedFormattedMessage,
  useTypedIntl,
  VehiclesMessages,
} from '../locale/messages';
import { StyledIconTooltipWarning } from '../VehiclesList/VehicleList.styles';

type VehicleParams = {
  vehicleDetails: VehicleDetailsParams;
};

export const VehicleDetails = ({ vehicleDetails }: VehicleParams): React.ReactElement | null => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const currentUser = useCurrentUser();
  const [isDetailsScrollable, setIsDetailsScrollable] = useState(false);
  const [expanded, setExpanded] = useState<{ type: 'converter' | 'nonstandard'; index: number }[]>(
    [],
  );

  useEffect(() => {
    setExpanded([]);
  }, [vehicleDetails?.id]);

  const vehicleConverters = vehicleDetails?.converters as VehicleConverters[];
  const vehicleNonstandardConverters =
    vehicleDetails?.nonstandardConverters as NonstandardConverterDetails[];

  if (!vehicleDetails) return null;

  const fields =
    vehicleConverters &&
    vehicleConverters.flatMap(({ id, identifier, avgGroupPrice, converters }, index) => {
      const selected = expanded.some(el => el?.type === 'converter' && el?.index === index);
      return [
        {
          name: `header${index}`,
          label: '',
          value: (
            <HeaderWrapper
              onClick={() => {
                if (selected) {
                  setExpanded([
                    ...expanded.filter(el => el.index !== index || el.type !== 'converter'),
                  ]);
                } else {
                  setExpanded([...expanded, { type: 'converter', index }]);
                }
              }}
              data-cy="header-wrapper"
            >
              <IconButton
                icon={selected ? minusIcon : addIcon}
                iconColor={theme.colors.alto}
                size={selected ? '14px' : '28px'}
                data-cy={`vehicle-converter-expand-${id}`}
              />
              <StyledMessageWrapper>
                <TypedFormattedMessage
                  id="VehicleDetails.Value.Group.Title"
                  values={{ group: identifier }}
                />
              </StyledMessageWrapper>
              <Price
                value={avgGroupPrice}
                language={currentUser.language}
                currency={currentUser.currency}
              />
              {!converters.some(vc => vc.price.marketPrice !== null) && (
                <StyledIconTooltipWarning
                  tooltip={<TypedFormattedMessage id="VehicleDetails.IncompletePrice" />}
                />
              )}
            </HeaderWrapper>
          ),
        },
        {
          name: `table${index}`,
          label: '',
          value: <VehicleConvertersList data={converters} />,
          doNotRender: !selected,
        },
      ];
    });

  const nonstandardFields =
    vehicleNonstandardConverters &&
    vehicleNonstandardConverters.flatMap((section, index) => {
      const selected = expanded.some(el => el?.type === 'nonstandard' && el?.index === index);
      return [
        {
          name: `header${index}`,
          value: (
            <HeaderWrapper
              onClick={() => {
                if (selected) {
                  setExpanded([
                    ...expanded.filter(el => el.index !== index || el.type !== 'nonstandard'),
                  ]);
                } else {
                  setExpanded([...expanded, { type: 'nonstandard', index }]);
                }
              }}
              data-cy="header-wrapper"
            >
              <IconButton
                icon={selected ? minusIcon : addIcon}
                iconColor={theme.colors.alto}
                size={selected ? '14px' : '28px'}
                data-cy={`vehicle-nonstandard-converter-expand-${section.nonstandardConverterId}`}
              />
              <StyledMessageWrapper>
                <TypedFormattedMessage
                  id="VehicleDetails.Value.NonstandardGroup.Title"
                  values={{ group: section.material }}
                />
              </StyledMessageWrapper>
              <Price
                value={section.price}
                language={currentUser.language}
                currency={currentUser.currency}
              />
              {section.price === null && (
                <StyledIconTooltipWarning
                  tooltip={<TypedFormattedMessage id="VehicleDetails.IncompletePrice" />}
                />
              )}
            </HeaderWrapper>
          ),
        },
        {
          name: `table${index}`,
          value: <VehicleNonstandardConvertersList data={[section]} />,
          doNotRender: !selected,
        },
      ];
    });

  const handleUpdateView = item => {
    navigate(`/vehicles/list/update/${item.id}`);
  };

  const actions: ItemAction[] = [
    {
      id: 1,
      label: <TypedFormattedMessage id="Global.Update" />,
      onClick: () => handleUpdateView(vehicleDetails),
      visible: authorize(PERMISSIONS.VEHICLES.EDIT),
    },
  ];

  return (
    <AlphamartIntlProvider messages={messages}>
      <Details
        title={`${vehicleDetails.year} ${vehicleDetails.make} ${vehicleDetails.model}`}
        backUrl="/vehicles/list"
        scrollable={!isDetailsScrollable}
        actions={actions}
      >
        {vehicleDetails?.files && vehicleDetails.files.length > 0 && (
          <DetailsSection
            title={<TypedFormattedMessage id="VehicleDetails.VehicleDetails.Photos" />}
            fields={[]}
          >
            <Gallery
              images={vehicleDetails.files}
              onLightboxToggle={isOpened => {
                setIsDetailsScrollable(isOpened);
              }}
            />
          </DetailsSection>
        )}
        <DetailsSection
          title={<TypedFormattedMessage id="VehicleDetails.VehicleDetails.Title" />}
          fields={[
            authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && {
              name: 'vin',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.Vin" />,
              value: vehicleDetails.vin,
            },
            {
              name: 'year',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.Year" />,
              value: vehicleDetails.year,
            },
            {
              name: 'make',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.Make" />,
              value: vehicleDetails.make,
            },
            {
              name: 'model',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.Model" />,
              value: vehicleDetails.model,
            },
            {
              name: 'vehicleType',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.VehicleType" />,
              value: vehicleDetails.vehicleType
                ? intl.formatMessage({
                    id: `VehicleForm.VehicleType.${capitalize(
                      vehicleDetails.vehicleType,
                    )}` as keyof VehiclesMessages,
                  })
                : null,
            },
            {
              name: 'numberOfDoors',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.NumberOfDoors" />,
              value: vehicleDetails.numberOfDoors,
            },
            {
              name: 'weight',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.Weight" />,
              value: vehicleDetails.weight ? `${vehicleDetails.weight} ${UNITS.LBS}` : null,
            },
            authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && {
              name: 'note',
              label: <TypedFormattedMessage id="VehicleDetails.VehicleDetails.Note" />,
              value: vehicleDetails.notes,
            },
          ].filter(isTruthy)}
        />
        <DetailsSection
          title={<TypedFormattedMessage id="VehicleDetails.EngineDetails.Title" />}
          fields={[
            {
              name: 'engineType',
              label: <TypedFormattedMessage id="VehicleDetails.EngineDetails.EngineType" />,
              value: intl.formatMessage({
                id: `VehicleForm.EngineType.${capitalize(
                  vehicleDetails.engineType,
                )}` as keyof VehiclesMessages,
              }),
            },
            {
              name: 'engineDisplacement',
              label: <TypedFormattedMessage id="VehicleDetails.EngineDetails.EngineDisplacement" />,
              value: vehicleDetails.engineDisplacement
                ? `${vehicleDetails.engineDisplacement} ${UNITS.CC}`
                : null,
            },
            {
              name: 'transmissionType',
              label: <TypedFormattedMessage id="VehicleDetails.EngineDetails.TransmissionType" />,
              value: vehicleDetails.transmission
                ? intl.formatMessage({
                    id: `VehicleForm.TransmissionType.${capitalize(
                      vehicleDetails.transmission,
                    )}` as keyof VehiclesMessages,
                  })
                : null,
            },
            {
              name: 'enginePower',
              label: <TypedFormattedMessage id="VehicleDetails.EngineDetails.EnginePower" />,
              value: vehicleDetails.enginePower
                ? `${vehicleDetails.enginePower} ${UNITS.HP}`
                : null,
            },
          ]}
        />
        <DetailsSection
          title={<TypedFormattedMessage id="VehicleDetails.ConverterDetails.Title" />}
          template={[
            `numberOfConverters ${'averageConvertersValue averageConvertersValue'}`,
            authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) &&
              'relatedConvertersIds relatedConvertersIds relatedConvertersIds',
          ].filter(isTruthy)}
          fields={[
            {
              name: 'numberOfConverters',
              label: (
                <TypedFormattedMessage id="VehicleDetails.ConverterDetails.NumberOfConverters" />
              ),
              value: vehicleDetails.numberOfConverters,
            },
            {
              name: 'averageConvertersValue',
              label: (
                <TypedFormattedMessage id="VehicleDetails.ConverterDetails.AverageConvertersValue" />
              ),

              value: (
                <Price
                  value={vehicleDetails.avgConvertersPrice}
                  language={currentUser.language}
                  currency={currentUser.currency}
                />
              ),
            },
            authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && {
              name: 'relatedConvertersIds',
              label: (
                <TypedFormattedMessage id="VehicleDetails.ConverterDetails.RelatedConverters" />
              ),
              value: (
                <StyledUl>
                  {vehicleDetails.converters?.map(c => (
                    <StyledLi key={c.identifier}>{c.identifier}</StyledLi>
                  ))}
                  {vehicleDetails.nonstandardConverters?.map(c => (
                    <StyledLi key={c.material}>{c.material}</StyledLi>
                  ))}
                </StyledUl>
              ),
            },
          ].filter(isTruthy)}
        />

        {authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && (
          <StyledDetails>
            <DetailsSection
              cols={4}
              title={<TypedFormattedMessage id="VehicleDetails.Converters.Group.Title" />}
              template={vehicleConverters
                .map((_, index) => [
                  `header${index} header${index} header${index} header${index}`,
                  `table${index} table${index} table${index} table${index}`,
                ])
                .flat()}
              fields={fields}
            />
          </StyledDetails>
        )}

        {authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && !!vehicleNonstandardConverters.length && (
          <StyledDetails>
            <DetailsSection
              cols={4}
              template={vehicleNonstandardConverters
                .map((_, index) => [
                  `header${index} header${index} header${index} header${index}`,
                  `table${index} table${index} table${index} table${index}`,
                ])
                .flat()}
              fields={nonstandardFields}
            />
          </StyledDetails>
        )}

        {authorize(PERMISSIONS.VEHICLES.FULL_DETAILS) && (
          <DetailsFooter
            fields={[
              {
                name: 'createdBy',
                label: intl.formatMessage({ id: 'Global.CreatedBy' }),
                value:
                  vehicleDetails.createdBy &&
                  `${vehicleDetails.createdBy.firstName} ${vehicleDetails.createdBy.lastName}`,
              },
              {
                name: 'createdAt',
                label: intl.formatMessage({ id: 'Global.CreatedOn' }),
                value: formatDate(vehicleDetails.createdAt),
              },
              {
                name: 'updatedBy',
                label: intl.formatMessage({ id: 'Global.UpdatedBy' }),
                value:
                  vehicleDetails.updatedBy &&
                  `${vehicleDetails.updatedBy.firstName} ${vehicleDetails.updatedBy.lastName}`,
              },
              {
                name: 'updatedAt',
                label: intl.formatMessage({ id: 'Global.UpdatedOn' }),
                value: vehicleDetails.updatedAt && formatDate(vehicleDetails.updatedAt),
              },
            ]}
          />
        )}
      </Details>
    </AlphamartIntlProvider>
  );
};
