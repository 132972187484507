import { createSlice } from '@reduxjs/toolkit';

import { AssayFormShape } from 'components/views/Assays/CreateAssay/CreateAssay';
import { apiHostname } from 'shared/constants';
import { serializeLocationHeader } from 'shared/helpers';
import { parseAssay } from 'shared/parsers/parseAssay';
import { AlphamartHttpError, Coords } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

const updateAssaySlice = createSlice({
  name: 'updateAssay',
  initialState: {
    isPending: false,
    assay: null,
  },
  reducers: {
    updateAssayAction: state => ({
      ...state,
      isPending: true,
    }),
    updateAssaySuccessAction: (state, { payload }) => ({
      ...state,
      isPending: false,
      assay: payload,
    }),
    updateAssayFailureAction: (state, { payload }) => ({
      ...state,
      isPending: false,
      error: payload,
    }),
  },
});

export const { updateAssayAction, updateAssaySuccessAction, updateAssayFailureAction } =
  updateAssaySlice.actions;

export const updateAssay =
  (
    values: AssayFormShape,
    id: number,
    converterId: number,
    position: Coords | null = null,
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(updateAssayAction());

      const headers = position ? { 'user-location': serializeLocationHeader(position) } : undefined;
      const files = await Promise.all(
        values.converter.photos.map(photo => {
          if (photo.id) {
            return photo;
          }

          const formData = new FormData();
          formData.append('image', photo.file!);
          return httpClient.post<string>(`${apiHostname}/api/upload/image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );

      const assayDto = parseAssay({ ...values, id, converterId }, files, true);
      const assay = await httpClient.put(`${apiHostname}/api/assays/${id}`, assayDto, { headers });
      dispatch(updateAssaySuccessAction({ assay }));
    } catch (error) {
      dispatch(updateAssayFailureAction((error as AlphamartHttpError)?.response?.data?.message));
      return Promise.reject(error);
    }
  };

export default updateAssaySlice.reducer;
