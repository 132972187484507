import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import addIcon from 'assets/images/icons/Add.svg';
import removeIcon from 'assets/images/icons/Remove.svg';
import { IconButton } from 'components/shared/Buttons';
import { Theme } from 'theme';
import { FieldInputRaw, Props as FieldInputProps } from '../FieldInput/FieldInput';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

interface Props extends FieldInputProps {
  min?: number;
  max?: number;
}

const StyledIconButton = styled(IconButton)<{ theme?: Theme }>`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  z-index: 1;
`;

const fieldInputStyles = css`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  & > span {
    width: auto !important;
    border: none !important;
    background: none !important;
    padding-left: 10px;
    padding-right: 15px;
    gap: 0 10px;
  }
`;

const ButtonContainer = ({
  inputRef,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
}): React.ReactElement => {
  // required because stepUp/stepDown doesn't trigger onChange event
  const triggerEvent = () => {
    const ev = new Event('change', { bubbles: true });
    inputRef.current?.dispatchEvent(ev);
  };
  const increment = () => {
    inputRef.current?.stepUp();
    triggerEvent();
  };
  const decrement = () => {
    inputRef.current?.stepDown();
    triggerEvent();
  };

  return (
    <>
      <StyledIconButton
        icon={removeIcon}
        btnSize={24}
        onClick={decrement}
        data-cy="field-number-decrement"
      />
      <StyledIconButton
        icon={addIcon}
        btnSize={24}
        onClick={increment}
        data-cy="field-number-increment"
      />
    </>
  );
};

const FieldNumberLayout = (props: Props): React.ReactElement => {
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <FieldInputRaw
      type="number"
      suffix={<ButtonContainer inputRef={ref} />}
      rootClassName={fieldInputStyles}
      ref={ref}
      {...props}
    />
  );
};

const FieldNumber = withFieldWrapper<Props>(props => <FieldNumberLayout {...props} />);

export { FieldNumber };
