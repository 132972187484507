import { VehicleFormShape } from 'components/views/Vehicles/VehicleForm';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { apiHostname } from 'shared/constants';
import { ExternalPhoto, parseVehicle } from 'shared/parsers/parseVehicle';
import { GenericThunk } from './shared/createGenericStoreSlice';

export const updateVehicle =
  (values: VehicleFormShape, id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const fileIds = await Promise.all(
        values.photos.map(photo => {
          if ('url' in photo && !('file' in photo)) {
            return httpClient.post<ExternalPhoto>(
              `${apiHostname}/api/upload/vehicle-image/external`,
              {
                url: photo.url,
              },
            );
          }

          if (photo.id) {
            return photo;
          }

          if (!photo.file) return;
          const formData = new FormData();
          formData.append('image', photo.file);
          return httpClient.post<string>(`${apiHostname}/api/upload/vehicle-image`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );
      const vehicleDto = parseVehicle(values, fileIds.filter(isTruthy));
      await httpClient.put(`${apiHostname}/api/vehicles/${id}`, vehicleDto);
    } catch (error) {
      return Promise.reject(error);
    }
  };
