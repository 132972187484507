import { createSlice } from '@reduxjs/toolkit';
import * as fns from 'date-fns';

import { apiHostname, DashboardFiltersKeys, granulationLevels } from 'shared/constants';
import { AlphamartHttpError, MetalPrice } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface MetalPriceState extends GenericStoreSlice {
  metalPrices: {
    list: MetalPrice[];
    today: Partial<MetalPrice>;
  };
}

const metalPricesSlice = createSlice<MetalPriceState, GenericStoreReducer<MetalPriceState>>({
  name: 'metalPrices',
  initialState: {
    metalPrices: {
      list: [],
      today: {},
    },
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      metalPrices: payload,
    })),
  },
});

export const {
  pending: fetchMetalPricesAction,
  success: fetchMetalPricesSuccessAction,
  failure: fetchMetalPricesFailureAction,
} = metalPricesSlice.actions;

export const fetchMetalPrices =
  (from: DashboardFiltersKeys): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchMetalPricesAction());
      const granulationLevel = granulationLevels[from];
      const [timespan, qtt] = from.split('-');

      const metalPrices = await httpClient.get(`${apiHostname}/api/metal-prices`, {
        params: {
          from: fns.setMilliseconds(fns[`sub${timespan}`](new Date(), qtt), 0),
          granulationLevel,
        },
      });

      dispatch(fetchMetalPricesSuccessAction(metalPrices.data));
    } catch (error) {
      dispatch(
        fetchMetalPricesFailureAction((error as AlphamartHttpError)?.response?.data.message),
      );
      return Promise.reject(error);
    }
  };

export default metalPricesSlice.reducer;
