import React, { useEffect } from 'react';
import { FieldArray, useField } from 'formik';

import { useExtendedTheme } from 'shared/hooks';
import { FieldMultiTextButtonStyle } from './FieldMultiText.style';
import { FieldInputRaw } from '../FieldInput/FieldInput';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

interface Props {
  name: string;
  value: {
    value: string;
  }[];
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  onTouch?: (name: string) => void;
  addLabel?: React.ReactNode;
  error?: unknown;
  maxLength?: number;
  maxItems?: number;
  minItems?: number;
  prefix?: string;
  capitalize?: boolean;
}

const FieldMultiTextLayout = ({
  maxLength,
  value,
  onChange,
  onFocus,
  onBlur,
  onTouch,
  name,
  addLabel,
  error,
  prefix,
  maxItems = 10,
  minItems = 0,
  capitalize = false,
}: Props): React.ReactElement => {
  const theme = useExtendedTheme();
  const [, , { setTouched }] = useField(name);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onItemChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    setTouched(true);
  };
  const filteredValues = value.slice(0, maxItems);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [filteredValues.length]);

  return (
    <FieldArray name={name}>
      {arrayHelpers => (
        <div>
          {filteredValues.map((fieldKey, index) => (
            <FieldInputRaw
              onChange={onItemChange}
              onBlur={onBlur}
              onFocus={onFocus}
              name={`${name}.${index}.value`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={filteredValues[index].value}
              onRemove={
                filteredValues.length > minItems
                  ? () => {
                      onTouch?.(name);
                      setTouched(true);
                      return arrayHelpers.remove(index);
                    }
                  : undefined
              }
              error={error}
              maxLength={maxLength}
              prefix={prefix}
              capitalize={capitalize}
              withButton
              ref={inputRef}
            />
          ))}
          {filteredValues.length < maxItems && (
            <button
              type="button"
              className={FieldMultiTextButtonStyle(theme)}
              onClick={() => {
                arrayHelpers.push({ value: '' });
              }}
              onFocus={onFocus}
            >
              {addLabel}
            </button>
          )}
        </div>
      )}
    </FieldArray>
  );
};

const FieldMultiText = withFieldWrapper<Props>(props => <FieldMultiTextLayout {...props} />);

export { FieldMultiText };
