import { useCallback } from 'react';

import { HedgeMessages, useTypedIntl } from 'components/views/Hedges/locale/messages';
import { ErrorCode, ModalFormType } from 'shared/constants';
import { AlphamartErrorData, Hedge } from 'shared/types';
import { showModalForm } from 'store/modalFormSlice';
import { removeHedge } from 'store/removeHedgeSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { unassignHedge } from 'store/unassignHedgeSlice';
import { useAlphamartNavigate } from './useAlphamartRouter';

interface UseHedgeEditActions {
  actionsPending: boolean;
  handleRemove: (hedge: Pick<Hedge, 'id'>) => Promise<void>;
  handleUnassign: (hedge: Pick<Hedge, 'id'>) => Promise<void>;
  handleUpdate: (hedge: Pick<Hedge, 'id'>) => void;
  handleAssign: (hedge: Hedge, onSuccess: () => void) => void;
}

export const useHedgeEditActions = (): UseHedgeEditActions => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const removeHedgeIsPending = useAppSelector(state => state.removeHedge.isPending);
  const unassignHedgeIsPending = useAppSelector(state => state.unassignHedge.isPending);
  const actionsPending = !!(removeHedgeIsPending || unassignHedgeIsPending);

  const handleUpdate = (hedge: Pick<Hedge, 'id'>) => navigate(`/hedges/${hedge.id}/edit`);

  const handleRemove = useCallback(
    async (hedge: Pick<Hedge, 'id'>) => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'Modal.Confirm' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());

            try {
              await dispatch(removeHedge(hedge.id));
              dispatch(
                snackBarPushSuccess(
                  intl.formatMessage({ id: 'Global.Remove.Success' }, { record: 'Hedge' }),
                ),
              );
              return navigate('/hedges');
            } catch (err) {
              const error = err as AlphamartErrorData;
              const messageId = [ErrorCode.HEDGE_IS_ALREADY_ASSIGNED].includes(error?.errorCode)
                ? `Global.Error.${error.errorCode}`
                : 'Global.Error.SomethingWentWrong';
              dispatch(
                snackBarPushFailure(
                  intl.formatMessage({ id: messageId as keyof HedgeMessages }, error?.errorData),
                ),
              );
            }
          },
        }),
      );
    },
    [dispatch, intl],
  );

  const handleUnassign = useCallback(
    async (hedge: Pick<Hedge, 'id'>) => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'Modal.Unassign.Confirm' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());

            try {
              await dispatch(unassignHedge(hedge.id));
              dispatch(
                snackBarPushSuccess(
                  intl.formatMessage({ id: 'Global.Unassign.Success' }, { record: 'Hedge' }),
                ),
              );
              return navigate('/hedges');
            } catch {
              dispatch(
                snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
              );
            }
          },
        }),
      );
    },
    [dispatch],
  );

  const handleAssign = (hedge: Hedge, onSuccess: () => void) => {
    dispatch(showModalForm({ modalType: ModalFormType.AssignHedge, params: { hedge, onSuccess } }));
  };

  return {
    actionsPending,
    handleRemove,
    handleUnassign,
    handleUpdate,
    handleAssign,
  };
};
