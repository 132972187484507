import { chain } from 'lodash';

import {
  ConverterListItem,
  MaterialUnit,
  NonstandardConverter,
  VehicleListItem,
} from 'shared/types';
import { MAIN_COLOR_PALLETE } from 'theme';

export interface ConverterToRender {
  id: number | string;
  averagePrice?: number | null;
  identifier: string;
  color: string;
  converters: Partial<ConverterListItem>[];
  hasIncompletePrice: boolean;
}

export interface NonstandardConverterToRender {
  id?: number | string;
  price?: number;
  nonstandardConverterId?: number;
  material?: string;
  materialWeight?: number;
  materialUnit?: MaterialUnit;
}

export type VehicleListItemToRender = Omit<VehicleListItem, 'vehicleConverters'> & {
  vehicleConverters: ConverterToRender[];
  nonstandardVehicleConverters?: NonstandardConverter[];
  hasIncompletePrice: boolean;
  groupItem: boolean;
};

export const getVehiclesListWithUniqueConverters = (
  vehicles: VehicleListItem[],
): VehicleListItemToRender[] =>
  vehicles.map(v => {
    let i = 0;
    return {
      ...v,
      groupItem: true,
      hasIncompletePrice: v.vehicleConverters.some(
        vc => !vc.converters || !vc.converters.filter(c => c.price?.marketPrice !== null).length,
      ),
      vehicleConverters: chain(
        v.vehicleConverters?.map<ConverterToRender>(vc => {
          let averagePrice: number | null = 0;
          const nonNullConverters = vc.converters.filter(c => c.price?.marketPrice !== null).length;

          if (vc.converters.every(converter => converter.price?.marketPrice === null)) {
            averagePrice = null;
          } else if (nonNullConverters > 0) {
            averagePrice = vc.converters.length
              ? vc.converters.reduce((a, b) => a + (b.price?.marketPrice as number), 0) /
                nonNullConverters
              : null;
          }

          const converters = vc.converters.map(converter => ({
            color: MAIN_COLOR_PALLETE[i % MAIN_COLOR_PALLETE.length]?.value,
            ...converter,
          }));

          const hasIncompletePrice =
            !vc.converters || !converters.filter(c => c.price?.marketPrice !== null).length;

          const result = {
            id: vc.id,
            identifier: vc.identifier,
            averagePrice,
            color: MAIN_COLOR_PALLETE[i % MAIN_COLOR_PALLETE.length]?.value,
            converters,
            hasSample: Boolean(vc.converters.length),
            hasIncompletePrice,
          };
          i++;
          return result;
        }),
      )
        .uniqBy('identifier')
        .orderBy('identifier')
        .value(),
      nonstandardVehicleConverters: chain(v.nonstandardVehicleConverters)
        .uniqBy('material')
        .orderBy('material')
        .map(nsc => {
          const result = {
            ...nsc,
            color: MAIN_COLOR_PALLETE[i % MAIN_COLOR_PALLETE.length]?.value,
          };
          i++;
          return result;
        })
        .value(),
    };
  });
