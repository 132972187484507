import { getHiddenOnMediaQuery, getMediaQueries } from './shared/utils/getMediaQueries';

export const BREAKPOINTS = {
  SM: 480,
  MD: 768,
  LG: 1024,
  XL: 1280,
  XXL: 1536,
} as const;

const MEDIA_QUERY = {
  ...getMediaQueries(BREAKPOINTS),
  MOBILE_LANDSCAPE: '(pointer: coarse) and (orientation: landscape)',
};

const HIDDEN_ON_MEDIA_QUERY = getHiddenOnMediaQuery(MEDIA_QUERY);

export const NAVBAR_HEIGHT_MOBILE = '64px';
const theme = {
  background: '#151515',
  fontColor: '#FFFFFF',
  mainColor: '#EF5E26',
  colors: {
    black: '#000000',
    codGrayDarker: '#151515',
    codGray: '#1E1E1E',
    alto: '#CECECE',
    mineShaftDarker: '#222222',
    mineShaft: '#232323',
    mineShaftLighter: '#2A2A2A',
    mineShaftLightest: '#3A3A3A',
    charcoal: '#434343',
    emperor: '#525252',
    emerald: '#53BE72',
    zambezi: '#5D5D5D',
    doveGray: '#696969',
    lighterGray: '#414141',
    lightGray: '#484848',
    darkGray: '#1C1C1C',
    shingleFawn: '#764B39',
    alphaGray: '#8E8D8D',
    sliver: '#C1C1C1',
    greySnow: '#C8C8C8',
    altoLighter: '#D8D8D8',
    fuzzyWuzzyBrown: '#BE5353',
    flamingo: '#EF5E26',
    alphaOrange: '#D75422',
    whisper: '#E5E5E5',
    blazeOrange: '#FF6700',
    white: '#FFFFFF',
    gold: '#DEBF35',
    pictonBlue: '#289CEA',
  },
  fonts: {
    lato: 'Lato, sans-serif',
    montserrat: 'Montserrat, sans-serif',
  },
  modal: {
    background: '#1E1E1E',
  },
  nav: {
    wide: '228px',
    thin: '97px',
  },
};
export type Theme = typeof theme;

const layers = {
  carousel: 0,
  datePicker: 1,
  fieldInput: 1,
  formContainer: 1,
  galleryClose: 1,
  detailsHeader: 1,
  colorPickerPalette: 2,
  fieldInputRemove: 2,
  fieldInputAction: 2,
  disclaimer: 2,
  fieldInputError: 3,
  listCheckboxes: 3,
  datePickerPopper: 4,
  averageBox: 4,
  loaderOverlay: 4,
  selectMenu: 4,
  sidebar: 5,
  details: 6,
  navListLeaf: 6,
  notificationModal: 7, // has to be more than details for mobile views
  lightbox: 7,
  userProfile: 13,
  mobileNavbar: 14,
  loaderOverlayModal: 14,
  modal: 15,
  twoFactorAuthenticationModal: 16,
  loaderOverlayFull: 16,
  floatingButton: 50,
  snackbar: 99,
  menu: 100,
  guide: 101,
  termsAndConditionsModal: 9999,
};

const MAIN_COLOR_PALLETE = [
  {
    value: theme.mainColor,
    label: 'Global.Color.Orange',
  },
  {
    value: '#909BE2',
    label: 'Global.Color.Purple',
  },
  {
    value: '#87F88C',
    label: 'Global.Color.Aquamarine',
  },
  {
    value: '#C5C5C5',
    label: 'Global.Color.Gray',
  },
  {
    value: '#F88787',
    label: 'Global.Color.Pink',
  },
  {
    value: '#00FFF7',
    label: 'Global.Color.Turquoise',
  },
  {
    value: '#FF9A00',
    label: 'Global.Color.Gold',
  },
  {
    value: '#289CEA',
    label: 'Global.Color.Blue',
  },
];

const NAVIGATION_BAR_COLOR_PALLETE = [
  {
    value: '#1A235C',
    label: 'Global.Color.NavyBlue',
  },
  {
    value: '#1A5C38',
    label: 'Global.Color.Green',
  },
  {
    value: '#859622',
    label: 'Global.Color.Wasabi',
  },
  {
    value: '#17827A',
    label: 'Global.Color.Turquoise',
  },
  {
    value: '#5C1A37',
    label: 'Global.Color.Burgundy',
  },
  {
    value: '#1A465C',
    label: 'Global.Color.Blue',
  },
  {
    value: '#000000',
    label: 'Global.Color.Black',
  },
  {
    value: '#222222',
    label: 'Global.Color.Gray',
  },
];

const scrollbarStyles = {
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.colors.alphaGray}`,

  '::-webkit-scrollbar-track': {
    borderRadius: '12px',
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: '12px',
    boxShadow: 'inset 0 0 16px rgba(0,0,0,.4)',
    backgroundColor: theme.colors.alphaGray,

    ':hover': {
      backgroundColor: `${theme.colors.alphaGray}99`,
    },
  },

  '::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
};

const globalStyles = (currentTheme: Theme) => ({
  '*': {
    boxSizing: 'border-box',

    ...scrollbarStyles,
  },
  html: {
    overscrollBehaviorX: 'none',
    WebkitOverflowScrolling: 'touch !important',
  },
  body: {
    margin: '0',
    background: currentTheme.background,
    color: currentTheme.fontColor,
    fontFamily: currentTheme.fonts.montserrat,
    overscrollBehaviorX: 'none',
    WebkitOverflowScrolling: 'touch !important',
    WebkitTapHighlightColor: 'transparent',
  },
  a: {
    color: currentTheme.mainColor,
    textDecoration: 'none',
  },
});

export {
  globalStyles,
  HIDDEN_ON_MEDIA_QUERY,
  layers,
  MAIN_COLOR_PALLETE,
  MEDIA_QUERY,
  NAVIGATION_BAR_COLOR_PALLETE,
  theme,
};
