import { useCallback } from 'react';

import { useTypedIntl } from 'components/views/Companies/locale/messages';
import { ModalFormType } from 'shared/constants';
import { Company, Status } from 'shared/types';
import { changeCompanyStatus } from 'store/changeCompanyStatusSlice';
import {
  DownloadCompanyLocationsForm,
  DownloadCompanyLogsExcelForm,
  showModalForm,
  ThresholdsModalForm,
} from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { useAlphamartNavigate } from './useAlphamartRouter';

interface UseCompanyActions {
  actionsPending: boolean;
  handleUpdate: (company: Pick<Company, 'id'>) => void;
  handleActivate: (company: Pick<Company, 'id'>) => void;
  handleInactivate: (company: Pick<Company, 'id'>) => void;

  handleLocationsModalOpen: (params: DownloadCompanyLocationsForm) => unknown;
  handleHedgeLogsModalOpen: (params: DownloadCompanyLogsExcelForm) => unknown;
  handleConverterLogsModalOpen: (params: DownloadCompanyLogsExcelForm) => unknown;
  handleThresholdsModalOpen: (params: ThresholdsModalForm) => unknown;
}

export const useCompanyActions = (): UseCompanyActions => {
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const isChangeStatusPending = useAppSelector(state => state.changeCompanyStatus.isPending);
  const isFileDownloading = useAppSelector(state => state.downloadFile.isPending);
  const actionsPending = !!(isChangeStatusPending || isFileDownloading);

  const handleLocationsModalOpen = (params: DownloadCompanyLocationsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.CompanyLocations, params }));

  const handleHedgeLogsModalOpen = (params: DownloadCompanyLogsExcelForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.HedgeLogs, params }));

  const handleConverterLogsModalOpen = (params: DownloadCompanyLogsExcelForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.ConverterLogs, params }));

  const handleThresholdsModalOpen = (params: ThresholdsModalForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UsageLimit, params }));

  const handleUpdate = (company: Pick<Company, 'id'>) => navigate(`/companies/${company.id}/edit`);

  const handleActivate = useCallback(
    (company: Pick<Company, 'id'>) => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'Global.CompanyDetails.Reactivate.Question' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            try {
              await dispatch(changeCompanyStatus(company.id, Status.ACTIVE));
              dispatch(
                snackBarPushSuccess(
                  intl.formatMessage({ id: 'Global.Reactivate.Success' }, { record: 'Company' }),
                ),
              );
            } catch {
              dispatch(
                snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
              );
            }
            dispatch(hideModal());
          },
        }),
      );
    },
    [dispatch],
  );

  const handleInactivate = useCallback(
    (company: Pick<Company, 'id'>) => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'Global.CompanyDetails.Inactivate.Question' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            try {
              await dispatch(changeCompanyStatus(company.id, Status.INACTIVE));
              dispatch(
                snackBarPushSuccess(
                  intl.formatMessage({ id: 'Global.Inactivate.Success' }, { record: 'Company' }),
                ),
              );
            } catch {
              dispatch(
                snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
              );
            }
            dispatch(hideModal());
          },
        }),
      );
    },
    [dispatch],
  );

  return {
    actionsPending,
    handleUpdate,
    handleActivate,
    handleInactivate,

    handleLocationsModalOpen,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
    handleThresholdsModalOpen,
  };
};
