import { css, keyframes } from '@emotion/css';

import { layers, MEDIA_QUERY, Theme } from 'theme';

export const SNACKBAR_ANIMATION_TIME = 300; // miliseconds

export const snackBarStyles = css`
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${layers.snackbar};

  @media ${MEDIA_QUERY.MAX_MD} {
    padding: 0 10px;
    min-width: 250px;
  }
`;

type MessageType = 'success' | 'failure';

export const snackBarDefaultMessageStyles = (type: MessageType, theme: Theme): string => css`
  border-radius: 8px;
  background-color: ${type === 'success' ? theme.colors.emerald : theme.colors.fuzzyWuzzyBrown};
  color: ${theme.colors.white};
  padding: 24px 64px 24px 32px;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: ${theme.fonts.montserrat};
  min-width: calc(32px * 12);
  width: 400px;
  position: relative;
  display: none;
  word-break: break-word;

  @media ${MEDIA_QUERY.MAX_SM} {
    min-width: inherit;
    width: 100%;
  }
`;

export const snackBarMessageShowAnimationStyles = (
  type: MessageType,
  messageHeight: number,
  theme: Theme,
): string => css`
  ${snackBarDefaultMessageStyles(type, theme)}
  display: block;
  animation: ${keyframes`
    from {
      margin: 16px 0 -${messageHeight}px 0;
      opacity: 0;
    }
    to {
      margin: 16px 0 0 0;
      opacity: 1;
    }
  `} ${SNACKBAR_ANIMATION_TIME}ms ease forwards;
`;

export const snackBarMessageHideAnimationStyles = (
  type: MessageType,
  messageHeight: number,
  theme: Theme,
): string => css`
  ${snackBarDefaultMessageStyles(type, theme)}
  display: block;
  animation: ${keyframes`
    from {
      margin: 16px 0 0 0;
      opacity: 1;
    }
    to {
      margin: 0px 0 -${messageHeight}px 0;
      opacity: 0;
    }
  `} ${SNACKBAR_ANIMATION_TIME}ms ease forwards;
`;

export const snackBarMessageButtonStyles = css`
  position: absolute;
  right: 16px;
  top: 24px;
  height: 32px;
  background: none;
  width: 32px;
  padding: 0;
  border: none;
  margin: -8px 0 0 0;
  cursor: pointer;
  outline: none;
`;

export const snackBarMessageButtonIconStyles = css`
  height: 32px;
  width: 32px;
  display: block;
`;
