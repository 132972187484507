import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import iconCircle from 'assets/images/icons/circle.svg';
import iconCross from 'assets/images/icons/Close.svg';
import iconConfirm from 'assets/images/icons/confirm.svg';
import iconCursor from 'assets/images/icons/cursor.svg';
import iconHand from 'assets/images/icons/hand.svg';
import iconSquare from 'assets/images/icons/square.svg';
import { CancelButton, Icon } from 'components/shared/Buttons';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { LoadableContent } from 'components/shared/Loader';
import {
  calculatePathAndArea,
  getRectanglePath,
} from 'components/shared/MapComponent/CustomMapComponents';
import { DrawingModes, DrawingTools } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { parsePolygonPath } from 'shared/parsers/parsePolygonPath';
import { ShapeParam } from 'store/locationsSlice';
import { MapShape, setLastShape, setMapDrawing, setShouldErase } from 'store/mapDrawingSlice';
import { fetchOffZones } from 'store/offZonesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateOffZones } from 'store/updateOffZonesSlice';
import { Theme } from 'theme';
import { Controls, drawingPanelStyles, eraseBtnStyles } from './DrawingPanel.styles';
import { TypedFormattedMessage as FormattedMessage } from '../../locale/messages';
import { MapFiltersShape } from '../MapFilters';

interface Props {
  className?: string;
  getMarkers: (shape: ShapeParam, filters?: MapFiltersShape) => void;
}
const DrawingPanelLayout = ({ className, getMarkers }: Props): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const {
    drawingMode: isVisible,
    currentShape,
    lastShape,
    map,
    drawingTool,
    eraseOnClick,
    offZones,
  } = useAppSelector(state => state.mapDrawing);
  const [isSavePending, setIsSavePending] = useState(false);
  const isOffZonesView = isVisible === DrawingModes.OFF_ZONES;
  const handleClose = () =>
    dispatch(setMapDrawing({ drawingTool: null, drawingMode: null, eraseOnClick: false }));

  const handleCancel = useCallback(() => {
    if (currentShape) (currentShape as MapShape).overlay.setMap(null);
    if (!lastShape) {
      dispatch(setMapDrawing({ area: 0, savedPath: null }));
      getMarkers({ path: getRectanglePath(map!.getBounds()!) });
    } else {
      (lastShape as MapShape).overlay.setMap(map);

      const { path, area } = calculatePathAndArea(lastShape);

      getMarkers(path as ShapeParam);
      dispatch(
        setMapDrawing({
          currentShape: lastShape,
          savedPath: path as ShapeParam,
          area: Math.round(area * 10) / 10,
        }),
      );
    }

    if (isOffZonesView) dispatch(fetchOffZones());

    handleClose();
  }, [lastShape, currentShape, map, isOffZonesView]);

  const handleSave = useCallback(async () => {
    if (isOffZonesView) {
      setIsSavePending(true);
      const zones = [
        ...offZones[DrawingTools.CIRCLE]!.map(({ radius, center }) => ({
          radius,
          // @ts-ignore lat() is callable
          centerLatitude: center.lat(),
          // @ts-ignore lng() is callable
          centerLongitude: center.lng(),
          company: currentUser.company.id,
        })),
        ...offZones[DrawingTools.POLYGON]!.map(shape =>
          parsePolygonPath(
            shape.id,
            shape.getPath ? shape.getPath().getArray() : shape.path,
            currentUser.company.id,
          ),
        ),
        ...offZones[DrawingTools.RECTANGLE]!.map(shape =>
          parsePolygonPath(null, getRectanglePath(shape.getBounds()!), currentUser.company.id),
        ),
      ];
      try {
        await dispatch(updateOffZones(zones));
        dispatch(snackBarPushSuccess(<FormattedMessage id="Global.OffZones.Updated" />));
      } catch {
        dispatch(snackBarPushFailure(<FormattedMessage id="Global.Error.SomethingWentWrong" />));
      }
      setIsSavePending(false);
    } else if (currentShape) dispatch(setLastShape(currentShape));

    handleClose();
  }, [currentShape, dispatch, offZones]);

  const handleErase = () =>
    dispatch(
      isOffZonesView
        ? setMapDrawing({ eraseOnClick: !eraseOnClick, drawingTool: null })
        : setShouldErase(true),
    );

  const handleDrawingModeChange = event => {
    const val = event.target.value === DrawingTools.NAVIGATE ? null : event.target.value;

    return dispatch(setMapDrawing({ drawingTool: val, eraseOnClick: false }));
  };

  if (!isVisible) return null;

  const drawingTools = [
    {
      value: DrawingTools.POLYGON,
      label: <FormattedMessage id="Geolocation.Drawing.Polygon" />,
      icon: iconHand,
    },
    {
      value: DrawingTools.RECTANGLE,
      label: <FormattedMessage id="Geolocation.Drawing.Rectangle" />,
      icon: iconSquare,
    },
    {
      value: DrawingTools.CIRCLE,
      label: <FormattedMessage id="Geolocation.Drawing.Circle" />,
      icon: iconCircle,
    },
  ];

  return (
    <div className={className}>
      <LoadableContent loading={isSavePending} mode={LoadableContent.MODE.FULL} />
      <header>
        <FormattedMessage
          id={`Geolocation.Drawing.${isOffZonesView ? 'ManageOffZones' : 'SelectTerrain'}`}
        />
      </header>
      <div className="navigate-map">
        <input
          type="radio"
          id={DrawingTools.NAVIGATE}
          name="drawingMode"
          value={DrawingTools.NAVIGATE}
          onClick={() => dispatch(setMapDrawing({ eraseOnClick: false }))}
          onChange={handleDrawingModeChange}
          checked={drawingTool === null}
          data-cy="drawing-mode-radio-btn"
        />
        <label htmlFor={DrawingTools.NAVIGATE}>
          <Icon icon={iconCursor} size="19px" />
          <FormattedMessage id="Geolocation.Drawing.Navigate" />
        </label>
      </div>
      <FiltersSection
        sectionName="drawTool"
        label={<FormattedMessage id="Geolocation.Drawing.SelectDrawingTool" />}
      >
        {drawingTools.map(({ value, label, icon }) => (
          <div key={value}>
            <input
              type="radio"
              id={value}
              name="drawingMode"
              value={value}
              onChange={handleDrawingModeChange}
              checked={value === drawingTool}
              data-cy="drawing-mode-radio-btn"
            />
            <label htmlFor={value}>
              <Icon icon={icon} size="19px" />
              {label}
            </label>
          </div>
        ))}
      </FiltersSection>
      <FiltersSection
        sectionName="selectTerrain"
        label={<FormattedMessage id="Geolocation.Drawing.EraseTerrain" />}
      >
        <AppButton className={eraseBtnStyles(eraseOnClick)} onClick={handleErase}>
          <Icon icon={iconCross} />
          <FormattedMessage id="Geolocation.Drawing.Erase" />
        </AppButton>
      </FiltersSection>
      <Controls>
        <CancelButton onClick={handleCancel}>
          <FormattedMessage id="Global.Cancel" />
        </CancelButton>
        <AppButton onClick={handleSave}>
          <Icon icon={iconConfirm} />
          <FormattedMessage id="Global.Save" />
        </AppButton>
      </Controls>
    </div>
  );
};

const DrawingPanel = styled(DrawingPanelLayout)<{ theme?: Theme }>`
  ${({ theme }) => drawingPanelStyles(theme)};
`;

export { DrawingPanel };
