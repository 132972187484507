import React from 'react';
import styled from '@emotion/styled';

import { Theme } from 'theme';

const ExtraInformationStyle = styled.div<{ theme?: Theme; name: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
  padding: 9px 0;
`;

const StyledUl = styled.ul<{ theme?: Theme }>`
  list-style: none;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.lato};
`;

const StyledLi = styled.li<{ theme?: Theme; color?: string }>`
  display: inline-block;
  padding: 0 20px;
  color: ${({ theme, color }) => color ?? theme.colors.alphaGray};
  font-size: 12px;
  font-weight: bold;
`;

export const ExtraInformation = ({
  children,
  color,
  name,
}: {
  children: React.ReactNode;
  name: string;
  color?: string;
}): React.ReactElement => (
  <ExtraInformationStyle name={name}>
    <StyledUl>
      <StyledLi color={color}>{children}</StyledLi>
    </StyledUl>
  </ExtraInformationStyle>
);
