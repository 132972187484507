import React from 'react';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { ActionsButton } from 'components/shared/Buttons/ActionsButton';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { ItemAction } from 'shared/types';
import { ExchangeRates } from './ExchangeRates/ExchangeRates';
import { messages, TypedFormattedMessage as FormattedMessage } from './locale/messages';
import { MetalPrices } from './MetalPrices/MetalPrices';
import { headerActionsStyles, ListSections } from './Settings.styles';

const Settings = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const actions: ItemAction[] = [
    {
      id: 1,
      label: <FormattedMessage id="Settings.Display.Change" />,
      onClick: () => navigate('/company/settings/display'),
      dataCy: () => 'settings-update-display',
      visible: true,
    },
    {
      id: 2,
      label: <FormattedMessage id="Settings.MetalPrices.Update" />,
      onClick: () => navigate(`/company/settings/metals/edit`),
      dataCy: () => 'settings-update-feed-source',
      visible: true,
    },
  ];

  return (
    <AlphamartIntlProvider messages={messages}>
      <PanelTemplate>
        <PanelHeader title={<FormattedMessage id="Settings.Header.Title" />}>
          <ActionsButton actions={actions} className={headerActionsStyles} />
        </PanelHeader>
        <PanelContent>
          <PanelContentMain>
            <ListSections>
              <MetalPrices />
              <ExchangeRates />
            </ListSections>
          </PanelContentMain>
        </PanelContent>
      </PanelTemplate>
    </AlphamartIntlProvider>
  );
};

export { Settings };
