import { useEffect, useState } from 'react';

type Browsers = 'IE' | 'Edge' | 'Opera' | 'Chrome' | 'Firefox' | 'Safari' | 'Unknown' | '';

export function useUserBrowser(): Browsers {
  const [userBrowser, setUserBrowser] = useState<Browsers>('');

  useEffect(() => {
    let browser;

    // @ts-ignore The documentMode only works in Internet Explorer.
    if (navigator.userAgent.includes('MSIE') || !!document.documentMode === true) {
      browser = 'IE';
    } else if (navigator.appVersion.includes('Edge')) {
      browser = 'Edge';
    } else if (navigator.userAgent.toLowerCase().includes('opr')) {
      browser = 'Opera';
    } else if (navigator.userAgent.includes('Chrome')) {
      browser = 'Chrome';
    } else if (navigator.userAgent.includes('Firefox')) {
      browser = 'Firefox';
    } else if (navigator.userAgent.includes('Safari')) {
      browser = 'Safari';
    } else {
      browser = 'Unknown';
    }

    setUserBrowser(browser);
  }, []);

  return userBrowser;
}
