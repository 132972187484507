import React, { ReactNode } from 'react';

import { TypedFormattedMessage } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { PhotoShape } from './FieldPhotos';
import { FieldPhotosAddButtonStyle, FieldPhotosGridStyle } from './FieldPhotos.style';
import { PhotoPreview } from './PhotoPreview';
import { DraggableProvider } from '../../DraggableProvider/DraggableProvider';
import { LoadableContent, LoadableContentModes } from '../../Loader';
import { TypedFormattedMessage as FormattedMessage } from '../../TermsAndConditionsModal/locale/messages';
import { Tooltip } from '../../Tooltip/Tooltip';

interface PhotoPreviewListProps {
  photos: PhotoShape[];
  setArrayPhotos: (photos: PhotoShape[]) => void;
  onRemovePhoto: (photo?: PhotoShape) => void;
  onNewPhotos: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allowedExtensions: string[];
  addPhotoMessage?: ReactNode;
  fetchPhotoMessage?: ReactNode;
  contextPhotos: PhotoShape[];
  single?: boolean;
  sortable?: boolean;
  largePreview?: boolean;
  canFetchPhoto?: boolean;
  isFetchingPhoto?: boolean;
  externalPhotoAvailable?: boolean;
  onSortEnd?: (oldIndex: number, newIndex: number) => void;
  onFetchPhoto?: () => void;
}

export const PhotoPreviewList = ({
  photos,
  onRemovePhoto,
  onNewPhotos,
  contextPhotos,
  allowedExtensions,
  single,
  addPhotoMessage,
  fetchPhotoMessage,
  largePreview,
  setArrayPhotos,
  canFetchPhoto,
  isFetchingPhoto,
  externalPhotoAvailable,
  sortable,
  onSortEnd,
  onFetchPhoto = () => {},
}: PhotoPreviewListProps) => {
  const theme = useExtendedTheme();

  const filesFilter = (allowedExtensions || []).join(', ');
  setArrayPhotos(Array.from(new Set([...photos, ...contextPhotos])));

  return (
    <DraggableProvider>
      <div className={FieldPhotosGridStyle(single)}>
        {[...photos].map((photo, index) => (
          <PhotoPreview
            onSortEnd={onSortEnd}
            key={photo.id}
            onRemove={() => onRemovePhoto(photo)}
            photo={{ ...photo, index }}
            disabled={single || !sortable}
            allowedExtensions={allowedExtensions}
            largePreview={largePreview}
          />
        ))}
        {photos.length < (single ? 1 : 10) && (
          <button type="button" className={FieldPhotosAddButtonStyle(theme)}>
            {addPhotoMessage ?? <TypedFormattedMessage id="Global.Fields.Photos.AddPhoto" />}
            <input type="file" accept={filesFilter} onChange={onNewPhotos} multiple={!single} />
          </button>
        )}
        {canFetchPhoto && (
          <LoadableContent loading={isFetchingPhoto} mode={LoadableContentModes.INLINE}>
            <Tooltip
              placement="top"
              tooltip={<FormattedMessage id="Global.Fields.Photos.Error.PhotoNotAvailable" />}
              enabled={!externalPhotoAvailable}
              bgColor={theme.colors.fuzzyWuzzyBrown}
              trigger={['hover', 'click']}
            >
              <button
                type="button"
                disabled={!externalPhotoAvailable}
                className={FieldPhotosAddButtonStyle(theme, externalPhotoAvailable)}
                onClick={onFetchPhoto}
              >
                {fetchPhotoMessage ?? <TypedFormattedMessage id="Global.Fields.Photos.GetPhoto" />}
              </button>
            </Tooltip>
          </LoadableContent>
        )}
      </div>
    </DraggableProvider>
  );
};
