import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface NotificationCountState extends GenericStoreSlice {
  count: number;
}

const notificationCountSlice = createSlice<
  NotificationCountState,
  GenericStoreReducer<NotificationCountState>
>({
  name: 'notificationCount',
  initialState: {
    count: 0,
    error: undefined,
    isPending: true,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      count: payload,
    })),
  },
});

export const {
  pending: fetchNotificationCountPendingAction,
  success: fetchNotificationCountSuccessAction,
  failure: fetchNotificationCountFailureAction,
} = notificationCountSlice.actions;

export const fetchNotificationCount =
  (): GenericThunk => async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchNotificationCountPendingAction());
      const { data } = await httpClient.get<number>(`${apiHostname}/api/notifications/unread`);
      dispatch(fetchNotificationCountSuccessAction(data));
    } catch (error) {
      dispatch(
        fetchNotificationCountFailureAction((error as AlphamartHttpError)?.response?.data?.message),
      );
      return Promise.reject(error);
    }
  };

export const clearNotificationCount = (): ReturnType<typeof fetchNotificationCountSuccessAction> =>
  fetchNotificationCountSuccessAction(null);

export default notificationCountSlice.reducer;
