import React from 'react';
import styled from '@emotion/styled';

import { TypedFormattedMessage } from 'locale/messages';
import { Theme } from 'theme';
import AppButton from '../forms/AppButton/AppButton';

interface Props {
  resetZoom: () => void;
  isZoomedIn?: boolean;
  isMobileZoomStarted?: boolean;
}

const LegendContent = styled.div<{ theme?: Theme; isVisible?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.lato};
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  text-align: center;
  font-size: 13px;
  margin-left: 32px;
  height: 48px;

  button {
    position: relative;
    left: calc(var(--left-offset) * -1);
  }
`;
const MobileHint = styled.span<{ theme?: Theme }>`
  color: ${({ theme }) => theme.mainColor};
`;
export const ScatterChartLegend = ({
  resetZoom,
  isZoomedIn,
  isMobileZoomStarted,
}: Props): React.ReactElement => (
  <LegendContent isVisible={isMobileZoomStarted || isZoomedIn}>
    {isMobileZoomStarted && !isZoomedIn ? (
      <MobileHint>
        <TypedFormattedMessage id="Global.Charts.Scatter.MobileZoomActivated" />
      </MobileHint>
    ) : (
      <AppButton onClick={resetZoom}>
        <TypedFormattedMessage id="Global.Charts.Scatter.ResetZoom" />
      </AppButton>
    )}
  </LegendContent>
);
