import { useEffect, useRef } from 'react';

export function useEventListener<EventType>(
  eventName: string,
  handler: (event: EventType) => void,
  element = window,
): void {
  const savedHandler = useRef<(event: EventType) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = event => savedHandler.current?.(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
