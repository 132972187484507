import { isEqual, isObject, omit, transform } from 'lodash';

export type DiffResult<T> = {
  diff: Partial<T>;
  count: number;
};
function baseDiff<T>(a: T, b: T): T {
  return transform(a as unknown as Record<string, unknown>, (result, value, key) => {
    if (!isEqual(value, b[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] =
        isObject(value) && isObject(b[key])
          ? baseDiff(value as unknown as T, b[key] as unknown as T)
          : value;
    }
  });
}
export function diffObjects<T = Record<string, unknown>>(
  a: T,
  b: T,
  omitKeys: string[] & (keyof T)[] = [],
): DiffResult<T> {
  const aOmitted = omit(a as unknown as Record<string, unknown>, omitKeys);
  const bOmitted = omit(b as unknown as Record<string, unknown>, omitKeys);
  const diff = baseDiff(aOmitted, bOmitted);

  return {
    diff,
    count: Object.entries(diff).length,
  };
}
