import { apiHostname } from 'shared/constants';
import { GenericThunk } from './shared/createGenericStoreSlice';
import { AlphamartHttpError, CompanyFormData } from '../shared/types';

export const createCompany =
  (values: CompanyFormData, skipUniqueChecker: boolean): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const { data: company } = await httpClient.post(
        `${apiHostname}/api/companies?skipUniquenessCheck=${skipUniqueChecker}`,
        values,
      );

      return company;
    } catch (error) {
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };
