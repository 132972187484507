import React, { useEffect, useState } from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { Guide, GuideStep } from 'components/shared/Guide';
import { AccessFlag, GuideTypes } from 'shared/constants';
import { useAlphamartNavigate, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useMarkGuideAsCompleted } from 'shared/mutations';
import { useGetPendingGuides } from 'shared/queries/guides';
import { MEDIA_QUERY } from 'theme';
import { messages, useTypedIntl } from './locale/messages';

const GuidesComponent = (): React.ReactNode => {
  const [steps, setSteps] = useState<GuideStep[]>([]);
  const user = useCurrentUser();
  const pendingGuides = useGetPendingGuides({ enabled: Boolean(user) });
  const markGuideAsCompleted = useMarkGuideAsCompleted();
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_MD);
  useEffect(() => {
    const stepsToSet: GuideStep[] = [];
    if (
      pendingGuides.data?.includes(GuideTypes.SHOPPING_CART) &&
      user?.accessFlags?.includes(AccessFlag.SHOPPING_CART_VIEWER)
    ) {
      stepsToSet.push({
        querySelector: isMobile
          ? '[data-guide="shopping-cart-max-md"]'
          : '[data-guide="shopping-cart"]',
        title: intl.formatMessage({ id: 'Guides.ShoppingCart.Title' }),
        message: intl.formatMessage({ id: 'Guides.ShoppingCart.Message' }),
        pathnameToMatch: '/converters',
        pathnameToRedirect: '/converters',
        onStepCompleted: () => markGuideAsCompleted.mutateAsync(GuideTypes.SHOPPING_CART),
      });
    }
    if (pendingGuides.data?.includes(GuideTypes.NEW_PROFILE_POSITION) && !isMobile) {
      stepsToSet.push({
        querySelector: '[data-guide="profile-position"]',
        title: intl.formatMessage({ id: 'Guides.ProfilePosition.Title' }),
        message: intl.formatMessage({ id: 'Guides.ProfilePosition.Message' }),
        pathnameToMatch: '/dashboard',
        pathnameToRedirect: '/dashboard',
        onStepCompleted: () => markGuideAsCompleted.mutateAsync(GuideTypes.NEW_PROFILE_POSITION),
      });
    }
    setSteps(stepsToSet);
  }, [pendingGuides.data, user, isMobile]);

  const handleGuideFinished = () => pendingGuides.refetch();
  const shouldRender = user && steps.length > 0;

  return shouldRender ? (
    <Guide
      steps={steps}
      onFinish={handleGuideFinished}
      redirectFunction={navigate}
      blockingQuerySelectors={['[data-guide-blocker]']}
    />
  ) : null;
};

export const Guides = withAlphamartIntlProvider(
  GuidesComponent as React.FunctionComponent,
  messages,
);
