import { useEffect, useState } from 'react';

/**
 * Hook to detect if media query matches
 *
 * @see https://usehooks-ts.com/react-hook/use-media-query
 */
export function useMediaQuery(mediaQuery: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(mediaQuery));

  function handleChange() {
    setMatches(getMatches(mediaQuery));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(mediaQuery);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [mediaQuery]);

  return matches;
}
