import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { Details } from 'components/shared/Details';
import { FieldRadioButton } from 'components/shared/Fields/FieldRadioButton/FieldRadioButton';
import { ModalFormContent } from 'components/shared/forms/Form/ModalForm.styles';
import { HIDDEN_ON_MEDIA_QUERY, layers, MEDIA_QUERY, Theme } from 'theme';

export const tabsStyles = css`
  margin-top: 24px;
  margin-bottom: -31px;
  border-bottom: transparent !important;
  ${HIDDEN_ON_MEDIA_QUERY.MAX_LG}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDetails = styled(Details)`
  header {
    position: sticky;
    top: 0;
    z-index: ${layers.detailsHeader};
    background-color: inherit;
    align-items: flex-start;

    @media ${MEDIA_QUERY.MAX_MD} {
      min-height: 0;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    & > button {
      margin-top: 6px;
    }

    & > div,
    & > div > div {
      width: 100%;
    }
  }
`;

export const TableContainer = styled.div`
  align-self: start;
  & > div {
    padding-top: 16px;
  }
`;

export const SummaryContainer = styled.div<{ theme?: Theme }>`
  position: sticky;
  bottom: 0;
  padding-bottom: 16px !important;
  margin-top: 32px;
  background-color: ${({ theme }) => theme.colors.codGrayDarker};
  color: ${({ theme }) => theme.colors.alphaGray};
  font-size: 14px;

  strong {
    color: ${({ theme }) => theme.colors.white};
  }

  & > div {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px 32px;
    text-align: right;
    font-weight: bold;
    @media ${MEDIA_QUERY.LG} {
      padding-top: 24px;
      flex-direction: row;
      text-align: left;
      border-top: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
    }

    & > div:first-of-type {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
      color: ${({ theme }) => theme.colors.white};
      @media ${MEDIA_QUERY.LG} {
        margin-right: auto;
        padding-bottom: 0;
        border: none;
      }
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px 8px;
  h3 {
    font-weight: 500;
    margin-right: auto;
  }
`;

export const InfoContainer = styled.div<{ theme?: Theme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: start;
  gap: 10px;
  margin: 24px 32px;
  padding: 12px 24px !important;
  border-radius: 8px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.mineShaftDarker};
  span {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const PricesSource = styled(InfoContainer)`
  margin: 0;
  @media ${MEDIA_QUERY.MAX_LG} {
    width: 100%;
  }
`;

export const CartNameContainer = styled.div`
  margin-right: auto;
  display: flex;
  position: relative;
  gap: 16px;
  overflow: hidden;

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media ${MEDIA_QUERY.MAX_LG} {
    display: none;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;

  > div:nth-of-type(1) {
    order: 1;
  }
  > div:nth-of-type(2) {
    order: 2;
  }
  > div:nth-of-type(3) {
    order: 3;
  }
  > div:nth-of-type(4) {
    order: 4;
  }

  @media ${MEDIA_QUERY.MAX_LG} {
    flex-wrap: wrap;

    > div:nth-of-type(1) {
      order: -1;
    }
    > div:nth-of-type(2) {
      order: 1;
      flex: 2;
    }
    > div:nth-of-type(3) {
      order: 3;
    }
    > div:nth-of-type(4) {
      order: 2;
    }
  }
`;

export const EditButton = styled.div`
  position: relative;
  top: 18px;
  height: 25px;
`;
export const StyledFieldRadioButton = styled(FieldRadioButton)<{ theme?: Theme }>`
  & > div:nth-of-type(2) > div:first-of-type {
    background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  }
`;

export const refreshButtonStyles = css`
  gap: 8px;
`;

export const StyledModalFormContent = styled(ModalFormContent)`
  position: relative;
  overflow: hidden;
`;

export const Divider = styled.div<{ theme?: Theme }>`
  margin: 12px 0;
  border-bottom: 1px dotted ${({ theme }) => theme.colors.mineShaftLightest};
`;

export const PriceWrapper = styled.div`
  text-align: right;
`;
