import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import { isUndefined } from 'lodash';

import filterIcon from 'assets/images/icons/filter.svg';
import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import {
  AdditionalNestedTableContainer,
  DataList,
  ItemThumbnail,
  ListActions,
  NestedTableContainer,
  useGetRowId,
} from 'components/shared/List';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { Price } from 'components/shared/Price/Price';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import {
  FilterableModules,
  Language,
  ModalFormType,
  PERMISSIONS,
  SUPPORTED_PORTAL_CURRENCIES,
  UNITS,
} from 'shared/constants';
import {
  SortableModules,
  SortDirection,
  SortSelectOptions,
  SortTypes,
} from 'shared/constants/sortableModules';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useGetVehicle, useGetVehicles } from 'shared/queries';
import { ConverterListItem, ItemAction } from 'shared/types';
import { diffObjects } from 'shared/utils/diffObjects';
import { changePageIndexAction, changePagination, setSortState } from 'store/listComponentSlice';
import { showModalForm } from 'store/modalFormSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import {
  ConverterToRender,
  getVehiclesListWithUniqueConverters,
  NonstandardConverterToRender,
  VehicleListItemToRender,
} from './VehicleList.helpers';
import { panelContentStyles, PriceColumn, StyledIconTooltipWarning } from './VehicleList.styles';
import { VehicleFiltersShape, VehicleListFilters } from './VehicleListFilters';
import {
  messages,
  TypedFormattedMessage,
  useTypedIntl,
  VehiclesMessages,
} from '../locale/messages';
import { VehicleDetails } from '../VehicleDetails/VehicleDetails';
import { VehicleSearchModal } from '../VehicleSearch/VehicleSearchModal';

export const StyledPrice = ({
  currency,
  hasIncompletePrice,
  language,
  value,
}: {
  currency: SUPPORTED_PORTAL_CURRENCIES;
  hasIncompletePrice?: boolean;
  language: Language;
  value: number | null | undefined;
}): React.ReactElement => (
  <PriceColumn>
    {hasIncompletePrice && (
      <StyledIconTooltipWarning
        tooltip={<TypedFormattedMessage id="VehiclesList.IncompletePrice" />}
      />
    )}
    <Price value={value} language={language} currency={currency} />
  </PriceColumn>
);

const NestedConvertersList = ({ row: converterRow }: { row: Row<ConverterToRender> }) => {
  const intl = useTypedIntl();
  const isLg = useMediaQuery(MEDIA_QUERY.LG);
  const currentUser = useCurrentUser();
  const columns: ColumnDef<Partial<ConverterListItem>>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Image' }),
      cell: ({ row }) =>
        ItemThumbnail({
          photo: row.original.converterPhoto,
          attrAlt: row.original.converterIdentifier!,
        }),
    },
    {
      id: 'converterIdentifier',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Identifier' }),
      cell: ({ row }) =>
        row.original.isPartial
          ? intl.formatMessage(
              { id: 'VehiclesList.PartialIdentifier' },
              { identifier: row.original.converterIdentifier },
            )
          : row.original.converterIdentifier,
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Price' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.price!.marketPrice,
          currency: currentUser.currency!,
          language: currentUser.language!,
        }),
      meta: { align: 'center' },
    },
  ];

  const table = useReactTable({
    columns,
    data: converterRow.original.converters,
    getRowId: useGetRowId(),
    getCoreRowModel: getCoreRowModel(),
    state: { columnVisibility: { image: isLg } },
    enableRowSelection: false,
  });

  return <DataList table={table} outerContainerComponent={NestedTableContainer} />;
};

const NestedVehicleConvertersList = ({
  row: vehicleRow,
}: {
  row: Row<VehicleListItemToRender>;
}) => {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();

  const columns: ColumnDef<ConverterToRender>[] = [
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Identifier' }),
      cell: ({ row }) => row.original.identifier,
    },
    {
      id: 'averagePrice',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.AveragePrice' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.averagePrice,
          currency: currentUser.currency!,
          language: currentUser.language!,
          hasIncompletePrice: row.original.hasIncompletePrice,
        }),
      meta: { align: 'center', widthPx: 150 },
    },
  ];

  const nonstandardColumns: ColumnDef<NonstandardConverterToRender>[] = [
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Identifier' }),
      cell: ({ row }) => row.original.material,
    },
    {
      id: 'averagePrice',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.AveragePrice' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.price,
          currency: currentUser.currency!,
          language: currentUser.language!,
          hasIncompletePrice: !row.original.price,
        }),
      meta: { align: 'center', widthPx: 150 },
    },
  ];

  const table = useReactTable({
    columns,
    data: vehicleRow.original.vehicleConverters,
    getRowId: useGetRowId(),
    getRowCanExpand: row => !!row.original.converters?.length,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
  });

  const nonstandardTable = useReactTable({
    columns: nonstandardColumns,
    data: vehicleRow.original.nonstandardVehicleConverters!,
    getRowId: useGetRowId(),
    getRowCanExpand: () => false,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
  });

  const nonstandardConvertersExist = !!vehicleRow.original.nonstandardVehicleConverters?.length;

  return (
    <>
      {vehicleRow.original.vehicleConverters.length > 0 && (
        <DataList
          subComponent={NestedConvertersList}
          table={table}
          outerContainerComponent={
            nonstandardConvertersExist ? NestedTableContainer : AdditionalNestedTableContainer
          }
        />
      )}

      {nonstandardConvertersExist && (
        <DataList
          table={nonstandardTable}
          outerContainerComponent={AdditionalNestedTableContainer}
          headerBodyComponent={nonstandardConvertersExist ? null : React.Fragment}
        />
      )}
    </>
  );
};

const VehiclesListBase = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const intl = useTypedIntl();
  const listComponent = useAppSelector(state => state.listComponent);
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isLg = useMediaQuery(MEDIA_QUERY.LG);
  const currentUser = useCurrentUser();
  const selectedSorting = listComponent.sortState?.[SortableModules.VEHICLES];
  const savedFilters = useAppSelector(state => state.filters.vehicles);
  const { data: vehicles, isFetching: isLoadingVehicles } = useGetVehicles(
    savedFilters.data,
    listComponent.pageIndex + 1,
    listComponent.pageSize,
    selectedSorting,
    { initialData: { data: [], count: 0 } },
  );

  const { id } = useParams<{ id: string }>();
  const { isFetching, data: vehicleData } = useGetVehicle(id!, { enabled: !isUndefined(id) });

  const vehiclesList = getVehiclesListWithUniqueConverters(vehicles?.data ?? []);
  const handleVinSearch = () =>
    dispatch(showModalForm({ modalType: ModalFormType.VinSearch, params: null }));

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const handleFiltersChanged = (changed: VehicleFiltersShape) => {
    const diffResult = diffObjects(savedFilters.data, changed);
    if (diffResult.count > 0) {
      setPageIndex(0);
    }
    dispatch(saveFilters({ filterKey: FilterableModules.VEHICLES, values: changed }));
  };

  const handleSortingChange = (changed: Updater<SortingState>) => {
    setPageIndex(0);
    dispatch(
      setSortState({
        moduleName: SortableModules.VEHICLES,
        value: changed.length
          ? {
              sortType: changed[0].id as SortTypes,
              sortDirection: changed[0].desc ? SortDirection.DESC : SortDirection.ASC,
            }
          : null,
      }),
    );
  };

  const createVehicle = () => {
    setFiltersOpen(false);
    navigate('/vehicles/create');
  };

  const handleVehicleView = item => {
    setFiltersOpen(false);
    navigate(`/vehicles/list/${item.id}`);
  };

  const handleUpdateView = item => {
    setFiltersOpen(false);
    navigate(`/vehicles/list/update/${item.id}`);
  };

  const actions: ItemAction<VehicleListItemToRender>[] = authorize(
    PERMISSIONS.VEHICLES.FULL_DETAILS,
  )
    ? [
        {
          label: <TypedFormattedMessage id="VehiclesList.Details.View" />,
          onClick: item => handleVehicleView(item),
          dataCy: item => `view-vehicle-${item?.id}`,
        },
        {
          label: <TypedFormattedMessage id="Global.Update" />,
          onClick: item => handleUpdateView(item),
          dataCy: item => `edit-vehicle-${item?.id}`,
          visible: () => authorize(PERMISSIONS.VEHICLES.EDIT),
        },
      ]
    : [
        {
          label: <TypedFormattedMessage id="VehiclesList.Details.View" />,
          onClick: item => handleVehicleView(item),
          dataCy: item => `view-vehicle-${item?.id}`,
        },
      ];

  const handleFiltersToggle = () => setFiltersOpen(!filtersOpen);

  const sortTypes: SortSelectOptions[] = [
    {
      label: intl.formatMessage({ id: `VehiclesList.Filters.UpdatedAt.ASC` }),
      value: { sortDirection: SortDirection.ASC, sortType: SortTypes.UPDATED_AT },
    },
    {
      label: intl.formatMessage({ id: `VehiclesList.Filters.UpdatedAt.DESC` }),
      value: { sortDirection: SortDirection.DESC, sortType: SortTypes.UPDATED_AT },
    },
  ];

  const getVehicleName = (row: Row<VehicleListItemToRender>) =>
    `${row.original.year} ${row.original.make} ${row.original.model}`;
  const columns: ColumnDef<VehicleListItemToRender>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.Image' }),
      cell: ({ row }) =>
        ItemThumbnail({ photo: row.original.vehiclePhoto, attrAlt: getVehicleName(row) }),
    },
    {
      id: 'makeModelYear',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.MakeModelYear' }),
      cell: ({ row }) => getVehicleName(row),
    },
    {
      id: 'engine',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.Engine' }),
      cell: ({ row }) =>
        `${intl.formatMessage({
          id: `VehiclesList.EngineType.${row.original.engineType}` as keyof VehiclesMessages,
        })} ${row.original.engineDisplacement} ${UNITS.CC}`,
    },
    {
      id: 'numberOfConverters',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.NumberOfConverters' }),
      cell: ({ row }) => row.original.numberOfConverters,
      meta: {
        align: 'center',
      },
    },
    {
      id: 'value',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.Value' }),
      cell: ({ row }) => (
        <StyledPrice
          value={row.original.avgConvertersPrice}
          hasIncompletePrice={row.original.hasIncompletePrice}
          currency={currentUser.currency!}
          language={currentUser.language!}
        />
      ),
    },
    {
      id: 'actions',
      header: intl.formatMessage({ id: 'Global.Actions' }),
      cell: ({ row }) => <ListActions actions={actions} item={row.original} />,
      meta: {
        widthPx: 100,
      },
    },
    {
      id: 'valueActions',
      header: `${intl.formatMessage({
        id: 'VehiclesList.TableHeader.Value',
      })} / ${intl.formatMessage({ id: 'Global.Actions' })}`,
      cell: ({ row }) => (
        <>
          <StyledPrice
            value={row.original.avgConvertersPrice}
            hasIncompletePrice={row.original.hasIncompletePrice}
            currency={currentUser.currency!}
            language={currentUser.language!}
          />
          <ListActions actions={actions} item={row.original} />
        </>
      ),
      meta: {
        align: 'center',
      },
    },
  ];

  const table = useReactTable({
    columns,
    data: vehiclesList,
    getRowId: useGetRowId(),
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: row =>
      (row.original.vehicleConverters?.length > 0 ||
        (!!row.original.nonstandardVehicleConverters &&
          row.original.nonstandardVehicleConverters.length > 0)) &&
      authorize(PERMISSIONS.VEHICLES.FULL_DETAILS),
    pageCount: Math.ceil((vehicles?.count ?? 0) / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    onSortingChange: handleSortingChange,
    manualPagination: true,
    manualSorting: true,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        value: isLg,
        actions: isLg,
        valueActions: !isLg,
        engine: isMd,
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      sorting: selectedSorting
        ? [
            {
              id: selectedSorting.sortType,
              desc: selectedSorting.sortDirection === SortDirection.DESC,
            },
          ]
        : [],
      columnSizing: {
        makeModelYear: 200,
        engine: 180,
        numberOfConverters: 100,
        value: 180,
        valueActions: 120,
      },
    },
  });

  return (
    <LoadableContent loading={isFetching} mode={LoadableContentModes.FULL} drawContent>
      <PanelTemplate>
        <PanelHeader title={<TypedFormattedMessage id="VehiclesList.Header" />}>
          {authorize(PERMISSIONS.VEHICLES.CREATE) ? (
            <AppButton type="button" onClick={createVehicle}>
              <TypedFormattedMessage id="Global.Create" />
            </AppButton>
          ) : null}
          <AppButton type="button" onClick={handleVinSearch}>
            <TypedFormattedMessage
              id={`VehiclesMain.${isPhone ? 'SearchByVin.Short' : 'SearchByVin'}`}
            />
          </AppButton>
        </PanelHeader>
        <PanelContent>
          <PanelContentMain className={panelContentStyles}>
            <SearchBar
              searchInputVisible={false}
              onFiltersToggle={handleFiltersToggle}
              savedFilters={savedFilters}
            />
            <DataList
              table={table}
              isLoading={isLoadingVehicles}
              subComponent={NestedVehicleConvertersList}
              sortOptions={sortTypes}
            />
          </PanelContentMain>
          <PanelContentSidebar
            header={intl.formatMessage({ id: 'Global.Filters' })}
            headerIcon={filterIcon}
            open={filtersOpen}
            onSidebarClosed={handleFiltersToggle}
          >
            <VehicleListFilters
              onFiltersChanged={handleFiltersChanged}
              onFiltersApplied={handleFiltersToggle}
            />
          </PanelContentSidebar>
        </PanelContent>
        <VehicleDetails vehicleDetails={vehicleData!} />
      </PanelTemplate>
    </LoadableContent>
  );
};

export const VehiclesList = (): React.ReactElement => (
  <AlphamartIntlProvider messages={messages}>
    <VehiclesListBase />
    <VehicleSearchModal />
  </AlphamartIntlProvider>
);
