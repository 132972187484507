import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { addMinutes, isSameDay } from 'date-fns';
import { FormikContextType } from 'formik';

import { FieldDatePicker } from 'components/shared/Fields/FieldDatePicker/FieldDatePicker';
import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { ExtraInformation } from 'components/shared/forms/Section/components/ExtraInformation/ExtraInformation';
import { Section } from 'components/shared/forms/Section/Section';
import { getDateTimeFormat } from 'helpers/dateTime/dateTime';
import { CompanyTypes, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useLanguage } from 'shared/hooks';
import { useGetAssignedCompanies, useGetCompanies } from 'shared/queries';
import { Hedge, Status } from 'shared/types';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { theme } from 'theme';
import { useTypedIntl } from '../locale/messages';

interface Props {
  formikContext: FormikContextType<HedgeFormSchema>;
  editMode?: boolean;
  hedgeData?: Hedge;
}

export const HedgeFormStepOne = ({
  hedgeData,
  editMode = false,
  formikContext,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const language = useLanguage();
  const [activeSection, setActiveSection] = useState<string>('');
  const [activeInput, setActiveInput] = useState<string | null>();
  const canChangeParentCompany = authorize(PERMISSIONS.HEDGES.CHANGE_PARENT_COMPANY);
  const currentUser = useCurrentUser();
  const parentCompanies = useGetCompanies(
    { type: CompanyTypes.SISTER, status: Status.ACTIVE },
    { enabled: canChangeParentCompany && !editMode },
  );
  const assignedCompanies = useGetAssignedCompanies(
    formikContext.values.parentCompany ?? currentUser.company.id,
  );
  const dateFormat = getDateTimeFormat(language);

  const parentCompanyOptions = useMemo(() => {
    let availableOptions: { id: number; name: string }[];

    if (!canChangeParentCompany) {
      availableOptions = [{ id: currentUser.company.id, name: currentUser.company.name }];
    } else if (editMode && hedgeData) {
      availableOptions = [{ id: hedgeData.parentCompany?.id, name: hedgeData.parentCompany?.name }];
    } else {
      availableOptions = [...(parentCompanies.data?.data ?? [])];
    }

    return availableOptions
      .sort((c1, c2) => c1.name.localeCompare(c2.name))
      .map(({ id: value, name: label }) => ({ value, label }));
  }, [parentCompanies, editMode]);

  const { values, handleChange, getFieldMeta, handleBlur, setFieldValue, setFieldTouched } =
    formikContext;

  useEffect(() => {
    if (!values.ptPriceCust) setFieldValue('ptPriceCustConfirm', '');
    if (!values.pdPriceCust) setFieldValue('pdPriceCustConfirm', '');
    if (!values.rhPriceCust) setFieldValue('rhPriceCustConfirm', '');
  }, [values.ptPriceCust, values.pdPriceCust, values.rhPriceCust]);

  const getErrors = (name: string) => {
    const { touched: fieldTouched, error } = getFieldMeta(name);
    return fieldTouched && error;
  };

  const relatedCompaniesOptions = useMemo<{ value: number; label: string }[]>(() => {
    const parentCompanyId = editMode
      ? values.parentCompany ?? currentUser.company.id
      : values.parentCompany;
    const parentCompany = parentCompanyOptions.find(c => c.value === parentCompanyId)!;

    return [
      parentCompany,
      ...[...(assignedCompanies.data ?? [])]
        .filter(
          ({ id, status }) =>
            status === Status.ACTIVE ||
            hedgeData?.relatedCompanies?.some(({ id: existingId }) => id === existingId),
        )
        .sort((c1, c2) => c1.name.localeCompare(c2.name))
        .map(({ id: value, name: label }) => ({ value, label })),
    ];
  }, [
    assignedCompanies,
    values.parentCompany,
    parentCompanyOptions,
    currentUser,
    editMode,
    hedgeData,
  ]);
  const handleBlurOnConfirmableFields = useCallback(
    e => {
      setFieldTouched(`${e.target.name}Confirm`);
      setActiveInput(null);
    },
    [setActiveInput, handleBlur],
  );
  const handleFocusOnConfirmableFields = useCallback(
    e => {
      setFieldTouched(e.target.name);
      setActiveInput(e.target.name);
    },
    [setActiveInput, handleBlur],
  );

  return (
    <FormInWizard
      context={formikContext}
      header={intl.formatMessage({ id: `HedgeForm.${editMode ? 'UpdateHeader' : 'Header'}` })}
    >
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="hedgeInfo"
        label={intl.formatMessage({ id: 'HedgeForm.Section.HedgeInfo' })}
        template={[
          'name relatedLot',
          canChangeParentCompany ? 'parentCompany relatedCompanies' : 'relatedCompanies .',
        ]}
      >
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.HedgeName' })}
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          error={getErrors('name')}
          data-cy="name"
          required
          autoComplete="off"
          maxLength={50}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.RelatedLot' })}
          name="relatedLot"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.relatedLot}
          error={getErrors('relatedLot')}
          data-cy="related-lot"
          maxLength={50}
        />
        {canChangeParentCompany ? (
          <FieldSelect
            label={intl.formatMessage({ id: 'HedgeForm.ParentCompany' })}
            name="parentCompany"
            value={values.parentCompany}
            error={getErrors('parentCompany')}
            options={parentCompanyOptions}
            required
            disabled={editMode}
          />
        ) : null}
        <FieldSelect
          label={intl.formatMessage({ id: 'HedgeForm.RelatedCompanies' })}
          name="relatedCompanies"
          value={values.relatedCompanies}
          multi
          error={getErrors('relatedCompanies')}
          options={relatedCompaniesOptions}
          required
        />
      </Section>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="prices"
        label={intl.formatMessage({ id: 'HedgeForm.Section.Prices' })}
        template={[
          'ptPriceCust ptPriceCustConfirm',
          'pdPriceCust pdPriceCustConfirm',
          'rhPriceCust rhPriceCustConfirm',
          'extraInformation extraInformation',
        ]}
        extraInformation
      >
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.PtPriceCust' })}
          name="ptPriceCust"
          onChange={handleChange}
          onBlur={handleBlurOnConfirmableFields}
          onFocus={handleFocusOnConfirmableFields}
          protection={{
            hide: activeInput === 'ptPriceCustConfirm',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          value={values.ptPriceCust!}
          error={getErrors('ptPriceCust')}
          prefix="$"
          data-cy="pt-price-cust"
        />
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.PtPriceCustConfirm' })}
          name="ptPriceCustConfirm"
          onChange={handleChange}
          onBlur={handleBlurOnConfirmableFields}
          onFocus={handleFocusOnConfirmableFields}
          protection={{
            hide: activeInput === 'ptPriceCust',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          value={values.ptPriceCustConfirm!}
          error={getErrors('ptPriceCustConfirm')}
          disabled={!values.ptPriceCust}
          prefix="$"
          data-cy="pt-price-cust-confirm"
        />
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.PdPriceCust' })}
          name="pdPriceCust"
          onChange={handleChange}
          onBlur={handleBlurOnConfirmableFields}
          onFocus={handleFocusOnConfirmableFields}
          protection={{
            hide: activeInput === 'pdPriceCustConfirm',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          value={values.pdPriceCust!}
          error={getErrors('pdPriceCust')}
          prefix="$"
          data-cy="pd-price-cust"
        />
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.PdPriceCustConfirm' })}
          name="pdPriceCustConfirm"
          onChange={handleChange}
          onBlur={handleBlurOnConfirmableFields}
          onFocus={handleFocusOnConfirmableFields}
          protection={{
            hide: activeInput === 'pdPriceCust',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          value={values.pdPriceCustConfirm!}
          error={getErrors('pdPriceCustConfirm')}
          disabled={!values.pdPriceCust}
          prefix="$"
          data-cy="pd-price-cust-confirm"
        />
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.RhPriceCust' })}
          name="rhPriceCust"
          onChange={handleChange}
          onBlur={handleBlurOnConfirmableFields}
          onFocus={handleFocusOnConfirmableFields}
          protection={{
            hide: activeInput === 'rhPriceCustConfirm',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          value={values.rhPriceCust!}
          error={getErrors('rhPriceCust')}
          prefix="$"
          data-cy="rh-price-cust"
        />
        <FieldInput
          label={intl.formatMessage({ id: 'HedgeForm.RhPriceCustConfirm' })}
          name="rhPriceCustConfirm"
          onChange={handleChange}
          onBlur={handleBlurOnConfirmableFields}
          onFocus={handleFocusOnConfirmableFields}
          protection={{
            hide: activeInput === 'rhPriceCust',
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          value={values.rhPriceCustConfirm!}
          error={getErrors('rhPriceCustConfirm')}
          disabled={!values.rhPriceCust}
          prefix="$"
          data-cy="rh-price-cust-confirm"
        />
        <ExtraInformation name="extraInformation" color={theme.colors.blazeOrange}>
          {intl.formatMessage({ id: 'HedgeForm.OnePriceRequired' })}
        </ExtraInformation>
      </Section>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="note"
        label={intl.formatMessage({ id: 'HedgeForm.Section.Note' })}
        template={['note note']}
      >
        <FieldTextArea
          label={intl.formatMessage({ id: 'HedgeForm.Note' })}
          name="note"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.note}
          data-cy="note"
          maxLength={255}
        />
      </Section>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="dates"
        label={intl.formatMessage({ id: 'HedgeForm.Section.Dates' })}
        template={['placedAt expiresAt']}
      >
        <FieldDatePicker
          name="placedAt"
          value={values.placedAt!}
          onChange={setFieldValue}
          onTouch={setFieldTouched}
          error={getErrors('placedAt')}
          label={intl.formatMessage({ id: 'HedgeForm.PlacedOn' })}
          dateFormat={dateFormat}
          max={values.expiresAt}
          maxTime={
            isSameDay(values.placedAt!, values.expiresAt)
              ? addMinutes(values.expiresAt, -1)
              : undefined
          }
          required
          showTimeSelect
        />
        <FieldDatePicker
          name="expiresAt"
          value={values.expiresAt}
          onChange={setFieldValue}
          onTouch={setFieldTouched}
          error={getErrors('expiresAt')}
          label={intl.formatMessage({ id: 'HedgeForm.Expiration' })}
          dateFormat={dateFormat}
          min={values.placedAt!}
          minTime={
            isSameDay(values.placedAt!, values.expiresAt)
              ? addMinutes(values.placedAt!, 1)
              : undefined
          }
          required
          showTimeSelect
        />
      </Section>
    </FormInWizard>
  );
};
