import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import {
  CartesianGrid,
  Customized,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart as ScatterChartRecharts,
  ScatterProps,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Props as GridProps } from 'recharts/types/cartesian/CartesianGrid';
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';
import { ContentType } from 'recharts/types/component/Tooltip';

import { useExtendedTheme, useMediaQuery, useWindowHeight, useWindowWidth } from 'shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { getSelectedTick, getZoomedData, getZoomedTicks } from './ScatterChart.helpers';
import { ScatterChartLegend } from './ScatterChartLegend';
import { Loader } from '../Loader';

const chartOverrides = css`
  position: relative;
  --left-offset: -25px;
  left: var(--left-offset);

  .yAxis {
    line {
      opacity: 0;
    }
  }

  & > * {
    user-select: none;
  }

  .recharts-cartesian-axis-tick-value {
    @media ${MEDIA_QUERY.MAX_MD} {
      font-size: 10px;
      width: 5px;
      overflow: hidden;
    }
  }

  .recharts-surface {
    overflow: visible;
  }
`;
const StyledLoader = styled.g`
  g:first-of-type {
    transform: translateY(22%) scale(0.8) translateX(34%);
  }
`;

export type ScatterValue = {
  x: number;
  y: number;
};
export type ScatterData = ScatterProps & {
  data: ScatterValue[];
  reactKey: string;
};
type Props = {
  data: ScatterData[];
  xAxisTicks: number[];
  yAxisTicks: number[];
  generateXLabel: (val: string | number) => string;
  valuesFormatter: (value: string | number, label: string, props: unknown) => [string, string];
  width?: number;
  height?: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  customTooltip?: ContentType<any, any> | null;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  CustomScatterPoint?: (props: any) => React.ReactElement<SVGElement> | null;
  tooltipPosition?: {
    x?: number;
    y?: number;
  };
  gridProps?: GridProps;
  isPending?: boolean;
};

export function ScatterChart({
  data,
  xAxisTicks,
  yAxisTicks,
  generateXLabel,
  valuesFormatter,
  width = 100,
  height = 99.9,
  customTooltip = null,
  CustomScatterPoint,
  gridProps,
  isPending,
}: Props): React.ReactElement {
  const [isZoomedIn, setIsZoomedIn] = React.useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false);
  const [isMobileZoomStarted, setIsMobileZoomStarted] = React.useState<boolean>(false);
  const [zoomAreaXStart, setZoomAreaXStart] = React.useState<number | null>(null);
  const [zoomAreaXEnd, setZoomAreaXEnd] = React.useState<number | null>(null);
  const [zoomAreaYStart, setZoomAreaYStart] = React.useState<number | null>(null);
  const [zoomAreaYEnd, setZoomAreaYEnd] = React.useState<number | null>(null);
  const [zoomedData, setZoomedData] = React.useState<ScatterData[] | null>(null);
  const [zoomedYTicks, setZoomedYTicks] = React.useState<number[] | null>(null);
  const [zoomedXTicks, setZoomedXTicks] = React.useState<number[] | null>(null);
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);
  const theme = useExtendedTheme();
  React.useEffect(() => {
    handleResetZoom();
  }, [data]);

  const handleMouseDown: CategoricalChartFunc = (chartEvent, HTMLEvent) => {
    const chartSurfaceHit =
      HTMLEvent?.target?.className?.baseVal === 'recharts-surface' && chartEvent !== null;
    if (isZoomedIn || !chartSurfaceHit) {
      return;
    }

    if (isMobile) {
      const isStartTouch = zoomAreaXStart === null && zoomAreaYStart === null;
      if (isStartTouch) {
        setZoomAreaXStart(getSelectedTick(xAxisTicks, chartEvent?.xValue ?? 0));
        setZoomAreaYStart(getSelectedTick(yAxisTicks, chartEvent?.yValue ?? 0));
        setIsMobileZoomStarted(true);
      } else {
        const xEnd = getSelectedTick(xAxisTicks, chartEvent?.xValue ?? 0, zoomAreaXStart!);
        handleZoom(zoomAreaXStart!, xEnd, yAxisTicks[0], yAxisTicks[yAxisTicks.length - 1]);
        setIsMobileZoomStarted(false);
      }
      return;
    }

    setIsMouseDown(true);
    setZoomAreaXStart(getSelectedTick(xAxisTicks, chartEvent?.xValue ?? 0));
    setZoomAreaYStart(getSelectedTick(yAxisTicks, chartEvent?.yValue ?? 0));
  };

  const handleMouseMove: CategoricalChartFunc = chartEvent => {
    if (isMobile || isZoomedIn) {
      return;
    }
    if (isMouseDown) {
      setZoomAreaXEnd(getSelectedTick(xAxisTicks, chartEvent?.xValue ?? 0, zoomAreaXStart!));
      setZoomAreaYEnd(getSelectedTick(yAxisTicks, chartEvent?.yValue ?? 0, zoomAreaYStart!));
    }
  };

  const handleMouseUp = () => {
    if (isMobile || isZoomedIn) {
      return;
    }
    handleZoom(zoomAreaXStart!, zoomAreaXEnd!, zoomAreaYStart!, zoomAreaYEnd!);
  };

  const handleZoom = (xStart: number, xEnd: number, yStart: number, yEnd: number) => {
    const areaNotSelected = xEnd == null || yEnd == null;
    if (areaNotSelected) {
      setZoomAreaXStart(null);
      setZoomAreaYStart(null);
      setIsMouseDown(false);
      return;
    }

    setIsMouseDown(false);
    const xTicks = getZoomedTicks(xAxisTicks, xStart, xEnd);
    const yTicks = getZoomedTicks(yAxisTicks, yStart, yEnd);

    setZoomedXTicks(xTicks);
    setZoomedYTicks(yTicks);
    setZoomedData(getZoomedData(data, xTicks, yTicks));
    setZoomAreaXStart(null);
    setZoomAreaYStart(null);
    setZoomAreaXEnd(null);
    setZoomAreaYEnd(null);
    setIsZoomedIn(true);
  };

  const handleResetZoom = () => {
    setZoomedXTicks(null);
    setZoomedYTicks(null);
    setZoomedData(null);
    setZoomAreaXStart(null);
    setZoomAreaYStart(null);
    setZoomAreaXEnd(null);
    setZoomAreaYEnd(null);
    setIsZoomedIn(false);
    setIsMobileZoomStarted(false);
  };

  const shouldRenderSelectArea =
    zoomAreaXStart !== null &&
    zoomAreaXEnd !== null &&
    zoomAreaYStart !== null &&
    zoomAreaYEnd !== null;

  return (
    <ResponsiveContainer className={chartOverrides} width={`${width}%`} height={`${height}%`}>
      <ScatterChartRecharts
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Legend
          // eslint-disable-next-line
          content={() => (
            <ScatterChartLegend
              resetZoom={handleResetZoom}
              isZoomedIn={isZoomedIn}
              isMobileZoomStarted={isMobileZoomStarted}
            />
          )}
        />
        <CartesianGrid {...(gridProps as Record<string, unknown>)} />
        {/* rendering based on isPending prop is not in one group with React.Fragment because components wrapped in Fragment are not rendered by recharts */}
        {!isPending &&
          (zoomedData ?? data).map(el => (
            <Scatter
              {...(el as unknown as Record<string, unknown>)}
              isAnimationActive={false}
              shape={
                CustomScatterPoint ? (
                  <CustomScatterPoint
                    isZoomedIn={isZoomedIn}
                    isMobile={isMobile}
                    windowWidth={windowWidth}
                    windowHeight={windowHeight}
                    isMobileZoomStarted={isMobileZoomStarted}
                  />
                ) : undefined
              }
              key={el.reactKey}
            />
          ))}
        <XAxis
          dataKey="x"
          ticks={zoomedXTicks ?? xAxisTicks}
          tickFormatter={generateXLabel}
          type="number"
          domain={['minValue', 'maxValue']}
          interval={xAxisTicks.length > 13 ? 3 : 0}
        />
        <YAxis
          domain={['minValue', 'maxValue']}
          dataKey="y"
          ticks={zoomedYTicks ?? yAxisTicks}
          type="number"
        />

        {!isPending && !isMobile && (
          <Tooltip
            formatter={valuesFormatter}
            content={customTooltip ?? undefined}
            isAnimationActive={false}
          />
        )}
        {!isPending && shouldRenderSelectArea && (
          <ReferenceArea
            x1={zoomAreaXStart}
            x2={zoomAreaXEnd}
            y1={zoomAreaYStart}
            y2={zoomAreaYEnd}
            strokeOpacity={0.3}
          />
        )}
        {!isPending && isMobileZoomStarted && (
          <ReferenceLine x={zoomAreaXStart ?? undefined} stroke={theme.mainColor} />
        )}
        {isPending && (
          <Customized
            component={
              <StyledLoader>
                <Loader size="M" />
              </StyledLoader>
            }
          />
        )}
      </ScatterChartRecharts>
    </ResponsiveContainer>
  );
}
