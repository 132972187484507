import { createSlice } from '@reduxjs/toolkit';

import { AssayFiltersShape } from 'components/views/Assays/AssaysList/AssaysFilters';
import { apiHostname, ErrorCode } from 'shared/constants';
import { serializeLocationHeader } from 'shared/helpers';
import { AlphamartHttpError, Coords } from 'shared/types';
import { AssayListItem } from 'shared/types/assayListItem';
import { getDateString } from 'shared/utils/getDateString';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { blockUserAction } from './auth';

interface AssayListState extends GenericStoreSlice {
  count: number;
  list: AssayListItem[];
}

const assaysListSlice = createSlice<AssayListState, GenericStoreReducer<AssayListState>>({
  name: 'assaysList',
  initialState: {
    list: [],
    count: 0,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      list: payload ? payload.data : [],
      count: payload ? payload.count : 0,
    })),
  },
});

export const {
  success: fetchAssaysSuccessAction,
  failure: fetchAssaysFailureAction,
  pending: fetchAssaysAction,
} = assaysListSlice.actions;

export default assaysListSlice.reducer;

export const fetchAssays =
  (
    page = 1,
    pageSize = 10,
    filters: AssayFiltersShape = {},
    position: Coords | undefined = undefined,
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await dispatch(fetchAssaysAction());

      const headers = position && { 'user-location': serializeLocationHeader(position) };
      const { data } = await httpClient.get(`${apiHostname}/api/assays`, {
        params: {
          page,
          pageSize,
          query: filters.query,
          ...(typeof filters.folder === 'number' && { folder: filters.folder }),
          ...(typeof filters.type === 'number' && { type: filters.type }),
          ...(filters.removed !== 'false' && { removed: filters.removed }),
          ...(filters.picture && { picture: filters.picture }),
          ...(filters.sampleDate?.from && {
            sampleDateFrom: getDateString(filters.sampleDate.from as Date),
          }),
          ...(filters.sampleDate?.to && {
            sampleDateTo: getDateString(filters.sampleDate.to as Date),
          }),
        },
        headers,
      });

      dispatch(fetchAssaysSuccessAction(data));
    } catch (error) {
      if (
        (error as AlphamartHttpError).response?.data?.errorCode ===
        ErrorCode.USER_BLOCKED_OFF_LIMIT_AREA_ENTERED
      )
        dispatch(blockUserAction());

      dispatch(fetchAssaysFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };
