import { CaseReducer, createSlice } from '@reduxjs/toolkit';

const clearStore = createSlice<unknown, { clear: CaseReducer<unknown> }>({
  name: 'clearStore',
  initialState: null,
  reducers: {
    clear: () => null,
  },
});

export const { clear } = clearStore.actions;

export default clearStore.reducer;
