import React from 'react';
import styled from '@emotion/styled';

import { MEDIA_QUERY, theme } from 'theme';

function CardLayout({
  className,
  children,
}: {
  className?: string;
  children: React.ReactElement[];
}) {
  return <div className={className}>{children}</div>;
}

const Card = styled(CardLayout)<{ height?: number; startOpacity: number; endOpacity: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  ${({ height }) => height && `min-height: ${height ?? 100}%`};
  margin-bottom: 16px;
  border-radius: 8px;
  background: ${({ startOpacity = 1, endOpacity = 0 }) =>
    `linear-gradient(135deg, rgba(105, 105, 105, ${startOpacity}) 0%, rgba(105, 105, 105, ${endOpacity}) 100%);`};
  padding: 10%;
  position: relative;

  & > :first-of-type {
    justify-self: start;
    align-self: start;
  }

  & > :nth-of-type(2) {
    align-self: start;
    justify-self: end;
  }

  & > :nth-last-of-type(2) {
    align-self: end;
    justify-self: start;
  }

  & > :last-of-type {
    align-self: end;
    justify-self: end;
  }

  &::after {
    content: '';
    position: absolute;
    left: 10%;
    top: 50%;
    width: 25%;
    height: 2px;
    background-color: ${theme.colors.whisper};
    opacity: 0.6;
  }

  @media ${MEDIA_QUERY.MAX_XXL} {
    height: fit-content;
    min-height: 116px;
    padding: 2%;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  @media ${MEDIA_QUERY.MAX_SM} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);

    & > :first-of-type,
    & > :nth-of-type(2) {
      align-self: start;
      justify-self: start;
    }

    & > :nth-last-of-type(2),
    & > :last-of-type {
      align-self: end;
      justify-self: start;
    }
  }
`;

export { Card };
