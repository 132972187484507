import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { parseUser } from 'shared/parsers/parseUser';
import { AlphamartHttpError, ManageUserAccesses, User, UserFormData } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface CreateUserState extends GenericStoreSlice {
  user: User | null;
}

const createUserSlice = createSlice<CreateUserState, GenericStoreReducer<CreateUserState>>({
  name: 'createUser',
  initialState: {
    isPending: false,
    user: null,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => (payload ? { user: payload } : { user: null })),
  },
});

export const {
  pending: createUserAction,
  success: createUserSuccessAction,
  failure: createUserFailureAction,
} = createUserSlice.actions;

export const createUser =
  (
    values: UserFormData,
    accesses: ManageUserAccesses,
    skipUniqueMobileChecker = false,
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(createUserAction());

      const userDto = parseUser(values, false, accesses);

      const user = await httpClient.post(
        `${apiHostname}/api/users?skipUniquenessCheck=${skipUniqueMobileChecker}`,
        userDto,
      );

      dispatch(createUserSuccessAction({ user }));
    } catch (error) {
      dispatch(createUserFailureAction());
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };

export default createUserSlice.reducer;
