import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { uniq, uniqBy } from 'lodash';

import {
  FilterFieldMultiselectCheckbox,
  Group,
  Option,
  OptionValue,
} from 'components/shared/Fields/FieldMultiselectCheckbox/FieldMultiselectCheckbox';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { DashboardFiltersKeys, DEFAULT_FILTERS } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { useGetUsers } from 'shared/queries/users';
import { Status, UserListItem } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface StatisticFiltersFormShape {
  companies: number[];
  users: number[];
  date: DashboardFiltersKeys;
}

interface Props {
  onFiltersChanged: (values: StatisticFiltersFormShape) => void;
  onFiltersApplied: () => void;
}

export function StatisticsPageFilters({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement {
  const intl = useTypedIntl();
  const {
    // default value is required because react-query doesn't infer type from initialData
    data: usersData = [],
    isFetched,
    refetch,
  } = useGetUsers(
    { status: [Status.ACTIVE, Status.INACTIVE] },
    { enabled: false, initialData: [] },
  );
  const savedFilters = useAppSelector(state => state.filters.statistics);
  const formikContext = useFormik<StatisticFiltersFormShape>({
    initialValues: DEFAULT_FILTERS.statistics,
    onSubmit: () => {},
  });
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (isFetched) {
      selectAll();
    }
  }, [isFetched]);
  useEffect(() => {
    !isMobile && onFiltersChanged(formikContext.values);
  }, [formikContext.values]);

  const companiesForFilter = React.useMemo(() => {
    if (usersData.length === 0) return [];
    return [{ groupLabel: '', options: getCompaniesForFilter(usersData) }];
  }, [usersData]);
  const usersForFilter = React.useMemo(
    () => getUsersForFilter(usersData, formikContext.values.companies),
    [formikContext.values.companies],
  );

  const handleFiltersClear = () => {
    formikContext.setValues(DEFAULT_FILTERS.statistics);
  };
  const handleCompaniesSelect = (companies: OptionValue[]) => {
    const newSelectedCompanies = companies.filter(
      company => !formikContext.values.companies.includes(Number(company)),
    );
    const deselectedCompanies = formikContext.values.companies.filter(
      company => !companies.includes(Number(company)),
    );
    const areAllSelected = newSelectedCompanies.length === companiesForFilter[0].options.length;
    const isAnyDeselected = deselectedCompanies.length > 0;
    if (areAllSelected) {
      selectAll();
    } else if (isAnyDeselected) {
      formikContext.setFieldValue('companies', companies as number[]);
      formikContext.setFieldValue(
        'users',
        formikContext.values.users.filter(userId => {
          const fullUserData = usersData.find(usr => usr.id === userId);
          return !fullUserData || !deselectedCompanies.includes(fullUserData.company.id);
        }),
      );
    } else {
      formikContext.setFieldValue('companies', companies as number[]);
      formikContext.setFieldValue(
        'users',
        uniq([
          ...formikContext.values.users,
          ...usersData
            .filter(
              user =>
                newSelectedCompanies.includes(user.company.id) && user.status === Status.ACTIVE,
            )
            .map(usr => usr.id),
        ]),
      );
    }
  };
  const handleUsersSelect = (users: OptionValue[]) => {
    formikContext.setFieldValue('users', users as number[]);
  };
  const selectAll = () => {
    const activeUsers = usersData.filter(user => user.status === Status.ACTIVE);
    const allCompanies = getCompaniesForFilter(activeUsers).map(el => el.value) as number[];
    formikContext.setFieldValue('companies', allCompanies);
    formikContext.setFieldValue(
      'users',
      activeUsers.map(user => user.id),
    );
    onFiltersChanged({
      ...formikContext.values,
      users: activeUsers.map(user => user.id),
      companies: allCompanies,
    });
  };

  const handleFiltersApply = () => {
    onFiltersChanged(formikContext.values);
    onFiltersApplied();
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={handleFiltersClear}
      onFiltersApply={handleFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection label={intl.formatMessage({ id: 'Statistics.Filters.Company' })}>
        <FilterFieldMultiselectCheckbox
          groupedOptions={companiesForFilter}
          onChange={handleCompaniesSelect}
          value={formikContext.values.companies}
          dataCy="select-companies"
          isInitiallyOpen
        />
      </FiltersSection>
      <FiltersSection label={intl.formatMessage({ id: 'Statistics.Filters.User' })}>
        <FilterFieldMultiselectCheckbox
          groupedOptions={usersForFilter}
          onChange={handleUsersSelect}
          value={formikContext.values.users}
          dataCy="select-users"
        />
      </FiltersSection>
    </FiltersForm>
  );
}

export const getUsersForFilter = (
  usersData: UserListItem[],
  selectedCompanies: number[],
): Group[] =>
  usersData
    .reduce((acc, user) => {
      if (!selectedCompanies.includes(user.company.id)) {
        return acc;
      }

      const groupIndex = acc.findIndex(el => el.groupLabel === user.company.name);
      if (groupIndex !== -1) {
        acc[groupIndex].options.push({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        });
      } else {
        acc.push({
          groupLabel: user.company.name,
          options: [
            {
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            },
          ],
        });
      }
      return acc;
    }, [] as Group[])
    .sort((a, b) => a.groupLabel.localeCompare(b.groupLabel));

export const getCompaniesForFilter = (usersData: UserListItem[]): Option[] =>
  uniqBy(
    usersData.map(user => ({
      label: user.company.name,
      value: user.company.id,
    })),
    'value',
  ).sort((a, b) => a.label.localeCompare(b.label));
