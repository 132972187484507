import React from 'react';
import Highlighter from 'react-highlight-words';
import { css } from '@emotion/css';
import { isNil } from 'lodash';

import { theme as mainTheme } from 'theme';

export const highlightedTextStyle = css`
  background-color: ${mainTheme.colors.whisper};
  color: ${mainTheme.background};
`;

export const highlightableTextStyles = (suffixed?: boolean): string => css`
  ${suffixed ? 'padding-right: 3px' : ''}
`;

interface HighlightSearchProps {
  className?: string;
  searchWords: (string | RegExp)[];
  textToHighlight?: string | null;
  autoEscape?: boolean;
}

export const HighlightSearch = ({
  className,
  searchWords,
  textToHighlight,
  autoEscape,
}: HighlightSearchProps): React.ReactElement | null => {
  if (isNil(textToHighlight)) return null;

  return (
    <Highlighter
      autoEscape={autoEscape || false}
      className={className || highlightableTextStyles(false)}
      highlightClassName={highlightedTextStyle}
      searchWords={searchWords as string[]}
      textToHighlight={textToHighlight}
    />
  );
};
