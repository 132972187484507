import React from 'react';
import styled from '@emotion/styled';

import { Star } from './Star';

const RatingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-row: column;
  gap: 16px;
`;
const StyledStar = styled(Star)`
  cursor: pointer;
`;
interface Props {
  value: number | null;
  onChange: (newValue: number) => void;
}

export const Rate = ({ onChange, value }: Props): React.ReactElement => (
  <RatingWrapper>
    {[1, 2, 3, 4, 5].map(numericRate => (
      <StyledStar
        key={numericRate}
        filled={numericRate <= (value ?? 0)}
        onClick={() => onChange(numericRate)}
        size="40px"
      />
    ))}
  </RatingWrapper>
);
