import { TypedFormattedMessageFactory, useTypedIntlFactory } from 'locale/typedIntl';
import { Language } from 'shared/constants';
import enMessages from './en.json';
import esMessages from './es.json';
import frMessages from './fr.json';
import plMessages from './pl.json';

export type HedgeMessages = typeof enMessages;

export const messages: Record<Language, HedgeMessages> = {
  EN: enMessages,
  ES: esMessages,
  FR: frMessages,
  PL: plMessages,
};

export const useTypedIntl = useTypedIntlFactory<keyof HedgeMessages>();
export const TypedFormattedMessage = TypedFormattedMessageFactory<keyof HedgeMessages>();
export type TypedIntlShape = ReturnType<typeof useTypedIntl>;
