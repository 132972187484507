import React from 'react';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { ErrorCode } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useCreateNonstandardConverter } from 'shared/mutations/nonstandardConverters';
import { parseNonstandardConverter } from 'shared/parsers/parseNonstandardConverter';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../locale/messages';
import {
  NonstandardConverterFormShape,
  NonstandardConvertersForm,
} from '../NonstandardConvertersForm/NonstandardConvertersForm';

const CreateNonstandardConverterComponent = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const createMutation = useCreateNonstandardConverter();
  const handleSubmit = async (values: NonstandardConverterFormShape[]) => {
    const data = values.map(parseNonstandardConverter);
    try {
      await createMutation.mutateAsync(data);
      const messageId =
        data.length > 1
          ? 'NonstandardConvertersForm.Create.Success.Multi'
          : 'NonstandardConvertersForm.Create.Success.Single';
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: messageId }, { count: data.length })));
      navigate('/nonstandard-converters');
    } catch (error) {
      dispatch(
        snackBarPushFailure(intl.formatMessage({ id: 'NonstandardConvertersForm.Create.Failure' })),
      );
    }
  };
  const backendMaterialErrors =
    createMutation.error?.response?.data?.errorCode ===
    ErrorCode.NONSTANDARD_CONVERTER_MATERIAL_NOT_UNIQUE
      ? createMutation.error?.response?.data?.errorData?.materials
      : null;

  return (
    <LoadableContent
      drawContent
      mode={LoadableContent.MODE.FULL}
      loading={createMutation.isLoading}
    >
      <FormContainer>
        <NonstandardConvertersForm
          onSubmit={handleSubmit}
          backendMaterialErrors={backendMaterialErrors}
        />
      </FormContainer>
    </LoadableContent>
  );
};

export const CreateNonstandardConverter = withAlphamartIntlProvider(
  CreateNonstandardConverterComponent,
  messages,
);
