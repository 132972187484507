import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, SuccessResponse } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum AssignHedgeToUsersKeys {
  AssignHedgeToUsers = 'AssignHedgeToUsers',
}

interface Params {
  hedgeId: number;
  usersIds: number[] | null;
}

export const useAssignHedgeToUsers = (
  options?: UseMutationOptions<SuccessResponse, AlphamartHttpError, Params>,
) =>
  useMutation<SuccessResponse, AlphamartHttpError, Params, unknown>(
    async (params: Params) => {
      const response = await getHttpClient().post(
        `${apiHostname}/api/hedges/${params.hedgeId}/assign`,
        {
          usersIds: params.usersIds,
        },
      );

      return response.data;
    },
    { mutationKey: [AssignHedgeToUsersKeys.AssignHedgeToUsers], ...options },
  );
