import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';

import { FieldDatePickerRange } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import { FieldRadioButtonFilters } from 'components/shared/Fields/FieldRadioButton/FieldRadioButton';
import { FilterSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { getDateFormat } from 'helpers/dateTime/dateTime';
import { DEFAULT_FILTERS, FilterableModules, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers/authorize';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { BooleanString } from 'shared/types';
import { fetchFolders } from 'store/foldersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { fetchTypes } from 'store/typesSlice';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface AssayFiltersShape {
  sampleDate?: { from: string | Date | null; to: string | Date | null };
  folder?: string | number | null;
  type?: string | number | null;
  picture?: string | null;
  removed?: BooleanString | null;
  query?: string | null;
}

interface Props {
  onFiltersChanged: (filters: AssayFiltersShape) => void;
  onFiltersApplied: () => void;
}

export const AssaysFilters = ({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const { folders } = useAppSelector(state => state.folders);
  const { types } = useAppSelector(state => state.types);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.ASSAYS]);
  const { language } = useAppSelector(state => state.currentLanguage);
  const { position } = useAppSelector(state => state.position);
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const initialValues: AssayFiltersShape = {
    ...(authorize(PERMISSIONS.ASSAYS.FILTERS_REMOVED) && { removed: 'false' }),
  };
  const formikContext = useFormik<AssayFiltersShape>({
    initialValues: { ...savedFilters.data, ...initialValues },
    onSubmit: () => {},
  });
  const { setFieldValue, setValues, values } = formikContext;

  useEffect(() => {
    if (position?.latitude && position?.longitude) {
      dispatch(fetchFolders());
      dispatch(fetchTypes());
    }
  }, [position?.latitude, position?.longitude]);

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.assays);
    onFiltersChanged(DEFAULT_FILTERS.assays);
  };

  const onSampleDateClear = () => {
    setFieldValue('sampleDate', DEFAULT_FILTERS.assays.sampleDate);
    onFiltersChanged({ ...values, sampleDate: DEFAULT_FILTERS.assays.sampleDate });
  };

  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };

  const filterMapper = ({ id: value, name: label }) => ({ label, value });
  const folderOptions = useMemo(() => folders?.map(filterMapper), [folders]);
  const typesOptions = useMemo(
    () => types?.map(({ name, id }) => ({ id, name: `${name}` }))?.map(filterMapper),
    [types],
  );

  const hasPicturesOptions = [
    { label: intl.formatMessage({ id: 'AssaysList.Filters.PicturesYes' }), value: 'YES' },
    { label: intl.formatMessage({ id: 'AssaysList.Filters.PicturesNo' }), value: 'NO' },
    { label: intl.formatMessage({ id: 'AssaysList.Filters.PicturesOld' }), value: 'OLD' },
  ];

  const removedOptions = [
    { label: intl.formatMessage({ id: 'Global.Yes' }), value: 'true' },
    { label: intl.formatMessage({ id: 'Global.No' }), value: 'false' },
  ];

  const sampleDateClearable = Boolean(values?.sampleDate?.from || values?.sampleDate?.to);
  const dateFormat = getDateFormat(language);

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection
        sectionName="folderType"
        label={intl.formatMessage({ id: 'AssaysList.Filters.FolderType' })}
      >
        <FilterSelect
          name="folder"
          options={folderOptions}
          label={intl.formatMessage({ id: 'AssaysList.Filters.Folder' })}
          value={formikContext.values.folder}
          data-cy="filter-folder"
        />

        <FilterSelect
          name="type"
          options={typesOptions}
          label={intl.formatMessage({ id: 'AssaysList.Filters.Type' })}
          value={formikContext.values.type}
          data-cy="filter-type"
        />
      </FiltersSection>
      <FiltersSection
        sectionName="sampleDate"
        label={intl.formatMessage({ id: 'AssaysList.Filters.SampleDate' })}
        clearable={sampleDateClearable}
        onSectionClear={onSampleDateClear}
      >
        <FieldDatePickerRange
          name="sampleDate"
          value={formikContext.values.sampleDate}
          data-cy="filter-sample-date"
          dateFormat={dateFormat}
        />
      </FiltersSection>
      <FiltersSection
        sectionName="picture"
        label={intl.formatMessage({ id: 'AssaysList.Filters.ContainPictures' })}
      >
        <FieldRadioButtonFilters
          onChange={formikContext.handleChange}
          name="picture"
          options={hasPicturesOptions}
          value={formikContext.values.picture}
          data-cy="filter-picture"
          onClear={() => setFieldValue('picture', null)}
        />
      </FiltersSection>
      {authorize(PERMISSIONS.ASSAYS.FILTERS_REMOVED) && (
        <FiltersSection
          sectionName="removed"
          label={intl.formatMessage({ id: 'AssaysList.Filters.Removed' })}
        >
          <FieldRadioButtonFilters
            onChange={formikContext.handleChange}
            name="removed"
            options={removedOptions}
            value={formikContext.values.removed}
            data-cy="filter-removed"
          />
        </FiltersSection>
      )}
    </FiltersForm>
  );
};
