import React from 'react';

import { Badge } from 'components/shared/Badge';
import { TextButton } from 'components/shared/Buttons';
import { Details, DetailsFooter, DetailsSection } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { CompanyTypes, PERMISSIONS, SHARED } from 'shared/constants';
import { resolveStatusColor, useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate, useCompanyActions, useCurrentUser } from 'shared/hooks';
import { useLanguage } from 'shared/hooks/useLanguage';
import { Company, UsageThresholds } from 'shared/types';
import { CompanyDetailsThresholds } from './CompanyDetailsThresholds';
import { useCompanyItemActions } from '../hooks/useCompanyItemActions';
import { CompanyMessages, useTypedIntl } from '../locale/messages';

type Props = {
  company: Company;
};

const CompanyDetails = ({ company }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const language = useLanguage();
  const { companiesThresholds } = useCurrentUser();
  const filterCompany: UsageThresholds | undefined = companiesThresholds?.find(
    (item: UsageThresholds) => item.assignedTo === company?.id,
  );
  const canSeeUsageThresholds = authorize(PERMISSIONS.COMPANIES.EDIT_USAGES_THRESHOLDS);
  const { actionsPending } = useCompanyActions();
  const actions = useCompanyItemActions(company);

  if (!company) return null;

  let detailsTemplate = ['name name status'];
  let detailsFields = [
    {
      name: 'name',
      label: intl.formatMessage({ id: 'CompanyDetails.Name' }),
      value: company.name,
    },
    {
      name: 'status',
      label: intl.formatMessage({ id: 'CompanyDetails.Status' }),
      value: (
        <Badge color={resolveStatusColor(company.status.toUpperCase())}>
          {intl.formatMessage({
            id: `Global.Status.${company.status.toUpperCase()}` as keyof CompanyMessages,
          })}
        </Badge>
      ),
    },
  ];

  if (authorize(PERMISSIONS.COMPANIES.DETAILS.TYPE)) {
    detailsTemplate = ['name status type'];
    detailsFields = [
      ...detailsFields,
      {
        name: 'type',
        label: intl.formatMessage({ id: 'CompanyDetails.Type' }),
        value: intl.formatMessage({ id: `CompanyDetails.Type.${company.type}` }),
      },
    ];
  }

  if (company.parentCompany && authorize(PERMISSIONS.COMPANIES.DETAILS.PARENT_COMPANY)) {
    detailsTemplate = ['name status type', 'parentCompany parentCompany parentCompany'];
    detailsFields = [
      ...detailsFields,
      {
        name: 'parentCompany',
        label: intl.formatMessage({ id: 'CompanyDetails.ParentCompany' }),
        value: company.parentCompany.name,
      },
    ];
  }

  if (authorize(PERMISSIONS.COMPANIES.DETAILS.SUBDOMAIN)) {
    detailsTemplate = [
      'name status type',
      company.parentCompany ? 'parentCompany parentCompany subdomain' : 'subdomain . .',
    ];
    detailsFields = [
      ...detailsFields,
      {
        name: 'subdomain',
        label: intl.formatMessage({ id: 'CompanyDetails.Subdomain' }),
        value: company.subdomain,
      },
    ];
  }

  const primaryContacts = company.users.length
    ? company.users
    : [{ id: 1, firstName: SHARED.LONG_DASH, lastName: '', email: SHARED.LONG_DASH }];

  return (
    <LoadableContent loading={actionsPending} mode={LoadableContent.MODE.FULL} drawContent>
      <Details title={company.name} backUrl="/companies" actions={actions}>
        <DetailsSection
          title={intl.formatMessage({ id: 'CompanyDetails.Details' })}
          template={detailsTemplate}
          fields={detailsFields}
        />
        <DetailsSection
          title={intl.formatMessage({ id: 'CompanyDetails.Address' })}
          template={['country state city', 'street zipCode zipCode']}
          fields={[
            {
              name: 'country',
              label: intl.formatMessage({ id: 'CompanyDetails.Country' }),
              value: company.country.name[language],
            },
            {
              name: 'state',
              label: intl.formatMessage({ id: 'CompanyDetails.State' }),
              value: intl.messages[`Global.Provinces.${company.state}`]
                ? intl.formatMessage({
                    id: `Global.Provinces.${company.state}` as keyof CompanyMessages,
                  })
                : company.state,
            },
            {
              name: 'city',
              label: intl.formatMessage({ id: 'CompanyDetails.City' }),
              value: company.city,
            },
            {
              name: 'street',
              label: intl.formatMessage({ id: 'CompanyDetails.Street' }),
              value: company.street,
            },
            {
              name: 'zipCode',
              label: intl.formatMessage({ id: 'CompanyDetails.ZipCode' }),
              value: company.zipCode,
            },
          ]}
        />

        <DetailsSection
          title={intl.formatMessage({ id: 'CompanyDetails.Terms' })}
          fields={[
            {
              name: 'metalsReturnFinanceRate',
              label: intl.formatMessage({ id: 'CompanyDetails.MetalsReturnFinanceRate' }),
              value: `${company.terms.metalsReturnFinanceRate.toFixed(2)} %`,
            },
            {
              name: 'metalsReturnTermInDays',
              label: intl.formatMessage({ id: 'CompanyDetails.MetalsReturnTermInDays' }),
              value: company.terms.metalsReturnTermInDays,
            },
            {
              name: 'treatmentChargePerPound',
              label: intl.formatMessage({ id: 'CompanyDetails.TreatmentChargePerPound' }),
              value: formatCurrency(company.terms.treatmentChargePerPound, 'USD'),
            },
            {
              name: 'platinumReturnRate',
              label: intl.formatMessage({ id: 'CompanyDetails.PlatinumReturnRate' }),
              value: `${company.terms.platinumReturnRate.toFixed(2)} %`,
            },
            {
              name: 'palladiumReturnRate',
              label: intl.formatMessage({ id: 'CompanyDetails.PalladiumReturnRate' }),
              value: `${company.terms.palladiumReturnRate.toFixed(2)} %`,
            },
            {
              name: 'rhodiumReturnRate',
              label: intl.formatMessage({ id: 'CompanyDetails.RhodiumReturnRate' }),
              value: `${company.terms.rhodiumReturnRate.toFixed(2)} %`,
            },
          ]}
        />
        {primaryContacts.map((contact, index) => (
          <DetailsSection
            key={contact.id}
            title={index === 0 && intl.formatMessage({ id: 'CompanyDetails.PrimaryContacts' })}
            template={[`contactName${index} contactEmail${index} contactEmail${index}`]}
            fields={[
              {
                name: `contactName${index}`,
                label: intl.formatMessage({ id: 'CompanyDetails.ContactName' }),
                value: contact.firstName !== SHARED.LONG_DASH && (
                  <TextButton onClick={() => navigate(`/users/${contact.id}`)}>
                    {`${contact.firstName} ${contact.lastName}`}
                  </TextButton>
                ),
              },
              {
                name: `contactEmail${index}`,
                label: intl.formatMessage({ id: 'CompanyDetails.ContactEmail' }),
                value: contact.email,
              },
            ].flat()}
          />
        ))}

        <DetailsSection
          title={intl.formatMessage({ id: 'CompanyDetails.Notes' })}
          template={['notes notes notes']}
          fields={[
            {
              name: 'notes',
              label: false,
              value: company.note,
            },
          ]}
        />
        {authorize(PERMISSIONS.COMPANIES.MAX_ASSAY_USERS) &&
          company.type === CompanyTypes.ASSAY && (
            <DetailsSection
              title={intl.formatMessage({ id: 'CompanyDetails.Users' })}
              template={['maxAssayUsers maxAssayUsers maxAssayUsers']}
              fields={[
                {
                  name: 'maxAssayUsers',
                  label: intl.formatMessage({ id: 'CompanyDetails.MaxAssayUsers' }),
                  value: company.maxAssayUsers,
                },
              ]}
            />
          )}

        {canSeeUsageThresholds ? <CompanyDetailsThresholds company={filterCompany} /> : null}

        <DetailsFooter
          fields={[
            {
              name: 'createdBy',
              label: intl.formatMessage({ id: 'Global.CreatedBy' }),
              value:
                company.createdBy && `${company.createdBy.firstName} ${company.createdBy.lastName}`,
            },
            {
              name: 'createdAt',
              label: intl.formatMessage({ id: 'Global.CreatedOn' }),
              value: formatDate(company.createdAt),
            },
            {
              name: 'updatedBy',
              label: intl.formatMessage({ id: 'Global.UpdatedBy' }),
              value:
                company.updatedBy && `${company.updatedBy.firstName} ${company.updatedBy.lastName}`,
            },
            {
              name: 'updatedAt',
              label: intl.formatMessage({ id: 'Global.UpdatedOn' }),
              value: company.updatedAt && formatDate(company.updatedAt),
            },
            {
              name: 'termsUpdatedBy',
              label: intl.formatMessage({ id: 'CompanyDetails.UpdatedBy' }),
              value:
                company.terms?.updatedBy &&
                `${company.terms.updatedBy.firstName} ${company.terms.updatedBy.lastName}`,
            },
            {
              name: 'termsUpdatedAt',
              label: intl.formatMessage({ id: 'CompanyDetails.UpdatedOn' }),
              value: company.terms?.updatedAt && formatDate(company.terms.updatedAt),
            },
          ]}
        />
      </Details>
    </LoadableContent>
  );
};

export { CompanyDetails };
