import React, { useState } from 'react';
import styled from '@emotion/styled';

import { TextButton } from 'components/shared/Buttons';
import { Tooltip } from 'components/shared/Tooltip/Tooltip';
import { FilterableModules } from 'shared/constants';
import { useClickAway } from 'shared/hooks';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { TypedFormattedMessage as FormattedMessage } from '../../locale/messages';

const Container = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media ${MEDIA_QUERY.MAX_MD} {
    gap: 5px;
    margin-right: 5px;
  }
`;

const distancesInMiles = [100, 200, 500] as const;
type Distance = (typeof distancesInMiles)[number];

export interface FrequentFlyersFiltersShape {
  distanceInMiles: Distance;
}

const Label = ({ distance }: { distance: Distance }): React.ReactElement => {
  const dispatch = useAppDispatch();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.FREQUENT_FLYERS]);

  const handleSelect = (value: Distance) => {
    dispatch(
      saveFilters({
        filterKey: FilterableModules.FREQUENT_FLYERS,
        values: { ...savedFilters.data, distanceInMiles: value },
      }),
    );
  };

  const [visible, setVisible] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    setVisible(false);
  });
  const active = savedFilters.data.distanceInMiles === distance;

  return (
    <div ref={ref}>
      <Tooltip
        trigger={['hover', 'focus']}
        tooltip={<FormattedMessage id="FrequentFlyersList.Info" values={{ value: distance }} />}
        bgColor={theme.colors.mineShaftLighter}
        hideArrow
        placement="bottom"
        visible={visible}
        onVisibleChange={setVisible}
      >
        <TextButton
          color={!active ? theme.colors.alphaGray : undefined}
          isActive={active}
          onClick={() => handleSelect(distance)}
        >
          <FormattedMessage
            id="FrequentFlyersList.Filters.GeolocationDistance"
            values={{ value: distance }}
          />
        </TextButton>
      </Tooltip>
    </div>
  );
};

export const SelectDistance = (): React.ReactElement => (
  <Container>
    {distancesInMiles.map(distance => (
      <Label key={distance} distance={distance} />
    ))}
  </Container>
);
