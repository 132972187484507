import React from 'react';
import { capitalize } from 'lodash';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { Section, SectionChild } from 'components/shared/forms/Section/Section';
import { FeedTypes } from 'shared/constants';
import { Metals } from 'shared/types';
import { UpdateCompanyMetalsFormData } from './UpdateMetalPrices';
import { SettingMessages, TypedIntlShape } from '../locale/messages';

interface SingleMetalSettingsProps {
  metal: Metals;
  intl: TypedIntlShape;
  getErrors: (field: Metals, key: string) => React.ReactNode;
  sources: { label: string; value: FeedTypes }[];
  values: UpdateCompanyMetalsFormData;
  handleBlur: (e: React.FocusEvent) => void;
  handleChange: (e: React.ChangeEvent) => void;
  setActiveInput: (field: string) => void;
  activeInput: string;
  // eslint-disable-next-line react/no-unused-prop-types
  sectionName?: string;
}

export const SingleMetalSettings = ({
  metal,
  intl,
  getErrors,
  sources,
  values,
  handleBlur,
  handleChange,
  setActiveInput,
  activeInput,
}: SingleMetalSettingsProps): React.ReactElement => (
  <Section sectionName={`singleMetallSettings${metal}`} template={[`${metal}.type ${metal}.value`]}>
    <FieldSelect
      label={intl.formatMessage({
        id: `Global.Metals.${capitalize(metal)}` as keyof SettingMessages,
      })}
      name={`${metal}.type`}
      value={values[metal].type}
      error={getErrors(metal, 'type')}
      options={sources}
      required
    />
    {values[metal].type === FeedTypes.MARKET_FEED_ADJUSTED && (
      <FieldInput
        label={intl.formatMessage({ id: 'Settings.MetalPrices.Adjustment' })}
        name={`${metal}.value`}
        onChange={e => {
          handleBlur(e as unknown as React.FocusEvent);
          handleChange(e);
        }}
        onBlur={handleBlur}
        value={values[metal].value}
        error={getErrors(metal, 'value')}
        required={values[metal].type === FeedTypes.MARKET_FEED_ADJUSTED}
        prefix="$"
        data-cy={`${metal}-value`}
      />
    )}
    {values[metal].type === FeedTypes.MANUAL_ENTRY && (
      <SectionChild name={`${metal}.value`}>
        <FieldInput
          label={intl.formatMessage({ id: 'Settings.MetalPrices.ManualEntryPrice' })}
          name={`${metal}.value`}
          onChange={handleChange}
          onBlur={() => setActiveInput('')}
          onFocus={e => setActiveInput(e.target.name)}
          protection={{
            hide: activeInput === `${metal}.confirm`,
            copy: true,
            paste: true,
          }}
          autoComplete="off"
          value={values[metal].value}
          error={getErrors(metal, 'value')}
          prefix="$"
          data-cy={`${metal}-value`}
        />
        <FieldInput
          label={intl.formatMessage({ id: 'Settings.MetalPrices.ManualEntryPriceConfirm' })}
          name={`${metal}.confirm`}
          onChange={handleChange}
          onBlur={() => setActiveInput('')}
          onFocus={() => setActiveInput(`${metal}.confirm`)}
          autoComplete="off"
          protection={{
            hide: activeInput === `${metal}.value`,
            copy: true,
            paste: true,
          }}
          value={values[metal].confirm}
          error={getErrors(metal, 'confirm')}
          disabled={!values[metal].value}
          prefix="$"
          data-cy={`${metal}-confirm`}
        />
      </SectionChild>
    )}
  </Section>
);
