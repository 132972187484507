import { createSlice } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';

import { apiHostname, ErrorCode } from 'shared/constants';
import { serializeLocationHeader } from 'shared/helpers';
import { AlphamartHttpError, ConverterListItem, Coords } from 'shared/types';
import { getDateString } from 'shared/utils/getDateString';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { blockUserAction } from './auth';

let cancelTokenSource: CancelTokenSource;

interface ConvertersListState extends GenericStoreSlice {
  count: number;
  list: ConverterListItem[];
}

interface FetchConvertersFiltersParam {
  query?: string;
  picture?: string | null;
  years?: {
    from: string | null;
    to: string | null;
  };
  addedAt?: {
    from: Date | null;
    to: Date | null;
  };
  make?: string | null;
  model?: string | null;
  converterType?: number | null;
  showComingSoon?: boolean;
  showCounterfeit?: boolean;
  searchSession?: string;
}

const convertersListSlice = createSlice<
  ConvertersListState,
  GenericStoreReducer<ConvertersListState>
>({
  name: 'convertersList',
  initialState: {
    list: [],
    count: 0,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      list: payload ? payload.data : [],
      count: payload ? payload.count : 0,
    })),
  },
});

export const {
  success: fetchConvertersSuccessAction,
  failure: fetchConvertersFailureAction,
  pending: fetchConvertersAction,
} = convertersListSlice.actions;

export default convertersListSlice.reducer;

export const fetchConverters =
  (
    page = 1,
    pageSize = 12,
    filters: FetchConvertersFiltersParam = {},
    position: Coords | null = null,
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      cancelTokenSource?.cancel('Concurrent operation canceled');
      cancelTokenSource = httpClient.getCancelToken();

      dispatch(fetchConvertersAction());

      const headers = position ? { 'user-location': serializeLocationHeader(position) } : undefined;
      const { data } = await httpClient.get(`${apiHostname}/api/converters`, {
        params: {
          page,
          pageSize,
          query: filters.query ?? '',
          make: filters.make ?? '',
          model: filters.model ?? '',
          converterType: filters.converterType,
          searchSession: filters.searchSession,
          comingSoon: filters.showComingSoon ?? false,
          showCounterfeit: filters.showCounterfeit ?? false,
          ...(filters.picture && { picture: filters.picture }),
          ...(filters.years?.from && { yearFrom: filters.years?.from }),
          ...(filters.years?.to && { yearTo: filters.years?.to }),
          ...(filters.addedAt?.from && { addedFrom: getDateString(filters.addedAt.from) }),
          ...(filters.addedAt?.to && { addedTo: getDateString(filters.addedAt.to) }),
        },
        headers,
        cancelToken: cancelTokenSource.token,
      });
      dispatch(fetchConvertersSuccessAction(data));
    } catch (error) {
      if (httpClient.isCancel(error)) return;

      if (
        (error as AlphamartHttpError).response?.data?.errorCode ===
        ErrorCode.USER_BLOCKED_OFF_LIMIT_AREA_ENTERED
      )
        dispatch(blockUserAction());

      dispatch(fetchConvertersFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };
