import React, { memo } from 'react';

import { SpinButton } from 'components/shared/SpinButton/SpinButton';
import { useEditItemsGroupInShoppingCart } from 'shared/mutations';
import { ShoppingCartItemsGroup } from 'shared/types';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { useTypedIntl } from '../../locale/messages';

interface Props {
  group: ShoppingCartItemsGroup;
  onUpdate(): Promise<void>;
}

const UpdateGroupCount = ({ group, onUpdate }: Props): React.ReactElement => {
  const editItemsGroupInCart = useEditItemsGroupInShoppingCart();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();

  const onUpdateGroupCount = async (value: number) => {
    try {
      await editItemsGroupInCart.mutateAsync({
        id: group.id,
        unitsCount: value,
      });
      await onUpdate();
    } catch (e) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  return (
    <SpinButton
      initialValue={group.unitsCount}
      min={1}
      onChange={onUpdateGroupCount}
      disabled={editItemsGroupInCart.isLoading}
      data-cy={`shopping-cart-item-${group.id}-count`}
    />
  );
};

export default memo(UpdateGroupCount);
