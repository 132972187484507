import { isNil } from 'lodash';

import { Language, SHARED } from 'shared/constants';

export const formatCurrency = (
  number?: number | null,
  currency = 'USD',
  minimumFractionDigits = 2,
  language: Language | null = null,
): string => {
  if (isNil(number)) {
    return SHARED.LONG_DASH;
  }

  return new Intl.NumberFormat(language || window.navigator.language, {
    minimumFractionDigits,
    style: 'currency',
    currency,
  }).format(number);
};
