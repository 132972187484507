import React, { Children, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
// eslint-disable-next-line import/no-extraneous-dependencies
import { compose } from 'redux';

import { fillGaps, formatTemplate, sanitizeRow, sanitizeTemplate } from 'shared/helpers';
import { MEDIA_QUERY, Theme, theme as mainTheme } from 'theme';

interface SectionChildProps {
  name: string;
  area?: string;
  placeholder?: string;
  onFocus?: (e?: React.FocusEvent) => void;
  children?: React.ReactNode;
}
type SectionChildren = React.ReactElement<SectionChildProps>;
type SectionChildrenOrFalsy = SectionChildren | false | null;
export interface SectionProps {
  childrenNames?: string[];
  className?: string;
  template?: string[];
  children: SectionChildrenOrFalsy | SectionChildrenOrFalsy[];
  setActive?: (sectionName: string) => void;
  sectionName: string;
  singleFieldSection?: boolean;
  extraInformation?: boolean;
  activeSection?: string | null;
  label?: string | React.ReactElement | React.ReactNode;
}
export const SectionCell = styled.div<{
  area?: string;
  theme?: Theme;
  singleFieldSection?: boolean;
  extraInformation?: boolean;
  placeholderCell?: boolean;
}>`
  grid-area: ${({ area }) => (area ? sanitizeRow(area) : 'unset')};

  &:not(:last-of-type):not(:nth-last-of-type(2)) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-area: unset;

    &:nth-last-of-type(2) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
    }
  }

  ${({ singleFieldSection, placeholderCell }) =>
    !singleFieldSection &&
    `
    padding: 0 24px 24px 24px;

    @media ${MEDIA_QUERY.XL} {
      padding: 0 64px 24px 32px
    }

    @media ${MEDIA_QUERY.MAX_MD} {
      display: ${placeholderCell ? 'none' : 'block'};
    }
  `};

  ${({ extraInformation }) =>
    extraInformation &&
    `
    &:nth-last-of-type(3) {
      border-bottom: none!important;
    }

    &:nth-last-of-type(1) {
      background-color: ${mainTheme.colors.mineShaft};
      padding-left: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding-right: 0;
      padding-bottom: 0;

    &:nth-last-of-type(2) {
      border-bottom: none;
    }
  }
    `}
`;

const FieldSet = styled.fieldset`
  padding: 0;
  border: none;

  @media ${MEDIA_QUERY.MAX_SM} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Section = React.memo<SectionProps>(
  ({
    childrenNames = [],
    className,
    template,
    children,
    setActive,
    sectionName,
    singleFieldSection = false,
    extraInformation,
  }) => {
    const contentRef = useRef(null);
    const placeholders = useMemo(() => {
      const placeholderCount = template?.length
        ? template
            .filter(row => row.split(' ').some(name => childrenNames.includes(name)))
            .flatMap(row => row.split(' '))
            .filter(name => !childrenNames.includes(name)).length
        : childrenNames?.length % 2;

      return [...new Array(placeholderCount)].map(() => ({
        placeholderCell: true,
        component: <div />,
      }));
    }, [template, childrenNames]);

    const filteredChildren = useMemo(
      () =>
        (React.Children.toArray(children).filter(Boolean) as SectionChildren[]).map(child => ({
          component: child,
          placeholderCell: false,
        })),
      [children],
    );
    const childrenPresent = !!filteredChildren.length;

    return (
      <FieldSet>
        <div className={className} ref={contentRef}>
          {childrenPresent &&
            [
              extraInformation
                ? [
                    ...filteredChildren.slice(0, filteredChildren.length - 1),
                    ...placeholders,
                    filteredChildren[filteredChildren.length - 1],
                  ]
                : [...filteredChildren, ...placeholders],
            ]
              .flat(2)
              .map((child, index) =>
                child.component.props.area ? (
                  React.cloneElement(child.component, {
                    onFocus: e => {
                      setActive?.(sectionName);
                      if (child.component.props.onFocus) child.component.props.onFocus(e);
                    },
                  })
                ) : (
                  <SectionCell
                    singleFieldSection={singleFieldSection}
                    extraInformation={extraInformation}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    area={
                      template?.length
                        ? child.component.props.area ?? child.component.props.name
                        : null
                    }
                    placeholderCell={child.placeholderCell}
                  >
                    {React.cloneElement(child.component, {
                      onFocus: e => {
                        setActive?.(sectionName);
                        if (child.component.props.onFocus) child.component.props.onFocus(e);
                      },
                    })}
                  </SectionCell>
                ),
              )}
        </div>
      </FieldSet>
    );
  },
);

const StyledSection = styled(Section)<{
  theme?: Theme;
  sectionName?: string;
  activeSection?: string | null;
}>`
  position: relative;
  border-radius: 8px;
  background-color: ${({ sectionName, activeSection, singleFieldSection, theme }) => {
    if (singleFieldSection) return '';
    if (sectionName === activeSection) return theme.colors.mineShaft;

    return theme.colors.codGray;
  }};
  &::before,
  &::after {
    content: '';
    position: absolute;
    right: -1px;
    bottom: -1px;
  }
  &::before {
    width: 2px;
    height: 100%;
  }
  &::after {
    height: 2px;
    width: 100%;
  }

  display: ${({ singleFieldSection }) => (singleFieldSection ? 'block' : 'grid')};
  grid-template-columns: 1fr;
  gap: 1px;

  grid-template-columns: 1fr 1fr;
  grid-template-areas: ${({ template }) => (template ? formatTemplate(template) : 'unset')};

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: 1fr;
    grid-template-areas: unset;
  }
`;

const WrappedSection = ({ children, template, ...props }: SectionProps): React.ReactElement => {
  const parsedChildren = useMemo<SectionChildren[]>(
    () =>
      Children.toArray(children)
        .filter(child => !!child)
        .flat() as SectionChildren[],
    [children],
  );

  const childrenNames = useMemo(
    () => parsedChildren.filter(child => !!child).map(({ props: { name, area } }) => area ?? name),
    [parsedChildren],
  );

  const parsedTemplate = useMemo(
    () => (template ?? []).filter(row => row.split(' ').some(t => childrenNames.includes(t))),
    [template, childrenNames],
  );

  return (
    <StyledSection
      {...props}
      childrenNames={sanitizeTemplate(childrenNames)}
      template={compose(sanitizeTemplate, fillGaps)(parsedTemplate)}
    >
      {parsedChildren}
    </StyledSection>
  );
};

export const SectionChild = (props: SectionChildProps): React.ReactElement => <div {...props} />;
export { WrappedSection as Section };
