import React, { useMemo } from 'react';

import basketIcon from 'assets/images/icons/Basket.svg';
import iconClose from 'assets/images/icons/Close.svg';
import iconConfirm from 'assets/images/icons/confirm.svg';
import { Badge } from 'components/shared/Badge';
import { Icon } from 'components/shared/Buttons';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { Price } from 'components/shared/Price/Price';
import { ModalFormType, SHARED } from 'shared/constants';
import { useCurrentUser, useExtendedTheme, useMediaQuery } from 'shared/hooks';
import { Converter } from 'shared/types';
import { showModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import {
  averagePriceInfo,
  averageSelectionStyles,
  badgeStyles,
  HedgeInUseBadge,
  highlightStyles,
  mobileButtonStyle,
  selectionButtonStyle,
} from './AveragePrice.styles';
import { AddToCartButton } from './ConverterList.styles';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

interface Props {
  enabled?: boolean;
  onAverageModeToggle?: (e?: React.MouseEvent<HTMLElement>) => void;
  onSelectAll?: (e?: React.MouseEvent<HTMLElement>) => void;
  onClear?: (e?: React.MouseEvent<HTMLElement>) => void;
  converters?: Partial<Converter>[];
}
export const AveragePrice = ({
  enabled,
  onAverageModeToggle,
  onSelectAll,
  onClear,
  converters = [],
}: Props): React.ReactElement => {
  const { isVisible: isKeyboardVisible } = useAppSelector(({ virtualKeyboard }) => virtualKeyboard);
  const currentUser = useCurrentUser();
  const { pdHedgeUsed, ptHedgeUsed, rhHedgeUsed } = currentUser.prices;
  const hedgePriceInUse = [pdHedgeUsed, ptHedgeUsed, rhHedgeUsed].some(hedge => hedge);
  const count = converters?.filter(p => !p.converterGroup?.converters?.length).length;
  const dispatch = useAppDispatch();

  const allHaveSameIdentifier =
    converters.length >= 2 &&
    converters.every(cv => cv.converterIdentifier === converters[0].converterIdentifier);
  const theme = useExtendedTheme();

  const addItemsToCart = () => {
    dispatch(
      showModalForm({
        modalType: ModalFormType.AddShoppingCartItems,
        params: { converters: converters as Converter[] },
      }),
    );
  };

  const averagePrice = useMemo(() => {
    const groupPricesSums = converters
      ?.filter(({ converterGroup, price }) => !!converterGroup && price!.marketPrice !== null)
      ?.reduce((groups, { converterGroup, price }) => {
        groups.set(
          converterGroup,
          (groups.get(converterGroup) ?? 0) +
            Math.max(hedgePriceInUse ? price!.hedgePrice! : price!.marketPrice!, 0),
        );
        return groups;
      }, new Map());

    const groupConverterPrices = [...groupPricesSums.values()];
    const standaloneConverterPrices = converters
      ?.filter(({ converterGroup }) => !converterGroup)
      ?.map(({ price }) => (hedgePriceInUse ? price!.hedgePrice : price!.marketPrice))
      ?.filter(price => price !== null)
      ?.map(price => Math.max(price!, 0));

    if (!groupConverterPrices?.length && !standaloneConverterPrices?.length) return null;

    const prices = [...groupConverterPrices, ...standaloneConverterPrices];
    return (prices.reduce((sum, price) => sum + price, 0) / prices.length).toFixed(2);
  }, [converters]);

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_MD);

  return (
    <div className={averageSelectionStyles(!!enabled, theme, isKeyboardVisible)}>
      {enabled ? (
        <>
          <div className={averagePriceInfo(enabled)}>
            <div>
              <Badge
                color={isPhone ? theme.colors.doveGray : theme.colors.mineShaftLightest}
                className={badgeStyles}
              >
                <FormattedMessage id="ConvertersList.Average.Badge" values={{ count }} />
              </Badge>
              <FormattedMessage id="ConvertersList.Average.Title" />
              <span className={highlightStyles(theme)}>
                {count > 0 && averagePrice ? (
                  <Price
                    value={Number(averagePrice)}
                    language={currentUser.language}
                    currency={currentUser.currency}
                  />
                ) : (
                  SHARED.LONG_DASH
                )}
              </span>
            </div>
            {hedgePriceInUse && (
              <HedgeInUseBadge>
                <Badge
                  className={badgeStyles}
                  color={isPhone ? theme.colors.doveGray : theme.colors.mineShaftLightest}
                >
                  <FormattedMessage id="ConvertersList.Average.BasedOnHedge" />
                </Badge>
              </HedgeInUseBadge>
            )}
          </div>
          <AddToCartButton
            disabled={!allHaveSameIdentifier}
            icon={basketIcon}
            onClick={addItemsToCart}
            data-cy="add-to-cart-multi"
          />
          <AppButton
            onClick={onSelectAll}
            styleType="neutral-empty"
            className={selectionButtonStyle}
          >
            <FormattedMessage id="ConvertersList.Average.SelectAll" />
          </AppButton>
          <AppButton onClick={onClear} styleType="neutral-empty" className={selectionButtonStyle}>
            <FormattedMessage id="ConvertersList.Average.Clear" />
          </AppButton>
          <Icon icon={iconClose} color={theme.colors.white} onClick={onAverageModeToggle} />
        </>
      ) : (
        <>
          <span className={averagePriceInfo(!!enabled)}>
            <FormattedMessage id="ConvertersList.Average.SelectPrompt" />
            <span className={highlightStyles(theme)}>
              <FormattedMessage id="ConvertersList.Average.Placeholder" />
            </span>
          </span>
          <AppButton
            onClick={onAverageModeToggle}
            className={mobileButtonStyle}
            styleType="neutral-empty"
          >
            {isPhone ? (
              <FormattedMessage id="ConvertersList.Average.MobileButton" />
            ) : (
              <>
                <Icon icon={iconConfirm} color={theme.colors.white} />
                <FormattedMessage id="ConvertersList.Average.Select" />
              </>
            )}
          </AppButton>
        </>
      )}
    </div>
  );
};
