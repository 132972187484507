import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState(
    document.documentElement.clientWidth || window.innerWidth,
  );

  const handleWidthChange = debounce(() => {
    setWindowWidth(document.documentElement.clientWidth || window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', handleWidthChange);
    return () => {
      window.removeEventListener('resize', handleWidthChange);
    };
  }, []);

  return windowWidth;
};
