import { isNil, uniq } from 'lodash';

import { ScatterData } from './ScatterChart';

const inRangeInclusive = (value: number, min: number, max: number): boolean =>
  min <= value && value <= max;

const compareFn = (a: number, b: number): number => a - b;

export const getZoomedTicks = (ticks: number[], areaStart: number, areaEnd: number): number[] =>
  uniq([
    ...ticks.filter(tick => inRangeInclusive(tick, areaStart, areaEnd)),
    areaStart,
    areaEnd,
  ]).sort(compareFn);

export const getZoomedData = (
  data: ScatterData[],
  xTicks: number[],
  yTicks: number[],
): ScatterData[] =>
  data.map(el => ({
    ...el,
    data: el.data.filter(singleData => {
      const isInXAxis = inRangeInclusive(singleData.x, Math.min(...xTicks), Math.max(...xTicks));
      const isInYAxis = inRangeInclusive(singleData.y, Math.min(...yTicks), Math.max(...yTicks));

      return isInXAxis && isInYAxis;
    }),
  }));

export const getSelectedTick = (ticks: number[], val: number, startPoint?: number): number => {
  let selected = 0;
  for (let i = 1; i < ticks.length; i++) {
    if (inRangeInclusive(val, ticks[i - 1], ticks[i])) {
      const isStartingMoving = isNil(startPoint);
      const indexToSelect = isStartingMoving || val < startPoint ? i - 1 : i;
      selected = ticks[indexToSelect];
    }
  }
  return selected;
};
