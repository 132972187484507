import React from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { LoadableContent } from 'components/shared/Loader';
import { ModalButtonsStyle, ReactModalStyle } from 'components/shared/Modals.styles';
import { requestCompanyHedgePrice } from 'store/requestCompanyHedgePriceSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { layers, theme } from 'theme';
import { useTypedIntl } from '../locale/messages';

interface RequestHedgePriceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalContent = styled.div`
  background-color: ${theme.colors.mineShaft};
  padding: 24px 32px;
  border-radius: 12px;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-top: 16px;
`;
const ModalHeader = styled.p`
  text-align: center;
  margin: 0;
`;

const getValidationSchema = () =>
  yup.object().shape({
    note: yup.string(),
  });

export const RequestHedgePriceModal = ({
  isOpen,
  onClose,
}: RequestHedgePriceModalProps): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const requestCompanyHedgeState = useAppSelector(state => state.requestCompanyHedgePrice);
  const formikContext = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: getValidationSchema(),
    // eslint-disable-next-line
    onSubmit: () => {},
  });
  const handleConfirmClick = async () => {
    try {
      await dispatch(requestCompanyHedgePrice(formikContext.values.note));
      onClose();
      formikContext.setFieldValue('note', '');
      dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'Global.RequestHedgePrices.Success' })),
      );
    } catch {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };
  const handleCancelClick = () => {
    formikContext.setFieldValue('note', '');
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={ReactModalStyle(false, layers.modal)}
      parentSelector={() => document.querySelector('#root')!}
    >
      <ModalContent>
        <ModalHeader>{intl.formatMessage({ id: 'RequestHedgePrice.Modal.Header' })}</ModalHeader>
        <FieldTextArea
          label={intl.formatMessage({ id: 'RequestHedgePrice.Modal.Note' })}
          name="note"
          onChange={formikContext.handleChange}
          onBlur={formikContext.handleBlur}
          value={formikContext.values.note}
          data-cy="request-hedge-note"
          maxLength={160}
        />
        <ButtonContainer>
          <div className={ModalButtonsStyle()}>
            <AppButton
              onClick={handleCancelClick}
              data-cy="request-hedge-cancel"
              styleType="neutral-empty"
            >
              {intl.formatMessage({ id: 'Global.Cancel' })}
            </AppButton>
            <AppButton onClick={handleConfirmClick} data-cy="request-hedge-confirm">
              {intl.formatMessage({ id: 'Global.Confirm' })}
            </AppButton>
          </div>
        </ButtonContainer>
      </ModalContent>
      <LoadableContent
        mode={LoadableContent.MODE.FULL}
        loading={requestCompanyHedgeState.isPending}
      />
    </ReactModal>
  );
};
