import React, { useState } from 'react';
import styled from '@emotion/styled';

import AppButton from 'components/shared/forms/AppButton/AppButton';
import { ListActions } from 'components/shared/List';
import { LoadableContent } from 'components/shared/Loader';
import { METAL_ABBR, PRICE_SOURCES } from 'shared/constants';
import { shouldRender } from 'shared/helpers';
import { useMediaQuery } from 'shared/hooks';
import { ItemAction, Metals, User } from 'shared/types';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from './locale/messages';
import { useChangeHedge } from './hooks';
import { UserProfileChooseHedgeModal } from './UserProfileChooseHedgeModal';

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;

  & > button + button {
    margin-left: 10px;
  }
`;

type UserProfilePriceSourceActionsProps = { user: User; metalAbbr: METAL_ABBR };

export const UserProfilePriceSourceActions = ({
  user,
  metalAbbr,
}: UserProfilePriceSourceActionsProps): React.ReactElement | null => {
  const intl = useTypedIntl();
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);
  const { changeHedge, isPending: changeHedgePending } = useChangeHedge(metalAbbr);
  const [changeSourceModalOpen, setChangeSourceModalOpen] = useState(false);

  const applicableHedges = (user?.assignedHedges ?? []).filter(
    ({ applicableTo }) =>
      (applicableTo.includes(Metals.PLATINUM) && metalAbbr === 'pt') ||
      (applicableTo.includes(Metals.PALLADIUM) && metalAbbr === 'pd') ||
      (applicableTo.includes(Metals.RHODIUM) && metalAbbr === 'rh'),
  );
  const currentSource = user?.prices?.[`${metalAbbr}PriceSource`];
  const canUseHedge = currentSource === PRICE_SOURCES.MARKET && applicableHedges.length > 0;
  const canChangeHedge = currentSource === PRICE_SOURCES.HEDGE && applicableHedges.length > 1;
  const canRevertToMarket = currentSource === PRICE_SOURCES.HEDGE;

  const actions: ItemAction[] = [
    {
      label: intl.formatMessage({ id: 'UserProfile.UseHedgePrice' }),
      onClick: () => setChangeSourceModalOpen(true),
      dataCy: () => 'btn-change-hedge',
      visible: canUseHedge,
    },
    {
      label: intl.formatMessage({ id: 'UserProfile.ChangeHedgePrice' }),
      onClick: () => setChangeSourceModalOpen(true),
      dataCy: () => 'btn-change-hedge',
      visible: canChangeHedge,
    },
    {
      label: intl.formatMessage({ id: 'UserProfile.UseMarketFeed' }),
      onClick: () => changeHedge('MARKET'),
      dataCy: () => 'btn-restore-market-prices',
      visible: canRevertToMarket,
    },
  ].filter(shouldRender);

  if (actions.length) {
    return (
      <ButtonsContainer data-cy={`${metalAbbr}-change-source`}>
        {isMobile ? (
          <ListActions actions={actions} item={user} />
        ) : (
          actions.map(item => (
            <AppButton onClick={item.onClick} inline key={item.dataCy?.()}>
              {item.label}
            </AppButton>
          ))
        )}
        <UserProfileChooseHedgeModal
          user={user}
          metalAbbr={metalAbbr}
          isOpen={changeSourceModalOpen}
          onClose={() => setChangeSourceModalOpen(false)}
        />
        <LoadableContent mode={LoadableContent.MODE.FULL} loading={changeHedgePending} />
      </ButtonsContainer>
    );
  }
  return null;
};
