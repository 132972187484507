import React from 'react';
import styled from '@emotion/styled';

import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import { FilterContainer } from './FilterContainer';

const Content = styled.div`
  grid-area: input;
`;
interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  error?: React.ReactNode;
  label?: React.ReactNode;
}

const FilterWrapper = ({
  className,
  label,
  disabled,
  children,
  error,
}: Props): React.ReactElement => (
  <FilterContainer className={className}>
    <ErrorMessage label={label} show={!!error && !disabled}>
      {error}
    </ErrorMessage>
    <Content>{children}</Content>
  </FilterContainer>
);

function withFilterWrapper<T>(
  C: React.FunctionComponent<T>,
): (props: T & Props) => React.ReactElement {
  return (props: T & Props) => (
    <FilterWrapper {...props}>
      <C {...props} />
    </FilterWrapper>
  );
}

export { FilterWrapper, withFilterWrapper };
