import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface TypesState extends GenericStoreSlice {
  types: {
    id: number;
    name: string;
    prefix: string;
  }[];
}

const fetchTypesSlice = createSlice<TypesState, GenericStoreReducer<TypesState>>({
  name: 'types',
  initialState: {
    types: [],
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      types: payload,
    })),
  },
});

export const {
  pending: fetchTypesAction,
  success: fetchTypesSuccessAction,
  failure: fetchTypesFailureAction,
} = fetchTypesSlice.actions;

export const fetchTypes = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    dispatch(fetchTypesAction());
    const types = await httpClient.get(`${apiHostname}/api/converter-types`);
    dispatch(fetchTypesSuccessAction(types.data));
  } catch (error) {
    dispatch(fetchTypesFailureAction((error as AlphamartHttpError)?.response?.data.message));
    return Promise.reject(error);
  }
};

export default fetchTypesSlice.reducer;
