import styled from '@emotion/styled';

import { theme } from 'theme';

export const SearchHits = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  background: ${theme.colors.mineShaft};
  padding: 10px 32px;
  border-top: 1px solid ${theme.colors.charcoal};
  box-shadow: 0 -8px 0 ${theme.colors.mineShaft};
  border-radius: 0 0 8px 8px;
`;

export const SearchField = styled.div`
  display: flex;
  gap: 10px;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const SearchFieldLabel = styled.span`
  color: ${theme.colors.alphaGray};
`;

export const GridSearchHits = styled(SearchHits)`
  &&& {
    gap: 8px;
    padding-left: 20px;

    span {
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }
`;
