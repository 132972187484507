import React, { HTMLAttributes } from 'react';
import { isNil } from 'lodash';

import { StyledButton, StyledContainer } from './Tabs.styles';

interface Tab<ValueType> {
  label: string;
  value: ValueType;
  badge?: number | string | null;
}

interface Props<ValueType> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  list: Tab<ValueType>[];
  value: ValueType;
  onChange: (vt: ValueType) => void;
}

const Tabs = <ValueType extends null | string | number>({
  list,
  value,
  onChange,
  ...props
}: Props<ValueType>): React.ReactElement => (
  <StyledContainer {...props}>
    {list.map(tab => (
      <StyledButton
        type="button"
        key={tab.value}
        isActive={value === tab.value}
        onClick={() => onChange(tab.value)}
        data-active={value === tab.value}
        data-cy={`tab-${tab.value}`}
      >
        <div>{tab.label}</div>
        {!isNil(tab.badge) && <span>{tab.badge}</span>}
      </StyledButton>
    ))}
  </StyledContainer>
);

export { Tabs };
