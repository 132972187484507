import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const StyledPrice = styled.span`
  word-break: keep-all;
`;

export const ListSections = styled.div`
  display: grid;
  row-gap: 30px;
`;

export const ListSection = styled.div`
  h3 {
    font-size: 14px;
  }
`;

export const headerActionsStyles = css`
  button {
    margin-right: 0px;
  }
  div {
    padding-top: 0px;
  }
`;
