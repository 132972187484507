import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { FieldMultiText } from 'components/shared/Fields/FieldMultiText/FieldMultiText';
import { FieldRadioButton } from 'components/shared/Fields/FieldRadioButton/FieldRadioButton';
import { Section, SectionChild, SectionProps } from 'components/shared/forms/Section/Section';
import { SliderField } from 'components/shared/Slider/Slider';
import { MarginVisibility, PERMISSIONS, SHARED } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import { useGetCompanies } from 'shared/queries';
import { Status, UserFormData } from 'shared/types';
import { useAppSelector } from 'store/shared/hooks';
import { Theme } from 'theme';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';

type Props = Omit<
  SectionProps & {
    setActiveSection: (section: string) => void;
    handleChange: (e?: React.ChangeEvent) => void;
    getErrors: (fieldName: string) => string | false | undefined;
    getArrayErrors: (fieldName: string) => string | undefined;
    setFieldTouched: (fieldName: string) => void;
    setFieldValue: (field: string, value?: unknown, shouldValidate?: boolean) => void;
    handleBlur: (e?: React.FocusEvent) => void;
    activeSection: string;
    formValues: UserFormData;
    isAssayCompanyType: boolean;
  },
  'template' | 'children'
>;

const getTemplate = (canUseMarginSliderPerMetal: boolean) =>
  canUseMarginSliderPerMetal
    ? [
        'prices.marginVisibility prices.marginVisibility',
        'prices.userProfitMarginContainer .',
        'prices.currentProfitMarginPt prices.currentProfitMarginPt',
        'prices.currentProfitMarginPd prices.currentProfitMarginPd',
        'prices.currentProfitMarginRh prices.currentProfitMarginRh',
      ]
    : [
        'prices.marginVisibility prices.marginVisibility',
        'prices.userProfitMarginContainer .',
        'prices.currentProfitMarginPt prices.currentProfitMarginPt',
      ];

export const getTotalProfitMargin = (
  metal: 'Pt' | 'Pd' | 'Rh',
  formValues: UserFormData,
  limit: number,
): number | SHARED.LONG_DASH => {
  const profitMargin = [...(formValues?.prices?.userProfitMargin ?? [])].sort(
    (a, b) => Number(b.value) - Number(a.value),
  )[0];

  const baseValue = Math.trunc(
    Number(formValues.prices[`currentProfitMargin${metal}`]) + Number(profitMargin?.value ?? 0),
  );

  if (Number.isNaN(baseValue)) {
    return SHARED.LONG_DASH;
  }

  return Math.min(baseValue, limit, 140);
};

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
const SliderContainer = styled.div`
  width: 90%;
`;
const SliderSummary = styled.div<{ theme?: Theme }>`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.alphaGray};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: bold;
  text-align: end;
`;

const StyledProfitMarginsSection = styled.div<{ theme?: Theme }>`
  & fieldset > div > div:nth-child(even) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
  }
`;

export function ProfitMarginSection({
  setActiveSection,
  handleChange,
  getArrayErrors,
  setFieldTouched,
  handleBlur,
  setFieldValue,
  activeSection,
  formValues,
  isAssayCompanyType,
  sectionName,
}: Props): React.ReactElement {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const allowedToFetchCompanies = authorize(PERMISSIONS.COMPANIES.LIST);
  const currentUser = useCurrentUser();
  const { single, slider } = useAppSelector(state => state.config.profitMarginLimit!);
  const { data: fetchedCompanies } = useGetCompanies(
    { status: Status.ACTIVE },
    { enabled: allowedToFetchCompanies },
  );
  const companies = allowedToFetchCompanies ? fetchedCompanies!.data : [currentUser.company];
  const selectedCompany = [...companies].find(c => c.id === formValues?.info?.company);
  const canUseMarginSliderPerMetal = !!(
    selectedCompany?.canUseProfitMarginPerMetal ?? currentUser.company?.canUseProfitMarginPerMetal
  );
  const getHandleCurrentProfitMarginChange = (metal: string) => (val: number | readonly number[]) =>
    setFieldValue(`prices.currentProfitMargin${metal}`, val, false);
  const template = getTemplate(canUseMarginSliderPerMetal);
  const displaySingleProfitMargin =
    isAssayCompanyType ||
    formValues.prices.marginVisibility === MarginVisibility.SLIDER_MARGIN_ONLY ||
    formValues.prices.marginVisibility === MarginVisibility.SLIDER_AND_TOTAL_MARGIN;

  useEffect(() => {
    if (
      formValues?.prices?.marginVisibility !== MarginVisibility.NO_VISIBILITY &&
      formValues?.prices?.userProfitMargin?.length > 1
    ) {
      setFieldValue('prices.userProfitMargin', [formValues?.prices?.userProfitMargin?.[0] ?? 0]);
    }
  }, [formValues?.prices?.marginVisibility]);

  return (
    <StyledProfitMarginsSection>
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName={sectionName}
        template={template}
      >
        <FieldRadioButton
          label={intl.formatMessage({ id: 'UserForm.MarginVisibility' })}
          value={formValues.prices.marginVisibility!}
          name="prices.marginVisibility"
          onChange={handleChange}
          dataCy="margin-visibility"
          options={Object.values(MarginVisibility).map(value => ({
            label: intl.formatMessage({ id: `UserForm.MarginVisibility.${value}` }),
            description: intl.formatMessage({
              id: `UserForm.MarginVisibility.${value}.Description`,
            }),
            value: value as string,
          }))}
        />
        <SectionChild name="prices.userProfitMarginContainer">
          <FieldMultiText
            minItems={1}
            maxItems={displaySingleProfitMargin ? 1 : 10}
            label={intl.formatMessage({ id: 'UserForm.UserProfitMargin' })}
            addLabel={intl.formatMessage({ id: 'UserForm.AddUserProfitMargin' })}
            name="prices.userProfitMargin"
            onChange={handleChange}
            onTouch={setFieldTouched}
            onBlur={handleBlur}
            value={(formValues.prices?.userProfitMargin as { value: string }[]) ?? []}
            error={getArrayErrors('prices.userProfitMargin')}
            prefix="%"
            data-cy="user-profit-margin"
            required
          />
        </SectionChild>
        <SectionChild name="prices.currentProfitMarginPt">
          <SliderWrapper>
            <SliderContainer>
              <SliderField
                min={0}
                max={slider}
                value={formValues.prices.currentProfitMarginPt!}
                onChange={getHandleCurrentProfitMarginChange('Pt')}
                label={
                  canUseMarginSliderPerMetal ? (
                    <FormattedMessage id="Global.Metals.Platinum" />
                  ) : (
                    <FormattedMessage id="UserForm.UserProfitMarginSlider" />
                  )
                }
              />
            </SliderContainer>
            <SliderSummary>
              {intl.formatMessage(
                {
                  id: 'UserForm.TotalProfitMargin',
                },
                {
                  total: getTotalProfitMargin('Pt', formValues, single + slider),
                },
              )}
            </SliderSummary>
          </SliderWrapper>
        </SectionChild>
        {canUseMarginSliderPerMetal ? (
          <SectionChild name="prices.currentProfitMarginPd">
            <SliderWrapper>
              <SliderContainer>
                <SliderField
                  min={0}
                  max={slider}
                  value={formValues.prices.currentProfitMarginPd!}
                  onChange={getHandleCurrentProfitMarginChange('Pd')}
                  label={<FormattedMessage id="Global.Metals.Palladium" />}
                />
              </SliderContainer>
              <SliderSummary>
                {intl.formatMessage(
                  {
                    id: 'UserForm.TotalProfitMargin',
                  },
                  {
                    total: getTotalProfitMargin('Pd', formValues, single + slider),
                  },
                )}
              </SliderSummary>
            </SliderWrapper>
          </SectionChild>
        ) : null}
        {canUseMarginSliderPerMetal ? (
          <SectionChild name="prices.currentProfitMarginRh">
            <SliderWrapper>
              <SliderContainer>
                <SliderField
                  min={0}
                  max={slider}
                  value={formValues.prices.currentProfitMarginRh!}
                  onChange={getHandleCurrentProfitMarginChange('Rh')}
                  label={<FormattedMessage id="Global.Metals.Rhodium" />}
                />
              </SliderContainer>
              <SliderSummary>
                {intl.formatMessage(
                  {
                    id: 'UserForm.TotalProfitMargin',
                  },
                  {
                    total: getTotalProfitMargin('Rh', formValues, single + slider),
                  },
                )}
              </SliderSummary>
            </SliderWrapper>
          </SectionChild>
        ) : null}
      </Section>
    </StyledProfitMarginsSection>
  );
}
