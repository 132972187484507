import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { MEDIA_QUERY, theme } from 'theme';

export const mainPanelStyles = css`
  padding-bottom: 90px !important;
`;

export const Comment = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RemoveFeedbacksContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  gap: 8px 16px;
  align-items: center;
  justify-content: flex-start;

  padding: 16px 32px;
  font: bold 12px ${theme.fonts.montserrat};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.17);
  background-color: ${theme.colors.mineShaft};

  span {
    margin-right: auto;
  }

  button {
    color: ${theme.colors.white};

    &:before {
      border-color: currentColor;
    }
  }

  @media (${MEDIA_QUERY.MD}) {
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 759px;

    border-radius: 8px;
  }
`;

export const floatingButtonStyles = css`
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 64px;
  gap: 4px;

  border: none;
  background-color: ${theme.colors.mineShaft};

  @media (${MEDIA_QUERY.XL}) {
    right: 328px;
    bottom: 18px;
  }
`;
