import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs';

type GetResultWithoutCanvas = Omit<GetResult, 'components'> & {
  components: Omit<GetResult['components'], 'canvas'>;
};

export const getDeviceDetails = async (): Promise<GetResultWithoutCanvas | null> => {
  try {
    const fp = await FingerprintJS.load();
    const deviceDetails = await fp.get();
    const { canvas, ...components } = deviceDetails.components;
    return {
      ...deviceDetails,
      components,
    };
  } catch (e: unknown) {
    console.error(e);
    return null;
  }
};
