import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { isAlphamartHttpError, User } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface UserDetailsState extends GenericStoreSlice {
  user: User | undefined;
}

const userDetailsSlice = createSlice<UserDetailsState, GenericStoreReducer<UserDetailsState>>({
  name: 'userDetails',
  initialState: {
    user: undefined,
    error: undefined,
    isPending: false,
  },
  reducers: getGenericReducers(payload => ({
    user: payload,
  })),
});

export const {
  pending: fetchUserAction,
  success: fetchUserSuccessAction,
  failure: fetchUserFailureAction,
} = userDetailsSlice.actions;

export const fetchUser =
  (id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchUserAction());
      const { data } = await httpClient.get(`${apiHostname}/api/users/${id}`);
      dispatch(fetchUserSuccessAction(data));
    } catch (error) {
      isAlphamartHttpError(error) && dispatch(fetchUserFailureAction(error.response?.data.message));
      return Promise.reject(error);
    }
  };

export const clearUser = (): ReturnType<typeof fetchUserSuccessAction> =>
  fetchUserSuccessAction(null);
export default userDetailsSlice.reducer;
