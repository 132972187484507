import React from 'react';
import { useDrag } from 'react-dnd';
import styled from '@emotion/styled';

import hamburgerIcon from 'assets/images/icons/hamburger.svg';
import { Icon } from 'components/shared/Buttons';
import { useWindowWidth } from 'shared/hooks';
import { useEventListener } from 'shared/hooks/useEventListener';
import { MEDIA_QUERY, theme } from 'theme';
import { GroupColumn, Item } from '../DroppableGroup';

interface GroupItemProps {
  className?: string;
  columns: GroupColumn[];
  item: Item;
  itemType: string;
}

const GroupItemLayout = ({
  className,
  columns,
  item,
  itemType,
}: GroupItemProps): React.ReactElement => {
  const windowWidth = useWindowWidth();
  const [{ cursor, opacity, isDragging }, drag] = useDrag({
    item,
    type: itemType,
    collect: monitor => ({
      cursor: monitor.isDragging() ? 'grabbing' : 'grab',
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const onTouchMove = (e: React.TouchEvent & UIEvent) => {
    if (e.touches && isDragging) {
      if (e.view.innerHeight - e.touches[0].clientY < 100) window.scroll(0, window.scrollY + 3);
      if (e.view.innerHeight - e.touches[0].clientY > window.innerHeight - 100)
        window.scroll(0, window.scrollY - 3);
    }
  };
  useEventListener<React.TouchEvent & UIEvent>('touchmove', onTouchMove);

  return (
    <div className={className}>
      <div ref={drag} style={{ cursor, opacity }}>
        {columns.map(
          c =>
            c.mediaQuery < windowWidth && (
              <React.Fragment key={c.id}>{c.render(item)}</React.Fragment>
            ),
        )}
        <div>
          <Icon icon={hamburgerIcon} />
        </div>
      </div>
    </div>
  );
};

const GroupItem = styled(GroupItemLayout)<{ mainColor?: string }>`
  position: relative;

  & > div {
    align-items: center;
    background-color: ${theme.colors.mineShaft};
    border-radius: 8px;
    display: grid;
    font-size: 12px;
    grid-template-columns: repeat(6, 1fr);
    margin-left: 64px;
    padding: 8px 20px;
    position: relative;

    @media ${MEDIA_QUERY.MAX_MD} {
      grid-template-columns: repeat(3, 1fr);
    }

    p {
      padding: 0 20px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :hover {
      cursor: grab;
    }

    :focus,
    :active {
      cursor: grabbing;
    }

    ::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: ${({ mainColor }) => mainColor || theme.colors.doveGray};
      left: 0;
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type div {
      text-align: right;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  ::after {
    content: '';
    position: absolute;
    height: calc(100% + 12px);
    width: 32px;
    left: 32px;
    bottom: 50%;
    border-color: ${({ mainColor }) => mainColor || theme.colors.doveGray};
    border-style: dashed;
    border-width: 1px;
    border-top-width: 0;
    border-right-width: 0;
  }
`;

export { GroupItem };
