import React, { useRef, useState } from 'react';

import closeMenuImage from 'assets/images/icons/Close.svg';
import openMenuImage from 'assets/images/icons/dots.svg';
import { useEventListener, useExtendedTheme } from 'shared/hooks';
import { useAppSelector } from 'store/shared/hooks';
import { UserMenuLinks } from './UserMenuLinks/UserMenuLinks';
import {
  userInfoMenuButtonStyles,
  userInfoMenuStyles,
  userInfoNameStyles,
  userInfoStyles,
  userInfoToggleIconStyles,
} from './UserMenu.styles';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';

interface Props {
  compact?: boolean;
}

export const UserMenu = ({ compact }: Props) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const auth = useAppSelector(state => state.auth);
  const menuRef = useRef<HTMLDivElement>(null);
  const theme = useExtendedTheme();

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  const hideMenu = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpened(false);
    }
  };

  useEventListener('mousedown', hideMenu);

  return (
    <AlphamartIntlProvider>
      <div className={userInfoStyles(menuOpened, theme, compact)} ref={menuRef}>
        {!compact && (
          <span className={userInfoNameStyles(theme)}>
            {auth.user &&
              auth.user.data &&
              `${auth.user.data.firstName} ${auth.user.data.lastName}`}
          </span>
        )}
        <button
          data-cy="user-menu-button"
          type="button"
          className={userInfoMenuButtonStyles}
          onClick={toggleMenu}
        >
          <img
            className={userInfoToggleIconStyles}
            alt="Toggle user menu"
            src={menuOpened ? closeMenuImage : openMenuImage}
          />
        </button>
        {menuOpened ? (
          <div className={userInfoMenuStyles(theme)}>
            <UserMenuLinks closeMenuHandler={() => setMenuOpened(false)} />
          </div>
        ) : null}
      </div>
    </AlphamartIntlProvider>
  );
};
