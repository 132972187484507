import { useCallback } from 'react';

import { UsersMessages, useTypedIntl } from 'components/views/Users/locale/messages';
import { ErrorCode, ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { AlphamartErrorData, AlphamartHttpError, Status, User, UserListItem } from 'shared/types';
import { changeUserStatus } from 'store/changeUserStatusSlice';
import { deleteUser } from 'store/deleteUserSlice';
import { invitationResend } from 'store/invitationResendSlice';
import { DownloadUserLogsForm, showModalForm, ThresholdsModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { unblockUser } from 'store/updateUserSlice';
import { useAlphamartNavigate } from './useAlphamartRouter';

type useUserActionsReturnType = {
  handleUnblock: (user: Pick<User, 'id'>, onUserUnblockSuccess?: () => void) => Promise<void>;
  handleUpdate: (user: Pick<User, 'id'>) => void;
  handleInvitationResend: (user: Pick<User, 'id'>) => Promise<void>;
  handleStatusChange: (user: Pick<User, 'id'>, newStatus: Status) => Promise<void>;
  handleDeleteUser: (
    user: Pick<User, 'id'>,
    redirect?: boolean,
  ) => Promise<{ payload: unknown; type: string }>;
  handleLocationsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleHedgeLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleConverterLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleThresholdsModalOpen: (params: ThresholdsModalForm) => unknown;
  handleAccessListModalOpen: (params: UserListItem | User) => unknown;
  handleShoppingCartActivityLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  isPending: boolean;
};

export function useUserActions(): useUserActionsReturnType {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const userDetailsState = useAppSelector(state => state.userDetails);
  const fileDownloadingState = useAppSelector(state => state.downloadFile);
  const userUpdateState = useAppSelector(state => state.updateUser);
  const invitationResendState = useAppSelector(state => state.invitationResend);
  const changeUserStatusState = useAppSelector(state => state.changeUserStatus);
  const deleteUserState = useAppSelector(state => state.deleteUser);

  const isPending = !!(
    userDetailsState?.isPending ||
    fileDownloadingState.isPending ||
    userUpdateState.isPending ||
    invitationResendState.isPending ||
    changeUserStatusState.isPending ||
    deleteUserState.isPending
  );

  const handleUnblock = useCallback(
    async (user: Pick<User, 'id'>, onUserUnblockSuccess = () => {}) => {
      try {
        await dispatch(unblockUser(user.id));
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'Global.User.Unblock.Success' })));
        await onUserUnblockSuccess();
      } catch {
        dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.User.Unblock.Error' })));
      }
    },
    [dispatch],
  );

  const handleUpdate = (user: Pick<User, 'id'>) => navigate(`/users/${user.id}/edit`);

  const handleLocationsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserLocations, params }));

  const handleShoppingCartActivityLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.DownloadShoppingCartActivityLogs, params }));

  const handleHedgeLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserHedgeLogs, params }));

  const handleConverterLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserConverterLogs, params }));

  const handleThresholdsModalOpen = (params: ThresholdsModalForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UsageLimit, params }));

  const handleAccessListModalOpen = (params: UserListItem | User) =>
    dispatch(showModalForm({ modalType: ModalFormType.AccessList, params }));

  const handleInvitationResend = async (user: Pick<User, 'id'>) => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Modal.Confirm' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          dispatch(hideModal());

          try {
            await dispatch(invitationResend(user.id));
            dispatch(
              snackBarPushSuccess(
                intl.formatMessage({ id: 'Global.InvitationResend.Success' }, { record: 'User' }),
              ),
            );
          } catch (error) {
            const errorPayload = error as AlphamartErrorData;
            const messageId = [ErrorCode.USERS_LIMIT_REACHED].includes(errorPayload?.errorCode)
              ? `Global.UserDetails.InvitationResend.Error.${errorPayload.errorCode}`
              : 'Global.Error.SomethingWentWrong';
            dispatch(
              snackBarPushFailure(
                intl.formatMessage(
                  { id: messageId as keyof UsersMessages },
                  errorPayload?.errorData,
                ),
              ),
            );
          }
        },
      }),
    );
  };

  const handleStatusChangeSuccess = status =>
    dispatch(
      snackBarPushSuccess(
        intl.formatMessage(
          { id: `Global.${status === 'INACTIVE' ? 'Inactivate' : 'Reactivate'}.Success` },
          { record: 'User' },
        ),
      ),
    );

  const handleStatusChangeIfMobileInUse = async (
    user: Pick<User, 'id'>,
    newStatus: Status,
    messageId: string,
    error,
  ) => {
    dispatch(
      showModal({
        message: intl.formatMessage({
          id: `Global.UserDetails.Reactivate.Modal.ConfirmDuplicateMobilePhone`,
        }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          try {
            const statusResConfirmed = await dispatch(changeUserStatus(user.id, newStatus, true));
            handleStatusChangeSuccess(statusResConfirmed);
          } catch {
            dispatch(
              snackBarPushFailure(
                intl.formatMessage(
                  { id: messageId as keyof UsersMessages },
                  error?.response?.data.errorData,
                ),
              ),
            );
          }
          dispatch(hideModal());
        },
      }),
    );
  };

  const handleStatusChange = useCallback(
    async (user: Pick<User, 'id'>, newStatus: Status) => {
      await dispatch(
        showModal({
          message: intl.formatMessage({
            id: `Global.UserDetails.${
              newStatus === 'INACTIVE' ? 'Inactivate' : 'Reactivate'
            }.Question`,
          }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            try {
              dispatch(hideModal());
              const statusRes = await dispatch(
                changeUserStatus(user.id, newStatus, newStatus === Status.INACTIVE),
              );
              handleStatusChangeSuccess(statusRes);
            } catch (errorResponse) {
              const error = errorResponse as AlphamartHttpError;
              const isErrorWithOwnTranslation =
                error?.response?.data.errorCode &&
                [
                  ErrorCode.EMAIL_ALREADY_USED_FOR_OTHER_ACTIVE_USER,
                  ErrorCode.MOBILE_PHONE_ALREADY_USED,
                  ErrorCode.USERS_LIMIT_REACHED,
                ].includes(error.response.data.errorCode);
              const isUserLimitReachedError =
                error?.response?.data.errorCode !== ErrorCode.USERS_LIMIT_REACHED &&
                !authorize(PERMISSIONS.USERS.KNOW_ABOUT_OTHER_COMPANY_USERS);
              const messageId = isErrorWithOwnTranslation
                ? `Global.UserDetails.Reactivate.Error.${error?.response?.data.errorCode}${
                    isUserLimitReachedError ? '.ADMIN' : ''
                  }`
                : 'Global.Error.SomethingWentWrong';
              const isMobilePhoneAlreadyInUse =
                authorize(PERMISSIONS.USERS.CREATE_UPDATE_WITH_DUPLICATED_MOBILE_PHONE) &&
                ErrorCode.MOBILE_PHONE_ALREADY_USED_RETRY_ALLOWED ===
                  error?.response?.data.errorCode;

              if (isMobilePhoneAlreadyInUse) {
                await handleStatusChangeIfMobileInUse(user, newStatus, messageId, error);
              } else {
                dispatch(
                  snackBarPushFailure(
                    intl.formatMessage(
                      { id: messageId as keyof UsersMessages },
                      error?.response?.data.errorData,
                    ),
                  ),
                );
              }
            }
          },
        }),
      );
    },
    [dispatch],
  );

  const handleDeleteUser = async (user: Pick<User, 'id'>, redirect = false) =>
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Global.UserDetails.Delete.Modal' }),
        onClose: () => dispatch(hideModal()),
        onConfirm: async () => {
          try {
            await dispatch(deleteUser(user.id));
            dispatch(
              snackBarPushSuccess(
                intl.formatMessage({ id: 'Global.Remove.Success' }, { record: 'User' }),
              ),
            );
            if (redirect) navigate('/users');
          } catch {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
          dispatch(hideModal());
        },
      }),
    );

  return {
    handleUnblock,
    handleUpdate,
    handleInvitationResend,
    handleStatusChange,
    handleDeleteUser,
    handleLocationsModalOpen,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
    handleThresholdsModalOpen,
    handleAccessListModalOpen,
    handleShoppingCartActivityLogsModalOpen,
    isPending,
  };
}
