import { useCallback, useEffect, useRef } from 'react';
import { useDragDropManager } from 'react-dnd';
import { DragDropMonitor } from 'dnd-core/src/interfaces';
import { clamp } from 'lodash';

interface ScrollAmountParams {
  scrollableElement?: Element;
  bound?: [number, number];
  base?: number;
  offset?: number;
  fullScrollDuration?: number;
}

const getScrollAmount = (
  monitor: DragDropMonitor,
  delta: number,
  {
    scrollableElement,
    bound = [0.28, 0.48],
    base = 13,
    offset = 6,
    fullScrollDuration = 500, // 500 ms for full scroll at max pointer position
  }: ScrollAmountParams,
): number => {
  const clientOffset = monitor.getClientOffset();
  const rect = scrollableElement?.getBoundingClientRect();
  const top = rect?.top ?? 0;
  const height = rect?.height ?? document?.documentElement?.clientHeight;
  const scrollHeight = scrollableElement?.scrollHeight ?? document?.documentElement?.scrollHeight;
  if (!height || !clientOffset || !scrollHeight) return 0;

  const normalized = clamp((clientOffset.y - top) / height - 0.5, -0.5, 0.5); // -0.5...0.5
  const aboveBoundary =
    clamp(Math.abs(normalized) - bound[0], 0, bound[1] - bound[0]) / (bound[1] - bound[0]); // 0...1

  if (aboveBoundary === 0) return 0;

  const screenPow = Math.max(scrollHeight - height, 0);
  const scrollPow = base ** (aboveBoundary * offset - offset); // 0...1
  const refreshRatePow = delta / fullScrollDuration;
  return Math.round(Math.sign(normalized) * scrollPow * screenPow * refreshRatePow);
};

export interface AutoScrollOptions extends ScrollAmountParams {
  enabled?: boolean;
}

export const useAutoScroll = (props: AutoScrollOptions | undefined) => {
  const { enabled = true, ...options } = props ?? {};
  const monitor = useDragDropManager().getMonitor();
  const animationRef = useRef<number | undefined>();
  const timestampRef = useRef<DOMHighResTimeStamp | undefined>();

  const animate = useCallback<FrameRequestCallback>(
    timestamp => {
      const delta = timestampRef.current ? timestamp - timestampRef.current : 0;
      timestampRef.current = timestamp;
      const scrollAmount = getScrollAmount(monitor, delta, options);
      if (scrollAmount !== 0 && monitor.isDragging() && enabled) {
        (options.scrollableElement ?? document?.documentElement)?.scrollBy(0, scrollAmount);
      }
      animationRef.current = requestAnimationFrame(animate);
    },
    [monitor, enabled, options],
  );

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      animationRef.current = undefined;
      timestampRef.current = undefined;
    };
  }, [animate, options.scrollableElement]);
};
