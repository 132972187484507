import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const requestCompanyHedgePriceSlice = createSlice<
  GenericStoreSlice,
  GenericStoreReducer<GenericStoreSlice>
>({
  name: 'requestCompanyHedgePrice',
  initialState: { error: undefined },
  reducers: {
    ...getGenericReducers(),
  },
});

export const { success, failure, pending: submitting } = requestCompanyHedgePriceSlice.actions;

export default requestCompanyHedgePriceSlice.reducer;

export const requestCompanyHedgePrice =
  (note?: string): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(submitting());
      await httpClient.post(`${apiHostname}/api/companies/hedge-price`, { note });
      dispatch(success({}));
    } catch (err) {
      dispatch(failure((err as AlphamartHttpError)?.response?.data?.message));
      throw err;
    }
  };
