import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { debounce } from 'lodash';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 80px;
  gap: 0 6px;
  button {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0 5px;
    font-size: 1.5em;
    cursor: pointer;
    transition: opacity 0.2s;
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
`;

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  initialValue: number;
  onChange(value: number): void;
  min?: number;
  max?: number;
  wait?: number;
  disabled?: boolean;
}

const SpinButton = ({
  initialValue,
  onChange,
  min = -Infinity,
  max = Infinity,
  wait = 300,
  disabled = false,
  ...props
}: Props): React.ReactElement => {
  const [value, setValue] = useState(initialValue);

  const debouncedChangeHandler = useCallback(debounce(onChange, wait), []);

  const handleChange = (increment: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const nextValue = value + increment;
    setValue(nextValue);
    debouncedChangeHandler(nextValue);
  };

  return (
    <Container {...props}>
      <button
        type="button"
        disabled={disabled || value <= min}
        onClick={event => handleChange(-1, event)}
      >
        -
      </button>
      <span>{value}</span>
      <button
        type="button"
        disabled={disabled || value >= max}
        onClick={event => handleChange(1, event)}
      >
        +
      </button>
    </Container>
  );
};

export { SpinButton };
