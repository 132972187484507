import React, { useCallback } from 'react';
import ReactSlider from 'react-slider';
import styled from '@emotion/styled';

import plusIcon from 'assets/images/icons/Add.svg';
import minusIcon from 'assets/images/icons/minus.svg';
import stripesIcon from 'assets/images/icons/stripes.svg';
import { useExtendedTheme } from 'shared/hooks';
import { Theme, theme as defaultTheme } from 'theme';
import { Icon, IconButton } from '../Buttons';
import { withFieldWrapper } from '../Fields/FieldWrapper/FieldWrapper';

interface Props {
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  max: number;
  min: number;
  onChange: (val: number | readonly number[]) => void;
  percentage?: boolean;
  value: number;
}

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 12px;
  user-select: none;
`;

const StyledThumb = styled.div<{ theme?: Theme; disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  line-height: 24px;
  width: 16px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.altoLighter};
  background-color: ${({ theme }) => theme.colors.altoLighter};
  color: ${({ theme }) => theme.colors.whisper};
  border-radius: 50%;
  transform: translateY(-4px);
  cursor: grab;
  transition: border-color 0.3s ease;

  :active {
    cursor: grabbing;
    transform: scale(0.96) translateY(-4px);
  }

  :focus {
    outline: none;
  }
  :hover {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.colors.mineShaft};
      border: 1px solid ${theme.colors.mineShaft};

      :hover, :active {
        cursor: not-allowed;
      }

      :active {
        transform: translateY(-8px);
      }

      :hover {
        border: 1px solid ${theme.colors.mineShaft};
      }

      :focus {
        filter: none;
      }
    `}

  & > .valueIndicator {
    position: absolute;
    top: -28px;
    width: 48px;
    height: 24px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.altoLighter};
    color: ${({ theme }) => theme.colors.charcoal};
    font-weight: 600;
    font-size: 12px;
  }
`;

const Thumb = (props, state, disabled, percentage) => (
  <StyledThumb {...props} disabled={disabled}>
    <Icon icon={stripesIcon} size="8px" color={defaultTheme.colors.codGray} />
    <div className="valueIndicator">
      {state.value}
      {percentage ? ' %' : ''}
    </div>
  </StyledThumb>
);

const StyledTrack = styled.div<{ theme: Theme }>`
  top: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.codGrayDarker};
  border-radius: 50px;
  height: 8px;

  &.track-0 {
    background: ${({ theme }) => theme.mainColor};
  }
`;
const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const SliderLayout = ({
  className,
  min,
  max,
  value,
  onChange,
  dataCy,
  disabled = false,
  percentage = true,
}: Props): React.ReactElement => {
  const theme = useExtendedTheme();

  const handleAdd = useCallback(() => {
    onChange(value + 1);
  }, [value, onChange]);

  const handleSubtract = useCallback(() => {
    onChange(value - 1);
  }, [value, onChange]);

  return (
    <div className={className}>
      <IconButton
        icon={minusIcon}
        iconColor={theme.colors.alphaGray}
        onClick={handleSubtract}
        size="16px"
        disabled={value <= min}
        hoverColor={theme.mainColor}
      />
      <span className="minValue">
        {min}
        {percentage ? ' %' : ''}
      </span>
      <StyledSlider
        min={min}
        max={max}
        value={value ?? 0}
        renderTrack={Track}
        renderThumb={(props, state) => Thumb(props, state, disabled, percentage)}
        onAfterChange={onChange}
        onSliderClick={onChange}
        pearling
        disabled={disabled}
        data-cy={dataCy}
      />
      <IconButton
        icon={plusIcon}
        iconColor={theme.colors.alphaGray}
        onClick={handleAdd}
        size="32px"
        disabled={value >= max}
        hoverColor={theme.mainColor}
      />
      <span className="maxValue">
        {max}
        {percentage ? ' %' : ''}
      </span>
    </div>
  );
};

const Slider = styled(SliderLayout)<{ theme?: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 24px;

  & > button {
    touch-action: manipulation;
    margin: 0 8px;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 2px solid ${({ theme }) => theme.colors.mineShaftLightest};
    border-radius: 50%;

    &:hover:not(:disabled) {
      border-color: ${({ theme }) => theme.mainColor};

      & > span {
        background-color: ${({ theme }) => theme.mainColor};
      }
    }
  }

  & > span {
    position: absolute;
    top: -14px;
    font-size: 12px;

    &.minValue {
      left: 44px;
    }
    &.maxValue {
      right: 44px;
    }
  }
`;

export { Slider };

export const SliderField = withFieldWrapper<Props>(props => <Slider {...props} />);
