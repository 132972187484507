import styled from '@emotion/styled';

import { Theme } from 'theme';

export const StyledContainer = styled.nav<{ theme?: Theme }>`
  display: flex;
  gap: 0 24px;
  font: 500 16px ${({ theme }) => theme.fonts.montserrat};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mineShaft};
`;

export const StyledButton = styled.button<{ isActive: boolean; theme?: Theme }>`
  all: unset;
  display: flex;
  align-items: center;
  outline: revert;
  cursor: pointer;
  padding: 12px 1px;
  border-bottom: 1px solid transparent;

  ${({ isActive, theme }) => isActive && `border-bottom-color: ${theme.mainColor};`}
  &:focus {
    outline-color: ${({ theme }) => theme.mainColor};
  }

  & > span {
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 8px;
    font-size: 12px;
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.white : theme.colors.doveGray};
    color: ${({ isActive, theme }) => (isActive ? theme.colors.black : theme.colors.white)};
  }
`;
