import React, { useCallback, useEffect, useState } from 'react';
import { isNumber } from 'lodash';

import { ApiFile } from 'shared/types';
import { GalleryLayout } from './Gallery.styles';
import { Carousel } from '../Carousel/Carousel';
import ImageLightbox from '../ImageLightbox';
import { ThumbnailLink } from '../ThumbnailLink';

interface Props {
  largeImage?: boolean;
  withMainPhoto?: boolean;
  onLightboxToggle: (isOpened: boolean) => void;
  images: ApiFile[];
}

export const Gallery = ({
  images,
  withMainPhoto,
  largeImage,
  onLightboxToggle,
}: Props): React.ReactElement | null => {
  const [viewed, setViewed] = useState<number | null>(null);
  const goToNext = useCallback(
    () => isNumber(viewed) && setViewed(Math.min(images.length - 1, viewed + 1)),
    [viewed],
  );
  const goToPrevious = useCallback(
    () => isNumber(viewed) && setViewed(Math.max(0, viewed - 1)),
    [viewed],
  );

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (viewed === null) return;
      // ESC
      if (e.keyCode === 27) {
        setViewed(null);
        return onLightboxToggle(false);
      }
      if (e.keyCode === 37) goToPrevious(); // LEFT
      if (e.keyCode === 39) goToNext(); // RIGHT
    };

    window.addEventListener('keydown', keyListener);
    return () => window.removeEventListener('keydown', keyListener);
  }, [goToPrevious, goToNext]);

  const filteredImages = images?.filter(i => i.presignedUrls) ?? [];

  if (!filteredImages.length) return null;

  const customPaging = (i: number) => (
    <ThumbnailLink
      key={images[i].id}
      thumbnail={images[i].presignedUrls!.LARGE!}
      title={images[i].fileName}
      disableZoom
    />
  );

  return (
    <div id="gallery">
      <GalleryLayout withMainPhoto={withMainPhoto}>
        {images.map((image, index) => (
          <ThumbnailLink
            key={image.id}
            thumbnail={largeImage ? image.presignedUrls!.LARGE! : image.presignedUrls!.MEDIUM!}
            onClick={() => {
              onLightboxToggle(true);
              setViewed(index);
            }}
            title={image.fileName}
            disableZoom
          />
        ))}
      </GalleryLayout>
      <Carousel customPaging={customPaging}>
        {images.map((image, index) => (
          <ThumbnailLink
            key={image.id}
            thumbnail={image.presignedUrls!.LARGE!}
            onClick={() => {
              onLightboxToggle(true);
              setViewed(index);
            }}
            title={image.fileName}
            disableZoom
          />
        ))}
      </Carousel>
      {viewed !== null ? (
        <ImageLightbox
          onClick={() => {
            setViewed(null);
            onLightboxToggle(false);
          }}
          startFrom={viewed}
        >
          {images.map((image, index) => (
            <ThumbnailLink
              key={image.id}
              thumbnail={image.presignedUrls!.ORIGINAL!}
              onClick={() => setViewed(index)}
              title={image.fileName}
            />
          ))}
        </ImageLightbox>
      ) : null}
    </div>
  );
};
