import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { Province } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum ProvincesKeys {
  GetProvinces = 'GetProvinces',
}

type GetProvincesParams = { country: number | null };

export const useGetProvinces = (
  params: GetProvincesParams,
  options: UseQueryOptions<Province[]> = {},
): UseQueryResult<Province[]> =>
  useQuery<Province[]>(
    [ProvincesKeys.GetProvinces, params],
    async () => {
      if (!params.country) return [];

      const response = await getHttpClient().get<Province[]>(
        `${apiHostname}/api/provinces?countryId=${params.country}`,
      );
      return response.data ?? [];
    },
    { initialData: [], cacheTime: 60000, ...options },
  );
