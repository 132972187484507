import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FieldInputRaw } from 'components/shared/Fields/FieldInput/FieldInput';
import { ErrorMessage } from 'components/shared/Fields/FieldWrapper/ErrorMessage/ErrorMessage';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { LoadableContent } from 'components/shared/Loader';
import { ErrorCode } from 'shared/constants';
import { useDefaultRedirect, useExtendedTheme } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useAutofillDetection } from 'shared/hooks/useAutofillDetection';
import { AlphamartHttpError } from 'shared/types';
import { failure as loginFailure, login } from 'store/auth';
import { addDevice, resendDeviceChallenge, verifyChallenge } from 'store/devicesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import {
  AuthenticationMessages,
  messages,
  TypedFormattedMessage,
  useTypedIntl,
} from './locale/messages';
import {
  authenticationFieldTextContainer,
  authenticationFormContainerStyles,
  authenticationFormStyles,
  authenticationHeaderStyle,
  authenticationLogoContainerStyles,
  authenticationPhotoStyles,
  authenticationStyles,
  LoginFormButtonsContainer,
  LoginFormButtonsStyled,
} from './Authentication.styles';
import { getChallengeSchema, getLoginSchema } from './loginSchema';

interface LoginFormShape {
  email: string;
  password: string;
  challenge?: string;
}

function LoginPage() {
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const auth = useAppSelector(state => state.auth);
  const config = useAppSelector(state => state.config);
  const navigate = useAlphamartNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [smsChallenge, setSmsChallenge] = useState(false);
  const [isChallengePending, setIsChallengePending] = useState(false);
  const theme = useExtendedTheme();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const autofillDetected = useAutofillDetection([passwordRef, emailRef]);
  const isCaptchaEnabledAndVisible =
    config.recaptcha &&
    config.recaptcha.enabled &&
    auth.loginAttempts >= config.recaptcha.maxInvalidAttemtps;

  const loginForm = useFormik<LoginFormShape>({
    initialValues: {
      email: '',
      password: '',
      challenge: '',
    },
    validationSchema: smsChallenge ? getChallengeSchema(intl) : getLoginSchema(intl),
    onSubmit: async values => {
      if (values.challenge) {
        await handleChallengeSubmit();
      } else {
        await dispatch(
          login(
            values.email,
            values.password,
            recaptchaRef.current && recaptchaRef.current.getValue(),
          ),
        );
      }
    },
  });
  const handleChallengeSubmit = async () => {
    try {
      await dispatch(verifyChallenge(values.challenge!));
      await dispatch(
        login(
          values.email,
          values.password,
          recaptchaRef.current && recaptchaRef.current.getValue(),
        ),
      );
    } catch {
      loginForm.setFieldError(
        'challenge',
        intl.formatMessage({ id: 'Login.Error.Challenge.Invalid' }),
      );
    }
  };
  const handleAddDeviceConfirm = async () => {
    setIsChallengePending(true);
    dispatch(hideModal());
    try {
      await dispatch(addDevice());
      setSmsChallenge(true);
    } catch (error) {
      dispatch(
        snackBarPushFailure(
          intl.formatMessage({
            id:
              (error as AlphamartHttpError)?.response?.data?.errorCode ===
              ErrorCode.MAXIMAL_REQUESTS_NUMBER_REACHED
                ? 'Login.Error.MaximumNumberOfRequests'
                : 'Global.Error.SomethingWentWrong',
          }),
        ),
      );
    }
    setIsChallengePending(false);
  };
  const { errors, handleBlur, handleChange, setFieldError, touched, values } = loginForm;
  useEffect(() => {
    if (auth.error?.code === 'UNRECOGNIZED_DEVICE') {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'Login.Modal.AddDevice' }),
          onClose: () => dispatch(hideModal()),
          onConfirm: handleAddDeviceConfirm,
        }),
      );
    }
  }, [auth.error]);

  useEffect(() => {
    if (auth.error && !errors.password) {
      setFieldError(
        'email',
        intl.formatMessage({ id: auth.error.message as keyof AuthenticationMessages }),
      );
      loginForm.setTouched({ email: true, password: false }, false);

      if (isCaptchaEnabledAndVisible && recaptchaRef.current) {
        recaptchaRef.current.reset();
        setRecaptchaValue(null);
      }
    }

    return () => {
      if (
        auth.error &&
        auth.error?.code !== 'UNRECOGNIZED_DEVICE' &&
        !auth.isPending &&
        loginForm.isSubmitting
      ) {
        loginForm.resetForm({});
      }
    };
  }, [auth.error, auth.isPending, loginForm.isSubmitting]);

  const redirectTo = useDefaultRedirect();

  useEffect(() => {
    if (auth.user) {
      navigate(redirectTo);
    } else if (auth.redirectTo && auth.redirectTo !== '/login') {
      navigate(auth.redirectTo);
    }

    return () => {
      if (loginForm.isSubmitting && isCaptchaEnabledAndVisible && recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    };
  }, [isCaptchaEnabledAndVisible, auth.user, auth.redirectTo, recaptchaRef]);

  const handlePasswordChange = useCallback(
    e => {
      dispatch(loginFailure(null));
      handleChange(e);
    },
    [dispatch, loginFailure, handleChange],
  );

  const handleRecaptchaChange = e => {
    setRecaptchaValue(e);
    handleChange(e);
    return e;
  };
  const handleChallengeResend = async () => {
    try {
      setIsChallengePending(true);
      await dispatch(resendDeviceChallenge());
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'ResendChallenge.Success' })));
    } catch (error) {
      const id =
        (error as AlphamartHttpError)?.response?.data?.errorCode ===
        ErrorCode.MAXIMAL_REQUESTS_NUMBER_REACHED
          ? 'ResendChallenge.Error.LimitReached'
          : 'Global.Error.SomethingWentWrong';
      dispatch(snackBarPushFailure(intl.formatMessage({ id })));
    }
    setIsChallengePending(false);
  };

  const handleReset = () => {
    loginForm.resetForm();
    setSmsChallenge(false);
    dispatch(loginFailure(null));
  };

  const initialAutofill = autofillDetected && !loginForm.dirty;
  const isSubmitDisabled =
    !(
      loginForm.isValid &&
      loginForm.dirty &&
      (isCaptchaEnabledAndVisible ? !!recaptchaValue : true)
    ) || loginForm.isSubmitting;

  return (
    <LoadableContent mode={LoadableContent.MODE.OVERLAY} loading={isChallengePending} drawContent>
      <div className={authenticationStyles()}>
        <section className={authenticationFormContainerStyles()}>
          <h1 className={authenticationLogoContainerStyles(theme.logo)}>Alphamart</h1>
          <form className={authenticationFormStyles()} onSubmit={loginForm.handleSubmit}>
            <h2 className={authenticationHeaderStyle()}>
              <TypedFormattedMessage id="Login.Header.LogIn" />
            </h2>
            {!smsChallenge ? (
              <>
                <section className={authenticationFieldTextContainer()}>
                  {errors.email && touched.email && (
                    <ErrorMessage standalone show={!!errors.email && touched.email}>
                      {errors.email}
                    </ErrorMessage>
                  )}
                  <FieldInputRaw
                    name="email"
                    placeholder={intl.formatMessage({ id: 'Login.Label.Email' })}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    value={values.email}
                    data-cy="login-input-email"
                    ref={emailRef}
                    maxLength={100}
                  />
                </section>
                <section className={authenticationFieldTextContainer()}>
                  {errors.password && touched.password && (
                    <ErrorMessage standalone show={!!errors.password && touched.password}>
                      {errors.password}
                    </ErrorMessage>
                  )}
                  <FieldInputRaw
                    name="password"
                    type="password"
                    placeholder={intl.formatMessage({ id: 'Login.Label.Password' })}
                    onChange={handlePasswordChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    value={values.password}
                    data-cy="login-input-password"
                    ref={passwordRef}
                    maxLength={100}
                  />
                </section>
              </>
            ) : (
              <section className={authenticationFieldTextContainer()}>
                {errors.challenge && touched.challenge && (
                  <ErrorMessage standalone show={!!errors.challenge && touched.challenge}>
                    {errors.challenge}
                  </ErrorMessage>
                )}
                <FieldInputRaw
                  name="challenge"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'Login.Label.Challenge' })}
                  onChange={handlePasswordChange}
                  onBlur={handleBlur}
                  error={errors.challenge}
                  value={values.challenge ?? ''}
                  maxLength={4}
                  data-cy="login-input-challenge"
                />
              </section>
            )}
            {isCaptchaEnabledAndVisible && (
              <>
                {!recaptchaValue && (
                  <ErrorMessage standalone show>
                    <TypedFormattedMessage
                      id={
                        auth.error?.message === 'Login.Error.InvalidCaptcha'
                          ? 'Login.Error.InvalidCaptcha'
                          : 'Login.Error.PleaseClickTheBox'
                      }
                    />
                  </ErrorMessage>
                )}
                <section className={authenticationFieldTextContainer()}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={config.recaptcha?.key}
                    onChange={handleRecaptchaChange}
                  />
                </section>
              </>
            )}
            <LoginFormButtonsStyled hasResendButton={smsChallenge}>
              <LoginFormButtonsContainer>
                {smsChallenge ? (
                  <Link to="/login" onClick={handleReset}>
                    <TypedFormattedMessage id="Login.Button.ChangeAccount" />
                  </Link>
                ) : (
                  <Link to="/forgot-password">
                    <TypedFormattedMessage id="Login.Button.ForgotPassword" />
                  </Link>
                )}
              </LoginFormButtonsContainer>
              <LoginFormButtonsContainer>
                {smsChallenge && (
                  <AppButton
                    data-cy="login-button-resend-challenge"
                    onClick={handleChallengeResend}
                  >
                    <TypedFormattedMessage id="ResendChallenge.Button" />
                  </AppButton>
                )}
                <AppButton
                  disabled={initialAutofill ? false : isSubmitDisabled}
                  type="submit"
                  data-cy="login-button-login"
                >
                  <TypedFormattedMessage id="Login.Button.Login" />
                </AppButton>
              </LoginFormButtonsContainer>
            </LoginFormButtonsStyled>
          </form>
        </section>
        <div className={authenticationPhotoStyles()} />
      </div>
    </LoadableContent>
  );
}

export const Login = withAlphamartIntlProvider(LoginPage, messages);
