import { createSlice } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const downloadFileSlice = createSlice<GenericStoreSlice, GenericStoreReducer<GenericStoreSlice>>({
  name: 'downloadFile',
  initialState: {
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(),
  },
});

export const {
  success: downloadFileSuccessAction,
  failure: downloadFileFailureAction,
  pending: downloadFileAction,
} = downloadFileSlice.actions;

export default downloadFileSlice.reducer;

export const downloadFile =
  (path: string, name: string, params?: DownloadFileParams): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await dispatch(downloadFileAction());

      const res = await httpClient.get(`${apiHostname}${path}`, { responseType: 'blob', params });
      FileSaver.saveAs(res.data, name);

      dispatch(downloadFileSuccessAction({}));
    } catch (error) {
      dispatch(downloadFileFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export interface DownloadFileParams {
  addedFrom?: string;
  addedTo?: string;
  usersIds?: number[] | null;
  user?: number;
  timezone?: string;
  companyId?: number;
}
