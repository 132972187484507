import * as React from 'react';
import styled from '@emotion/styled';

import { Theme } from 'theme';
import { Icon } from './Icon';

type Props = {
  className?: string;
  disabled?: boolean;
  icon: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: string;
  iconColor?: string;
  hoverColor?: string;
  'data-cy'?: string;
};

const IconButtonLayout = ({
  className,
  icon,
  onClick,
  size,
  disabled,
  iconColor,
  hoverColor,
  'data-cy': dataCy,
}: Props): React.ReactElement => (
  <button
    type="button"
    className={className}
    onClick={onClick}
    disabled={disabled}
    data-cy={dataCy || 'icon-button'}
  >
    <Icon icon={icon} size={size} color={iconColor} hoverColor={hoverColor} />
  </button>
);

const IconButton = styled(IconButtonLayout)<{
  theme?: Theme;
  btnSize?: number;
  iconPosition?: 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky';
}>`
  background-color: transparent;
  border: none;
  width: ${({ btnSize }) => btnSize || 36}px;
  height: ${({ btnSize }) => btnSize || 36}px;
  outline-color: ${({ theme }) => theme.mainColor};
  padding: 0;

  span {
    position: ${({ iconPosition }) => iconPosition || 'static'};
    cursor: inherit;
  }

  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.colors.mineShaftLightest};

    & > span {
      background-color: ${({ theme }) => theme.colors.mineShaftLightest};
    }
  }

  :focus {
    outline: none;
    box-shadow: inset 0 0 4px 0 rgba(255, 255, 255, 0.3);
  }
`;

const IconButtonRound = styled(IconButton)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.codGray};
`;

export { IconButton, IconButtonRound };
