import React, { useEffect, useRef } from 'react';

const defaultEvents = ['mousedown', 'touchstart'];

function useClickAway(
  ref: React.MutableRefObject<HTMLElement | null>,
  onClickAway: (event: string) => void,
  events = defaultEvents,
  element = window,
): void {
  const savedCallback = useRef<(event: string) => void>(onClickAway);

  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);

  useEffect(() => {
    const handler = event => {
      const { current: el } = ref;

      return el && !el.contains(event.target) && savedCallback.current(event);
    };

    events.forEach(eventName => element.addEventListener(eventName, handler));

    return () => {
      events.forEach(eventName => element.removeEventListener(eventName, handler));
    };
  }, [events, ref]);
}

export { useClickAway };
