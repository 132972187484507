import React from 'react';
import styled from '@emotion/styled';

import { theme } from 'theme';

interface Props {
  text: string;
}
const ListItemContainer = styled.div`
  border-bottom: 1px solid ${theme.colors.mineShaftLightest};
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-bottom: 20px;
`;
export const NotificationsListLastItem = React.forwardRef<HTMLDivElement, Props>(
  ({ text }, ref): React.ReactElement => <ListItemContainer ref={ref}>{text}</ListItemContainer>,
);
