import React from 'react';

import { DetailsSection } from 'components/shared/Details';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { UserLoginLog } from 'shared/types';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

interface Props {
  userLoginLog: UserLoginLog;
}

export function UserDetailsLastLogin({ userLoginLog }: Props): React.ReactElement {
  return (
    userLoginLog && (
      <DetailsSection
        title={<FormattedMessage id="UserDetails.LastLogin" />}
        template={['date date date']}
        fields={[
          {
            name: 'date',
            label: <FormattedMessage id="UserDetails.LastLogin.Date" />,
            value: formatDateWithTime(userLoginLog.createdAt),
          },
        ]}
      />
    )
  );
}
