import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { StyledWizardForm } from 'components/shared/forms/Form/StyledForm';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { Controls } from 'components/shared/forms/Wizard/Controls/Controls';
import { FormWrapper } from 'components/shared/forms/Wizard/FormWrapper/FormWrapper';
import { Wizard } from 'components/shared/forms/Wizard/Wizard';
import { ErrorCode, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartLocation, useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { AlphamartErrorData } from 'shared/types';
import { createVehicle } from 'store/createVehicleSlice';
import { fetchMakes } from 'store/makesSlice';
import { fetchModels } from 'store/modelsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../locale/messages';
import {
  vehicleFormSchema,
  VehicleFormShape,
  VehicleFormStepOne,
  VehicleFormStepTwo,
} from '../VehicleForm';

const CreateVehicleComponent = (): React.ReactElement => {
  const { models } = useAppSelector(state => state.models);
  const { makes } = useAppSelector(state => state.makes);
  const [activeSection, setActiveSection] = useState<string | null | undefined>(null);
  const [activeInput, setActiveInput] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();
  const vehiclePhoto = useAppSelector(state => state.config?.upload?.vehiclePhoto);
  const authorize = useAuthorization();

  const intl = useTypedIntl();
  const validationSchema = useMemo(
    () =>
      vehiclePhoto &&
      vehicleFormSchema(
        vehiclePhoto.imageExtensions,
        vehiclePhoto.fileSizeLimit,
        models,
        makes,
        intl,
      ),
    [vehiclePhoto?.imageExtensions, vehiclePhoto?.fileSizeLimit, models, makes],
  );

  const formikContext = useFormik<VehicleFormShape>({
    initialValues: {
      vin: '',
      make: null,
      model: null,
      year: null,
      vehicleType: '',
      photos: [],
      engineType: '',
      engineDisplacement: '',
      engineDisplacementConfirmation: '',
      transmission: '',
      enginePower: '',
      enginePowerConfirmation: '',
      numberOfConverters: 1,
      converters: [],
      numberOfNonstandardConverters: 0,
      nonstandardConverters: [],
      weight: '',
      weightConfirmation: '',
      numberOfDoors: null,
      notes: '',
    } as unknown as VehicleFormShape,
    initialTouched: { vin: true },
    validationSchema,
    onSubmit: async values => {
      try {
        await dispatch(createVehicle(values));
        dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'VehicleForm.Message.Success' })));
        navigate(authorize(PERMISSIONS.VEHICLES.LIST) ? '/vehicles/list' : '/vehicles', {
          state: location.state,
        });
      } catch (e) {
        const error = e as AlphamartErrorData;
        const vehicleAlreadyExist = error.errorCode === ErrorCode.VEHICLE_ALREADY_EXIST;
        const vinUniqueChecker = error.errorCode === ErrorCode.VIN_NOT_UNIQUE;
        let errorStatus;
        if (vehicleAlreadyExist) {
          errorStatus = intl.formatMessage({ id: 'VehicleForm.Message.Vehicle.Error' });
        } else if (vinUniqueChecker) {
          errorStatus = intl.formatMessage({ id: 'VehicleForm.Message.Vin.Error' });
        } else {
          errorStatus = intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' });
        }
        dispatch(snackBarPushFailure(errorStatus));
      }
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    getFieldMeta,
    isValid,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    isValidating,
  } = formikContext;

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(name);

    return fieldTouched && error;
  };

  useEffect(() => {
    dispatch(fetchMakes());
    dispatch(fetchModels());
  }, []);

  const errorFilter = { ...formikContext.errors };
  delete errorFilter.converters;
  delete errorFilter.nonstandardConverters;
  delete errorFilter.numberOfNonstandardConverters;

  return (
    <FormContainer>
      <Wizard>
        <StyledWizardForm onSubmit={formikContext.handleSubmit}>
          <FormWrapper pageIndex={1}>
            <VehicleFormStepOne
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              values={values}
              getErrors={getErrors}
              context={formikContext}
              setActiveInput={setActiveInput}
              activeInput={activeInput}
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              touched={touched}
            />
          </FormWrapper>
          <FormWrapper pageIndex={2}>
            <VehicleFormStepTwo
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              getErrors={getErrors}
              context={formikContext}
              activeInput={activeInput}
              setFieldValue={setFieldValue}
              setActiveInput={setActiveInput}
            />
          </FormWrapper>
          <Controls
            stepsErrors={[errorFilter]}
            isValid={isValid}
            touched={touched}
            isValidating={isValidating}
            isSubmitting={isSubmitting}
            lastPageButton={intl.formatMessage({ id: 'VehicleForm.AddVehicle.Submit' })}
          />
        </StyledWizardForm>
      </Wizard>
    </FormContainer>
  );
};

export const CreateVehicle = withAlphamartIntlProvider(CreateVehicleComponent, messages);
