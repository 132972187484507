import { isNil } from 'lodash';
import * as yup from 'yup';

import { METAL_ABBR } from 'shared/constants';
import { NUMBER_2_DIGITS_PRECISION, NUMBER_DECIMAL } from 'shared/constants/regularExpressions';
import { HedgeMessages, TypedIntlShape } from '../locale/messages';

yup.addMethod(yup.object, 'atLeastOneOf', function atLeastOneOf(list, message) {
  return this.test({
    name: 'atLeastOneOf',
    message,
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value, { createError }) =>
      value == null || list.some(f => !!value[f]) || createError({ path: 'ptPriceCust', message }),
  });
});

export const hedgeFormSchema = (
  intl: TypedIntlShape,
  canChangeParentCompany: boolean,
): yup.AnyObjectSchema => {
  const priceSchema = (metal: METAL_ABBR) =>
    yup
      .string()
      .nullable()
      .trim()
      .test({
        message: intl.formatMessage({ id: `HedgeForm.Errors.${metal}PriceCust.CharactersAllowed` }),
        test: value => (value ? NUMBER_DECIMAL.test(value) : true),
      })
      .test({
        message: intl.formatMessage({ id: `HedgeForm.Errors.${metal}PriceCust.Precision` }),
        test: value => (value ? NUMBER_2_DIGITS_PRECISION.test(value) : true),
      })
      .test({
        message: intl.formatMessage({ id: `HedgeForm.Errors.${metal}PriceCust.TooSmall` }),
        test: value => (value ? Number(value) > 0 : true),
      })
      .test({
        message: intl.formatMessage({ id: `HedgeForm.Errors.${metal}PriceCust.TooBig` }),
        test: value => !value || +value <= 99999999.99,
      });

  const confirmPriceSchema = (metal: METAL_ABBR) =>
    yup
      .string()
      .nullable()
      .test({
        name: 'isValid',
        message: intl.formatMessage({
          id: `HedgeForm.Errors.${metal.toLowerCase()}PriceCustConfirm` as keyof HedgeMessages,
        }),
        test(val) {
          const price = this.parent[`${metal.toLowerCase()}PriceCust`] as string;
          return !price || val === price;
        },
      });

  return yup
    .object()
    .shape({
      name: yup
        .string()
        .required(intl.formatMessage({ id: 'HedgeForm.Errors.HedgeName.Required' }))
        .trim()
        .min(1, intl.formatMessage({ id: 'HedgeForm.Errors.HedgeName.TooShort' })),
      relatedLot: yup.string().nullable(),
      relatedCompanies: yup
        .array()
        .ensure()
        .min(1, intl.formatMessage({ id: 'HedgeForm.Errors.RelatedCompanies.Required' })),
      parentCompany: yup.mixed().when('name', {
        is: () => canChangeParentCompany,
        then: yup
          .string()
          .required(intl.formatMessage({ id: 'HedgeForm.Errors.ParentCompany.Required' }))
          .nullable(),
      }),

      ptPriceCust: priceSchema(METAL_ABBR.PT),
      pdPriceCust: priceSchema(METAL_ABBR.PD),
      rhPriceCust: priceSchema(METAL_ABBR.RH),

      ptPriceCustConfirm: confirmPriceSchema(METAL_ABBR.PT),
      pdPriceCustConfirm: confirmPriceSchema(METAL_ABBR.PD),
      rhPriceCustConfirm: confirmPriceSchema(METAL_ABBR.RH),

      placedAt: yup
        .date()
        .required(intl.formatMessage({ id: 'HedgeForm.Errors.PlacedAt.Required' }))
        .nullable()
        .test(
          'isLessThanExpiresAt',
          intl.formatMessage({ id: 'HedgeForm.Errors.PlacedAt.Max' }),
          (value, { parent }) =>
            !isNil(value) &&
            !isNil(parent.expiresAt) &&
            value.valueOf() < parent.expiresAt.valueOf(),
        ),
      expiresAt: yup
        .date()
        .test(
          'isMoreThanPlacedAt',
          intl.formatMessage({ id: 'HedgeForm.Errors.ExpiresAt.Min' }),
          (value, { parent }) =>
            !isNil(value) && !isNil(parent.placedAt) && value.valueOf() > parent.placedAt.valueOf(),
        )
        .required(intl.formatMessage({ id: 'HedgeForm.Errors.ExpiresAt.Required' })),
      note: yup.string(),
    })
    .atLeastOneOf(
      ['ptPriceCust', 'pdPriceCust', 'rhPriceCust'],
      intl.formatMessage({ id: 'HedgeForm.Errors.Prices.OneRequired' }),
    );
};
