import styled from '@emotion/styled';

export const FilterContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-template-areas: 'error error' 'input input';
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 32px;
`;
