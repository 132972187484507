import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { Language, Languages } from 'shared/constants';

type State = { language: Language };

const currentLanguage = createSlice<
  State,
  { setLanguage: CaseReducer<State, { type: string; payload: Language }> }
>({
  name: 'currentLanguage',
  initialState: { language: Languages.EN },
  reducers: {
    setLanguage: (_, { payload }) => ({ language: payload }),
  },
});

export const { setLanguage } = currentLanguage.actions;

export default currentLanguage.reducer;
