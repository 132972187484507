import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const removeHedgeSlice = createSlice<GenericStoreSlice, GenericStoreReducer<GenericStoreSlice>>({
  name: 'removeHedge',
  initialState: {
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(),
  },
});

export const {
  pending: removeHedgeAction,
  success: removeHedgeSuccessAction,
  failure: removeHedgeFailureAction,
} = removeHedgeSlice.actions;

export const removeHedge =
  (id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(removeHedgeAction());

      await httpClient.patch(`${apiHostname}/api/hedges/${id}/remove`);

      return dispatch(removeHedgeSuccessAction({}));
    } catch (error) {
      dispatch(removeHedgeFailureAction());

      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };

export default removeHedgeSlice.reducer;
