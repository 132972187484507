import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Hedge } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';
import { parseHedge } from '../parsers/parseHedge';
import { HedgeFormSchema } from '../types/hedgeFormSchema ';

export enum UpdateHedgeKeys {
  UpdateHedge = 'UpdateHedge',
}

interface Params {
  id: string;
  values: HedgeFormSchema;
}

export const useUpdateHedge = (options?: UseMutationOptions<Hedge, AlphamartHttpError, Params>) =>
  useMutation<Hedge, AlphamartHttpError, Params>(
    async params => {
      const hedgeDto = parseHedge(params.values, true);
      const response = await getHttpClient().put<Hedge>(
        `${apiHostname}/api/hedges/${params.id}`,
        hedgeDto,
      );

      return response.data;
    },
    {
      mutationKey: [UpdateHedgeKeys.UpdateHedge],
      ...options,
    },
  );
