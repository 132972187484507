import React from 'react';
import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';
import { IndicatorDot } from '../IndicatorDot/IndicatorDot';
import { Label } from '../Label/Label';
import { Line } from '../Line/Line';

interface Props {
  isActive: boolean;
  label: string;
  last?: boolean;
  single?: boolean;
}
const Container = styled.div`
  padding: 17px 0 0 24px;

  @media ${MEDIA_QUERY.XL} {
    display: grid;
    padding: 34px 0 0;
    align-items: center;
    grid-template-areas: 'label dot' '. line';
    grid-template-rows: 34px calc(100% + 34px);
    grid-template-columns: auto 34px;
  }
`;

export const Indicator = React.memo<Props>(
  ({ isActive, label, last = false, single = false }): React.ReactElement => (
    <Container>
      <Label isActive={isActive}>{label}</Label>
      {!single && <IndicatorDot isActive={isActive} />}
      {!last && <Line />}
    </Container>
  ),
);
