import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const GalleryLayout = styled.div<{ withMainPhoto?: boolean }>`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, 162px);

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;
  }

  ${({ withMainPhoto }) =>
    withMainPhoto &&
    css`
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 30px;

      & > picture {
        &:first-of-type {
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    `}
`;

export const ThumbImageWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;
