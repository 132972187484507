import { createSlice } from '@reduxjs/toolkit';

import { apiHostname, Language } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const changeUserLanguageSlice = createSlice<
  GenericStoreSlice,
  GenericStoreReducer<GenericStoreSlice>
>({
  name: 'changeUserLanguage',
  initialState: { error: undefined },
  reducers: {
    ...getGenericReducers(),
  },
});

export const { success, failure, pending: submitting } = changeUserLanguageSlice.actions;

export default changeUserLanguageSlice.reducer;

export const changeUserLanguage =
  (language: Language): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(submitting());
      await httpClient.put(`${apiHostname}/api/me/language`, { language });
      dispatch(success({}));
    } catch (err) {
      dispatch(failure((err as AlphamartHttpError)?.response?.data?.message));
      throw err;
    }
  };
