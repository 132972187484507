import styled from '@emotion/styled';

import { theme } from 'theme';

export const ListItemContainer = styled.div`
  position: relative;
  border-bottom: 1px solid ${theme.colors.mineShaftLightest};
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ListItemStatus = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 12px;
  right: 5px;
  width: 22px;
`;

export const ListItemContent = styled.div`
  flex-grow: 5;
  padding: 24px;
  word-break: break-word;
`;

export const DateText = styled.span`
  color: ${theme.colors.alphaGray};
`;

export const UnreadMarker = styled.span`
  display: inline-block;
  background-color: ${theme.colors.fuzzyWuzzyBrown};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
