import React from 'react';

import { User } from 'shared/types';
import { TableHeaderPriceInfo } from './ConverterList.styles';

type Props = {
  currentUser: User;
  prefix: {
    hedge?: React.ReactNode;
    market?: React.ReactNode;
  };
  showComingSoon: boolean;
  title: React.ReactNode;
};

export const ConvertersHeaderPrice = ({
  currentUser,
  prefix,
  showComingSoon,
  title,
}: Props): React.ReactElement => {
  const { pdHedgeUsed, ptHedgeUsed, rhHedgeUsed } = currentUser.prices;
  const hedgePriceInUse = [pdHedgeUsed, ptHedgeUsed, rhHedgeUsed].some(hedge => hedge);

  return (
    <div>
      {title}
      {hedgePriceInUse && !showComingSoon && (
        <TableHeaderPriceInfo>
          (<span>{prefix.market}</span>,<span>{prefix.hedge}</span>)
        </TableHeaderPriceInfo>
      )}
    </div>
  );
};
