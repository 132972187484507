import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { parseTheme } from 'shared/parsers/parseTheme';
import { AlphamartHttpError, CompanyTheme } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface ThemeState extends GenericStoreSlice {
  theme: CompanyTheme | undefined;
}

interface FileValueParam {
  id?: number;
  file: Partial<File>;
  image: string;
  name: string;
  size: number;
  data?: string;
}

type UpdateThemeParam = Pick<CompanyTheme, 'mainColor' | 'navigationBarColor'> & {
  logo: FileValueParam[];
  icon: FileValueParam[];
  watermarkLogo: FileValueParam[];
  watermarkOpacity: number;
};
const updateThemeSlice = createSlice<ThemeState, GenericStoreReducer<ThemeState>>({
  name: 'updateTheme',
  initialState: {
    isPending: false,
    theme: undefined,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      theme: payload,
    })),
  },
});
export const {
  pending: updateThemeAction,
  success: updateThemeSuccessAction,
  failure: updateThemeFailureAction,
} = updateThemeSlice.actions;

export const updateTheme =
  (values: UpdateThemeParam, id: number, keepWatermark: boolean): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(updateThemeAction());

      const [logo] = await Promise.all(
        [...values.logo].map(async photo => {
          if (photo.id) {
            return photo;
          }

          const formData = new FormData();
          formData.append('logo', photo.file as Blob);
          return httpClient.post(`${apiHostname}/api/upload/logo`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );

      const [icon] = await Promise.all(
        [...values.icon].map(async photo => {
          if (photo.id) {
            return photo;
          }

          const formData = new FormData();
          formData.append('icon', photo.file as Blob);
          return httpClient.post(`${apiHostname}/api/upload/icon`, formData, {
            headers: { 'content-type': 'multipart/form-data' },
          });
        }),
      );

      const [watermark] = await Promise.all(
        values.watermarkLogo.map(async watermarkLogo => {
          if (watermarkLogo.id && keepWatermark) {
            return watermarkLogo;
          }
          if (watermarkLogo.id) {
            const response = await fetch(watermarkLogo.image, { mode: 'cors' });
            const blob = await response.blob();
            const file = new File([blob], `watermarkLogo-${new Date().valueOf()}.png`, {
              type: blob.type,
            });

            // eslint-disable-next-line no-param-reassign
            watermarkLogo.file = file;
          }

          const formData = new FormData();
          formData.append('watermark', watermarkLogo.file as File);

          return httpClient.post(
            `${apiHostname}/api/upload/watermark?opacity=${values.watermarkOpacity}`,
            formData,
            {
              headers: { 'content-type': 'multipart/form-data' },
            },
          );
        }),
      );
      const parsedTheme = parseTheme(values, logo, icon, watermark);
      const theme = await httpClient.put(`${apiHostname}/api/companies/${id}/theme`, parsedTheme);
      dispatch(updateThemeSuccessAction({ theme: theme.data }));
    } catch (error) {
      dispatch(updateThemeFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default updateThemeSlice.reducer;
