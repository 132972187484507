import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import {
  DataList,
  highlightableTextStyles,
  HighlightSearch,
  ListActions,
  useGetRowId,
} from 'components/shared/List';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { Price } from 'components/shared/Price';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { SearchChangEvent, SearchInput } from 'components/shared/SearchBar/SearchInput';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { isTruthy } from 'helpers/isTruthy/isTruthy';
import { ErrorCode, FilterableModules, ModalFormType, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartLocation, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { matchNormalized } from 'shared/matchers';
import { useRemoveNonstandardConverter } from 'shared/mutations';
import { useGetNonstandardConverters } from 'shared/queries';
import { AlphamartHttpError, ItemAction, NonstandardConverterListItem } from 'shared/types';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { showModalForm } from 'store/modalFormSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { MEDIA_QUERY } from 'theme';
import { NonstandardConvertersNestedList } from './NonstandardConvertersNestedList';
import { VehicleListItemToRender } from '../../Vehicles/VehiclesList/VehicleList.helpers';
import { messages, TypedFormattedMessage, useTypedIntl } from '../locale/messages';
import { NonstandardConverterVehiclesModal } from '../NonstandardConvertersVehicleModal/NonstandardConvertersVehicleModal';
import { SortNonstandardConverters } from '../SortNonstandardConverters/SortNonstandardConverters';

export interface NonstandardConvertersFiltersShape {
  query?: string;
}

const SearchInputLayout = styled.div`
  width: 100%;
  @media ${MEDIA_QUERY.MD} {
    width: 340px;
  }
`;

export const NonstandardConvertersListComponent = () => {
  const intl = useTypedIntl();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const currentUser = useCurrentUser();
  const handleCreateClick = () => navigate('/nonstandard-converters/create');
  const { pageSize } = useAppSelector(state => state.listComponent);
  const listComponent = useAppSelector(state => state.listComponent);
  const savedFilters = useAppSelector(
    state => state.filters[FilterableModules.NONSTANDARD_CONVERTERS],
  );
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const { pathname } = useAlphamartLocation();
  const isSorting = pathname.endsWith('/sort');

  const goToSortNonstandardConverters = () => navigate('/nonstandard-converters/sort');

  const nonstandardConverters = useGetNonstandardConverters({
    page: listComponent.pageIndex + 1,
    pageSize,
    ...savedFilters.data,
  });

  const { mutateAsync: removeNonstandardConverter } = useRemoveNonstandardConverter();

  const handleAssignedVehiclesModalOpen = assignedVehiclesParams => {
    dispatch(
      showModalForm({ modalType: ModalFormType.AssignedVehicles, params: assignedVehiclesParams }),
    );
  };

  const handleRemoveError = async error => {
    if (error.response.data.errorCode === ErrorCode.NONSTANDARD_CONVERTER_IS_ASSIGNED_TO_VEHICLE) {
      const assignedVehicles = error.response.data.errorData;
      handleAssignedVehiclesModalOpen(assignedVehicles);
      return;
    }

    dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
  };

  const handleRemove = useCallback(
    async (nonstandardConverter: Pick<NonstandardConverterListItem, 'id'>) => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'NonstandardConvertersList.Modal.Confirm' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());
            try {
              await removeNonstandardConverter({
                id: nonstandardConverter.id,
              });
              dispatch(
                snackBarPushSuccess(
                  intl.formatMessage({ id: 'NonstandardConvertersList.Modal.MaterialRemoved' }),
                ),
              );
              await nonstandardConverters.refetch();
            } catch (err) {
              handleRemoveError(err as AlphamartHttpError);
            }
          },
        }),
      );
    },
    [dispatch, intl],
  );

  const haveAccessToActions = authorize(PERMISSIONS.NONSTANDARD_CONVERTERS.UPDATE);
  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const onSearchChanged = (changed: SearchChangEvent) => {
    setPageIndex(0);
    dispatch(saveFilters({ filterKey: FilterableModules.NONSTANDARD_CONVERTERS, values: changed }));
  };

  const onSortChanged = () => {
    setPageIndex(0);
    nonstandardConverters.refetch();
  };

  const actions: ItemAction<VehicleListItemToRender>[] = [
    {
      label: <TypedFormattedMessage id="Global.Remove" />,
      onClick: item => handleRemove(item),
      dataCy: item => `remove-${item?.id}`,
      visible: () => haveAccessToActions,
    },
    {
      label: <TypedFormattedMessage id="Global.Update" />,
      onClick: item => navigate(`/nonstandard-converters/${item.id}/edit`),
      dataCy: item => `edit-${item?.id}`,
      visible: () => haveAccessToActions,
    },
  ];

  const tableColumns: ColumnDef<NonstandardConverterListItem>[] = [
    {
      id: 'material',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.Material' }),
      cell: ({ row }) => (
        <HighlightSearch
          className={highlightableTextStyles(false)}
          searchWords={[matchNormalized(savedFilters.data.query)!]}
          textToHighlight={row.original.material}
        />
      ),
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.Price' }),
      cell: ({ row }) => (
        <span>
          <Price
            value={row.original.price}
            language={currentUser.language}
            currency={currentUser.currency}
          />
          {row.original.price > 0 && <span> / {row.original.materialUnit} </span>}
        </span>
      ),
    },
    {
      id: 'buying',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.BuyingPercentAdjustment' }),
      cell: ({ row }) => `${row.original.buyingPercentAdjustment} %`,
    },
    {
      id: 'dollar',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.DollarPriceAdjustment' }),
      cell: ({ row }) =>
        formatCurrency(row.original.dollarPriceAdjustment, 'USD', 2, currentUser.language),
    },
    haveAccessToActions && {
      id: 'actions',
      header: isMd ? intl.formatMessage({ id: 'Global.Actions' }) : '',
      cell: ({ row }) => <ListActions actions={actions} item={row.original} />,
      meta: {
        widthPx: 90,
      },
    },
  ].filter(isTruthy);
  const isDesktop = useMediaQuery(MEDIA_QUERY.MD);

  const table = useReactTable({
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: () => true,
    data: nonstandardConverters.data!.data,
    getRowId: useGetRowId(),
    pageCount: Math.ceil(nonstandardConverters.data!.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    enableRowSelection: false,
    state: {
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      columnSizing: {
        material: 200,
        price: 200,
        buying: 150,
        dollar: 150,
      },
      columnVisibility: {
        price: isDesktop,
        buying: isDesktop,
        dollar: isDesktop,
      },
    },
  });

  return (
    <PanelTemplate>
      <PanelHeader title={<TypedFormattedMessage id="NonstandardConvertersList.Header" />}>
        <ProtectedArea permission={PERMISSIONS.NONSTANDARD_CONVERTERS.CREATE}>
          <AppButton type="button" onClick={handleCreateClick}>
            <TypedFormattedMessage id="Global.Create" />
          </AppButton>
        </ProtectedArea>
        <ProtectedArea permission={PERMISSIONS.NONSTANDARD_CONVERTERS.UPDATE}>
          <AppButton type="button" onClick={goToSortNonstandardConverters}>
            <TypedFormattedMessage id="NonstandardConvertersList.Sort" />
          </AppButton>
        </ProtectedArea>
      </PanelHeader>
      <PanelContent>
        <PanelContentMain>
          <ProtectedArea permission={PERMISSIONS.NONSTANDARD_CONVERTERS.SEARCH}>
            <SearchInputLayout>
              <SearchInput
                loadingState={nonstandardConverters.isFetching}
                onSearchChanged={onSearchChanged}
                initialQuery={savedFilters.data.query}
                searchAfter={2}
                addSearchSession
              />
            </SearchInputLayout>
          </ProtectedArea>
          <DataList
            table={table}
            isLoading={nonstandardConverters.isFetching}
            subComponent={NonstandardConvertersNestedList}
          />
        </PanelContentMain>
      </PanelContent>
      <NonstandardConverterVehiclesModal />
      {isSorting && <SortNonstandardConverters onUpdate={onSortChanged} />}
    </PanelTemplate>
  );
};

export const NonstandardConvertersList = withAlphamartIntlProvider(
  NonstandardConvertersListComponent,
  messages,
);
