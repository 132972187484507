import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { PaginatedData, Status, UserDevice } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum DevicesKeys {
  GetDevices = 'GetDevices',
}

type GetDevicesParams = {
  page?: number;
  pageSize?: number;
  userId?: number;
  status: Status[];
};

export const useGetDevices = (
  params: GetDevicesParams,
  options: UseQueryOptions<UserDevice[]> = {},
): UseQueryResult<UserDevice[]> =>
  useQuery<UserDevice[]>(
    [DevicesKeys.GetDevices, params],
    async () => {
      if (!params.userId) return [];

      const response = await getHttpClient().get<PaginatedData<UserDevice>>(
        `${apiHostname}/api/devices`,
        { params: { page: 1, pageSize: 3, ...params } },
      );

      return response.data?.data ?? [];
    },
    options,
  );
