import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface CrateFolderState extends GenericStoreSlice {
  folder: unknown;
}

const createFolderSlice = createSlice<CrateFolderState, GenericStoreReducer<CrateFolderState>>({
  name: 'createFolder',
  initialState: {
    isPending: false,
    folder: null,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      folder: payload,
    })),
  },
});

export const {
  pending: createFolderAction,
  success: createFolderSuccessAction,
  failure: createFolderFailureAction,
} = createFolderSlice.actions;

export const createFolder =
  (value: string): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(createFolderAction());
      const folder = await httpClient.post(`${apiHostname}/api/folders`, {
        name: value.replace(/\s+/g, ' ').trim(),
      });
      dispatch(createFolderSuccessAction(folder));
      return folder.data;
    } catch (error) {
      dispatch(createFolderFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default createFolderSlice.reducer;
