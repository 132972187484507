import styled from '@emotion/styled';

import { MEDIA_QUERY, theme } from 'theme';

export const ModalContent = styled.div<{ hasForm?: boolean }>`
  background-color: ${theme.colors.mineShaft};
  border-radius: 12px;
  display: grid;
  gap: 10px;
  grid-template-rows: auto;
  height: 75vh;
  padding: 24px;
  width: 75vw;

  form {
    align-items: end;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    padding: 16px;

    form {
      align-items: start;
      flex-direction: column;
    }

    @supports (-webkit-touch-callout: none) {
      margin-top: 64px;
    }
  }
`;

export const ContractText = styled.div`
  background-color: ${theme.colors.mineShaftLightest};
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  padding: 16px;

  .bold {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  h4 {
    text-align: center;
  }

  li {
    margin-bottom: 16px;
  }

  span {
    background-color: ${theme.mainColor}99;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    align-items: start;
  }
`;
