import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { layers, MEDIA_QUERY, Theme } from 'theme';

const BASE_WIDTH = '759px';

export const averageSelectionStyles = (
  enabled: boolean,
  theme: Theme,
  isKeyboardVisible: boolean,
): string => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 26px 32px;

  font-family: ${theme.fonts.montserrat};
  font-size: 12px;
  font-weight: bold;

  position: fixed;
  bottom: 5px;
  left: calc(50% - 420px);
  width: ${BASE_WIDTH};

  background-color: ${theme.colors.mineShaft};
  border: 1px solid ${theme.colors.mineShaft};
  border-radius: 8px;

  z-index: ${enabled ? layers.averageBox : `unset`};
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.9);

  button {
    margin-right: 8px;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    width: calc(${BASE_WIDTH} - ${theme.nav.thin});
    left: calc(50% - ${BASE_WIDTH} / 2 + ${theme.nav.thin});
    z-index: ${layers.averageBox};
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    bottom: ${isKeyboardVisible ? 56 : 6}px;
    width: auto;
    right: 6px;
    border-radius: 17px;
    padding: 0;
    left: auto;
    border: none;
    background-color: ${theme.colors.mineShaft};
    box-shadow: 0 0px 30px rgba(0, 0, 0, 0.17);

    button {
      margin-right: 0;
    }

    ${enabled &&
    `
      padding: 20px;
      bottom: 0px;
      width: 100%;
      right: 0px;
      border-radius: 0;
      background-color: ${theme.colors.mineShaft};
    `}
  }
`;

export const highlightStyles = (theme: Theme): string => css`
  color: ${theme.colors.emerald};
`;

export const averagePriceInfo = (enabled: boolean): string => css`
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;

    ${enabled &&
    `
      display: flex;
    `}
  }
`;

export const selectionButtonStyle = css`
  margin-right: 16px;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;
  }
`;

export const badgeStyles = css`
  padding: 8px 16px;
  border-radius: 8px;
`;

export const HedgeInUseBadge = styled.div<{ theme?: Theme }>`
  color: ${({ theme }) => theme.mainColor};
`;

export const mobileButtonStyle = css`
  @media ${MEDIA_QUERY.MAX_MD} {
    height: 64px;
    border: none;
    width: 100%;
  }
`;
