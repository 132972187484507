import React, { useState } from 'react';
import { debounce } from 'lodash';

import { FieldCreatableSelect } from 'components/shared/Fields/FieldSelect/FieldCreatableSelect';
import {
  FieldSelectOnChangeSingle,
  FieldSelectOption,
} from 'components/shared/Fields/FieldSelect/FieldSelect';
import { useTypedIntl } from 'locale/messages';
import { useGetConverterIdentifiers } from 'shared/queries';

interface ConverterIdentifierSelectProps {
  name: string;
  label: string;
  value?: string;
  'data-cy': string;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: FieldSelectOnChangeSingle;
  error: string | false | undefined;
  onCreateOption: (inputValue: string) => void;
  maskInput?: boolean;
}

export const ConverterIdentifierSelect = ({
  name,
  label,
  value,
  'data-cy': dataCy,
  onFocus,
  onBlur,
  error,
  onChange,
  onCreateOption,
  maskInput,
}: ConverterIdentifierSelectProps) => {
  const [inputValue, setInputValue] = useState('');
  const [createdValue, setCreatedValue] = useState(value);
  const identifiers = useGetConverterIdentifiers({ query: inputValue || value || '', limit: 20 });
  const intl = useTypedIntl();

  const onSelectChange = (newValue: FieldSelectOption | undefined) => {
    setInputValue('');
    setCreatedValue('');
    return onChange(newValue);
  };

  const onCreate = (newValue: string) => {
    setInputValue('');
    setCreatedValue(newValue);
    onCreateOption(newValue);
  };

  const onInputChange = debounce((newInput, actionMeta) => {
    if (actionMeta.action === 'input-change') setInputValue(newInput);
  }, 700);

  const foundOptions = (identifiers.data ?? []).map(id => ({ value: id, label: id }));
  const maskedValue =
    createdValue
      ?.split('')
      ?.map(() => '•')
      ?.join('') ?? '';
  const options = createdValue
    ? [{ value: createdValue, label: maskInput ? maskedValue : createdValue }, ...foundOptions]
    : foundOptions;

  return (
    <FieldCreatableSelect
      label={label}
      name={name}
      options={options}
      onInputChange={onInputChange}
      value={value}
      data-cy={dataCy}
      onFocus={onFocus}
      error={error}
      onBlur={onBlur}
      required
      maxLength={100}
      onChange={onSelectChange}
      onCreateOption={onCreate}
      hideSelectedOptions
      noOptionsMessage={() => intl.formatMessage({ id: 'Global.Fields.Select.StartTyping' })}
    />
  );
};
