import { UpdateOffZoneParam } from 'store/updateOffZonesSlice';

type Bounds = ({ lat: number; lng: number } | google.maps.LatLng)[];

export const parsePolygonPath = (
  id: number | null,
  bounds: Bounds,
  company: number,
): UpdateOffZoneParam => ({
  ...bounds.reduce<{ boundLatitudes: number[]; boundLongitudes: number[] }>(
    (acc, { lat, lng }) => ({
      boundLatitudes: [...acc.boundLatitudes, typeof lat === 'number' ? lat : lat()],
      boundLongitudes: [...acc.boundLongitudes, typeof lng === 'number' ? lng : lng()],
    }),
    { boundLatitudes: [], boundLongitudes: [] },
  ),
  id,
  company,
});
