import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Status } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const changeCompanyStatusSlice = createSlice<
  GenericStoreSlice,
  GenericStoreReducer<GenericStoreSlice>
>({
  name: 'changeCompanyStatus',
  initialState: { error: undefined },
  reducers: {
    ...getGenericReducers(),
  },
});

export const { success, failure, pending } = changeCompanyStatusSlice.actions;

export default changeCompanyStatusSlice.reducer;

export const changeCompanyStatus =
  (id: string | number, status: Status): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(pending());
      await httpClient.put(`${apiHostname}/api/companies/${id}/status`, { status });
      dispatch(success({}));
    } catch (err) {
      dispatch(failure((err as AlphamartHttpError)?.response?.data?.message));
      throw err;
    }
  };
