import ReactModal from 'react-modal';
import { css } from '@emotion/css';

import { layers, theme } from 'theme';

/**
 * File for global styles of modals
 */

const ModalMessageStyle = (): string => css`
  text-align: center;
  margin: 16px 0 32px 0;
  font-size: 14px;
`;

const ModalButtonsStyle = (): string => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;

const ReactModalStyle = (isPhone?: boolean, zIndex?: number): ReactModal.Styles => ({
  overlay: {
    backgroundColor: `${theme.colors.black}DD`,
    zIndex: zIndex ?? layers.termsAndConditionsModal,
    WebkitBackdropFilter: 'blur(2px)',
    backdropFilter: 'blur(2px)',
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(isPhone ? { padding: 0 } : {}),
  },
});

export { ModalButtonsStyle, ModalMessageStyle, ReactModalStyle };
