import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { SideBar } from 'components/shared/SideBar/SideBar';
import { useWindowHeight } from 'shared/hooks';
import { useAlphamartLocation } from 'shared/hooks/useAlphamartRouter';
import { MEDIA_QUERY, NAVBAR_HEIGHT_MOBILE, Theme } from 'theme';

interface StyledPanelProps {
  children: React.ReactNode;
  windowHeight: number;
}

const StyledPanelTemplate = ({ windowHeight, children }: StyledPanelProps): React.ReactElement => (
  <section
    className={css`
      display: flex;
      height: ${windowHeight}px;
      max-height: ${windowHeight}px;
      overflow: hidden;

      @media ${MEDIA_QUERY.MAX_MD} {
        flex-direction: column;
        overflow-y: auto;
        min-height: unset;
        max-height: unset;
      }
    `}
  >
    {children}
  </section>
);

const StyledLeftSide = styled.header<{ showLabels?: boolean; theme?: Theme }>`
  width: ${({ showLabels, theme }) => (showLabels ? theme.nav.wide : theme.nav.thin)};
  min-height: 100vh;
  min-height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;

  @media ${MEDIA_QUERY.MAX_XL} {
    width: ${({ theme }) => theme.nav.thin};
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    min-height: 64px;
    height: 64px;
    min-width: 100vw;
    max-width: 100vw;
  }
`;

const StyledRightSide = styled.section<{
  showLabels?: boolean;
  theme?: Theme;
  windowHeight: number;
}>`
  width: ${({ showLabels, theme }) =>
    `calc(100vw - ${showLabels ? theme.nav.wide : theme.nav.thin})`};
  background-color: ${({ theme }) => theme.colors.codGrayDarker};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;

  @media ${MEDIA_QUERY.MAX_XL} {
    width: ${({ theme }) => `calc(100vw - ${theme.nav.thin})`};
    min-height: 100%;
    max-height: 100%;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100vw;
    height: ${({ windowHeight }) => `calc(${windowHeight}px - ${NAVBAR_HEIGHT_MOBILE})`};
    min-height: ${({ windowHeight }) => `calc(${windowHeight}px - ${NAVBAR_HEIGHT_MOBILE})`};
    max-height: initial;
    overflow-y: auto;
  }
`;

interface Props {
  children: React.ReactNode;
}

const PanelTemplate = ({ children }: Props): React.ReactElement => {
  const windowHeight = useWindowHeight();
  const location = useAlphamartLocation();
  const noLabelLocations = ['/geolocation'];
  const showLabels = noLabelLocations.every(path => !location.pathname.includes(path));

  return (
    <StyledPanelTemplate windowHeight={windowHeight}>
      <StyledLeftSide showLabels={showLabels}>
        <SideBar />
      </StyledLeftSide>
      <StyledRightSide showLabels={showLabels} windowHeight={windowHeight}>
        {children}
      </StyledRightSide>
    </StyledPanelTemplate>
  );
};

export { PanelTemplate };
