import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { DataList, ListActions } from 'components/shared/List';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { SHARED, SUPPORTED_PORTAL_CURRENCIES } from 'shared/constants';
import { useLanguage, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { fetchExchangeRates } from 'store/exchangeRatesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { TypedFormattedMessage as FormattedMessage, useTypedIntl } from '../locale/messages';
import { ListSection } from '../Settings.styles';

const ExchangeRatesInfo = styled.p`
  color: ${theme.colors.alphaGray};
  font-size: 12px;
`;

export const ExchangeRates = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const language = useLanguage();
  const { exchangeRates, isPending } = useAppSelector(state => state.exchangeRates);
  const intl = useTypedIntl();
  useEffect(() => {
    dispatch(fetchExchangeRates());
  }, [dispatch, language]);

  const actions = [
    {
      label: <FormattedMessage id="Settings.ExchangeRates.EditAdjustment" />,
      onClick: item => navigate(`/company/settings/currency/${item.currency}/edit`),
      dataCy: item => `${item.name}-create-adjustment`,
    },
  ];

  const getCurrencyName = currencyPair => {
    const currencyArr = currencyPair.split('/');
    return currencyArr[0] === SUPPORTED_PORTAL_CURRENCIES.USD
      ? SUPPORTED_PORTAL_CURRENCIES.PLN
      : currencyArr[0];
  };

  const columns: ColumnDef<{
    name: string;
    rate: string;
    adjustment: string;
    adjusted: string;
    currency: string;
  }>[] = [
    {
      id: 'currencyPair',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.CurrencyPair' }),
      cell: ({ row }) => row.original.name,
    },
    {
      id: 'rate',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.Rate' }),
      cell: ({ row }) =>
        formatCurrency(
          +row.original.rate,
          language === 'PL' && row.original.name === 'USD/PLN' ? 'PLN' : exchangeRates.currency,
          4,
        ),
    },
    {
      id: 'adjustment',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.Adjustment' }),
      cell: ({ row }) =>
        +row.original.adjustment ? `${row.original.adjustment}%` : SHARED.LONG_DASH,
    },
    {
      id: 'adjusted',
      header: intl.formatMessage({ id: 'Settings.ExchangeRates.Adjusted' }),
      cell: ({ row }) =>
        formatCurrency(
          +row.original.adjusted,
          language === 'PL' && row.original.name === 'USD/PLN' ? 'PLN' : exchangeRates.currency,
          4,
        ),
    },
    {
      id: 'actions',
      header: intl.formatMessage({ id: 'Global.Actions' }),
      cell: ({ row }) => <ListActions actions={actions} item={row.original} />,
      meta: {
        widthPx: 80,
      },
    },
  ];

  const exchangeRatesData = useMemo(
    () =>
      Object.entries(exchangeRates.rates ?? {}).map(([name, value]) => ({
        name,
        ...value,
        currency: getCurrencyName(name),
      })),
    [exchangeRates.rates],
  );

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data: exchangeRatesData,
    getRowId: row => row.name,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        rate: useMediaQuery(MEDIA_QUERY.SM),
        adjustment: useMediaQuery(MEDIA_QUERY.MD),
      },
      columnSizing: {
        currencyPair: 200,
        rate: 200,
        adjustment: 200,
        adjusted: 200,
      },
    },
  });

  return (
    <ListSection>
      <h3>
        <FormattedMessage id="Settings.ExchangeRates.Header.Title" />
      </h3>
      <ExchangeRatesInfo>
        <FormattedMessage id="Settings.SourceInfo.Source" />
        <strong>{exchangeRates.source}</strong>
        <FormattedMessage id="Settings.SourceInfo.Updated" />
        <strong>{exchangeRates.createdAt && formatDate(exchangeRates.createdAt)}</strong>
      </ExchangeRatesInfo>
      <DataList table={table} isLoading={isPending} />
    </ListSection>
  );
};
