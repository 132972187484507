import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useExtendedTheme } from 'shared/hooks';
import { useAppSelector } from 'store/shared/hooks';

const SEO = (): React.ReactElement => {
  const theme = useExtendedTheme();
  const config = useAppSelector(state => state.config);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{config.company?.name ?? 'Alphamart'}</title>
        {theme.navigationBarColor && <meta name="theme-color" content={theme.navigationBarColor} />}
      </Helmet>

      {theme.icon && (
        <Helmet>
          <meta name="msapplication-navbutton-color" content={theme.icon.small} />
          <link rel="icon" type="image/png" href={theme.icon.small} />
          <link rel="apple-touch-icon" href={theme.icon.small} />
        </Helmet>
      )}
    </HelmetProvider>
  );
};

export default SEO;
