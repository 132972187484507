import { AxiosResponse } from 'axios';

import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { AssayFormShape } from 'components/views/Assays/CreateAssay/CreateAssay';
import { checkEmptyString, checkNumber } from 'shared/checkers';

export interface AssayDto {
  carbonPercentage?: number | null;
  converter: {
    folder?: number | null;
    identifier: string;
    isPartial: boolean;
    make?: string | null;
    model?: string | null;
    nicknames?: string | null;
    notes?: string | null;
    otherNumbers?: { original: string; id?: number }[] | null;
    partName?: string | null;
    type?: number | null;
    year?: number | null;
    counterfeit: boolean;
  };
  moisturePercentage?: number | null;
  notes?: string | null;
  palladiumPpm?: number | null;
  platinumPpm?: number | null;
  rhodiumPpm?: number | null;
  sampleDate: string | Date;
  sampleName: string;
  weightWetGrams?: number | null;
}

export type SubmittedAssayFormShape = AssayFormShape & { id?: number; converterId?: number };

export const parseAssay = (
  assay: SubmittedAssayFormShape,
  fileIds: (PhotoShape | AxiosResponse<string>)[],
  editMode = false,
): AssayDto => ({
  converter: {
    ...(assay.converterId ? { id: assay.converterId } : {}),
    identifier: assay.converter.identifier.trim(),
    ...(assay.converter.otherNumbers.length > 0 && {
      otherNumbers: assay.converter.otherNumbers
        .filter(otherNumber => !!otherNumber?.value)
        .map(otherNumber => ({
          original: otherNumber.value.toUpperCase(),
          id: otherNumber.id,
        })),
    }),
    ...(assay.converter.photos.length > 0 && {
      files: assay.converter.photos.map((photo, index) => ({
        externalId:
          (fileIds[index] as AxiosResponse<string>).data ||
          (fileIds[index] as PhotoShape).externalId,
        fileName: photo.name,
        mimetype: photo.file?.type,
        sort: index,
        ...((fileIds[index] as PhotoShape).id ? { id: (fileIds[index] as PhotoShape).id } : {}),
      })),
    }),
    ...((assay.converter.vehicle.make || editMode) && {
      make: checkEmptyString(assay.converter.vehicle.make) ?? null,
    }),
    ...((assay.converter.partName || editMode) && {
      partName: checkEmptyString(assay.converter.partName),
    }),
    ...((assay.converter.nicknames || editMode) && {
      nicknames: checkEmptyString(assay.converter.nicknames),
    }),
    ...((assay.converter.notes || editMode) && {
      notes: checkEmptyString(assay.converter.notes),
    }),
    ...((assay.converter.vehicle.model || editMode) && {
      model: checkEmptyString(assay.converter.vehicle.model) ?? null,
    }),
    ...((assay.converter.vehicle.year || editMode) && {
      year: assay.converter.vehicle.year,
    }),
    ...((assay.converter.type || editMode) && {
      type: checkNumber(assay.converter.type),
    }),
    ...((assay.converter.folder || editMode) && {
      folder: checkNumber(assay.converter.folder),
    }),
    ...((assay.converter.isPartial || editMode) && {
      isPartial: assay.converter.isPartial === 'true',
    }),
    counterfeit: assay.converter.counterfeit,
  },
  sampleName: assay.sampleName.trim(),

  sampleDate: new Date(assay.sampleDate),
  ...((assay.wetWeight || editMode) && { weightWetGrams: checkNumber(assay.wetWeight) }),
  ...((assay.moisture || editMode) && { moisturePercentage: checkNumber(assay.moisture) }),
  ...((assay.carbon || editMode) && { carbonPercentage: checkNumber(assay.carbon) }),
  ...((assay.platinum || editMode) && { platinumPpm: checkNumber(assay.platinum) }),
  ...((assay.palladium || editMode) && { palladiumPpm: checkNumber(assay.palladium) }),
  ...((assay.rhodium || editMode) && { rhodiumPpm: checkNumber(assay.rhodium) }),
  ...((assay.notes || editMode) && { notes: checkEmptyString(assay.notes) }),
});
