import React from 'react';

import { Price } from 'components/shared/Price';
import { useCurrentUser } from 'shared/hooks';

const ShoppingCartPrice = ({ value }: { value: number }): React.ReactElement => {
  const user = useCurrentUser();
  const formattedValue = value > 0 ? value : undefined;

  return <Price value={formattedValue} language={user.language} currency={user.currency} />;
};

export default ShoppingCartPrice;
