import { UserLocationMarker } from 'shared/types';
import { Theme } from 'theme';

export const generatePin = (fill: string | Set<string>, isGradient = false): string =>
  `data:image/svg+xml;charset=UTF-8;base64,${btoa(`<?xml version="1.0" encoding="UTF-8"?>
<svg width="24px" height="34px" viewBox="0 0 28 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Combined Shape</title>
    <g id="Page-4" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Map-Copy" transform="translate(-647.000000, -529.000000)" fill="#FFFFFF">
            <path fill="${
              isGradient ? 'url(#g)' : fill
            }" d="M657,529 C662.522847,529 667,533.477153 667,539 C667,541.184863 665.434972,545.397111 663.543179,549.690722 L663.225067,550.406744 C660.392102,556.732361 657,563 657,563 C657,563 653.607898,556.732361 650.774933,550.406744 L650.456821,549.690722 C648.565028,545.397111 647,541.184863 647,539 C647,533.477153 651.477153,529 657,529 Z M657,534 C654.238576,534 652,536.238576 652,539 C652,541.761424 654.238576,544 657,544 C659.761424,544 662,541.761424 662,539 C662,536.238576 659.761424,534 657,534 Z" id="Combined-Shape"></path>
            <linearGradient id="g">
                ${Array.from(fill).map(
                  (color, i) =>
                    `<stop offset="${
                      (1 / (fill as Set<string>).size) * (i + 0.5)
                    }" stop-color="${color}"/>`,
                )}
            </linearGradient>
        </g>
    </g>
</svg>`)}`;

export const generateCircle = (color: string, opacity: number): string =>
  `data:image/svg+xml;charset=UTF-8;base64,${btoa(`
      <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity="${opacity}" r="70" />
        <circle cx="120" cy="120" opacity="${opacity / 3}" r="120" />
      </svg>
    `)}`;

export const getMarkerIcon = (
  marker: UserLocationMarker[],
  isActive: boolean,
  theme: Theme,
): string => {
  const pinColor = (() => {
    if (marker.length > 1) return new Set(marker.map(({ color }) => color));
    return isActive ? marker[0].color : theme.colors.mineShaftLighter;
  })();

  return generatePin(pinColor, marker.length > 1);
};
