import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { Sort } from 'shared/constants/sort';
import { Status } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { AlphamartHttpError, HedgeListItem } from '../shared/types';

type HedgeFiltersType = {
  status?: Status | null;
  relatedCompanies?: number[] | null;
  parentCompany?: number | null;
  orderBy?: HedgeOrderBy;
  sort?: Sort;
};

interface FetchHedgesState extends GenericStoreSlice {
  count: number;
  list: HedgeListItem[];
}

type FetchHedgesReducer = GenericStoreReducer<FetchHedgesState> & {
  clearHedgesAction: CaseReducer<FetchHedgesState, { type: string }>;
};

const fetchHedgesSlice = createSlice<FetchHedgesState, FetchHedgesReducer>({
  name: 'hedges',
  initialState: {
    list: [],
    count: 0,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      list: payload.data,
      count: payload.count,
    })),
    clearHedgesAction: () => ({
      list: [],
      count: 0,
      isPending: false,
      error: undefined,
    }),
  },
});

export const {
  pending: fetchHedgesAction,
  success: fetchHedgesSuccessAction,
  failure: fetchHedgesFailureAction,
  clearHedgesAction,
} = fetchHedgesSlice.actions;

export const fetchHedges =
  (
    page = 1,
    pageSize = 12,
    { status, relatedCompanies, parentCompany, orderBy, sort }: HedgeFiltersType = {},
  ): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchHedgesAction());
      const { data } = await httpClient.get(`${apiHostname}/api/hedges`, {
        params: {
          page,
          pageSize,
          status,
          relatedCompany: relatedCompanies,
          parentCompany,
          orderBy,
          sort,
        },
      });

      dispatch(fetchHedgesSuccessAction(data));
    } catch (error) {
      dispatch(fetchHedgesFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default fetchHedgesSlice.reducer;
