import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import useDeepCompareEffect from 'use-deep-compare-effect';

import filterIcon from 'assets/images/icons/filter.svg';
import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import {
  DataGridContainer,
  DataList,
  GridCell,
  GridCellContent,
  highlightableTextStyles,
  HighlightSearch,
  ItemThumbnail,
  ListActions,
  TableCell,
  TableCellContent,
  useGetRowId,
} from 'components/shared/List';
import { LoadableContent } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelTemplate } from 'components/shared/PanelTemplate/PanelTemplate';
import { ProtectedArea } from 'components/shared/ProtectedArea/ProtectedArea';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import { formatDate } from 'helpers/dateTime/dateTime';
import { FilterableModules, LIST_MODE, PERMISSIONS } from 'shared/constants';
import { useAssayEditActions, useMediaQuery } from 'shared/hooks';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { matchIdentifier, matchSampleName } from 'shared/matchers';
import { ItemAction } from 'shared/types';
import { AssayListItem } from 'shared/types/assayListItem';
import { clearAssay, fetchAssay } from 'store/assayDetailsSlice';
import { fetchAssays } from 'store/assaysListSlice';
import { changePageIndexAction, changePagination } from 'store/listComponentSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { AssayFiltersShape, AssaysFilters } from './AssaysFilters';
import { AssaysListGridItem } from './AssaysListGridItem';
import { AssaysSearchRow } from './AssaysSearchRow';
import { AssayDetails } from '../AssayDetails/AssayDetails';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const AssaysListComponent = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const assays = useAppSelector(state => state.assays);
  const assayDetails = useAppSelector(state => state.assayDetails);
  const removeAssayState = useAppSelector(state => state.removeAssay);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.ASSAYS]);
  const isMd = useMediaQuery(MEDIA_QUERY.MD);

  const listComponent = useAppSelector(state => state.listComponent);
  const { id } = useParams<{ id: string }>();

  const [filtersOpen, setFiltersOpen] = useState(false);
  const onFiltersToggle = () => setFiltersOpen(open => !open);
  const intl = useTypedIntl();
  const { position } = useAppSelector(state => state.position);

  const { actionsPending, handleRemove } = useAssayEditActions();

  const createConverter = () => navigate('/assays/create');

  const isDeletedView = savedFilters.data.removed;

  useDeepCompareEffect(() => {
    if (!removeAssayState.isPending && position)
      dispatch(
        fetchAssays(
          listComponent.pageIndex + 1,
          listComponent.pageSize,
          savedFilters.data,
          position,
        ),
      );
  }, [
    listComponent.pageIndex,
    listComponent.pageSize,
    savedFilters,
    removeAssayState.isPending,
    dispatch,
    !!position,
  ]);

  useEffect(() => {
    dispatch(id && position ? fetchAssay(+id, position) : clearAssay());
  }, [id, dispatch, !!position]);

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const onFiltersChanged = (changed: AssayFiltersShape) => {
    setPageIndex(0);
    dispatch(saveFilters({ filterKey: FilterableModules.ASSAYS, values: changed }));
  };

  const goToAssay = (item: AssayListItem) => {
    setFiltersOpen(false);
    navigate(`/assays/${item.id}`);
  };

  const defaultActions: ItemAction<AssayListItem>[] = [
    {
      label: <FormattedMessage id="Global.Remove" />,
      onClick: item => handleRemove(item),
      dataCy: item => `remove-${item?.id}`,
      visible: item => !item?.deletedAt,
    },
    {
      label: <FormattedMessage id="Global.Update" />,
      onClick: item => navigate(`/assays/${item.id}/edit`),
      dataCy: item => `update-${item?.id}`,
      visible: item => !item?.deletedAt,
    },
  ];

  const actions = isDeletedView === 'true' ? [] : defaultActions;

  const tableColumns: ColumnDef<AssayListItem>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'AssaysList.TableHeader.Image' }),
      cell: ({ row }) =>
        ItemThumbnail({
          attrAlt: row.original.sampleName,
          counterfeit: {
            notes: row.original.converterNotes,
            show: row.original.converterCounterfeit,
          },
          photo: row.original.converterPhoto,
        }),
    },
    {
      id: 'sampleName',
      header: () => intl.formatMessage({ id: 'AssaysList.TableHeader.SampleName' }),
      cell: ({ row }) =>
        HighlightSearch({
          className: highlightableTextStyles(false),
          searchWords: [matchSampleName(savedFilters.data.query!)!],
          textToHighlight: row.original.sampleName,
        }),
    },
    {
      id: 'relatedConverter',
      header: intl.formatMessage({ id: 'AssaysList.TableHeader.RelatedConverter' }),
      cell: ({ row }) =>
        HighlightSearch({
          className: highlightableTextStyles(false),
          searchWords: [matchIdentifier(savedFilters.data.query!)!],
          textToHighlight: row.original.converterIdentifier,
        }),
    },
    {
      id: 'sampleDate',
      header: intl.formatMessage({ id: 'AssaysList.TableHeader.SampleDate' }),
      cell: ({ row }) => formatDate(row.original.sampleDate),
    },
    {
      id: 'actions',
      header: isMd ? intl.formatMessage({ id: 'Global.Actions' }) : '',
      cell: ({ row }) => <ListActions actions={actions} item={row.original} />,
      meta: {
        widthPx: 90,
      },
    },
  ];

  const table = useReactTable({
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    data: assays.list,
    getRowId: useGetRowId(),
    pageCount: Math.ceil(assays.count / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    manualPagination: true,
    enableRowSelection: false,
    state: {
      columnVisibility: {
        relatedConverter: useMediaQuery(MEDIA_QUERY.SM),
        sampleDate: useMediaQuery(MEDIA_QUERY.MD),
        actions: !!actions?.length,
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      columnSizing: {
        image: 150,
        sampleName: 200,
        relatedConverter: 200,
        sampleDate: 200,
      },
      globalFilter: savedFilters.data.query,
    },
  });

  return (
    <PanelTemplate>
      <PanelHeader title={<FormattedMessage id="AssaysList.Header" />}>
        <ProtectedArea permission={PERMISSIONS.ASSAYS.CREATE}>
          <AppButton type="button" onClick={createConverter}>
            <FormattedMessage id="Global.Create" />
          </AppButton>
        </ProtectedArea>
      </PanelHeader>
      <PanelContent>
        <PanelContentMain disableScroll={assays.isPending}>
          <SearchBar
            isSearchPending={assays.isPending}
            onSearchChanged={onFiltersChanged}
            initialQuery={savedFilters.data.query!}
            searchAfter={2}
            onFiltersToggle={onFiltersToggle}
            chooseListType
            savedFilters={savedFilters}
          />
          <DataList
            table={table}
            onRowClicked={goToAssay}
            cellComponent={listComponent.listMode === LIST_MODE.GRID ? GridCell : TableCell}
            cellContentComponent={
              listComponent.listMode === LIST_MODE.GRID ? GridCellContent : TableCellContent
            }
            rowComponent={
              listComponent.listMode === LIST_MODE.GRID ? AssaysListGridItem : AssaysSearchRow
            }
            headerBodyComponent={listComponent.listMode === LIST_MODE.GRID ? null : React.Fragment}
            dataBodyComponent={
              listComponent.listMode === LIST_MODE.GRID
                ? DataGridContainer(listComponent.columnsQuantity)
                : React.Fragment
            }
            isLoading={assays.isPending || actionsPending}
          />
        </PanelContentMain>
        <PanelContentSidebar
          header={intl.formatMessage({ id: 'Global.Filters' })}
          headerIcon={filterIcon}
          open={filtersOpen}
          onSidebarClosed={onFiltersToggle}
        >
          <AssaysFilters onFiltersChanged={onFiltersChanged} onFiltersApplied={onFiltersToggle} />
        </PanelContentSidebar>
      </PanelContent>
      <LoadableContent loading={assayDetails.isPending}>
        <AssayDetails assay={assayDetails.assay!} />
      </LoadableContent>
    </PanelTemplate>
  );
};

export const AssaysList = withAlphamartIntlProvider(AssaysListComponent, messages);
