import React from 'react';
import styled from '@emotion/styled';
import { ColumnDef, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { isEqual, isNil } from 'lodash';

import { DataList } from 'components/shared/List';
import { Price } from 'components/shared/Price';
import { formatDate } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { SHARED, UNITS } from 'shared/constants';
import { useCurrentUser, useMediaQuery } from 'shared/hooks';
import { NonstandardConverterListItem } from 'shared/types';
import { MEDIA_QUERY, Theme } from 'theme';
import { useTypedIntl } from '../locale/messages';

const NestedTableContainer = styled.div<{ theme?: Theme }>`
  position: relative;
  border: ${({ theme }) => theme.colors.mineShaft};
  margin: 5px 0 10px;
  background: ${({ theme }) => theme.colors.mineShaftLightest};
  box-shadow: 0 0 0 5px ${({ theme }) => theme.colors.mineShaftLightest};
  border-radius: 0 0 8px 8px;
`;

interface Props {
  row: Row<NonstandardConverterListItem>;
}

type NestedData = Partial<NonstandardConverterListItem>;

const TablePrices = ({ row: data }: Props) => {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();

  const columnsPrices: ColumnDef<NestedData>[] = [
    {
      id: 'buying',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.BuyingPercentAdjustment' }),
      cell: ({ row }) => `${row.original.buyingPercentAdjustment} %`,
    },
    {
      id: 'dollar',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.DollarPriceAdjustment' }),
      cell: ({ row }) =>
        formatCurrency(row.original.dollarPriceAdjustment, 'USD', 2, currentUser.language),
    },
    {
      id: 'weightDryLbs',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.WeightDryLbs',
      }),
      cell: ({ row }) =>
        row.original.weightDryLbs ? `${row.original.weightDryLbs} ${UNITS.LBS}` : SHARED.LONG_DASH,
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'NonstandardConvertersList.Price' }),
      cell: ({ row }) => (
        <span>
          <Price
            value={row.original.price}
            language={currentUser.language}
            currency={currentUser.currency}
          />
          {!isNil(row.original.price) && row.original.price > 0 && (
            <span> / {row.original.materialUnit} </span>
          )}
        </span>
      ),
    },
  ];

  const tablePrices = useReactTable({
    columns: columnsPrices,
    data: [data.original],
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
  });

  return <DataList table={tablePrices} />;
};

const TableAssays = ({ row: data }: Props) => {
  const intl = useTypedIntl();

  const columnsAssays: ColumnDef<NestedData>[] = [
    {
      id: 'weightDryLbs',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.WeightDryLbs',
      }),
      cell: ({ row }) =>
        row.original.weightDryLbs ? `${row.original.weightDryLbs} ${UNITS.LBS}` : SHARED.LONG_DASH,
    },
    {
      id: 'platinumAssay',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.PlatinumAssay',
      }),
      cell: ({ row }) =>
        !isNil(row.original.platinumAssay) ? `${row.original.platinumAssay} ppm` : SHARED.LONG_DASH,
    },
    {
      id: 'palladiumAssay',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.PalladiumAssay',
      }),
      cell: ({ row }) =>
        !isNil(row.original.palladiumAssay)
          ? `${row.original.palladiumAssay} ppm`
          : SHARED.LONG_DASH,
    },
    {
      id: 'rhodiumAssay',
      header: intl.formatMessage({
        id: 'NonstandardConvertersList.RhodiumAssay',
      }),
      cell: ({ row }) =>
        !isNil(row.original.rhodiumAssay) ? `${row.original.rhodiumAssay} ppm` : SHARED.LONG_DASH,
    },
    {
      id: 'changedBy',
      header: intl.formatMessage({ id: 'Global.ChangedBy' }),
      cell: ({ row }) => {
        const updatedBy = row.original.updatedBy ?? row.original.createdBy;
        return updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : SHARED.LONG_DASH;
      },
    },
    {
      id: 'changedOn',
      header: intl.formatMessage({ id: 'Global.ChangedOn' }),
      cell: ({ row }) => formatDate(row.original.updatedAt ?? row.original.createdAt),
    },
  ];

  const isDesktop = useMediaQuery(MEDIA_QUERY.MD);

  const tableAssays = useReactTable({
    columns: columnsAssays,
    data: [data.original],
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
    state: {
      columnVisibility: { weightDryLbs: isDesktop, changedOn: isDesktop, changedBy: isDesktop },
    },
  });

  return <DataList table={tableAssays} />;
};

const NonstandardConvertersNestedListComponent = ({ row }: Props) => {
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_MD);

  return (
    <NestedTableContainer>
      {isMobile && <TablePrices row={row} />}
      <TableAssays row={row} />
    </NestedTableContainer>
  );
};

export const NonstandardConvertersNestedList = React.memo(
  NonstandardConvertersNestedListComponent,
  (prevProps, nextProps) => isEqual(prevProps.row.original, nextProps.row.original),
);
