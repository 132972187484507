import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, CompanyFormData } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

export const updateCompany =
  (values: CompanyFormData, id: number, skipUniqueChecker: boolean): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const { parentCompany, type, ...updateValues } = values;
      await httpClient.put(
        `${apiHostname}/api/companies/${id}?skipUniquenessCheck=${skipUniqueChecker}`,
        updateValues,
      );
    } catch (error) {
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };
