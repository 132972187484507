import { UpdateCompanyMetalsFormData } from 'components/views/Settings/MetalPrices/UpdateMetalPrices';
import { apiHostname, FeedTypes } from 'shared/constants';
import { parseCompanyMetalPrice } from 'shared/parsers/parseCompanyMetalPrice';
import { Metals } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

type UpdateCompanyMetalPrice = {
  type: FeedTypes;
  value: number;
  metal: Metals;
};
export interface UpdateCompanyMetalsPriceDto {
  [Metals.PALLADIUM]: UpdateCompanyMetalPrice;
  [Metals.PLATINUM]: UpdateCompanyMetalPrice;
  [Metals.RHODIUM]: UpdateCompanyMetalPrice;
}
export const updateMetalAdjustment =
  (companyId: number, values: UpdateCompanyMetalsFormData): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    const adjustmentDto = parseCompanyMetalPrice(values);
    try {
      await httpClient.put(`${apiHostname}/api/companies/${companyId}/metal-prices`, adjustmentDto);
    } catch (error) {
      return Promise.reject(error);
    }
  };
