import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, NonstandardConverter, SuccessResponse } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum NonstandardConvertersKeys {
  CreateNonstandardConverter = 'CreateNonstandardConverter',
  UpdateNonstandardConverter = 'UpdateNonstandardConverter',
  RemoveNonstandardConverter = 'RemoveNonstandardConverter',
  SortNonstandardConverters = 'SortNonstandardConverters',
}

type MutationError = AlphamartHttpError<{ materials: string[] }>;

export const useCreateNonstandardConverter = (
  options?: UseMutationOptions<NonstandardConverter[], MutationError, NonstandardConverter[]>,
) =>
  useMutation(
    async (data: NonstandardConverter[]) => {
      const response = await getHttpClient().post<NonstandardConverter[]>(
        `${apiHostname}/api/nonstandard-converters`,
        {
          data,
        },
      );

      return response.data;
    },
    {
      mutationKey: [NonstandardConvertersKeys.CreateNonstandardConverter],
      ...options,
    },
  );

interface UpdateParams {
  id: string;
  values: NonstandardConverter;
}

interface DeleteParams {
  id: number;
}

interface SortParams {
  nonstandardConvertersIds: number[];
}

export const useUpdateNonstandardConverter = (
  options?: UseMutationOptions<NonstandardConverter, MutationError, UpdateParams>,
) =>
  useMutation(
    async (data: UpdateParams) => {
      const response = await getHttpClient().put<NonstandardConverter>(
        `${apiHostname}/api/nonstandard-converters/${data.id}`,
        data.values,
      );

      return response.data;
    },
    {
      mutationKey: [NonstandardConvertersKeys.UpdateNonstandardConverter],
      ...options,
    },
  );

export const useRemoveNonstandardConverter = (
  options?: UseMutationOptions<NonstandardConverter, MutationError, DeleteParams>,
) =>
  useMutation(
    async data => {
      const response = await getHttpClient().patch<NonstandardConverter>(
        `${apiHostname}/api/nonstandard-converters/${data.id}/remove`,
      );
      return response.data;
    },
    {
      mutationKey: [NonstandardConvertersKeys.RemoveNonstandardConverter],
      ...options,
    },
  );

export const useSortNonstandardConverters = (
  options?: UseMutationOptions<SuccessResponse, MutationError, SortParams>,
) =>
  useMutation(
    async data => {
      const response = await getHttpClient().put<SuccessResponse>(
        `${apiHostname}/api/nonstandard-converters/sort`,
        data,
      );

      return response.data;
    },
    {
      mutationKey: [NonstandardConvertersKeys.SortNonstandardConverters],
      ...options,
    },
  );
