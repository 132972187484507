import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { FormikProvider, useFormik } from 'formik';
import { isNil } from 'lodash';

import iconConfirm from 'assets/images/icons/confirm.svg';
import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { CancelButton, Icon } from 'components/shared/Buttons';
import { FieldColorPicker } from 'components/shared/Fields/FieldColorPicker/FieldColorPicker';
import { FieldPhotos } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import AppButton from 'components/shared/forms/AppButton/AppButton';
import { LoadableContent } from 'components/shared/Loader';
import { Slider } from 'components/shared/Slider/Slider';
import {
  useAlphamartLocation,
  useAlphamartNavigate,
  useCurrentUser,
  useExtendedTheme,
} from 'shared/hooks';
import { fetchConfig } from 'store/config';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { updateTheme } from 'store/updateThemeSlice';
import { MAIN_COLOR_PALLETE, NAVIGATION_BAR_COLOR_PALLETE } from 'theme';
import { designFormSchema } from './designFormSchema';
import {
  clearSettingsButton,
  DisplaySettingsFieldButtons,
  DisplaySettingsFieldSection,
  DisplaySettingsFieldSectionTitle,
  DisplaySettingsMain,
  DisplaySettingsMockupContainer,
  DisplaySettingsSidebar,
  DisplaySettingsTitle,
} from './DisplaySettings.styles';
import { Mockup } from './Mockup';
import {
  messages,
  TypedFormattedMessage as FormattedMessage,
  useTypedIntl,
} from '../locale/messages';

const DisplaySettingsComponent = (): React.ReactElement => {
  const [watermarkPreview, setWatermarkPreview] = useState<string | null>(null);
  const [isGeneratingWatermark, setGeneratingWatermark] = useState(false);
  const upload = useAppSelector(state => state.config?.upload);
  const user = useCurrentUser();
  const theme = useExtendedTheme();
  const { theme: currentTheme } = useAppSelector(state => state.config || {});
  const { isPending: isUpdatingTheme } = useAppSelector(state => state.theme);

  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const location = useAlphamartLocation();

  const formikContext = useFormik({
    initialValues: {
      mainColor: currentTheme?.mainColor ?? theme.mainColor,
      navigationBarColor: currentTheme?.navigationBarColor ?? theme.colors.mineShaftDarker,
      logo: currentTheme?.logo
        ? [
            {
              file: {
                name: currentTheme?.logo.fileName,
                type: currentTheme?.logo.mimetype,
              } as File,
              data: currentTheme?.logo.externalId,
              name: currentTheme?.logo.fileName,
              size: 1,
              image: currentTheme?.logo?.presignedUrls?.ORIGINAL as string,
              id: currentTheme?.logo.id,
            },
          ]
        : [],
      icon: currentTheme?.icon
        ? [
            {
              file: {
                name: currentTheme?.icon.fileName,
                type: currentTheme?.icon.mimetype,
              } as File,
              data: currentTheme?.icon.externalId,
              name: currentTheme?.icon.fileName,
              size: 1,
              image: currentTheme?.icon.presignedUrls!.ORIGINAL as string,
              id: currentTheme?.icon.id,
            },
          ]
        : [],
      watermarkLogo: currentTheme?.watermarkLogo
        ? [
            {
              file: {
                name: currentTheme?.watermarkLogo.fileName,
                type: currentTheme?.watermarkLogo.mimetype,
              } as File,
              data: currentTheme?.watermarkLogo.externalId,
              name: currentTheme?.watermarkLogo.fileName,
              size: 1,
              image: currentTheme?.watermarkLogo.presignedUrls!.ORIGINAL as string,
              id: currentTheme?.watermarkLogo.id,
            },
          ]
        : [],
      watermarkOpacity: currentTheme?.watermarkOpacity ?? 0,
    },
    enableReinitialize: true,
    validationSchema: designFormSchema(upload!, intl),
    onSubmit: async newTheme => {
      try {
        await dispatch(
          updateTheme(
            newTheme,
            user?.company.id,
            newTheme.watermarkOpacity === currentTheme?.watermarkOpacity,
          ),
        );
        await dispatch(
          snackBarPushSuccess(intl.formatMessage({ id: 'Settings.Display.UpdateSuccess' })),
        );
        await dispatch(fetchConfig());
        navigate('/company/settings');
      } catch {
        dispatch(snackBarPushFailure(<FormattedMessage id="Global.Error.SomethingWentWrong" />));
      }
    },
  });

  const onCancel = useCallback(() => {
    dispatch(
      showModal({
        message: intl.formatMessage({ id: 'Settings.Display.Modal.Message' }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: () => {
          navigate('/company/settings');
          dispatch(hideModal());
        },
      }),
    );
  }, [location, dispatch]);

  const {
    handleChange,
    values,
    handleSubmit,
    initialValues,
    touched,
    getFieldMeta,
    setFieldTouched,
    setFieldValue,
    isValid,
  } = formikContext;

  const prepareInitialWatermark = async () => {
    setGeneratingWatermark(true);
    const image = await fetch(initialValues.watermarkLogo[0]?.image);
    const initialWatermark = await import('helpers/watermarks/prepareWatermark').then(
      ({ prepareWatermark }) => prepareWatermark(image),
    );
    const reader = image.body!.getReader();
    let originalLogo: Uint8Array[] = [];
    await reader.read().then(function prepareImage({ value, done }) {
      if (done) return;
      originalLogo = originalLogo.concat(value);

      return reader.read().then(prepareImage);
    });
    const file = new File(originalLogo, initialValues.watermarkLogo[0].file.name, {
      type: initialValues.watermarkLogo[0].file.type,
    });

    setFieldValue('watermarkLogo', [
      {
        ...initialValues.watermarkLogo[0],
        file,
      },
    ]);

    setGeneratingWatermark(false);
    return setWatermarkPreview(initialWatermark);
  };

  useEffect(() => {
    if (initialValues.watermarkLogo.length) prepareInitialWatermark();
  }, [initialValues.watermarkLogo.length]);

  useEffect(() => {
    if (!values.watermarkLogo.length) {
      setWatermarkPreview(null);
      setFieldValue('watermarkOpacity', 0);
    }
  }, [values.watermarkLogo.length]);

  const getErrors = name => Object.keys(touched).includes(name) && getFieldMeta(name).error;

  const handleWatermarkLogoChange = useCallback(
    async e => {
      handleChange(e);
      const isExtensionValid = e.target.value.every(file =>
        new RegExp(`${upload?.watermark?.imageExtensions?.join('|')}$`, 'i').test(file.name),
      );
      const isAllowedSize =
        !isNil(upload?.watermark?.fileSizeLimit) &&
        e.target.value.every(file => file.size <= upload!.watermark.fileSizeLimit);

      if (e.target.value.length && isExtensionValid && isAllowedSize) {
        setGeneratingWatermark(true);
        setFieldValue('watermarkOpacity', 20);

        const image = await import('helpers/watermarks/prepareWatermark').then(
          ({ prepareWatermark }) => prepareWatermark(e.target.value[0].image),
        );

        setGeneratingWatermark(false);
        return setWatermarkPreview(image);
      }
    },
    [setWatermarkPreview, setGeneratingWatermark, values.watermarkOpacity, upload],
  );

  const handleTransparencyChange = useCallback(
    val => {
      setFieldValue('watermarkOpacity', val);
    },
    [setFieldValue, values],
  );

  const handleReset = async () => {
    formikContext.resetForm();
    setFieldValue('logo', initialValues.logo, false);
    await prepareInitialWatermark();
    setFieldValue('watermarkLogo', initialValues.watermarkLogo, false);
  };

  const isResetDisabled =
    values.logo[0]?.image === initialValues.logo[0]?.image &&
    values.mainColor === initialValues.mainColor &&
    values.navigationBarColor === initialValues.navigationBarColor &&
    values.watermarkLogo[0]?.image === initialValues.watermarkLogo[0]?.image &&
    values.watermarkOpacity === initialValues.watermarkOpacity;

  const opacityIsVisible = !!values.watermarkLogo?.length;

  return (
    <LoadableContent mode={LoadableContent.MODE.FULL} loading={isUpdatingTheme}>
      <DisplaySettingsMain>
        <FormikProvider value={formikContext}>
          <DisplaySettingsSidebar>
            <form onSubmit={handleSubmit}>
              <DisplaySettingsTitle>
                <FormattedMessage id="Settings.Display.ApplicationDesign" />
              </DisplaySettingsTitle>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Settings.Display.CompanyLogo" />
                </DisplaySettingsFieldSectionTitle>
                <FieldPhotos
                  name="logo"
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('logo')}
                  onTouch={setFieldTouched}
                  allowedExtensions={upload?.logo?.imageExtensions as string[]}
                  initialPhotos={values.logo}
                  data-cy="logo"
                  sortable={false}
                  error={getErrors('logo')}
                  single
                  fileSizeLimit={upload?.logo?.fileSizeLimit as number}
                />
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Settings.Display.CompanyIcon" />
                </DisplaySettingsFieldSectionTitle>
                <FieldPhotos
                  name="icon"
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('icon')}
                  onTouch={setFieldTouched}
                  allowedExtensions={upload?.icon?.imageExtensions as string[]}
                  initialPhotos={values.icon}
                  data-cy="icon"
                  sortable={false}
                  error={getErrors('icon')}
                  single
                  fileSizeLimit={upload?.icon?.fileSizeLimit as number}
                />
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Settings.Display.MainColor" />
                </DisplaySettingsFieldSectionTitle>
                <FieldColorPicker
                  name="mainColor"
                  palette={MAIN_COLOR_PALLETE}
                  value={values.mainColor}
                  onChange={handleChange}
                  label={<FormattedMessage id="Settings.Display.Color" />}
                />
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Settings.Display.NavigationBarColor" />
                </DisplaySettingsFieldSectionTitle>
                <FieldColorPicker
                  name="navigationBarColor"
                  palette={NAVIGATION_BAR_COLOR_PALLETE}
                  value={values.navigationBarColor}
                  onChange={handleChange}
                  label={<FormattedMessage id="Settings.Display.Color" />}
                />
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Settings.Display.WatermarkLogo" />
                </DisplaySettingsFieldSectionTitle>
                <FieldPhotos
                  name="watermarkLogo"
                  onChange={handleWatermarkLogoChange}
                  onBlur={() => setFieldTouched('watermarkLogo')}
                  onTouch={setFieldTouched}
                  allowedExtensions={upload?.watermark?.imageExtensions as string[]}
                  initialPhotos={values.watermarkLogo}
                  data-cy="watermark-logo"
                  sortable={false}
                  error={getErrors('watermarkLogo')}
                  single
                  fileSizeLimit={upload?.watermark?.fileSizeLimit as number}
                />
                {opacityIsVisible && (
                  <>
                    <DisplaySettingsFieldSectionTitle>
                      <FormattedMessage id="Settings.Display.WatermarkOpacity" />
                    </DisplaySettingsFieldSectionTitle>
                    <Slider
                      min={0}
                      max={100}
                      value={values.watermarkOpacity}
                      onChange={handleTransparencyChange}
                      dataCy="watermark-opacity"
                    />
                  </>
                )}
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldSectionTitle>
                  <FormattedMessage id="Global.Restore" />
                </DisplaySettingsFieldSectionTitle>
                <AppButton
                  type="button"
                  className={clearSettingsButton}
                  disabled={isResetDisabled}
                  onClick={handleReset}
                >
                  <FormattedMessage id="Global.ClearChanges" />
                </AppButton>
              </DisplaySettingsFieldSection>
              <DisplaySettingsFieldSection>
                <DisplaySettingsFieldButtons>
                  <CancelButton styleType="flex" onClick={onCancel}>
                    <FormattedMessage id="Global.Cancel" />
                  </CancelButton>
                  <AppButton
                    type="submit"
                    className={css`
                      padding: 0;
                    `}
                    disabled={!isValid}
                  >
                    <Icon
                      icon={iconConfirm}
                      color={isValid ? theme.mainColor : theme.colors.mineShaft}
                    />
                    <FormattedMessage id="Global.Save" />
                  </AppButton>
                </DisplaySettingsFieldButtons>
              </DisplaySettingsFieldSection>
            </form>
          </DisplaySettingsSidebar>
        </FormikProvider>
        <DisplaySettingsMockupContainer>
          <Mockup
            logo={values.logo.length === 1 ? values.logo[0] : null}
            mainColor={values.mainColor}
            navigationBarColor={values.navigationBarColor}
            watermarkPreview={watermarkPreview}
            watermarkOpacity={values.watermarkOpacity}
            watermarkLogo={values.watermarkLogo?.[0]}
            isGeneratingWatermark={isGeneratingWatermark}
          />
        </DisplaySettingsMockupContainer>
      </DisplaySettingsMain>
    </LoadableContent>
  );
};

export const DisplaySettings = withAlphamartIntlProvider(DisplaySettingsComponent, messages);
