import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { ErrorCode } from 'shared/constants';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useUpdateNonstandardConverter } from 'shared/mutations';
import { parseNonstandardConverter } from 'shared/parsers/parseNonstandardConverter';
import { useGetNonstandardConverter } from 'shared/queries';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { messages, useTypedIntl } from '../locale/messages';
import {
  NonstandardConverterFormShape,
  NonstandardConvertersForm,
  SingleNonstandardConverterFormData,
} from '../NonstandardConvertersForm/NonstandardConvertersForm';

const UpdateNonstandardConverterComponent = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { data: nonstandardConverterData, isFetching } = useGetNonstandardConverter(id!);
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const updateMutation = useUpdateNonstandardConverter();

  const handleSubmit = async (values: NonstandardConverterFormShape[]) => {
    const [data] = values.map(parseNonstandardConverter);
    try {
      await updateMutation.mutateAsync({
        id: id!,
        values: data,
      });
      dispatch(
        snackBarPushSuccess(intl.formatMessage({ id: 'NonstandardConvertersForm.Update.Success' })),
      );
      navigate('/nonstandard-converters');
    } catch (error) {
      dispatch(
        snackBarPushFailure(intl.formatMessage({ id: 'NonstandardConvertersForm.Update.Failure' })),
      );
    }
  };

  const backendMaterialErrors =
    updateMutation.error?.response?.data?.errorCode ===
    ErrorCode.NONSTANDARD_CONVERTER_MATERIAL_NOT_UNIQUE
      ? updateMutation.error?.response?.data?.errorData?.materials
      : null;

  const initialData = useMemo<SingleNonstandardConverterFormData | null>(() => {
    if (!nonstandardConverterData) return null;
    const initialValues: NonstandardConverterFormShape = {
      material: `${nonstandardConverterData?.material}`,
      pricePer: nonstandardConverterData?.materialUnit,
      buyingPercentAdjustment: `${nonstandardConverterData?.buyingPercentAdjustment}`,
      dollarPriceAdjustment: `${nonstandardConverterData?.dollarPriceAdjustment}`,
      weightDryLbs: `${nonstandardConverterData?.weightDryLbs ?? ''}`,
      confirmWeightDryLbs: `${nonstandardConverterData?.weightDryLbs ?? ''}`,
      platinumAssay: `${nonstandardConverterData?.platinumAssay ?? ''}`,
      confirmPlatinumAssay: `${nonstandardConverterData?.platinumAssay ?? ''}`,
      palladiumAssay: `${nonstandardConverterData?.palladiumAssay ?? ''}`,
      confirmPalladiumAssay: `${nonstandardConverterData?.palladiumAssay ?? ''}`,
      rhodiumAssay: `${nonstandardConverterData?.rhodiumAssay ?? ''}`,
      confirmRhodiumAssay: `${nonstandardConverterData?.rhodiumAssay ?? ''}`,
    };
    return new Map([[id!, { initialValues }]]);
  }, [id, nonstandardConverterData]);

  return (
    <LoadableContent
      drawContent
      mode={LoadableContent.MODE.FULL}
      loading={updateMutation.isLoading || isFetching}
    >
      <FormContainer>
        {initialData && (
          <NonstandardConvertersForm
            initialData={initialData}
            onSubmit={handleSubmit}
            backendMaterialErrors={backendMaterialErrors}
            editMode
          />
        )}
      </FormContainer>
    </LoadableContent>
  );
};

export const UpdateNonstandardConverter = withAlphamartIntlProvider(
  UpdateNonstandardConverterComponent,
  messages,
);
