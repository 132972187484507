import styled from '@emotion/styled';

import editConverterIcon from 'assets/images/icons/Edit.svg';
import { Section } from 'components/shared/forms/Section/Section';
import { MEDIA_QUERY, Theme } from 'theme';

const StyledSelectedConverter = styled.div<{ theme?: Theme }>`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: bold;
  display: grid;
  grid-template-columns: 70% 30%;
`;

const StyledLabel = styled.div`
  display: flex;
  padding: 40px 0 40px 32px;
`;

const StyledSectionConverter = styled(Section)<{ theme?: Theme }>`
  width: 50%;
  background-color: ${({ theme }) => theme.colors.mineShaftDarker};

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100%;
  }
`;

const StyledEdit = styled.div<{ theme?: Theme }>`
  border-left: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: url(${editConverterIcon});
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledSpan = styled.span`
  padding: 0 30px;
  word-break: break-word;
`;

export { StyledEdit, StyledLabel, StyledSectionConverter, StyledSelectedConverter, StyledSpan };
