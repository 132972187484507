import { SHARED } from 'shared/constants';

export const formatDeviceData = (
  name?: string | null,
  os?: string | null,
  browserFamily?: string | null,
): string => {
  if (!name && !os && !browserFamily) {
    return SHARED.LONG_DASH;
  }

  return [name, os, browserFamily].filter(Boolean).join(' ');
};
