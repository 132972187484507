import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { MEDIA_QUERY } from 'theme';

export const StyledHeader = styled.div`
  display: flex;
  padding: 10px 0px;
`;

export const LinkToVehicle = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex-basis: 48%;
    margin: 0;
  }
`;

export const StyledModal = styled.div`
  & > div {
    min-width: 390px;
    max-width: 700px;
    width: auto;

    ${MEDIA_QUERY.MD} {
      max-width: revert !important;
    }
  }
`;
