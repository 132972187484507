import styled from '@emotion/styled';

import { Theme } from 'theme';

export const ModalFormButtonContainer = styled.div<{ theme?: Theme }>`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
`;
export const ModalFormContent = styled.div<{ theme?: Theme }>`
  background-color: ${({ theme }) => theme.colors.mineShaft};
  border-radius: 12px;
  max-width: 100%;
  padding: 24px 32px;
  width: 430px;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
`;
export const SubheaderContainer = styled.div<{ theme?: Theme }>`
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  padding-top: 5px;

  button {
    border: 1px solid transparent;
    cursor: pointer;
    border-bottom: 1px dotted ${({ theme }) => theme.mainColor};
    color: ${({ theme }) => theme.mainColor};
    font-weight: bold;
    outline: none;
    background: transparent;
    padding: 0px;
  }
`;
