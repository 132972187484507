import React from 'react';
import styled from '@emotion/styled';

import { bytesToMB } from 'helpers/files/formatSize';
import { useTypedIntl } from 'locale/messages';
import { useAppDispatch } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';

const Container = styled.div`
  padding: 10px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  label {
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  input {
    display: none;
  }
`;

interface FieldPhotoRawProps {
  name: string;
  fileSizeLimit: number;
  allowedExtensions: string[];
  label: React.ReactNode;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const FieldPhotoRaw = ({
  allowedExtensions,
  onChange,
  label,
  name,
  fileSizeLimit,
}: FieldPhotoRawProps) => {
  const acceptExtensions = (allowedExtensions || []).join(', ');
  const dispatch = useAppDispatch();
  const intl = useTypedIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    if (event.target.files[0].size > fileSizeLimit) {
      dispatch(
        snackBarPushFailure(
          intl.formatMessage(
            { id: 'Global.Fields.Photos.Error.FileSizeLimit' },
            { limitInMB: bytesToMB(fileSizeLimit) },
          ),
        ),
      );
      return;
    }
    onChange(event);
  };

  return (
    <Container>
      <label htmlFor={`field-input-raw-${name}`}>{label}</label>
      <input
        type="file"
        accept={acceptExtensions}
        onChange={handleChange}
        multiple={false}
        id={`field-input-raw-${name}`}
      />
    </Container>
  );
};
