import styled from '@emotion/styled';

import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { MEDIA_QUERY, Theme } from 'theme';

const PartialConvertersPanel = styled.div<{ theme?: Theme }>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.charcoal};
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-template-rows: 97px auto;
  grid-template-areas: 'header groups' 'partialConverters groupsSection';
  gap: 1px;

  & > div {
    background-color: ${({ theme }) => theme.colors.codGrayDarker};
    font-size: 18px;
    display: flex;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    min-height: 100vh;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: 100%;
    grid-template-rows: 64px auto 64px auto;
    grid-template-areas: 'header' 'partialConverters' 'groups' 'groupsSection';
  }
`;

const PartialConvertersPartialHeader = styled.div`
  grid-area: header;
  padding-left: 38px;
  align-items: center;
`;

const PartialConvertersGroupHeader = styled.div`
  grid-area: groups;
  align-items: center;
  justify-content: space-between;
  padding: 0 38px;

  & > button {
    min-width: 144px;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 0 16px;
  }
`;

const PartialConvertersContainer = styled(FormContainer)`
  grid-area: partialConverters;

  & > div > button {
    @media ${MEDIA_QUERY.MAX_XL} {
      height: 97px;
      width: 36px;
      top: 0;
      left: 0;
      padding: 0;
      background-color: ${({ theme }) => theme.colors.mineShaftLightest};
      border-right: 2px solid ${({ theme }) => theme.mainColor};

      &:hover {
        background-color: ${({ theme }) => theme.colors.mineShaft};
      }

      img {
        width: 28px;
        height: 28px;
      }
    }

    @media ${MEDIA_QUERY.MAX_MD} {
      height: 64px;
    }
  }
`;

export {
  PartialConvertersContainer,
  PartialConvertersGroupHeader,
  PartialConvertersPanel,
  PartialConvertersPartialHeader,
};
