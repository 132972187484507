import { css } from '@emotion/css';

import { MEDIA_QUERY, Theme } from 'theme';

export const notificationMenuStyles = (theme: Theme, compact?: boolean): string => css`
  display: flex;
  align-items: center;
  background: ${theme.colors.mineShaftLighter};
  padding: 12px ${compact ? '24px' : '40px'};
  justify-content: ${compact ? 'center' : 'space-between'};
  position: relative;
  max-width: 288px;
  border-left: 1px solid ${theme.colors.mineShaft};
  height: 54px;
  font-size: 14px;
  color: ${theme.colors.greySnow};
  cursor: pointer;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: none;
  }
`;
