export const matchNormalized = (query?: string | null | void): RegExp | undefined => {
  if (!query) return;

  const queryWords = query
    .replaceAll(/\s+/g, ' ')
    .replaceAll(/\\[\\]/g, '')
    .split(' ');

  const regexWithAlternatives = Array.from(new Set([query, ...queryWords])).map(word =>
    word
      .replace(/[^\w-ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\*]|_|-/g, '')
      .replaceAll('*', '(?:.*?)')
      .split(/(?![^(]*\))/)
      .join('[^a-z\\d]*?')
      .replace(/(?!a-z\\d\]\*)[AĄ]/gi, '[ĄA]')
      .replace(/[CĆ]/gi, '[ĆC]')
      .replace(/[EĘ]/gi, '[ĘE]')
      .replace(/[NŃ]/gi, '[ŃN]')
      .replace(/[SŚ]/gi, '[ŚS]')
      .replace(/(?!z\\d\]\*)[ZŹŻ]/gi, '[ŹŻZ]')
      .replace(/[ŁLI1]/gi, '[ŁLI1]')
      .replace(/[ÓO0]/gi, '[ÓO0]'),
  );

  return new RegExp(`(${regexWithAlternatives.join('|')})`, 'ig');
};
