import { css } from '@emotion/css';

import { Theme } from 'theme';

const FieldMultiTextButtonStyle = (theme?: Theme): string => css`
  border: 1px dashed #8e8d8d;
  background: none;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  color: #b1b1b1;
  font-family: ${theme?.fonts.lato};
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin: 8px 0 0 0;

  &:first-child {
    margin: 0;
  }
`;

export { FieldMultiTextButtonStyle };
