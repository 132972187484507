import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { ErrorCode } from 'shared/constants';

export type ErrorState =
  | {
      errorCode: ErrorCode;
      httpStatus: number;
    }
  | {
      errorCode: null;
      httpStatus: null;
    };

const errorsSlice = createSlice<
  ErrorState,
  {
    setError: CaseReducer<ErrorState, { type: string; payload: ErrorState }>;
  }
>({
  name: 'errors',
  initialState: {
    errorCode: null,
    httpStatus: null,
  },
  reducers: {
    setError: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setError } = errorsSlice.actions;

export default errorsSlice.reducer;
