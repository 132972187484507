import React from 'react';
import { Circle, Polygon, Rectangle } from '@react-google-maps/api';

import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { DrawingTools } from 'shared/constants';
import { setOffZones } from 'store/mapDrawingSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { theme } from 'theme';

const shapesOptions = {
  fillColor: theme.colors.fuzzyWuzzyBrown,
  strokeColor: theme.colors.fuzzyWuzzyBrown,
  fillOpacity: 0.1,
};

const OffZones = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { eraseOnClick, offZones } = useAppSelector(state => state.mapDrawing);
  const {
    [DrawingTools.CIRCLE]: circles,
    [DrawingTools.POLYGON]: polygons,
    [DrawingTools.RECTANGLE]: rectangles,
  } = offZones;

  const handleShapeErase = (index, type) => {
    if (eraseOnClick)
      dispatch(
        showModal({
          message: <FormattedMessage id="Modal.EraseSelectedZone" />,
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: () => {
            dispatch(
              setOffZones({ ...offZones, [type]: offZones[type].filter((_, i) => i !== index) }),
            );

            dispatch(hideModal());
          },
        }),
      );
  };

  return (
    <>
      {circles?.map((shape, index) => (
        <Circle
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          radius={shape.radius}
          // @ts-ignore lng() and lat() is callable
          center={{ lng: shape.center.lng(), lat: shape.center.lat() }}
          options={shapesOptions}
          onClick={() => handleShapeErase(index, DrawingTools.CIRCLE)}
        />
      ))}
      {polygons?.map((shape, index) => (
        <Polygon
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          path={
            (shape.path as unknown as google.maps.MVCArray<google.maps.LatLng>) ?? shape.getPath()
          }
          options={shapesOptions}
          onClick={() => handleShapeErase(index, DrawingTools.POLYGON)}
        />
      ))}
      {rectangles?.map((shape, index) => (
        <Rectangle
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          bounds={shape.getBounds()?.toJSON()}
          options={shapesOptions}
          onClick={() => handleShapeErase(index, DrawingTools.RECTANGLE)}
        />
      ))}
    </>
  );
};

export { OffZones };
