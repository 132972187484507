import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { throttle } from 'lodash';

import {
  BROWSER_PERMISSION_STATES,
  geoLocationErrorCodes,
  geoLocationErrors,
} from 'shared/constants';
import { displayMode } from 'shared/helpers/displayMode';
import { useGetCurrentIpLocation } from 'shared/queries/location';
import { setError, setPosition } from 'store/positionSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';

type GeoMessage = {
  type: 'success';
  coords?: GeolocationCoordinates;
};

type GeoError = {
  type: 'error';
  message?: string;
  code?: number;
};

export function useWatchPosition(geoFrame?: React.MutableRefObject<HTMLIFrameElement | null>): {
  framePath: string;
} {
  const dispatch = useAppDispatch();
  const { permissions: chromiumBrowserPermissions } = navigator;
  const [geoPermissions, setGeoPermissions] = useState('granted' as PermissionState);
  const [framePath, setFramePath] = useState('');
  const { error } = useAppSelector(state => state.position);
  const config = useAppSelector(state => state.config);
  const enableIpLocation = !!config?.maps?.key && error?.code === geoLocationErrorCodes.TIMEOUT;
  const { data: ipLocation } = useGetCurrentIpLocation(config.maps?.key, {
    enabled: enableIpLocation,
  });

  const reloadFrame = useCallback(
    throttle(() => geoFrame?.current?.contentWindow?.location?.reload(), 5000),
    [geoFrame],
  );

  const onLocationChange = (coords: GeolocationCoordinates) => {
    dispatch(
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
      }),
    );
  };

  useEffect(() => {
    if (ipLocation)
      dispatch(
        setPosition({
          latitude: ipLocation.location.lat,
          longitude: ipLocation.location.lng,
          accuracy: ipLocation.accuracy,
        }),
      );
  }, [ipLocation]);

  const onError = (err: { code?: geoLocationErrorCodes; message?: string }) => {
    console.error(err);
    if (!err?.code && err?.message) {
      dispatch(setError({ code: undefined, message: err.message }));
    } else {
      dispatch(
        setError({
          code: err?.code,
          message: err?.message ?? geoLocationErrors.NOT_SUPPORTED,
        }),
      );
    }
  };

  const onPermissionsChange = (state: PermissionState) => {
    setGeoPermissions(state);
    if (state === BROWSER_PERMISSION_STATES.granted) {
      dispatch(setError(undefined));
    } else {
      dispatch(
        setError({
          code: geoLocationErrorCodes.USER_DENIED,
          message: geoLocationErrors.USER_DENIED,
        }),
      );
    }
  };

  useEffect(() => {
    const handler = (message: MessageEvent<GeoMessage | GeoError>) => {
      if (message?.data?.type === 'success') {
        message.data.coords && onLocationChange(message.data.coords);
      } else if (message?.data?.type === 'error') {
        onError({ code: message.data.code, message: message.data.message });
      }
    };

    window.addEventListener('message', handler);
    setTimeout(
      () => setFramePath(`/geo.html?t=${format(new Date(), 'yyyyMMdd')}${displayMode()}`),
      process.env.NODE_ENV === 'development' ? 500 : 100,
    );
    return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    if (!chromiumBrowserPermissions) return;

    chromiumBrowserPermissions?.query({ name: 'geolocation' })?.then(result => {
      result.addEventListener('change', () => onPermissionsChange(result.state));
      onPermissionsChange(result.state);
    });
  }, []);

  useEffect(() => {
    if (!!error || geoPermissions === 'granted') reloadFrame();
  }, [error, geoPermissions, reloadFrame]);

  return { framePath };
}
