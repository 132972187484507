import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { AlphamartHttpError, ExchangeRates } from '../shared/types';

interface ExchangeRatesState extends GenericStoreSlice {
  exchangeRates: Partial<ExchangeRates>;
}

const exchangeRatesSlice = createSlice<ExchangeRatesState, GenericStoreReducer<ExchangeRatesState>>(
  {
    name: 'exchangeRates',
    initialState: {
      exchangeRates: {},
      error: undefined,
      isPending: false,
    },
    reducers: {
      ...getGenericReducers(payload => ({
        exchangeRates: payload,
      })),
    },
  },
);

export const {
  pending: fetchExchangeRatesAction,
  success: fetchExchangeRatesSuccessAction,
  failure: fetchExchangeRatesFailureAction,
} = exchangeRatesSlice.actions;

export default exchangeRatesSlice.reducer;

export const fetchExchangeRates = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    await dispatch(fetchExchangeRatesAction());
    const { data } = await httpClient.get(`${apiHostname}/api/exchange-rates`);
    dispatch(fetchExchangeRatesSuccessAction(data));
  } catch (error) {
    dispatch(
      fetchExchangeRatesFailureAction((error as AlphamartHttpError)?.response?.data.message),
    );
    return Promise.reject(error);
  }
};
