import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Duration } from 'date-fns';
import { useFormik } from 'formik';
import { matchSorter } from 'match-sorter';

import iconArrow from 'assets/images/icons/Back.svg';
import { Icon } from 'components/shared/Buttons';
import { FilterCheckbox } from 'components/shared/Fields/FieldCheckbox';
import { FieldDatePickerRange } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import {
  CustomSearchFunction,
  FieldSelectOption,
  FilterSelect,
} from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { getDateFormat } from 'helpers/dateTime/dateTime';
import { DEFAULT_FILTERS, DrawingModes, FilterableModules } from 'shared/constants';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetCompanies, useGetUsers } from 'shared/queries';
import { Status } from 'shared/types';
import { setDrawingMode, setShouldErase } from 'store/mapDrawingSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, Theme } from 'theme';
import { useTypedIntl } from '../../locale/messages';

interface Props {
  onFiltersChanged: (filters?: MapFiltersShape) => void;
  onFiltersApplied: () => void;
}

export interface MapFiltersShape {
  timeSpan: { from: Date | number; to: Date | number };
  company: number | null;
  users: number[] | null;
  showInactive?: boolean;
}

const EnableDrawingButton = styled.button<{ theme?: Theme; area?: string }>`
  width: 100%;
  height: 48px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  margin-top: 26px;
  background-color: ${({ area, theme }) => (area ? theme.colors.mineShaftLightest : 'transparent')};
  color: ${({ theme }) => theme.colors.alphaGray};
  font-weight: bold;
  transition: border-color 0.2s ease;
  outline: none;
  cursor: pointer;

  :hover {
    border-color: ${({ theme }) => theme.colors.alphaGray};
  }

  span {
    transform: rotate(180deg);
  }
`;

export const MapFilters = ({ onFiltersChanged, onFiltersApplied }: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.GEOLOCATION]);
  const formikContext = useFormik<MapFiltersShape>({
    initialValues: savedFilters.data,
    onSubmit: () => {},
  });
  const { setFieldValue, setValues, values, handleChange } = formikContext;
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const { area } = useAppSelector(state => state.mapDrawing);

  const { data: companies } = useGetCompanies({});
  const { data: users, isFetching: isFetchingUsers } = useGetUsers(
    {
      company: values.company!,
      ...(!values.showInactive && { status: [Status.ACTIVE] }),
    },
    { enabled: !!values.company, initialData: [] },
  );
  const { language } = useAppSelector(state => state.currentLanguage);

  const companiesOptions = useMemo<FieldSelectOption[]>(
    () =>
      companies?.data.map(company => ({
        label: company.name,
        value: company.id,
      })) ?? [],
    [companies],
  );

  const usersOptions = useMemo<FieldSelectOption[]>(
    () =>
      users
        ?.filter(({ status }) => status !== Status.PENDING)
        .map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
          searchString: user.email,
        })) ?? [],
    [users],
  );

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  const maxDateRange = useMemo<Duration>(() => {
    if (values?.users?.length === 1) return { years: 1, days: -1 };
    if (values?.company) return { days: 6 };
    return { days: 0 };
  }, [values?.users?.length, values?.company]);

  const handleCompanyChange = useCallback(() => {
    setFieldValue('users', null);
    setFieldValue('timeSpan', DEFAULT_FILTERS.geolocation.timeSpan);
  }, [setFieldValue]);

  const handleUsersChange = useCallback(
    () => setFieldValue('timeSpan', DEFAULT_FILTERS.geolocation.timeSpan),
    [],
  );

  const handleDrawingsClear = useCallback(() => dispatch(setShouldErase(true)), []);

  const handleUsersSearch: CustomSearchFunction = (options, inputText) =>
    matchSorter(options, inputText, {
      keys: ['searchString', 'label'],
      threshold: matchSorter.rankings.CONTAINS,
    });
  const onFiltersClear = () => {
    if (area) dispatch(setShouldErase(true));

    setValues(DEFAULT_FILTERS.geolocation);
    onFiltersChanged(DEFAULT_FILTERS.geolocation);
  };

  const onAddedAtClear = () => {
    setFieldValue('timeSpan', DEFAULT_FILTERS.geolocation.timeSpan);
    onFiltersChanged({ ...values, timeSpan: DEFAULT_FILTERS.geolocation.timeSpan });
  };

  const onFiltersApply = () => {
    onFiltersChanged({ ...savedFilters.data, ...values });
    onFiltersApplied();
  };

  const timeSpanClearable = Boolean(values?.timeSpan?.from || values?.timeSpan?.to);
  const dateFormat = getDateFormat(language);

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection
        sectionName="companyUsers"
        label={intl.formatMessage({ id: 'Geolocation.Filters.CompanyUsers' })}
      >
        <FilterSelect
          name="company"
          onChange={handleCompanyChange}
          options={companiesOptions}
          label={intl.formatMessage({ id: 'Geolocation.Filters.Company' })}
          value={values.company}
        />

        <FilterSelect
          name="users"
          onChange={handleUsersChange}
          options={usersOptions}
          customSearchFunction={handleUsersSearch}
          label={intl.formatMessage({ id: 'Geolocation.Filters.Users' })}
          value={values.users}
          multi={!!values.company}
          loading={isFetchingUsers}
        />
        <FilterCheckbox
          name="showInactive"
          size="32px"
          checked={values.showInactive}
          onChange={handleChange}
        >
          {intl.formatMessage({ id: 'Geolocation.Filters.ShowInactive' })}
        </FilterCheckbox>
      </FiltersSection>
      <FiltersSection
        sectionName="timeSpan"
        label={intl.formatMessage({ id: 'Geolocation.Filters.TimeSpan' })}
        clearable={timeSpanClearable}
        onSectionClear={onAddedAtClear}
      >
        <FieldDatePickerRange
          name="timeSpan"
          value={values.timeSpan}
          data-cy="filter-time-span"
          dateFormat={dateFormat}
          max={new Date()}
          maxDateRange={maxDateRange}
          resetOnChange={false}
          fillInBounds
        />
      </FiltersSection>
      <FiltersSection
        sectionName="selectTerrain"
        label={intl.formatMessage({ id: 'Geolocation.Filters.SelectTerrain' })}
        clearable
        onSectionClear={handleDrawingsClear}
      >
        <EnableDrawingButton
          onClick={() => dispatch(setDrawingMode(DrawingModes.FILTERS))}
          area={String(area)}
          type="button"
          data-cy="enable-drawing-filters"
        >
          {area} {intl.formatMessage({ id: 'Global.Units.Miles' })}&sup2;
          <Icon icon={iconArrow} />
        </EnableDrawingButton>
      </FiltersSection>
      <FiltersSection
        sectionName="manageOffZones"
        label={intl.formatMessage({ id: 'Geolocation.Filters.OffLimitsAreas' })}
      >
        <EnableDrawingButton
          onClick={() => dispatch(setDrawingMode(DrawingModes.OFF_ZONES))}
          type="button"
          data-cy="enable-drawing-off-zones"
        >
          {intl.formatMessage({ id: 'Global.Manage' })}
          <Icon icon={iconArrow} />
        </EnableDrawingButton>
      </FiltersSection>
    </FiltersForm>
  );
};
