import React, { useMemo, useState } from 'react';

import { PhotoShapeUnion } from 'components/shared/Fields/FieldPhotos/FieldPhotos';

interface WizardContext {
  currentPage: number;
  changePage: (page: number) => void;
  pageIndexes: number[];
  updatePageIndexes: (index: number) => void;
  contextPhotos: PhotoShapeUnion[];
  setContextPhotos: (photos: PhotoShapeUnion[]) => void;
}

const WizardContext = React.createContext<WizardContext>({
  currentPage: 1,
  changePage: () => {},
  pageIndexes: [] as number[],
  updatePageIndexes: () => {},
  contextPhotos: [],
  setContextPhotos: () => {},
});

interface Props {
  children: React.ReactNode;
}

const Wizard = ({ children }: Props): React.ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageIndexes, setPageIndexes] = useState<number[]>([]);
  const [contextPhotos, setContextPhotos] = useState<PhotoShapeUnion[]>([]);

  const updatePageIndexes = (pageIndex: number) => {
    if (pageIndexes.includes(pageIndex)) {
      return;
    }
    setPageIndexes([...pageIndexes, pageIndex].sort((a, b) => a - b));
  };

  const changePage = newPageIndex => {
    setCurrentPage(newPageIndex);
    window.scrollTo(0, 0);
  };

  const value = useMemo(
    () => ({
      currentPage,
      changePage,
      pageIndexes,
      updatePageIndexes,
      contextPhotos,
      setContextPhotos,
    }),
    [currentPage, pageIndexes, contextPhotos],
  );

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>;
};

export { Wizard, WizardContext };
