import { enUS, es, fr, pl } from 'date-fns/locale';

export const Languages: Record<string, Language> = {
  EN: 'EN',
  PL: 'PL',
  FR: 'FR',
  ES: 'ES',
};

export const LocalesByLanguage: Record<string, Locale> = {
  [Languages.EN]: enUS,
  [Languages.PL]: pl,
  [Languages.FR]: fr,
  [Languages.ES]: es,
};

export type Language = 'PL' | 'EN' | 'FR' | 'ES';
