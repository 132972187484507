import { useEffect } from 'react';

import { Language, Languages } from 'shared/constants/languages';
import { setLanguage } from 'store/shared/currentLanguageSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';

export function useLanguage(): Language {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(state => state.currentLanguage);
  const userLanguage = useAppSelector(state => state.auth?.user?.data?.language);
  const browserLanguage = (
    navigator.languages?.length ? navigator.languages[0] : navigator.language
  )
    .split('-')[0]
    .toUpperCase();

  useEffect(() => {
    const mainLanguage = Object.values(Languages).some(value => value === browserLanguage)
      ? (browserLanguage as Language)
      : Languages.EN;
    const newLang = userLanguage || mainLanguage;

    if (newLang !== language) dispatch(setLanguage(newLang));
  }, [userLanguage, navigator.languages, navigator.language]);

  return language;
}
