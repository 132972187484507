import { useCallback } from 'react';

import { useTypedIntl } from 'components/views/Assays/locale/messages';
import { Assay } from 'shared/types';
import { removeAssay } from 'store/removeAssaySlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { AlphamartHistoryShape, useAlphamartNavigate } from './useAlphamartRouter';

type RemoveHandler = (
  assay: Pick<Assay, 'id'>,
  redirect?: string,
  redirectState?: AlphamartHistoryShape | undefined,
) => Promise<void>;

interface UseAssayEditActions {
  actionsPending: boolean;
  handleRemove: RemoveHandler;
}

export function useAssayEditActions(): UseAssayEditActions {
  const navigate = useAlphamartNavigate();
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const actionsPending = !!useAppSelector(state => state.removeAssay.isPending);

  const handleRemove = useCallback<RemoveHandler>(
    async (assay: Pick<Assay, 'id'>, redirect = '', redirectState = undefined) => {
      dispatch(
        showModal({
          message: intl.formatMessage({ id: 'Modal.Confirm' }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());

            try {
              await dispatch(removeAssay(assay.id));
              if (redirect) navigate(`/${redirect}`, { state: redirectState });
              dispatch(
                snackBarPushSuccess(
                  intl.formatMessage({ id: 'Global.Remove.Success' }, { record: 'Assay' }),
                ),
              );
            } catch {
              dispatch(
                snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
              );
            }
          },
        }),
      );
    },
    [dispatch, intl],
  );

  return { actionsPending, handleRemove };
}
