import styled from '@emotion/styled';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { Section } from 'components/shared/forms/Section/Section';
import { MEDIA_QUERY, Theme } from 'theme';

const StyledPartSection = styled(Section)<{ theme?: Theme }>`
  margin-top: 16px;

  & > div {
    &:not(:last-of-type) {
      border: none;
    }

    :nth-last-of-type(2) {
      margin-bottom: 12px;
    }
  }
`;

const AddConverter = styled.button<{ theme?: Theme }>`
  width: 100%;
  height: 60px;
  background: transparent;
  border: 2px dashed ${({ theme }) => theme.colors.doveGray};
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.alphaGray};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.whisper};
    color: ${({ theme }) => theme.colors.whisper};

    & > span {
      background-color: ${({ theme }) => theme.colors.whisper};
    }
  }

  &:focus {
    outline: none;
  }
`;

const ConverterRow = styled.div<{ theme?: Theme }>`
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => theme.colors.mineShaft};
  border-radius: 8px;
  padding: 0px 30px;
  margin-bottom: 6px;
  border: none;

  display: grid;
  grid-template-columns: repeat(4, 1fr) 137px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.whisper};
  font-weight: bold;

  & > button,
  & > span > button {
    padding: 0 20px;
    width: 137px;

    span,
    span:hover {
      background-color: ${({ theme }) => theme.mainColor};
      margin-right: 16px;
    }
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    height: 128px;
    column-gap: 10px;
  }
`;

const AddConvertersHeader = styled.header<{ theme?: Theme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 94px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.codGrayDarker};
  padding: 0 52px;
`;

const ConvertersListModal = styled.div<{ theme?: Theme }>`
  padding: 44px 50px;
`;

const ConverterRowItem = styled.div<{ theme?: Theme }>`
  @media ${MEDIA_QUERY.MAX_MD} {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledConverterSectionWrapper = styled(Section)<{ theme?: Theme }>`
  grid-template-columns: 1fr;
`;

const StyledSingleConverterSectionWrapper = styled.div<{ theme?: Theme }>`
  display: flex;
  justify-content: space-between;

  & > div {
    flex-basis: 45%;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal};
    padding: 15px 0 20px;

    &:last-of-type {
      border-bottom: none;
    }
  }
`;

const NonstandardConvertersNumberInput = styled(FieldInput)`
  & > div:first-of-type {
    height: auto;
    padding: 4px 0;

    @media ${MEDIA_QUERY.MAX_XL} {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
`;

export {
  AddConverter,
  AddConvertersHeader,
  ConverterRow,
  ConverterRowItem,
  ConvertersListModal,
  NonstandardConvertersNumberInput,
  StyledConverterSectionWrapper,
  StyledPartSection,
  StyledSingleConverterSectionWrapper,
};
