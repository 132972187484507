import React, { Dispatch, SetStateAction } from 'react';
import { Preview } from 'react-dnd-multi-backend';
import { List, WindowScroller } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import { DndTypes } from 'shared/constants';
import { moveArrayItem } from 'shared/helpers';
import { AutoScrollOptions, useAutoScroll } from 'shared/hooks';
import { ListContainer } from './SortableList.styles';
import { SortableListItem, SortableListItemComponent } from './SortableListItem';
import { DraggableProvider } from '../DraggableProvider/DraggableProvider';

export interface SortableItem {
  id: number;
  index: number;
  content: React.ReactNode;
}

export interface SortableListProps {
  items: SortableItem[];
  type: DndTypes;
  autoScrollOptions: AutoScrollOptions;
  previewClassName?: string;
  setItems: Dispatch<SetStateAction<SortableItem[]>>;
}

const PreviewComponent = ({
  options,
  previewClassName,
}: {
  options?: AutoScrollOptions;
  previewClassName?: string;
}) => {
  useAutoScroll(options);

  return (
    <Preview<SortableItem>>
      {({ item, style }) => (
        <SortableListItemComponent
          isDragging
          item={item}
          style={style}
          className={previewClassName}
        />
      )}
    </Preview>
  );
};

export const SortableList = ({
  items,
  type,
  autoScrollOptions,
  previewClassName,
  setItems,
}: SortableListProps): React.ReactElement => {
  const reorder = (dragIndex: number, hoverIndex: number) =>
    setItems(prevItems =>
      moveArrayItem(prevItems, dragIndex, hoverIndex).map((item, index) => ({
        ...item,
        index,
      })),
    );

  return (
    <ListContainer>
      <DraggableProvider>
        <WindowScroller scrollElement={autoScrollOptions.scrollableElement}>
          {({ height, scrollTop, isScrolling }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <>
                  <PreviewComponent
                    options={autoScrollOptions}
                    previewClassName={previewClassName}
                  />
                  <List
                    height={height || 100}
                    rowCount={items.length}
                    rowHeight={86}
                    width={width || 100}
                    scrollTop={scrollTop}
                    isScrolling={isScrolling}
                    overscanRowCount={20}
                    autoHeight
                    rowRenderer={({ index, style }) => (
                      <SortableListItem
                        key={items[index].id}
                        item={items[index]}
                        reorder={reorder}
                        type={type}
                        style={style}
                      />
                    )}
                  />
                </>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </DraggableProvider>
    </ListContainer>
  );
};
