// Chrome does not fire change events until there has been real user interaction with the page
// because of this autofill on initial page load does not trigger validation and submit buttons are disabled despite valid values
// https://stackoverflow.com/a/62199697

import React, { useEffect, useState } from 'react';

export function useAutofillDetection(
  refs: React.RefObject<HTMLInputElement>[],
  onAutofillDetected?: () => void,
): boolean {
  const [autofillDetected, setAutofillDetected] = useState<boolean>(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (refs.every(testElement)) {
        onAutofillDetected?.();
        setAutofillDetected(true);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return autofillDetected;
}

const testElement = (el: React.RefObject<HTMLInputElement>) =>
  el.current?.matches(':-internal-autofill-selected');
