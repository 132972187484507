import React from 'react';
import styled from '@emotion/styled';

import { MEDIA_QUERY, Theme } from 'theme';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

const FieldTextAreaLayout = styled.textarea<{ theme?: Theme }>`
  height: 100%;
  width: 100%;
  border: none;
  padding: 0 32px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.alphaGray};
  font-size: 12px;
  caret-color: ${({ theme }) => theme.colors.alphaGray};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  resize: none;

  &:focus,
  &:hover {
    outline: none;
  }

  @media ${MEDIA_QUERY.XL} {
    width: 384px;
  }
`;

interface WrapperProps {
  theme?: Theme;
  error?: boolean;
}
const TextareaWrap = styled.div<WrapperProps>`
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.colors.fuzzyWuzzyBrown : theme.colors.alphaGray)};
  border-radius: 21px;
  background-color: transparent;
  padding: 16px 0;
  transition: border-color 0.2s ease;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.alphaGray};
  }

  @media ${MEDIA_QUERY.XL} {
    width: fit-content;
  }
`;

interface Props extends React.ComponentPropsWithoutRef<'textarea'> {
  'data-cy'?: string;
  error?: boolean | string;
}
const FieldTextArea = withFieldWrapper<Props>(props => {
  const { 'data-cy': dataCy, ...propsWithoutDataCy } = props;
  return (
    <TextareaWrap {...(propsWithoutDataCy as WrapperProps)}>
      <FieldTextAreaLayout {...props} as="textarea" />
    </TextareaWrap>
  );
});

export { FieldTextArea, FieldTextAreaLayout as FieldTextAreaRaw };
