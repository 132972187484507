const defaultSessionDuration = 12 * 60 * 60;
let intervalHandle;
export class SessionListener {
  static setSessionEnd(sessionDuration = defaultSessionDuration): void {
    try {
      localStorage.setItem(
        'alphamartSessionEnd',
        String(new Date(Date.now() + sessionDuration * 1000).getTime()),
      );
    } catch (e) {
      console.error(e);
    }
  }

  static setTimeout(callback: () => void): void {
    if (intervalHandle) clearInterval(intervalHandle);

    const sessionEnd = localStorage.getItem('alphamartSessionEnd');

    if (sessionEnd) {
      try {
        intervalHandle = setInterval(() => {
          if (Number(sessionEnd) < new Date().getTime()) {
            localStorage.removeItem('alphamartSessionEnd');
            callback();
          }
        }, 1000);
      } catch (e) {
        console.error(e);
      }
    }
  }
}
