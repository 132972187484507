import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { useLanguage } from 'shared/hooks/useLanguage';
import { useGetCountries } from 'shared/queries';
import { MEDIA_QUERY } from 'theme';
import { FieldInputRaw, Props as InputProps } from '../FieldInput/FieldInput';
import {
  FieldSelectBase,
  FieldSelectOnChangeSingle,
  FieldSelectOption,
} from '../FieldSelect/FieldSelect';
import { withFieldWrapper } from '../FieldWrapper/FieldWrapper';

const FieldPhoneNumberRoot = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 96px) 1fr;

  @media ${MEDIA_QUERY.MAX_XL} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${MEDIA_QUERY.MAX_SM} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 16px;
  }
`;

const phoneStyles = css`
  border-radius: 0 21px 21px 0 !important;
  border-left-width: 0px !important;

  @media ${MEDIA_QUERY.MAX_SM} {
    border-radius: 21px !important;
    border-left-width: 1px !important;
  }
`;

interface Props extends InputProps {
  onPrefixChange?: FieldSelectOnChangeSingle;
  phoneError?: React.ReactNode;
  prefixError?: React.ReactNode;
  prefixName: string;
  prefixValue?: string;
}

const FieldPhoneNumberBase = ({
  onPrefixChange,
  phoneError,
  prefixError,
  prefixName,
  prefixValue,
  ...props
}: Props): React.ReactElement => {
  const { data: countries } = useGetCountries();
  const language = useLanguage();

  const countriesOptions = useMemo(() => {
    const createLabel = (phonePrefix, name) =>
      name?.includes('(') || name?.includes(')')
        ? `${phonePrefix} [${name}]`
        : `${phonePrefix} (${name})`;

    return countries!
      .map(country => ({ ...country, name: country.name[language] }))
      .reduce<{ phonePrefix; name; alphaCode2 }[]>((acc, curr) => {
        const samePrefix = acc.find(c => c.phonePrefix === curr.phonePrefix);
        if (samePrefix) {
          return [
            ...acc.filter(c => c.phonePrefix !== samePrefix.phonePrefix),
            { ...curr, name: `${samePrefix.name}, ${curr.name}` },
          ];
        }
        return [...acc, curr];
      }, [])
      .reduce<FieldSelectOption[]>((acc, { phonePrefix, name, alphaCode2 }) => {
        if (alphaCode2 === 'US' || alphaCode2 === 'CA' || alphaCode2 === 'UM') {
          return [{ label: createLabel(phonePrefix, name), value: phonePrefix }, ...acc];
        }

        return [...acc, { label: createLabel(phonePrefix, name), value: phonePrefix }];
      }, []);
  }, [countries]);

  return (
    <FieldPhoneNumberRoot>
      <FieldSelectBase
        name={prefixName}
        value={prefixValue}
        onChange={onPrefixChange}
        options={countriesOptions}
        error={phoneError || prefixError}
        isPrefix
      />
      <FieldInputRaw
        {...props}
        disabled={props.disabled || !prefixValue}
        error={phoneError || prefixError}
        className={phoneStyles}
      />
    </FieldPhoneNumberRoot>
  );
};

const FieldPhoneNumber = withFieldWrapper<Props>(props => <FieldPhoneNumberBase {...props} />);

export { FieldPhoneNumber };
