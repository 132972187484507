export enum ErrorCode {
  ASSAY_NAME_ALREADY_EXIST = 'ASSAY_NAME_ALREADY_EXIST',
  COMPANY_ADDRESS_ALREADY_EXIST = 'COMPANY_ADDRESS_ALREADY_EXIST',
  COMPANY_NAME_ALREADY_EXIST = 'COMPANY_NAME_ALREADY_EXIST',
  COMPANY_SUBDOMAIN_ALREADY_EXIST = 'COMPANY_SUBDOMAIN_ALREADY_EXIST',
  CONFIRM_ACCOUNT_ERROR = 'CONFIRM_ACCOUNT_ERROR',
  EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
  EMAIL_ALREADY_USED_FOR_OTHER_ACTIVE_USER = 'EMAIL_ALREADY_USED_FOR_OTHER_ACTIVE_USER',
  EMAIL_ALREADY_USED_IN_OTHER_COMPANY = 'EMAIL_ALREADY_USED_IN_OTHER_COMPANY',
  HEDGE_IS_ALREADY_ASSIGNED = 'HEDGE_IS_ALREADY_ASSIGNED',
  INVALID_CAPTCHA_ERROR = 'INVALID_CAPTCHA_ERROR',
  MAXIMAL_REQUESTS_NUMBER_REACHED = 'MAXIMAL_REQUESTS_NUMBER_REACHED',
  MOBILE_PHONE_ALREADY_USED = 'MOBILE_PHONE_ALREADY_USED',
  MOBILE_PHONE_ALREADY_USED_RETRY_ALLOWED = 'MOBILE_PHONE_ALREADY_USED_RETRY_ALLOWED',
  PASSWORD_ALREADY_SET = 'PASSWORD_ALREADY_SET',
  TOKEN_ALREADY_USED = 'TOKEN_ALREADY_USED',
  UNRECOGNIZED_DEVICE = 'UNRECOGNIZED_DEVICE',
  USER_BLOCKED_OFF_LIMIT_AREA_ENTERED = 'USER_BLOCKED_OFF_LIMIT_AREA_ENTERED',
  USERS_LIMIT_LOWER_THAN_ACTIVE_AND_PENDING_USERS_COUNT = 'USERS_LIMIT_LOWER_THAN_ACTIVE_AND_PENDING_USERS_COUNT',
  USERS_LIMIT_REACHED = 'USERS_LIMIT_REACHED',
  VEHICLE_DATA_MIXED = 'VEHICLE_DATA_MIXED',
  PASSWORD_HISTORY_ERROR = 'PASSWORD_HISTORY_ERROR',
  PASSWORD_CONTAINS_USER_INFO = 'PASSWORD_CONTAINS_USER_INFO',
  PASSWORD_IS_TOO_COMMON = 'PASSWORD_IS_TOO_COMMON',
  VEHICLE_ALREADY_EXIST = 'VEHICLE_ALREADY_EXIST',
  VIN_NOT_UNIQUE = 'VEHICLE_WITH_THIS_VIN_ALREADY_EXISTS',
  TWO_FACTOR_AUTHENTICATION_VERIFICATION_FAILED = 'TWO_FACTOR_AUTHENTICATION_VERIFICATION_FAILED',
  TWO_FACTOR_AUTHENTICATION_SET_FAILED = 'TWO_FACTOR_AUTHENTICATION_SET_FAILED',
  TWO_FACTOR_AUTHENTICATION_REQUEST_LIMIT_REACHED = 'TWO_FACTOR_AUTHENTICATION_REQUEST_LIMIT_REACHED',
  NONSTANDARD_CONVERTER_MATERIAL_NOT_UNIQUE = 'NONSTANDARD_CONVERTER_MATERIAL_NOT_UNIQUE',
  NONSTANDARD_CONVERTER_INVALID_IDS_IN_RANK = 'NONSTANDARD_CONVERTER_INVALID_IDS_IN_RANK',
  NONSTANDARD_CONVERTER_IS_ASSIGNED_TO_VEHICLE = 'NONSTANDARD_CONVERTER_IS_ASSIGNED_TO_VEHICLE',
}
