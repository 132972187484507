import { VehicleFiltersShape } from 'components/views/Vehicles/VehiclesList/VehicleListFilters';
import { SortInterface } from 'shared/constants/sortableModules';
import { GetVehiclesParams } from 'shared/queries/vehicles';

export function parseGetVehiclesParams(
  filters: VehicleFiltersShape,
  page: number,
  pageSize: number,
  sorting: SortInterface | null,
): GetVehiclesParams {
  return {
    page,
    pageSize,
    year: filters.year ? Number(filters.year) : null,
    engineType: filters.engineType || null,
    model: filters.model || null,
    make: filters.make || null,
    engineDisplacementFrom: filters.engineDisplacement?.from,
    engineDisplacementTo: filters.engineDisplacement?.to,
    numberOfConverters: filters.numberOfConverters,
    sortBy: sorting?.sortType,
    sortDirection: sorting?.sortDirection,
  };
}
