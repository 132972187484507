import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

const forgotPasswordSlice = createSlice<GenericStoreSlice, GenericStoreReducer<GenericStoreSlice>>({
  name: 'forgotPassword',
  initialState: {
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(),
  },
});

export const { success, failure, pending } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;

export const forgotPasswordAction =
  (email: string): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(pending());
      await httpClient.post(`${apiHostname}/api/auth/password/reset`, { email });
      dispatch(success(null));
    } catch (err) {
      dispatch(failure((err as AlphamartHttpError)?.response?.data?.message));
    }
  };
