import React from 'react';

import { Badge } from 'components/shared/Badge';
import { DetailsSection } from 'components/shared/Details';
import { User } from 'shared/types';
import { theme } from 'theme';
import { TypedFormattedMessage as FormattedMessage } from '../locale/messages';

interface Props {
  user: User;
}

const getTemplate = (canUseMarginSliderPerMetal: boolean) => {
  const perMetalTemplate = [
    'userProfitMarginPt userProfitMarginPd userProfitMarginRh',
    'marginVisibility userProfitMargin .',
  ];
  const generalMarginSliderTemplate = ['marginVisibility userProfitMargin userProfitMarginPt'];
  if (canUseMarginSliderPerMetal) {
    return perMetalTemplate;
  }
  return generalMarginSliderTemplate;
};

export function UserDetailsProfitMargins({ user }: Props): React.ReactElement {
  const canUseMarginSliderPerMetal = !!(
    user?.company?.canUseProfitMarginPerMetal ||
    user?.company?.parentCompany?.canUseProfitMarginPerMetal
  );
  const template = getTemplate(canUseMarginSliderPerMetal);
  return (
    <DetailsSection
      title={<FormattedMessage id="UserDetails.ProfitMargin" />}
      template={template}
      fields={[
        {
          name: 'marginVisibility',
          label: <FormattedMessage id="UserDetails.MarginVisibility" />,
          value: <FormattedMessage id={`UserDetails.MarginVisibility.${user.marginVisibility!}`} />,
        },
        {
          name: 'userProfitMargin',
          label: (
            <FormattedMessage
              id="UserDetails.UserProfitMargin"
              values={{ count: user.profitMargins.length }}
            />
          ),
          value: user.profitMargins
            ?.slice()
            ?.sort((pM1, pM2) => pM1.value - pM2.value)
            ?.map(({ value }) => (
              <Badge
                key={value}
                color={
                  value === user.selectedProfitMargin
                    ? theme.colors.emerald
                    : theme.colors.mineShaftLightest
                }
              >
                {`${value} %`}
              </Badge>
            )),
        },
        {
          name: 'userProfitMarginPt',
          label: canUseMarginSliderPerMetal ? (
            <FormattedMessage id="Global.Metals.Platinum" />
          ) : (
            <FormattedMessage id="UserDetails.SliderProfitMargin" />
          ),
          value: (
            <>
              <Badge color={theme.colors.mineShaftLightest}>
                {`${user.currentProfitMarginPt ?? 0} %`}
              </Badge>
              <FormattedMessage
                id="UserDetails.TotalProfitMargin"
                values={{ total: user.selectedProfitMargin! + user.currentProfitMarginPt! }}
              />
            </>
          ),
        },
        {
          name: 'userProfitMarginPd',
          label: <FormattedMessage id="Global.Metals.Palladium" />,
          value: (
            <>
              <Badge color={theme.colors.mineShaftLightest}>
                {`${user.currentProfitMarginPd ?? 0} %`}
              </Badge>
              <FormattedMessage
                id="UserDetails.TotalProfitMargin"
                values={{ total: user.selectedProfitMargin! + user.currentProfitMarginPd! }}
              />
            </>
          ),
          doNotRender: !canUseMarginSliderPerMetal,
        },
        {
          name: 'userProfitMarginRh',
          label: <FormattedMessage id="Global.Metals.Rhodium" />,
          value: (
            <>
              <Badge color={theme.colors.mineShaftLightest}>
                {`${user.currentProfitMarginRh ?? 0} %`}
              </Badge>
              <FormattedMessage
                id="UserDetails.TotalProfitMargin"
                values={{ total: user.selectedProfitMargin! + user.currentProfitMarginRh! }}
              />
            </>
          ),
          doNotRender: !canUseMarginSliderPerMetal,
        },
      ]}
    />
  );
}
