import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { css } from '@emotion/css';
import isBefore from 'date-fns/isBefore';
import { chain } from 'lodash';

import iconClose from 'assets/images/icons/Close.svg';
import { LoadableContent } from 'components/shared/Loader';
import { isBetween } from 'shared/helpers';
import { useCurrentUser, useEventListener, useMediaQuery } from 'shared/hooks';
import { logout } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { messages, TypedFormattedMessage as FormattedMessage } from './locale/messages';
import { ScrollDownPrompt } from './ScrollDownPrompt';
import { ContractText, ModalContent } from './TermsAndConditionsModal.styles';
import { TermsAndConditionsModalForm } from './TermsAndConditionsModalForm';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';
import { IconButton } from '../Buttons';
import AppButton from '../forms/AppButton/AppButton';
import { ReactModalStyle } from '../Modals.styles';
import { Tooltip } from '../Tooltip/Tooltip';

interface Props {
  setDocumentRead?: (isRead: boolean) => void;
  showModal?: boolean;
  toggleModal?: () => void;
  showPrompt?: boolean;
}

const replaceEmbeddedVars = (text: string, variables: Record<string, string | undefined>): string =>
  Object.entries(variables).reduce(
    (acc, [key, value]) => acc.replace(new RegExp(`{${key}}`, 'g'), value ?? ''),
    text,
  );

const termsAndConditionsUrl = '/resources/document/TermsAndConditions.html';

function TermsAndConditionsModal({
  showModal,
  toggleModal,
  setDocumentRead,
  showPrompt = true,
}: Props): React.ReactElement {
  const dispatch = useAppDispatch();
  const [termsAndConditionsText, setTermsAndConditionsText] = useState<string | null>(null);
  const currentUser = useCurrentUser();
  const config = useAppSelector(state => state.config);
  const { position } = useAppSelector(state => state.position);
  const [termsRead, setTermsRead] = useState(false);
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_MD);
  const textRef = useRef<HTMLDivElement>(null);

  const userConsentDate = chain(currentUser?.consents)
    .orderBy('termsAndConditionsConsentDate', 'desc')
    .first()
    .value()?.termsAndConditionsConsentDate;

  const isVisible = !!(
    showModal ??
    (currentUser &&
      (!userConsentDate ||
        (config.lastTermsAndConditionsModificationDate &&
          isBefore(
            new Date(userConsentDate),
            new Date(config.lastTermsAndConditionsModificationDate),
          ))))
  );

  const fetchTermsAndConditions = async () => {
    const response = await fetch(termsAndConditionsUrl);
    const { company } = config;
    const text = await response.text();
    const documentVariables = {
      companyName: company?.name,
      appUrl: window.location.hostname,
      contactPersonMobilePhone: `${company?.contactPerson?.mobilePhonePrefix} ${company?.contactPerson?.mobilePhone}`,
      contactPersonEmail: company?.contactPerson?.email,
    };

    setTermsAndConditionsText(replaceEmbeddedVars(text, documentVariables));
  };

  useEffect(() => {
    if (isVisible) fetchTermsAndConditions();
  }, [config, isVisible]);

  const onScroll = e => {
    const isRead =
      termsRead ||
      isBetween(
        e.target.scrollTop,
        e.target.scrollHeight - e.target.offsetHeight - 70,
        e.target.scrollHeight - e.target.offsetHeight + 70,
      );

    setTermsRead(isRead);
    if (setDocumentRead) setDocumentRead(isRead);
  };

  useEffect(() => {
    if (textRef.current) onScroll({ target: textRef.current });
  }, [textRef.current]);

  const handleBack = () => isVisible && dispatch(logout('/login'));
  useEventListener('popstate', handleBack);

  return (
    <AlphamartIntlProvider messages={messages}>
      <ReactModal
        isOpen={isVisible}
        style={ReactModalStyle(isPhone)}
        parentSelector={() => document.querySelector('#root')!}
      >
        <ModalContent hasForm={showPrompt} data-guide-blocker>
          {showModal && <IconButton icon={iconClose} onClick={toggleModal} />}
          {termsAndConditionsText ? (
            <ContractText
              ref={textRef}
              onScroll={onScroll}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: termsAndConditionsText }}
              data-cy="contract-text"
            />
          ) : (
            <LoadableContent mode={LoadableContent.MODE.INLINE} loading />
          )}
          <div>
            {showPrompt &&
              (!showModal || !termsRead ? (
                <ScrollDownPrompt visible={!termsRead} />
              ) : (
                <Tooltip
                  placement="bottom"
                  tooltip={<FormattedMessage id="TermsAndConditionsModal.PositionRequired" />}
                  enabled={!position}
                  trigger={['hover', 'click']}
                  className={css`
                    display: inline-block;
                  `}
                >
                  <AppButton
                    disabled={!position}
                    type="button"
                    data-cy="accept-terms-and-conditions-confirm"
                    onClick={toggleModal}
                  >
                    <FormattedMessage id="Global.Confirm" />
                  </AppButton>
                </Tooltip>
              ))}
          </div>
          {!showModal && <TermsAndConditionsModalForm checkboxDisabled={!termsRead} />}
        </ModalContent>
      </ReactModal>
    </AlphamartIntlProvider>
  );
}

export { TermsAndConditionsModal };
