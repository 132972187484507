import React, { useEffect, useMemo, useState } from 'react';
import { FormikContextType } from 'formik';
import { isUndefined } from 'lodash';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import {
  FieldSelect,
  FieldSelectOnChangeSingle,
  FormatOptionLabel,
} from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldSortableSelect } from 'components/shared/Fields/FieldSelect/FieldSortableSelect';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { Section } from 'components/shared/forms/Section/Section';
import { formatDateWithTime } from 'helpers/dateTime/dateTime';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { CompanyTypes, METAL_ABBR, PERMISSIONS, PRICE_SOURCES, UserRoles } from 'shared/constants';
import { HedgeOrderBy } from 'shared/constants/hedgeOrderBy';
import { Sort } from 'shared/constants/sort';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import {
  Company,
  CompanyListItem,
  Hedge,
  Metals,
  RoleListItem,
  Status,
  UserFormData,
} from 'shared/types';
import { fetchHedges } from 'store/hedgesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { ProfitMarginSection } from './ProfitMarginsSection';
import { HedgeSelectOption } from './UserForm';
import { useTypedIntl } from '../locale/messages';

type Props = {
  editMode?: boolean;
  activeSection: string;
  setActiveSection: (sectionName: string) => void;
  context: FormikContextType<UserFormData>;
  roles?: RoleListItem[];
  companies?: (Company | CompanyListItem)[];
};

export const UserPricingForm = ({
  editMode = false,
  context,
  activeSection,
  setActiveSection,
  companies,
  roles,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const authorize = useAuthorization();
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();
  const { list: hedges } = useAppSelector(state => state.hedges);
  const [isAssayCompanyType, setAssayCompanyType] = useState(false);
  const allowedToSeeHedgePrices = authorize(PERMISSIONS.HEDGES.SHOW_HEDGE_PRICES);
  const buyerRoleId = roles?.find(r => r.name === UserRoles.BUYER)?.id;
  const isBuyer = !isUndefined(buyerRoleId) && !!context.values.roles?.roles?.includes(buyerRoleId);

  const canModifyTermsAdjustments =
    isBuyer && authorize(PERMISSIONS.USERS.UPDATE_TERMS_ADJUSTMENTS);

  const { values, handleChange, getFieldMeta, handleBlur, setFieldValue, setFieldTouched } =
    context;

  useEffect(() => {
    if (!editMode) return;

    const canFilter = currentUser.roles.some(r =>
      [UserRoles.SUPER_ADMIN, UserRoles.SISTER_COMPANY_ADMIN].includes(r.name),
    );
    const relatedCompanies = [values.info?.company];

    dispatch(
      fetchHedges(0, 0, {
        status: Status.ACTIVE,
        ...(canFilter && { relatedCompanies }),
        orderBy: HedgeOrderBy.NAME,
        sort: Sort.ASC,
      }),
    );
  }, [values.info?.company, editMode]);

  const hedgesOptions = useMemo(
    () =>
      hedges.length
        ? [...hedges]
            .map(hedge => {
              const prices = allowedToSeeHedgePrices
                ? [
                    `Pt: ${formatCurrency(hedge.ptPriceCust)}`,
                    `Pd: ${formatCurrency(hedge.pdPriceCust)}`,
                    `Rh: ${formatCurrency(hedge.rhPriceCust)}`,
                  ].join(', ')
                : '';

              return {
                option: {
                  hedge,
                  label: `${hedge.name}, ${formatDateWithTime(hedge.placedAt)}${
                    prices && `, ${prices}`
                  }`,
                  labelHtml: {
                    line1: `${hedge.name}, ${formatDateWithTime(hedge.placedAt)}`,
                    ...(prices && { line2: prices }),
                  },
                  value: hedge.id,
                },
                applicableTo: hedge.applicableTo,
              };
            })
            .sort()
        : [],
    [hedges],
  );
  const formatOptionLabel: FormatOptionLabel = ({ labelHtml }) => (
    <HedgeSelectOption>
      <strong>{labelHtml?.line1}</strong>
      {labelHtml?.line2 && <span>{labelHtml.line2}</span>}
    </HedgeSelectOption>
  );
  const assignedHedgesOptions = useMemo(
    () => hedgesOptions.map(val => val.option, [hedgesOptions]),
    [hedgesOptions],
  );
  const getUsedHedgesOptions = metal =>
    editMode
      ? hedgesOptions
          .filter(
            hedge =>
              values.prices?.assignedHedges?.includes(hedge.option.value) &&
              ((hedge.applicableTo.includes(Metals.PLATINUM) && metal === METAL_ABBR.PT) ||
                (hedge.applicableTo.includes(Metals.PALLADIUM) && metal === METAL_ABBR.PD) ||
                (hedge.applicableTo.includes(Metals.RHODIUM) && metal === METAL_ABBR.RH)),
          )
          .map(val => val.option)
      : [];
  const getPriceSourceOptions = metal => {
    const availableOptions = [
      {
        value: PRICE_SOURCES.MARKET,
        label: intl.formatMessage({ id: `Global.PriceSource.${PRICE_SOURCES.MARKET}` }),
      },
    ];
    const usedHedgesOptions = getUsedHedgesOptions(metal);
    if (usedHedgesOptions && usedHedgesOptions.length > 0) {
      availableOptions.push({
        value: PRICE_SOURCES.HEDGE,
        label: intl.formatMessage({ id: `Global.PriceSource.${PRICE_SOURCES.HEDGE}` }),
      });
    }
    return availableOptions;
  };

  useEffect(() => {
    if (currentUser.roles.some(item => item.name === UserRoles.ADMIN)) {
      setAssayCompanyType(true);
    } else {
      const isAssayType = !!companies?.some(
        ({ id, type }) => id === values.info?.company && type === CompanyTypes.ASSAY,
      );
      setAssayCompanyType(isAssayType);
    }
  }, [values.info?.company, companies, currentUser?.id]);

  const getArrayErrors = name => {
    const indexesNotToValidate =
      getFieldMeta<{ value: string }[]>(name)
        .value?.map((val, i) => val.value === '' && i)
        .filter(Boolean) ?? [];

    const yupResult = ([] as ({ value: string } | undefined)[])
      .concat(getFieldMeta(name)?.error as unknown as { value: string })
      .filter((_, i) => !indexesNotToValidate.includes(i))
      .filter(Boolean)
      .shift()?.value;

    if (yupResult) return yupResult;

    if (
      values?.prices?.userProfitMargin
        ?.map(pM => ((pM?.value || pM?.value === 0) && pM?.value !== '-' ? +pM.value : null))
        ?.filter((e, i, a) => a.indexOf(e) !== i).length
    ) {
      return intl.formatMessage({ id: 'UserForm.Errors.UserProfitMargin.Unique' });
    }
  };

  const handleAssignedHedgeChange = val => {
    if (val.length) {
      const ids = val.map(({ value }) => value);

      if (!ids.includes(values.prices?.prices?.ptHedgeUsed))
        setFieldValue('prices.ptHedgeUsed', null);
      if (!ids.includes(values.prices?.prices?.pdHedgeUsed))
        setFieldValue('prices.pdHedgeUsed', null);
      if (!ids.includes(values.prices?.prices?.rhHedgeUsed))
        setFieldValue('prices.rhHedgeUsed', null);

      setFieldValue(
        'prices.assignedHedges',
        val.map(({ value }) => value),
      );
    } else {
      setFieldValue('prices.ptHedgeUsed', null);
      setFieldValue('prices.pdHedgeUsed', null);
      setFieldValue('prices.rhHedgeUsed', null);
    }
  };

  const handlePriceSourceChange: (metal: METAL_ABBR) => FieldSelectOnChangeSingle =
    metal => opt => {
      if (opt?.value === PRICE_SOURCES.MARKET) {
        setFieldValue(`prices.${metal}HedgeUsed`, null, false);
      }
    };

  const formatUsedHedgeLabel: (metalAbbr: METAL_ABBR) => FormatOptionLabel<{ hedge?: Hedge }> =
    metalAbbr =>
    ({ labelHtml, hedge }) => {
      if (!allowedToSeeHedgePrices) return labelHtml?.line1;

      const price = formatCurrency(hedge?.[`${metalAbbr}PriceCust`]);
      return `${labelHtml?.line1}, ${price}`;
    };

  const getErrors = name => {
    const { touched: fieldTouched, error } = getFieldMeta(`prices.${name}`);
    return fieldTouched && error;
  };

  return (
    <FormInWizard
      context={context}
      header={intl.formatMessage({ id: editMode ? 'UserForm.UpdateHeader' : 'UserForm.Header' })}
    >
      <ProfitMarginSection
        sectionName="profitMargin"
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        formValues={values}
        handleChange={handleChange}
        getErrors={getErrors}
        getArrayErrors={getArrayErrors}
        setFieldTouched={setFieldTouched}
        isAssayCompanyType={isAssayCompanyType}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        label={intl.formatMessage({ id: 'UserForm.Section.ProfitMargin' })}
      />
      {editMode ? (
        <Section
          setActive={setActiveSection}
          activeSection={activeSection}
          sectionName="assignedHedges"
          label={intl.formatMessage({ id: 'UserForm.AssignedHedges' })}
          template={['prices.assignedHedges prices.assignedHedges']}
        >
          <FieldSortableSelect
            label={intl.formatMessage({ id: 'UserForm.AssignedHedges' })}
            name="prices.assignedHedges"
            onChange={handleAssignedHedgeChange}
            value={values.prices?.assignedHedges}
            error={getErrors('assignedHedges')}
            options={assignedHedgesOptions}
            clearable={false}
            formatOptionLabel={formatOptionLabel}
            styles={{ multiValue: { maxWidth: 'auto' } }}
            multi
          />
        </Section>
      ) : null}
      {editMode ? (
        <Section
          setActive={setActiveSection}
          activeSection={activeSection}
          sectionName="prices"
          label={intl.formatMessage({ id: 'UserForm.Prices' })}
          template={[
            'prices.prices.ptPriceSource prices.prices.ptHedgeUsed',
            'prices.prices.pdPriceSource prices.prices.pdHedgeUsed',
            'prices.prices.rhPriceSource prices.prices.rhHedgeUsed',
          ]}
        >
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PtPriceSource' })}
            name="prices.prices.ptPriceSource"
            onChange={handlePriceSourceChange(METAL_ABBR.PT)}
            value={values.prices?.prices?.ptPriceSource}
            error={getErrors('prices.ptPriceSource')}
            options={getPriceSourceOptions(METAL_ABBR.PT)}
            clearable={false}
            required
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PtHedgeUsed' })}
            name="prices.prices.ptHedgeUsed"
            value={
              values.prices?.prices?.ptPriceSource === PRICE_SOURCES.HEDGE
                ? values.prices?.prices?.ptHedgeUsed
                : ''
            }
            options={getUsedHedgesOptions(METAL_ABBR.PT)}
            error={getErrors('prices.ptHedgeUsed')}
            required={values.prices?.prices?.ptPriceSource === PRICE_SOURCES.HEDGE}
            disabled={values.prices?.prices?.ptPriceSource !== PRICE_SOURCES.HEDGE}
            formatOptionLabel={formatUsedHedgeLabel(METAL_ABBR.PT) as FormatOptionLabel}
            data-cy="prices-pt-hedge-used"
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PdPriceSource' })}
            name="prices.prices.pdPriceSource"
            onChange={handlePriceSourceChange(METAL_ABBR.PD)}
            value={values.prices?.prices?.pdPriceSource}
            error={getErrors('prices.pdPriceSource')}
            options={getPriceSourceOptions(METAL_ABBR.PD)}
            clearable={false}
            required
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.PdHedgeUsed' })}
            name="prices.prices.pdHedgeUsed"
            value={
              values.prices?.prices?.pdPriceSource === PRICE_SOURCES.HEDGE
                ? values.prices?.prices?.pdHedgeUsed
                : ''
            }
            options={getUsedHedgesOptions(METAL_ABBR.PD)}
            error={getErrors('prices.pdHedgeUsed')}
            required={values.prices?.prices?.pdPriceSource === PRICE_SOURCES.HEDGE}
            disabled={values.prices?.prices?.pdPriceSource !== PRICE_SOURCES.HEDGE}
            formatOptionLabel={formatUsedHedgeLabel(METAL_ABBR.PD) as FormatOptionLabel}
            data-cy="prices-pd-hedge-used"
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.RhPriceSource' })}
            name="prices.prices.rhPriceSource"
            onChange={handlePriceSourceChange(METAL_ABBR.RH)}
            value={values.prices?.prices?.rhPriceSource}
            error={getErrors('prices.rhPriceSource')}
            options={getPriceSourceOptions(METAL_ABBR.RH)}
            clearable={false}
            required
          />
          <FieldSelect
            label={intl.formatMessage({ id: 'UserForm.RhHedgeUsed' })}
            name="prices.prices.rhHedgeUsed"
            value={
              values.prices?.prices?.rhPriceSource === PRICE_SOURCES.HEDGE
                ? values.prices?.prices?.rhHedgeUsed
                : ''
            }
            options={getUsedHedgesOptions(METAL_ABBR.RH)}
            error={getErrors('prices.rhHedgeUsed')}
            required={values.prices?.prices?.rhPriceSource === PRICE_SOURCES.HEDGE}
            disabled={values.prices?.prices?.rhPriceSource !== PRICE_SOURCES.HEDGE}
            formatOptionLabel={formatUsedHedgeLabel(METAL_ABBR.RH) as FormatOptionLabel}
            data-cy="prices-rh-hedge-used"
          />
        </Section>
      ) : null}

      {canModifyTermsAdjustments ? (
        <Section
          setActive={setActiveSection}
          activeSection={activeSection}
          sectionName="termsAdjustments"
          label={intl.formatMessage({ id: 'UserForm.TermsAdjustments' })}
          template={[
            'prices.ptTermsAdjustment prices.pdTermsAdjustment',
            'prices.rhTermsAdjustment .',
          ]}
        >
          <FieldInput
            label={intl.formatMessage({ id: 'UserForm.PtTermsAdjustment' })}
            name="prices.ptTermsAdjustment"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.prices?.ptTermsAdjustment as string}
            error={getErrors('ptTermsAdjustment')}
            suffix="%"
            data-cy="pt-terms-adjustment"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'UserForm.PdTermsAdjustment' })}
            name="prices.pdTermsAdjustment"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.prices?.pdTermsAdjustment as string}
            error={getErrors('pdTermsAdjustment')}
            suffix="%"
            data-cy="pd-terms-adjustment"
          />
          <FieldInput
            label={intl.formatMessage({ id: 'UserForm.RdTermsAdjustment' })}
            name="prices.rhTermsAdjustment"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.prices?.rhTermsAdjustment as string}
            error={getErrors('rhTermsAdjustment')}
            suffix="%"
            data-cy="rh-terms-adjustment"
          />
        </Section>
      ) : null}
    </FormInWizard>
  );
};
