import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';

import { AccessFlag, apiHostname, PriceSources } from 'shared/constants';
import { UserRoles } from 'shared/constants/userRoles';
import { isAlphamartHttpError, UserListItem } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

let cancelTokenSource: CancelTokenSource;
const CANCEL_MESSAGE = 'Concurrent operation canceled';

export interface UsersListState extends GenericStoreSlice {
  list: UserListItem[];
  count: number;
}

type UsersListReducer = GenericStoreReducer<UsersListState> & {
  clearUsersAction: CaseReducer<UsersListState, { type: string }>;
};

export interface FetchUsersParams {
  status?: string | string[] | null;
  company?: number | null;
  roles?: UserRoles[];
  hedges?: number[];
  query?: string;
  page?: number;
  pageSize?: number;
  ptPriceSource?: PriceSources | null;
  pdPriceSource?: PriceSources | null;
  rhPriceSource?: PriceSources | null;
  accessFlags?: AccessFlag[];
}

const usersListSlice = createSlice<UsersListState, UsersListReducer>({
  name: 'usersList',
  initialState: {
    list: [],
    count: 0,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      list: payload?.data ?? [],
      count: payload?.count ?? 0,
    })),
    clearUsersAction: () => ({
      list: [],
      count: 0,
      submitting: false,
      error: undefined,
    }),
  },
});

export const {
  success: fetchUsersSuccessAction,
  failure: fetchUsersFailureAction,
  pending: fetchUsersAction,
  clearUsersAction,
} = usersListSlice.actions;

export default usersListSlice.reducer;

export const fetchUsers =
  ({
    company,
    hedges,
    page,
    pageSize,
    query,
    roles,
    status,
    ptPriceSource,
    pdPriceSource,
    rhPriceSource,
    accessFlags,
  }: FetchUsersParams): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      cancelTokenSource?.cancel(CANCEL_MESSAGE);
      cancelTokenSource = httpClient.getCancelToken();

      const arrayify = <T>(obj: T | T[]): T[] => (Array.isArray(obj) ? obj : [obj]);

      await dispatch(fetchUsersAction());
      const { data } = await httpClient.get(`${apiHostname}/api/users`, {
        params: {
          ...(page && { page }),
          ...(pageSize && { pageSize }),
          ...(Number.isInteger(company) && { company }),
          ...(roles && { roles: arrayify(roles) }),
          ...(status && { status: arrayify(status) }),
          ...(hedges && { hedges: arrayify(hedges) }),
          ...(ptPriceSource && { ptPriceSource }),
          ...(pdPriceSource && { pdPriceSource }),
          ...(rhPriceSource && { rhPriceSource }),
          ...(accessFlags?.length && { accessFlags }),
          query,
        },
        cancelToken: cancelTokenSource.token,
      });
      data && (await dispatch(fetchUsersSuccessAction(data)));
    } catch (error) {
      // failureAction shouldn't be called if last request was canceled - isPending should be still true
      if (isAlphamartHttpError(error) && error.response?.data.message !== CANCEL_MESSAGE) {
        dispatch(fetchUsersFailureAction(error.response?.data.message));
      }
      return Promise.reject(error);
    }
  };
