import React, { memo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

import closeMenuMobile from 'assets/images/icons/Close.svg';
import menuMobile from 'assets/images/icons/menu.svg';
import { Logo } from 'components/shared/Logo/Logo';
import { NotificationsBell } from 'components/shared/NotificationsBell/NotificationsBell';
import { NavList } from 'components/shared/SideBar/NavList/NavList';
import { useAlphamartLocation, useExtendedTheme, useMediaQuery } from 'shared/hooks';
import { CompanyTheme } from 'shared/types';
import { openNotifications } from 'store/notificationsList';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { layers, MEDIA_QUERY, NAVBAR_HEIGHT_MOBILE, Theme as DefaultTheme } from 'theme';
import { messages } from './locale/messages';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';
import { NotificationsMenu } from '../NotificationMenu/NotificationMenu';
import { ShoppingCartMenu } from '../ShoppingCartMenu/ShoppingCartMenu';
import { UserMenu } from '../UserMenu/UserMenu';

const headerButtonWidth = 74;

const StyledSideBar = styled.nav<{ theme?: CompanyTheme; mobileMenuOpened?: boolean }>`
  ${({ theme }) =>
    theme.navigationBarColor
      ? `background: ${theme.navigationBarColor};`
      : `
    background: rgb(34, 34, 34);
    background: linear-gradient(
      90deg,
      rgba(34, 34, 34, 1) 0%,
      rgba(33, 33, 33, 1) 79%,
      rgba(28, 28, 28, 1) 100%
    );
  `}

  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  position: relative;
  z-index: ${layers.selectMenu};

  @media ${MEDIA_QUERY.MAX_MD} {
    z-index: ${layers.mobileNavbar};
    background: #222222;

    ${({ mobileMenuOpened }) =>
      mobileMenuOpened
        ? `
    min-height: 100vh;
    height: fit-content;
    z-index: ${layers.menu};
  `
        : `
    height: ${NAVBAR_HEIGHT_MOBILE};
    overflow: hidden;
  `}
  }
`;

const HeaderContainer = styled.div`
  height: 91px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  @media ${MEDIA_QUERY.MAX_MD} {
    min-height: 64px;
    height: 64px;
  }
`;

const HeaderLogo = styled(NavLink)<{ theme?: DefaultTheme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:focus {
    outline-color: ${({ theme }) => theme.mainColor};
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    padding: 0;
    min-height: 64px;
    flex-grow: 0;
  }
`;

const StyledMobileMenuButton = styled.button<{ menuOpened?: boolean }>`
  display: none;
  background: none;
  border: 0;
  padding: 0;
  height: 100%;
  width: ${headerButtonWidth}px;
  outline: none;

  ${({ menuOpened }) =>
    menuOpened
      ? `
    background: url(${closeMenuMobile}) no-repeat center;
  `
      : `
    background: url(${menuMobile}) no-repeat center;
  `}
  @media ${MEDIA_QUERY.MAX_MD} {
    display: block;
  }
`;
const NotificationsBellContainer = styled.div`
  display: none;
  width: ${headerButtonWidth}px;
  height: 100%;
  min-width: 74px;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: block;
  }
`;

const ShoppingCartContainer = styled.div`
  display: none;
  height: 100%;

  @media ${MEDIA_QUERY.MAX_MD} {
    display: block;
  }
`;

const SideBar = memo(() => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const theme = useExtendedTheme();
  const dispatch = useAppDispatch();
  const notificationsList = useAppSelector(state => state.notificationsList);
  const toggleMenu = () => {
    setMobileMenuOpened(!mobileMenuOpened);
  };
  const handleNotificationBellClick = (event: React.MouseEvent) => {
    if (!notificationsList?.isOpen) {
      event.stopPropagation();

      setMobileMenuOpened(false);
      dispatch(openNotifications());
    }
  };
  const location = useAlphamartLocation();

  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const isMaxXl = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const noLabelLocations = ['/geolocation'];
  const compact =
    isMd && (isMaxXl || noLabelLocations.some(path => location.pathname.includes(path)));

  return (
    <StyledSideBar mobileMenuOpened={mobileMenuOpened}>
      <HeaderContainer>
        <StyledMobileMenuButton type="button" onClick={toggleMenu} menuOpened={mobileMenuOpened} />
        <HeaderLogo to="/dashboard">
          <Logo desktop={theme.logo} mobile={theme.icon.small} compact={compact} />
        </HeaderLogo>
        <NotificationsBellContainer>
          <NotificationsBell size="M" onClick={handleNotificationBellClick} />
        </NotificationsBellContainer>
        <ShoppingCartContainer>
          <ShoppingCartMenu
            compact
            hide={location.pathname !== '/converters'}
            data-guide="shopping-cart-max-md"
          />
        </ShoppingCartContainer>
      </HeaderContainer>
      <AlphamartIntlProvider messages={messages}>
        <NavList toggleMenu={toggleMenu} menuOpened={mobileMenuOpened} />
      </AlphamartIntlProvider>
      <div data-guide="profile-position">
        <NotificationsMenu compact={compact} />
        <UserMenu compact={compact} />
      </div>
    </StyledSideBar>
  );
});

export { SideBar };
