import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { times } from 'lodash';

import { PhotoShape } from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { useExtendedTheme, useMediaQuery, useWindowWidth } from 'shared/hooks';
import { MEDIA_QUERY, Theme } from 'theme';
import { GridItemMockup } from './GridItemMockup/GridItemMockup';
import { TypedFormattedMessage } from '../locale/messages';

interface PlaceholderProps {
  children?: React.ReactNode;
  color?: string;
  size?: string;
  type: string;
}

interface MockupProps {
  isGeneratingWatermark: boolean;
  logo: {
    image: string;
  } | null;
  mainColor: string;
  navigationBarColor: string;
  watermarkLogo: PhotoShape;
  watermarkOpacity: number;
  watermarkPreview: string | null;
}

interface placeholderStylesProps {
  type: string;
  color: string;
  size: string;
}

const MockupContainer = styled.div<{ theme: Theme }>`
  border: 1px solid ${({ theme }) => theme.colors.mineShaftLightest};
  background: ${({ theme }) => theme.colors.codGrayDarker};
  width: 1028px;
  max-width: 100%;
  margin: auto;
`;

const MockupFrame = styled.div<{ contentHeight?: number }>`
  padding-top: ${({ contentHeight }) => (contentHeight ? `${contentHeight + 74 + 56}px` : '48%')};
  position: relative;
`;

const MockupGrid = styled.div<{ theme: Theme }>`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 1px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.mineShaftLightest};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${MEDIA_QUERY.MAX_XL} {
    grid-template-columns: 72px 3fr 1fr;
  }
`;

const LogoContainer = styled.div<{ theme: Theme }>`
  background: ${({ theme }) => theme.colors.codGrayDarker};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LogoImage = styled.img`
  height: 40px;
  max-width: 100%;
  object-fit: contain;

  @media ${MEDIA_QUERY.MAX_XL} {
    height: 32px;
  }
`;

const TopContainer = styled.div<{ theme: Theme }>`
  background: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 6.5%;

  @media ${MEDIA_QUERY.MAX_XL} {
    padding: 8px 6.5%;
  }
`;

const UserContainer = styled.div<{ theme: Theme }>`
  background: ${({ theme }) => theme.background};
  display: grid;
  align-items: center;
  padding: 0 12%;
  grid-template-columns: min-content auto;
  grid-column-gap: 15px;
`;

const MenuContainer = styled.div<{ mainColor: string; navigationBarColor: string }>`
  ${({ navigationBarColor }) => `
    background: ${navigationBarColor}
  `};
  padding: 27px 12%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ mainColor }) => `
    &:after {
      content: '';
      display: block;
      background: ${mainColor};
      width: 8px;
      height: 40px;
      border-radius: 4px;
      position: absolute;
      top: 20%;
      right: -4px;
    }
  `};

  @media ${MEDIA_QUERY.MAX_XL} {
    align-items: center;
  }
`;

const ContentContainer = styled.div<{ theme: Theme }>`
  background: ${({ theme }) => theme.background};
  padding: 28px 36px;

  @media ${MEDIA_QUERY.MAX_XL} {
    grid-column: 2 / span 2;
  }
`;

const FiltersContainer = styled.div<{ theme: Theme }>`
  background: ${({ theme }) => theme.background};
  padding: 27px 12%;

  & > * {
    margin: 0 0 12px 0;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    display: none;
  }
`;

const placeholderStyles = ({ type, color, size }: placeholderStylesProps): string => css`
  height: 10px;
  background-color: ${color};
  border-radius: 4px;
  font-size: 0;
  width: 60px;
  position: relative;

  ${type === 'string'
    ? `
    ${
      size === 'small' &&
      `
      width: 30px;
    `
    }
  `
    : ''}

  ${type === 'icon'
    ? `
    height: 12px;
    width: 12px;
    background: ${color};
    border-radius: 6px;

    ${
      size === 'medium'
        ? `
      height: 26px;
      width: 26px;
      border-radius: 13px;
    `
        : ''
    }

    ${
      size === 'large'
        ? `
      height: 40px;
      width: 40px;
      border-radius: 20px;
    `
        : ''
    }

  `
    : ''}

  ${type === 'button'
    ? `
    border: 2px solid ${color};
    background-color: transparent;
    height: 40px;
    border-radius: 20px;
    display: grid;
    grid-template-columns: min-content auto;
    grid-column-gap: 15px;
    align-items: center;
    padding: 0 20px;
    width: auto;
    margin: 0 0 0 auto;

    @media ${MEDIA_QUERY.MAX_XL} {
       height: 32px;
    }

  `
    : ''}

  ${type === 'row'
    ? `
    background-color: ${color};
    height: 12%;
    border-radius: 4px;
    margin: 0 0 16px 0;
    width: 100%;
  `
    : ''}

  ${type === 'list-item'
    ? `
    display: grid;
    grid-template-columns: min-content auto;
    grid-column-gap: 15px;
    align-items: center;
    margin: 0 0 16px 0;
    width: 100%;
    height: auto;
    background: none;

    @media ${MEDIA_QUERY.MAX_XL} {
      grid-template-columns: auto;
      justify-content: center;
    }

    &:last-child {
      margin: auto 0 0 0;
    }
  `
    : ''}
`;

const Placeholder = ({
  type,
  children,
  color,
  size = 'medium',
}: PlaceholderProps): React.ReactElement => {
  const theme = useExtendedTheme();

  return (
    <div className={placeholderStyles({ type, color: color || theme.colors.codGrayDarker, size })}>
      {children}
    </div>
  );
};

const MockupLabel = styled.div<{
  distance?: number;
  labelOffset?: number;
  theme?: Theme;
  type: string;
}>`
  display: flex;
  background: ${({ theme }) => theme.colors.mineShaftLighter};
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  font-family: ${({ theme }) => theme.fonts.lato};
  padding: 0 15px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;

  ${({ type, labelOffset }) => `
    ${type === 'up' ? `top: -${labelOffset ?? 100}px;` : `bottom: -${labelOffset ?? 100}px;`}
  `}

  &:before {
    content: '';
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.alphaGray};
    position: absolute;
    left: 50%;
    margin: 4px 0 0 -5px;

    ${({ type, distance }) => `
      ${type === 'up' ? `bottom: -${distance ?? 80}px;` : 'top: -80px;'}
    `}
  }

  &:after {
    content: '';
    display: block;
    height: ${({ distance }) => distance ?? '62'}px;
    border-left: 1px dashed ${({ theme }) => theme.colors.alphaGray};
    position: absolute;
    left: 50%;
    margin: 4px 0 0 -1px;

    ${({ type }) => `
      ${type === 'up' ? `top: 100%;` : 'bottom: 100%;'}
    `}
  }
`;

const Mockup = ({
  logo,
  mainColor,
  navigationBarColor,
  watermarkPreview,
  isGeneratingWatermark,
  watermarkOpacity,
  watermarkLogo,
}: MockupProps): React.ReactElement => {
  const [contentHeight, setContentHeight] = useState<number | undefined>(0);
  const theme = useExtendedTheme();
  const windowWidth = useWindowWidth();
  const content = document.getElementById('mockupContent');
  const isLg = useMediaQuery(MEDIA_QUERY.LG);
  const isXl = useMediaQuery(MEDIA_QUERY.XL);
  const placeholdersCount = +isXl + +isLg + 3;

  useEffect(() => {
    const height = content?.clientHeight;
    setContentHeight(height);
  }, [windowWidth, content?.clientHeight]);

  return (
    <MockupContainer theme={theme}>
      <MockupFrame contentHeight={contentHeight}>
        <MockupGrid theme={theme}>
          <LogoContainer theme={theme}>
            <MockupLabel type="up">
              <TypedFormattedMessage id="Settings.Display.CompanyLogo" />
            </MockupLabel>
            {logo && <LogoImage alt="logo" src={logo.image} />}
          </LogoContainer>
          <TopContainer theme={theme}>
            {isXl && <Placeholder type="string" color={theme.colors.mineShaftLighter} />}
            <Placeholder type="button" color={mainColor}>
              <MockupLabel type="up">
                <TypedFormattedMessage id="Settings.Display.MainColor" />
              </MockupLabel>
              {isXl && <Placeholder type="icon" size="small" color={mainColor} />}
              <Placeholder type="string" color={mainColor} />
            </Placeholder>
          </TopContainer>
          <UserContainer theme={theme}>
            {isXl && <Placeholder type="icon" size="large" color={theme.colors.mineShaftLighter} />}
            <Placeholder type="string" color={theme.colors.mineShaftLighter} />
          </UserContainer>
          <MenuContainer
            mainColor={mainColor}
            navigationBarColor={navigationBarColor || theme.colors.codGrayDarker}
          >
            <MockupLabel type="bottom">
              <TypedFormattedMessage id="Settings.Display.NavigationBarColor" />
            </MockupLabel>
            {times(placeholdersCount, () => (
              <Placeholder type="list-item">
                <Placeholder type="icon" size="medium" color={theme.colors.mineShaftLightest} />
                {isXl && <Placeholder type="string" color={theme.colors.mineShaftLightest} />}
              </Placeholder>
            ))}
          </MenuContainer>
          <ContentContainer theme={theme}>
            <MockupLabel type="up" labelOffset={50} distance={125}>
              <TypedFormattedMessage id="Settings.Display.Watermark" />
            </MockupLabel>
            <GridItemMockup
              componentId="mockupContent"
              isGeneratingWatermark={isGeneratingWatermark}
              theme={theme}
              watermark={watermarkPreview}
              watermarkLogo={watermarkLogo}
              watermarkOpacity={watermarkOpacity}
            />
          </ContentContainer>
          <FiltersContainer theme={theme}>
            <Placeholder type="string" color={theme.colors.mineShaftLighter} />
            <Placeholder type="string" size="small" color={theme.colors.mineShaftLighter} />
          </FiltersContainer>
        </MockupGrid>
      </MockupFrame>
    </MockupContainer>
  );
};

export { Mockup };
