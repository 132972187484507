import { Action, CaseReducer, ThunkAction } from '@reduxjs/toolkit';

import HttpClient from 'shared/utils/httpClient';
import { RootState } from '../index';

export type GenericThunk = ThunkAction<void, RootState, HttpClient, Action<string>>;

export interface GenericStoreSlice {
  error?: string;
  isPending?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericStoreReducer<TStore, TSuccessPayload = any> = {
  success: CaseReducer<TStore, { type: string; payload: TSuccessPayload }>;
  pending: CaseReducer<TStore, { type: string }>;
  failure: CaseReducer<TStore, { type: string; payload: string | undefined }>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getGenericReducers<StateShape = Record<string, unknown>, SuccessPayload = any>(
  successReducerOverwrites: (payload: SuccessPayload) => Partial<StateShape> = () =>
    ({} as StateShape),
): GenericStoreReducer<StateShape> {
  return {
    success: (state, action) => ({
      ...state,
      error: null,
      isPending: false,
      ...successReducerOverwrites(action.payload),
    }),
    pending: state => ({
      ...state,
      isPending: true,
      error: null,
    }),
    failure: (state, action) => ({
      ...state,
      error: action?.payload,
      isPending: false,
    }),
  };
}
