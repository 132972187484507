import React from 'react';
import styled from '@emotion/styled';

interface Props {
  src: string;
  alt: string;
}

const ListImageContainer = styled.div`
  height: 48px;
  width: 72px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;

export const ListImage = ({ src, alt }: Props): React.ReactElement => (
  <ListImageContainer>
    <img src={src} alt={alt} />
  </ListImageContainer>
);
