import { TypedFormattedMessageFactory, useTypedIntlFactory } from 'locale/typedIntl';
import { Language } from 'shared/constants';
import enMessages from './en.json';
import esMessages from './es.json';
import frMessages from './fr.json';
import plMessages from './pl.json';

export type TermsAndConditionsMessages = typeof enMessages;

export const messages: Record<Language, TermsAndConditionsMessages> = {
  EN: enMessages,
  ES: esMessages,
  FR: frMessages,
  PL: plMessages,
};

export const useTypedIntl = useTypedIntlFactory<keyof TermsAndConditionsMessages>();
export const TypedFormattedMessage =
  TypedFormattedMessageFactory<keyof TermsAndConditionsMessages>();
export type TypedIntlShape = ReturnType<typeof useTypedIntl>;
