import { AccessFlag } from './accessFlag';
import { UserRoles } from './userRoles';

export const ALLOW_ALL = '*' as const;

const PERMISSIONS = {
  DASHBOARD: {
    SHOW: ALLOW_ALL,
  },
  CONVERTERS: {
    LIST: [AccessFlag.CONVERTER_VIEWER],
    LIST_SAMPLE: [AccessFlag.ASSAY_VIEWER, UserRoles.ADMIN],
    FILTERS_ADVANCED: [AccessFlag.ASSAY_EDITOR],
    DETAILS: {
      CREATED_BY: [AccessFlag.ASSAY_EDITOR],
      ASSAY: [AccessFlag.ASSAY_VIEWER],
    },
    PARTIAL_CONVERTERS: [AccessFlag.PARTIAL_CONVERTER_MANAGER],
    DISCLAIMER: [UserRoles.ADMIN, UserRoles.CUSTOMER],
  },
  USERS: {
    LIST: [AccessFlag.USER_MANAGER],
    LIST_COMPANY_NAME: [AccessFlag.COMPANY_MANAGER],
    FILTER_ALL_ROLES: [AccessFlag.COMPANY_MANAGER],
    FILTER_BY_COMPANY: [AccessFlag.COMPANY_MANAGER],
    FILTER_BY_ACCESS_FLAGS: [UserRoles.SUPER_ADMIN],
    FILTER_HEDGES: [AccessFlag.HEDGE_BASIC_VIEWER],
    EXPORT_LOCATIONS: [AccessFlag.GEOLOCATION_MANAGER],
    EXPORT_SHOPPING_CART_LOGS: [AccessFlag.REPORT_ADVANCED_GENERATOR],
    CREATE: [AccessFlag.USER_MANAGER],
    CREATE_UPDATE_WITH_DUPLICATED_MOBILE_PHONE: [AccessFlag.COMPANY_MANAGER],
    CHANGE_COMPANY: [AccessFlag.COMPANY_MANAGER],
    UPDATE: [AccessFlag.USER_MANAGER],
    DELETE: [AccessFlag.USER_DELETER],
    DETAILS: {
      SHOW_PARENT_COMPANY: [UserRoles.SUPER_ADMIN],
      SHOW_USER_PRICES_FIELD: [AccessFlag.HEDGE_EDITOR],
      SHOW_USER_TERMS_ADJUSTMENTS_FIELD: [AccessFlag.COMPANY_MANAGER],
      SHOW_USER_DEVICES_DETAILS: [AccessFlag.USER_MANAGER],
    },
    UPDATE_TERMS_ADJUSTMENTS: [AccessFlag.COMPANY_MANAGER],
    KNOW_ABOUT_OTHER_COMPANY_USERS: [AccessFlag.COMPANY_MANAGER],
    UNBLOCK: [AccessFlag.COMPANY_MANAGER],
    SHOW_DISPLAY_HEDGE_PRICES_FIELD: [AccessFlag.HEDGE_PRICE_VIEWER],
    EDIT_USAGES_THRESHOLDS: [AccessFlag.STATISTICS_VIEWER],
    SEE_TERMS_AND_CONDITIONS_CONSENT_DETAILS: [UserRoles.SUPER_ADMIN],
    MANAGE_ACCESS: [UserRoles.SUPER_ADMIN],
  },
  COMPANIES: {
    LIST: [AccessFlag.COMPANY_MANAGER],
    CREATE: [AccessFlag.COMPANY_MANAGER],
    CHANGE_COMPANY_TYPE: [UserRoles.SUPER_ADMIN],
    CHANGE_SUBDOMAIN: [UserRoles.SUPER_ADMIN],
    LIST_RESTRICTED_FIELDS: [UserRoles.SUPER_ADMIN],
    UPDATE: [AccessFlag.COMPANY_MANAGER],
    DETAILS: {
      PARENT_COMPANY: [UserRoles.SUPER_ADMIN],
      SUBDOMAIN: [UserRoles.SUPER_ADMIN],
      TYPE: [UserRoles.SUPER_ADMIN],
    },
    EDIT_USAGES_THRESHOLDS: [AccessFlag.STATISTICS_VIEWER],
    MAX_ASSAY_USERS: [AccessFlag.COMPANY_MANAGER],
    EXPORT_LOCATIONS: [AccessFlag.GEOLOCATION_MANAGER],
    EXPORT_HEDGE_USE_LOGS: [AccessFlag.REPORT_BASIC_GENERATOR],
    EXPORT_CONVERTER_LOGS: [AccessFlag.REPORT_BASIC_GENERATOR],
  },
  ASSAYS: {
    LIST: [AccessFlag.ASSAY_VIEWER],
    FILTERS_REMOVED: [AccessFlag.ASSAY_EDITOR],
    CREATE: [AccessFlag.ASSAY_EDITOR],
    UPDATE: [AccessFlag.ASSAY_EDITOR],
  },
  COMPANY_SETTINGS: [AccessFlag.SETTINGS_MANAGER],
  USER_PROFILE: {
    CHANGE_PROFIT_MARGIN: [AccessFlag.CONVERTER_VIEWER],
    EDIT_USAGES_THRESHOLDS: [AccessFlag.STATISTICS_VIEWER],
    SHOW_PRICE_SOURCE: [AccessFlag.CONVERTER_VIEWER],
  },
  GEOLOCATION: {
    DISPLAY: [AccessFlag.GEOLOCATION_MANAGER],
  },
  VEHICLES: {
    MAIN: [
      AccessFlag.VEHICLE_BASIC_VIEWER,
      AccessFlag.VEHICLE_ADVANCED_VIEWER,
      AccessFlag.VEHICLE_CREATOR,
      AccessFlag.VEHICLE_EDITOR,
    ],
    LIST: [AccessFlag.VEHICLE_BASIC_VIEWER, AccessFlag.VEHICLE_ADVANCED_VIEWER],
    FULL_DETAILS: [AccessFlag.VEHICLE_ADVANCED_VIEWER],
    CREATE: [AccessFlag.VEHICLE_CREATOR],
    EDIT: [AccessFlag.VEHICLE_EDITOR],
  },
  HEDGES: {
    LIST: [AccessFlag.HEDGE_BASIC_VIEWER],
    FILTER_BY_COMPANY: [AccessFlag.COMPANY_MANAGER],
    FILTER_BY_PARENT_COMPANY: [UserRoles.SUPER_ADMIN],
    DETAILS: {
      SHOW_PAGE: [AccessFlag.HEDGE_BASIC_VIEWER],
      SHOW_PARENT_COMPANY: [UserRoles.SUPER_ADMIN],
      SHOW_RELATED_COMPANY: [AccessFlag.COMPANY_MANAGER],
    },
    CREATE: [AccessFlag.HEDGE_EDITOR],
    CHANGE_PARENT_COMPANY: [UserRoles.SUPER_ADMIN],
    LIST_RESTRICTED_FIELDS: [AccessFlag.HEDGE_EDITOR],
    LIST_PARENT_COMPANY: [UserRoles.SUPER_ADMIN],
    UPDATE: [AccessFlag.HEDGE_EDITOR],
    REMOVE: [AccessFlag.HEDGE_EDITOR],
    UNASSIGN: [AccessFlag.HEDGE_EDITOR],
    REQUEST_HEDGE: [UserRoles.ADMIN],
    ASSIGN: {
      ACTION: [AccessFlag.HEDGE_BASIC_VIEWER],
      SHOW_FULL_HEDGE_DATA: [AccessFlag.HEDGE_EDITOR],
    },
    SHOW_HEDGE_PRICES: [AccessFlag.HEDGE_PRICE_VIEWER],
  },
  NOTIFICATIONS: {
    MAIN: [AccessFlag.DASHBOARD_VIEWER],
    ALL_DETAILS: [AccessFlag.SETTINGS_MANAGER],
  },
  STATISTICS: {
    LIST: [AccessFlag.STATISTICS_VIEWER],
  },
  NONSTANDARD_CONVERTERS: {
    MAIN: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR, AccessFlag.NON_STANDARD_CONVERTER_VIEWER],
    CREATE: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR],
    UPDATE: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR],
    SEARCH: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR, AccessFlag.NON_STANDARD_CONVERTER_VIEWER],
  },
  FEEDBACKS: {
    MAIN: [AccessFlag.FEEDBACK_MANAGER],
  },
  SHOPPING_CART: {
    MAIN: [AccessFlag.SHOPPING_CART_VIEWER],
  },
};

export { PERMISSIONS };
