import { UserRoles } from './userRoles';

export enum AccessFlag {
  ASSAY_VIEWER = 'ASSAY_VIEWER',
  ASSAY_EDITOR = 'ASSAY_EDITOR',
  COMPANY_MANAGER = 'COMPANY_MANAGER',
  CONVERTER_VIEWER = 'CONVERTER_VIEWER',
  PARTIAL_CONVERTER_MANAGER = 'PARTIAL_CONVERTER_MANAGER',
  DASHBOARD_VIEWER = 'DASHBOARD_VIEWER',
  GEOLOCATION_MANAGER = 'GEOLOCATION_MANAGER',
  HEDGE_BASIC_VIEWER = 'HEDGE_BASIC_VIEWER',
  HEDGE_EDITOR = 'HEDGE_EDITOR',
  HEDGE_PRICE_VIEWER = 'HEDGE_PRICE_VIEWER',
  NON_STANDARD_CONVERTER_VIEWER = 'NON_STANDARD_CONVERTER_VIEWER',
  NON_STANDARD_CONVERTER_EDITOR = 'NON_STANDARD_CONVERTER_EDITOR',
  SETTINGS_MANAGER = 'SETTINGS_MANAGER',
  STATISTICS_VIEWER = 'STATISTICS_VIEWER',
  USER_MANAGER = 'USER_MANAGER',
  USER_DELETER = 'USER_DELETER',
  VEHICLE_BASIC_VIEWER = 'VEHICLE_BASIC_VIEWER',
  VEHICLE_ADVANCED_VIEWER = 'VEHICLE_ADVANCED_VIEWER',
  VEHICLE_EDITOR = 'VEHICLE_EDITOR',
  VEHICLE_CREATOR = 'VEHICLE_CREATOR',
  REPORT_BASIC_GENERATOR = 'REPORT_BASIC_GENERATOR',
  REPORT_ADVANCED_GENERATOR = 'REPORT_ADVANCED_GENERATOR',
  FEEDBACK_MANAGER = 'FEEDBACK_MANAGER',
  FOIL_VIEWER = 'FOIL_VIEWER',
  SHOPPING_CART_VIEWER = 'SHOPPING_CART_VIEWER',
}

export const accessFlagsDependencies: Partial<Record<AccessFlag, AccessFlag[]>> = {
  [AccessFlag.VEHICLE_ADVANCED_VIEWER]: [AccessFlag.VEHICLE_BASIC_VIEWER],
  [AccessFlag.VEHICLE_CREATOR]: [AccessFlag.VEHICLE_BASIC_VIEWER],
  [AccessFlag.VEHICLE_EDITOR]: [AccessFlag.VEHICLE_BASIC_VIEWER],
  [AccessFlag.NON_STANDARD_CONVERTER_EDITOR]: [AccessFlag.NON_STANDARD_CONVERTER_VIEWER],
  [AccessFlag.FOIL_VIEWER]: [AccessFlag.CONVERTER_VIEWER],
  [AccessFlag.SHOPPING_CART_VIEWER]: [AccessFlag.CONVERTER_VIEWER],
};

export const accessFlagsAllowedForRoles: Array<[AccessFlag[], UserRoles[]]> = [
  [[AccessFlag.CONVERTER_VIEWER], [UserRoles.ADMIN, UserRoles.CUSTOMER]],
  [
    [AccessFlag.FOIL_VIEWER],
    [UserRoles.TECH, UserRoles.BUYER, UserRoles.ADMIN, UserRoles.CUSTOMER],
  ],
  [
    [AccessFlag.HEDGE_PRICE_VIEWER],
    [UserRoles.BUYER, UserRoles.TECH, UserRoles.ADMIN, UserRoles.CUSTOMER],
  ],
  [
    [AccessFlag.NON_STANDARD_CONVERTER_EDITOR, AccessFlag.NON_STANDARD_CONVERTER_VIEWER],
    [UserRoles.SUPER_ADMIN, UserRoles.SISTER_COMPANY_ADMIN, UserRoles.TECH],
  ],
  [
    [AccessFlag.VEHICLE_BASIC_VIEWER, AccessFlag.VEHICLE_ADVANCED_VIEWER],
    [
      UserRoles.SISTER_COMPANY_ADMIN,
      UserRoles.TECH,
      UserRoles.BUYER,
      UserRoles.ADMIN,
      UserRoles.CUSTOMER,
    ],
  ],
  [
    [AccessFlag.VEHICLE_CREATOR, AccessFlag.VEHICLE_EDITOR],
    [UserRoles.SISTER_COMPANY_ADMIN, UserRoles.TECH, UserRoles.BUYER],
  ],
  [[AccessFlag.REPORT_BASIC_GENERATOR], [UserRoles.SISTER_COMPANY_ADMIN]],
  [[AccessFlag.SHOPPING_CART_VIEWER], [UserRoles.BUYER, UserRoles.CUSTOMER]],
];

export const superAdminExclusiveFlags: (
  | AccessFlag.NON_STANDARD_CONVERTER_VIEWER
  | AccessFlag.NON_STANDARD_CONVERTER_EDITOR
  | AccessFlag.VEHICLE_BASIC_VIEWER
  | AccessFlag.VEHICLE_ADVANCED_VIEWER
  | AccessFlag.VEHICLE_EDITOR
  | AccessFlag.VEHICLE_CREATOR
  | AccessFlag.FOIL_VIEWER
)[] = [
  AccessFlag.NON_STANDARD_CONVERTER_VIEWER,
  AccessFlag.NON_STANDARD_CONVERTER_EDITOR,
  AccessFlag.VEHICLE_BASIC_VIEWER,
  AccessFlag.VEHICLE_ADVANCED_VIEWER,
  AccessFlag.VEHICLE_EDITOR,
  AccessFlag.VEHICLE_CREATOR,
  AccessFlag.FOIL_VIEWER,
];

export const twoFactorAuthExclusiveFlags: (
  | AccessFlag.NON_STANDARD_CONVERTER_VIEWER
  | AccessFlag.NON_STANDARD_CONVERTER_EDITOR
  | AccessFlag.VEHICLE_BASIC_VIEWER
  | AccessFlag.VEHICLE_ADVANCED_VIEWER
  | AccessFlag.VEHICLE_EDITOR
  | AccessFlag.VEHICLE_CREATOR
)[] = [
  AccessFlag.NON_STANDARD_CONVERTER_VIEWER,
  AccessFlag.NON_STANDARD_CONVERTER_EDITOR,
  AccessFlag.VEHICLE_BASIC_VIEWER,
  AccessFlag.VEHICLE_ADVANCED_VIEWER,
  AccessFlag.VEHICLE_EDITOR,
  AccessFlag.VEHICLE_CREATOR,
];
