import React from 'react';
import Select from 'react-select';

import { useTypedIntl } from 'locale/messages';
import {
  SortDirection,
  SortInterface,
  SortSelectOptions,
  SortTypes,
} from 'shared/constants/sortableModules';
import { FieldSelectOption, SelectStyles } from '../Fields/FieldSelect/FieldSelect';
import { FieldSelectStyled } from '../Fields/FieldSelect/FieldSelect.styles';

interface PageSortInterface {
  customSortTypes?: SortSelectOptions[];
  handleChange?: (changed: SortInterface | null) => void;
  currentSortValue?: SortInterface;
}

const PageSortSelect = ({
  customSortTypes,
  handleChange,
  currentSortValue,
}: PageSortInterface): React.ReactElement => {
  const intl = useTypedIntl();
  const styles: SelectStyles = FieldSelectStyled(false, false);

  const defaultSortTypes = [
    {
      label: intl.formatMessage({ id: `Global.Filters.Default` }),
      value: null,
    },
  ];

  const sortOptions = Array.isArray(customSortTypes)
    ? [...defaultSortTypes, ...customSortTypes]
    : defaultSortTypes;

  const selectOptions = sortOptions.map(({ label, value }) => ({
    label,
    value: value ? `${value.sortType}.${value.sortDirection}` : '',
  }));

  const optionValue =
    selectOptions.find(
      option => option.value === `${currentSortValue?.sortType}.${currentSortValue?.sortDirection}`,
    ) ?? selectOptions[0];
  const handleSortChange = (selected: FieldSelectOption | null) => {
    const [sortType, sortDirection] = `${selected?.value}`?.split('.') as [
      SortTypes,
      SortDirection,
    ];
    handleChange?.(selected?.value ? { sortType, sortDirection } : null);
  };

  return (
    <Select
      id="sort-list-select"
      name="sort-list"
      inputId="sortBy"
      onChange={handleSortChange}
      options={selectOptions as FieldSelectOption[]}
      value={optionValue as FieldSelectOption}
      isSearchable={false}
      styles={styles}
      openMenuOnFocus
      data-cy="sort-list-select"
      menuPlacement="top"
    />
  );
};

export { PageSortSelect };
