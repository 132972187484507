import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FormikContextType } from 'formik';
import { isNil } from 'lodash';

import { FieldSelect, FieldSelectOption } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { Section, SectionChild } from 'components/shared/forms/Section/Section';
import { resolveHedgeStatus } from 'helpers/hedges/resolveHedgeStatus';
import { CompanyTypes, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser } from 'shared/hooks';
import {
  useGetAssignableUsers,
  useGetAssignableUsersForCreate,
  useGetAssignedCompanies,
  useGetCompanies,
} from 'shared/queries';
import { Hedge, Status } from 'shared/types';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { Theme } from 'theme';
import { useTypedIntl } from '../locale/messages';

export const DisabledInfo = styled(SectionChild)<{ theme?: Theme }>`
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.alphaGray};
  padding: 10px 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  formikContext: FormikContextType<HedgeFormSchema>;
  hedgeData?: Hedge;
  editMode?: boolean;
}

export const HedgeFormStepTwo = ({
  formikContext,
  hedgeData,
  editMode = false,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const assignableUsers = useGetAssignableUsersForCreate(formikContext.values.relatedCompanies!, {
    enabled: !editMode,
  });

  const assignableUsersForEdit = useGetAssignableUsers(
    {
      hedgeId: hedgeData?.id as number,
      relatedCompanies: formikContext.values.relatedCompanies,
    },
    { enabled: editMode && !isNil(hedgeData?.id) },
  );

  const currentUser = useCurrentUser();
  const canChangeCompany = authorize(PERMISSIONS.HEDGES.ASSIGN.SHOW_FULL_HEDGE_DATA);
  const canChangeParentCompany = authorize(PERMISSIONS.HEDGES.CHANGE_PARENT_COMPANY);
  const parentCompanies = useGetCompanies(
    { type: CompanyTypes.SISTER, status: Status.ACTIVE },
    { enabled: canChangeParentCompany },
  );

  const companiesToSelect = useGetAssignedCompanies(
    formikContext.values.parentCompany ?? currentUser.company.id,
  );

  const companiesOptions: FieldSelectOption[] = useMemo(
    () =>
      (canChangeCompany ? formikContext.values.relatedCompanies! : [currentUser.company.id])?.map(
        companyId => ({
          value: companyId,
          label: [
            ...companiesToSelect.data!,
            ...parentCompanies.data!.data,
            currentUser.company,
          ].find(el => el.id === companyId)?.name as string,
        }),
      ),
    [
      canChangeCompany,
      currentUser,
      formikContext.values.relatedCompanies,
      companiesToSelect.data,
      parentCompanies.data,
    ],
  );
  const usersOptions: FieldSelectOption[] = useMemo(
    () =>
      (editMode ? assignableUsersForEdit.data : assignableUsers.data)
        ?.filter(user => user.company.id === formikContext.values.assignedUsersCompany)
        .map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        })) ?? [],
    [
      assignableUsers.data,
      formikContext.values.assignedUsersCompany,
      editMode,
      assignableUsersForEdit.data,
    ],
  );

  const isHedgeInactive =
    resolveHedgeStatus({
      placedAt: formikContext.values.placedAt,
      expiresAt: formikContext.values.expiresAt,
    } as unknown as Hedge) !== Status.ACTIVE;
  const template = isHedgeInactive
    ? ['assignedUsersCompany assignedUsers', 'inactiveInfo inactiveInfo']
    : ['assignedUsersCompany assignedUsers'];

  return (
    <FormInWizard
      context={formikContext}
      header={intl.formatMessage({ id: `HedgeForm.${editMode ? 'UpdateHeader' : 'Header'}` })}
    >
      <Section
        setActive={setActiveSection}
        activeSection={activeSection}
        sectionName="hedgeInfo"
        label={intl.formatMessage({ id: 'HedgeForm.Section.Assign' })}
        template={template}
      >
        <FieldSelect
          label={intl.formatMessage({ id: 'HedgeForm.Assign.Company' })}
          name="assignedUsersCompany"
          value={formikContext.values.assignedUsersCompany}
          options={companiesOptions}
          disabled={isHedgeInactive}
          clearable
        />
        <FieldSelect
          label={intl.formatMessage({ id: 'HedgeForm.Assign.Users' })}
          name="assignedUsers"
          value={formikContext.values.assignedUsers}
          options={usersOptions}
          disabled={isHedgeInactive}
          multi
        />
        {isHedgeInactive && (
          <DisabledInfo name="inactiveInfo" data-cy="step-two-hedge-inactive">
            {intl.formatMessage({ id: 'HedgeForm.Assign.Inactive' })}
          </DisabledInfo>
        )}
      </Section>
    </FormInWizard>
  );
};
