export const matchIdentifier = (query?: string | null): RegExp | undefined => {
  if (!query) return;

  return new RegExp(
    query
      .replace(/[^\w-'ąćęłńóśźżĄĆĘŁŃÓŚŹŻ']|_/g, '')
      .split('')
      .join('[^a-z\\d]*?')
      .replace(/(?!a-z\\d\]\*)[AĄ]/gi, '[ĄA]')
      .replace(/[CĆ]/gi, '[ĆC]')
      .replace(/[EĘ]/gi, '[ĘE]')
      .replace(/[NŃ]/gi, '[ŃN]')
      .replace(/[SŚ]/gi, '[ŚS]')
      .replace(/(?!z\\d\]\*)[ZŹŻ]/gi, '[ŹŻZ]')
      .replace(/[ŁLI1]/gi, '[ŁLI1]')
      .replace(/[ÓO0]/gi, '[ÓO0]'),
    'ig',
  );
};
