import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname } from 'shared/constants';
import { Vehicle } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum DecodeVinKeys {
  DecodeVin = 'DecodeVin',
}

type DecodeVinResponse = Pick<
  Vehicle,
  | 'engineType'
  | 'make'
  | 'model'
  | 'transmission'
  | 'vehicleType'
  | 'vin'
  | 'year'
  | 'engineDisplacement'
  | 'enginePower'
  | 'numberOfDoors'
>;
export type UseDecodeVinResponse = UseQueryResult<DecodeVinResponse>;

export const useDecodeVin = (
  params: string,
  options: UseQueryOptions<DecodeVinResponse> = {},
): UseDecodeVinResponse =>
  useQuery<DecodeVinResponse>(
    [DecodeVinKeys.DecodeVin, params],
    async () => {
      if (params.length !== 17) return null;
      const response = await getHttpClient().get(
        `${apiHostname}/api/vin/decode/${params.toUpperCase()}`,
      );
      return response.data;
    },
    options,
  );
