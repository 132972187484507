import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

import { geoLocationErrorCodes, Languages } from 'shared/constants';
import { useCurrentUser, useLanguage, useMediaQuery, useUserBrowser } from 'shared/hooks';
import { useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, theme } from 'theme';
import { messages, TypedFormattedMessage as FormattedMessage } from './locale/messages';
import { AlphamartIntlProvider } from '../AlphamartIntlProvider';
import { TextButton } from '../Buttons';
import AppButton from '../forms/AppButton/AppButton';
import { LoadableContent, LoadableContentModes } from '../Loader';
import { ReactModalStyle } from '../Modals.styles';

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  width: 50vw;
  height: 50vh;
  background-color: ${theme.colors.mineShaft};
  padding: 24px 32px;
  border-radius: 12px;

  @media ${MEDIA_QUERY.MAX_XL} {
    width: 75vw;
    height: 75vh;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    padding: 16px;
  }

  & > * {
    margin-top: 16px;
  }
`;

const enableGeolocationLinks = {
  [Languages.EN]: 'https://www.google.com/search?q=enable+geolocation+services',
  [Languages.ES]: 'https://www.google.com/search?q=habilitar+los+servicios+de+geolocalizaci%C3%B3n',
  [Languages.FR]: 'https://www.google.com/search?q=activer+les+services+de+g%C3%A9olocalisation',
  [Languages.PL]:
    'https://www.google.pl/search?q=zmienianie+domy%C5%9Blnych+ustawie%C5%84+lokalizacji',
};

function DisabledGeolocationModal(): React.ReactElement | null {
  const [forceReloadEnabled, setForceReloadEnabled] = useState(false);
  const language = useLanguage();
  const userBrowser = useUserBrowser();
  const { position, error } = useAppSelector(state => state.position);
  const currentUser = useCurrentUser();
  const userApprovedTC = !!currentUser?.consents?.length;
  const errorExists = !!(error?.code || error?.message);
  const timeoutError = error?.code === geoLocationErrorCodes.TIMEOUT;
  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const geolocationDisabled = errorExists || !position;
  const positionFetchedFromGoogleApi = position && timeoutError;

  // Show button 2s after timeout error appears
  useEffect(() => {
    const timeoutHandle = setTimeout(() => setForceReloadEnabled(timeoutError), 2000);
    return () => clearTimeout(timeoutHandle);
  }, [timeoutError]);

  const forceReload = () => window.location.reload();

  if (positionFetchedFromGoogleApi) return null;

  return (
    <AlphamartIntlProvider messages={messages}>
      <ReactModal
        isOpen={geolocationDisabled && userApprovedTC}
        style={ReactModalStyle(isPhone)}
        parentSelector={() => document.querySelector('#root')!}
      >
        <ModalContent data-guide-blocker>
          {errorExists && !timeoutError ? (
            <>
              <FormattedMessage id="GeolocationModal.Message.Disabled" />
              <a
                href={`${enableGeolocationLinks[language]}+${userBrowser}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TextButton fontSize={20}>
                  <FormattedMessage id="GeolocationModal.HowToTurnOnGeolocation" />
                </TextButton>
              </a>
            </>
          ) : (
            <>
              <FormattedMessage id="GeolocationModal.Message.Pending" />
              {forceReloadEnabled ? (
                <AppButton onClick={forceReload}>
                  <FormattedMessage id="GeolocationModal.ForceReload" />
                </AppButton>
              ) : (
                <LoadableContent loading mode={LoadableContentModes.INLINE} />
              )}
            </>
          )}
        </ModalContent>
      </ReactModal>
    </AlphamartIntlProvider>
  );
}

export { DisabledGeolocationModal };
