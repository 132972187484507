import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { CompanyTypes, FeedTypes } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { Adjustment } from 'shared/types';
import { fetchAdjustments } from 'store/adjustmentsSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY, Theme } from 'theme';
import { messages, TypedFormattedMessage as FormattedMessage } from '../locale/messages';

export const Notification = styled.div<{ theme?: Theme }>`
  grid-area: message;
  margin-top: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.codGray};
  font-weight: bold;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.alphaGray};
  padding: 10px 12px;

  @media ${MEDIA_QUERY.MAX_XL} {
    margin-top: -8px;
    margin-left: -16px;
    width: calc(100% + 2 * 16px);
    border-radius: 0;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    margin-bottom: 16px;
  }
`;

const ManualEntryPriceNotificationComponent = (): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const adjustmentState = useAppSelector(state => state.adjustments);
  const list = adjustmentState.adjustmentRates.list as Omit<Adjustment, 'id'>[];
  const auth = useAppSelector(state => state.auth);
  const user = useCurrentUser();
  useEffect(() => {
    dispatch(fetchAdjustments(user?.company.id));
  }, [dispatch, user?.id]);

  const usingManualEntryPrice = list.some(
    ({ sourceAdjustmentType }) => FeedTypes.MANUAL_ENTRY === sourceAdjustmentType,
  );

  const company = auth?.user?.data?.company;
  if (!usingManualEntryPrice || !company) return null;

  return (
    <Notification>
      <FormattedMessage
        id="Dashboard.ManualEntryPricesInUse"
        values={{
          companyName:
            company.type === CompanyTypes.SISTER ? company.name : company.parentCompany?.name,
        }}
      />
    </Notification>
  );
};

export const ManualEntryPriceNotification = withAlphamartIntlProvider(
  ManualEntryPriceNotificationComponent,
  messages,
);
