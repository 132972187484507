import React from 'react';

import { appendThumbnailLink } from 'helpers/watermarks/appendThumbnailLink';
import { ApiFile } from 'shared/types';
import { ItemThumbnailWrapper } from './DataList.styles';
import { ListImage } from './ListImage';
import { IconTooltipWarning } from '../IconTooltip';
import { PhotoPlaceholder } from '../PhotoPlaceholder';

type Props = {
  attrAlt: string;
  counterfeit?: {
    notes: string | null;
    show: boolean;
  };
  photo?: ApiFile | null;
  size?: 'SMALL' | 'LARGE' | 'MEDIUM';
};

export const ItemThumbnail = ({
  attrAlt,
  counterfeit,
  photo,
  size = 'SMALL',
}: Props): React.ReactElement => (
  <ItemThumbnailWrapper>
    {photo?.presignedUrls ? (
      <ListImage alt={attrAlt} src={appendThumbnailLink(photo?.presignedUrls?.[size] ?? '')} />
    ) : (
      <PhotoPlaceholder />
    )}
    {counterfeit?.show && <IconTooltipWarning tooltip={counterfeit.notes} />}
  </ItemThumbnailWrapper>
);
