import React from 'react';
import styled from '@emotion/styled';

import { useTypedIntl } from 'locale/messages';
import { useExtendedTheme } from 'shared/hooks';
import { LoadableContentModes, LoaderSizes } from './LoadableContent';
import { dashAnimation, loaderStyles } from './Loader.styles';

const LoaderLabel = styled.div`
  padding-top: 10px;
  font-size: 16px;
  font-weight: normal;
`;

interface Props {
  label?: React.ReactNode;
  mode?: LoadableContentModes;
  circlesNum?: number;
  size?: LoaderSizes;
  speed?: number;
  strokeWidth?: number;
  hideLabel?: boolean;
}

export const Loader = ({
  label,
  mode,
  size = 'M',
  circlesNum = 5,
  strokeWidth = 2,
  speed = 4,
  hideLabel = false,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const circles = [...Array(circlesNum)].map((_, index) => ({
    className: dashAnimation(2 * Math.PI * (50 - index * 2), speed, index),
    r: 50 - strokeWidth * (index + 1),
    opacity: 1 - index / 10,
  }));

  return (
    <>
      {hideLabel
        ? null
        : mode !== 'INLINE' && (
            <LoaderLabel>{label || intl.formatMessage({ id: 'Global.Loading' })}</LoaderLabel>
          )}
      <svg
        className={loaderStyles(size)}
        viewBox="0 0 100 100"
        height="110px"
        width="110px"
        data-testid="loader"
      >
        <g strokeWidth={strokeWidth * 1.3} stroke={theme.mainColor} fill="none">
          {circles.map(({ className, r, opacity }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <circle key={i} strokeOpacity={opacity} className={className} cx="50" cy="50" r={r} />
          ))}
        </g>
      </svg>
    </>
  );
};
