import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';

import { GlobalMessages, TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { useEventListener } from 'shared/hooks';
import { layers, MEDIA_QUERY, Theme } from 'theme';

const FieldColorPickerMain = styled.div`
  position: relative;
`;

const FieldColorPickerButton = styled.div<{ theme?: Theme; isOpened?: boolean }>`
  background: ${({ theme }) => theme.colors.mineShaftLightest};
  height: 48px;
  border-radius: 24px;
  display: flex;
  padding: 8px;
  align-items: center;
  cursor: pointer;
  position: relative;

  ${({ isOpened }) => `
    ${isOpened && `background: ${({ theme }) => theme.colors.mineShaftLightest};`}
  `}
`;

const FieldColorPickerValue = styled.div<{ value?: string }>`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin: 0 20px 0 0;

  ${({ value }) => `
    background: ${value}
  `}
`;

const FieldColorPickerLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const FieldColorPickerPalette = styled.div<{ theme?: Theme }>`
  position: absolute;
  z-index: ${layers.colorPickerPalette};
  left: 100%;
  top: 50%;
  margin: 0 0 0 20px;
  transform: rotate(90deg) translate(-6px, 88%);
  padding: 18px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.mineShaftLightest};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  &:after {
    content: '';
    display: block;
    border-right: 10px solid ${({ theme }) => theme.colors.mineShaftLightest};
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    position: absolute;
    top: 50%;
    left: -10px;
    margin: -20px 0 0 0;
  }

  @media ${MEDIA_QUERY.MAX_XL} {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 20px 0 0 0;

    &:after {
      border-bottom: 10px solid ${({ theme }) => theme.colors.mineShaftLightest};
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      position: absolute;
      left: 50%;
      top: -10px;
      margin: -20px 0 0 -20px;
    }
  }
`;

const FieldColorPickerPalleteItem = styled.div<{ theme?: Theme; active?: boolean }>`
  height: 36px;
  width: 36px;
  border-radius: 18px;
  cursor: pointer;

  ${({ color }) => `
    background: ${color};
  `}

  ${({ active, theme }) => active && `border: 2px solid ${theme.colors.white}`}
`;

const FieldColorPicker = ({ palette, value, label, onChange, name }: Props): React.ReactElement => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  const hideMenu = event => {
    if (fieldRef.current && !fieldRef.current.contains(event.target)) {
      setIsOpened(false);
    }
  };

  useEventListener('mousedown', hideMenu);

  return (
    <FieldColorPickerMain ref={fieldRef}>
      <FieldColorPickerButton isOpened={isOpened} onClick={() => setIsOpened(!isOpened)}>
        <FieldColorPickerValue value={value} />
        <FieldColorPickerLabel>
          {label}
          {`: `}
          <FormattedMessage
            id={
              (palette.find(color => color.value === value)?.label as keyof GlobalMessages) ||
              'Global.Color.SelectedColor'
            }
          />
        </FieldColorPickerLabel>
      </FieldColorPickerButton>
      {isOpened && (
        <FieldColorPickerPalette>
          {palette.map(color => (
            <FieldColorPickerPalleteItem
              active={color.value === value}
              color={color.value}
              onClick={() => {
                onChange({
                  target: {
                    name,
                    value: color.value,
                  },
                });
                setIsOpened(!isOpened);
              }}
            />
          ))}
        </FieldColorPickerPalette>
      )}
    </FieldColorPickerMain>
  );
};

interface Props {
  palette: {
    value: string;
    label: string;
  }[];
  value: string;
  label: React.ReactNode;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export { FieldColorPicker };
