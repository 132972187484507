import React, { useCallback, useState } from 'react';
import { css, keyframes } from '@emotion/css';
import { debounce, isNil } from 'lodash';
import { v4 as uuid4 } from 'uuid';

import closeIcon from 'assets/images/icons/Close.svg';
import dotsIcon from 'assets/images/icons/dots.svg';
import searchIcon from 'assets/images/icons/search.svg';
import { useTypedIntl } from 'locale/messages';
import { useEffectAfterMount, useExtendedTheme } from 'shared/hooks';
import { usePrevious } from 'shared/hooks/usePrevious';
import { Icon } from '../Buttons';
import { FieldInputRaw } from '../Fields/FieldInput/FieldInput';

export interface SearchChangEvent {
  query?: string;
  searchSession?: string;
}
interface Props {
  onSearchChanged: (changed: SearchChangEvent) => void;
  searchAfter: number;
  initialQuery?: string;
  loadingState?: boolean;
  addSearchSession?: boolean;
}
const loadingIndicator = theme => css`
  position: absolute;
  right: 56px;
  top: 0;
  z-index: 1;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    animation: ${expand} 1s 0s linear infinite;
    height: 100%;
    background: ${theme.colors.codGrayDarker};
  }
`;

const searchBarWrapper = css`
  position: relative;
`;

const expand = keyframes`
  0% { width: 100%; }
  24% { width: 100%; }
  25% { width: 60%; }
  49% { width: 60%; }
  50% { width: 40%; }
  74% { width: 40%; }
  75% { width: 0%; }
`;

export const SearchInput = ({
  loadingState,
  onSearchChanged,
  searchAfter,
  initialQuery,
  addSearchSession = false,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const onSearchChangedDebounce = useCallback(debounce(onSearchChanged, 700), [onSearchChanged]);
  const [query, setQuery] = useState(initialQuery ?? '');
  const [searchSession, setSearchSession] = useState(uuid4());
  const [keyRepeats, setKeyRepeats] = useState({ key: '', repeats: 0 });
  const previousQuery = usePrevious(query);

  const handleKeyDown = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e) return;
    setKeyRepeats(({ key, repeats }) => ({ key: e.key, repeats: e.key === key ? repeats + 1 : 1 }));
  };

  const searchChangeHandler = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e?.target.value ?? '');
  };

  useEffectAfterMount(() => {
    const session =
      query.includes(previousQuery ?? '') || keyRepeats.repeats > 1 ? searchSession : uuid4();

    if (
      query.length >= searchAfter ||
      (query.length === 0 && !isNil(previousQuery) && previousQuery.length > 0)
    ) {
      onSearchChangedDebounce({
        query,
        ...(addSearchSession && { searchSession: session }),
      });
    }

    setSearchSession(session);
  }, [query]);

  const clearQuery = useCallback(() => {
    setQuery('');
    setSearchSession(uuid4());
  }, [query, setQuery, setSearchSession, onSearchChanged]);

  return (
    <div className={searchBarWrapper}>
      <FieldInputRaw
        name="search"
        className={css`
          z-index: unset !important;
        `}
        onChange={searchChangeHandler}
        onKeyDown={handleKeyDown}
        placeholder={intl.formatMessage({ id: 'Global.Search' })}
        data-cy="search-input"
        icon={query ? closeIcon : searchIcon}
        onIconClick={query ? clearQuery : undefined}
        value={query}
        iconProps={{ pointer: true }}
      />
      {loadingState && (
        <Icon
          className={loadingIndicator(theme)}
          color={theme.colors.sliver}
          icon={dotsIcon}
          size="48px"
        />
      )}
    </div>
  );
};
