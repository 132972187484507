import { UseDecodeVinResponse } from 'shared/queries';

export const shouldShowDecodeVinError = (
  response: UseDecodeVinResponse,
  vinToDecode?: string,
): boolean => {
  if (!response.isFetched || !vinToDecode) {
    return false;
  }
  if (!response.data || response.isError) {
    return true;
  }

  const { vin, ...rest } = response.data;
  return Object.values(rest).every(val => !val);
};
