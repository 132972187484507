import React from 'react';
import { useParams } from 'react-router-dom';
import difference from 'lodash/difference';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { FormContainer } from 'components/shared/forms/FormContainer/FormContainer';
import { LoadableContent } from 'components/shared/Loader';
import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate } from 'shared/hooks/useAlphamartRouter';
import { useUpdateHedge } from 'shared/mutations';
import { useGetHedgeDetails } from 'shared/queries';
import { HedgeFormSchema } from 'shared/types/hedgeFormSchema ';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { HedgeForm } from '../HedgeForm/HedgeForm';
import { messages, useTypedIntl } from '../locale/messages';

const UpdateHedgeComponent = (): React.ReactElement => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: hedge, isFetching: isHedgeDetailsFetching } = useGetHedgeDetails(+id!);
  const { mutateAsync: updateHedge, isLoading: isUpdateHedgeLoading } = useUpdateHedge();
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const canChangeParentCompany = authorize(PERMISSIONS.HEDGES.CHANGE_PARENT_COMPANY);

  const initialValues: HedgeFormSchema | undefined = hedge && {
    parentCompany: canChangeParentCompany ? hedge.parentCompany.id : null,
    ptPriceCust: hedge.ptPriceCust === 0 ? null : String(hedge.ptPriceCust ?? ''),
    pdPriceCust: hedge.pdPriceCust === 0 ? null : String(hedge.pdPriceCust ?? ''),
    rhPriceCust: hedge.rhPriceCust === 0 ? null : String(hedge.rhPriceCust ?? ''),
    ptPriceCustConfirm: hedge.ptPriceCust === 0 ? null : String(hedge.ptPriceCust ?? ''),
    pdPriceCustConfirm: hedge.pdPriceCust === 0 ? null : String(hedge.pdPriceCust ?? ''),
    rhPriceCustConfirm: hedge.rhPriceCust === 0 ? null : String(hedge.rhPriceCust ?? ''),
    relatedLot: hedge.relatedLot,
    name: hedge.name,
    relatedCompanies: hedge.relatedCompanies.map(company => company.id),
    note: hedge.note ?? '',
    placedAt: new Date(hedge.placedAt),
    expiresAt: new Date(hedge.expiresAt),
    assignedUsers: null,
    assignedUsersCompany: null,
  };

  const submitFormData = async (values: HedgeFormSchema) => {
    try {
      await updateHedge({ values, id: id! });
      dispatch(snackBarPushSuccess(intl.formatMessage({ id: 'HedgeForm.Update.Success' })));
      navigate(`/hedges/${id}`);
    } catch {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })));
    }
  };

  const handleSubmit = async (values: HedgeFormSchema) => {
    const relatedCompanyRemoved = difference(
      initialValues?.relatedCompanies,
      values?.relatedCompanies ?? [],
    ).length;
    if (relatedCompanyRemoved > 0) {
      const messageId =
        relatedCompanyRemoved === 1
          ? 'HedgeForm.Update.UnassignCompanyWarningSingular'
          : 'HedgeForm.Update.UnassignCompanyWarningPlural';
      dispatch(
        showModal({
          message: intl.formatMessage({ id: messageId }),
          onClose: () => {
            dispatch(hideModal());
          },
          onConfirm: async () => {
            dispatch(hideModal());
            await submitFormData(values);
          },
        }),
      );
    } else {
      await submitFormData(values);
    }
  };

  return (
    <FormContainer>
      <LoadableContent
        loading={isUpdateHedgeLoading || isHedgeDetailsFetching}
        mode={LoadableContent.MODE.FULL}
        drawContent
      >
        {hedge && (
          <HedgeForm
            onSubmit={handleSubmit}
            editMode
            initialValues={initialValues}
            hedgeData={hedge}
          />
        )}
      </LoadableContent>
    </FormContainer>
  );
};

export const UpdateHedge = withAlphamartIntlProvider(UpdateHedgeComponent, messages);
