import React, { useEffect } from 'react';
import { FieldArray } from 'formik';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { Vehicle } from 'shared/types';
import { ConverterIdentifierSelect } from './ConverterIdentifierSelect';
import { StyledSingleConverterSectionWrapper } from './VehicleForm.styles';
import { MAX_CONVERTERS_COUNT } from './vehicleFormSchema';
import { useTypedIntl } from '../locale/messages';

interface ConverterFieldsProps {
  values: Vehicle;
  handleChange: (e?: React.ChangeEvent) => void;
  getErrors: (fieldName: string) => string | false | undefined;
  handleBlur: (e?: React.ChangeEvent) => void;
  setActiveInput: (input: string) => void;
  handleFocus: (section?: string) => void;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  activeInput?: string | null;
}

export const ConverterSection = ({
  values,
  handleChange,
  setActiveInput,
  getErrors,
  handleBlur,
  setFieldValue,
  handleFocus,
  activeInput,
}: ConverterFieldsProps): React.ReactElement => {
  const intl = useTypedIntl();

  const onBlur = e => {
    setActiveInput('');
    handleBlur(e);
  };

  const onFocus = e => {
    setActiveInput(e.target.name);
    handleFocus(e);
  };

  useEffect(() => {
    values.converters?.forEach((field, index) => {
      if (!field.identifier) {
        setFieldValue(`converters.${index}.identifierConfirm`, '');
      }
    });
  }, [values.converters]);

  const convertersToRender = values.converters?.slice(
    0,
    Math.max(
      0,
      Math.min(values.numberOfConverters ?? 0, MAX_CONVERTERS_COUNT) -
        (values.numberOfNonstandardConverters ?? 0),
    ),
  );

  return (
    <FieldArray
      name="converters"
      render={() => (
        <div>
          <FieldInput
            label={intl.formatMessage({ id: 'VehicleForm.TotalNumberOfConverters' })}
            name="numberOfConverters"
            onChange={e => {
              handleChange(e);
            }}
            onBlur={onBlur}
            value={values.numberOfConverters ?? ''}
            error={getErrors('numberOfConverters')}
            data-cy="number-of-converters"
            onFocus={onFocus}
            required
            maxLength={9}
          />
          {convertersToRender?.map((field, index) => (
            <StyledSingleConverterSectionWrapper key={field.key}>
              <ConverterIdentifierSelect
                name={`converters.${index}.identifier`}
                label={intl.formatMessage({ id: 'VehicleForm.Identifier' })}
                onChange={value => {
                  setFieldValue(`converters.${index}.identifier`, value?.value);
                  setFieldValue(`converters.${index}.identifierConfirm`, value?.value);
                }}
                onCreateOption={value => {
                  setFieldValue(`converters.${index}.identifier`, value);
                  setFieldValue(`converters.${index}.identifierConfirm`, '');
                }}
                onFocus={e => setActiveInput(e.target.name)}
                onBlur={onBlur}
                value={field.identifier}
                data-cy={`converters-${index}-converter-id`}
                error={getErrors(`converters.${index}.identifier`)}
                maskInput={activeInput === `converters.${index}.identifierConfirm`}
              />
              <FieldInput
                label={intl.formatMessage({ id: 'VehicleForm.IdentifierConfirm' })}
                name={`converters.${index}.identifierConfirm`}
                onChange={handleChange}
                onBlur={onBlur}
                value={field.identifierConfirm}
                error={getErrors(`converters.${index}.identifierConfirm`)}
                data-cy={field.label}
                onFocus={onFocus}
                autoComplete="off"
                disabled={!field.identifier}
                required
                capitalize
                protection={{
                  hide: activeInput === `converters.${index}.identifier`,
                  copy: true,
                  paste: true,
                }}
                maxLength={100}
              />
            </StyledSingleConverterSectionWrapper>
          ))}
        </div>
      )}
    />
  );
};
