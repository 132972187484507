import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { apiHostname, GuideTypes } from 'shared/constants';
import { getHttpClient } from 'shared/utils/httpClient';

export enum GuidesKeys {
  GetPendingGuides = 'GetPendingGuides',
}

export const useGetPendingGuides = (
  options: UseQueryOptions<GuideTypes[]> = {},
): UseQueryResult<GuideTypes[]> =>
  useQuery<GuideTypes[]>(
    [GuidesKeys.GetPendingGuides],
    async () => {
      const response = await getHttpClient().get<GuideTypes[]>(`${apiHostname}/api/me/guides`);

      return response.data;
    },
    { initialData: [], ...options },
  );
